import React from 'react'
import SignsListPure from './Pure'
import { useLocation } from 'wouter'
import { ISignOnDemand } from 'collections'
import useIntl from '../../../hooks/useIntl'
import { routes } from '../../../community'
import { ClassNameMap } from '@material-ui/styles'

interface ISignsOnDemandListProps {
  /** Dados da tabela */
  data?: ISignOnDemand[]
  /** Classes do useStyles */
  classes: ClassNameMap
  /** Handle de edição */
  handleClickEditRow: (data: ISignOnDemand) => void
  /** Handle de exclusão */
  handleClickDeleteRow: (data: ISignOnDemand) => void
}

/**
 * Componente da listagem de sinais sob demanda
 */
const SignsList: React.FC<ISignsOnDemandListProps> = ({ data, classes, handleClickEditRow, handleClickDeleteRow }) => {
  const intl = useIntl()
  const textGlosa = intl.get('pages.signOnDemand.glosa')
  const textRelatedWords = intl.get('pages.signOnDemand.relatedWords')
  const textStatus = intl.get('pages.signOnDemand.status')
  const [, setLocation] = useLocation()

  const onSelectSignOnDemand = (rowId: string) => {
    setLocation(routes.signOnDemandRecordVideo.replace(':signOnDemandId', rowId))
  }

  const getStatus = (status: string, signOnDemand: ISignOnDemand) => {
    if (signOnDemand.isRecording?.value) {
      return intl.get(`pages.signOnDemand.recordingVideo`)
    } else if (signOnDemand.isSegmenting?.value) {
      return intl.get(`pages.signOnDemand.segmentingVideo`)
    }
    return intl.get(`pages.signOnDemand.${status}`)
  }

  return (
    <SignsListPure
      textStatus={textStatus}
      textGlosa={textGlosa}
      textRelatedWords={textRelatedWords}
      data={data || []}
      classes={classes}
      onSignOnDemandClick={onSelectSignOnDemand}
      getStatus={getStatus}
      handleClickEditRow={handleClickEditRow}
      handleClickDeleteRow={handleClickDeleteRow}
    />
  )
}

export default SignsList
