import React from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
// import Select from '@material-ui/core/Select'

import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import { Checkbox } from '@material-ui/core'
interface ISettingsMenuProps {
  /** Determina se o menu está visivel */
  open: boolean
  /**
   * Chamado quanod o fied de contagem regressiva for alterado
   */
  onCountDownChange: (value: number) => void
  /** Menu foi fechado */
  onClose?: () => void
  /** Contagem regressiva atual */
  countdown: number
  /** Elemento que será utilizado como base de posicionamento */
  anchorEl?: HTMLElement
  /** Texto do componente */
  textCountdown: string
  cameraDevices: MediaDeviceInfo[]
  handleChangeCamera: (value: string) => void
  selectedCamera: MediaDeviceInfo | null
  pinContour?: boolean
  textFixContour?: string
  handleChangePinContour?: (value: boolean) => void
}

/**
 * Renderiza a view pura do menu de configurações
 */
const SettingsMenuPure: React.FC<ISettingsMenuProps> = ({
  onCountDownChange,
  onClose,
  open,
  countdown,
  anchorEl,
  textCountdown,
  cameraDevices,
  handleChangeCamera,
  selectedCamera,
  pinContour,
  textFixContour,
  handleChangePinContour,
}) => {
  const handleOnCountdownChange = (newValue: number | number[]) => {
    onCountDownChange(newValue as number)
  }

  const CountdownField = (
    <Box minWidth={200}>
      <Box>
        <Typography variant="caption">{textCountdown}</Typography>
      </Box>
      <Box mt={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Slider
              onChange={(_, newValue) => handleOnCountdownChange(newValue)}
              style={{ width: 170 }}
              value={countdown}
              min={0}
              max={10}
            />
          </Grid>
          <Grid item>
            <Typography variant="overline" align="center">
              {countdown}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  const CameraSelectField = (
    <Box minWidth={200}>
      <Box>
        <Typography variant="caption">{'Camera'}</Typography>
      </Box>
      <Box mt={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Select
              fullWidth
              style={{ minWidth: '200', maxWidth: '200' }}
              value={selectedCamera?.deviceId}
              onChange={(
                event: React.ChangeEvent<{
                  name?: string | undefined
                  value: unknown
                }>,
              ) => {
                handleChangeCamera(event.target.value as string)
              }}
            >
              {cameraDevices.map((el) => (
                <MenuItem key={el.deviceId} value={el.deviceId}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  const PinContour = (
    <Box minWidth={200}>
      <Box>
        <Typography variant="caption">{textFixContour}</Typography>
      </Box>
      <Box mt={1}>
        <Checkbox
          color="primary"
          checked={pinContour}
          onChange={(
            event: React.ChangeEvent<{
              checked: boolean
            }>,
          ) => {
            if (handleChangePinContour !== undefined) handleChangePinContour(event.target.checked)
          }}
        />
      </Box>
    </Box>
  )

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      {/* <MenuItem disableTouchRipple>{CameraField}</MenuItem> */}
      <MenuItem disableTouchRipple>{CameraSelectField}</MenuItem>
      <MenuItem disableTouchRipple>{CountdownField}</MenuItem>
      <MenuItem disableTouchRipple>{PinContour}</MenuItem>
    </Menu>
  )
}

export default SettingsMenuPure
