import { FC } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CardMedia from '@material-ui/core/CardMedia'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import useIntl from '../../hooks/useIntl'

const Image = styled(CardMedia)`
  height: 350px;
  background-size: cover !important;
  background-position: top !important;
  margin-bottom: 16px;
`

export interface IApplicationDialogProps {
  open: boolean
  /**
   * Identifica o elemento (ou elementos) que rotula o elemento atual.
   * */
  labelledby: string
  imageUrl: string
  handleOKClick: () => void
}
export const LockModuleDialogPure: FC<IApplicationDialogProps> = ({ open, labelledby, imageUrl, handleOKClick }) => {
  const intl = useIntl()
  return (
    <Dialog id={labelledby} open={open} onClose={handleOKClick} aria-labelledby={labelledby} maxWidth="sm" fullWidth>
      <>
        <DialogContent>
          <Image image={imageUrl} />
          <Typography paragraph variant="h6">
            {intl.get('components.lockModuleDialog.mainMessage')}
          </Typography>
          <Typography variant="body1">• {intl.get('components.lockModuleDialog.reason1')}</Typography>
          <Typography variant="body1">• {intl.get('components.lockModuleDialog.reason2')}</Typography>
        </DialogContent>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <DialogActions>
            <Button variant="contained" onClick={handleOKClick} color="primary">
              OK
            </Button>
          </DialogActions>
        </Box>
      </>
    </Dialog>
  )
}

export default LockModuleDialogPure
