import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Layout from '../../components/Layout'
import TextField from '@material-ui/core/TextField'
import { DrawerName } from 'collections'
import { Typography, Button, Divider } from '@material-ui/core'
import { useFirestore } from 'reactfire'
import { green } from '@material-ui/core/colors'
import { useFsUserDocRef, useFsUserDocData } from '../../hooks/useFsUser'
import useIntl from '../../hooks/useIntl'
import Drawer from './Drawer'
import Shortcuts from './Shortcuts'
import useSnackbar from '../../services/hooks/useSnackbar'
import { runTransaction } from '@firebase/firestore'
interface IConfigurationProps {
  path: string
}

const Configuration: React.FC<IConfigurationProps> = () => {
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const [timeVideoRecord, setTimeVideoRecord] = useState<number>()
  const [selectedDrawer, setSelectedDrawer] = useState<DrawerName>(DrawerName.shortcuts)
  const firestore = useFirestore()
  const intl = useIntl()
  const showSnackbar = useSnackbar()

  const handleSave = async () => {
    await runTransaction(firestore, async (transaction) => {
      transaction.update(fsUserRef, {
        timeVideoRecord: timeVideoRecord,
      })
    })
    showSnackbar(intl.get('messages.savedSuccessfully'), { variant: 'success' })
  }

  useEffect(() => {
    setTimeVideoRecord(fsUser.timeVideoRecord)
  }, [fsUser])

  const handleOnChangeVideoTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeVideoRecord(Number(event.target.value))
  }

  return (
    <Layout title={intl.get('menu.configuration')}>
      <Grid container justifyContent="center">
        <Drawer selectedDrawer={selectedDrawer} setSelectedDrawer={setSelectedDrawer} />
        {selectedDrawer === DrawerName.shortcuts && <Shortcuts />}
        {selectedDrawer === DrawerName.otherSettings && (
          <Grid container justifyContent="center" style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
            <Grid item xs sm={8} md={8}>
              <Grid item>
                <Box p={2}>
                  <Typography align="center" variant="h6">
                    {intl.get('messages.otherSettings')}
                  </Typography>
                </Box>
              </Grid>
              <Box
                style={{
                  border: 'solid #AAAA 2px',
                  borderRadius: '10px',
                  padding: '15px',
                  marginTop: '10px',
                }}
              >
                <Grid item>
                  <Box p={2}>
                    <Typography variant="subtitle2">{intl.get('modules.signSentence')}</Typography>
                    <Divider />
                  </Box>
                </Grid>
                <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
                  <Box p={1}>
                    <TextField
                      label={intl.get('messages.videoTime')}
                      value={timeVideoRecord}
                      type="number"
                      onChange={handleOnChangeVideoTime}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
                p={2}
              >
                <Button variant="contained" onClick={handleSave} style={{ background: green[700], color: '#fff' }}>
                  {intl.get('messages.save')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}

export default Configuration
