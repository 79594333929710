import { FC } from 'react'

import Typography from '@material-ui/core/Typography'
import { Box, Tooltip } from '@material-ui/core'
import { StarRate } from '@material-ui/icons'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

interface ICardContribution {
  /** Quantidade de contribuições */
  amount: number
  /** Texto */
  contributionText: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amount: {
      fontSize: '1em',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
  }),
)

/**
 * Utilize para exibir determinadas contribuições em módulos do community
 */
export const CardContributionPure: FC<ICardContribution> = ({ amount, contributionText }) => {
  const classes = useStyles()

  return (
    <Tooltip title={contributionText}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '2px',
          alignItems: 'center',
        }}
      >
        <Box style={{ marginTop: '5px' }}>
          <StarRate />
        </Box>

        <Typography variant="h5" component="span" className={classes.amount}>
          {amount}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default CardContributionPure
