import React, { createContext, useState, useContext, useCallback } from 'react'
import FeedbackDialog from '../../components/Dialog/FeedbackDialog'
import FeedbackSentenceDialog from '../../components/Dialog/ChangeSentenceDialog'
import UploadDialog from '../../components/Dialog/UploadDialog'
import FeedbackTagVideoDialog from '../../components/Dialog/FeedbackTagDialog'
import FeatureToggleDialog from '../../components/Dialog/FeatureToggleDialog'
import ReportSegmentDialog from '../../components/Dialog/ReportSegmentDialog'

type DialogNames =
  | 'feedback'
  | 'feedbackSentence'
  | 'feedbackTagVideo'
  | 'upload'
  | 'featureToggle'
  | 'reportSegmentDialog'
  | 'outro'

type DialogsState = Record<DialogNames, boolean>

const initialState: DialogsState = {
  feedback: false,
  feedbackSentence: false,
  feedbackTagVideo: false,
  upload: false,
  featureToggle: false,
  reportSegmentDialog: false,
  outro: false,
}

type DialogContext = [DialogsState, React.Dispatch<React.SetStateAction<DialogsState>>]

const Context = createContext<DialogContext>([initialState, () => ({})])

export const ProviderDialog: React.FC = ({ children }) => {
  const [dialogsState, setDialogState] = useState(initialState)
  return (
    <Context.Provider value={[dialogsState, setDialogState]}>
      {children}
      {dialogsState.feedback && <FeedbackDialog />}
      {dialogsState.feedbackSentence && <FeedbackSentenceDialog />}
      {dialogsState.feedbackTagVideo && <FeedbackTagVideoDialog />}
      {dialogsState.upload && <UploadDialog />}
      {dialogsState.featureToggle && <FeatureToggleDialog />}
      {dialogsState.reportSegmentDialog && <ReportSegmentDialog />}
    </Context.Provider>
  )
}

export default (dialogName: DialogNames): [boolean, () => void] => {
  const [dialogsState, setDialogState] = useContext(Context)

  const toggleDialog = useCallback(() => {
    setDialogState((state) => {
      const newState = Object.assign({}, state, {
        [dialogName]: !state[dialogName],
      })
      return newState
    })
  }, [dialogName, setDialogState])

  const isOpen = !!dialogsState[dialogName]

  return [isOpen, toggleDialog]
}
