import { DocumentData, DocumentReference } from '@firebase/firestore'
import { useUser } from 'reactfire'
import { IUser, UserId } from 'collections'
import { useFirestoreData, useFirestoreRef } from './useFirestoreData'

/**
 * Pega a referencia de um documento do firestore de um usuário especifico,
 * ou do usuário logado atualmente
 * @param userId Usuario que iremos pegar o ref, o padrão é o user logado
 */
export const useFsUserDocRef = (userId?: UserId): DocumentReference<DocumentData> => {
  const fbUser = useUser<firebase.default.User>().data
  const ref = useFirestoreRef(`users/${userId || fbUser?.uid}`)

  return ref
}

/**
 * Pega os dados de um documento do firestore de dados de um usuário especifico,
 * ou do usuário logado atualmente
 * @param userId Usuario que iremos pegar data, o padrão é o user logado
 */
export const useFsUserDocData = (userId?: UserId): IUser => {
  const fbUser = useUser<firebase.default.User>()
  const ref = useFsUserDocRef(userId || fbUser.data?.uid)
  const data = useFirestoreData(ref.path).data as IUser
  if (data) data.displayName = fbUser.data?.displayName || ''
  return data
}
