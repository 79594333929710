interface IUseLocalStorageFNS {
  setLocalStorageValue: (key: string, value: string) => void
  getLocalStorageValue: (key: string) => string | null
}
export const useLocalStorage = (): IUseLocalStorageFNS => {
  const setLocalStorageValue = (key: string, value: string) => {
    localStorage.setItem(key, value)
  }

  const getLocalStorageValue = (key: string) => {
    return localStorage.getItem(key)
  }

  return { getLocalStorageValue, setLocalStorageValue }
}
