import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PeapleIcon from '@material-ui/icons/People'
import WarningIcon from '@material-ui/icons/Warning'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LinkIcon from '@material-ui/icons/Link'
import LaunchIcon from '@material-ui/icons/Launch'
import GetAppIcon from '@material-ui/icons/GetApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import UndoIcon from '@material-ui/icons/Undo'
import EditIcon from '@material-ui/icons/Edit'
import MovieFilterIcon from '@material-ui/icons/MovieFilter'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import useIntl from '../../../hooks/useIntl'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core'
import { red, green } from '@material-ui/core/colors'
import MultiSelectSentenceCategory from '../../../components/MultiSelectSentenceCategory'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import FramePlayer from '../../../components/FramePlayer'
import { ITimelineCursorState } from 'collections'

const RootStyled = styled(Box)`
  width: 100%;
  height: 100%;
`

const FixedAspectRatioContainer = styled(Box)`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`

const FixedAspectRatioContent = styled(Box)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`

const VideoStyled = styled.video`
  /** O fundo do video deve ser preto independente do tema */
  background: #000;
  width: 100%;
  height: 100%;
`

const FabStyled = styled(Fab)`
  && {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
`
const RightFabStyled = styled(Fab)`
  && {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`

const FullHeightFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const StyledIcon = styled(ListItemIcon)`
  min-width: 30px;
`

const StyledSelect = styled(Select)`
  && div {
    padding: 8px 32px 8px 16px;
  }
`

const StyledInfoBox = styled(Box)`
  && {
    margin: 2.5px 0px;
  }
`

interface IKpData {
  face: [][]
  hand_l: [][]
  hand_r: [][]
  pose: [][]
}

interface IVideoCard {
  /** Estado atual do vídeo */
  _state: string
  /** Id do Cliente solicitante */
  clientId: string
  /** Grupo do corpus que o vídeo foi utilizado */
  corpusGroup: string
  /** Erros do vídeo */
  errors: {
    [userId: string]: string
  } | null
  /** Indicador se precisa criar sinais */
  needCreateSign: boolean
  /** Quantidade de segmentos do vídeo */
  numberOfSegments?: number
  /** Quantidade de vezes que o vídeo foi segmentado */
  numberOfSegmentations: number
  /** Quantidade de segmentos que faltam ser processados */
  segmentsToProcess: number | null
  /** Lista de sinais vinculados ao vídeo */
  signs?: string[]
  /** Sentença que será exibida como titulo do card */
  sentence?: string
  /** Origem da sentença */
  sentenceOrigin?: string
  /** Categoria da sentença */
  sentenceCategory: string[] | null
  /** Nome do usuário que representa este vídeo */
  displayName: string
  /** Path do vídeo que será adicionado ao player */
  videoSrc?: string
  /** Icone que será aplicado no botão */
  buttonIcon: JSX.Element
  /** Referencia do vídeo */
  videoRef?: React.Ref<HTMLVideoElement>
  /** Referencia do vídeo como tag*/
  videoElement?: HTMLVideoElement
  /** Indica que o vídeo está em processo de carregamento ou buffering */
  isLoading: boolean
  /** Data de criação deste vídeo */
  createdAt: Date
  /** Usuário que criou o vídeo */
  createdBy: string
  /** Usuários que segmentaram o video */
  segmentedBy?: string[] | null
  /** Indicador se o vídeo foi criado sob demanda (Sinal sob demanda) */
  createdOnDemand?: boolean
  /** Indicador se o vídeo está no corpus mais recente */
  usedOnTrain?: boolean
  /** Indicador se está habilitado a edição das informações do vídeo */
  editInfo: boolean
  /** Categorias das sentenças*/
  categories?: string[]
  /** Origens das sentenças*/
  origins?: string[]
  /** Dialog para confirmar edição dos dados do vídeo */
  confirmSaveEditedInfoDialog?: boolean
  /** Elemento do menu de opções para voltar o estado do vídeo */
  videoOptionsMenuEl?: Element | null
  /** Estado para qual o vídeo sera retornado */
  backVideoState?: string | null
  /** Condicional para exibir o dialog de confirmação específico */
  confirmationDialog?: string | null
  /** Condicional para verificar se o usuário é um mantenedor */
  isMaintainer?: boolean
  /** Condicional para verficiar se o canvas está sendo exibido*/
  displayingCanvas?: boolean
  /** Dados dos keypoints */
  keypointsData?: IKpData
  /** Lista de frames do vídeo */
  framesData?: string[]
  /** Condicional para verificar se o frame player está sendo executado */
  isPlayingFramePlayer?: boolean
  /** Cursor da timeline */
  timelineCursor?: ITimelineCursorState
  /** id do vídeo */
  id: string
  /** Callback para estado do vídeo alterado */
  onToggleVideoState?: () => void
  /** Botão de copiar link foi clicado */
  onClickCopyLinkButton?: () => void
  /** Função para alterar o texto da sentença */
  handleChangeSentence?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** Função para alterar o ID do cliente */
  handleChangeClientId?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** Função para habilitar edição das informações */
  handleEditInfo?: () => void
  /** Função para salvar as novas informações do vídeo no banco de dados */
  handleClickSave?: () => void
  /** Função para cancelar edição das informações */
  handleClickCancel?: () => void
  /** Função para alterar a categoria das sentenças */
  handleChangeCategories?: (value: string[]) => void
  /** Função para alterar a origem das sentenças*/
  handleChangeOrigin?: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  /** Função para recusar edição dos dados do vídeo */
  handleDisagreeConfirmation?: () => void
  /** Função para aceitar edição dos dados do vídeo */
  handleAgreeConfirmation?: () => void
  /** Função para redirecionar para a página do processar vídeo de acordo com o vídeo selecionado */
  handleGoToProcessVideo?: () => void
  /** Função para redirecionar para a página do htube de acordo com o sinal selecionado */
  handleGoToHtubeSignInfo?: (sign: string) => void
  /** Função para baixar arquivo .mp4 do vídeo*/
  handleDownloadVideoFile?: () => void
  /** Função para fechar o menu de opções para voltar o estado do vídeo */
  handleCloseVideoOptionsMenu?: () => void
  /** Função para abrir o menu de opções para voltar o estado do vídeo */
  handleOpenVideoOptionsMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Função para voltar o estado do vídeo */
  handleBackVideoState?: (newState: 'RECORDED' | 'DELETED' | null) => void
  /** Função para voltar o estado do vídeo */
  handleEditSegmentation?: () => void
  /** Função para editar a segmentação do vídeo */
  handleOpenEditSegment?: () => void
  /** Função para abrir o dialog de confirmação de ação */
  toggleOpenConfirmationDialog?: () => void
  /** Função para retornar o vídeo a um estado anterior */
  saveBackVideoState?: () => Promise<void>
  /** Função para ativar a visualização de keypoints */
  handleActivateKeypoints?: () => void
  /** Função para desativar a visualização de keypoints */
  handleDeactivateKeypoints?: () => void
  /** Função para exibir mensagem de erro do frame player */
  handleShowError?: () => void
  /** Função para mudar frames do segmento */
  handleOnChangedTime?: (value: number) => void
}

/**
 * Card com informações sobre um determinado vídeo
 */
const VideoCard: React.FC<IVideoCard> = ({
  id,
  _state,
  clientId,
  corpusGroup,
  errors,
  needCreateSign,
  numberOfSegmentations,
  segmentsToProcess,
  sentenceCategory,
  createdOnDemand,
  numberOfSegments,
  sentenceOrigin,
  signs,
  sentence,
  displayName,
  videoSrc,
  buttonIcon,
  videoRef,
  videoElement,
  onToggleVideoState,
  isLoading,
  createdAt,
  createdBy,
  segmentedBy,
  usedOnTrain,
  editInfo,
  categories,
  origins,
  confirmSaveEditedInfoDialog,
  videoOptionsMenuEl,
  backVideoState,
  confirmationDialog,
  isMaintainer,
  displayingCanvas,
  keypointsData,
  framesData,
  isPlayingFramePlayer,
  timelineCursor,
  onClickCopyLinkButton,
  handleChangeSentence,
  handleChangeClientId,
  handleEditInfo,
  handleClickSave,
  handleClickCancel,
  handleChangeCategories,
  handleChangeOrigin,
  handleDisagreeConfirmation,
  handleAgreeConfirmation,
  handleGoToProcessVideo,
  handleGoToHtubeSignInfo,
  handleDownloadVideoFile,
  handleCloseVideoOptionsMenu,
  handleOpenVideoOptionsMenu,
  handleBackVideoState,
  handleEditSegmentation,
  handleOpenEditSegment,
  toggleOpenConfirmationDialog,
  saveBackVideoState,
  handleActivateKeypoints,
  handleDeactivateKeypoints,
  handleShowError,
  handleOnChangedTime,
}) => {
  const intl = useIntl()

  const actionButtons = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          margin: '20px 0px 0px auto',
        }}
      >
        {!editInfo && (
          <Button variant="contained" color="primary" onClick={handleEditInfo}>
            {intl.get('pages.videoManager.video.editInfo')}
          </Button>
        )}
        {editInfo && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: 'auto',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                alignSelf: 'flex-end',
                background: red[700],
                color: '#fff',
              }}
              onClick={handleClickCancel}
            >
              {intl.get('pages.videoManager.video.cancelChanges')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSave}
              style={{
                alignSelf: 'flex-end',
                background: green[700],
                color: '#fff',
                marginLeft: '5px',
              }}
            >
              {intl.get('pages.videoManager.video.saveChanges')}
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <RootStyled>
      <Card>
        <Box padding={1} display="flex" justifyContent="space-between">
          {/** Nome do usuário (esquerda) */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <PeapleIcon fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {displayName}
            </Typography>
          </Box>

          {/** Data e botão de copiar link (direita)*/}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <DateRangeIcon fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 5 }}>
              {createdAt.toLocaleString('en-US', {
                hour12: false,
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
            <Tooltip style={{ marginLeft: 10 }} title={intl.get('pages.signSentence.videoCard.copyAbsolute')}>
              <IconButton onClick={onClickCopyLinkButton} size="small">
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box>
          {/**
           * Aqui utilizamos a mesma logica dos players de videos em formato embed (iframe),
           * onde envolvemos uma div absoluta dentro de uma div com um hack de aspect ratio.
           */}
          <FixedAspectRatioContainer>
            <FixedAspectRatioContent>
              {/** Não existe um videoSrc, então o vídeo está quebrado! */}
              {!videoSrc && (
                <FullHeightFlex>
                  <WarningIcon />
                  <Typography>{intl.get('messages.videoNotFound')}</Typography>
                </FullHeightFlex>
              )}

              {/** Existe um video src e não está carregando, então exibimos o vídeo */}
              {videoSrc && (
                <>
                  {/** Iremos exibir os vídeos sem controle e mutado, assim as pessoas não saberão
                   * que estamos gravando o audio */}
                  <VideoStyled
                    ref={videoRef}
                    src={videoSrc}
                    muted
                    style={{
                      display: keypointsData && framesData && displayingCanvas ? 'none' : '',
                    }}
                  />

                  {keypointsData && framesData && displayingCanvas && (
                    <FramePlayer
                      isPlayingFromProps={isPlayingFramePlayer}
                      onError={handleShowError!}
                      onReady={() => ({})}
                      onChangedTime={handleOnChangedTime!}
                      timelineCursor={timelineCursor}
                      frameRatio={30}
                      framesDuration={Object.keys(framesData!).length}
                      timeFrame={30}
                      src={videoSrc}
                      startFrame={0}
                      communityModule={'segmentVideo'}
                      keypointsData={keypointsData}
                      framesVideo={framesData}
                      drawKeypoints={isPlayingFramePlayer}
                      showPlayerControls={false}
                      videoElement={videoElement}
                    />
                  )}

                  {/**
                   * Esta div bloqueia os cliques com o botão direito,
                   * isso evita que o usuário habilite os controles do HTMLVideoElement
                   * clicando em uma div ao invés de um video
                   * */}
                  <FullHeightFlex style={{ position: 'absolute', top: 0, left: 0 }} />

                  {/**
                   * Controles do nosso mini player -
                   * Bloqueamos o botão durante o carregamento, pois em alguns csos de buffering
                   * os estados podem dar uma bugada voltando para playing, a maioria dos players fazem isso
                   */}
                  <FabStyled disabled={isLoading} onClick={onToggleVideoState}>
                    {buttonIcon}
                  </FabStyled>

                  <RightFabStyled
                    aria-controls="video-options"
                    disabled={isLoading}
                    onClick={handleOpenVideoOptionsMenu}
                  >
                    <MoreVertIcon />
                  </RightFabStyled>
                  <Menu
                    id="video-options"
                    anchorEl={videoOptionsMenuEl}
                    open={Boolean(videoOptionsMenuEl)}
                    onClose={handleCloseVideoOptionsMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{ horizontal: 'center', vertical: 35 }}
                  >
                    <MenuItem onClick={() => handleBackVideoState && handleBackVideoState('DELETED')}>
                      <StyledIcon>
                        <UndoIcon fontSize="small" />
                      </StyledIcon>
                      {intl.get('messages.backToRecording')}
                    </MenuItem>
                    {_state !== 'EXTRACTED_FRAMES' && (
                      <MenuItem onClick={() => handleBackVideoState && handleBackVideoState('RECORDED')}>
                        <StyledIcon>
                          <UndoIcon fontSize="small" />
                        </StyledIcon>
                        {intl.get('messages.backToSegmentation')}
                      </MenuItem>
                    )}
                    {numberOfSegments !== null && numberOfSegments !== 0 && (
                      <MenuItem onClick={() => handleEditSegmentation && handleEditSegmentation()}>
                        <StyledIcon>
                          <EditIcon fontSize="small" />
                        </StyledIcon>
                        {intl.get('messages.editSegmentation')}
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleGoToProcessVideo}>
                      <StyledIcon>
                        <LaunchIcon fontSize="small" />
                      </StyledIcon>
                      {intl.get('pages.videoManager.others.processVideo')}
                    </MenuItem>
                    <MenuItem onClick={handleDownloadVideoFile}>
                      <StyledIcon>
                        <GetAppIcon fontSize="small" />
                      </StyledIcon>
                      {intl.get('pages.videoManager.others.downloadVideoFile')}
                    </MenuItem>
                    {isMaintainer && !displayingCanvas && (
                      <MenuItem onClick={handleActivateKeypoints}>
                        <StyledIcon>
                          <MovieFilterIcon fontSize="small" />
                        </StyledIcon>
                        {intl.get('pages.videoManager.others.activateKeypoints')}
                      </MenuItem>
                    )}
                    {isMaintainer && displayingCanvas && (
                      <MenuItem onClick={handleDeactivateKeypoints}>
                        <StyledIcon>
                          <MovieFilterIcon fontSize="small" />
                        </StyledIcon>
                        {intl.get('pages.videoManager.others.deactivateKeypoints')}
                      </MenuItem>
                    )}
                  </Menu>

                  {/** Existe um video src e está carregando, então exibimos o loading  */}
                  {isLoading && (
                    <FullHeightFlex style={{ position: 'absolute', top: 0, left: 0 }}>
                      <CircularProgress />
                    </FullHeightFlex>
                  )}
                </>
              )}
            </FixedAspectRatioContent>
          </FixedAspectRatioContainer>

          <Box padding={1} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <TextField
              id="sentence"
              label={intl.get('pages.videoManager.video.sentence')}
              variant="filled"
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px 0px 0px 0px',
              }}
              value={sentence}
              onChange={handleChangeSentence}
              disabled={!editInfo}
            />
            <TextField
              id="clientId"
              label={intl.get('pages.videoManager.video.clientId')}
              variant="filled"
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px 0px 0px 0px',
              }}
              value={clientId}
              onChange={handleChangeClientId}
              disabled={!editInfo}
            />
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Categorias do vídeo:{' '}
                {editInfo
                  ? sentenceCategory &&
                    categories &&
                    handleChangeCategories && (
                      <MultiSelectSentenceCategory
                        options={categories}
                        value={sentenceCategory}
                        handleChangeOptions={handleChangeCategories}
                      />
                    )
                  : sentenceCategory
                  ? sentenceCategory.join(', ')
                  : ''}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Origem do vídeo:{' '}
                {editInfo
                  ? sentenceOrigin &&
                    origins && (
                      <StyledSelect
                        variant="outlined"
                        value={sentenceOrigin}
                        disabled={isLoading}
                        onChange={handleChangeOrigin}
                      >
                        {origins.map((origin) => (
                          <MenuItem key={origin} className={`notranslate`} value={origin}>
                            {origin}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    )
                  : sentenceOrigin
                  ? sentenceOrigin
                  : ''}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Estado:{' '}
                {_state == 'QUARANTINE'
                  ? 'Quarentena'
                  : _state == 'MISSING_SIGN'
                  ? 'Faltando sinal'
                  : _state == 'EXTRACTED_FRAMES'
                  ? 'Não segmentado'
                  : _state == 'SEGMENTED'
                  ? 'Segmentado'
                  : _state == 'PROCESSED'
                  ? 'Processado'
                  : 'Validado'}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Corpus: {corpusGroup}
              </Typography>
            </StyledInfoBox>
            <Divider />
            {errors && (
              <>
                <StyledInfoBox>
                  <Typography className="notranslate" variant="body1" color="textSecondary">
                    Erros do vídeo:
                    {Object.entries(errors).map(([, error]) => (
                      <>
                        <Box
                          style={{
                            margin: '8px 0px 8px 16px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography style={{ marginRight: '8px' }}>{error}</Typography>
                        </Box>
                        <Divider
                          style={{
                            margin: '8px 0px 8px 16px',
                          }}
                        />
                      </>
                    ))}
                  </Typography>
                </StyledInfoBox>
                <Divider />
              </>
            )}
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Precisa criar sinal: {needCreateSign ? 'Sim' : 'Não'}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Segmentações: {numberOfSegmentations}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Segmentos: {numberOfSegments}
              </Typography>
            </StyledInfoBox>
            <Divider />
            {segmentsToProcess != null && (
              <>
                <StyledInfoBox>
                  <Typography className="notranslate" variant="body1" color="textSecondary">
                    Segmentos para processar: {segmentsToProcess}
                  </Typography>
                </StyledInfoBox>
                <Divider />
              </>
            )}
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Criado sob demanda: {createdOnDemand ? 'Sim' : 'Não'}
              </Typography>
            </StyledInfoBox>
            <Divider />
            {signs && signs.length > 0 && (
              <>
                <StyledInfoBox>
                  <Typography className="notranslate" variant="body1" color="textSecondary">
                    Sinais vinculados:
                    {signs.map((sign) => (
                      <>
                        <Box
                          style={{
                            margin: '8px 0px 8px 16px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography style={{ marginRight: '8px' }}>{sign}</Typography>
                          <IconButton
                            style={{
                              padding: '5px',
                            }}
                            onClick={() => handleGoToHtubeSignInfo && handleGoToHtubeSignInfo(sign)}
                          >
                            <LaunchIcon fontSize="small" />
                          </IconButton>
                        </Box>
                        <Divider
                          style={{
                            margin: '8px 0px 8px 16px',
                          }}
                        />
                      </>
                    ))}
                  </Typography>
                </StyledInfoBox>
              </>
            )}
            {segmentedBy && segmentedBy.length > 0 && (
              <>
                <StyledInfoBox>
                  <Typography className="notranslate" variant="body1" color="textSecondary">
                    Segmentador por: {segmentedBy.join(', ')}.
                  </Typography>
                </StyledInfoBox>
                <Divider />
              </>
            )}
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Vídeo está no corpus mais recente: {usedOnTrain ? 'Sim' : 'Não'}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                Criado por: {createdBy}
              </Typography>
            </StyledInfoBox>
            <Divider />
            <StyledInfoBox>
              <Typography className="notranslate" variant="body1" color="textSecondary">
                id: {id || ''}
              </Typography>
            </StyledInfoBox>
            <Divider />
            {actionButtons()}
          </Box>
        </Box>
      </Card>
      {confirmSaveEditedInfoDialog && (
        <Dialog
          open={confirmSaveEditedInfoDialog}
          onClose={handleDisagreeConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.get('pages.videoManager.video.confirmChanges')}</DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDisagreeConfirmation}
              style={{
                background: red[700],
                color: '#fff',
              }}
            >
              {intl.get('pages.videoManager.video.refuseChanges')}
            </Button>
            <Button
              style={{
                background: green[700],
                color: '#fff',
              }}
              autoFocus
              onClick={handleAgreeConfirmation}
            >
              {intl.get('pages.videoManager.video.acceptChanges')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ConfirmationDialog
        open={confirmationDialog === 'backVideoState'}
        toggleOpen={() => toggleOpenConfirmationDialog && toggleOpenConfirmationDialog()}
        handleYes={() => {
          saveBackVideoState && saveBackVideoState()
        }}
        title={intl.get('messages.backVideoStatus')}
        subtitle={intl.get('messages.wantReturnVideo', {
          queue:
            backVideoState === 'DELETED' ? intl.get('messages.recordingQueue') : intl.get('messages.segmentationQueue'),
        })}
      />
      <ConfirmationDialog
        open={confirmationDialog === 'editSegmentation'}
        toggleOpen={() => toggleOpenConfirmationDialog && toggleOpenConfirmationDialog()}
        handleYes={() => {
          handleOpenEditSegment && handleOpenEditSegment()
        }}
        title={intl.get('messages.editSegmentation')}
        subtitle={intl.get('messages.changesWillBeSavedBeforeProceeding')}
      />
    </RootStyled>
  )
}

export default VideoCard
