import React, { useEffect, useState, useCallback } from 'react'
import { useGlobal } from 'reactn'
import styled from 'styled-components'
import { useRoute } from 'wouter'
import { useStorage, useFirestore } from 'reactfire'
import { differenceInSeconds } from 'date-fns'
import ViewWeekIcon from '@material-ui/icons/ViewWeek'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import Layout from '../../../components/Layout'
import useSnackbar from '../../../services/hooks/useSnackbar'
import useModuleSegmentVideo from '../../../hooks/useModuleSegmentVideo'
import logger from '../../../services/logger'
import FramePlayer from '../../../components/FramePlayer'
import Timeline from '../../../components/Timeline'
import {
  LanguageKeypair,
  WorkspaceId,
  VideoId,
  IVideo,
  IGlobalAttr,
  ITimelineRegion,
  ISegment,
  CommunityShortcuts,
  getPathFramesJson,
  Timestamp,
  updateVideoFlags,
  DocumentReferenceGeneric,
} from 'collections'
import { routes } from '../../../community'
import Paper from '@material-ui/core/Paper'
import useContributionMetadata from '../../../hooks/useContributionMetadata'
import { green, red } from '@material-ui/core/colors'
import ContributionMetadataView from '../../../components/ContributionMetadataView'
import UserMenu from '../../../components/UserMenu'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import useDialog from '../../../services/hooks/useDialog'
import useJobMeter from '../../../services/hooks/useJobMeter'
import useTimelineRegions from '../../../hooks/useTimelineRegions'
import useContributionsLogger from '../../../services/hooks/useContributionsLogger'
import useIntl from '../../../hooks/useIntl'
import useShortcuts from '../../../hooks/useShortcuts'
import { useFirestoreData } from '../../../hooks/useFirestoreData'
import { doc, serverTimestamp, where, query, getDocs, Query, DocumentData } from '@firebase/firestore'
import { ref, getDownloadURL } from '@firebase/storage'
import { getCollectionReference, getDocumentReference } from 'collections'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'
import CardMessage from '../../../components/CardMessage'
import { getPathVideoKeypointsFile } from 'collections'
import useSegmentationModel from '../../../hooks/useSegmentationModel'
const { log } = logger('Segment')

const VideoContainer = styled(Grid)`
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

const MainGrid = styled(Grid)`
  overflow-y: hidden;
  top: 0px;
  padding-top: 20px;
`

interface ISegmentVideoProps {
  path?: string
  videoIdParam?: string
  userIdParam?: string | null
  setIsSegmenting?: React.Dispatch<React.SetStateAction<boolean>>
}

const timeToReleaseVideo = 3600

/**
 * Modulo de etiquetação de sinais em vídeos
 */
const SegmentVideo: React.FC<ISegmentVideoProps> = ({ videoIdParam, userIdParam, setIsSegmenting }) => {
  const [url, setUrl] = useState('')
  const { refreshVideo, save } = useModuleSegmentVideo()
  const showSnackbar = useSnackbar()
  const [timeFrame, setTimeFrame] = useState(0)
  const [userContribution, setUserContribution] = useState(0)
  const [, routeParams] = useRoute(routes.segmentASign)
  const storage = useStorage()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const videoId: VideoId = videoIdParam ? videoIdParam : routeParams?.videoId || ''

  const userId: string = userIdParam ? userIdParam : routeParams?.user || 'segment'
  const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
  const video = useFirestoreData(videoRef.path).data as IVideo
  const firestore = useFirestore()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const setFsVideoRef = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')[1]
  const setFsVideoDocData = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')[1]
  const [contributionMetadataState, contributionMetadataFns] = useContributionMetadata()
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const intl = useIntl()
  const lastSegmentingTime = video.isSegmenting?.lastUpdate?.toDate() || new Date()
  const isUnlockedByTime = differenceInSeconds(new Date(), lastSegmentingTime) > timeToReleaseVideo
  const handleReportSegmentClick = useDialog('reportSegmentDialog')[1]
  const { endJobMeter, resetJobMeter } = useJobMeter('segmentVideo')
  const contributionsLoggerFns = useContributionsLogger()[1]

  const [, timelineRegionsFns] = useTimelineRegions()
  const { shortcutEvent } = useShortcuts('segmentVideo')
  const hasPermissionOnModule = useCheckHasPermission('segmentVideo')
  const [isSaving, setIsSaving] = useState(false)

  const [, predictSegmentation] = useSegmentationModel(fsUser.workspace.id as WorkspaceId)
  const [isLoadingSegmentation, setIsLoadingSegmentation] = useState(false)

  useEffect(() => {
    if (!video.isSegmenting?.value || isUnlockedByTime) {
      log('Video liberado para segmentação, vinculando usuario')
      contributionMetadataFns.setMetadata()
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
      updateVideoFlags(videoRef, {
        isSegmenting: {
          value: true,
          user: fsUserRef,
          lastUpdate: serverTimestamp() as Timestamp,
        },
      })
      timelineRegionsFns.clear()
      resetJobMeter()
    } else {
      log(`O video ja esta sendo segmentado por: ${video.isSegmenting.user?.id}`)
      contributionMetadataFns.setMetadata({
        timeToRelease: timeToReleaseVideo,
        contributor: video.isSegmenting.user,
        lastUpdate: video.isSegmenting.lastUpdate?.toDate(),
        isLocked: fsUserRef.id !== video.isSegmenting.user?.id,
        isTimerReachedZero: false,
      })
      if (fsUserRef.id !== video.isSegmenting.user?.id && userId == 'segment')
        showSnackbar(intl.get('pages.segmentVideo.video.someoneIsSegmenting'), {
          variant: 'warning',
        })
    }
    setFsVideoDocData(video)
    setFsVideoRef(videoRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video])

  useEffect(() => {
    if (contributionMetadataState?.isTimerReachedZero) {
      refreshVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributionMetadataState])

  const getSegmentsOfVideo = useCallback(async () => {
    const userContributionRef = doc(firestore, 'users', userId)
    const segmentsSnapshot = await getDocs(
      query(
        getCollectionReference(fsUser.workspace, 'segments') as Query<DocumentData>,
        where('video', '==', videoRef),
        where('createdBy', '==', userContributionRef),
      ),
    )

    const regionsSegments: ITimelineRegion[] = []
    segmentsSnapshot.docs.forEach(async (segmentSnapshot) => {
      const segmentData = segmentSnapshot.data() as ISegment
      regionsSegments.push({
        id: segmentSnapshot.id || (segmentData.startFrame + segmentData.endFrame).toString(),
        ref: segmentSnapshot.ref,
        startFrame: segmentData.startFrame,
        endFrame: segmentData.endFrame,
        type: segmentData.type,
        text: segmentData.text,
        representation: '',
      })
    })
    timelineRegionsFns.set(regionsSegments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userId != 'segment') {
      getSegmentsOfVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // seta a contribuição de segmentVideo do usuário
  useEffect(() => {
    if (fsUser.contribs) {
      const contribs = fsUser.contribs[fsUser.workspace.id as WorkspaceId]
      const language = `${fsUser.oralLanguageId}-${fsUser.signLanguageId}` as LanguageKeypair

      const totalContributionsSegmentVideo = contribs?.segmentVideo?.[language] || 0

      if (totalContributionsSegmentVideo) setUserContribution(totalContributionsSegmentVideo)
    }
  }, [fsUser.contribs, fsUser.oralLanguageId, fsUser.signLanguageId, fsUser.workspace.id])

  const getSegmentsByModel = async () => {
    showSnackbar(intl.get('messages.segmentingVideo'), { variant: 'info' })
    if (isLoadingSegmentation) return
    setIsLoadingSegmentation(true)
    try {
      const kpUrlString = getPathVideoKeypointsFile(fsUser.workspace.id, videoId)
      const kpUrlRef = ref(storage, kpUrlString)
      const kpUrl = await getDownloadURL(kpUrlRef)
      const kpVideoResult = await fetch(kpUrl)
      const kpVideo = await kpVideoResult.json()
      const keypointsData = kpVideo.data

      const keypointsToPredict: number[][][] = []

      for (let i = 0; i <= keypointsData.pose.length - 1; i++) {
        keypointsToPredict.push(keypointsData.pose[i].concat(keypointsData.hand_l[i]).concat(keypointsData.hand_r[i]))
      }
      if (kpVideo) {
        const segmentsPredictionResult = await predictSegmentation(keypointsToPredict, -1)
        if (segmentsPredictionResult) {
          const regions: ITimelineRegion[] = segmentsPredictionResult.map((movement) => {
            return {
              id: (Math.round(movement[0]) + Math.round(movement[1])).toString() || '',
              representation: 'Unknown',
              startFrame: movement[0],
              endFrame: movement[1],
              type: 'sign',
              text: '',
            } as ITimelineRegion
          })
          timelineRegionsFns.set(regions)
        }
        showSnackbar(intl.get('messages.successfullySegmentedVideo'), {
          variant: 'success',
        })
      }
    } catch (err) {
      showSnackbar(intl.get('messages.failedToSegmentVideo'), {
        variant: 'error',
      })
    } finally {
      setIsLoadingSegmentation(false)
    }
  }

  // Definição do video
  useEffect(() => {
    log(`Vídeo atual: ${videoId}`)
    if (video && videoId) {
      // Pega o video principal
      const call = async () => {
        /**  1000 representa segundo em milisegundos e appConfig.frameRate a
         *    quantidade de frames por segundo
         */
        const calculateFrameTime = 1000 / appConfig.frameRate

        setTimeFrame(calculateFrameTime)
        const videoUrlRefString = getPathFramesJson(fsUser.workspace.id, videoId, video.duplicateOf || '')

        const videoUrlRef = ref(storage, videoUrlRefString)
        const videoUrl = await getDownloadURL(videoUrlRef)
        setUrl(videoUrl)
      }
      call()
    }
  }, [appConfig.frameRate, fsUser.workspace.id, storage, video, videoId])

  /**
   * Deu alguma falha ao carregar o player
   * @param duration Duração do vídeo carregado em frames
   */
  const onFramePlayerError = () => {
    showSnackbar(intl.get('pages.segmentVideo.video.failToLoadVideoFrames'), {
      variant: 'error',
    })
  }

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const handleNextVideoButtonClick = () => {
    handleReportSegmentClick()
  }

  const handleSaveSegmentClick = () => {
    if (isSaving) return
    setIsSaving(true)
    if (userId == 'segment') endJobMeter()
    save(userId != 'segment', setIsSegmenting, setIsSaving)
    if (userId == 'segment') {
      contributionsLoggerFns.update(video.sentence, 1, 'segmentVideo')
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    return () => {
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
      updateVideoFlags(videoRef, {
        isSegmenting: {
          value: false,
          user: fsUserRef,
          lastUpdate: serverTimestamp() as Timestamp,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    switch (shortcutEvent) {
      case CommunityShortcuts.saveSegments:
        handleSaveSegmentClick()
        break
      case CommunityShortcuts.skipSegments:
        refreshVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutEvent])

  const segmentedByIds = video.segmentedBy?.map((userRef: DocumentReferenceGeneric) => userRef.id)

  const numberOfSegmentations = video.numberOfSegmentations

  if ((segmentedByIds?.includes(fsUserRef.id) || numberOfSegmentations > 0) && userId == 'segment') {
    return (
      <Layout title={intl.get('modules.segmentVideo')} requiredModule={'segmentVideo'}>
        <Grid container direction="column" justifyContent="center" alignItems="center" item xs>
          <Typography variant="subtitle1" gutterBottom style={{ margin: 0 }}>
            {segmentedByIds?.includes(fsUserRef.id)
              ? intl.get('pages.segmentVideo.video.youAlreadySegmentedVideo')
              : intl.get('pages.segmentVideo.video.alreadySegmentedVideo')}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              refreshVideo()
            }}
          >
            {intl.get('messages.searchAgain')}
          </Button>
        </Grid>
      </Layout>
    )
  }

  if (video.errors && userId == 'segment') {
    return (
      <Layout title={intl.get('modules.segmentVideo')} requiredModule={'segmentVideo'}>
        <Grid container direction="column" justifyContent="center" alignItems="center" item xs>
          <Typography variant="subtitle1" gutterBottom style={{ margin: 0 }}>
            {intl.get('messages.videoReported')}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              refreshVideo()
            }}
          >
            {intl.get('messages.searchAgain')}
          </Button>
        </Grid>
      </Layout>
    )
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.segmentVideo')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout
      title={intl.get('modules.segmentVideo')}
      includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
      requiredModule={'segmentVideo'}
    >
      <Box width="100%" p={2}>
        <MainGrid container spacing={2} justifyContent="center">
          {/** Area de visualização do vídeo */}
          <Grid item xs md={8} sm={12}>
            <Grid
              item
              sm={12}
              md={12}
              style={{
                marginBottom: 10,
              }}
            >
              <Paper
                color="default"
                style={{
                  padding: 10,
                  border: '1px solid #cecece',
                  boxShadow: 'none',
                }}
              >
                <Typography className="notranslate" variant="h6">
                  {video
                    ? `${intl.get('pages.segmentVideo.video.sentence')}: ${video.sentence}`
                    : `${intl.get('pages.segmentVideo.video.loadingVideo')}...`}
                </Typography>
                <Typography variant="subtitle1">
                  {video
                    ? `${intl.get('pages.segmentVideo.video.segments')}: ${video.numberOfSegments || 0} | ${intl.get(
                        'messages.contributions',
                      )}: ${userContribution}`
                    : ' '}
                </Typography>
              </Paper>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <VideoContainer
                item
                md={7}
                sm={7}
                style={{
                  background: 'black',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <FramePlayer
                  onError={onFramePlayerError}
                  onReady={() => ({})}
                  onChangedTime={handleOnChangedTime}
                  frameRatio={30}
                  framesDuration={video.duration}
                  timeFrame={timeFrame}
                  src={url}
                  timelineCursor={timelineCursor}
                  startFrame={0}
                  communityModule={'segmentVideo'}
                />
              </VideoContainer>
            </Grid>

            <Grid
              item
              sm={12}
              md={12}
              style={{
                marginBottom: 10,
              }}
            >
              {/** Só exibiremos a linha do tempo quando o vídeo estiver carregado */}
              <Timeline
                timelineCursor={timelineCursor}
                timelineCursorFns={timelineCursorFns}
                timeFrame={timeFrame}
                sentence={video.sentence}
                editing={userId != 'segment'}
              />
            </Grid>

            <Grid item>
              {' '}
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent={userId != 'segment' ? 'flex-end' : 'space-between'}
                p={2}
              >
                {userId == 'segment' && (
                  <Box display="flex">
                    <Box mr={2}>
                      <Button
                        variant="outlined"
                        style={{ color: red['400'], borderColor: red['400'] }}
                        onClick={handleNextVideoButtonClick}
                      >
                        {intl.get('messages.report')}
                      </Button>
                    </Box>
                    <Box mr={2}>
                      <Button variant="outlined" onClick={() => refreshVideo()}>
                        {intl.get('messages.skip')}
                      </Button>
                    </Box>
                    <Box mr={2}>
                      <Button variant="outlined" onClick={() => getSegmentsByModel()} startIcon={<ViewWeekIcon />}>
                        {intl.get('messages.automaticSegmentation')}
                      </Button>
                    </Box>
                  </Box>
                )}
                {videoIdParam && setIsSegmenting && (
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setIsSegmenting(false)
                      }}
                    >
                      {intl.get('messages.cancel')}
                    </Button>
                  </Box>
                )}

                <Box mr={2}>
                  <Button
                    style={{ background: green[700], color: '#fff' }}
                    variant="contained"
                    onClick={handleSaveSegmentClick}
                    disabled={isSaving}
                  >
                    {intl.get('messages.save')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </MainGrid>
      </Box>
    </Layout>
  )
}

export default SegmentVideo
