import React, { FC } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useIntl from '../../hooks/useIntl'

interface IParameters {
  labelTextFiel?: string
  subtitle: string
  feedback: string
  isPending: boolean
  buttonText?: string
}

export interface IApplicationDialogProps {
  title: string
  open: boolean
  /**
   * IParameters {
    labelTextFiel: string;
    subtitle: string; 
    feedback: string;
    isPending: boolean;
    buttonText?: string;
  }
   * */
  options?: IParameters
  /** Se options for undefined, então pode passar conteúdo do seu dialog. */
  component?: JSX.Element
  /** Se options for undefined, então pode passar o elemento de actions do seu dialog. */
  actionsElement?: JSX.Element
  /**
   * Identifica o elemento (ou elementos) que rotula o elemento atual.
   * */
  labelledby: string
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  toggleOpen?: () => void
  send?: () => void
}
export const ApplicationDialogPure: FC<IApplicationDialogProps> = ({
  open,
  options,
  labelledby,
  handleChange,
  toggleOpen,
  send,
  component,
  actionsElement,
  title,
}) => {
  const intl = useIntl()
  return (
    <Dialog id={labelledby} open={open} onClose={toggleOpen} aria-labelledby={labelledby} maxWidth="sm" fullWidth>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      {options ? (
        <>
          <DialogContent>
            <DialogContentText>{options.subtitle}</DialogContentText>
            <TextField
              fullWidth
              label={options.labelTextFiel}
              multiline
              rows="5"
              margin="normal"
              variant="outlined"
              value={options.feedback}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={toggleOpen} color="primary" disabled={options.isPending}>
              {intl.get('messages.cancel')}
            </Button>
            <Button variant="contained" onClick={send} color="primary" disabled={options.isPending}>
              {options.buttonText ? options.buttonText : intl.get('messages.send')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent style={{ overflow: 'hidden' }}>{component}</DialogContent>
          {/** Mostraremos os actions parametrizados  */}
          {actionsElement && <DialogActions>{actionsElement}</DialogActions>}
        </>
      )}
    </Dialog>
  )
}

export default ApplicationDialogPure
