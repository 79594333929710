import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import styled from 'styled-components'

import { getBoundsByTag } from '../../../services/utils'
import logger from '../../../services/logger'
import useInteractable from '../../../services/hooks/useInteractable'
import TimelineItem from './Pure'
import { ITimelineRegion, IGlobalAttr } from 'collections'
import useTimelineRegions from '../../../hooks/useTimelineRegions'
import useTimelineTagHover from '../../../hooks/useTimelineTagHover'
import useCurrentRegionAction from '../../../hooks/useCurrentRegionAction'
import useIntl from '../../../hooks/useIntl'

const { log } = logger('TimelineItem')

const itemHeight = '80px'

const InteractableStyle = styled.div`
  position: absolute;
  left: 0px;
  height: ${itemHeight};
`

interface ITimelineItemProps {
  id: string | null
  isLocked: boolean
  tag: ITimelineRegion
  onStartDrag?: () => void
  onRemoved?: (id: string) => void
  onModified?: (id: string, region: ITimelineRegion) => void
  sentence?: string
  onPlayRegion?: (region?: ITimelineRegion) => void
  isPlaying?: boolean
  text?: string | null
  cursorPosition?: number
  editing: boolean
}

const TimelineItemUse: React.FC<ITimelineItemProps> = ({
  id,
  isLocked,
  tag,
  onModified,
  onRemoved,
  sentence,
  onPlayRegion,
  isPlaying,
  text,
  cursorPosition,
  editing,
}) => {
  const [minX, setMinX] = useState(0)
  const [maxX, setMaxX] = useState(0)
  const [x, setX] = useState(0)
  const [width, setWidth] = useState(0)
  const [isDragOver, setIsDragOver] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isDropActive, setIsDropActive] = useState(false)
  const [isDropped, setIsDropped] = useState(false)
  const timelineTagHoverFns = useTimelineTagHover()[1]
  const [fingerspellSign, setFingerspellSign] = useState(text || '_sign')
  const [isDragged, setIsDragged] = useState(false)
  const [selectIsOpened, setSelectIsOpened] = useState(false)
  // hooks
  const [timelineTags] = useTimelineRegions()
  const [currentRegionActionState] = useCurrentRegionAction()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelinePixelsSizeState] = useGlobal<IGlobalAttr, 'timelinePixelsSize'>('timelinePixelsSize')
  const intl = useIntl()
  const textPlaySegment = intl.get('components.timeline.timelineItem.playSegment')
  const textDeleteSegment = intl.get('components.timeline.timelineItem.deleteSegment')
  const textSign = intl.get('messages.sign')
  const textFingerspell = intl.get('messages.fingerspell')
  const textNotFingerspell = intl.get('components.timeline.timelineItem.notFingerspell')
  const [timelineLeftPixelsOffsetState] = useGlobal<IGlobalAttr, 'timelineLeftPixelsOffset'>('timelineLeftPixelsOffset')
  const [fsVideoDocData] = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')

  const durationFrames = fsVideoDocData?.duration || 0
  const timelinePixelsSize = timelinePixelsSizeState || 0
  const timelineLeftPixelsOffset = timelineLeftPixelsOffsetState || 0

  const { transform, elemRef, statusDragging, handleStatusDragging } = useInteractable({
    dropzone: true,
    contained: true,
    resizable: true,
    lockDragAxis: 'x',
    dropzoneAccept: 'sign',
    minX: minX,
    maxX: maxX,
    width: width,
    onDrop: () => {
      setIsDropped(true)
      setIsDragOver(false)
      setIsDropActive(false)
    },
    onDragEnter: () => {
      setIsDragOver(true)
    },
    onDragLeave: () => {
      setIsDragOver(false)
    },
    onDropActivate: () => {
      setIsDropActive(true)
    },
    onDropDeactivate: () => {
      setIsDropActive(false)
      setIsDropped(false)
    },
    baseX: x,
    minSize: Math.round((appConfig.minTagFramesDuration / durationFrames) * timelinePixelsSize),
    draggable: true,
  })

  const [refElement, setRefElement] = useState(elemRef)

  //
  // Comportamento do item interativo
  // ----------------------------------------------------------------------------

  useEffect(() => {
    if (statusDragging == 'begin') {
      setIsDragged(true)
    } else if (statusDragging == 'end') {
      const startPosition = x + transform.offsetX
      const endPosition = x + transform.offsetX + transform.width

      // Pega o frame da posição inicial
      const startFrame = (startPosition / timelinePixelsSize) * durationFrames

      // Pega a o frame da posiçao final
      const endFrame = (endPosition / timelinePixelsSize) * durationFrames
      handleStatusDragging('none')
      if (onModified) {
        onModified(id!, {
          ...tag,
          startFrame: Math.round(startFrame),
          endFrame: Math.round(endFrame),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDragging])

  useEffect(() => {
    setSelectIsOpened(false)
    setIsDragged(isPlaying || false)
  }, [isPlaying])

  //
  // Comportamento(s) e callback(s) padrão do componente
  // ----------------------------------------------------------------------------

  const handleButtonRemoveClicked = () => {
    if (editing) return
    if (onRemoved) onRemoved(id!)
    timelineTagHoverFns.set()
  }

  const handleOnMouseOver = () => {
    setIsMouseOver(true)
    timelineTagHoverFns.set({
      type: tag.type,
      representation: tag.representation,
      startFrame: tag.startFrame,
      endFrame: tag.endFrame,
    })
  }

  const handleOnMouseExit = () => {
    setIsMouseOver(false)
    timelineTagHoverFns.set()
  }

  // Altera o ref para interactable
  useEffect(() => {
    setRefElement(elemRef)
  }, [elemRef])

  const handleOnClickRegion = () => {
    if (!isDragged && !editing) {
      setSelectIsOpened(!selectIsOpened)
    }
    setIsDragged(false)
  }

  // O sinal foi solto num item da timeline, iremos anexar o novo valor

  useEffect(() => {
    if (isDropped && currentRegionActionState) {
      const { type, sign, representation } = currentRegionActionState

      if (type === 'sign' && sign) {
        if (onModified) {
          onModified(id!, {
            ...tag,
            startFrame: tag.startFrame,
            endFrame: tag.endFrame,
            type: 'sign',
            sign,
            representation,
          })
        }
      } else if (type === 'fingerspell' && representation) {
        if (onModified) {
          onModified(id!, {
            ...tag,
            id: tag.id,
            startFrame: tag.startFrame,
            endFrame: tag.endFrame,
            type: 'fingerspell',
            representation,
          })
        }
      } else if (type === 'create' && representation) {
        if (onModified) {
          onModified(id!, {
            ...tag,
            startFrame: tag.startFrame,
            endFrame: tag.endFrame,
            type: 'create',
            representation,
          })
        }
      }
    } else if (fingerspellSign && tag) {
      if (onModified) {
        onModified(id!, {
          ...tag,
          startFrame: tag.startFrame,
          endFrame: tag.endFrame,
          type: fingerspellSign !== '_sign' ? 'fingerspell' : 'sign',
          representation: 'unknown',
          text: fingerspellSign !== '_sign' ? fingerspellSign : '',
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegionActionState, id, isDropped, fingerspellSign])

  const handleSelectFingerspellSign = (text: string) => {
    setFingerspellSign(text)
    setSelectIsOpened(false)
  }
  /**
   * Tag, area width ou duração do vídeo mudou, iremos recalcular tudo
   */
  useEffect(() => {
    // Converte o frame inicial em posição
    const currentX = (tag.startFrame / durationFrames) * timelinePixelsSize

    // Converte o frame final em posição
    const endX = (tag.endFrame / durationFrames) * timelinePixelsSize

    // Calcula a largura
    const currentWidth = endX - currentX
    setX(currentX)
    setWidth(currentWidth)

    // Obtém os limites da tag
    const { minBoundX, maxBoundX } = getBoundsByTag(tag, timelineTags, durationFrames, timelinePixelsSize)
    setMaxX(maxBoundX + timelineLeftPixelsOffset)
    setMinX(minBoundX + timelineLeftPixelsOffset)

    log(`Tag recalculada: 
          x: ${currentX}
          width: ${currentWidth}
          maxX: ${maxBoundX + timelineLeftPixelsOffset}
          minX: ${minBoundX + timelineLeftPixelsOffset}
          `)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, timelineTags, timelinePixelsSize, timelineLeftPixelsOffset, durationFrames])

  const handlePlayRegion = (region?: ITimelineRegion) => {
    if (onPlayRegion) onPlayRegion(region)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeydown = (ev: KeyboardEvent) => {
    /**
     * Resgata o item da timeline
     */
    const _timelineItem = timelineTags.find(
      (el) =>
        el.endFrame >= ((cursorPosition || 0) * durationFrames) / timelinePixelsSize &&
        el.startFrame <= ((cursorPosition || 0) * durationFrames) / timelinePixelsSize,
    )
    /**
     * Irá exibir o select quando o usuário pressionar enter sobre uma timeline
     */

    /**
     * Key 13 = Enter
     */
    if (ev.keyCode === 13 && _timelineItem && !editing) {
      setSelectIsOpened(!selectIsOpened && _timelineItem.id === id)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown, true)
    return () => {
      window.removeEventListener('keydown', handleKeydown, true)
    }
  }, [handleKeydown])

  return (
    <InteractableStyle
      ref={refElement}
      style={{
        touchAction: 'none',
        bottom: '10px',
        top: 'unset',
        transform: `translate(${transform.offsetX}px, ${transform.offsetY}px)`,
        left: `${transform.x}px`,
        width: `${transform.width}px`,
      }}
    >
      <TimelineItem
        handleOnMouseOver={handleOnMouseOver}
        variant={(sentence?.includes(fingerspellSign) ? 'fingerspell' : 'link') || 'unknown'}
        handleOnMouseExit={handleOnMouseExit}
        isDragOver={isDragOver}
        isLocked={isLocked}
        isMouseOver={isMouseOver}
        handleButtonRemoveClicked={handleButtonRemoveClicked}
        isDropActive={isDropActive}
        sentence={sentence}
        selectedFingerspellSign={fingerspellSign}
        onSelectFingerspellSign={handleSelectFingerspellSign}
        onPlayRegion={() => handlePlayRegion(tag)}
        isPlaying={isPlaying}
        handleOnClick={handleOnClickRegion}
        selectIsOpened={selectIsOpened}
        textPlaySegment={textPlaySegment}
        textDeleteSegment={textDeleteSegment}
        textSign={textSign}
        textFingerspell={textFingerspell}
        textNotFingerspell={textNotFingerspell}
        editing={editing}
      />
    </InteractableStyle>
  )
}
export default TimelineItemUse
