import React, { useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import IconButton from '@material-ui/core/IconButton'
import ConfirmDialog, { ConfirmDialogContentType } from '../ConfirmDialog'
import GetAppIcon from '@material-ui/icons/GetApp'
import FileCopyRounded from '@material-ui/icons/FileCopyRounded'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import useIntl from '../../../../hooks/useIntl'
import { FirebaseStorage, getDownloadURL, ref } from '@firebase/storage'

interface IAnimationListPureProps {
  /** Lista de animações para exibição */
  data: firebase.default.firestore.DocumentData[]
  /** Lista de usuários do workspace */
  workspaceUsers: firebase.default.firestore.DocumentData
  /** Animação selecinado */
  animationSelected: number
  /** Indíce da animação principal */
  currentAnimationIndex: number
  /** Determina se o botão `Set as fingerspelling` está ativado */
  actionsActive: boolean
  /** Referência ao storage */
  storage?: FirebaseStorage
  /** Callback para salvar como fingerspell */
  setSignAsFingerspelling: () => void
  /** Callback para mudar a animação selecionada */
  setAnimationSelected: (index: number) => void
  /** Callback para salvar a nova animação principal */
  setMainAnimation: (index?: number) => void
  /** Callback para desativar uma animação */
  disableAnimation: () => void
  /** Texto da coluna animator */
  textAnimator: string
  /** Texto da coluna date */
  textDate: string
  /** Texto da coluna main */
  textMain: string
  /** Texto tooltip copiar hta */
  textCopyHtaTooltip: string
  /** Texto tooltip baixar .blend */
  textDownloadBlendTooltip: string
}

const MainIcon = styled(CheckCircleIcon)`
  margin-right: 10px;
`

const AnimationTableContainer = styled(TableContainer)`
  && {
    max-height: 300px;
  }
`

const AnimationRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }

  && {
    cursor: pointer;
    background-color: ${(props: { isSelected: boolean }) => props.isSelected && '#2196f3'};
  }
`

/**
 * Componente puro de exibição de animações
 */
const AnimationListPure: React.SFC<IAnimationListPureProps> = ({
  data,
  animationSelected,
  workspaceUsers,
  currentAnimationIndex,
  actionsActive,
  storage,
  disableAnimation,
  setAnimationSelected,
  setMainAnimation,
  setSignAsFingerspelling,
  textAnimator,
  textDate,
  textMain,
  textCopyHtaTooltip,
  textDownloadBlendTooltip,
}) => {
  const intl = useIntl()
  const [isConfirmDialogOpen, setIsConfirmModalOpen] = useState(false)
  const [modalContentType, setModalContentType] = useState<ConfirmDialogContentType>('currentAnimation')
  const { htaSize, htaAnimation, blendFile } = data[animationSelected] || {}
  const htaSizeType = (htaSize >= 15000 && 'Huge') || (htaSize >= 10000 && 'Medium') || 'Small'

  const activateCurrentAnimationModal = () => {
    setModalContentType('currentAnimation')
    setIsConfirmModalOpen(true)
  }

  const downloadBlendFile = async () => {
    if (storage) {
      const downloadUrl = await getDownloadURL(ref(storage, blendFile))
      open(downloadUrl)
    }
  }

  const copyHtaText = () => {
    navigator.clipboard.writeText(htaAnimation)
  }

  const onConfirmDialog = () => {
    setIsConfirmModalOpen(false)
    switch (modalContentType) {
      case 'isDisabled':
        disableAnimation()
        break

      case 'currentAnimation':
        setMainAnimation()
        break

      case 'isFingerspell':
        setSignAsFingerspelling()
        break
    }
  }

  return (
    <Grid container>
      {/* Lista de animações */}
      <AnimationTableContainer>
        <Table aria-label="Animations list">
          <TableHead>
            <TableRow>
              <TableCell>{textAnimator}</TableCell>
              <TableCell>{textDate}</TableCell>
              <TableCell>HTA</TableCell>
              <TableCell align="center">{textMain}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <AnimationRow isSelected={i === animationSelected} key={i} onClick={() => setAnimationSelected(i)}>
                <TableCell>
                  {(row.createdBy &&
                    workspaceUsers[row.createdBy.id] &&
                    workspaceUsers[row.createdBy.id].displayName) ||
                    ''}
                </TableCell>

                <TableCell>{format(row.createdAt?.toDate() || new Date(), 'dd/MM/yyyy HH:mm:ss')}</TableCell>

                <TableCell>{htaSizeType}</TableCell>

                <TableCell align="center">
                  {i === currentAnimationIndex ? (
                    <Grid justifyContent="center" container>
                      <MainIcon />
                      <Typography>MAIN</Typography>
                    </Grid>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={row.isDisabled.value || !actionsActive}
                      onClick={activateCurrentAnimationModal}
                    >
                      {intl.get('pages.animation.setMain')}
                    </Button>
                  )}
                </TableCell>

                <TableCell>{row.animator || ''}</TableCell>
                <TableCell>
                  <Tooltip title={textCopyHtaTooltip}>
                    <IconButton aria-label="Copy HTA" component="span" onClick={copyHtaText}>
                      <FileCopyRounded />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={textDownloadBlendTooltip}>
                    <IconButton aria-label="Download .blend" component="span" onClick={downloadBlendFile}>
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </AnimationRow>
            ))}
          </TableBody>
        </Table>
      </AnimationTableContainer>

      {/* Modal de confirmação de ações */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={onConfirmDialog}
        onClose={() => setIsConfirmModalOpen(false)}
        contentType={modalContentType}
      />
    </Grid>
  )
}

export default AnimationListPure
