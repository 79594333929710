import React from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import { IWorkspace } from 'collections'
import useIntl from '../../../hooks/useIntl'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      background: theme.palette.type === 'light' ? '#CCC' : '#525252',
    },
  }),
)

const SearchField = styled(TextField)`
  && {
    margin: 10px 0;
  }
`

interface IWorkspacesTableProps {
  workspaces: IWorkspace[]
  handleCreateWorkspace: () => void
}

const WorkspacesTablePure: React.FC<IWorkspacesTableProps> = ({ workspaces, handleCreateWorkspace }) => {
  const intl = useIntl()
  const classes = useStyles()

  /**
   * Cebeçalho da tabela, com as informações iniciais o titulo das colunas
   */
  const head = (
    <TableHead>
      <TableRow>
        <TableCell>Workspace</TableCell>
      </TableRow>
    </TableHead>
  )

  /**
   * Corpo da nossa tabela, com os dados dos usuários
   */
  const body = (
    <TableBody>
      {workspaces.map((workspace, index) => (
        <TableRow key={workspace.name} className={index % 2 ? classes.tableRow : ''}>
          <TableCell>
            <CardHeader
              avatar={<Avatar>HT</Avatar>}
              title={workspace.name || ''}
              subheader={workspace.id}
              style={{ padding: 0 }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

  return (
    <Card>
      <Button
        style={{ margin: '5px', fontWeight: 'bold' }}
        variant="contained"
        color="primary"
        onClick={handleCreateWorkspace}
      >
        {intl.get('pages.admin.usersTable.register')}
      </Button>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SearchField style={{ width: '100%' }} label="Search" variant="filled" value={''} />
        </Grid>
      </Grid>
      <Table className={classes.table} aria-label="simple table">
        {head}
        {body}
      </Table>
    </Card>
  )
}

export default WorkspacesTablePure
