import React, { FC, useCallback, useEffect, useState } from 'react'
import { useGlobal } from 'reactn'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Layout from '../../../components/Layout'
import FramePlayer from '../../../components/FramePlayer'
import Preloader from '../../../components/Preloader'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InfiniteScroll from 'react-infinite-scroll-component'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

import {
  IGlobalAttr,
  ISegment,
  ITimelineCursorState,
  ISegmentView,
  SignId,
  ISignView,
  getPathFramesJson,
  DocumentSnapshot,
  getCollectionReference,
  getDocumentReference,
  getFirestoreDocument,
  QueryDocumentSnapshot,
  updateSign,
  IVideo,
} from 'collections'
import useIntl from '../../../hooks/useIntl'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { useStorage } from 'reactfire'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import HoverablePlayer from '../HoverablePlayer'
import { routes } from '../../../community'
import { useLocation, useRoute } from 'wouter'
import CardMessage from '../../../components/CardMessage'
import CardSegment from './CardSegment'
import useModuleHTube from '../../../hooks/useModuleHTube'
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
} from '@material-ui/core'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import JoinClustersDialog from './JoinClustersDialog'
import Skeleton from '@material-ui/lab/Skeleton'
import useKeyPress from '../../../hooks/useKeyPress'
import AnimationViewDialog from './AnimationViewDialog'
import { getDownloadURL, ref } from '@firebase/storage'
import {
  DocumentData,
  getDocs,
  limit,
  Query,
  query as queryFirestore,
  startAfter,
  where,
  orderBy,
  OrderByDirection,
} from '@firebase/firestore'
import { green, red } from '@material-ui/core/colors'
import useSnackbar from '../../../services/hooks/useSnackbar'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { ApplicationPhrasesDialog } from './ApplicationPhrases'

interface IHTubeProps {
  path: string
}

const CompareBox = styled.div`
  width: 100%;
  clear: both;
`

const VideosBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100vw;
  position: relative;
  left: 48%;
  right: 50%;
  margin-left: -49vw;
  margin-right: -50vw;

  & > div {
    width: 45%;
  }
`

const FrameBox = styled.div`
  border-radius: 4px;
  background-color: #000;
  padding: 4px;
  width: 100%;
  height: 100%;
`

const BlockGrid = styled(Grid)`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
`

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

interface IHtubeInfoProps {
  id: string
}

export const HTubeInfo: FC<IHtubeInfoProps> = ({ id }) => {
  const [segments, setSegments] = useState<ISegmentView[]>([])
  const [mainSegment, setMainSegment] = useState<ISegmentView | null>(null)
  const [primarySegment, setPrimarySegment] = useState<ISignView | null>(null)
  const [lastSegment, setLastSegment] = useState<QueryDocumentSnapshot | null>(null)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [hasPrimarySegment, setHasPrimarySegment] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [compareSegments, setCompareSegments] = useState(false)
  const [autoPlay, setAutoPlay] = useState(false)
  const [opennedPromotionDialog, setOpennedPromotionDialog] = useState(false)
  const [opennedJoinDialog, setOpennedJoinDialog] = useState(false)
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const [timelineCursorSecondary, timelineCursorFnsSecondary] = useTimelineCursor()
  const [, routeParams] = useRoute(routes.hTube)
  const [signId, setSignId] = useState<SignId>(routeParams?.clusterId ? routeParams?.clusterId : id || '')
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const storage = useStorage()
  const [opennedAnimationDialog, setOpennedAnimationDialog] = useState(false)
  const { removeSegmentFromCluster, changePrimarySegment, promoteClusterToSign, joinClusters } = useModuleHTube()
  const [selectedsSegments, setSelectedsSegments] = useState<ISegmentView[]>([])
  const ctrlPressed = useKeyPress(17) // CTRL Key
  const [order, setOrder] = useState<OrderByDirection>('asc')
  const [relatedWords, setRelatedWords] = useState<string[]>([])
  const [addWordText, setAddWordText] = useState<string>('')
  const [edit, setEdit] = useState(false)

  const [north, setNorth] = useState(false)
  const [northeast, setNortheast] = useState(false)
  const [south, setSouth] = useState(false)
  const [southeast, setSoutheast] = useState(false)
  const [midwest, setMidwest] = useState(false)
  const [regionOfUse, setRegionOfUse] = useState<string[]>([])

  const [definition, setDefinition] = useState('')
  const [pieceOfSign, setPieceOfSign] = useState(false)
  const [reference, setRefence] = useState('')
  const [glosa, setGlosa] = useState('')

  const [opennedConfirmation, setOpennedConfirmation] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [showAlertRelatedWord, setShowAlertRelatedWord] = useState(false)
  const [showAlertGlosa, setShowAlertGlosa] = useState(false)

  const [distanceValue, setDistanceValue] = useState<number[]>([0, 100])
  const [openApplicationPhrasesDialog, setOpenApplicationPhrasesDialog] = useState(false)

  const showSnackbar = useSnackbar()
  const [, setLocation] = useLocation()

  const getVideoUrl = async (segment: ISegment, duplicateOf: string) => {
    const urlString = getPathFramesJson(fsUser.workspace.id, segment.video.id, duplicateOf)

    return await getDownloadURL(ref(storage, urlString))
  }

  // Função para coletar os segmentos do cluster
  const loadMore = useCallback(
    async (rewrite?: boolean, ordering?: OrderByDirection) => {
      if (rewrite) setIsLoading(true)
      try {
        let _segments: ISegmentView[] = []
        if (!rewrite) _segments = [...segments]

        if (signId == '') return
        const signRef = getDocumentReference(fsUser.workspace, 'signs', signId)
        const signSnapshot = (await getFirestoreDocument(signRef)) as DocumentSnapshot

        let minDistance = distanceValue.sort((a, b) => a - b)[0]
        let maxDistance = distanceValue.sort((a, b) => a - b)[1]

        // Divide por 100
        minDistance = minDistance > 0 ? minDistance / 100 : 0
        maxDistance = maxDistance > 0 ? maxDistance / 100 : 0

        if (signSnapshot.exists()) {
          const _sign = signSnapshot.data() as ISignView
          let query = queryFirestore(
            getCollectionReference(fsUser.workspace, 'segments') as Query<DocumentData>,
            where('movement', '==', signRef),
            where('distanceToPrimary', '>=', minDistance),
            where('distanceToPrimary', '<=', maxDistance),
            orderBy('distanceToPrimary', ordering),
          )
          if (lastSegment && !rewrite) {
            query = queryFirestore(query, startAfter(lastSegment))
          }

          if (!primarySegment || rewrite) {
            _sign.id = signSnapshot.id
            _sign.ref = signSnapshot.ref

            if (_sign.primarySegment) {
              const primarySnapshot = await getFirestoreDocument(_sign.primarySegment)
              const _primaryData = (await primarySnapshot.data()) as ISegmentView
              if (_primaryData) {
                const videoSnapshot = await getFirestoreDocument(_primaryData.video)
                const videoData = videoSnapshot.data() as IVideo
                _primaryData.videoDuration = videoData.duration
                _primaryData.videoSentence = videoData.sentence
                _primaryData.videoUrl = await getVideoUrl(_primaryData, videoData.duplicateOf || '')
                _primaryData.id = primarySnapshot.id
                _sign.segment = _primaryData
              }
            }
            setPrimarySegment(_sign)
            setMainSegment(_sign.segment || null)
          }

          const segmentsSnapshot = await getDocs(queryFirestore(query, limit(8)))
          for (const segmentSnapshot of segmentsSnapshot.docs) {
            const _segment: ISegmentView = segmentSnapshot.data() as ISegmentView
            _segment.id = segmentSnapshot.id
            if (_sign && _segment.id === _sign.segment?.id) continue

            _segment.ref = segmentSnapshot.ref
            const videoSnapshot = await getFirestoreDocument(_segment.video)
            const videoData = videoSnapshot.data() as IVideo
            _segment.videoDuration = videoData.duration
            _segment.videoSentence = videoData.sentence
            _segment.videoUrl = await getVideoUrl(_segment, videoData.duplicateOf || '')
            _segments.push(_segment)
          }
          setHasMoreResults(segmentsSnapshot.size == 8)
          setLastSegment(segmentsSnapshot.docs[segmentsSnapshot.docs.length - 1] || null)
          setSegments(_segments)
          setIsLoading(false)
        } else {
          setHasPrimarySegment(false)
          // Redireciona para o sinal unido, caso exista
          const unitedSignsQuery = queryFirestore(
            getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
            where('unitedWithSigns', 'array-contains', signId),
          )

          const unitedSignsSnap = await getDocs(unitedSignsQuery)
          if (unitedSignsSnap.docs.length > 0) {
            setLocation(routes.hTube.replace(':clusterId', unitedSignsSnap.docs[0].id))
          }
        }
      } finally {
        setIsLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser.workspace.id, lastSegment, segments, signId, primarySegment, distanceValue],
  )

  useEffect(() => {
    setSegments([])
    setHasPrimarySegment(true)
    setPrimarySegment(null)
    setMainSegment(null)
    setLastSegment(null)
    setOrder('asc')
    loadMore(true)
    setRelatedWords([])
    setAddWordText('')
    setEdit(false)
    setShowAlertRelatedWord(false)
    setShowAlertGlosa(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.workspace.id, signId])

  useEffect(() => {
    setSignId(routeParams?.clusterId ? routeParams?.clusterId : id || '')
  }, [routeParams?.clusterId, id])

  useEffect(() => {
    handleRescueSignData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primarySegment])

  useEffect(() => {
    const call = async () => {
      if (confirmed && primarySegment) {
        try {
          showSnackbar(intl.get('messages.savingData'), {
            variant: 'info',
          })

          primarySegment.relatedWords = relatedWords
          primarySegment.definition = definition
          primarySegment.searchReference = reference
          primarySegment.regionOfUse = regionOfUse
          primarySegment.pieceOfSign = pieceOfSign

          primarySegment.glosa = glosa
          await updateSign(primarySegment?.ref, {
            relatedWords: relatedWords,
            definition: definition,
            searchReference: reference,
            regionOfUse: regionOfUse,
            pieceOfSign: pieceOfSign,
            glosa: glosa,
          })

          showSnackbar(intl.get('messages.savedSuccessfully'), {
            variant: 'success',
          })
        } catch (err) {
          showSnackbar((err as TypeError | RangeError | EvalError).message, {
            variant: 'error',
          })
        }

        setConfirmed(false)
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed])

  const handleRescueSignData = () => {
    const _relatedWords: string[] = []
    primarySegment?.relatedWords.map((value: string) => {
      const found = _relatedWords.find((el) => el === value)

      if (!found) _relatedWords.push(value)
    })
    setRelatedWords(_relatedWords)

    setDefinition(primarySegment?.definition || '')

    if (primarySegment?.pieceOfSign !== undefined) setPieceOfSign(primarySegment?.pieceOfSign)

    if (primarySegment?.regionOfUse !== undefined) {
      setRegionOfUse(primarySegment?.regionOfUse)
      setNorth(primarySegment?.regionOfUse.find((el: string) => el === 'Norte') !== undefined)
      setNortheast(primarySegment?.regionOfUse.find((el: string) => el === 'Nordeste') !== undefined)
      setSouth(primarySegment?.regionOfUse.find((el: string) => el === 'Sul') !== undefined)
      setSoutheast(primarySegment?.regionOfUse.find((el: string) => el === 'Sudeste') !== undefined)
      setMidwest(primarySegment?.regionOfUse.find((el: string) => el === 'Centro-Oeste') !== undefined)
    } else {
      setRegionOfUse(['Norte', 'Nordeste', 'Sul', 'Sudeste', 'Centro-Oeste'])
      setNorth(true)
      setNortheast(true)
      setSouth(true)
      setSoutheast(true)
      setMidwest(true)
    }

    if (primarySegment?.searchReference !== undefined) setRefence(primarySegment?.searchReference)

    if (primarySegment?.glosa !== undefined) {
      const _glosa = primarySegment?.glosa.replace('¶', '')
      setGlosa(_glosa)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnScroll = (scroll: any) => {
    const el = document.getElementById('main-player')
    if (el) {
      if (scroll.target.scrollTo > 350) {
        el.style.position = 'fixed'
        el.style.top = '80px'
        el.style.width = '40%'
        el.style.zIndex = '2'
      } else {
        el.style.position = 'static'
        el.style.top = 'auto'
        el.style.width = '100%'
      }
    }
  }

  const toggleOpenPromotionDialog = () => {
    setOpennedPromotionDialog(!opennedPromotionDialog)
  }

  const toggleOpenJoinDialog = () => {
    setOpennedJoinDialog(!opennedJoinDialog)
  }

  const toggleOpenAnimationDialog = () => {
    setOpennedAnimationDialog(!opennedAnimationDialog)
  }

  const toggleOpenApplicationPhrasesDialog = () => {
    setOpenApplicationPhrasesDialog(!openApplicationPhrasesDialog)
  }

  const toggleOpenConfirmation = () => {
    setOpennedConfirmation(!opennedConfirmation)
  }

  const handleDisagreeConfirmation = () => {
    setOpennedConfirmation(false)
    setConfirmed(false)
    handleClickCancel()
  }

  const handleAgreeConfirmation = () => {
    setOpennedConfirmation(false)
    setConfirmed(true)
  }

  const handleSignPromotion = () => {
    promoteClusterToSign(signId)
    toggleOpenPromotionDialog()
  }

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const handleOnChangedTimeSecondary = (value: number) => {
    timelineCursorFnsSecondary.setPosition(value)
  }

  const handleSelectSegment = (segment: ISegmentView) => {
    const _selectedsSegments = [...selectedsSegments]
    const index = _selectedsSegments.findIndex((el) => el.id === segment.id)
    if (index === -1) _selectedsSegments.push(segment)
    else _selectedsSegments.splice(index, 1)
    setSelectedsSegments(_selectedsSegments)
  }

  const video = (
    segment: ISegmentView,
    timelineCursor: ITimelineCursorState,
    handleOnChangedTime: (value: number) => void,
    onClick?: () => void,
    selected?: boolean,
  ) => {
    return (
      <FrameBox
        style={{
          marginBottom: 8,
          padding: `${selected ? '5px' : '0px'}`,
          backgroundColor: '#aaa',
        }}
        onClick={onClick}
      >
        <FramePlayer
          reference={segment.id}
          src={segment.videoUrl || ''}
          onReady={() => ({})}
          onError={() => ({})}
          startFrame={segment.startFrame}
          framesDuration={segment.endFrame}
          timeFrame={appConfig.frameRate}
          frameRatio={30}
          onChangedTime={handleOnChangedTime}
          timelineCursor={timelineCursor}
          autoPlay={autoPlay}
          hoverable={false}
          fullVideoDuration={segment.videoDuration}
          videoSentence={segment.videoSentence}
        />
      </FrameBox>
    )
  }

  const handleClickPrimaryVideo = () => {
    if (ctrlPressed && mainSegment?.id === primarySegment?.segment?.id && primarySegment?.segment) {
      handleSelectSegment(primarySegment?.segment)
    }
  }

  const handleChangeSlider = (newValue: number | number[]) => {
    setDistanceValue(newValue as number[])
  }

  const checkboxGroup = () => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={compareSegments}
              onChange={() => {
                setCompareSegments(!compareSegments)
              }}
              disabled={mainSegment?.id === primarySegment?.segment?.id && mainSegment != null}
              name="compareSegments"
              color="primary"
            />
          }
          label={intl.get('pages.hTube.sign.compareSegments')}
        />
        <FormControlLabel
          control={
            <Checkbox checked={autoPlay} onChange={() => setAutoPlay(!autoPlay)} name="autoPlay" color="primary" />
          }
          label={intl.get('pages.hTube.sign.autoPlay')}
        />
      </FormGroup>
    )
  }

  const buttonGroup = () => {
    return (
      <ButtonGroup color="primary" aria-label="Operações para clusters" style={{ display: 'flex' }}>
        <Button onClick={toggleOpenAnimationDialog} style={{ width: '100%' }}>
          {intl.get('pages.hTube.sign.viewAnimation')}
        </Button>
        <Button onClick={toggleOpenApplicationPhrasesDialog} style={{ width: '100%' }}>
          {intl.get('pages.hTube.sign.applicationPhrase')}
        </Button>
      </ButtonGroup>
    )
  }

  // Page's skeleton to appear when the page is loading
  const SkeletonPage = () => {
    const rangeArray = Array.from(Array(8), (_, i) => i + 1)
    return (
      <Grid item xs={12} sm={3}>
        {rangeArray.slice(0, 6).map((i) => (
          <Box mb={1} key={i}>
            <Skeleton variant="rect" width={'100%'} style={{ borderRadius: '4px', paddingBottom: '56.25%' }} />
          </Box>
        ))}
      </Grid>
    )
  }

  const actionButtons = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          id="btnEdit"
          variant="contained"
          color="primary"
          onClick={edit ? handleClickSave : handleClickEnableEdit}
          style={
            edit
              ? {
                  alignSelf: 'flex-end',
                  background: green[700],
                  color: '#fff',
                }
              : {
                  alignSelf: 'flex-end',
                }
          }
        >
          {!edit ? intl.get('pages.hTube.sign.editInfo') : intl.get('pages.hTube.sign.saveChanges')}
        </Button>
        {edit && (
          <Button
            variant="contained"
            color="primary"
            style={{
              alignSelf: 'flex-end',
              background: red[700],
              color: '#fff',
              marginLeft: '5px',
            }}
            onClick={handleClickCancel}
          >
            CANCELAR
          </Button>
        )}
      </Box>
    )
  }

  const handleClickOrderSegments = (event: React.MouseEvent<HTMLElement>) => {
    setOrder(event.currentTarget.id as OrderByDirection)
    loadMore(true, event.currentTarget.id as OrderByDirection)
  }

  const handleClickDeleteChip = (chipText: string) => {
    const relatedWordsFiltered = relatedWords.filter((el) => el !== chipText)
    setRelatedWords(relatedWordsFiltered)
  }

  const handleKeyPressAddWord = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      const _relatedWords = [...new Set([...relatedWords, addWordText])]
      setRelatedWords(_relatedWords)
      setAddWordText('')
    }
  }

  const handleChangeAddWordText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddWordText(event.target.value)
  }

  const handleClickEnableEdit = () => {
    setEdit(true)
  }

  const handleClickSave = () => {
    setEdit(false)
    setAddWordText('')

    if (relatedWords.length == 0) {
      setShowAlertRelatedWord(true)
      return
    }

    if (!glosa) {
      setShowAlertGlosa(true)
      return
    }

    toggleOpenConfirmation()
  }

  const handleClickCancel = () => {
    setEdit(false)
    setAddWordText('')

    setRelatedWords([])
    handleRescueSignData()
  }

  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _regionOfUse = [...regionOfUse]

    const found = _regionOfUse.find((el) => el === event.target.name)
    if (!found) _regionOfUse.push(event.target.name)
    else _regionOfUse.splice(regionOfUse.indexOf(event.target.name), 1)

    setRegionOfUse(_regionOfUse)

    setNorth(_regionOfUse.find((el) => el === 'Norte') !== undefined)
    setNortheast(_regionOfUse.find((el) => el === 'Nordeste') !== undefined)
    setSouth(_regionOfUse.find((el) => el === 'Sul') !== undefined)
    setSoutheast(_regionOfUse.find((el) => el === 'Sudeste') !== undefined)
    setMidwest(_regionOfUse.find((el) => el === 'Centro-Oeste') !== undefined)
  }

  const handleChangeDefinitions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefinition(event.target.value)
  }

  const handleChangePieceOfSign = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPieceOfSign(event.target.checked)
  }

  const handleChangeReferenceText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefence(event.target.value)
  }

  const handleClickGlosa = (word: string) => {
    setGlosa(word)
  }

  const sliderMarks = [
    {
      value: 0,
      label: intl.get('messages.equal'),
    },
    {
      value: 100,
      label: intl.get('messages.different'),
    },
  ]

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {showAlertRelatedWord && (
          <Alert
            severity="warning"
            onClose={() => {
              setShowAlertRelatedWord(false)
              handleClickCancel()
            }}
            style={{ zIndex: 1 }}
          >
            <AlertTitle>{intl.get('pages.hTube.sign.others.alert')}</AlertTitle>
            {intl.get('pages.hTube.sign.others.alertRelatedWords')}
          </Alert>
        )}
        {showAlertGlosa && (
          <Alert
            severity="warning"
            onClose={() => {
              setShowAlertGlosa(false)
              handleClickCancel()
            }}
            style={{ zIndex: 1 }}
          >
            <AlertTitle>{intl.get('pages.hTube.sign.others.alert')}</AlertTitle>
            {intl.get('pages.hTube.sign.others.alertGlosa')}
          </Alert>
        )}
      </Box>

      {!hasPrimarySegment && (
        <CardMessage
          title={intl.get('pages.hTube.sign.noPrimary')}
          subtitle={intl.get('pages.hTube.sign.checkWorkspace')}
        />
      )}

      {(segments.length || primarySegment) && (
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sm={10}
          xs={10}
          style={{ marginTop: '16px' }}
        >
          {compareSegments ? (
            <CompareBox>
              <VideosBox>
                {mainSegment && video(mainSegment, timelineCursor, handleOnChangedTime)}
                {primarySegment &&
                  primarySegment?.segment &&
                  video(primarySegment?.segment, timelineCursorSecondary, handleOnChangedTimeSecondary)}
              </VideosBox>
              {checkboxGroup()}
            </CompareBox>
          ) : (
            // coluna esquerda
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="row" pb={2} alignItems="center">
                <Typography variant="h4">
                  {primarySegment && primarySegment?.glosa ? primarySegment?.glosa.replace('¶', '') : ''}
                </Typography>
              </Box>
              <Box>
                {mainSegment && (
                  <div id="main-player">
                    {video(
                      mainSegment,
                      timelineCursor,
                      handleOnChangedTime,
                      handleClickPrimaryVideo,
                      selectedsSegments.find((el) => el.id === primarySegment?.segment?.id) &&
                        mainSegment.id === primarySegment?.segment?.id,
                    )}
                  </div>
                )}
                <Box mb={2} style={{ borderBottom: '1px solid #777' }}>
                  {buttonGroup()}
                  {checkboxGroup()}
                </Box>
                {mainSegment && (
                  <Box
                    mt={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {actionButtons()}

                    <TextField
                      id="addWord"
                      label={intl.get('pages.hTube.sign.relatedWords')}
                      variant="filled"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        margin: '3px 0px 0px 0px',
                      }}
                      value={addWordText}
                      onChange={handleChangeAddWordText}
                      onKeyPress={handleKeyPressAddWord}
                      disabled={!edit}
                    />
                    {relatedWords.length > 0 && (
                      <Paper
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          margin: '0px',
                        }}
                        component="ul"
                      >
                        {relatedWords.map((word: string, index: number) => (
                          <ListItem key={index}>
                            <Chip
                              label={`${word}`}
                              key={index}
                              onDelete={() => {
                                handleClickDeleteChip(word)
                              }}
                              onClick={() => {
                                handleClickGlosa(word)
                              }}
                              color={word == glosa ? 'primary' : 'default'}
                              disabled={!edit}
                            />
                          </ListItem>
                        ))}
                      </Paper>
                    )}

                    <FormGroup
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pieceOfSign}
                            onChange={handleChangePieceOfSign}
                            name="pieceOfSign"
                            disabled={!edit}
                            color="primary"
                          />
                        }
                        label={intl.get('pages.hTube.sign.others.pieceOfSign')}
                      />
                    </FormGroup>

                    <TextField
                      id="filled-multiline-flexible"
                      variant="filled"
                      label={intl.get('pages.hTube.sign.signDefinition')}
                      multiline
                      minRows={4}
                      value={definition}
                      onChange={handleChangeDefinitions}
                      style={{ margin: '10px 0px 0px 0px' }}
                      disabled={!edit}
                    />

                    <TextField
                      id="reference"
                      label={intl.get('pages.hTube.sign.reference')}
                      variant="filled"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '10px 0px 0px 0px',
                      }}
                      value={reference}
                      onChange={handleChangeReferenceText}
                      disabled={!edit}
                    />

                    <Box display="flex" alignItems="stretch" flexDirection="column">
                      <FormControl
                        component="fieldset"
                        variant="standard"
                        style={{
                          marginTop: '10px',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <FormLabel component="legend">{intl.get('pages.hTube.sign.region.name')}</FormLabel>
                        <FormGroup
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={north}
                                onChange={handleChangeRegion}
                                name="Norte"
                                disabled={!edit}
                                color="primary"
                              />
                            }
                            label={intl.get('pages.hTube.sign.region.north')}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={northeast}
                                onChange={handleChangeRegion}
                                name="Nordeste"
                                disabled={!edit}
                                color="primary"
                              />
                            }
                            label={intl.get('pages.hTube.sign.region.northEast')}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={south}
                                onChange={handleChangeRegion}
                                name="Sul"
                                disabled={!edit}
                                color="primary"
                              />
                            }
                            label={intl.get('pages.hTube.sign.region.south')}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={southeast}
                                onChange={handleChangeRegion}
                                name="Sudeste"
                                disabled={!edit}
                                color="primary"
                              />
                            }
                            label={intl.get('pages.hTube.sign.region.southEast')}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={midwest}
                                onChange={handleChangeRegion}
                                name="Centro-Oeste"
                                disabled={!edit}
                                color="primary"
                              />
                            }
                            label={intl.get('pages.hTube.sign.region.midwest')}
                          />
                        </FormGroup>
                      </FormControl>

                      <FormControl
                        component="fieldset"
                        variant="standard"
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <FormLabel component="legend">{intl.get('pages.hTube.sign.others.name')}</FormLabel>
                      </FormControl>

                      <Typography variant="body2" color="textSecondary" style={{ alignSelf: 'left' }}>
                        ID: {mainSegment.id}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ alignSelf: 'left' }}>
                        Video ID: {mainSegment.video.id}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ alignSelf: 'left' }}>
                        {intl.get('messages.numberOfSegmentsWithNumber', {
                          number: primarySegment?.numberOfSegments,
                        })}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ alignSelf: 'left' }}>
                        {`${intl.get('pages.hTube.sign.corpusPopularity')}: ${primarySegment?.corpusPopularity}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ alignSelf: 'left' }}>
                        {`${intl.get('pages.hTube.sign.translationPopularity')}: ${
                          primarySegment?.translationPopularity
                        }`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ alignSelf: 'left' }}>
                        {`${intl.get('messages.state')}: ${intl.get(
                          'pages.hTube.sign.signsState.' + primarySegment?._state,
                        )}`}
                      </Typography>

                      {actionButtons()}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          )}
          {
            // coluna direita
          }
          {isLoading && <SkeletonPage />}

          {!isLoading && (
            <Grid item sm={3} xs={12} id="scrollableDiv">
              {mainSegment && mainSegment.id !== primarySegment?.segment?.id && (
                <Box
                  onClick={() => {
                    if (ctrlPressed) {
                      if (primarySegment?.segment) {
                        handleSelectSegment(primarySegment?.segment)
                      }
                    } else {
                      if (compareSegments) setCompareSegments(false)
                      setMainSegment(primarySegment?.segment || null)
                    }
                  }}
                  mb={1}
                  pb={2}
                  style={{ borderBottom: '1px solid #aaa' }}
                >
                  <Card style={{ padding: 10 }}>
                    <CardContent style={{ padding: '0 0 8px 0' }}>
                      <Typography variant="h6">{intl.get('pages.hTube.sign.primarySegment')}</Typography>
                    </CardContent>
                    <CardContent
                      style={{
                        padding: `${
                          selectedsSegments.find((el) => el.id === primarySegment?.segment?.id) ? '5px' : '0px'
                        }`,
                        backgroundColor: '#aaa',
                      }}
                    >
                      {primarySegment?.segment && <HoverablePlayer segment={primarySegment?.segment} />}
                    </CardContent>
                  </Card>
                </Box>
              )}

              <Box style={{ marginTop: '20px', marginBottom: '10px' }}>
                <Typography id="input-slider" gutterBottom>
                  {intl.get('pages.hTube.sign.similarityWithTheSign')}
                </Typography>
                <Slider
                  onChangeCommitted={() => {
                    loadMore(true)
                  }}
                  marks={sliderMarks}
                  value={distanceValue}
                  onChange={(_, newValue) => handleChangeSlider(newValue)}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                />
              </Box>
              <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                <FormLabel component="legend">{intl.get('pages.hTube.sign.orderBy')}</FormLabel>
                <RadioGroup aria-label="order" defaultValue={order} name="radio-buttons-group">
                  <FormControlLabel
                    id="asc"
                    value="asc"
                    control={<Radio color="primary" />}
                    label={intl.get('pages.hTube.sign.crescent')}
                    onClick={handleClickOrderSegments}
                  />
                  <FormControlLabel
                    id="desc"
                    value="desc"
                    control={<Radio color="primary" />}
                    label={intl.get('pages.hTube.sign.decrescent')}
                    onClick={handleClickOrderSegments}
                  />
                </RadioGroup>
              </FormControl>

              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={segments.length}
                onScroll={handleOnScroll}
                endMessage={
                  <BlockGrid item sm={12} lg={12}>
                    <Typography>{intl.get('pages.signSentence.contributions.noMoreVideos')}</Typography>
                  </BlockGrid>
                }
                hasMore={hasMoreResults}
                next={() => loadMore(undefined, order)}
                loader={
                  <BlockGrid item sm={12} lg={12}>
                    <Preloader asBlock text={intl.get('messages.loadingSegments')} />
                  </BlockGrid>
                }
                height={1000}
                style={{ marginBottom: '10px' }}
              >
                {segments.map((item) => (
                  <Box
                    mb={1}
                    key={item.id}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (ctrlPressed) {
                        handleSelectSegment(item)
                      } else {
                        setMainSegment(item)
                      }
                    }}
                  >
                    <CardContent
                      style={{
                        padding: `${selectedsSegments.find((el) => el.id === item.id) ? '5px' : '0px'}`,
                        backgroundColor: '#aaa',
                      }}
                    >
                      <CardSegment
                        segment={item}
                        removeSegmentFromCluster={removeSegmentFromCluster}
                        changePrimarySegment={changePrimarySegment}
                        id={signId}
                        segments={segments}
                        setSegments={setSegments}
                      />
                    </CardContent>
                  </Box>
                ))}
              </InfiniteScroll>
            </Grid>
          )}
          {opennedPromotionDialog && (
            <ConfirmationDialog
              open={opennedPromotionDialog}
              toggleOpen={toggleOpenPromotionDialog}
              handleYes={handleSignPromotion}
              title={intl.get('pages.hTube.sign.promoteSign')}
              subtitle={intl.get('pages.hTube.sign.confirmPromotion')}
            />
          )}
        </Grid>
      )}
      <JoinClustersDialog
        title={intl.get('pages.hTube.sign.selectClusters')}
        open={opennedJoinDialog}
        toggleOpen={toggleOpenJoinDialog}
        joinClusters={joinClusters}
        words={(primarySegment?.relatedWords && primarySegment.relatedWords) || []}
        actualCluster={primarySegment?.segment || null}
        setIsLoadingOperation={setIsLoading}
      />
      {signId !== '' && (
        <AnimationViewDialog
          opened={opennedAnimationDialog}
          toggleOpen={toggleOpenAnimationDialog}
          primarySegment={primarySegment}
          signId={signId}
        />
      )}
      {openApplicationPhrasesDialog == true && (
        <ApplicationPhrasesDialog
          open={openApplicationPhrasesDialog}
          signId={signId}
          fsUser={fsUser}
          toggleOpen={toggleOpenApplicationPhrasesDialog}
        />
      )}
      <Dialog
        open={opennedConfirmation}
        onClose={handleDisagreeConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Tem certeza que deseja salvar?'}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDisagreeConfirmation}>recusar</Button>
          <Button onClick={handleAgreeConfirmation} autoFocus>
            aceitar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const HTube: React.FC<IHTubeProps> = () => {
  const intl = useIntl()

  return (
    <Layout title={intl.get('modules.hTube')} requiredModule={'hTube'} id="scrollable">
      <HTubeInfo id={''} />
    </Layout>
  )
}

export default HTube
