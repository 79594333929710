import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFirestore, useFirestoreDoc, useStorage } from 'reactfire'
import { useRoute, useLocation } from 'wouter'
import { addContribAnimationReviewFunction } from '../../../services/firebase'
import { routes } from '../../../community'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import {
  ISign,
  IUserInfo,
  IAnimationProblem,
  AnimationProblemsCode,
  ISegment,
  IGlobalAttr,
  getPathFramesJson,
  WorkspaceId,
  DocumentReferenceClient,
  IVideo,
} from 'collections'
import { green } from '@material-ui/core/colors'
import CardMessage from '../../../components/CardMessage'
import AnimationView from '../../../components/AnimationView'
import ContributionMetadataView from '../../../components/ContributionMetadataView'
import UserMenu from '../../../components/UserMenu'
import Preloader from '../../../components/Preloader'
import Typography from '@material-ui/core/Typography'
import Layout from '../../../components/Layout'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { getUsersByWorkspaceFunction } from '../../../services/firebase'
import useJobMeter from '../../../services/hooks/useJobMeter'
import { TextField } from '@material-ui/core'
import useIntl from '../../../hooks/useIntl'
import FramePlayer from '../../../components/FramePlayer'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import { useGlobal } from 'reactn'
import { runTransaction } from '@firebase/firestore'
import { getDownloadURL, ref } from '@firebase/storage'
import { getDocumentReference, getFirestoreDocument } from 'collections'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'
import { serverTimestamp, Timestamp } from 'firebase/firestore'

interface IAnimationProps {
  path: string
}

const AnimationHomeContainer = styled(Grid)`
  width: 90%;
  margin: 25px 0;
`

const SignViewContainer = styled.div`
  width: 361px;
  height: 361px;
`

const BoxContainer = styled(Grid)`
  && {
    margin: 30px 0;
  }
`

const AnimateDivider = styled(Divider)`
  && {
    height: 400px;
    margin-left: 10px;
    margin-right: 10px;
  }
`

/**
 * Componente da animação de sinais
 */
const ReviewAnimation: React.FC<IAnimationProps> = () => {
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  const [animationProblems, setAnimationProblems] = useState<IAnimationProblem[]>([
    {
      id: AnimationProblemsCode.wrongFacialExpression,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongFacialExpression}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongHandShape,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongHandShape}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongLocation,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongLocation}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.floatyMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.floatyMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.tooSlow,
      description: intl.get(`animationProblems.${AnimationProblemsCode.tooSlow}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.standardPose,
      description: intl.get(`animationProblems.${AnimationProblemsCode.standardPose}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.unnecessaryFrames,
      description: intl.get(`animationProblems.${AnimationProblemsCode.unnecessaryFrames}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.tooFast,
      description: intl.get(`animationProblems.${AnimationProblemsCode.tooFast}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.uncannyMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.uncannyMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.noFacialExpression,
      description: intl.get(`animationProblems.${AnimationProblemsCode.noFacialExpression}`),
      checked: false,
    },
  ])
  const { startJobMeter, resetJobMeter, endJobMeter } = useJobMeter('reviewAnimation')
  const hasPermissionOnModule = useCheckHasPermission('reviewAnimation')
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const [, setLocation] = useLocation()
  const [, routeParams] = useRoute(routes.reviewAnimation)
  const signId = (routeParams && routeParams.signId) || ''
  const firestore = useFirestore()
  const signDoc = useFirestoreDoc(
    getDocumentReference(fsUser.workspace, 'signs', signId) as DocumentReferenceClient,
  ).data

  const signData = signDoc.data() as ISign
  const { isExtractingKeypoints, isStudioFileRecording, isCropping, currentAnimation } = signData
  const [segmentData, setSegmentData] = useState<ISegment | null>(null)
  const [otherProblem, setOtherProblem] = useState('')
  const storage = useStorage()
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})
  const defaultSpeed = 1.0
  const [isPlaying, setIsPlaying] = useState(false)
  const [speed, setSpeed] = useState(defaultSpeed)
  const textFast = intl.get('messages.fast')
  const textMedium = intl.get('messages.medium')
  const textLow = intl.get('messages.low')

  useEffect(() => {
    getUsers()
    resetJobMeter()
    startJobMeter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const call = async () => {
      if (signData?.primarySegment) {
        const segmentSnapshot = await getFirestoreDocument(signData.primarySegment)
        const _segmentData = segmentSnapshot.data() as ISegment
        _segmentData.id = segmentSnapshot.id
        const videoSnapshot = await getFirestoreDocument(_segmentData.video)
        const videoData = videoSnapshot.data() as IVideo
        const videoUrlRefString = getPathFramesJson(
          fsUser.workspace.id,
          _segmentData.video.id,
          videoData.duplicateOf || '',
        )
        const videoUrlRef = ref(storage, videoUrlRefString)
        const videoUrl = await getDownloadURL(videoUrlRef)
        _segmentData.url = videoUrl
        setSegmentData(_segmentData)
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.signLanguageId, fsUser.workspace.id, signDoc])

  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const errors: string[] = []
      animationProblems
        .filter((animationProblem) => animationProblem.checked == true)
        .forEach((animationProblem) => {
          errors.push(animationProblem.id)
        })
      if (otherProblem.trim() != '') {
        errors.push(otherProblem)
      }

      let currentAnimationHasErrors: boolean | null = null
      if (errors.length == 0) {
        currentAnimationHasErrors = false
        errors.push('NO_ERRORS')
      } else {
        currentAnimationHasErrors = true
      }
      const _currentAnimation = currentAnimation
      if (_currentAnimation) _currentAnimation.errors = errors
      const currentAnimationReviewedAt = serverTimestamp() as Timestamp
      runTransaction(firestore, async (transaction) => {
        transaction.update(signDoc.ref, {
          currentAnimationHasErrors: currentAnimationHasErrors,
          currentAnimation: _currentAnimation,
          currentAnimationReviewedAt,
        })

        if (currentAnimation?.animation) {
          transaction.update(currentAnimation.animation as DocumentReferenceClient, {
            errors: errors,
            reviewedBy: fsUserRef,
            reviewedAt: currentAnimationReviewedAt,
          })
        }
      })
      endJobMeter()

      await addContribAnimationReviewFunction({
        signId: signDoc.id,
        userId: fsUserRef.id,
        workspaceId: fsUser.workspace.id as WorkspaceId,
      })
      setIsLoading(false)
      setLocation('/reviewAnimation')
    } catch {
      setIsLoading(false)
      setLocation('/reviewAnimation')
    }
  }

  const handOnChangeOtherProblem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherProblem(event.target.value)
  }

  const handleOnChange = (problem: IAnimationProblem, checked: boolean) => {
    const _animationProblems = [...animationProblems]
    const index = _animationProblems.findIndex((animationProblem) => animationProblem.id == problem.id)
    if (index > -1) {
      _animationProblems[index].checked = checked
    }
    setAnimationProblems(_animationProblems)
  }

  const handleSkip = () => {
    setLocation('/reviewAnimation')
  }

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const handleExportHTA = async () => {
    setIsLoading(true)
    try {
      if (signData?.currentAnimation?.animation) {
        const body = JSON.stringify({
          animationId: signData.currentAnimation.animation.id,
          workspaceId: fsUser.workspace.id,
        })

        await fetch('https://hta-export-n6wkcl65ba-uc.a.run.app/convert_to_hta', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        })
        // window.location.reload()
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.reviewAnimation')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  // Se o sinal estiver sendo editado, não poderá ser visualizado
  if (signData && (isExtractingKeypoints.value || isStudioFileRecording.value)) {
    return (
      <Layout
        title={intl.get('modules.reviewAnimation')}
        includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
      >
        <CardMessage
          title={`${
            (isCropping.value && intl.get('message.signIsBeingCutted')) ||
            (isExtractingKeypoints.value && intl.get('message.signIsExtractingKeypoints')) ||
            (isStudioFileRecording.value && intl.get('message.signIsRecording'))
          }.`}
          subtitle={intl.get('message.tryAgainLater')}
        />
      </Layout>
    )
  }

  return (
    <Layout
      title={intl.get('modules.reviewAnimation')}
      requiredModule={'reviewAnimation'}
      includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
    >
      {/** Exibe o preload ja que está carregando */}
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingAndSaving')}></Preloader>}

      <AnimationHomeContainer>
        <Grid container justifyContent="center">
          <Typography align="center" variant="h5">
            {intl.get('pages.reviewAnimation.problemsOnThisAnimation')}
          </Typography>
        </Grid>

        <BoxContainer justifyContent="space-between" container>
          {/* Vídeo do sinal atual */}
          <Box>
            <SignViewContainer>
              {segmentData && (
                <FramePlayer
                  reference={segmentData.id}
                  onError={() => ({})}
                  onReady={() => ({})}
                  onChangedTime={handleOnChangedTime}
                  frameRatio={30}
                  framesDuration={segmentData.endFrame}
                  timeFrame={appConfig.frameRate}
                  src={segmentData.url || ''}
                  startFrame={segmentData.startFrame}
                  timelineCursor={timelineCursor}
                  autoPlay={true}
                  autoPlayEnabled={true}
                  mirrorEnabled={true}
                />
              )}
            </SignViewContainer>
            <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {intl.get('pages.hTube.sign.relatedWords')}
            </Typography>
            {signData.relatedWords.map((word) => (
              <Typography variant="body2" key={word}>
                {word}
              </Typography>
            ))}
          </Box>

          <AnimateDivider orientation="vertical" />

          <Grid>
            {/* Exibição de uma animação  */}
            <AnimationView
              signData={signData}
              rest={true}
              repeat={false}
              autoPlay={false}
              speedControls={true}
              defaultSpeed={defaultSpeed}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speed={speed}
              setSpeed={setSpeed}
              textFast={textFast}
              textMedium={textMedium}
              textLow={textLow}
            />
          </Grid>

          <AnimateDivider orientation="vertical" />

          <Grid>
            <Typography style={{ marginLeft: -2 }} variant="h6">
              {intl.get('messages.animator')}:{' '}
              {(signData.currentAnimation &&
                workspaceUsers[signData.currentAnimation?.createdBy.id] &&
                workspaceUsers[signData.currentAnimation?.createdBy.id].displayName) ||
                ''}
            </Typography>
            {animationProblems.map((problem) => (
              <Grid item key={problem.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={problem.checked}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleOnChange(problem, checked)
                      }
                      style={{ padding: '5px' }}
                    />
                  }
                  label={<Typography variant="body2">{problem.description}</Typography>}
                />
              </Grid>
            ))}
            <Grid item>
              <TextField
                value={otherProblem}
                style={{ marginTop: '10px', marginLeft: '-5px' }}
                size="small"
                label={intl.get('pages.reviewAnimation.otherProblem')}
                variant="outlined"
                onChange={handOnChangeOtherProblem}
              />
            </Grid>
          </Grid>
          <BoxContainer justifyContent="space-between" container>
            <Box display="flex">
              <Box mr={2}>
                <Button variant="outlined" onClick={handleSkip}>
                  {intl.get('messages.skip')}
                </Button>
              </Box>
              <Box mr={2}>
                <Button variant="outlined" onClick={handleExportHTA}>
                  {intl.get('pages.reviewAnimation.exportHta')}
                </Button>
              </Box>
            </Box>
            <Button style={{ background: green[700], color: '#fff' }} variant="contained" onClick={handleSave}>
              {animationProblems.filter((problem) => problem.checked == true).length > 0 || otherProblem.trim() != ''
                ? intl.get('messages.save')
                : intl.get('pages.reviewAnimation.correctAnimation')}
            </Button>
          </BoxContainer>
        </BoxContainer>
      </AnimationHomeContainer>
    </Layout>
  )
}

export default ReviewAnimation
