import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import LayoutPure from './Pure'
import { StandardAppBar } from '../AppBar'
import { CommunityFeatures, CommunityModule } from 'collections'
import useFeatureToggle from '../../services/hooks/useFeatureToggle'
import CardMessage from '../CardMessage'
import { useFsUserDocData } from '../../hooks/useFsUser'
import Maintenance from './Maintenance'
import { useFsSettingsDocData } from '../../hooks/useFsSettingsDocData'
import useIntl from '../../hooks/useIntl'
import useTheme from '../../services/hooks/useTheme'
import { useUser } from 'reactfire'

interface IControlledLayout {
  /** Título do header */
  title: string
  /** Imagem de fundo do Layout */
  imageBg?: boolean
  /** Id para acesso ao elemento no DOM */
  id?: string
  /** Impede a visualização de children quando a feature nao estiver ativada */
  requiredFeature?: CommunityFeatures
  /** Adiciona a necessidade de ter acesso a um módulo para acessar a rota */
  requiredModule?: CommunityModule
  /** Adiciona um elemento customizado */
  includeCustomElem?: JSX.Element
}

const ControlledLayout: React.FC<IControlledLayout> = ({
  children,
  title,
  imageBg,
  id,
  requiredFeature,
  requiredModule,
  includeCustomElem,
}) => {
  const [{ type }] = useTheme()
  const fullTitle = `HT Community${title ? ` | ${title}` : ''}`
  const [{ featuresMap }] = useFeatureToggle()
  const fsUser = useFsUserDocData()
  const settings = useFsSettingsDocData()
  const intl = useIntl()
  const fbUser = useUser<firebase.default.User>()
  const [isMaintainer, setIsMaintener] = useState(false)
  const workspaceId = fsUser.workspace.id

  useEffect(() => {
    const call = async () => {
      const tokenResult = await fbUser.data?.getIdTokenResult()
      const _isMaintainer: boolean = !!tokenResult?.claims.isMaintainer

      setIsMaintener(_isMaintainer)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const hasFeature = requiredFeature ? featuresMap[requiredFeature] : true
  const hasModule = requiredModule ? fsUser?.modules.includes(requiredModule) : true
  const hasPermission = hasFeature && hasModule

  const FeatureNotAllowedCard = (
    <CardMessage
      title={intl.get('components.layout.featureLocked')}
      subtitle={intl.get('components.layout.talkToAdmin')}
    />
  )

  if (settings?.isMaintenanceMode && !isMaintainer) {
    return <Maintenance />
  }

  // Autenticação não obrigatória
  return (
    <LayoutPure
      appBar={
        <StandardAppBar
          title={title}
          includeCustomElem={includeCustomElem}
          communityModule={requiredModule}
          workspace={workspaceId}
        />
      }
      imageBg={imageBg}
      id={id}
      themeColor={type}
    >
      <Helmet>
        <title>{fullTitle}</title>
      </Helmet>
      {!hasPermission && FeatureNotAllowedCard}
      {hasPermission && children}
    </LayoutPure>
  )
}

export default ControlledLayout
