import React, { FC } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const indigo = '#2296F3'
const orange = '#D46D2A'
const teal = '#26a7a0'

const StyledPaper = styled(Paper)`
  && {
    background-color: ${(props) =>
      (props.color === 'indigo' && indigo) || (props.color === 'orange' && orange) || teal};
    color: #fff;
  }
`

const StyledBannerPaper = styled(Paper)`
  overflow: hidden;
`

interface ISignViewContainerProps {
  /** Conteúdo principal, você pode renderizar o video do sinal, icone ou o que desejar */
  bannerEl?: JSX.Element | null | false
  /** Rodapé com detalhes extras sobre o sinal  */
  footerEl?: JSX.Element | null | false
  /* Callback chamado quando o action de remoção for clicado */
  onRemove?: () => void
  /** Cor de fundo do container */
  color: 'indigo' | 'orange' | 'teal'
}

/**
 * Caixa de apoio à exibição de sinais, datilologia ou outras ações
 * */
export const SignViewContainerPure: FC<ISignViewContainerProps> = ({ bannerEl, footerEl, onRemove, color }) => {
  const action = (
    <>
      {onRemove && (
        <Box position="absolute" top={5} right={5}>
          <Fab size="small" color="secondary" onClick={onRemove}>
            <DeleteForeverIcon fontSize="small" />
          </Fab>
        </Box>
      )}
    </>
  )

  const banner = (
    <>
      {bannerEl && (
        <StyledBannerPaper>
          <Box position="relative" width="100%" paddingTop="100%">
            <Box position="absolute" width="100%" height="100%" top={0} left={0}>
              {bannerEl}
            </Box>
            {action}
          </Box>
        </StyledBannerPaper>
      )}
    </>
  )
  const footer = <>{footerEl && <Box>{footerEl}</Box>}</>

  return (
    <Box>
      <StyledPaper color={color}>
        <Box p={1} position="relative" display="flex" flexDirection="column">
          {banner}
          {footer}
        </Box>
      </StyledPaper>
    </Box>
  )
}

export default SignViewContainerPure
