import { Box, createStyles, TextField, Theme, WithStyles } from '@material-ui/core'
import useIntl from '../../hooks/useIntl'
import Layout from '../../components/Layout'
import { getAllUsersFunction } from '../../services/firebase'
import { ChangeEvent, useEffect, useState } from 'react'
import { IWorkspace, preProcess, getUser, IUser, CommunityModule, updateUser, IWorkspacePermissions } from 'collections'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { doc } from '@firebase/firestore'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import CardMessage from '../../components/CardMessage'
import ModalIncludeUser from './ModalIncludeUser'
import UsersTable from './UsersTable'
import { collection, DocumentSnapshot, getDocs } from 'firebase/firestore'
import useSnackbar from '../../services/hooks/useSnackbar'
import Preloader from '../../components/Preloader'

interface IUpdatePermissionsProps {
  path?: string
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const UpdatePermissions: React.FC<IUpdatePermissionsProps> = () => {
  const intl = useIntl()
  const showSnackbar = useSnackbar()
  const fsUser = useFsUserDocData()
  const firestore = useFirestore()
  const ref = doc(firestore, fsUser.workspace.path)
  const { status, data: workspaceData } = useFirestoreDocData(ref)
  const hasPermissionOnModule = useCheckHasPermission('updatePermissions')
  const [workspaceUsers, setWorkspaceUsers] = useState<
    Record<
      string,
      {
        displayName?: string
        email?: string
        photoURL?: string
        workspaceId?: string
        permissions?: IWorkspacePermissions
      }
    >
  >()
  const [workspaceUsersToShow, setWorkspaceUsersToShow] = useState<
    Record<
      string,
      {
        displayName?: string
        email?: string
        photoURL?: string
        workspaceId?: string
        permissions?: IWorkspacePermissions
      }
    >
  >()
  const [userData, setUserData] = useState<{
    [UserId: string]: {
      photoURL: string
      displayName: string
      roles: Record<string, boolean>
    }
  }>()

  const [workspaces, setWorkspaces] = useState<string[]>([])
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const [selectedUser, setSelectedUser] = useState<
    | {
        id: string
        name: string
        email: string
        workspaceId: string
        userRole: string
        permissions: IWorkspacePermissions
        isExternal?: boolean
      }
    | undefined
  >(undefined)
  const [openUser, setOpenUser] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const getUsers = async () => {
    try {
      setWorkspaceUsers(undefined)
      setIsLoading(true)

      const { data } = await getAllUsersFunction({})

      const users = Object.fromEntries(
        Object.entries(data.users).sort((a, b) => {
          const nameA = a[1].displayName || ''
          const nameB = b[1].displayName || ''
          return nameA > nameB ? 1 : nameB > nameA ? -1 : 0
        }),
      )

      getUserRoles(users)
    } finally {
      setIsLoading(false)
      if (searchText) handleSearchUser()
    }
  }

  const getUserRoles = async (
    users: Record<
      string,
      {
        displayName?: string
        email?: string
        photoURL?: string
        workspaceId?: string
        permissions?: IWorkspacePermissions
      }
    >,
  ) => {
    const rolesList: { [id: string]: string[] } = {
      signSentence: [],
      segmentVideo: [],
      findIdenticalSegments: [],
      validateTranslation: [],
      animation: [],
      reviewAnimation: [],
      hTube: [],
      signOnDemand: [],
      phraseManager: [],
      processVideo: [],
      abTestNmt: [],
      abTestNmtResults: [],
      updatePermissions: [],
      videoManager: [],
    }
    if (status == 'error') return
    const workspace = workspaceData as IWorkspace

    if (!workspace.userData) return
    Object.entries(workspace.userData).map((el) => {
      if (users[el[0]]) {
        const displayName = users[el[0]].displayName
        Object.entries(el[1].roles || {}).map((element) => {
          if (element[1] && rolesList[element[0]]) rolesList[element[0]].push(displayName || '')
        })
      }
    })

    setUserData(workspace.userData)
    setWorkspaceUsersToShow(users)
    setWorkspaceUsers(users)
  }

  const getWorkspaces = async () => {
    try {
      const workspaceCollection = collection(firestore, 'workspaces')
      const _workspaces: string[] = []
      const workspaceRef = await getDocs(workspaceCollection)
      workspaceRef.docs.map((workspace: DocumentSnapshot) => _workspaces.push(workspace.id))
      setWorkspaces(_workspaces)
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const updateUserModules = async (user: string, modules: CommunityModule[], isExternal: boolean) => {
    const userRef = doc(firestore, 'users', user)
    const userSnapshot = await getUser(userRef)
    const userData = userSnapshot.data() as IUser

    userData.modules = modules
    userData.isExternal = isExternal
    await updateUser(userRef, userData)
  }

  const handleChangeSearchText = (
    event: ChangeEvent<{
      name?: string | undefined
      value: unknown
      key?: unknown
    }>,
  ) => {
    const _searchText = event.target.value as string
    setSearchText(_searchText)
  }

  const handleSearchUser = () => {
    if (searchText == '') {
      setWorkspaceUsersToShow(workspaceUsers)
      return
    }

    if (!workspaceUsers) return
    const _workspaceUsers = Object.fromEntries(
      Object.entries(workspaceUsers).filter((user) => {
        const _displayName = preProcess(user[1].displayName || '', fsUser?.workspace.id || '', true, true, false, false)

        const _searchText = preProcess(searchText, fsUser?.workspace.id || '', true, true, false, false)

        return _displayName.includes(_searchText)
      }),
    )

    setWorkspaceUsersToShow(_workspaceUsers)
  }
  const handleClickAddUserBtn = () => {
    setOpenUser(true)
  }

  const modulesKey = [
    'signSentence',
    'segmentVideo',
    'findIdenticalSegments',
    'validateTranslation',
    'animation',
    'reviewAnimation',
    'hTube',
    'signOnDemand',
    'phraseManager',
    'processVideo',
    'abTestNmt',
    'abTestNmtResults',
    'updatePermissions',
    'manuallyTranslation',
    'videoManager',
  ]

  useEffect(() => {
    getWorkspaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData])

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSearchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.updatePermissions')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.updatePermissions')}>
      <Box width="70%" p={2}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            id="outlined-basic"
            label="Pesquisa"
            variant="outlined"
            style={{ marginBottom: '5px' }}
            onChange={handleChangeSearchText}
          />
        </Box>
        {!workspaceUsers && isLoading && <Preloader text={intl.get('messages.loadingData')} />}
        {workspaceUsers && (
          <UsersTable
            workspaceUsers={workspaceUsers}
            workspaceUsersToShow={workspaceUsersToShow}
            userData={userData}
            handleClickAddUserBtn={() => {
              handleClickAddUserBtn()
            }}
            setSelectedModules={setSelectedModules}
            setSelectedUser={setSelectedUser}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
        <ModalIncludeUser
          openUser={openUser}
          setOpenUser={setOpenUser}
          workspaces={workspaces}
          modules={modulesKey}
          selectedModules={selectedModules}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          updateUserModules={updateUserModules}
          getUsers={getUsers}
        />
      </Box>
    </Layout>
  )
}

export default UpdatePermissions
