import React from 'react'
import styled, { css } from 'styled-components'
import FramePlayer from '../../../components/FramePlayer'
import { IMenuOptions, ISegmentView, ITimelineCursorState } from 'collections'

interface IHoverablePlayerPureProps {
  /**
   * Segmento proveniente do firebase, adicionado com o URL de seu vídeo/framesJson
   */
  segment: ISegmentView
  /**
   * Habilita, desabilita o auto play do video através do hovering
   */
  isHovering: boolean
  /**
   * Função para mudar frames do segmento
   */
  handleOnChangedTime: (value: number) => void
  /**
   * Função para comportamento quando o mouse estiver em cima do player
   */
  handleOnMouseEnter: () => void
  /**
   * Função para comportamento quando o mouse sair do player
   */
  handleOnMouseLeave: () => void
  /**
   * Tempo em frames
   */
  timeFrame: number
  /**
   * Cursor da timeline
   */
  timelineCursor: ITimelineCursorState
  /** Altura 100% */
  fullHeight?: boolean
  /** Player está sendo executado */
  isPlaying?: boolean
  /** Menu component */
  menu?: IMenuOptions[]
  /** Exibe opção de ver segmento em tela cheia */
  showFullScreenSegment?: boolean
}

interface IFrameBoxStyleProps {
  fullHeight?: boolean
}

const FrameBox = styled.div`
  border-radius: 4px;
  background-color: #000;
  padding: 4px;
  width: 100%;
  cursor: pointer;
  ${({ fullHeight }: IFrameBoxStyleProps) =>
    css`
      height: ${fullHeight ? '100%' : 'auto'};
    `}
`

const HoverablePlayerPure: React.FC<IHoverablePlayerPureProps> = ({
  segment,
  isHovering,
  handleOnChangedTime,
  handleOnMouseEnter,
  handleOnMouseLeave,
  timeFrame,
  timelineCursor,
  fullHeight,
  isPlaying,
  menu,
  showFullScreenSegment,
}) => {
  return (
    <FrameBox onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} fullHeight={fullHeight}>
      <FramePlayer
        reference={segment.id}
        src={segment.videoUrl || ''}
        onReady={() => ({})}
        onError={() => ({})}
        startFrame={segment.startFrame}
        framesDuration={segment.endFrame}
        timeFrame={timeFrame}
        frameRatio={30}
        onChangedTime={handleOnChangedTime}
        timelineCursor={timelineCursor}
        autoPlay={isHovering || isPlaying}
        hoverable={true}
        fullHeight={fullHeight}
        fullVideoDuration={segment.videoDuration}
        videoSentence={segment.videoSentence}
        handleOnMouseLeave={handleOnMouseLeave}
        menuOptions={menu}
        showFullScreenSegment={showFullScreenSegment}
      />
    </FrameBox>
  )
}

export default HoverablePlayerPure
