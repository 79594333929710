import React, { useState, useCallback } from 'react'
import { useAuth } from 'reactfire'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

import logger from '../services/logger'
import useSnackbar from '../services/hooks/useSnackbar'
import Preloader from './Preloader'
import { sendPasswordResetEmail, signInWithEmailAndPassword } from '@firebase/auth'
import { Box, Link, Typography } from '@material-ui/core'
import googleImg from '../images/googleIcon.svg'
import { useLocation } from 'wouter'
import { routes } from '../community'
import { GoogleAuthProvider, getAdditionalUserInfo, signInWithPopup } from 'firebase/auth'
import useIntlNoAuth from '../hooks/useIntlNoAuth'
import { OralLanguageId } from 'collections'
import { useLocalStorage } from '../hooks/useLocalStorage'

const { error } = logger('Auth')

const CardStyled = styled(Card)`
  width: 400px;
`

const FullScreenGrid = styled(Grid)`
  width: 100%;
  height: 100%;
`

const CardActionsStyled = styled(CardActions)`
  float: right;
`

const Auth: React.FC = () => {
  const [currentForm, setCurrentForm] = useState<'login' | 'recovery'>('login')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hasEmailError, setHasEmailError] = useState(false)
  const [hasPasswordError, setHasPasswordError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [lang, setLang] = useState<OralLanguageId>(OralLanguageId.eng)
  const [, setLocation] = useLocation()
  const intl = useIntlNoAuth(lang)
  const { setLocalStorageValue } = useLocalStorage()
  const showSnackbar = useSnackbar()

  // O type de useAuth está errado! esse cast resolve o problema
  const auth = useAuth()

  const provider = new GoogleAuthProvider()

  const getLang = async (latitude: number, longitude: number) => {
    let json: {
      results: {
        address_components: { long_name: string; short_name: string }[]
        types: string[]
      }[]
    }
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`,
      )
      json = await response.json()
      const results = json.results.filter((result) => {
        return result.types.includes('country')
      })

      let _lang: OralLanguageId
      if (results[0] && results[0].address_components[0] && results[0].address_components[0].short_name == 'BR')
        _lang = OralLanguageId.por
      else _lang = OralLanguageId.eng

      setLang(_lang)
      setLocalStorageValue('defaultLanguage', _lang)
    } catch (error) {
      console.log('error: ', error)
    }
  }

  if ('geolocation' in navigator) {
    /* geolocation is available */
    navigator.geolocation.getCurrentPosition((position) => {
      getLang(position.coords.latitude, position.coords.longitude)
    })
  } else {
    alert(intl.get('login.messages.errorGeolocation'))
  }

  /**
   * Efetua login com usuário e senha informados nos campos
   */
  const login = useCallback(async () => {
    setHasEmailError(email.length === 0)
    setHasPasswordError(password.length === 0)

    if (email.length > 0 && password.length > 0) {
      try {
        setIsLoading(true)
        await signInWithEmailAndPassword(auth, email, password)
      } catch (err) {
        setPassword('')
        setHasEmailError(true)
        setHasPasswordError(true)
        error(err)
        showSnackbar((err as TypeError | RangeError | EvalError).message, {
          variant: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      showSnackbar(intl.get('login.messages.specifyEmailPassword'), {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, email, password, showSnackbar])

  /**
   * Envia o email de recuperação de senha
   */
  const recovery = useCallback(async () => {
    setHasEmailError(email.length === 0)
    if (email.length > 0) {
      try {
        setIsLoading(true)
        await sendPasswordResetEmail(auth, email)
        showSnackbar(intl.get('login.messages.resetPassword'), {
          variant: 'success',
        })
        setEmail('')
      } catch (err) {
        error(err)
        showSnackbar((err as TypeError | RangeError | EvalError).message, {
          variant: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      showSnackbar(intl.get('login.messages.specifyEmail'), {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, email, showSnackbar])

  /**
   * Campo de email foi alterado
   */
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim())
    setHasEmailError(event.target.value.length === 0)
  }

  /**
   * Campo de email foi alterado
   */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setHasPasswordError(event.target.value.length === 0)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      login()
    }
  }

  const handleSignWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        const additionalUserInfo = getAdditionalUserInfo(userCredential)
        if (additionalUserInfo?.isNewUser) {
          showSnackbar(intl.get('login.messages.userNotFound'), {
            variant: 'error',
          })
          userCredential.user.delete()
        } else {
          setLocation(routes.home)
        }
      })
      .catch((error) => {
        // Handle Errors here.
        console.log('error: ', error)
        const errorCode = error.code
        const errorMessage = error.message
        showSnackbar(`${errorCode} ': ' ${errorMessage}`, {
          variant: 'error',
        })
        // // The email of the user's account used.
        // const email = error.customData.email
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }

  const formTitle = currentForm === 'login' ? intl.get('login.name') : intl.get('login.recoveryPassword')

  const form = (
    <>
      <CardStyled>
        <CardContent>
          <Typography
            variant="h5"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            {formTitle}
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Button
              size="medium"
              variant="outlined"
              color="default"
              onClick={handleSignWithGoogle}
              style={{ textTransform: 'none' }}
            >
              <img alt={'Google image'} src={googleImg} style={{ marginRight: '10px' }} />
              <Typography>{intl.get('login.signWithGoogle')}</Typography>
            </Button>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
                textTransform: 'none',
              }}
            >
              {intl.get('login.or')}
            </Typography>
          </Box>

          <TextField
            fullWidth
            value={email}
            type="email"
            onChange={handleEmailChange}
            label={intl.get('login.email')}
            required
            error={hasEmailError}
            style={{ marginBottom: 10 }}
          />

          {currentForm === 'login' && (
            <TextField
              fullWidth
              value={password}
              type="password"
              onChange={handlePasswordChange}
              label={intl.get('login.password')}
              error={hasPasswordError}
              onKeyDown={handleKeyDown}
            />
          )}
        </CardContent>
        <CardActionsStyled>
          <Box>
            {currentForm === 'recovery' && (
              <>
                <Button style={{ textTransform: 'none' }} color="primary" onClick={() => setCurrentForm('login')}>
                  {intl.get('login.returnLogin')}
                </Button>
                <Button style={{ textTransform: 'none' }} color="primary" variant="contained" onClick={recovery}>
                  {intl.get('login.recovery')}
                </Button>
              </>
            )}
            {currentForm === 'login' && (
              <>
                <Button color="primary" onClick={() => setCurrentForm('recovery')} style={{ textTransform: 'none' }}>
                  {intl.get('login.forgotPassword')}
                </Button>
                <Button color="primary" variant="contained" onClick={login} style={{ textTransform: 'none' }}>
                  {intl.get('login.name')}
                </Button>
              </>
            )}
          </Box>
        </CardActionsStyled>
      </CardStyled>

      <Typography variant="body1" gutterBottom style={{ marginTop: '10px' }}>
        {intl.get('login.dontHaveAccount')}{' '}
        <Link
          onClick={() => {
            setLocation(routes.registration)
          }}
          style={{ cursor: 'pointer' }}
        >
          {intl.get('login.register')}
        </Link>
        {/* Cadastre-se */}
      </Typography>
    </>
  )
  const loader = <Preloader text="Authenticating..." />
  return (
    <>
      <FullScreenGrid container item xs justifyContent="center" alignItems="center" direction="column">
        {!isLoading && form}
        {isLoading && loader}
      </FullScreenGrid>
    </>
  )
}

export default Auth
