import { FC } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useIntl from '../../hooks/useIntl'
import FramePlayer from '../FramePlayer'
import { IGlobalAttr } from 'collections'
import { useGlobal } from 'reactn'
import useTimelineCursor from '../../hooks/useTimelineCursor'
import { Button } from '@material-ui/core'

export interface IApplicationDialogProps {
  open: boolean
  /**
   * Identifica o elemento (ou elementos) que rotula o elemento atual.
   * */
  labelledby: string
  toggleOpen?: () => void
  videoUrl: string
  videoDuration: number
  videoSentence?: string
  framesVideo?: string[]
  startFrame?: number
}
export const ShowVideoDialog: FC<IApplicationDialogProps> = ({
  open,
  labelledby,
  toggleOpen,
  videoDuration,
  videoUrl,
  framesVideo,
  videoSentence,
  startFrame,
}) => {
  const intl = useIntl()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  return (
    <Dialog id={labelledby} open={open} aria-labelledby={labelledby} maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">{videoSentence}</DialogTitle>

      <>
        <DialogContent style={{ overflow: 'auto' }}>
          <FramePlayer
            onError={() => {
              console.log('error')
            }}
            onReady={() => ({})}
            onChangedTime={handleOnChangedTime}
            frameRatio={30}
            framesDuration={videoDuration}
            timeFrame={1000 / appConfig.frameRate}
            src={videoUrl}
            timelineCursor={timelineCursor}
            startFrame={startFrame || 0}
            communityModule={'processVideo'}
            framesVideo={framesVideo}
          />
        </DialogContent>
        {/** Mostraremos os actions parametrizados  */}
        <DialogActions>
          <Button onClick={toggleOpen} color="primary" variant="contained">
            {intl.get('messages.close')}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default ShowVideoDialog
