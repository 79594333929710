import React from 'react'
import { useLocation } from 'wouter'
import { LockModuleDialogPure } from './Pure'
import imageUrl from '../../images/lock-module-hugo-reduzido.png'

interface ILockModuleDialogProps {
  open: boolean
  toggleOpen: () => void
}

const LockModuleDialog: React.FC<ILockModuleDialogProps> = ({ open, toggleOpen }) => {
  const [location, setLocation] = useLocation()
  const handleOKClick = () => {
    toggleOpen()
    if (location !== '/') {
      setLocation('/')
    }
  }

  return (
    <LockModuleDialogPure labelledby="report-dialog" open={open} imageUrl={imageUrl} handleOKClick={handleOKClick} />
  )
}

export default LockModuleDialog
