import { FC } from 'react'

import Toolbar from '@material-ui/core/Toolbar'
import MuiAppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

import whitebrand from './whitebrand.svg'
import { CardMedia } from '@material-ui/core'

export interface IAppBarProps {
  /** Título da página */
  title?: string
  /** Callback para clicks na imagem */
  onImageClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      cursor: 'pointer',
      width: 140,
      height: 40,
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
      },
      backgroundSize: 'auto',
    },
    appBar: {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.dark : theme.palette.grey[900],
      zIndex: 3,
    },
    button: {
      marginLeft: 10,
    },
    toolbar: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px 8px 0px 0px',
      },
    },
    title: {
      width: '20%',
      fontSize: '1em',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
    },
  }),
)

export const AppBarPure: FC<IAppBarProps> = ({ title, children, onImageClick }) => {
  const classes = useStyles()

  const handleImageClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onImageClick) onImageClick(event)
  }

  return (
    <MuiAppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <CardMedia className={classes.logo} image={whitebrand} onClick={handleImageClick} />
        {title && (
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        )}

        <Grid item xs />
        {children}
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBarPure
