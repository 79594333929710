import React, { useState } from 'react'

import Fab from '@material-ui/core/Fab'
import Autorenew from '@material-ui/icons/Autorenew'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined'
import useCamera from '../../services/hooks/useCamera'
import SecondsIncreaseDecrease from './SecondsIncreaseDecrease'
import useSentence from '../../hooks/useSentence'
import useRecordingDuration from '../../hooks/useRecordingDuration'
import useCountdown from '../../hooks/useCountdown'
import { CommunityModule } from 'collections'
import useIntl from '../../hooks/useIntl'
import { updateQueuePriorityFunction } from '../../services/firebase'
import { useFsUserDocData } from '../../hooks/useFsUser'
import ConfirmationDialog from '../ConfirmationDialog'
import { Grid } from '@material-ui/core'

interface ITextHeader {
  showDurationChanger?: boolean
  communityModule: CommunityModule
  text?: string
}

const TextHeader: React.FC<ITextHeader> = ({ showDurationChanger, communityModule, text }) => {
  const [sentenceState, sentenceFns] = useSentence(communityModule)
  const [videoDuration, videoDurationFns] = useRecordingDuration()
  const [countdownState] = useCountdown()
  const [cameraState] = useCamera()
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const [opennedDialog, setOpennedDialog] = useState(false)

  const seconds = (videoDuration / 1000).toFixed(2)

  const handleChangeSentence = () => {
    sentenceFns.refresh()
  }

  const toggleOpenDialog = () => {
    setOpennedDialog(!opennedDialog)
  }

  const openDialogReport = () => {
    toggleOpenDialog()
  }

  const handleReportSentence = async () => {
    // Altera a prioridade na fila reduz 50%
    updateQueuePriorityFunction({
      oralLanguageId: fsUser.oralLanguageId,
      signLanguageId: fsUser.signLanguageId,
      sentence: sentenceState.sentence || '',
      isCorrect: true,
      workspaceId: fsUser.workspace.id,
      sentenceOrigin: (sentenceState.origin || 'APP').toUpperCase(),
      reported: communityModule === 'signSentence',
      corpusGroup: sentenceState.corpusGroup,
      sentenceCategory: sentenceState.sentenceCategory || ['undefined'],
      clientId: sentenceState.clientId,
    })
    sentenceFns.refresh()
    toggleOpenDialog()
  }

  const content = (
    <>
      {!sentenceState.isLoading ? (
        <Typography
          variant="h4"
          style={{
            textTransform: 'uppercase',
            fontSize: 'clamp(1rem, 2.5vw, 2rem)',
          }}
          align="center"
        >
          {communityModule === 'signOnDemand' || communityModule === 'hTube' ? text : sentenceState.sentence}
        </Typography>
      ) : (
        <Skeleton variant="text" width={350} />
      )}
    </>
  )

  return (
    <Box mt={1} display="flex" width="100%" justifyContent="center" alignItems="center" flexDirection="column">
      {showDurationChanger && !cameraState.isRecording && (
        <Box width="fit-content">
          <Card>
            <Box p={1}>
              <SecondsIncreaseDecrease
                text={intl.get('components.textHeader.text')}
                unidadeTempo={intl.get('components.textHeader.timeUnity')}
                seconds={seconds}
                durationDecease={videoDurationFns.decrease}
                durationIncrease={videoDurationFns.increase}
              />
            </Box>
          </Card>
        </Box>
      )}
      <Box mt={1} maxWidth="70%">
        <Card>
          <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
            <Grid
              item
              xs={12}
              md={communityModule == 'signSentence' && 10}
              lg={communityModule == 'signSentence' && 11}
            >
              {content}
            </Grid>
            {/** Se signSentenceBeta estiver ativado, nao exibiremos o botão de alterar frase durante a gravação  */}
            <Grid item xs={12} md={communityModule == 'signSentence' && 2} lg={communityModule == 'signSentence' && 1}>
              {communityModule !== 'signOnDemand' && communityModule !== 'hTube' && !cameraState.isRecording && (
                <Box
                  mt={1}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box position="relative" width={80} height={40}>
                    {sentenceState.isLoading && (
                      <Box position="absolute" left={0} top={0}>
                        <CircularProgress variant="indeterminate" />
                      </Box>
                    )}
                    <Box flexDirection="row" display="flex">
                      <Tooltip title={intl.get('components.textHeader.nextSentence')}>
                        <Box style={{ marginRight: '5px' }}>
                          <Fab
                            disabled={
                              sentenceState.isLoading || cameraState.isRecording || countdownState.countdownStep != 0
                            }
                            size="small"
                            color="primary"
                            onClick={handleChangeSentence}
                          >
                            <Autorenew />
                          </Fab>
                        </Box>
                      </Tooltip>
                      <Tooltip title={intl.get('messages.report')}>
                        <Box>
                          <Fab
                            disabled={
                              sentenceState.isLoading || cameraState.isRecording || countdownState.countdownStep != 0
                            }
                            size="small"
                            color="secondary"
                            onClick={openDialogReport}
                          >
                            <ReportOutlinedIcon />
                          </Fab>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
      </Box>
      <ConfirmationDialog
        open={opennedDialog}
        toggleOpen={toggleOpenDialog}
        handleYes={handleReportSentence}
        title={intl.get('messages.sendReport')}
        subtitle={intl.get('messages.confirmYourRport')}
      />
    </Box>
  )
}

export default TextHeader
