import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Typography from '@material-ui/core/Typography'
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import useIntl from '../../../hooks/useIntl'
import { useEffect } from 'react'
import { useState } from 'react'
import {
  ISignOnDemand,
  StatusSignOnDemand,
  serverTimestamp,
  Timestamp,
  addFirestoreDocument,
  getCollectionReference,
  updateFirestoreDocument,
  getDocumentReference,
  ISegmentView,
  getFirestoreDocument,
  DocumentSnapshot,
  getPathFramesJson,
  IVideo,
  accentRemove,
} from 'collections'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { ref } from 'firebase/database'
import { useDatabase, useDatabaseObjectData, useStorage } from 'reactfire'
import HoverablePlayer from '../../HTube/HoverablePlayer'
import { getDownloadURL, ref as refStorage } from 'firebase/storage'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '85vw',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    },
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

interface IModalIncludeSignOnDemand {
  data: ISignOnDemand | null
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleRefreshTable?: () => void
}

const initialRegionsOfUse = ['Norte', 'Nordeste', 'Centro-Oeste', 'Sudeste', 'Sul']

const ModalIncludeSignOnDemand: React.FC<IModalIncludeSignOnDemand> = ({ data, open, setOpen, handleRefreshTable }) => {
  const intl = useIntl()

  const editing = data != null
  const [signOnDemand, setSignOnDemand] = useState<ISignOnDemand | null>(editing ? { ...data } : null)

  const [textToAdd, setTextToAdd] = useState('')
  const showSnackbar = useSnackbar()
  const fsUser = useFsUserDocData()
  const db = useDatabase()
  const storage = useStorage()

  const originsReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`
  const originsRef = ref(db, originsReferenceString)
  const { status, data: originsData } = useDatabaseObjectData(originsRef)
  const [origins, setOrigins] = useState<string[]>([])
  const [regionOfUse, setRegionOfUse] = useState<string[]>(initialRegionsOfUse)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const openPopover = Boolean(anchorEl)
  const [segmentToView, setSegmentToView] = useState<ISegmentView | null>(null)

  const handleClose = () => {
    setOpen(false)
  }

  const getVideoUrl = async (videoId: string, duplicateOf: string) => {
    if (!videoId) return ''
    const urlString = getPathFramesJson(fsUser.workspace.id, videoId, duplicateOf)
    const url = await getDownloadURL(refStorage(storage, urlString))
    return url
  }

  const getSegmentData = async (segmentId?: string) => {
    if (!segmentId) {
      setSegmentToView(null)
      return
    }
    const segmentRef = getDocumentReference(fsUser.workspace, 'segments', segmentId)
    const segmentSnapshot = (await getFirestoreDocument(segmentRef)) as DocumentSnapshot

    const segmentData = segmentSnapshot.data() as ISegmentView

    if (!segmentData) {
      setSegmentToView(null)
      return
    }
    segmentData.id = segmentSnapshot.id
    const videoSnapshot = await getFirestoreDocument(segmentData.video)
    const videoData = videoSnapshot.data() as IVideo
    segmentData.videoUrl = await getVideoUrl(segmentData.video.id, videoData.duplicateOf || '')
    setSegmentToView(segmentData)
  }

  useEffect(() => {
    if (open) {
      setSignOnDemand(data ? { ...data } : null)
      setRegionOfUse(data?.regionOfUse || initialRegionsOfUse)
      getSegmentData(data?.segmentToView?.id || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    getOrigins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originsData])

  const handleSaveSignOnDemand = async () => {
    /** Verifica se todos os campos necessários estão preenchidos */
    if (!signOnDemand?.portuguese || !signOnDemand?.portuguese.length) {
      showSnackbar(
        intl.get('messages.requiredField', {
          field: intl.get('pages.signOnDemand.relatedWords'),
        }),
        { variant: 'error' },
      )
      return
    }
    showSnackbar(intl.get('messages.savingData'), { variant: 'info' })
    signOnDemand.glosa = signOnDemand.portuguese[0]
    const dateUpdate = serverTimestamp() as Timestamp
    signOnDemand.lastUpdate = dateUpdate
    signOnDemand.sentenceCategory = ['undefined']
    if (!editing) {
      signOnDemand.createdAt = dateUpdate
      signOnDemand.status = StatusSignOnDemand.pending
      signOnDemand.error = null
      signOnDemand.hta = null
      signOnDemand.isRecording = null
      signOnDemand.isSegmenting = null

      signOnDemand.segmentRef = null
      signOnDemand.videoRef = null
      signOnDemand.corpusGroup = 'TRAIN'
      signOnDemand.segmentToView = null
      signOnDemand.searchTerms = []
    }
    signOnDemand.regionOfUse = regionOfUse || []
    if (!signOnDemand.searchReference) {
      signOnDemand.searchReference = ''
    }
    if (!signOnDemand.description) {
      signOnDemand.description = ''
    }
    if (!signOnDemand.pieceOfSign) signOnDemand.pieceOfSign = false

    if (!(signOnDemand.sentenceOrigin || '').trim()) signOnDemand.sentenceOrigin = 'DEMAND'
    else {
      signOnDemand.sentenceOrigin = accentRemove(signOnDemand.sentenceOrigin || 'DEMAND')
        .trim()
        .toUpperCase()
    }
    signOnDemand.clientId = 'HT'

    handleClose()
    if (editing) {
      const id = signOnDemand.id
      if (id) {
        //Remove key id
        delete signOnDemand['id']
        await updateFirestoreDocument(getDocumentReference(fsUser.workspace, 'signsOnDemand', id), signOnDemand)
      }
    } else {
      await addFirestoreDocument(getCollectionReference(fsUser.workspace, 'signsOnDemand'), signOnDemand)
    }
    if (handleRefreshTable) handleRefreshTable()
    showSnackbar(intl.get('messages.savedSuccessfully'), { variant: 'success' })
  }

  const handleAddToPortuguese = () => {
    if (!textToAdd.trim()) return
    const _signOnDemand: ISignOnDemand = { ...signOnDemand }
    if (!_signOnDemand.portuguese) _signOnDemand.portuguese = []
    _signOnDemand.portuguese.push(textToAdd)
    setSignOnDemand(_signOnDemand || null)
    setTextToAdd('')
  }

  const handleDeleteFromPortuguese = (index: number) => {
    const _signOnDemand = { ...signOnDemand }
    _signOnDemand?.portuguese?.splice(index, 1)
    setSignOnDemand(_signOnDemand)
  }

  const handleChangeSignOnDemand = (
    attribute: keyof ISignOnDemand,
    event: React.ChangeEvent<HTMLInputElement>,
    checked?: boolean,
  ) => {
    const _signOnDemand: ISignOnDemand = { ...signOnDemand }

    if (checked != undefined) {
      //@ts-ignore
      _signOnDemand[attribute] = checked
    } else {
      //@ts-ignore
      _signOnDemand[attribute] = event.target.value
    }
    setSignOnDemand(_signOnDemand)
  }

  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _regionOfUse = [...regionOfUse]

    const found = _regionOfUse.find((el) => el === event.target.name)
    if (!found) _regionOfUse.push(event.target.name)
    else _regionOfUse.splice(regionOfUse.indexOf(event.target.name), 1)

    setRegionOfUse(_regionOfUse)
  }

  const getOrigins = async () => {
    if (status == 'error') return
    if (!originsData) return
    const originsObj = originsData as { id: number; origin: string }
    const origins: string[] = []
    const originsArray = Object.entries(originsObj)
    originsArray.map((value, index) => {
      if (originsArray.length - 1 == index) return
      const origin = value as string[]
      origins.push(origin[1])
    })
    setOrigins(origins)
  }

  const handleCloseSegment = () => {
    setAnchorEl(null)
  }

  const handlePreviewSegment = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <IconButton onClick={handlePreviewSegment}>
            <LiveTvIcon />
          </IconButton>
          <Typography variant="h6">
            {`${intl.get(editing ? 'pages.signOnDemand.update' : 'pages.signOnDemand.add')} - ${intl.get(
              'modules.signOnDemand',
            )}`}
          </Typography>
        </Box>
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleCloseSegment}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Card style={{ width: '300px' }}>
            {segmentToView && <HoverablePlayer showFullScreenSegment={true} segment={segmentToView} />}
          </Card>
        </Popover>
      </DialogTitle>
      <DialogContent dividers style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          value={signOnDemand?.searchReference || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeSignOnDemand('searchReference', event)}
          label={intl.get('pages.signOnDemand.reference')}
          variant="filled"
          style={{ marginTop: '10px' }}
          fullWidth
        />
        <TextField
          value={signOnDemand?.description || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeSignOnDemand('description', event)}
          label={intl.get('messages.description')}
          variant="filled"
          style={{ marginTop: '10px' }}
          fullWidth
        />
        <FormControl fullWidth style={{ marginTop: '10px' }}>
          <InputLabel id="demo-simple-select-label" style={{ marginLeft: '10px' }}>
            {intl.get('messages.sentenceOrigin')}
          </InputLabel>
          <Select
            id="grouped-select"
            label={intl.get('messages.sentenceOrigin')}
            style={{ color: 'inherit' }}
            variant={'filled'}
            value={signOnDemand?.sentenceOrigin || ''}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>,
            ) => {
              handleChangeSignOnDemand('sentenceOrigin', event as React.ChangeEvent<HTMLInputElement>)
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {origins &&
              origins.map((origin) => (
                <MenuItem key="0" value={origin}>
                  {origin}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={signOnDemand?.pieceOfSign || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                handleChangeSignOnDemand('pieceOfSign', event, checked)
              }
              style={{ padding: '10px' }}
              color={'primary'}
            />
          }
          label={<Typography variant="body2">{intl.get('pages.signOnDemand.pieceOfSign')}</Typography>}
        />
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={intl.get('messages.firstRelatedWordRepresentateSign')}>
            <TextField
              value={textToAdd}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTextToAdd(event.target.value)}
              label={intl.get('pages.signOnDemand.relatedWord')}
              variant="filled"
              style={{ marginTop: '10px' }}
              rowsMax={1}
              fullWidth
            />
          </Tooltip>
          <IconButton onClick={() => handleAddToPortuguese()} style={{ marginTop: '10px' }} aria-label="add-text">
            <AddCircleIcon />
          </IconButton>
        </Box>
        {(signOnDemand?.portuguese || []).length > 0 && (
          <Typography style={{ marginTop: '10px' }}>{intl.get('pages.signOnDemand.relatedWords')}</Typography>
        )}
        <List>
          {signOnDemand?.portuguese?.map((portuguese: string, index: number) => (
            <ListItem key={index} divider>
              <ListItemText primary={portuguese} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleDeleteFromPortuguese(index)} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <FormControl>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            {intl.get('pages.signOnDemand.corpusGroup.name')}
          </InputLabel>
          <Select defaultValue="TRAIN">
            <MenuItem value="TRAIN">{intl.get('pages.signOnDemand.corpusGroup.train')}</MenuItem>
            <MenuItem value="VALIDATION">{intl.get('pages.signOnDemand.corpusGroup.validation')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          component="fieldset"
          variant="standard"
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <FormLabel component="legend">{intl.get('pages.hTube.sign.region.name')}</FormLabel>
          <FormGroup
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={regionOfUse.includes('Norte')}
                  onChange={handleChangeRegion}
                  name="Norte"
                  color="primary"
                />
              }
              label={intl.get('pages.hTube.sign.region.north')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={regionOfUse.includes('Nordeste')}
                  onChange={handleChangeRegion}
                  name="Nordeste"
                  color="primary"
                />
              }
              label={intl.get('pages.hTube.sign.region.northEast')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={regionOfUse.includes('Sul')}
                  onChange={handleChangeRegion}
                  name="Sul"
                  color="primary"
                />
              }
              label={intl.get('pages.hTube.sign.region.south')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={regionOfUse.includes('Sudeste')}
                  onChange={handleChangeRegion}
                  name="Sudeste"
                  color="primary"
                />
              }
              label={intl.get('pages.hTube.sign.region.southEast')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={regionOfUse.includes('Centro-Oeste')}
                  onChange={handleChangeRegion}
                  name="Centro-Oeste"
                  color="primary"
                />
              }
              label={intl.get('pages.hTube.sign.region.midwest')}
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleSaveSignOnDemand()} color="primary">
          {intl.get('messages.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalIncludeSignOnDemand
