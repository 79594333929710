import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Layout from '../../components/Layout'
import Drawer from './Drawer'
import { CommunityFeatures, DrawerName } from 'collections'
import UsersTable from './UsersTable'
import WorkspacesTable from './WorkspacesTable'

interface IAdminProps {
  path: string
}

const Admin: React.FC<IAdminProps> = () => {
  const [selectedDrawer, setSelectedDrawer] = useState<DrawerName>(DrawerName.users)

  const oralLanguages = [
    {
      id: 'eng',
      name: 'English',
    },
    {
      id: 'por',
      name: 'Portugues',
    },
  ]

  const signLanguages = [
    {
      id: 'ase',
      name: 'American Sign Language',
    },
    {
      id: 'bzs',
      name: 'Língua Brasileira de Sinais',
    },
    {
      id: 'bfi',
      name: 'Língua Britânica de Sinais',
    },
  ]

  return (
    <Layout requiredFeature={CommunityFeatures.adminDashboard} title="Admin" imageBg>
      <Box mt={4}>
        <Grid container justifyContent="center">
          <Drawer selectedDrawer={selectedDrawer} setSelectedDrawer={setSelectedDrawer} />
          <Grid item>
            {selectedDrawer === DrawerName.users && (
              <UsersTable oralLanguages={oralLanguages} signLanguages={signLanguages} />
            )}
            {selectedDrawer === DrawerName.workspaces && (
              <WorkspacesTable oralLanguages={oralLanguages} signLanguages={signLanguages} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default Admin
