import React, { useEffect, useRef, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { limitToFirst, query, ref as refDatabase, orderByKey, startAfter } from '@firebase/database'
import { useDatabase } from 'reactfire'

import AnimationView from '../../../../components/AnimationView'
import useModelOld from '../../../../hooks/useModelOld'
import { getData } from 'collections'

interface INewRecordScreenProps {
  path?: string
}

interface IAnimationSettings {
  speed: number
  isPlaying: boolean
  repeat: boolean
}

interface ISignMotion {
  animation: string
  glosa: string
  id_primary: string
  sign: string
  subCategory: string
  keypoints: number[][][]
}

enum ESinalizationState {
  loadingApp = 'loadingApp',
  correct = 'correct',
  incorrect = 'incorrect',
  signaling = 'signaling',
  getingResults = 'getingResults',
  avatarAnimation = 'avatarAnimation',
  nextSentence = 'nextSentence',
}

const NewRecordScreen: React.FC<INewRecordScreenProps> = () => {
  const db = useDatabase()

  const defaultSpeed = 0.5

  const [animationSettings, setAnimationSettings] = useState<IAnimationSettings>({
    isPlaying: true,
    speed: defaultSpeed,
    repeat: false,
  })

  const [sinalizationState, setSinalizationState] = useState<ESinalizationState>(ESinalizationState.loadingApp)

  const signData = useRef<ISignMotion | null>(null)

  const sentence = useRef<string>('')

  const [loadedApp] = useState(false)

  const [nextSentenceLoaded, setNextSentenceLoaded] = useState<boolean>(false)

  const lastSign = useRef<string>('')

  const signModel = useModelOld({
    modelPath: `gs://ht-motion/models/tfjs/classification/sign`,
    classesPath: `gs://ht-motion/models/tfjs/classification/sign`,
    modelName: 'sign',
    motionModel: true,
  })

  const modelSegmentationFns = useModelOld({
    modelPath: `gs://ht-motion/models/tfjs/classification/segmentation`,
    modelName: 'segmentation',
    motionModel: true,
  })

  const handleSetAnimationSettings = (key: keyof IAnimationSettings, value: number | boolean) => {
    const _animationSettings = { ...animationSettings, [key]: value }
    setAnimationSettings(_animationSettings)
  }

  const getSignData = async () => {
    const refString = 'signs/'

    const refDb = refDatabase(db, refString)

    let queryDb = query(refDb, orderByKey(), limitToFirst(1))
    if (lastSign) {
      queryDb = query(queryDb, startAfter(lastSign.current))
    }

    const snapshot = await getData(queryDb)

    const _signDatas = snapshot.val()
    const currentSentence = Object.keys(_signDatas)[0]

    sentence.current = currentSentence
    if (_signDatas && _signDatas[sentence.current]) {
      const _signData = _signDatas[sentence.current] as ISignMotion

      signData.current = _signData
      setNextSentenceLoaded(true)
    }
  }

  const modelsDownload = async () => {
    // Resgata os dados do modelo de segmentação
    await modelSegmentationFns.download()
    //Resgata os dados do modelo
    await signModel.download()
  }

  useEffect(() => {
    getSignData()
    modelsDownload()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (animationSettings.repeat == false && loadedApp && sinalizationState == ESinalizationState.avatarAnimation) {
      setSinalizationState(ESinalizationState.signaling)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationSettings.repeat])

  useEffect(() => {
    if (loadedApp) {
      setSinalizationState(ESinalizationState.avatarAnimation)
      handleSetAnimationSettings('repeat', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedApp])

  useEffect(() => {
    handleSetAnimationSettings('isPlaying', animationSettings.repeat)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationSettings.repeat])

  useEffect(() => {
    if (nextSentenceLoaded && loadedApp) {
      setNextSentenceLoaded(false)
      handleSetAnimationSettings('repeat', true)
      setSinalizationState(ESinalizationState.avatarAnimation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSentenceLoaded, loadedApp])

  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        backgroundColor: '#eeeeee',
      }}
    >
      <Grid item lg={12} style={{ display: 'flex' }}>
        <Box style={{ margin: 'auto', textAlign: 'center' }}>
          <Box>
            <Typography>
              {sinalizationState == ESinalizationState.loadingApp ? '' : `Sentença: ${sentence.current}`}
            </Typography>
          </Box>
          <Box>
            <Typography>
              {sinalizationState == ESinalizationState.loadingApp
                ? 'A aplicação está sendo carregada, por favor aguarde.'
                : sinalizationState == ESinalizationState.avatarAnimation
                ? 'Observe o hugo'
                : sinalizationState == ESinalizationState.signaling
                ? 'Imite o sinal realizado pelo hugo.'
                : sinalizationState == ESinalizationState.getingResults
                ? 'Realizando predição, aguarde.'
                : sinalizationState == ESinalizationState.nextSentence
                ? 'Carregando próxima sentença, aguarde.'
                : sinalizationState == ESinalizationState.correct
                ? 'Você acertou'
                : 'Você errou, tente novamente.'}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} sm={12}>
        <Box>
          <AnimationView
            defaultSpeed={defaultSpeed}
            hiddenControls={true}
            autoPlay={true}
            repeat={animationSettings.repeat}
            rest={true}
            height={906}
            htaAnimation={
              //   (sinalizationState != ESinalizationState.loadingApp
              //     ? mode.current == 'fingerspell'
              //       ? alphabetData &&
              //         alphabetData[indexAlphabet] &&
              //         alphabetData[indexAlphabet].animation
              //       : signData?.animation
              //     : '') || ''
              signData.current?.animation
            }
            avatar={'hugo'}
            isPlaying={animationSettings.isPlaying}
            setIsPlaying={(value) => {
              handleSetAnimationSettings('isPlaying', value)
            }}
            speed={animationSettings.speed}
            setSpeed={(value) => {
              handleSetAnimationSettings('speed', value)
            }}
            textFast={''}
            textMedium={''}
            textLow={''}
            onFinalizeAnimation={() => {
              handleSetAnimationSettings('repeat', false)
            }}
          />
        </Box>
      </Grid>
      <Grid item lg={6} sm={12}>
        {/* <MediapipeCamera
          height={650}
          setLoadedApp={() => {
            setLoadedApp(true)
          }}
          predict={(pose, lHand, rHand) => {
            setSinalizationState(ESinalizationState.getingResults)
            setTimeout(() => {
              handlePredict(pose, lHand, rHand)
            }, 1000)
          }}
          sinalizationState={sinalizationState}
        /> */}
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '8px',
          width: '100%',
          marginTop: 'auto',
        }}
      >
        <Box style={{ backgroundColor: '#5B69C2', width: '20%', height: '100%' }} />
        <Box style={{ backgroundColor: '#49C2F9', width: '20%', height: '100%' }} />
        <Box style={{ backgroundColor: '#FBC000', width: '20%', height: '100%' }} />
        <Box style={{ backgroundColor: '#FC8D00', width: '20%', height: '100%' }} />
        <Box style={{ backgroundColor: '#00EAB5', width: '20%', height: '100%' }} />
      </Grid>
    </Grid>
  )
}

export default NewRecordScreen
