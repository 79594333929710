import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useIntl from '../../../hooks/useIntl'

export type ConfirmDialogContentType = 'currentAnimation' | 'isFingerspell' | 'isDisabled' | 'isSign'

interface IConfirmDialogProps {
  /** Determina se o modal está visível */
  isOpen: boolean
  /** Tipo que define o conteúdo a ser exibido */
  contentType: ConfirmDialogContentType
  /** Callback para executar ação que estava sendo confirmada */
  onConfirm: () => void
  /** Callback para fechar o modal */
  onClose: () => void
}

interface IConfirmDialogTexts {
  /** Título do conteúdo atual */
  title: string
  /** Subtítulo do conteúdo atual */
  subtitle: string
}

const contents: Array<ConfirmDialogContentType> = ['currentAnimation', 'isFingerspell', 'isDisabled', 'isSign']

/**
 * Componente para confirmar ações executadas
 */
const ConfirmDialog: React.SFC<IConfirmDialogProps> = ({ isOpen, onConfirm, onClose, contentType }) => {
  const intl = useIntl()

  const textContent: {
    [k in ConfirmDialogContentType]?: IConfirmDialogTexts
  } = {}
  contents.forEach((key) => {
    Object.assign(textContent, {
      [key]: {
        title: intl.get(`pages.animation.confirmDialog.${key}.title`),
        subtitle: intl.get(`pages.animation.confirmDialog.${key}.subtitle`),
      },
    })
  })
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{textContent[contentType]?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{textContent[contentType]?.subtitle}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.get('messages.cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {intl.get('messages.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
