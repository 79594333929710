import { FC } from 'react'
import styled from 'styled-components'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import DeleteForeverIcon from '@material-ui/icons/Cancel'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import { IVideoFile } from 'collections'

const WrapperCard = styled(Paper)`
  margin: 4px;
  height: 112.5px;
  width: 200px;
  position: relative;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5) !important;
`

const Video = styled.video`
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
`

const ClearIconWrapper = styled(Grid)`
  position: absolute;
  top: 10px;
  left: -20px;
  z-index: 1;
`

interface IVideoPreviewProps {
  video: IVideoFile
  clear: () => void
}

export const VideoPreview: FC<IVideoPreviewProps> = ({ video, clear }) => (
  <WrapperCard>
    <ClearIconWrapper>
      <Tooltip title="delete">
        <Fab size="small" color="secondary" onClick={clear}>
          <DeleteForeverIcon fontSize="small" />
        </Fab>
      </Tooltip>
    </ClearIconWrapper>
    <Video src={video.link} loop autoPlay muted controls={true} />
  </WrapperCard>
)

export default VideoPreview
