import { Box, Button, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { differenceInSeconds } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useStorage } from 'reactfire'
import { useGlobal } from 'reactn'
import styled from 'styled-components'
import { useLocation, useRoute } from 'wouter'
import { routes } from '../../../community'
import CardMessage from '../../../components/CardMessage'
import ContributionMetadataView from '../../../components/ContributionMetadataView'
import FramePlayer from '../../../components/FramePlayer'
import Layout from '../../../components/Layout'
import Preloader from '../../../components/Preloader'
import Timeline from '../../../components/Timeline'
import UserMenu from '../../../components/UserMenu'
import { DocumentReferenceGeneric, IVideo, updateVideoFlags } from 'collections'
import useContributionMetadata from '../../../hooks/useContributionMetadata'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import useIntl from '../../../hooks/useIntl'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import useTimelineRegions from '../../../hooks/useTimelineRegions'
import useContributionsLogger from '../../../services/hooks/useContributionsLogger'
import useJobMeter from '../../../services/hooks/useJobMeter'
import useSnackbar from '../../../services/hooks/useSnackbar'
import logger from '../../../services/logger'
import {
  getPathFramesJson,
  IGlobalAttr,
  ISignOnDemand,
  LanguageKeypair,
  StatusSignOnDemand,
  VideoId,
  WorkspaceId,
  Timestamp,
} from 'collections'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useModuleSignOnDemand from '../../../services/hooks/useModuleSignOnDemand'
import { useFirestoreData } from '../../../hooks/useFirestoreData'
import { serverTimestamp } from '@firebase/firestore'
import { getDownloadURL, ref } from '@firebase/storage'
import { getDocumentReference, getFirestoreDocument, updateFirestoreDocument } from 'collections'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'

interface ISignVideoOnDemandSegmentVideoProps {
  path: string
}

const VideoContainer = styled(Grid)`
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

const StyledBox = styled(Box)`
  @media (min-width: 600px) {
    padding: 16px;
  }
`

const MainGrid = styled(Grid)`
  @media (max-width: 600px) {
    max-width: 100vw;
    margin-top: 25px;
  }
  overflow-y: hidden;
  position: absolute;
  top: 0px;
  padding-top: 20px;
`
const BackButton = styled(IconButton)`
  && {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-right: 22px;
    z-index: 2;
    &:first-child {
      color: #c4c4c4;
    }
  }
`

const timeToReleaseVideo = 3600
const { log } = logger('SignOnDemandSegment')
const SignOnDemandSegmentVideo: React.FC<ISignVideoOnDemandSegmentVideoProps> = () => {
  const [url, setUrl] = useState('')
  const { saveSegment, refreshNextSignOnDemandSegment } = useModuleSignOnDemand()[1]
  const showSnackbar = useSnackbar()
  const hasPermissionOnModule = useCheckHasPermission('signOnDemand')
  const [timeFrame, setTimeFrame] = useState(0)
  const [userContribution, setUserContribution] = useState(0)
  const [, routeParams] = useRoute(routes.signOnDemandSegmentVideo)
  const storage = useStorage()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const signOnDemandId: string = routeParams?.signOnDemandId || ''
  const signOnDemandRef = getDocumentReference(fsUser.workspace, 'signsOnDemand', signOnDemandId)
  const signOnDemand = useFirestoreData(signOnDemandRef.path).data as ISignOnDemand

  const [videoId, setVideoId] = useState<VideoId | null>(null)
  const [videoRef, setVideoRef] = useState<DocumentReferenceGeneric | null>(null)
  const [video, setVideo] = useState<IVideo | null>(null)
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const setFsVideoRef = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')[1]
  const setFsVideoDocData = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')[1]
  const [contributionMetadataState, contributionMetadataFns] = useContributionMetadata()
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(true)
  const [isUnlockedByTime, setIsUnlockedByTime] = useState(false)
  const setLocation = useLocation()[1]
  const { endJobMeter, resetJobMeter } = useJobMeter('signOnDemand')
  const contributionsLoggerFns = useContributionsLogger()[1]
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [, timelineRegionsFns] = useTimelineRegions()

  useEffect(() => {
    const call = async () => {
      if (signOnDemand && !updatingStatus) {
        timelineRegionsFns.clear()
        if (!signOnDemand.isSegmenting?.value && signOnDemand.status !== StatusSignOnDemand.segmentedVideo) {
          setUpdatingStatus(true)
          await updateFirestoreDocument(signOnDemandRef, {
            isSegmenting: {
              lastUpdate: serverTimestamp() as Timestamp,
              user: fsUserRef,
              value: true,
            },
          })
          setUpdatingStatus(false)
        }
        setVideoId(signOnDemand.videoRef?.id || '')
        setVideoRef(signOnDemand.videoRef || null)
        if (!signOnDemand.videoRef) return
        const videoSnapshot = await getFirestoreDocument(signOnDemand.videoRef)
        const _videoData = videoSnapshot?.data() as IVideo
        const lastSegmentingTime = _videoData.isSegmenting?.lastUpdate?.toDate() || new Date()
        setIsUnlockedByTime(differenceInSeconds(new Date(), lastSegmentingTime) > timeToReleaseVideo)
        setVideo(_videoData)
      }
    }

    if (signOnDemand.status === StatusSignOnDemand.pending || signOnDemand.status === StatusSignOnDemand.request) {
      setLocation(routes.signOnDemandRecordVideo.replace(':signOnDemandId', signOnDemandId))
      return
    } else if (signOnDemand.status === StatusSignOnDemand.segmentedVideo) {
      setLocation(routes.signOnDemandDetail.replace(':signOnDemandId', signOnDemandId))
      return
    }

    call()

    return () => {
      if (signOnDemand.isSegmenting?.value && signOnDemand.isSegmenting?.user?.id === fsUserRef.id) {
        updateFirestoreDocument(signOnDemandRef, {
          isSegmenting: {
            lastUpdate: serverTimestamp() as Timestamp,
            user: fsUserRef,
            value: false,
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOnDemand?.videoRef?.id])

  useEffect(() => {
    setIsLoading(false)
  }, [url])

  useEffect(() => {
    if (video && videoId) {
      if (!video.isSegmenting?.value || isUnlockedByTime) {
        log('Video liberado para segmentação, vinculando usuario')
        contributionMetadataFns.setMetadata()
        const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
        updateVideoFlags(videoRef, {
          isSegmenting: {
            value: true,
            user: fsUserRef,
            lastUpdate: serverTimestamp() as Timestamp,
          },
        })
        timelineRegionsFns.clear()
        resetJobMeter()
      }
      setFsVideoDocData(video)
      if (videoRef) setFsVideoRef(videoRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video])

  useEffect(() => {
    if (contributionMetadataState?.isTimerReachedZero) {
      refreshNextSignOnDemandSegment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributionMetadataState])

  // seta a contribuição de segmentVideo do usuário
  useEffect(() => {
    if (fsUser.contribs) {
      const contribs = fsUser.contribs[fsUser.workspace.id as WorkspaceId]
      const language = `${fsUser.oralLanguageId}-${fsUser.signLanguageId}` as LanguageKeypair

      const totalContributionsSegmentVideo = contribs?.segmentVideo?.[language] || 0

      if (totalContributionsSegmentVideo) setUserContribution(totalContributionsSegmentVideo)
    }
  }, [fsUser.contribs, fsUser.oralLanguageId, fsUser.signLanguageId, fsUser.workspace.id])

  const separe = (_sentence: string) => {
    const characters = ['?', '!', ',', '.', '%']
    const charactersWithoutDot = ['?', '!', ',', '%']
    let newSentence = ''
    const aSentenceWithDot: string[] = []
    _sentence.split(' ').filter((el: string) => {
      let wordWithDot = el
      if (wordWithDot.includes('.') && wordWithDot[0] != '.') {
        charactersWithoutDot.forEach((character: string) => {
          wordWithDot = wordWithDot.replaceAll(character, '')
        })
        aSentenceWithDot.push(wordWithDot)
      }
    })
    const re = new RegExp('^([0-9])')
    for (let i = 0; i < _sentence.length; i++) {
      if (characters.includes(_sentence[i])) {
        newSentence += ' '
      }
      newSentence += _sentence[i]
      if (
        i + 1 < _sentence.length &&
        _sentence[i + 1] !== ' ' &&
        (characters.includes(_sentence[i]) || (re.test(_sentence[i]) && !re.test(_sentence[i + 1])))
      )
        newSentence += ' '
    }
    if (aSentenceWithDot.length) newSentence = newSentence += ` ${aSentenceWithDot.join(' ')}`
    return newSentence
  }

  // Definição do video
  useEffect(() => {
    log(`Vídeo atual: ${videoId}`)
    if (video && videoId) {
      // Pega o video principal
      const call = async () => {
        /**  1000 representa segundo em milisegundos e appConfig.frameRate a
         *    quantidade de frames por segundo
         */
        const calculateFrameTime = 1000 / appConfig.frameRate

        setTimeFrame(calculateFrameTime)
        const videoUrlRefString = getPathFramesJson(fsUser.workspace.id, videoId, video.duplicateOf || '')
        const videoUrlRef = ref(storage, videoUrlRefString)
        const videoUrl = await getDownloadURL(videoUrlRef)
        setUrl(videoUrl)
      }
      call()
    }
  }, [appConfig.frameRate, fsUser.workspace.id, storage, video, videoId])

  /**
   * Deu alguma falha ao carregar o player
   * @param duration Duração do vídeo carregado em frames
   */
  const onFramePlayerError = () => {
    showSnackbar(intl.get('pages.segmentVideo.video.failToLoadVideoFrames'), {
      variant: 'error',
    })
    setIsLoading(false)
  }

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout
        title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.segmentVideo')}`}
        requiredModule={'signOnDemand'}
      >
        <CardMessage
          // title={`${intl.get('modules.signOnDemand')} - ${intl.get(
          //   'modules.segmentVideo',
          // )}`}
          // subtitle={intl.get('messages.noWritterRoles.description')}
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  const handleSaveSegmentClick = () => {
    setIsLoading(true)
    endJobMeter()
    saveSegment(signOnDemand, signOnDemandRef)

    contributionsLoggerFns.update(video?.sentence || '', 1, 'signOnDemand')
  }

  const handleRefresh = () => {
    refreshNextSignOnDemandSegment()
  }

  if ((video?.segmentedBy || []).length > 0 || signOnDemand.status === StatusSignOnDemand.segmentedVideo) {
    return (
      <Layout
        title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.segmentVideo')}`}
        requiredModule={'signOnDemand'}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" item xs>
          <Typography variant="subtitle1" gutterBottom style={{ margin: 0 }}>
            {intl.get('pages.segmentVideo.video.alreadySegmentedVideo')}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              handleRefresh()
            }}
          >
            {intl.get('messages.searchAgain')}
          </Button>
        </Grid>
      </Layout>
    )
  }

  if (video && video.errors) {
    return (
      <Layout
        title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.segmentVideo')}`}
        requiredModule={'signOnDemand'}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" item xs>
          <Typography variant="subtitle1" gutterBottom style={{ margin: 0 }}>
            {intl.get('messages.videoReported')}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              handleRefresh()
            }}
          >
            {intl.get('messages.searchAgain')}
          </Button>
        </Grid>
      </Layout>
    )
  }

  if (signOnDemand?.isSegmenting?.value && signOnDemand?.isSegmenting?.user?.id !== fsUserRef.id) {
    const dif = Math.floor((+new Date() - +signOnDemand?.isSegmenting?.lastUpdate) / (1000 * 60 * 60))
    //Destrava o vídeo caso tenha mais de 1 hora segmentando
    if (dif >= 1) {
      updateFirestoreDocument(signOnDemandRef, {
        isSegmenting: {
          lastUpdate: serverTimestamp() as Timestamp,
          user: signOnDemand?.isSegmenting?.user,
          value: false,
        },
      })
    }

    return (
      <Layout
        title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.segmentVideo')}`}
        requiredModule={'signOnDemand'}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" item xs>
          <Typography variant="subtitle1" gutterBottom style={{ margin: 0 }}>
            {intl.get('pages.segmentVideo.video.someoneIsSegmenting')}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              handleRefresh()
            }}
          >
            {intl.get('messages.searchAgain')}
          </Button>
        </Grid>
      </Layout>
    )
  }

  return (
    <Layout
      title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.segmentVideo')}`}
      includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
      requiredModule={'signOnDemand'}
    >
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingVideo')}></Preloader>}
      {!isLoading && (
        <StyledBox width="100%">
          <Grid>
            <BackButton aria-label="Return to the list of signs" onClick={() => setLocation('/signOnDemand')}>
              <ArrowBackIcon fontSize="default" />
            </BackButton>
          </Grid>
          <MainGrid container spacing={2} justifyContent="center">
            {/** Area de visualização do vídeo */}
            <Grid item xs md={8} sm={12}>
              <Grid
                item
                sm={12}
                md={12}
                style={{
                  marginBottom: 10,
                }}
              >
                <Paper
                  color="default"
                  style={{
                    padding: 10,
                    border: '1px solid #cecece',
                    boxShadow: 'none',
                  }}
                >
                  <Typography className="notranslate" variant="h6">
                    {video
                      ? `${intl.get('pages.segmentVideo.video.sentence')}: ${video.sentence}`
                      : `${intl.get('pages.segmentVideo.video.loadingVideo')}...`}
                  </Typography>
                  <Typography variant="subtitle1">
                    {video
                      ? `${intl.get('pages.segmentVideo.video.segments')}: ${video.numberOfSegments || 0} | ${intl.get(
                          'messages.contributions',
                        )}: ${userContribution}`
                      : ' '}
                  </Typography>
                </Paper>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <VideoContainer
                  item
                  md={7}
                  sm={7}
                  style={{
                    background: 'black',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <FramePlayer
                    onError={onFramePlayerError}
                    onReady={() => ({})}
                    onChangedTime={handleOnChangedTime}
                    frameRatio={30}
                    framesDuration={video?.duration || 0}
                    timeFrame={timeFrame}
                    src={url}
                    timelineCursor={timelineCursor}
                    startFrame={0}
                    communityModule={'segmentVideo'}
                  />
                </VideoContainer>
              </Grid>

              <Grid
                item
                sm={12}
                md={12}
                style={{
                  marginBottom: 10,
                }}
              >
                {/** Só exibiremos a linha do tempo quando o vídeo estiver carregado */}
                <Timeline
                  timelineCursor={timelineCursor}
                  timelineCursorFns={timelineCursorFns}
                  timeFrame={timeFrame}
                  sentence={separe(video?.sentence || '')}
                  editing={false}
                  onlyOneSegment={true}
                />
              </Grid>

              <Grid item>
                {' '}
                <Box display="flex" alignItems="center" flexDirection="row" justifyContent="flex-end" p={2}>
                  <Box mr={2}>
                    <Button
                      style={{ background: green[700], color: '#fff' }}
                      variant="contained"
                      onClick={handleSaveSegmentClick}
                    >
                      {intl.get('messages.save')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </MainGrid>
        </StyledBox>
      )}
    </Layout>
  )
}

export default SignOnDemandSegmentVideo
