import React from 'react'
import ValidateTranslationsActionsPure from './Pure'
import { ValidateActions } from 'collections'
import useIntl from '../../hooks/useIntl'

interface IValidateTranslationActionsProps {
  /** Callback para valição realizada */
  onValidate?: (action: ValidateActions) => void
  /** Bloqueia os botões */
  lockButtons?: boolean
  /** Verificar se a tabela está aberta */
  openTable: boolean
}

const ValidateTranslationsActions: React.FC<IValidateTranslationActionsProps> = ({ onValidate, lockButtons }) => {
  const intl = useIntl()
  const incorrectText = intl.get('messages.incorrect')
  const correctText = intl.get('messages.correct')
  const outOfContextText = intl.get('messages.outOfContext')
  const missingSignText = intl.get('messages.missingSign')
  return (
    <ValidateTranslationsActionsPure
      lockButtons={lockButtons}
      onValidate={onValidate}
      incorrectText={incorrectText}
      correctText={correctText}
      missingSignText={missingSignText}
      outOfContextText={outOfContextText}
    />
  )
}
export default ValidateTranslationsActions
