import React, { useState } from 'react'
import { useGlobal } from 'reactn'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import { IGlobalAttr, IMenuOptions, ISegmentView } from 'collections'

import HoverablePlayerPure from './Pure'

interface IHoverablePlayerProps {
  segment: ISegmentView
  fullHeight?: boolean
  isPlaying?: boolean
  /** Menu component */
  menu?: IMenuOptions[]
  showFullScreenSegment?: boolean
}

const HoverablePlayer: React.FC<IHoverablePlayerProps> = ({
  segment,
  fullHeight,
  isPlaying,
  menu,
  showFullScreenSegment,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const handleOnMouseEnter = () => {
    setIsHovering(true)
  }

  const handleOnMouseLeave = () => {
    timelineCursorFns.setPosition(segment.startFrame)
    setIsHovering(false)
  }

  return (
    <HoverablePlayerPure
      segment={segment}
      isHovering={isHovering}
      isPlaying={isPlaying}
      handleOnChangedTime={handleOnChangedTime}
      handleOnMouseEnter={handleOnMouseEnter}
      handleOnMouseLeave={handleOnMouseLeave}
      timeFrame={appConfig.frameRate}
      timelineCursor={timelineCursor}
      fullHeight={fullHeight}
      menu={menu}
      showFullScreenSegment={showFullScreenSegment}
    />
  )
}

export default HoverablePlayer
