import React, { useState, useEffect, useCallback } from 'react'

import ContributionsHeader from './Pure'
import useContributionsPagination from '../../../../services/hooks/useContributionsPagination'
import { UserId, IUserInfo } from 'collections'
import { useFirestore } from 'reactfire'
import { doc } from '@firebase/firestore'

/**
 * Cabeçalho das contribuições, aqui estão todos os seletores de filtros e o nome do workspace
 * Lembre-se que os filtros podem ser condicionados com o papel do usuário
 */
const ContributionsHeaderUse: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>('all')
  const [allUsersInfo, setAllUsersInfo] = useState<Record<UserId, IUserInfo>>({
    all: { displayName: 'All users' },
  })
  const [{ usersInfo, filters }, { applyFilters }] = useContributionsPagination()
  const firestore = useFirestore()

  // Sempre que a lista de users atualizar, adicionamos all
  useEffect(() => {
    const users: Record<UserId, IUserInfo> = {
      ...usersInfo,
      all: { displayName: 'All users' },
    }
    setAllUsersInfo(users)
  }, [usersInfo])

  /**
   * Usuário foi alterado
   */
  const handleUserChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const userId = event.target.value as UserId

      const userRef = userId !== 'all' ? doc(firestore, 'users', userId) : undefined

      setSelectedUserId(userId)
      applyFilters(filters.startDate, filters.endDate, userRef)
    },
    [applyFilters, filters.endDate, filters.startDate, firestore],
  )

  return <ContributionsHeader users={allUsersInfo} selectedUserId={selectedUserId} onUserChange={handleUserChange} />
}

export default ContributionsHeaderUse
