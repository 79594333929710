import React, { ReactNode } from 'react'
import { SnackbarProvider, useSnackbar, OptionsObject, VariantType } from 'notistack'
import { Debugger } from 'debug'
import logger from '../logger'

const loggers = logger('useSnackbar')

const logFns: Record<VariantType, Debugger> = {
  success: loggers.log,
  info: loggers.log,
  error: loggers.error,
  warning: loggers.warn,
  default: loggers.log,
}

export const ProviderSnackbar: React.FC<{ children: JSX.Element }> = ({ children }) => (
  <SnackbarProvider
    maxSnack={5}
    preventDuplicate
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    {children}
  </SnackbarProvider>
)

// eslint-disable-next-line
export default () => {
  const { enqueueSnackbar } = useSnackbar()
  return (message: ReactNode, options?: OptionsObject) => {
    const finalOptions = Object.assign({}, { autoHideDuration: 3000 }, options)
    const { variant } = finalOptions

    const logFn = logFns[variant || 'default']
    logFn(message)
    enqueueSnackbar(message, finalOptions)
  }
}
