import md5 from 'md5'
import { Decoder, tools, Reader } from 'ts-ebml'
import logger from './logger'
import { IVideoFile, ITimelineRegion } from 'collections'
import { FirebaseStorage, ref, StorageReference, uploadBytesResumable, UploadTaskSnapshot } from '@firebase/storage'
import { sendMailOnCallFunction } from './firebase'
import { ReactFireGlobals } from 'reactfire'

const { log } = logger('utils')

/**
 * SetTimeout assíncrono
 * @param timeout Temo em milisegundos
 * @param handler Função a executar (optional)
 */
export const asyncTimeout = (timeout: number, handler?: () => void): Promise<unknown> =>
  new Promise((resolve) => {
    setTimeout(() => {
      if (handler) handler()
      //@ts-ignore
      resolve()
    }, timeout)
  })

/**
 * Retorna a URL da imagem do avatar no Gravatar
 * @see https://en.gravatar.com/site/implement/images/
 * @param email Email do usuário
 */
export const getGravatarUrl = (email: string): string => {
  const hash = md5(email)
  return `https://gravatar.com/avatar/${hash}.jpg`
}

// Expande o evento de Media pra ter data
interface IMediaEvent extends Event {
  data: Blob
}

/**
 * Retorna um arquivo de vídeo gravado de uma stream de vídeo
 * @param stream Stream pra gravar
 * @param duration Duração da gravação
 * @param midiaId Id do vídeo que será gerado
 */
export const recordVideoFromStream = (stream: MediaStream, duration: number, midiaId: string): Promise<IVideoFile> => {
  return new Promise<IVideoFile>((res, rej) => {
    const data: Blob[] = []

    const onDataAvailable = (event: IMediaEvent) => {
      if (event.data.size > 0) data.push(event.data)
    }

    const onStop = async () => {
      // Este processo prepara cria um video seekeable
      // para mais detalhes leia https://github.com/legokichi/ts-ebml/blob/master/src/example_seekable.ts
      const blob = new Blob(data)
      const decoder = new Decoder()
      const reader = new Reader()
      const link = URL.createObjectURL(blob)
      const response = await fetch(link)
      const arrayBuffer = await response.arrayBuffer()
      const elms = decoder.decode(arrayBuffer)
      elms.forEach((elm) => {
        const type = elm.type.toString()
        if (type != 'unknown') {
          reader.read(elm)
        }
      })
      reader.stop()

      const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues)
      const body = arrayBuffer.slice(reader.metadataSize)
      const refinedWebM = new Blob([refinedMetadataBuf, body], {
        type: 'video/webm',
      })

      const refinedVideoUrl = URL.createObjectURL(refinedWebM)

      const recordedVideo: IVideoFile = {
        link: refinedVideoUrl,
        duration: reader.duration,
        blob: refinedWebM,
        id: midiaId,
      }
      res(recordedVideo)
    }

    // Crie o MediaRecorder e adicione os eventos;
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: 'video/webm;codecs=h264',
    })
    mediaRecorder.addEventListener('dataavailable', onDataAvailable as EventListener)
    mediaRecorder.addEventListener('stop', onStop)

    mediaRecorder.onerror = rej

    // Ouvimos o onstart para garantir a duração do vídeo
    mediaRecorder.onstart = () => {
      setTimeout(() => {
        mediaRecorder.stop()
        log('Finalizando gravação')
      }, duration)
    }

    log('Iniciando gravação')
    mediaRecorder.start()
  })
}

/**
 * Captura uma foto do momento atual de um video
 */
export const takeSnapShotFromVideo = (video: HTMLVideoElement): string => {
  const canvas = document.createElement('canvas')
  canvas.width = 512
  canvas.height = 512

  const ctx = canvas.getContext('2d')
  if (!ctx) throw 'Navegador não suportado'

  ctx.drawImage(video, 0, 0, 512, 512)
  const image = canvas.toDataURL('image/jpeg')

  return image
}

interface IGetBound {
  minBoundX: number
  maxBoundX: number
}

/**
 * Retorna um objeto com a posição minima e maxima (em pixels) que o item pode conter.
 * Este metodo utiliza  valor maximo e minimo de uma tag para encontrar os limites.
 * @param tag - Tag que terá o bounding calculado
 * @param tags- Dicionario com as tags atuais da linha do tempo
 * @param videoDuration - Duração do vídeo em frames
 * @param timelinseSize - Tamanho da timeline em pixels
 */
export const getBoundsByTag = (
  tag: ITimelineRegion,
  tags: ITimelineRegion[],
  durationFrames: number,
  timelineSize: number,
): IGetBound => {
  // Pegamos todas as tags que possuem um end time menor que o stat time da tag atual
  const leftPositions = tags.filter((item) => item.endFrame <= tag.startFrame).map((item) => item.endFrame)

  // Pegamos todas as tags que possuem um start time maior que o da tag atual
  const rightPositions = tags.filter((item) => item.startFrame >= tag.endFrame).map((item) => item.startFrame)

  // Pega as tags mais proximas e detemina como limites
  const maxTime = Math.min(...rightPositions, durationFrames)
  const minTime = Math.max(...leftPositions, 0)

  // ..e de segundos para pixels
  const maxBoundX = (maxTime / durationFrames) * timelineSize
  const minBoundX = (minTime / durationFrames) * timelineSize

  return { minBoundX, maxBoundX }
}

/**
 * Retorna um objeto com a posição minima e maxima (em pixels) que o item pode conter.
 * Este metodo utiliza uma variação de tempo na timeline para encontrar os limites.
 * @param frame - Numero do frame de referencia
 * @param tags- Dicionario com as tags atuais da linha do tempo
 * @param durationFrames - Duração do vídeo em frames
 * @param timelineSize - Tamanho da timeline em pixels
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const getBoundsByFrame = (
  frame: number,
  tags: ITimelineRegion[],
  durationFrames: number,
  timelineSize: number,
): IGetBound => {
  // Pegamos todas as tags que possuem um end time menor que o stat time da tag atual
  const leftPositions = tags.filter((item) => item.endFrame <= frame).map((item) => item.endFrame)

  // Pegamos todas as tags que possuem um start time maior que o da tag atual
  const rightPositions = tags.filter((item) => item.startFrame >= frame).map((item) => item.startFrame)

  // Pega as tags mais proximas e detemina como limites
  const maxFrame = Math.min(...rightPositions, durationFrames)
  const minFrame = Math.max(...leftPositions, 0)

  // Converte frame em pixels
  const maxBoundX = (maxFrame / durationFrames) * timelineSize
  const minBoundX = (minFrame / durationFrames) * timelineSize

  return { minBoundX, maxBoundX }
}

/**
 * Copia o conteúdo para a area de transferencia, adicionando-o em um text area e em seguida destruindo
 * @param text
 */
export const copyToClipboard = (text: string): void => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

/**
 * Envia blob para o storage
 * @param storage Objeto devidamente autenticado do firebase storage
 * @param remotePath Caminho onde o blob será salvo
 * @param blob Blob a ser salvo em remotePath
 * @param contentType Tipo de conteudo exemplo: `"video/webm;codecs=h264"`
 * @param identifier Utilizado para saber qual item está reportando progress
 * @param onProgress Callback para progresso de upload
 */
export const uploadToStorage = (
  storage: FirebaseStorage,
  remotePath: string,
  blob: Blob,
  contentType: string,
  identifier: string,
  onProgress?: (identifier: string, value: number) => void,
): Promise<unknown> => {
  const objectRef: StorageReference = ref(storage, remotePath)

  const uploadTask = uploadBytesResumable(ref(objectRef), blob, { contentType })

  return new Promise((resolve, reject) => {
    const onError = (err: Error) => {
      reject(err)
    }

    const onDone = async () => {
      log(`Upload finalizado: ${identifier}`)
      resolve('')
    }

    const _onProgress = (snapshot: UploadTaskSnapshot) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes
      log(`Progresso do upload: ${identifier} - ${Math.round(progress * 100)}%`)
      if (onProgress) onProgress(identifier, progress)
    }

    uploadTask.on('state_changed', _onProgress, onError, onDone)
  })
}

/**
 * Checa de o usuário está utilizando um device mobile ou desktop
 */
export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const hasNumber = (text: string): boolean => {
  return /\d/.test(text)
}

export const separeVideoSentence = (sentence: string): string[] => {
  const _menuItems = sentence
    .split(' ')
    .join(',')
    .split('.')
    .join(',')
    .split('/')
    .join(',')
    .split('(')
    .join(',')
    .split(')')
    .join(',')
    .split('$')
    .join(',')
    .split(':')
    .join(',')
    .split('%')
    .join(',')
    .split('-')
    .join(',')
    .split("'")
    .join(',')
    .split(',')

  // Remove duplicados
  const _distinctMenuItems = Array.from(new Set(_menuItems))
  //Remove vazios
  _distinctMenuItems.forEach((el, index) => {
    if (el == '') {
      _distinctMenuItems.splice(index, 1)
    }
  })

  return _distinctMenuItems
}

export const addSpaceBetweenFingerspell = (arr: string[]): string[] => {
  const result: string[] = []
  let lastIsDatilology = false
  arr.forEach((el) => {
    if (el.startsWith('¶')) lastIsDatilology = false
    else if (lastIsDatilology && !el.startsWith('¶')) {
      result.push(' ')
    } else lastIsDatilology = true
    result.push(el)
  })
  return result
}

export const cmsTypesName: Record<string, string> = {
  'hugo-ensina': 'hugoTeaches',
  dictionary: 'dictionary',
  'divulgacao-cliente': 'customerDisclosure',
  'campanha-interna': 'internalCampaign',
  'atividade-interna': 'internalActivity',
  'revisao-corpus': 'corpusRevision',
}

export const cmsStatusName: Record<string, string> = {
  requested: 'requested',
  approved: 'approved',
  inProgress: 'inProgress',
  finalized: 'finalized',
  rejected: 'rejected',
}

interface ISendMail {
  text: string
  to: string
  subject: string
  html?: string | Buffer | undefined
  cc?: string
}
export const sendMail = async (data: ISendMail) => {
  return sendMailOnCallFunction({
    text: data.text,
    subject: data.subject,
    to: data.to,
    cc: data.cc,
    html: data.html,
  })
}

export const clearFirestoreCache = () => {
  const map = (globalThis as unknown as ReactFireGlobals)._reactFirePreloadedObservables
  Array.from(map.keys()).forEach((key) => key.includes('firestore') && map.delete(key))
}

// A POSE_CONNECTIONS direto do mediapipe destaca a conexão do corpo inteiro
// Esta variável inclui a conexão apenas do corpo e braços até o cotovelo.
// Veja o arquivo original nesse link:
// https://github.com/google/mediapipe/blob/master/mediapipe/python/solutions/pose_connections.py
// Para entender melhor cada ponto da pose, veja essa imagem:
// https://google.github.io/mediapipe/solutions/pose.html#pose-landmark-model-blazepose-ghum-3d
export const POSE_CONNECTIONS_NO_FACE_NO_HANDS: [number, number][] = [
  // Conexões de 0 a 10 são todas conexões do rosto. Vamos Remover:
  // [0, 1],
  // [1, 2],
  // [2, 3],
  // [3, 7],
  // [0, 4],
  // [4, 5],
  // [5, 6],
  // [6, 8],
  // [9, 10],
  // A partir daqui é o braço esquerdo
  [11, 12],
  [11, 13],
  // A Mão esquerda tem os pontos 15, 21, 19, 17. Remove.
  // [13, 15],
  // [15, 17],
  // [15, 19],
  // [15, 21],
  // [17, 19],
  [12, 14], // Esse é o braço direito
  // A Mão direita tem os pontos 16, 22, 20, 18. Remove todos.
  // [14, 16],
  // [16, 18],
  // [16, 20],
  // [16, 22],
  // [18, 20],
  // A partir daqui fica tudo igual.
  [11, 23],
  [12, 24],
  [23, 24],
  [23, 25],
  [24, 26],
  [25, 27],
  [26, 28],
  [27, 29],
  [28, 30],
  [29, 31],
  [30, 32],
  [27, 31],
  [28, 32],
]

export const getNewCategoriesText = (selectedCategories: string[], isSaving?: boolean, isOpenning?: boolean) => {
  const categoriesText = [
    'accessibility and inclusion=acessibilidade e inclusão',
    'arts, culture and entertainment=arte, cultura e entretenimento',
    'beauty=beleza',
    'food and drinks=comidas e bebidas',
    'dialogue=diálogo',
    'education=educação',
    'finance=finanças',
    'real estate and construction=imóveis e construção',
    'undefined=indefinido',
    'industry, factory and machinery=indústria, fábrica e maquinário',
    'fashion=moda',
    'business=negócios',
    'government office=órgão público',
    'health=saúde',
    'technology and software=tecnologia e software',
    'telecommunication=telecomunicação',
    'transportation and logistics=transporte e logística',
    'retail=varejo',
    'travel and tourism=viagem e turismo',
    'communication=comunicação',
    'recruitment and selection=recrutamento e seleção',
  ]

  let _value: string[] = []
  selectedCategories?.forEach((el) => {
    for (const cat in categoriesText) {
      const newCategory = categoriesText[cat].split('=')
      if (isSaving && ((newCategory.length > 1 && newCategory[1].includes(el)) || newCategory[0].includes(el)))
        _value.push(newCategory[0] || newCategory[1])
      else if (isOpenning && newCategory[0].includes(el)) _value.push(newCategory[1])
    }
  })

  if ((_value && !_value.length) || (_value && _value.length && _value.length != selectedCategories.length))
    _value = selectedCategories

  return _value
}
