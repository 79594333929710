import React, { FC } from 'react'
import styled from 'styled-components'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import ColorLens from '@material-ui/icons/ColorLens'

const id = 'language-pair-selector'

const theme = { dark: 'Dark', light: 'Light' }

const SelectStyled = styled(Select)`
  .MuiSelect-root {
    padding: 0;

    &:focus {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 0;
    }
  }
  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    fieldset {
      border: none !important;
    }
  }
`

interface ILanguagePairSelectProps {
  value: string
  onChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    child: React.ReactNode,
  ) => void
}

/**
 - Use para selecionar Dark ou Light.
 * */
export const SelectThemePure: FC<ILanguagePairSelectProps> = ({ value, onChange }) => {
  const renderValue = () => {
    return (
      <ListItem>
        <ListItemIcon>
          <ColorLens />
        </ListItemIcon>
        <ListItemText>{value}</ListItemText>
      </ListItem>
    )
  }

  return (
    <SelectStyled
      value={value}
      renderValue={renderValue}
      inputProps={{ name: id, id }}
      onChange={onChange}
      variant="outlined"
      fullWidth
      MenuProps={{
        style: { zIndex: 2002 },
      }}
    >
      {Object.entries(theme).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      ))}
    </SelectStyled>
  )
}

export default SelectThemePure
