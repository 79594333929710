import { Grid, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useLocation, useRoute } from 'wouter'
import { routes } from '../../../community'
import Layout from '../../../components/Layout'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import {
  getPathFramesJson,
  getSegment,
  IGlobalAttr,
  ISegment,
  ISignOnDemand,
  StatusSignOnDemand,
  getFirestoreDocument,
  IVideo,
} from 'collections'
import { useStorage } from 'reactfire'
import AnimationView from '../../../components/AnimationView'
import FramePlayer from '../../../components/FramePlayer'
import { useGlobal } from 'reactn'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import useIntl from '../../../hooks/useIntl'
import { getUserByIdFunction } from '../../../services/firebase'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import styled from 'styled-components'
import { useFirestoreData } from '../../../hooks/useFirestoreData'
import { getDownloadURL, ref } from '@firebase/storage'
import { getDocumentReference } from 'collections'

const BackButton = styled(IconButton)`
  @media (min-width: 600px) {
    margin-left: 50px;
    margin-top: 5px;
  }
  && {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    position: absolute;
    left: 0px;

    &:first-child {
      color: #c4c4c4;
    }
  }
`

const GridContent = styled(Grid)`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`
interface ISignOnDemandDeatail {
  path?: string
}

const SignOnDemandDetail: React.FC<ISignOnDemandDeatail> = () => {
  const [, routeParams] = useRoute(routes.signOnDemandDetail)
  const signOnDemandId = routeParams?.signOnDemandId
  const fsUser = useFsUserDocData()
  const signOnDemandRef = getDocumentReference(fsUser.workspace, 'signsOnDemand', signOnDemandId || '')
  const signOnDemand = useFirestoreData(signOnDemandRef.path).data as ISignOnDemand
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const [segment, setSegment] = useState<ISegment | null>(null)
  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }
  const setLocation = useLocation()[1]
  const [url, setUrl] = useState('')
  const storage = useStorage()
  const intl = useIntl()
  const [isPlaying, setIsPlaying] = useState(false)
  const defaultSpeed = 1.0
  const [speed, setSpeed] = useState(defaultSpeed)
  const textFast = intl.get('messages.fast')
  const textMedium = intl.get('messages.medium')
  const textLow = intl.get('messages.low')
  const textRotate = intl.get('messages.rotateAvatar')

  const getUrlVideoFromSegment = async (_segment: ISegment, duplicateOf: string): Promise<string> => {
    const videoUrlRefString = getPathFramesJson(fsUser.workspace.id, _segment.video.id, duplicateOf)
    const videoUrlRef = ref(storage, videoUrlRefString)
    const videoUrl = await getDownloadURL(videoUrlRef)
    return videoUrl
  }

  useEffect(() => {
    const call = async () => {
      if (signOnDemand) {
        if (signOnDemand.status !== StatusSignOnDemand.segmentedVideo) {
          setLocation(routes.signOnDemandRecordVideo.replace(':signOnDemandId', signOnDemandId || ''))
          return
        }
        if (!signOnDemand.segmentRef) return
        const segmentSnapshot = await getSegment(signOnDemand.segmentRef)
        const segmentData = segmentSnapshot?.data() as ISegment
        segmentData.id = segmentSnapshot?.id

        segmentData.nameUserSegmented =
          (
            await getUserByIdFunction({
              userId: signOnDemand?.isSegmenting?.user?.id || '',
            })
          ).data.user.displayName || ''
        segmentData.nameUserRecorded =
          (
            await getUserByIdFunction({
              userId: signOnDemand?.isRecording?.user?.id || '',
            })
          ).data.user.displayName || ''
        setSegment(segmentData)
        const videoSnapshot = await getFirestoreDocument(segmentData.video)
        const videoData = videoSnapshot.data() as IVideo
        const _url = await getUrlVideoFromSegment(segmentData, videoData.duplicateOf || '')
        setUrl(_url)
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOnDemand, setLocation, signOnDemandId])

  return (
    <Layout title={intl.get('modules.signOnDemand')}>
      <BackButton aria-label="Return to the list of signs" onClick={() => setLocation('/signOnDemand')}>
        <ArrowBackIcon fontSize="default" />
      </BackButton>
      <GridContent item sm={11} justifyContent="space-between" container>
        <Grid
          item
          xs={12}
          md={5}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {segment && (
            <FramePlayer
              reference={segment.id}
              onError={() => ({})}
              onReady={() => ({})}
              onChangedTime={handleOnChangedTime}
              frameRatio={30}
              framesDuration={segment.endFrame}
              timeFrame={appConfig.frameRate}
              src={url}
              startFrame={segment.startFrame}
              timelineCursor={timelineCursor}
              autoPlay={true}
              autoPlayEnabled={true}
              mirrorEnabled={true}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '30px',
          }}
        >
          <Typography>{`Glosa: ${signOnDemand?.glosa}`}</Typography>
          {signOnDemand?.error && <Typography>{`Erro: ${signOnDemand?.error}`}</Typography>}

          <Typography>{`Palavras relacionadas: ${signOnDemand?.portuguese?.join(', ')}`}</Typography>
          <Typography>{`Gravado por: ${segment?.nameUserRecorded}`}</Typography>
          <Typography>{`Segmentado por: ${segment?.nameUserSegmented}`}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <AnimationView
            htaAnimation={signOnDemand?.hta || ''}
            rest={true}
            repeat={true}
            autoPlay={true}
            speedControls={true}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            speed={speed}
            setSpeed={setSpeed}
            textFast={textFast}
            textMedium={textMedium}
            textLow={textLow}
            textRotate={textRotate}
          />
        </Grid>
      </GridContent>
    </Layout>
  )
}

export default SignOnDemandDetail
