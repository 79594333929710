import { createStyles, Grid, Link, makeStyles, Typography, Theme } from '@material-ui/core'
import React from 'react'
import { motionColors } from '../../styles'
import LogoHT from '../../images/HTLogoWithName.svg'
// import { Container } from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomBar: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gridGap: '10px',
      alignItems: 'center',
      '& img': {
        height: '26px',
        [theme.breakpoints.up('lg')]: {
          height: '36px',
        },
        [theme.breakpoints.up('xl')]: {
          height: '56px',
        },
      },
    },
    bottomLine: {
      display: 'flex',
      flexDirection: 'row',
      height: '8px',
      width: '100%',
    },
    bottomBarColors: {
      width: '20%',
      height: '100%',
    },
    terms: {
      color: motionColors.fontColor,
      fontSize: '14px',
      [theme.breakpoints.up('lg')]: {
        fontSize: 12,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16,
      },
    },
  }),
)

const BottomBarMotion: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.bottomBar}>
      <img src={LogoHT} />
      <Link style={{ textDecoration: 'none', cursor: 'pointer' }}>
        <Typography className={classes.terms}>Política de privacidade</Typography>
      </Link>
      <div className={classes.bottomLine}>
        <div className={classes.bottomBarColors} style={{ backgroundColor: '#5B69C2' }} />
        <div className={classes.bottomBarColors} style={{ backgroundColor: '#49C2F9' }} />
        <div className={classes.bottomBarColors} style={{ backgroundColor: '#FBC000' }} />
        <div className={classes.bottomBarColors} style={{ backgroundColor: '#FC8D00' }} />
        <div className={classes.bottomBarColors} style={{ backgroundColor: '#00EAB5' }} />
      </div>
    </Grid>
  )
}

export default BottomBarMotion
