const lodash = require('lodash')
import * as tf from '@tensorflow/tfjs'
import { NormalizedLandmarkList } from '@mediapipe/holistic'

// Para interpolacao calcula o proximo frame valido
function next_valid_coord(data: number[][][], frame: number) {
  let n_frame, sum_frame
  n_frame = 0
  for (const frame_data of data) {
    if (n_frame < frame) {
      continue
    }
    sum_frame = 0
    for (const coord of frame_data) {
      sum_frame += lodash.sum(coord)
    }
    if (sum_frame !== 0) {
      return n_frame
    }
    n_frame += 1
  }
  return frame
}

// Para interpolacao calcula o ultimo frame valido
function last_valid_coord(data: number[][][], frame: number) {
  let n_frame, sum_frame
  const last_coord = 0
  n_frame = 0
  for (const frame_data of data) {
    if (n_frame > frame) {
      break
    }
    sum_frame = 0
    for (const coord of frame_data) {
      sum_frame += lodash.sum(coord)
    }
    if (sum_frame !== 0) {
      return n_frame
    }
    n_frame += 1
  }

  return last_coord
}

// Interpolacao dos keypoints
function interpolate_keypoints(list_data: number[][][][]) {
  let keypoints_data, last_contrib, last_valid, n, n_frame, next_contrib, next_valid, part, sum_coord, x, y, z
  const keypoints_list = []
  for (let i = 0; i < list_data.length; i++) {
    keypoints_data = []
    n_frame = 0
    for (const frame_data of list_data[i]) {
      part = []
      sum_coord = 0
      n = 0
      for (const coord of frame_data) {
        if (coord === undefined) {
          x = 0
          y = 0
          z = 0
        } else {
          x = coord[0]
          y = coord[1]
          z = coord[2]
        }
        part.push([x, y, z])
        sum_coord += lodash.sum(coord)
        n += 1
      }
      if (sum_coord === 0) {
        part = []
        last_valid = last_valid_coord(list_data[i], n_frame)
        next_valid = next_valid_coord(list_data[i], n_frame)
        if (next_valid && last_valid) {
          last_contrib = (n_frame - last_valid) / (next_valid - last_valid)
          next_contrib = (next_valid - n_frame) / (next_valid - last_valid)
          n = 0
          for (const coord of frame_data) {
            if (coord === undefined) {
              x = 0
              y = 0
              z = 0
            } else {
              x = list_data[i][last_valid][n][0] * next_contrib + list_data[i][next_valid][n][0] * last_contrib
              y = list_data[i][last_valid][n][1] * next_contrib + list_data[i][next_valid][n][1] * last_contrib
              z = list_data[i][last_valid][n][2] * next_contrib + list_data[i][next_valid][n][2] * last_contrib
            }
            part.push([x, y, z])
            n += 1
          }
        } else {
          if (next_valid) {
            n = 0
            for (const coord of frame_data) {
              if (coord === undefined) {
                x = 0
                y = 0
                z = 0
              } else {
                x = list_data[i][next_valid][n][0]
                y = list_data[i][next_valid][n][1]
                z = list_data[i][next_valid][n][2]
              }
              part.push([x, y, z])
              n += 1
            }
          } else {
            n = 0
            for (const coord of frame_data) {
              if (coord === undefined) {
                x = 0
                y = 0
                z = 0
              } else {
                x = list_data[i][last_valid][n][0]
                y = list_data[i][last_valid][n][1]
                z = list_data[i][last_valid][n][2]
              }
              part.push([x, y, z])
              n += 1
            }
          }
        }
      }
      keypoints_data.push(part)
      n_frame += 1
    }
    keypoints_list.push(keypoints_data)
  }

  return keypoints_list
}

// Interpolacao dos keypoints
function put_hand_in_body(pose_data: number[][][], hand_left_data: number[][][], hand_right_data: number[][][]) {
  let l_disp, r_disp
  for (let frame = 0; frame < hand_left_data.length; frame++) {
    l_disp = [
      hand_left_data[frame][0][0] - pose_data[frame][15][0],
      hand_left_data[frame][0][1] - pose_data[frame][15][1],
      hand_left_data[frame][0][2] - pose_data[frame][15][2],
    ]
    r_disp = [
      hand_right_data[frame][0][0] - pose_data[frame][16][0],
      hand_right_data[frame][0][1] - pose_data[frame][16][1],
      hand_right_data[frame][0][2] - pose_data[frame][16][2],
    ]

    for (let joint = 0; joint < hand_left_data[frame].length; joint++) {
      hand_left_data[frame][joint] = [
        hand_left_data[frame][joint][0] - l_disp[0],
        hand_left_data[frame][joint][1] - l_disp[1],
        hand_left_data[frame][joint][2] - l_disp[2],
      ]
      hand_right_data[frame][joint] = [
        hand_right_data[frame][joint][0] - r_disp[0],
        hand_right_data[frame][joint][1] - r_disp[1],
        hand_right_data[frame][joint][2] - r_disp[2],
      ]
      if (isNaN(hand_left_data[frame][joint][0])) {
        hand_left_data[frame][joint] = [0.0, 0.0, 0.0]
      }
      if (isNaN(hand_right_data[frame][joint][0])) {
        hand_right_data[frame][joint] = [0.0, 0.0, 0.0]
      }
    }
  }

  return [pose_data, hand_left_data, hand_right_data]
}

// Escolhendo keypoints para uso
function mediapipe_to_posenet(pose: number[][][]) {
  let n: number

  const list_poses_to_mantain = [0, 2, 5, 7, 8, 11, 12, 13, 14, 15, 16, 23, 24]
  const posenet: number[][][] = []

  for (const frame of pose) {
    const framenet: number[][] = []
    n = 0
    for (const bone of frame) {
      if (list_poses_to_mantain.includes(n)) {
        framenet.push(bone)
      }
      n += 1
    }
    framenet.push([...frame[23]])
    framenet.push([...frame[24]])
    framenet.push([...frame[23]])
    framenet.push([...frame[24]])
    posenet.push(framenet)
  }
  if (posenet.length == 0) {
    for (let i = 0; i < pose.length; i++) {
      const key_aux = []
      for (let i = 0; i < 17; i++) {
        key_aux.push([0.0, 0.0, 0.0])
      }
      posenet.push(key_aux)
    }
  }
  return posenet
}

// centralizando dados no pescoco
function chicken_neck_mediapipe(pose: number[][][], lhand: number[][][], rhand: number[][][]) {
  let disp, n
  n = 0
  const data = []
  let data_aux = []

  for (const frame of pose) {
    data_aux = []
    disp = [0, 0, 0]
    disp[0] = disp[0] - (frame[5][0] + frame[6][0]) * 0.5
    disp[1] = disp[1] - (frame[5][1] + frame[6][1]) * 0.5
    disp[2] = disp[2] - (frame[5][2] + frame[6][2]) * 0.5
    for (let i = 0; i < pose[n].length; i++) {
      pose[n][i][0] = pose[n][i][0] + disp[0]
      pose[n][i][1] = pose[n][i][1] + disp[1] - 1
      pose[n][i][2] = pose[n][i][2] + disp[2]
      data_aux.push(pose[n][i])
    }
    for (let i = 0; i < lhand[n].length; i++) {
      lhand[n][i][0] = lhand[n][i][0] + disp[0]
      lhand[n][i][1] = lhand[n][i][1] + disp[1] - 1
      lhand[n][i][2] = lhand[n][i][2] + disp[2]
      data_aux.push(lhand[n][i])
    }
    for (let i = 0; i < rhand[n].length; i++) {
      rhand[n][i][0] = rhand[n][i][0] + disp[0]
      rhand[n][i][1] = rhand[n][i][1] + disp[1] - 1
      rhand[n][i][2] = rhand[n][i][2] + disp[2]
      data_aux.push(rhand[n][i])
    }
    data.push(data_aux)
    n += 1
  }
  return data
}

// espelhamento
interface Itreat_keypoints_func {
  data: number[][][]
}

//executando funcoes
export const treat_keypoints_func = (
  pose_keypoints_data: number[][][],
  hand_left_keypoints_data: number[][][],
  hand_right_keypoints_data: number[][][],
): Itreat_keypoints_func => {
  // eslint-disable-next-line prettier/prettier
  [pose_keypoints_data, hand_left_keypoints_data, hand_right_keypoints_data] =
    interpolate_keypoints([
    pose_keypoints_data,
    hand_left_keypoints_data,
    hand_right_keypoints_data,
  ])
  ;[pose_keypoints_data, hand_left_keypoints_data, hand_right_keypoints_data] = put_hand_in_body(
    pose_keypoints_data,
    hand_left_keypoints_data,
    hand_right_keypoints_data,
  )
  const pose_net = mediapipe_to_posenet(pose_keypoints_data)
  const data = chicken_neck_mediapipe(pose_net, hand_left_keypoints_data, hand_right_keypoints_data)
  // const mirror_data = mirror_augmentation(data)
  return { data }
}

export const landMarksToArray = async (
  poseData: NormalizedLandmarkList,
  leftData: NormalizedLandmarkList,
  rightData: NormalizedLandmarkList,
) => {
  let pose: number[][]
  let handL: number[][]
  let handR: number[][]
  if (poseData) {
    pose = [...poseData].map((value) => {
      return [value?.x || 0.0, value?.y || 0.0, value?.z || 0.0]
    })
  } else {
    pose = (await tf.zeros([33, 3]).array()) as number[][]
  }
  if (leftData) {
    handL = [...leftData].map((value) => {
      return [value?.x || 0.0, value?.y || 0.0, value?.z || 0.0]
    })
  } else {
    handL = (await tf.zeros([21, 3]).array()) as number[][]
  }
  if (rightData) {
    handR = [...rightData].map((value) => {
      return [value?.x || 0.0, value?.y || 0.0, value?.z || 0.0]
    })
  } else {
    handR = (await tf.zeros([21, 3]).array()) as number[][]
  }
  return [pose, handL, handR]
}
