import { getDownloadURL, ref } from '@firebase/storage'
import {
  Fab,
  Tooltip,
  Typography,
  Box,
  Select,
  ListSubheader,
  MenuItem,
  InputLabel,
  FormControl,
  Input,
  Button,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteRounded'
import SearchIcon from '@material-ui/icons/Search'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  DocumentReferenceGeneric,
  DocumentReference,
  getFirestoreDocument,
  getPathFramesJson,
  ISegmentView,
  ISign,
  IGlobalAttr,
  IVideo,
  IMenuOptions,
  ISignOnDemand,
} from 'collections'
import React, { useState, useEffect, useCallback } from 'react'
import { useStorage } from 'reactfire'
import { useGlobal } from 'reactn'
import styled, { css } from 'styled-components'
import { ISegmentViewProcessVideo, ISignWithRef } from '..'
import FramePlayer from '../../../components/FramePlayer'
import useCurrentRegionAction from '../../../hooks/useCurrentRegionAction'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import useIntl from '../../../hooks/useIntl'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import useInteractable from '../../../services/hooks/useInteractable'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { routes } from '../../../community'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import useSnackbar from '../../../services/hooks/useSnackbar'

const InteractableStyle = styled.div`
  left: 0px;
  height: 100%;
  width: 100%;
`

const useStyles = makeStyles((theme) =>
  createStyles({
    darkText: {
      color: '#000',
      '&:before': {
        borderColor: '#000',
      },
      '&:after': {
        borderColor: '#000',
      },
    },
    icon: {
      fill: '#000',
    },
    margin: {
      margin: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    root: {
      ...theme.typography.button,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
    },
  }),
)

interface IItemStyleProps {
  showDropZone: boolean
  variant?: 'unknown' | 'sign' | 'fingerspell' | 'create'
}

const ItemStyle = styled.div`
  width: 100%;
  height: 100%;
  background: #a0a0a0;
  font-size: 1.5em;
  overflow: hidden;
  user-selection: none;
  box-sizing: border-box;
  color: #000000;
  border-top: solid 5px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  transition: background 100ms;
  display: flex;
  justify-content: center;
  padding: 10px;

  ${({ variant }: IItemStyleProps) =>
    variant === 'fingerspell' &&
    css`
      background: #d46d2a;
    `}

  ${({ showDropZone }: IItemStyleProps) =>
    showDropZone &&
    css`
      opacity: 1;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      border: dashed #fff 2px;
    `}
`

interface IVerticalTimelineItemProps {
  signRef?: DocumentReferenceGeneric | null
  isPlaying?: boolean
  index: number
  setSegments: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo[]>>
  segments: ISegmentViewProcessVideo[]
  setsegmentToSuggestion?: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo | null>>
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
  menuItems: string[]
  signOnDemand: ISignOnDemand | null

  setSignOnDemandLinked: React.Dispatch<React.SetStateAction<Record<string, ISignOnDemand>>>
  signOnDemandLinked: Record<string, ISignOnDemand>
  setSignsData: React.Dispatch<React.SetStateAction<Record<string, ISignWithRef | null>>>
  signsData: Record<string, ISignWithRef | null>
}

const VerticalTimelineItem: React.FC<IVerticalTimelineItemProps> = ({
  signRef,
  isPlaying,
  index,
  setSegments,
  segments,
  setsegmentToSuggestion,
  setEditing,
  menuItems,
  signOnDemand,
  setSignOnDemandLinked,
  signOnDemandLinked,
  signsData,
  setSignsData,
}) => {
  const intl = useIntl()
  const showSnackbar = useSnackbar()
  const [isDropped, setIsDropped] = useState(false)
  const [isDropActive, setIsDropActive] = useState(false)
  const [currentRegionActionState] = useCurrentRegionAction()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const [signOnDemandView, setSignOnDemandView] = useState<ISegmentView | null>(null)
  const [includeNewFingerspell, setIncludeNewFingerspell] = useState<string>('')
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
  const textSign = intl.get('messages.sign')
  const textNotFingerspell = intl.get('components.timeline.timelineItem.notFingerspell')
  const textFingerspell = intl.get('messages.fingerspell')
  const classes = useStyles()

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const signData = signsData[segments[index] ? segments[index].id : '']

  const setSignData = (value: ISignWithRef | null) => {
    if (segments[index].id) {
      setSignsData((val) => {
        return {
          ...val,
          [segments[index].id]: value,
        }
      })
    }
  }

  const storage = useStorage()
  const fsUser = useFsUserDocData()

  const getVideoUrl = useCallback(
    async (videoId: string, duplicateOf: string) => {
      if (!videoId) return ''
      const urlString = getPathFramesJson(fsUser.workspace.id, videoId, duplicateOf)
      const url = await getDownloadURL(ref(storage, urlString))
      return url
    },
    [fsUser.workspace.id, storage],
  )

  const handleChangeSegment = (value: DocumentReference | null) => {
    const _segments = [...segments]
    _segments[index].movement = value
    setSegments(_segments)
  }

  const handleRemoveSignOnDemand = () => {
    const _signOnDemandLinked = { ...signOnDemandLinked }

    delete _signOnDemandLinked[segments[index].id || '']
    setSignOnDemandLinked(_signOnDemandLinked)
  }

  const getSignData = async (_signRef: DocumentReference) => {
    const signSnapshot = await getFirestoreDocument(_signRef)
    const _signData = signSnapshot.data() as ISign
    const signViewData: ISignWithRef = {
      signData: _signData,
      refSign: _signRef,
    }
    /** Resgata dados do segmento */
    if (_signData.primarySegment) {
      const segmentsSnapshot = await getFirestoreDocument(_signData.primarySegment)
      if (segmentsSnapshot) {
        const segmentViewData = segmentsSnapshot.data() as ISegmentView

        const videoSnapshot = await getFirestoreDocument(segmentViewData.video)
        const videoData = videoSnapshot.data() as IVideo
        segmentViewData.videoUrl = await getVideoUrl(segmentViewData.video.id, videoData.duplicateOf || '')
        segmentViewData.id = segmentsSnapshot.id
        segmentViewData.videoDuration = videoData.duration
        segmentViewData.videoSentence = videoData.sentence
        signViewData.segmentView = segmentViewData
      }
    }
    setSignData(signViewData)
  }

  useEffect(() => {
    if (signRef) {
      getSignData(signRef as DocumentReference)
      handleChangeSegment(signRef as DocumentReference)
    } else {
      setSignData(null)
      handleChangeSegment(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signRef])

  useEffect(() => {
    const fetchSignOnDemand = async () => {
      if (!signOnDemand || !signOnDemand.segmentToView) return
      const segmentRef = signOnDemand.segmentToView
      const segmentsSnapshot = await getFirestoreDocument(segmentRef)
      const segmentViewData = segmentsSnapshot.data() as ISegmentView

      const videoSnapshot = await getFirestoreDocument(segmentViewData.video)
      const videoData = videoSnapshot.data() as IVideo
      segmentViewData.videoUrl = await getVideoUrl(segmentViewData.video.id, videoData.duplicateOf || '')
      segmentViewData.id = segmentsSnapshot.id
      segmentViewData.videoDuration = videoData.duration
      segmentViewData.videoSentence = videoData.sentence
      setSignOnDemandView(segmentViewData)
    }

    if (signOnDemand) {
      fetchSignOnDemand()
    } else {
      setSignOnDemandView(null)
    }
  }, [getVideoUrl, signOnDemand])

  const handleClickShowInHTube = () => {
    const url = `${window.location.origin}${routes.hTube.replace(':clusterId', segments[index].movement?.id || '')}`
    window.open(url, '_blank')?.focus()
  }

  const menuOptionsSignView: IMenuOptions[] = [
    {
      title: intl.get('components.signView.showInHTube'),
      onClick: handleClickShowInHTube,
    },
  ]

  const handleChangeFingerspell = (value: string, index: number) => {
    if (value === '_sign') {
      segments[index].type = 'sign'
      segments[index].text = ''
    } else {
      segments[index].type = 'fingerspell'
      segments[index].text = value
    }
    setSegments(segments)
  }

  const { transform, elemRef } = useInteractable({
    dropzone: true,
    contained: true,
    resizable: false,
    lockDragAxis: 'x',
    dropzoneAccept: 'sign',
    minX: 0,
    maxX: 0,
    width: 284,
    onDrop: () => {
      setIsDropped(true)
      setIsDropActive(false)
    },
    onDropActivate: () => {
      setIsDropActive(true)
    },
    onDropDeactivate: () => {
      setIsDropActive(false)
      setIsDropped(false)
    },
    baseX: 0,
    minSize: 0,
    draggable: false,
  })

  useEffect(() => {
    if (isDropped && currentRegionActionState?.sign && !signData && segments[index].type !== 'fingerspell') {
      setEditing(true)
      handleChangeSegment(currentRegionActionState.sign)
      getSignData(currentRegionActionState.sign)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropped])

  const handleRemoveSign = () => {
    setEditing(true)
    setSignData(null)
    handleChangeSegment(null)
  }

  const SelectFingerspell = (
    <Select
      id="grouped-select"
      value={segments[index].type == 'sign' ? '_sign' : segments[index].text}
      className={`${classes.darkText} notranslate`}
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      onChange={(
        event: React.ChangeEvent<{
          name?: string | undefined
          value: unknown
        }>,
      ) => {
        handleChangeFingerspell(event.target.value as string, index)
      }}
    >
      <ListSubheader>{textNotFingerspell}</ListSubheader>
      <MenuItem className={`notranslate`} value={'_sign'}>
        {textSign}
      </MenuItem>
      <ListSubheader>{textFingerspell}</ListSubheader>

      {menuItems.map((value, id) => (
        <MenuItem className={`notranslate`} value={value} key={id}>
          {value}
        </MenuItem>
      ))}
    </Select>
  )

  const toggleDialog = () => {
    setOpenConfirmation(!openConfirmation)
  }

  const isLetterOrNumber = (str: string) => {
    return (str.length === 1 && str.match(/[A-Za-z]/i)) || str.match(/[0-9]/i)
  }

  const InputNewFingerspell = (
    <FormControl
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
      }}
    >
      <InputLabel className={classes.darkText} htmlFor="demo-customized-textbox" color="primary">
        {intl.get('pages.processVideo.text')}
      </InputLabel>
      <Input
        className={classes.darkText}
        id="demo-customized-textbox"
        color="primary"
        value={includeNewFingerspell}
        onChange={(event) => {
          const newValue = event?.target.value

          const lastItem = newValue[newValue.length - 1] || ''
          if (newValue != '' && !isLetterOrNumber(lastItem)) {
            showSnackbar(intl.get('pages.processVideo.justLetterAndNumber'), {
              variant: 'warning',
            })
            return
          }

          setIncludeNewFingerspell(event?.target.value)
        }}
      />
      <Tooltip title={intl.get('pages.processVideo.addText')}>
        <Button
          className={classes.darkText}
          onClick={() => {
            const index = menuItems.findIndex((el) => {
              return el == includeNewFingerspell
            })

            if (index >= 0) {
              showSnackbar(intl.get('pages.processVideo.alreadyExistText'), {
                variant: 'warning',
              })
              return
            }

            toggleDialog()
          }}
        >
          <AddCircleIcon />
        </Button>
      </Tooltip>
    </FormControl>
  )

  return (
    <InteractableStyle
      ref={elemRef}
      style={{
        touchAction: 'none',
        bottom: '10px',
        top: 'unset',
        transform: `translate(${transform.offsetX}px, ${transform.offsetY}px)`,
        left: `${transform.x}px`,
        width: '100%',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '95%',
        }}
      >
        {signOnDemandView && signOnDemand && (
          <Box style={{ border: '1px solid #F06F06' }}>
            <div
              style={{
                position: 'absolute',
                zIndex: 1,
                right: '7%',
                top: '5px',
              }}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="player"
                onClick={() => {
                  handleRemoveSignOnDemand()
                }}
              >
                <DeleteIcon />
              </Fab>
            </div>

            <FramePlayer
              reference={signOnDemandView.id}
              src={signOnDemandView.videoUrl || ''}
              onReady={() => ({})}
              onError={() => ({})}
              startFrame={signOnDemandView.startFrame}
              framesDuration={signOnDemandView.endFrame}
              timeFrame={appConfig.frameRate}
              frameRatio={30}
              onChangedTime={handleOnChangedTime}
              timelineCursor={timelineCursor}
              autoPlay={isPlaying}
              hoverable={true}
              fullHeight={false}
              fullVideoDuration={signOnDemandView.videoDuration}
              menuOptions={menuOptionsSignView}
            />
          </Box>
        )}
        {signData && signData.segmentView && (
          <>
            {segments[index].id !== signData.signData.primarySegment?.id && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  right: '7%',
                  top: '5px',
                }}
              >
                <Fab
                  size="small"
                  color="primary"
                  aria-label="player"
                  onClick={() => {
                    handleRemoveSign()
                  }}
                >
                  <DeleteIcon />
                </Fab>
              </div>
            )}
            <FramePlayer
              reference={signData.segmentView.id}
              src={signData.segmentView.videoUrl || ''}
              onReady={() => ({})}
              onError={() => ({})}
              startFrame={signData.segmentView.startFrame}
              framesDuration={signData.segmentView.endFrame}
              timeFrame={appConfig.frameRate}
              frameRatio={30}
              onChangedTime={handleOnChangedTime}
              timelineCursor={timelineCursor}
              autoPlay={isPlaying}
              hoverable={true}
              fullHeight={false}
              fullVideoDuration={signData.segmentView.videoDuration}
              menuOptions={menuOptionsSignView}
            />
          </>
        )}
        {((!signOnDemand && !signData) ||
          (signData &&
            signData.segmentView &&
            !signData.segmentView?.movement &&
            signData.segmentView?.type === 'fingerspell')) && (
          <ItemStyle
            showDropZone={isDropActive && segments[index].type !== 'fingerspell'}
            variant={segments[index].type}
          >
            {segments[index].type === 'fingerspell' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Typography variant="subtitle1" align="center">
                  {textFingerspell}
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                  className={classes.margin}
                >
                  {SelectFingerspell}
                  {InputNewFingerspell}
                </Box>
                <Typography variant="h6" align="center">
                  {segments[index].text}
                </Typography>
              </div>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography align="center" variant="subtitle1">
                  {intl.get('messages.dragSignHere')}
                </Typography>
                {SelectFingerspell}
                <Tooltip title={intl.get('messages.searchRelatedSignsOnModel')}>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="player"
                    onClick={() => {
                      if (setsegmentToSuggestion) setsegmentToSuggestion(segments[index] || null)
                    }}
                  >
                    <SearchIcon />
                  </Fab>
                </Tooltip>
              </Box>
            )}
            <ConfirmationDialog
              open={openConfirmation}
              toggleOpen={toggleDialog}
              handleYes={() => {
                handleChangeFingerspell(includeNewFingerspell, index)
                toggleDialog()
              }}
              title={intl.get('pages.processVideo.addText')}
              subtitle={intl.get('pages.processVideo.confirmAddText')}
            />
          </ItemStyle>
        )}
      </div>
    </InteractableStyle>
  )
}

export default VerticalTimelineItem
