import React from 'react'
import SelectThemePure from './Pure'
import useTheme from '../../../services/hooks/useTheme'

const ControlledThemeSelect = (): JSX.Element => {
  const [{ type }, updateTheme] = useTheme()

  const handleChange = (
    select: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    updateTheme((select.target.value as 'dark' | 'light') || 'dark')
  }

  return <SelectThemePure value={type} onChange={handleChange} />
}

export default ControlledThemeSelect
