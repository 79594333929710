import {
  AppBar,
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tab,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  CommunityModule,
  CommunityModuleName,
  IWorkspace,
  updateWorkspace,
  IWorkspacePermissions,
  getUser,
  IUser,
} from 'collections'
import useSnackbar from '../../../services/hooks/useSnackbar'
import useIntl from '../../../hooks/useIntl'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { useFirestore } from 'reactfire'
import { collection, doc, documentId, getDocs, query, where } from 'firebase/firestore'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Preloader from '../../../components/Preloader'

interface IModalIncludeUser {
  openUser: boolean
  setOpenUser: Dispatch<SetStateAction<boolean>>
  workspaces: string[]
  modules: string[]
  selectedModules: string[]
  selectedUser:
    | {
        id: string
        name: string
        email: string
        workspaceId: string
        userRole: string
        permissions: IWorkspacePermissions
        isExternal?: boolean
      }
    | undefined
  setSelectedUser: Dispatch<
    SetStateAction<
      | {
          id: string
          name: string
          email: string
          workspaceId: string
          userRole: string
          permissions: IWorkspacePermissions
          isExternal?: boolean
        }
      | undefined
    >
  >
  updateUserModules: (user: string, modules: CommunityModule[], isExternal: boolean) => Promise<void>
  getUsers: () => Promise<void>
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const ModalIncludeUser: React.FC<IModalIncludeUser> = ({
  openUser,
  setOpenUser,
  workspaces,
  modules,
  selectedUser,
  setSelectedUser,
  updateUserModules,
  getUsers,
}) => {
  const intl = useIntl()
  const firestore = useFirestore()
  const showSnackbar = useSnackbar()

  const [tabValue, setTabValue] = useState(0)
  const [workspaceTabs, setWorkspaceTabs] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [activesWorkspaces, setActivesWorkspaces] = useState<string[]>([])

  const handleCheckPermission = (workspace: string, module: string): boolean => {
    if (
      selectedUser?.permissions[module] &&
      selectedUser?.permissions[module][workspace] &&
      selectedUser?.permissions[module][workspace] == 1
    )
      return true
    else return false
  }

  const handleChangeSelectedModules = (workspace: string, module: string) => {
    if (!selectedUser) return

    const _selectedUser = { ...selectedUser }

    if (
      selectedUser.permissions[module] &&
      selectedUser.permissions[module][workspace] &&
      selectedUser?.permissions[module][workspace] == 1
    ) {
      _selectedUser.permissions[module][workspace] = _selectedUser.permissions[module][workspace] == 1 ? 0 : 1
    } else {
      _selectedUser.permissions = {
        ..._selectedUser.permissions,
        [module]: {
          ..._selectedUser.permissions[module],
          [workspace]: 1,
        },
      }
    }

    setSelectedUser(_selectedUser)
  }

  const fetchIsExternal = async () => {
    if (selectedUser?.id) {
      const userRef = doc(firestore, 'users', selectedUser?.id)
      const _userSnapshot = await getUser(userRef)
      const userData = _userSnapshot.data() as IUser
      const _selectedUser = { ...selectedUser }
      _selectedUser['isExternal'] = userData.isExternal
      setSelectedUser(_selectedUser)
    }
  }

  const handleChangeName = (event: ChangeEvent<{ value: unknown }>) => {
    if (!selectedUser) return
    const name = event.target.value as string
    const _selectedUser = {
      ...selectedUser,
      name,
    }

    setSelectedUser(_selectedUser)
  }

  const handleChangeIsExternal = (_event: ChangeEvent<{ name: string }>, checked: boolean) => {
    if (!selectedUser) return
    const _selectedUser = { ...selectedUser }
    _selectedUser['isExternal'] = checked
    setSelectedUser(_selectedUser)
  }

  const handleChangeWorkspaceCheckbox = (event: ChangeEvent<{ name: string }>, checked: boolean) => {
    if (!selectedUser) return

    const _activesWorkspaces = [...activesWorkspaces]
    const name = event.target.name
    if (checked) _activesWorkspaces.push(name)
    else {
      const index = activesWorkspaces.findIndex((el) => el == name)
      _activesWorkspaces.splice(index, 1)
    }
    _activesWorkspaces.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0
    })

    const workspaceId = activesWorkspaces.filter(function (obj) {
      return _activesWorkspaces.indexOf(obj) === -1
    })
    const _selectedUser = { ...selectedUser }
    Object.entries(selectedUser.permissions).forEach((module) => {
      if (_selectedUser.permissions[module[0]] && _selectedUser.permissions[module[0]][workspaceId[0]])
        _selectedUser.permissions[module[0]][workspaceId[0]] = 0
    })
    setActivesWorkspaces(_activesWorkspaces)
    setWorkspaceTabs(_activesWorkspaces)
  }

  const handleSaveUserClick = async () => {
    setIsSaving(true)
    try {
      if (!selectedUser) return
      let workspacesId: string[] = []
      Object.entries(selectedUser.permissions).map((modules) => {
        Object.entries(modules[1]).forEach((el) => {
          workspacesId = [...new Set([...workspacesId, el[0]])]
        })
      })
      const workspaceCollection = collection(firestore, 'workspaces')
      const newQuery = query(workspaceCollection, where(documentId(), 'in', workspacesId))
      const workspaceSnapshot = await getDocs(newQuery)
      const userModules: CommunityModule[] = []
      for (const document of workspaceSnapshot.docs) {
        const workspaceRef = document.ref
        const workspaceData = document.data() as IWorkspace
        let _workspaceData = JSON.parse(JSON.stringify(workspaceData))
        if (!_workspaceData.userData)
          _workspaceData = {
            ..._workspaceData,
            userData: {},
          }
        if (!_workspaceData.userData[selectedUser.id])
          _workspaceData.userData[selectedUser.id] = {
            photoURL: '',
            displayName: '',
            roles: {},
          }
        let roles: Record<string, boolean> = {}
        Object.entries(selectedUser.permissions).map(async (modules) => {
          const workspaces = modules[1]
          Object.entries(workspaces).forEach((el) => {
            if (el[0] == workspaceRef.id && el[1] == 1) {
              roles = { ...roles, [modules[0]]: true }
              userModules.push(modules[0] as CommunityModule)
            } else if (el[0] == workspaceRef.id) {
              roles = { ...roles, [modules[0]]: false }
            }
          })
        })

        _workspaceData.userData[selectedUser.id] = {
          ..._workspaceData.userData[selectedUser.id],
          roles,
        }
        await updateWorkspace(workspaceRef, _workspaceData)
      }
      // Remove duplicados
      const _modules = Array.from(new Set(userModules))
      await updateUserModules(selectedUser.id, _modules, selectedUser.isExternal || false)

      showSnackbar(intl.get('messages.savedSuccessfully'), {
        variant: 'success',
      })
    } catch (err) {
      showSnackbar((err as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    } finally {
      getUsers()
      setTabValue(0)
      setIsSaving(false)
      setOpenUser(false)
      setSelectedUser(undefined)
    }
  }

  useEffect(() => {
    if (!selectedUser) return

    let _activesWorkspaces: string[] = []
    const _workspaceTabs: string[] = []

    Object.values(selectedUser?.permissions).map((element) => {
      Object.entries(element).forEach((el) => {
        if (_workspaceTabs.indexOf(el[0]) == -1 && el[1] == 1) _workspaceTabs.push(el[0])
        if (el[1] == 1) _activesWorkspaces = [...new Set([..._activesWorkspaces, el[0]])]
      })
    })
    setActivesWorkspaces(
      _activesWorkspaces.sort((a, b) => {
        return a > b ? 1 : b > a ? -1 : 0
      }),
    )
    _workspaceTabs.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0
    })
    setWorkspaceTabs(_workspaceTabs)

    if (_workspaceTabs.length == 0) setTabValue(0)
    else if (_workspaceTabs.length < workspaceTabs.length && tabValue > 0) setTabValue(tabValue - 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser])

  useEffect(() => {
    return () => {
      setSelectedUser(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (openUser) {
      fetchIsExternal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUser])

  return (
    <Dialog
      open={openUser}
      onClose={() => {
        setOpenUser(false)
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setOpenUser(false)
        }}
      >
        {`${intl.get('pages.updatePermissions.userInfo')}`}
      </DialogTitle>
      {isSaving && <Preloader text={intl.get('messages.savingData')} />}
      <DialogContent>
        <Box style={{ width: '100%', filter: isSaving ? 'blur(3px)' : 'none' }}>
          <TabContext value={tabValue.toString()}>
            <Box
              style={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <AppBar position="static" color="default">
                <TabList
                  onChange={(_, value: number) => {
                    setTabValue(value)
                  }}
                  indicatorColor="primary"
                  centered
                >
                  <Tab label={intl.get('pages.updatePermissions.general')} value={'0'} />
                  {workspaceTabs.map((tab, index) => (
                    <Tab key={index + 1} label={tab} value={(index + 1).toString()} />
                  ))}
                </TabList>
              </AppBar>
            </Box>
            <TabPanel value={'0'} key={0}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>{`Name`}</Typography>
                  <TextField id="name" variant="outlined" value={selectedUser?.name} onChange={handleChangeName} />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>{`Email`}</Typography>
                  <TextField id="email" variant="outlined" value={selectedUser?.email} disabled />
                </Grid>
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name={'isExternal'}
                    checked={selectedUser?.isExternal || false}
                    onChange={handleChangeIsExternal}
                  />
                }
                label={'Usuário externo'}
              />
              <Box style={{ padding: '24px' }}>
                <Typography>Workspaces</Typography>
                <FormGroup
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxHeight: '200px',
                  }}
                >
                  {workspaces.map((workspace, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="primary"
                          name={workspace}
                          checked={activesWorkspaces.includes(workspace) ? true : false}
                          onChange={handleChangeWorkspaceCheckbox}
                        />
                      }
                      label={workspace}
                    />
                  ))}
                </FormGroup>
              </Box>
            </TabPanel>
            {selectedUser &&
              workspaceTabs.map((workspace, index) => (
                <TabPanel key={`${workspace}-${index}`} value={(index + 1).toString()}>
                  <TabPanel
                    value={(index + 1).toString()}
                    key={index + 1}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography style={{ marginBottom: '10px' }}>{`${intl.get(
                          'pages.updatePermissions.modules',
                        )}`}</Typography>
                        <Box>
                          <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                minHeight: '300px',
                                maxHeight: '300px',
                              }}
                              wrap="wrap"
                            >
                              {modules.map((module) => (
                                <FormControlLabel
                                  key={module}
                                  label={
                                    <Typography variant="body2">
                                      {CommunityModuleName[module as CommunityModule] || ''}
                                    </Typography>
                                  }
                                  control={
                                    <Checkbox
                                      size="small"
                                      color="primary"
                                      onChange={() => {
                                        handleChangeSelectedModules(workspace, module)
                                      }}
                                      checked={handleCheckPermission(workspace, module)}
                                    />
                                  }
                                />
                              ))}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabPanel>
              ))}
          </TabContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSaveUserClick} color="primary" variant="contained">
          {intl.get('messages.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalIncludeUser
