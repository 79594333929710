import { useCallback, useState } from 'react'
import { useGlobal, useEffect } from 'reactn'

import { useDatabase, useRemoteConfig } from 'reactfire'
import { useFsUserDocData } from '../hooks/useFsUser'

import { CommunityModule, IGlobalAttr } from 'collections'
import { limitToLast, onValue, orderByChild, query, ref } from '@firebase/database'
import { fetchAndActivate, getValue } from '@firebase/remote-config'

interface ILockModuleHook {
  lockModule: () => void
  lockedRecordVideo: boolean
  setLockedRecordVideo: (locked: boolean) => void
  lockedValidateTranslation: boolean
  checkLockedModule: (communityModule: CommunityModule) => void
  open: boolean
  toggleOpen: () => void
}

const useLockModules = (): ILockModuleHook => {
  const [minPrioritySentences, setMinPrioritySentences] = useGlobal<IGlobalAttr, 'minPrioritySentences'>(
    'minPrioritySentences',
  )
  const fsRemoteConfig = useRemoteConfig()
  const [lockedRecordVideo, setLockedRecordVideo] = useState(false)
  const [lockedValidateTranslation, setLockedValidateTranslation] = useState(false)
  const [open, setOpen] = useState(false)
  const fsUser = useFsUserDocData()
  const db = useDatabase()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const checkLockedModule = (communityModule: CommunityModule) => {
    if (communityModule === 'signSentence' && lockedRecordVideo) {
      toggleOpen()
    } else if (communityModule === 'validateTranslation' && lockedValidateTranslation) {
      toggleOpen()
    }
  }

  const lockModule = useCallback(async () => {
    if (minPrioritySentences && minPrioritySentences[fsUser?.workspace.id]) {
      const _countSentences = { translationPriority: 0, recordVideoPriority: 0 }
      const minPriorityRecord = minPrioritySentences[fsUser?.workspace.id].recordVideoPriority || 0
      const minPriorityValidation = minPrioritySentences[fsUser?.workspace.id].translationPriority || 0
      const referenceRecordString = `${fsUser?.workspace.id}/${fsUser?.oralLanguageId}/${
        fsUser?.signLanguageId
      }/${'recordVideoPriority'}`
      const countSentences = await new Promise<{
        translationPriority: number
        recordVideoPriority: number
      }>((resolve) => {
        const referenceRecord = query(ref(db, referenceRecordString), orderByChild('priority'), limitToLast(1))

        onValue(referenceRecord, (value) => {
          const sentences = value.val()
          let countSentencesToRecord = 0
          if (sentences) {
            // TODO -> Implementar interfaces do database
            countSentencesToRecord = Object.entries(sentences || {}).filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (el: any) => {
                return el[1].priority >= minPriorityRecord
              },
            ).length
          }
          _countSentences['recordVideoPriority'] = countSentencesToRecord
        })
        const referenceValidationString = `${fsUser?.workspace.id}/${fsUser?.oralLanguageId}/${
          fsUser?.signLanguageId
        }/${'translationPriority'}`
        const referenceValidation = query(ref(db, referenceValidationString), orderByChild('priority'), limitToLast(1))

        onValue(referenceValidation, (value) => {
          const sentences = value.val()
          let countSentencesToValidation = 0
          if (sentences) {
            // TODO -> Implementar interfaces do database
            countSentencesToValidation = Object.entries(sentences || {}).filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (el: any) => el[1].priority >= minPriorityValidation,
            ).length
          }
          _countSentences['translationPriority'] = countSentencesToValidation
          resolve(_countSentences)
        })
      })
      setLockedRecordVideo(countSentences.recordVideoPriority == 0)
      setLockedValidateTranslation(countSentences.translationPriority == 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrioritySentences, fsUser?.workspace.id])

  useEffect(() => {
    const call = async () => {
      await fetchAndActivate(fsRemoteConfig)
      const _minPrioritySentences = getValue(fsRemoteConfig, 'HandTalk_MinPrioritySentences').asString() || '{}'
      setMinPrioritySentences(JSON.parse(_minPrioritySentences))
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsRemoteConfig, fsUser?.signLanguageId])

  useEffect(() => {
    lockModule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser?.workspace.id, minPrioritySentences])

  return {
    lockModule,
    lockedRecordVideo,
    setLockedRecordVideo,
    lockedValidateTranslation,
    checkLockedModule,
    open,
    toggleOpen,
  }
}

export default useLockModules
