import { useCallback } from 'react'
import { useGlobal } from 'reactn'
import { useLocation } from 'wouter'

import logger from '../services/logger'
import useSnackbar from '../services/hooks/useSnackbar'
import {
  getCollectionReference,
  getFirestoreDocument,
  IGlobalAttr,
  IVideo,
  updateSegment,
  WorkspaceId,
} from 'collections'
import { useFsUserDocData, useFsUserDocRef } from './useFsUser'
import { routes } from '../community'
import useTimelineRegions from './useTimelineRegions'
import { updateVideoFlags } from 'collections'
import { DocumentReference, serverTimestamp, Timestamp, createSegment, ISegment } from 'collections'
import useIntl from './useIntl'
import { addContribSegmentFunction } from '../services/firebase'
import { getDocumentReference } from 'collections'
import { DocumentData, getDocs, Query, query, where } from 'firebase/firestore'

const { log, error } = logger('VideoSegmentModule.tsx')

interface IModuleSegmentVideoFns {
  refreshVideo: () => void
  skipVideo: () => void
  save: (
    edit?: boolean,
    setIsSegmenting?: React.Dispatch<React.SetStateAction<boolean>>,
    setIsSaving?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void
}

const useModuleSegmentVideo = (): IModuleSegmentVideoFns => {
  const intl = useIntl()
  const showSnackbar = useSnackbar()
  const [, setLocation] = useLocation()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const [timelineRegions, timelineRegionsFns] = useTimelineRegions()
  const [fsVideoDocData] = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')
  const [fsVideoRef] = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')

  /**
   * Pega o proximo vídeo
   */
  const refreshVideo = useCallback(
    async (edit?: boolean) => {
      timelineRegionsFns.clear()
      if (fsVideoDocData && fsVideoRef && fsUserRef.id == fsVideoDocData.isSegmenting?.user?.id) {
        const videoRef = getDocumentReference(fsUser.workspace, 'videos', fsVideoRef.id)
        await updateVideoFlags(videoRef, {
          isSegmenting: {
            value: false,
            lastUpdate: serverTimestamp() as Timestamp,
            user: fsUserRef,
          },
        })
      }
      if (edit) {
        setLocation(routes.segmentASignContributions)
      } else {
        setLocation(routes.segmentASignHome)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsVideoDocData, fsVideoRef, fsUserRef, setLocation, fsUser.workspace],
  )

  /**
   * Pula para o proximo vídeo
   */
  const skipVideo = useCallback(() => {
    if (fsVideoRef) {
      log(`skipping video ${fsVideoRef.id}`)
      //      skipVideoForTagFunction({ videoId: fsVideoRef.id })
      refreshVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsVideoRef, refreshVideo, timelineRegionsFns])

  /**
   * Salva os sinais no banco de dados
   */
  const save = useCallback(
    async (
      edit?: boolean,
      setIsSegmenting?: React.Dispatch<React.SetStateAction<boolean>>,
      setIsSaving?: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      if (!fsVideoRef || !fsVideoDocData || timelineRegions.length === 0) {
        showSnackbar(intl.get('hooks.useModuleSegmentVideo.timelineIsEmpty'), {
          variant: 'error',
        })
        return
      }

      showSnackbar(intl.get('hooks.useModuleSegmentVideo.savingRegions'), {
        variant: 'info',
      })

      // Checa se o vídeo foi segmentado por outro usuário
      const videoSnapshot = await getFirestoreDocument(fsVideoRef)
      const videoData = videoSnapshot.data() as IVideo
      if (!edit && videoData.numberOfSegmentations > 0) {
        showSnackbar(intl.get('pages.segmentVideo.video.alreadySegmentedVideo'), {
          variant: 'error',
        })
        return
      }

      try {
        await Promise.all(
          timelineRegions.map(async (region) => {
            let segment: DocumentReference | null = null
            if (edit) {
              if (region && region.ref) {
                await updateSegment(region.ref, {
                  startFrame: region.startFrame,
                  endFrame: region.endFrame,
                })
                segment = region.ref
              }
            } else {
              // Cria o segmento necessario
              segment = (await createSegment(fsUser.workspace, fsUser, fsUserRef, {
                startFrame: region.startFrame,
                endFrame: region.endFrame,
                video: fsVideoRef,
                type: region.type,
                text: region.text || '',
              })) as DocumentReference
            }
            return segment
          }),
        )
        if (!edit) {
          /** Atualizando número de segmentos do video
           e os segmentos a serem processados */
          const _query = query(
            getCollectionReference(fsUser.workspace, 'segments') as Query<DocumentData>,
            where('video', '==', fsVideoRef),
          )
          const segmentsSnapshot = await getDocs(query(_query))
          let segmentsCount: number = 0
          let segmentsToProcessCount: number = 0

          segmentsSnapshot.docs.map((segment) => {
            segmentsCount++
            const segmentData = segment.data() as ISegment
            if (segmentData.type == 'sign') segmentsToProcessCount++
          })

          addContribSegmentFunction({
            videoId: fsVideoRef.id,
            userId: fsUserRef.id,
            workspaceId: fsUser.workspace.id as WorkspaceId,
            numberOfSegments: segmentsCount,
            segmentsToProcess: segmentsToProcessCount,
          })
        }
        if (setIsSaving) setIsSaving(false)

        showSnackbar(intl.get('hooks.useModuleSegmentVideo.videosSaved'), {
          variant: 'success',
        })
        if (setIsSegmenting) setIsSegmenting(false)
        else await refreshVideo()
      } catch (e) {
        error(e)
        showSnackbar(intl.get('hooks.useModuleSegmentVideo.failSaving'), {
          variant: 'error',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser, fsUserRef, fsVideoDocData, fsVideoRef, refreshVideo, showSnackbar, timelineRegions, timelineRegionsFns],
  )

  const fns: IModuleSegmentVideoFns = {
    refreshVideo,
    save,
    skipVideo,
  }

  return fns
}

export default useModuleSegmentVideo
