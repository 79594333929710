import React, { useState } from 'react'
import { useFsUserDocData } from '../../../../hooks/useFsUser'
import {
  DocumentData,
  getCollectionReference,
  getFirestoreDocument,
  getPathFramesJson,
  ISegmentView,
  ISignOnDemand,
  IVideo,
  preProcess,
  Query,
} from 'collections'
import { Box, CircularProgress, Grid, IconButton, Paper } from '@material-ui/core'
import HoverablePlayer from '../../../HTube/HoverablePlayer'
import { orderBy, where, query, getDocs, documentId } from '@firebase/firestore'
import { getDownloadURL, ref as refStorage } from 'firebase/storage'
import { useStorage } from 'reactfire'
import useIntl from '../../../../hooks/useIntl'
import InputBase from '@material-ui/core/InputBase'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'

interface ISearchSignOnDemandResult {
  text: string | null
  signOnDemand?: ISignOnDemand | null
}

interface ISearchSignOnDemandProps {
  selectedSignOnDemand: ISearchSignOnDemandResult
  setSelectedSignOnDemand: React.Dispatch<React.SetStateAction<ISearchSignOnDemandResult>>
}

interface ISignOnDemandWithSign extends ISignOnDemand {
  id?: string
  segmentView: ISegmentView
}

interface ISearchFieldSignProps {
  onKeyUpSearch: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  text: string
  onClickSearch: () => void
  children?: JSX.Element
}

const SearchField = ({ text, onTextChange, onKeyUpSearch, onClickSearch, children }: ISearchFieldSignProps) => {
  const intl = useIntl()
  return (
    <Paper variant="outlined">
      <Box display="flex" alignItems="center" borderBottom="1px solid #cecece" borderRadius={0}>
        <InputBase
          placeholder={intl.get('messages.search')}
          style={{ padding: 10, width: '100%' }}
          value={text}
          onChange={onTextChange}
          onKeyUp={onKeyUpSearch}
        />
        <Tooltip title={intl.get('messages.search')}>
          <IconButton
            aria-label="search"
            style={{ padding: 10 }}
            onClick={() => {
              onClickSearch()
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {children}
    </Paper>
  )
}

export const SearchSignOnDemand: React.FC<ISearchSignOnDemandProps> = ({
  setSelectedSignOnDemand,
  selectedSignOnDemand,
}) => {
  const fsUser = useFsUserDocData()
  const [signsOnDemand, setSignsOnDemand] = useState<ISignOnDemandWithSign[]>([])
  const [searchText, setSearchText] = useState('')
  const storage = useStorage()

  const signOnDemandCollectionsRef = getCollectionReference(fsUser.workspace, 'signsOnDemand')
  const [isLoading, setIsLoading] = useState(false)

  const segmentsCollectionsRef = getCollectionReference(fsUser.workspace, 'segments')
  const getVideoUrl = async (videoId: string, duplicateOf: string) => {
    if (!videoId) return ''
    const urlString = getPathFramesJson(fsUser.workspace.id, videoId, duplicateOf)
    const url = await getDownloadURL(refStorage(storage, urlString))
    return url
  }

  const fetchSegmentsView = async (segmentsIds: string[]) => {
    const result: Record<string, ISegmentView> = {}
    const batch: string[][] = []
    const batchNumber = 9
    for (let i = 0; i < segmentsIds.length; i = i + batchNumber) {
      batch.push(segmentsIds.slice(i, i + batchNumber))
    }

    for (const segments of batch) {
      const segmentsQuery = query(segmentsCollectionsRef as Query<DocumentData>, where(documentId(), 'in', segments))
      const segmentsSnapshot = await getDocs(segmentsQuery)

      for (const segmentSnapshot of segmentsSnapshot.docs) {
        const segmentData = segmentSnapshot.data() as ISegmentView
        segmentData.id = segmentSnapshot.id
        const videoSnapshot = await getFirestoreDocument(segmentData.video)
        const videoData = videoSnapshot.data() as IVideo
        segmentData.videoUrl = await getVideoUrl(segmentData.video.id, videoData.duplicateOf || '')
        result[segmentSnapshot.id] = segmentData
      }
    }
    return result
  }

  const fetchSignsOnDemand = async () => {
    setIsLoading(true)
    try {
      if (!searchText.trim()) {
        setSignsOnDemand([])
        return
      }
      const searchTerm = preProcess(searchText, fsUser.workspace.id, true, true, false)
      const signsOnDemandQuery = query(
        signOnDemandCollectionsRef as Query<DocumentData>,
        where('segmentToView', '!=', null),
        where('status', 'in', ['pending', 'request', 'recordedVideo', 'recordingVideo']),
        where('searchTerms', 'array-contains', searchTerm),
        orderBy('segmentToView'),
        orderBy('createdAt', 'desc'),
      )

      const signsOnDemandSnapshot = await getDocs(signsOnDemandQuery)
      const _signsOnDemand: ISignOnDemandWithSign[] = []

      for (const signOnDemandSnapshot of signsOnDemandSnapshot.docs) {
        const signOnDemandData = signOnDemandSnapshot.data() as ISignOnDemandWithSign
        signOnDemandData.id = signOnDemandSnapshot.id

        _signsOnDemand.push(signOnDemandData)
      }

      const segmentsIds = _signsOnDemand.map((el) => el.segmentToView?.id || '')

      const segmentsData = await fetchSegmentsView(segmentsIds)
      _signsOnDemand.forEach((el) => {
        if (segmentsData[el.segmentToView?.id || '']) {
          el.segmentView = segmentsData[el.segmentToView?.id || '']
        }
      })

      setSignsOnDemand(_signsOnDemand)
    } finally {
      setIsLoading(false)
    }
  }
  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = (event.target && event.target.value) || ''
    setSearchText(text)
    const _selectedSignOnDemand = { ...selectedSignOnDemand }
    _selectedSignOnDemand.text = text

    setSelectedSignOnDemand(_selectedSignOnDemand)
  }

  const handleKeyUpSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSearchSigns()
    }
  }

  const handleSearchSigns = () => {
    fetchSignsOnDemand()
  }

  const handleClickItem = (item?: ISignOnDemand) => {
    const _selectedSignOnDemand = { ...selectedSignOnDemand }
    if (!item || item?.id === selectedSignOnDemand.signOnDemand?.id) {
      _selectedSignOnDemand.signOnDemand = null
    } else {
      _selectedSignOnDemand.signOnDemand = {
        ...item,
      }
    }

    setSelectedSignOnDemand(_selectedSignOnDemand)
  }

  return (
    <Grid item style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <SearchField
        onTextChange={handleSearchTextChange}
        text={searchText}
        onClickSearch={handleSearchSigns}
        onKeyUpSearch={handleKeyUpSearch}
      >
        <>
          {isLoading && (
            <Grid
              item
              container
              style={{
                justifyContent: 'space-around',
                padding: '10px',
                minHeight: '250px',
              }}
            >
              <CircularProgress />
            </Grid>
          )}
          {!isLoading && (
            <Grid
              item
              container
              style={{
                padding: '10px',
                height: '350px',
                gap: '5px',
                justifyContent: 'space-evenly',
                overflow: 'auto',
              }}
            >
              {signsOnDemand.map((el) => (
                <Grid key={el.id} item md={3}>
                  <div
                    style={{
                      border: selectedSignOnDemand.signOnDemand?.id === el?.id ? '4px solid #5B69C2' : '',
                    }}
                    onClick={() => {
                      handleClickItem(el)
                    }}
                  >
                    {el.segmentView && <HoverablePlayer segment={el.segmentView} />}
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      </SearchField>
    </Grid>
  )
}
