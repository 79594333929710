import { Holistic, HAND_CONNECTIONS, FACEMESH_CONTOURS, Results, VERSION } from '@mediapipe/holistic'

import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils'
import { POSE_CONNECTIONS_NO_FACE_NO_HANDS } from '../services/utils'

// Inicializar Holistic
const holistic = new Holistic({
  locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@${VERSION}/${file}`,
})

// Setar Opções
holistic.setOptions({
  // Complexidade. 0-Baixa, 1- Padrão, 2-Alta. A baixa funciona ótimo!
  modelComplexity: 1,
  // Inverte a imagem horizontalmente, funcionando como um espelho.
  selfieMode: true,
  // smoothLandmarks: false,
  // enableSegmentation: false,
  // smoothSegmentation: false,
  // refineFaceLandmarks: false,
  // minDetectionConfidence: 0.3,
  // minTrackingConfidence: 0.5,
  // useCpuInference: true
})

// Cores usadas no wireframe
const transparentOrange = '#F06F06'
const transparentPurple = '#542DC2'

/**
 * Utiliza o Hollistic e desenha seu resultado em um canvas junto com a imagem.
 * @param {*} onResults Função chamada sempre que um resultado do Hollistic aparecer
 * @param {*} canvasRef Referência para o Canvas para desenhar o objeto
 * @returns Hollistic inicializado
 */
interface IUseHolisticProps {
  onResults: (results: Results) => void
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
  showKeypoints?: boolean
}

const useHollistic = ({ onResults, canvasRef, showKeypoints }: IUseHolisticProps) => {
  // Definir o onResults do hollistic
  holistic.onResults((results) => {
    onResults(results)

    // Se não há elemento de canvas, o trabalho está terminado!
    if (!canvasRef || !canvasRef.current) return

    // Extraímos algumas variáveis usadas muitas vezes.
    const canvasEl = canvasRef.current
    const canvasCon = canvasEl.getContext('2d')

    if (!canvasCon) return

    // Salva o estado, restaurando quando for chamado um cc.restore()
    canvasCon.save()

    // Limpa o canvas. Remove tudo que foi desenhado antes
    canvasCon.clearRect(0, 0, canvasEl.width, canvasEl.height)

    // Desenha a imagem da câmera no canvas
    canvasCon.drawImage(results.image, 0, 0, canvasEl.width, canvasEl.height)
    if (showKeypoints) {
      const { poseLandmarks, rightHandLandmarks, leftHandLandmarks, faceLandmarks } = results

      /**
       * Linhas Azuis
       */
      // Para dar um efeito esfumaçado, adicionamos três linhas com larguras diferentes.
      const linesWidth = [1, 5, 10]

      linesWidth.forEach((lineWidth) => {
        /**
         * Desenhar linhas do corpo (sem mãos e rosto)
         */
        drawConnectors(canvasCon, poseLandmarks, POSE_CONNECTIONS_NO_FACE_NO_HANDS, {
          color: transparentOrange,
          lineWidth,
        })

        /**
         * Desenhar linha das mãos
         */
        ;[leftHandLandmarks, rightHandLandmarks].forEach((handLM) => {
          drawConnectors(canvasCon, handLM, HAND_CONNECTIONS, {
            color: transparentOrange,
            lineWidth: lineWidth / 2,
          })
        })

        /**
         * Desenhar linhas que conectam os cotovelos (pose) às mãos (hand).
         */
        // Relembre os índices de cada ponto aqui:
        // https://google.github.io/mediapipe/solutions/pose.html#pose-landmark-model-blazepose-ghum-3d
        // https://google.github.io/mediapipe/solutions/hands.html#hand-landmark-model
        // leftHandLandmarks
        drawConnectors(
          canvasCon,
          [
            // Usamos optional chaining para evitar "ifs".
            // Veja que se a inferência da mão falhar, conectamos ao pulso da pose mesmo.
            poseLandmarks?.[13], // Cotovelo Esquerdo
            leftHandLandmarks?.[0] || poseLandmarks?.[15], // Mão Esquerda
            poseLandmarks?.[14], // Cotovelo Direito
            rightHandLandmarks?.[0] || poseLandmarks?.[16], // Mão Direita
          ],
          [
            [0, 1],
            [2, 3],
          ],
          {
            color: transparentOrange,
            lineWidth: lineWidth / 1.5,
          },
        )

        /**
         * Desenhar linhas do contorno do rosto
         */
        if (faceLandmarks) {
          drawConnectors(canvasCon, faceLandmarks, FACEMESH_CONTOURS, {
            color: transparentOrange,
            lineWidth: lineWidth / 2,
          })
        }
      })

      if (faceLandmarks) {
        // Adicionar os pontinhos verdes do rosto.
        drawLandmarks(canvasCon, faceLandmarks, {
          // Precisa colocar a color: "transparent" para remover o contorno.
          color: 'transparent',
          fillColor: transparentPurple,
          radius: 2,
          lineWidth: 2,
        })
      }
    }

    canvasCon.restore()
  })

  return holistic
}

export default useHollistic
