import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useLocation } from 'wouter'
import useIntl from '../../../hooks/useIntl'

interface ICongrulationProps {
  open: boolean
  toggleOpenCloseDialog: () => void
}

const CongrulationsDialog: React.FC<ICongrulationProps> = ({ open, toggleOpenCloseDialog }) => {
  const [, setLocation] = useLocation()
  const intl = useIntl()

  return (
    <Dialog
      open={open}
      onClose={() => {
        setLocation('/')
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{intl.get('pages.abTestNmt.congratulations')}</DialogTitle>
      <DialogContent>
        <Typography>{intl.get('pages.abTestNmt.testCompleted')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            toggleOpenCloseDialog()
            setLocation('/')
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CongrulationsDialog
