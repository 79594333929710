import React, { createContext, useState, useContext, useCallback, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { useLocation } from 'wouter'
import deepOrange from '@material-ui/core/colors/deepOrange'
import { DefaultTheme } from '@material-ui/styles'
import red from '@material-ui/core/colors/red'
import blue from '@material-ui/core/colors/blue'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

interface IThemeProps {
  type: 'dark' | 'light'
  theme: DefaultTheme
}

type ThemeContext = [IThemeProps, (type?: 'dark' | 'light') => void]

const initialState: IThemeProps = {
  type: 'dark',
  theme: {
    palette: {
      type: 'dark',
      error: red,
      primary: blue,
      secondary: deepOrange,
    },
  },
}

// Precisamos de um estado inicial para o Context.
const Context = createContext<ThemeContext>([initialState, () => ({})])

export const ProviderTheme: React.FC = ({ children }) => {
  const defaultTheme = 'dark'
  const location = useLocation()[0]

  const [theme, setTheme] = useState<DefaultTheme>(initialState)
  const [type, setType] = useState<'dark' | 'light'>(
    //@ts-ignore
    location.includes('motion') ? 'light' : localStorage.getItem('themeColor') || defaultTheme,
  )

  const updateTheme = useCallback((type?: 'dark' | 'light') => {
    if (type) setType(type)
  }, [])

  useEffect(() => {
    setTheme({
      palette: {
        type: type,
        error: red,
        primary: blue,
        secondary: deepOrange,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 400,
          md: 960,
          lg: 1280,
          xl: 1820,
        },
      },
    })
    localStorage.setItem('themeColor', type)
  }, [type])

  const muiTheme = createTheme(theme)

  return (
    <Context.Provider value={[{ type, theme }, updateTheme]}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </Context.Provider>
  )
}

export default (): ThemeContext => useContext(Context)
