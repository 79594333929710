import React, { useState, useCallback, useContext, createContext, useEffect } from 'react'

import logger from '../logger'
import {
  UserId,
  IUserInfo,
  IVideoManagerContributionVideo,
  VideoId,
  IVideo,
  CommunityModule,
  getPathVideoFile,
  DocumentReference,
  DocumentReferenceGeneric,
  generateSearchTermsVideo,
} from 'collections'
import { getUsersByWorkspaceFunction } from '../firebase'
import { useFirestoreCollection, useStorage } from 'reactfire'
import { useFsUserDocData } from '../../hooks/useFsUser'
import {
  DocumentData,
  limit,
  orderBy,
  query,
  Query,
  query as queryFirestore,
  QueryDocumentSnapshot,
  startAfter,
  endBefore,
  limitToLast,
  where,
} from '@firebase/firestore'
import { getDownloadURL, ref } from '@firebase/storage'
import { getCollectionReference } from 'collections'

const { log, error } = logger('useContributionsPagination')

interface IQueryMatchFilters {
  /** Texto da sentença do vídeo */
  searchText?: string
  /** Id do sinal vinculado ao vídeo */
  signId?: string
  /** Origem da sentença do vídeo */
  sentenceOrigin?: string
  /** Categoria da sentença do vídeo */
  sentenceCategory?: string
  /** Estado do vídeo */
  videoState?: string
  /** Id do usuário no seletor */
  userReference?: DocumentReference
  /** Data inicial da consulta - Vídeos apartir desta data */
  startDate: Date
  /** Data final da consulta - Vídeos até a data especificada */
  endDate: Date
  /** Pagina atual da lista */
  page?: number
}

interface IContributionsPaginationState {
  /** Dicionario com o timestamp (do dia) e quantidade de vídeos */
  videos: IVideoManagerContributionVideo[]
  /** Dicionario com informações sobre os usuários deste workspace */
  usersInfo: Record<UserId, IUserInfo>
  /** Determina se algo está sendo carregado */
  isLoading: boolean
  /** Ultimo elemento visivel na tela (a partir dele iremos carregar os demais)  */
  lastVisibleVideoDoc?: QueryDocumentSnapshot
  /** Primeiro elemento visivel na tela (a partir dele iremos carregar os demais)  */
  firstVisibleVideoDoc?: QueryDocumentSnapshot
  /** Quantos vídeos serão carregados em cada query */
  videosQueryAmount: number
  /** Filtros que iremos aplicar na busca dos videos */
  filters: IQueryMatchFilters
  /** Indica que não há mais resultados depois do documento atual */
  hasMoreResults: boolean
  /** Indica que os filtros estão sendo atualizados */
  isApplyingFilters: boolean
  /** Indica o módulo do community */
  communityModule: CommunityModule
}

interface IUpdateStateFunctions {
  loadMore: (overwrite?: boolean, communityModule?: CommunityModule) => void
  applyFilters: (
    startDate: Date,
    endDate: Date,
    userReference?: DocumentReference,
    searchText?: string,
    signId?: string,
    sentenceOrigin?: string,
    sentenceCategory?: string,
    videoState?: string,
    page?: number,
  ) => void
  setCommunityModule: (communityModule: CommunityModule, applyFilter?: boolean) => void
}

const initialState: IContributionsPaginationState = {
  videos: [],
  usersInfo: {},
  isLoading: false,
  videosQueryAmount: 10,
  filters: {
    searchText: '',
    signId: '',
    sentenceOrigin: 'all',
    sentenceCategory: 'all',
    videoState: 'NONE',
    startDate: new Date('Jan 01 2018'),
    endDate: new Date(),
    page: 0,
  },
  hasMoreResults: true,
  isApplyingFilters: false,
  communityModule: 'segmentVideo',
}

const initialFunctions: IUpdateStateFunctions = {
  loadMore: () => null,
  applyFilters: () => null,
  setCommunityModule: () => null,
}

type Context = [IContributionsPaginationState, IUpdateStateFunctions]
const Context = createContext<Context>([initialState, initialFunctions])

export const ProviderModuleSignSentenceContributions: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState)
  const [currentPage, setCurrentPage] = useState(0)

  const storage = useStorage()
  const fsUser = useFsUserDocData()

  const initialVideoQuery = query(
    getCollectionReference(fsUser.workspace, 'videos') as Query<DocumentData>,
    where('createdAt', '>=', state.filters.startDate),
    where('createdAt', '<=', state.filters.endDate),
    orderBy('createdAt', 'desc'),
    limit(10),
  )
  const [videoQueryState, setVideoQueryState] = useState<Query<DocumentData>>(initialVideoQuery)

  const videosCollection = useFirestoreCollection(videoQueryState)

  /**
   * Obtém a lista de usuários deste workspace
   */
  const getUsersInfo = useCallback(async () => {
    log('Obtendo lista de users do workspace')
    const { data } = await getUsersByWorkspaceFunction({})

    log(`Usuários do workspace obtidos: ${Object.keys(data.users).length}`)

    return data.users
  }, [])

  /**
   * Pega novos vídeos continuando de um index ou iniciando do zero
   */
  const getPaginatedContributionsVideos = useCallback(() => {
    let query = queryFirestore(getCollectionReference(fsUser?.workspace, 'videos') as Query<DocumentData>)
    if (state.communityModule === 'segmentVideo') {
      query = queryFirestore(query, where('numberOfSegments', '>', 0))
      // Filtra segmentos de um usuário especifico
      if (state.filters.userReference) {
        query = queryFirestore(query, where('segmentedBy', 'array-contains', state.filters.userReference))
      }
    } else {
      // Filtra vídeos de um usuário especifico
      if (state.filters.userReference) {
        query = queryFirestore(query, where('createdBy', '==', state.filters.userReference))
      }
      if (state.filters.sentenceOrigin && state.filters.sentenceOrigin !== 'all') {
        query = queryFirestore(query, where('sentenceOrigin', '==', state.filters.sentenceOrigin.toUpperCase()))
      }
      if (state.filters.sentenceCategory && state.filters.sentenceCategory !== 'all') {
        query = queryFirestore(query, where('sentenceCategory', 'array-contains', state.filters.sentenceCategory))
      }
      if (state.filters.videoState && state.filters.videoState !== 'NONE') {
        query = queryFirestore(
          query,
          where('_state', '==', state.filters.videoState === 'MISSING_SIGN' ? 'SEGMENTED' : state.filters.videoState),
        )
        if (state.filters.videoState === 'MISSING_SIGN') {
          query = queryFirestore(query, where('needCreateSign', '==', true))
        }
      }
      if (state.filters.signId !== undefined) {
        const signId = state.filters.signId.trim()
        if (signId) {
          query = queryFirestore(query, where('signs', 'array-contains', signId))
        }
      }
      if (state.filters.searchText !== undefined && state.filters.searchText.trim()) {
        const searchTerms = generateSearchTermsVideo(state.filters.searchText, fsUser.workspace.id)
        query = queryFirestore(
          query,
          where('searchTerms', '>=', searchTerms.trim()),
          where('searchTerms', '<=', searchTerms.trim() + '\uf8ff'),
          orderBy('searchTerms'),
          orderBy('segmentsToProcess'),
        )
      } else {
        query = queryFirestore(
          query,
          where('createdAt', '>=', state.filters.startDate),
          where('createdAt', '<=', state.filters.endDate),
          orderBy('createdAt', 'desc'),
        )
      }
    }
    // Incia a consuta a partir deste documento
    if (state.filters.page! > currentPage) {
      if (state.lastVisibleVideoDoc) {
        query = queryFirestore(query, startAfter(state.lastVisibleVideoDoc))
      }
    } else if (state.filters.page! < currentPage) {
      if (state.firstVisibleVideoDoc) {
        query = queryFirestore(query, endBefore(state.firstVisibleVideoDoc))
        query = queryFirestore(query, limitToLast(state.videosQueryAmount))
      }
    }

    if (state.filters.page! == 0 || state.filters.page! > currentPage) {
      query = queryFirestore(query, limit(state.videosQueryAmount))
    }

    setVideoQueryState(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.workspace, state])

  /**
   * Define que a pagina chegou no final do scroll, sempre que isso acontecer, iremos carregar mais videos
   */
  const loadMore = useCallback(() => {
    // Evita chamadas desnecessarias
    if (state.isLoading) return

    const call = async () => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }))

      getPaginatedContributionsVideos()
    }
    call()
  }, [getPaginatedContributionsVideos, state.isLoading])

  /**
   * Aplica os filtros à query principal do firestore
   * @param startDate Data inicial da consulta, ou seja, pegará vídeos apartir desta data
   * @param endDate Data final da consulta, ou seja, pegará vídeos até esta data
   * @param page Pagina atual da lista
   * @param userReference Referencia do usuario selecionado
   * @param searchText Texto para pesquisa
   * @param signId Id do sinal vinculado ao vídeo
   * @param sentenceOrigin Origem da sentença
   * @param sentenceCategory Categoria da sentença
   * @param videoState Estado do vídeo
   */
  const applyFilters = (
    startDate: Date,
    endDate: Date,
    userReference?: DocumentReference,
    searchText?: string,
    signId?: string,
    sentenceOrigin?: string,
    sentenceCategory?: string,
    videoState?: string,
    page?: number,
  ) => {
    log(
      `Novos filtros aplicados: 
        startDate: ${startDate}
        endDate: ${endDate} 
        user: ${userReference?.path}`,
    )
    setState((prev) => ({
      ...prev,
      isApplyingFilters: true,
      firstVisibleVideoDoc: page == 0 ? undefined : state.firstVisibleVideoDoc,
      filters: {
        startDate,
        endDate,
        page,
        userReference,
        searchText,
        signId,
        sentenceOrigin,
        sentenceCategory,
        videoState,
      },
    }))
  }

  useEffect(() => {
    if (JSON.stringify(state.usersInfo) == JSON.stringify({})) {
      const call = async () => {
        let users = state.usersInfo

        // Ainda não temos a lista de usuários então iremos baixar
        if (Object.keys(users).length === 0) {
          users = await getUsersInfo()
        }

        setState((prev) => ({
          ...prev,
          usersInfo: users,
        }))
      }
      call()
    }
  }, [getUsersInfo, state.usersInfo])

  const setCommunityModule = (communityModule?: CommunityModule, applyFilter?: boolean) => {
    setState((prev) => ({
      ...prev,
      isApplyingFilters: applyFilter ? true : false,
      communityModule: communityModule || 'signSentence',
    }))
  }

  useEffect(() => {
    if (state.isApplyingFilters) {
      loadMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isApplyingFilters, state.communityModule])

  useEffect(() => {
    if (JSON.stringify(state.usersInfo) !== JSON.stringify({})) {
      const call = async () => {
        let segmentedBy: DocumentReferenceGeneric[] | null = null
        let videos: IVideoManagerContributionVideo[] = []
        if (state.filters.userReference && state.communityModule === 'segmentVideo') {
          segmentedBy = [state.filters.userReference]
        }
        const results: Record<VideoId, IVideo> = {}
        videosCollection.data.docs.forEach((doc) => {
          results[doc.id] = doc.data() as IVideo
        })
        // Pega a url publica de cada vídeo
        const videosData = await Promise.all(
          Object.entries(results).map(async ([videoId, video]) => {
            // Pega a midia principal
            video.midia.filter((midia) => midia.isMain)[0]

            const userId = video.createdBy.id

            let videoSrc: string | undefined

            try {
              const videoPath = getPathVideoFile(fsUser.workspace.id, videoId)
              // Pega a url publica de download do video
              videoSrc = await getDownloadURL(ref(storage, videoPath))
            } catch (err) {
              error(err)
            }

            const displayName = (state.usersInfo[userId] && state.usersInfo[userId].displayName) || 'Anonymous'

            const createdAt = video.createdAt.toDate()

            const videoResult: IVideoManagerContributionVideo = {
              _state: video._state,
              clientId: video.clientId,
              corpusGroup: video.corpusGroup,
              errors: video.errors,
              needCreateSign: video.needCreateSign,
              numberOfSegments: video.numberOfSegments,
              numberOfSegmentations: video.numberOfSegmentations,
              segmentsToProcess: video.segmentsToProcess,
              signs: video.signs,
              userId,
              sentence: video.sentence,
              sentenceOrigin: video.sentenceOrigin,
              sentenceCategory: video.sentenceCategory,
              createdBy: `${displayName}`,
              createdOnDemand: video.createdOnDemand,
              videoId,
              videoSrc,
              createdAt,
              displayName: `${displayName}`,
              segmentedBy: segmentedBy || video.segmentedBy || null,
              usedOnTrain: video.usedOnTrain,
            }

            return videoResult
          }),
        )
        videos = videosData
        let lastVisibleSnapshoot
        if (videos.length === state.videosQueryAmount) {
          lastVisibleSnapshoot = videosCollection.data.docs[videosCollection.data.docs.length - 1]
        }
        const firstVisibleSnapshoot = videosCollection.data.docs[0]

        return {
          results: videos,
          lastVisibleSnapshoot: lastVisibleSnapshoot && lastVisibleSnapshoot,
          firstVisibleSnapshoot: firstVisibleSnapshoot,
        }
      }
      call()
        .then((data) => {
          const hasMoreResults = data.results.length === state.videosQueryAmount

          // Sobrescreve a lista antiga de vídeos por esta nova
          setState((prev) => ({
            ...prev,
            videos: data.results,
            lastVisibleVideoDoc: data.lastVisibleSnapshoot,
            firstVisibleVideoDoc: data.firstVisibleSnapshoot,
            isLoading: false,
            hasMoreResults,
          }))
          setCurrentPage(state.filters.page!)
        })
        .then(() => {
          setTimeout(() => {
            setState((prev) => ({
              ...prev,
              isApplyingFilters: false,
            }))
          }, 500)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videosCollection.data, state.usersInfo])

  const updateStateFns: IUpdateStateFunctions = {
    loadMore,
    applyFilters,
    setCommunityModule,
  }

  return <Context.Provider value={[state, updateStateFns]}>{children}</Context.Provider>
}

export default (): Context => useContext(Context)
