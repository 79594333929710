import React, { FC } from 'react'

import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

interface IPreloaderProps {
  /** Texto opcional */
  text?: string
  /** Como bloco. Se for falso é tela cheia. */
  asBlock?: boolean
}

const FullScreenGrid = styled(Grid)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
`

export const PreloaderPure: FC<IPreloaderProps> = ({ text, asBlock }) => {
  const Component = asBlock ? Grid : FullScreenGrid

  return (
    <Component item xs container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
      {text && (
        <Typography variant="caption" style={{ marginTop: 10 }}>
          {text}
        </Typography>
      )}
    </Component>
  )
}

export default PreloaderPure
