import {
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TablePagination,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import React, { useCallback, useEffect, useState } from 'react'
import { useGlobal } from 'reactn'
import Layout from '../../components/Layout'
import { useFsUserDocData } from '../../hooks/useFsUser'
import useIntl from '../../hooks/useIntl'
import {
  DocumentData,
  IGlobalAttr,
  ISignOnDemand,
  Query,
  StatusSignOnDemand,
  getCollectionReference,
  IPaginationConfig,
  WorkspaceId,
  preProcess,
} from 'collections'
import SignsOnDemandList from './SignsOnDemandList'
import ModalIncludeSignOnDemand from './ModalIncludeSignOnDemand'
import { orderBy, query, startAfter, where, endBefore, limitToLast, limit, getDocs } from '@firebase/firestore'

import ConfirmationDialog from '../../components/ConfirmationDialog'
import { deleteSignOnDemandFunction } from '../../services/firebase'
import useSnackbar from '../../services/hooks/useSnackbar'
import CardMessage from '../../components/CardMessage'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

interface ISignOnDemandProps {
  path: string
}

interface IPaginationConfigSignOnDemand extends IPaginationConfig {
  filterSignOnDemand: StatusSignOnDemand
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
    },
    content: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '80px',
      },
    },
    radioGroup: {
      marginLeft: '10px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: '8px',
      },
    },
  }),
)

const initialPaginationConfig: IPaginationConfigSignOnDemand = {
  currentPage: 0,
  firstOfList: null,
  lastOfList: null,
  load: true,
  rowsPerPage: 10,
  filterSignOnDemand: StatusSignOnDemand.pending,
}

const SignOnDemand: React.FC<ISignOnDemandProps> = () => {
  const fsUser = useFsUserDocData()
  const hasPermissionOnModule = useCheckHasPermission('signOnDemand')
  const [signsOnDemand, setSignsOnDemand] = useState<ISignOnDemand[]>([])
  const [selectedSignOnDemand, setSelectedSignOnDemand] = useState<ISignOnDemand | null>(null)
  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfigSignOnDemand>(initialPaginationConfig)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [open, setOpen] = useState(false)
  const [opennedConfirmDeleteDialog, setOpennedConfirmDeleteDialog] = useState(false)
  const showSnackbar = useSnackbar()

  const [filterSignOnDemand, setFilterSignOnDemand] = useGlobal<IGlobalAttr, 'filterSignOnDemand'>('filterSignOnDemand')
  const [loadingData, setLoadingData] = useState(true)
  const [searchText, setSearchText] = useState('')

  const intl = useIntl()

  const classes = useStyles()

  const fetchSignsOnDemand = useCallback(
    async (searchText?: string) => {
      let signsOnDemandQuery = query(
        getCollectionReference(fsUser.workspace, 'signsOnDemand') as Query<DocumentData>,
        where(
          'status',
          '==',
          (paginationConfig.filterSignOnDemand! in StatusSignOnDemand && paginationConfig.filterSignOnDemand) ||
            'pending',
        ),
        orderBy('createdAt', 'desc'),
      )
      if (searchText) {
        const searchTerm = preProcess(searchText, fsUser.workspace.id, true, true, false)
        signsOnDemandQuery = query(signsOnDemandQuery, where('searchTerms', 'array-contains', searchTerm))
      } else {
        if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
          if (paginationConfig.lastOfList) {
            signsOnDemandQuery = query(
              signsOnDemandQuery,
              startAfter(paginationConfig.lastOfList),
              limit(paginationConfig.rowsPerPage),
            )
          } else {
            signsOnDemandQuery = query(
              signsOnDemandQuery,
              endBefore(paginationConfig.firstOfList),
              limitToLast(paginationConfig.rowsPerPage),
            )
          }
        }
        if (!paginationConfig.firstOfList) {
          signsOnDemandQuery = query(signsOnDemandQuery, limit(paginationConfig.rowsPerPage))
        }
      }

      const signsOnDemandSnapshot = await getDocs(signsOnDemandQuery)
      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.lastOfList = signsOnDemandSnapshot.docs[signsOnDemandSnapshot.docs.length - 1]
      _paginationConfig.firstOfList = signsOnDemandSnapshot.docs[0]
      _paginationConfig.load = false
      setPaginationConfig(_paginationConfig)
      const _signsOnDemand: ISignOnDemand[] = []

      for (const signOnDemandSnapshot of signsOnDemandSnapshot.docs) {
        const signOnDemandData = signOnDemandSnapshot.data() as ISignOnDemand
        signOnDemandData.id = signOnDemandSnapshot.id
        _signsOnDemand.push(signOnDemandData)
      }
      setSignsOnDemand(_signsOnDemand)
      setLoadingData(false)
      setHasMoreResults(paginationConfig.rowsPerPage != _signsOnDemand.length)
      setSearchText('')
    },
    [paginationConfig, fsUser.workspace],
  )

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.rowsPerPage = Number(event.target.value)
      _paginationConfig.currentPage = 0
      _paginationConfig.lastOfList = null
      _paginationConfig.firstOfList = null
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
      setLoadingData(true)
    },
    [paginationConfig],
  )

  const refreshPage = () => {
    setPaginationConfig({
      ...initialPaginationConfig,
      filterSignOnDemand: filterSignOnDemand || StatusSignOnDemand.pending,
    })
    setLoadingData(true)
  }

  const toggleConfirmDeleteDialog = () => {
    setOpennedConfirmDeleteDialog(!opennedConfirmDeleteDialog)
  }

  const handleDeleteSignOnDemand = async (item: ISignOnDemand | null) => {
    if (!item || !item.id) return
    try {
      await deleteSignOnDemandFunction({
        signOnDemandId: item.id,
        workspaceId: fsUser.workspace.id as WorkspaceId,
      })
      refreshPage()
      showSnackbar(intl.get('messages.signOnDemandSuccessfullyDeleted'), {
        variant: 'success',
      })
    } catch (err) {
      showSnackbar(intl.get('messages.failedToDeleteSignOnDemand'), {
        variant: 'error',
      })
    } finally {
      setSelectedSignOnDemand(null)
    }
  }

  useEffect(() => {
    refreshPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.workspace.id])

  useEffect(() => {
    if (paginationConfig.load) fetchSignsOnDemand()
  }, [paginationConfig, fetchSignsOnDemand])

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
      setLoadingData(true)
    },
    [paginationConfig],
  )

  const handleChangeFilter = (value: StatusSignOnDemand) => {
    setFilterSignOnDemand(value)
  }

  useEffect(() => {
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.filterSignOnDemand = filterSignOnDemand || StatusSignOnDemand.pending
    _paginationConfig.firstOfList = null
    _paginationConfig.lastOfList = null
    _paginationConfig.currentPage = 0
    _paginationConfig.load = true
    setPaginationConfig(_paginationConfig)
    setLoadingData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSignOnDemand])

  const handleClickEditRow = (data: ISignOnDemand) => {
    setSelectedSignOnDemand(data)
    setOpen(true)
  }

  const handleClickDeleteRow = (data: ISignOnDemand) => {
    setSelectedSignOnDemand(data)
    setOpennedConfirmDeleteDialog(true)
  }

  const handleAddSignOnDemand = () => {
    setSelectedSignOnDemand(null)
    setOpen(true)
  }

  const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleSearchSign = () => {
    fetchSignsOnDemand(searchText)
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.signOnDemand')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.signOnDemand')} requiredModule={'signOnDemand'}>
      <Grid item justifyContent="center" alignItems="center" container className={classes.content}>
        <Grid item md={10} style={{ margin: '10px 0', alignItems: 'center', display: 'flex' }}>
          <RadioGroup
            row
            className={classes.radioGroup}
            value={(filterSignOnDemand! in StatusSignOnDemand && filterSignOnDemand) || 'pending'}
            onChange={(ev) => handleChangeFilter((ev.target.value as StatusSignOnDemand) || StatusSignOnDemand.pending)}
          >
            <FormControlLabel
              value={StatusSignOnDemand.request}
              control={<Radio color="primary" />}
              label={intl.get('pages.signOnDemand.request')}
              labelPlacement="end"
            />
            <FormControlLabel
              value={StatusSignOnDemand.pending}
              control={<Radio color="primary" />}
              label={intl.get('pages.signOnDemand.pending')}
              labelPlacement="end"
            />
            <FormControlLabel
              value={StatusSignOnDemand.recordedVideo}
              control={<Radio color="primary" />}
              label={intl.get('pages.signOnDemand.recordedVideo')}
              labelPlacement="end"
            />
            <FormControlLabel
              value={StatusSignOnDemand.segmentedVideo}
              control={<Radio color="primary" />}
              label={intl.get('pages.signOnDemand.segmentedVideo')}
              labelPlacement="end"
            />
          </RadioGroup>
          <TextField
            style={{ marginLeft: 'auto' }}
            label={intl.get('messages.search')}
            variant="outlined"
            value={searchText}
            onChange={handleChangeSearchText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchSign} edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={10}>
          <SignsOnDemandList
            data={signsOnDemand || []}
            handleClickEditRow={handleClickEditRow}
            handleClickDeleteRow={handleClickDeleteRow}
            classes={classes}
          />
          <TablePagination
            style={{ pointerEvents: loadingData ? 'none' : 'auto' }}
            component="div"
            count={-1}
            rowsPerPage={paginationConfig.rowsPerPage}
            page={paginationConfig.currentPage}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30]}
            labelRowsPerPage={intl.get('pages.animation.labelRowsPerPage')}
            labelDisplayedRows={({ from, to }) => intl.get('pages.animation.labelDisplayedRows', { from, to })}
            nextIconButtonProps={{ disabled: hasMoreResults }}
          />
        </Grid>
      </Grid>
      <Fab
        aria-label="Add"
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '30px',
        }}
        color="primary"
        onClick={handleAddSignOnDemand}
      >
        <AddIcon />
      </Fab>
      <ModalIncludeSignOnDemand
        open={open}
        setOpen={setOpen}
        data={selectedSignOnDemand}
        handleRefreshTable={refreshPage}
      />
      <ConfirmationDialog
        open={opennedConfirmDeleteDialog}
        toggleOpen={toggleConfirmDeleteDialog}
        handleYes={() => {
          toggleConfirmDeleteDialog()
          handleDeleteSignOnDemand(selectedSignOnDemand)
        }}
        title={intl.get('messages.delete')}
        subtitle={intl.get('messages.confirmDeleteSignOnDemand')}
      />
    </Layout>
  )
}

export default SignOnDemand
