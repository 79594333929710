interface IStopWords {
  [workspace: string]: string[]
}

export const stopWords: IStopWords = {
  'HT-ASL': ['am', 'is', 'are', 'as', 'at', 'an', 'of', 'be'],
  'HT-BSL': ['am', 'is', 'are', 'as', 'at', 'an', 'of', 'be'],
  'HT-BZS': [
    'em',
    'do',
    'dos',
    'da',
    'das',
    'de',
    'pro',
    'pra',
    'para',
    'no',
    'nos',
    'na',
    'nas',
    'pelo',
    'pelos',
    'pela',
    'pelas',
    'o',
    'os',
    'a',
    'as',
    'à',
    'às',
    'num',
    'numa',
    'numas',
    'uns',
    'umas',
    'lhe',
  ],
  'HT-Annoy': [
    'em',
    'do',
    'dos',
    'da',
    'das',
    'de',
    'pro',
    'pra',
    'para',
    'no',
    'nos',
    'na',
    'nas',
    'pelo',
    'pelos',
    'pela',
    'pelas',
    'o',
    'os',
    'a',
    'as',
    'à',
    'às',
    'num',
    'numa',
    'numas',
    'uns',
    'umas',
    'lhe',
  ],
}
