import { Results } from '@mediapipe/holistic'
import React from 'react'
import useHollistic from './useHolistic'
import useCameraUtils from './useCameraUtils'
import { IKeypoints } from '../types/keypoints'

// import { Container } from './styles';

interface UseMediaPipeCameraProps {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
  onFrame?: (mpResultsList: IKeypoints) => void
  showKeypoints?: boolean
  cameraEnabled: boolean
}

const useMediaPipeCamera = ({
  videoRef,
  canvasRef,
  onFrame,
  showKeypoints,
  cameraEnabled,
}: UseMediaPipeCameraProps) => {
  const onResults = ({ poseLandmarks, leftHandLandmarks, rightHandLandmarks, faceLandmarks }: Results) => {
    // Função callback para ser executada a cada frame
    if (onFrame) {
      onFrame({
        pose: poseLandmarks,
        leftHand: leftHandLandmarks,
        rightHand: rightHandLandmarks,
        face: faceLandmarks,
      })
    }
  }
  // Inicia Holistic
  const holistic = useHollistic({ onResults, canvasRef, showKeypoints })
  // Inicia a câmera
  const { isLoading } = useCameraUtils({
    videoRef,
    holistic,
    cameraEnabled,
  })

  return {
    isLoading,
  }
}

export default useMediaPipeCamera
