import React, { useRef } from 'react'

import Typography from '@material-ui/core/Typography'
import CameraStream from '../../CameraStream/index'
import styled from 'styled-components'
import useIntl from '../../../../hooks/useIntl'

const Wrapper = styled.div`
  height: 300px;
  margin: auto;
`

const CameraPermissionStep: React.FC = () => {
  const intl = useIntl()
  const videoRef = useRef<HTMLVideoElement>(null)
  return (
    <>
      <Typography>{intl && intl.get('pages.signSentence.lobby.cameraPermissionStep.description')}</Typography>
      <Wrapper>
        <CameraStream
          textUnableAccessCamera={intl.get('pages.signSentence.cameraStream.unableAccessCamera')}
          videoRef={videoRef}
        />
      </Wrapper>
    </>
  )
}

export default CameraPermissionStep
