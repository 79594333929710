import React, { createRef, useEffect, useState } from 'react'
import { IManuallyTranslation, IManuallyTranslationSentence } from 'collections'
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import TextField from '@material-ui/core/TextField'
import { Edit } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { green } from '@material-ui/core/colors'
import DeleteIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import TranslateIcon from '@material-ui/icons/Translate'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import useIntl from '../../../../hooks/useIntl'
import Tooltip from '@material-ui/core/Tooltip'

interface AccordionTestProps {
  onDeleteSentence: (index: number) => void
  onVerifyTranslation: (id: string) => void
  item: IManuallyTranslationSentence
  index: number
  children?: JSX.Element
  setData: React.Dispatch<React.SetStateAction<IManuallyTranslation | null>>
  data: IManuallyTranslation | null
  onGetTranslationFromNMT: (index: number) => void
  onPlayAnimation: () => void
  disabled: boolean
  isPlaying: string | null
  onFinalizeAnimation: () => void
  expandedAccordions: string[]
  setExpandedAccordions: React.Dispatch<React.SetStateAction<string[]>>
}

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
  },
}))

const DraggableAccordion = (props: AccordionTestProps) => {
  const {
    item,
    onDeleteSentence,
    onVerifyTranslation,
    index,
    children,
    setData,
    data,
    onGetTranslationFromNMT,
    onPlayAnimation,
    disabled,
    isPlaying,
    onFinalizeAnimation,
    expandedAccordions,
    setExpandedAccordions,
  } = props
  const [showEditButton, setShowEditButton] = useState(false)
  const [isEditingSentence, setIsEditingSentence] = useState(false)
  const textFieldRef = createRef<HTMLTextAreaElement>()
  const [textValue, setTextValue] = useState('')
  const intl = useIntl()

  const id = item.id || ''
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    height: isDragging ? '54px' : 'auto',
  }

  useEffect(() => {
    setTextValue(item.source)
    if (isEditingSentence && textFieldRef.current) {
      textFieldRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingSentence, textFieldRef.current])

  const saveText = () => {
    if (!data) return
    const _data = { ...data }
    const translationSentences = [..._data.translationSentences]
    const oldText = translationSentences[index].source

    if (!textValue.trim()) {
      alert('Texto não informado')
    } else {
      translationSentences[index].source = textValue
    }

    if (textValue.trim() && oldText !== textValue) {
      _data.translationSentences = translationSentences
      setData(_data)
    }
  }

  return (
    <Accordion
      key={item.id}
      disabled={disabled}
      style={style}
      expanded={expandedAccordions.includes(item.id || '')}
      {...attributes}
      ref={setNodeRef}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ paddingLeft: '0px' }}
        onClick={() => {
          const _expandedAccordions = [...expandedAccordions]
          if (expandedAccordions.includes(item.id || '')) {
            const index = _expandedAccordions.findIndex((el) => el === (item.id || ''))
            _expandedAccordions.splice(index, 1)
          } else {
            _expandedAccordions.push(item.id || '')
          }
          setExpandedAccordions(_expandedAccordions)
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            width: '100%',
          }}
          onMouseLeave={() => setShowEditButton(false)}
          onMouseEnter={() => setShowEditButton(true)}
        >
          <Box {...listeners}>
            <DragIndicatorIcon style={{ cursor: 'move' }} />
          </Box>
          {!isEditingSentence && <Typography>{item.source}</Typography>}
          {isEditingSentence && (
            <TextField
              style={{ marginRight: '20px' }}
              fullWidth
              inputRef={textFieldRef}
              value={textValue}
              onChange={(ev) => {
                setTextValue(ev.target.value)
              }}
              onClick={(ev) => {
                ev.stopPropagation()
              }}
              onBlur={() => {
                setIsEditingSentence(false)
                saveText()
              }}
              disabled={item.verified}
            />
          )}
          {showEditButton && !isEditingSentence && (
            <Tooltip title={intl.get('messages.edit')}>
              <IconButton
                disabled={isPlaying === item.id || isPlaying === 'GERAL'}
                style={{ padding: '5px' }}
                onClick={(ev) => {
                  ev.stopPropagation()
                  setIsEditingSentence(true)
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Tooltip title={intl.get(item.verified ? 'messages.invalidateTranslation' : 'messages.validateTranslation')}>
            <IconButton
              disabled={isPlaying === item.id || isPlaying === 'GERAL'}
              size="small"
              onClick={(ev) => {
                ev.stopPropagation()
                onVerifyTranslation(item.id || '')
              }}
            >
              {item.verified && <CheckCircleIcon style={{ color: green[700] }} />}
              {!item.verified && <CheckCircleIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.get(isPlaying === item.id ? 'messages.stopAnimation' : 'messages.playAnimation')}>
            <IconButton
              size="small"
              onClick={(ev) => {
                ev.stopPropagation()
                if (item.id === isPlaying) {
                  onFinalizeAnimation()
                } else {
                  onPlayAnimation()
                }
              }}
            >
              {isPlaying === item.id ? <StopIcon /> : <PlayArrowIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.get('messages.translateWithNMT')}>
            <IconButton
              disabled={isPlaying === item.id || isPlaying === 'GERAL'}
              size="small"
              onClick={(ev) => {
                ev.stopPropagation()
                onGetTranslationFromNMT(index)
              }}
            >
              <TranslateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.get('messages.delete')}>
            <IconButton
              disabled={isPlaying === item.id || isPlaying === 'GERAL'}
              size="small"
              onClick={(ev) => {
                ev.stopPropagation()
                onDeleteSentence(index)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </AccordionSummary>
      {children}
    </Accordion>
  )
}

export default DraggableAccordion
