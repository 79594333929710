import React, { useEffect } from 'react'
import AnimationViewPure from './Pure'
import { useFirestoreCollection, useFirestore } from 'reactfire'
import { useFsUserDocRef, useFsUserDocData } from '../../../../hooks/useFsUser'
import { IAnimation, AnimationProblems, AnimationProblemsCode, DocumentReference, Query } from 'collections'
import useIntl from '../../../../hooks/useIntl'
import { query, runTransaction, serverTimestamp } from '@firebase/firestore'
import { DocumentData, orderBy, where } from '@firebase/firestore'
import { getCollectionReference } from 'collections'

interface IAnimationData extends IAnimation {
  id: string
}

interface IAnimationViewProps {
  /** Refêrencia ao sinal atual */
  signDoc: DocumentReference
  /** Usuários do workspace */
  workspaceUsers: DocumentData
  /** Dados do sinal atual */
  signData: DocumentData
  /** Número da animação selecionada */
  animationSelected: number
  /** Callback para ativar a animação */
  setAnimationSelected: (index: number) => void
}

/**
 * Componente de exibição de animações
 */
const AnimationView: React.SFC<IAnimationViewProps> = ({
  signDoc,
  workspaceUsers,
  signData,
  animationSelected,
  setAnimationSelected,
}) => {
  const { currentAnimation, isAnimating, isFingerspell } = signData
  const firestore = useFirestore()
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const animationsCollection = useFirestoreCollection(
    query(
      getCollectionReference(fsUser.workspace, 'animations') as Query<DocumentData>,
      where('sign', '==', signDoc),
      orderBy('isDisabled.value', 'asc'),
    ),
  ).data
  const animationsCollectionData: IAnimationData[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animationsCollection.forEach((animation: any) =>
    animationsCollectionData.push({ ...animation.data(), id: animation.id }),
  )

  const currentAnimationIndex =
    currentAnimation &&
    animationsCollectionData.findIndex(({ id }: IAnimationData) => id === currentAnimation.animation.id)
  const intl = useIntl()
  const textEnableSign = intl.get('pages.animation.enableSign')
  const textSetAsFingerspell = intl.get('pages.animation.setAsFingerspell')
  const textReviewedBy = intl.get('pages.animation.reviewedBy')
  const textProblems = intl.get('pages.animation.problems')
  const textAnimator = intl.get('messages.animator')
  const textDate = intl.get('messages.date')

  useEffect(() => {
    setAnimationSelected(currentAnimationIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnimationIndex])

  /** Salva uma animação como animação principal */
  const setMainAnimation = async () => {
    if (animationsCollectionData[animationSelected].htaAnimation)
      await runTransaction(firestore, async (transaction) => {
        transaction.update(signDoc, {
          // @ts-ignore
          currentAnimation: {
            animation: animationsCollection.docs[animationSelected].ref,
            createdBy: animationsCollection.docs[animationSelected].data().createdBy,
            createdAt: animationsCollection.docs[animationSelected].data().createdAt,
            errors: animationsCollection.docs[animationSelected].data().errors,
          },
        })
      })
  }

  /** Ativa o status de erro de datilologia */
  const setSignAsFingerspelling = async () => {
    await runTransaction(firestore, async (transaction) => {
      transaction.update(signDoc, {
        isFingerspell: true,
        isAnimating: {
          value: false,
          user: null,
          lastUpdate: null,
        },
      })
    })
  }

  /** Desativa uma animação, por conter erros */
  const disableAnimation = async () => {
    // @ts-ignore
    const animationDoc = animationsCollection.docs[animationSelected].ref
    await runTransaction(firestore, async (transaction) => {
      transaction.update(animationDoc, {
        isDisabled: {
          user: fsUserRef,
          lastUpdate: serverTimestamp(),
          value: true,
        },
      })

      if (currentAnimation && currentAnimation.animation.id === animationDoc.id)
        transaction.update(signDoc, {
          currentAnimation: null,
          isAnimated: false,
          currentAnimationHasErrors: null,
          currentAnimationReviewedAt: null,
        })
    })
  }

  const enableSign = async () => {
    await runTransaction(firestore, async (transaction) => {
      transaction.update(signDoc, {
        isFingerspell: false,
        isAnimating: {
          value: false,
          user: null,
          lastUpdate: null,
        },
      })
    })
  }

  const getErrorDescription = (problemCode: string) => {
    if (AnimationProblems[problemCode as AnimationProblemsCode]) {
      return intl.get(`animationProblems.${AnimationProblems[problemCode as AnimationProblemsCode].id}`)
    } else {
      return problemCode
    }
  }

  return (
    <AnimationViewPure
      data={animationsCollectionData}
      getErrorDescription={getErrorDescription}
      currentAnimationIndex={currentAnimationIndex}
      animationSelected={animationSelected}
      setAnimationSelected={setAnimationSelected}
      workspaceUsers={workspaceUsers}
      setMainAnimation={setMainAnimation}
      actionsActive={!isFingerspell && (!isAnimating.value || isAnimating.user.id === fsUserRef.id)}
      setSignAsFingerspelling={setSignAsFingerspelling}
      disableAnimation={disableAnimation}
      enableSign={enableSign}
      textEnableSign={textEnableSign}
      textSetAsFingerspell={textSetAsFingerspell}
      textReviewedBy={textReviewedBy}
      textAnimator={textAnimator}
      textProblems={textProblems}
      textDate={textDate}
    />
  )
}

export default AnimationView
