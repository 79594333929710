import { CommunityModule, IWorkspacePermissions } from 'collections'
import { useEffect, useState } from 'react'
import { useUser } from 'reactfire'
import { useFsUserDocData } from '../../hooks/useFsUser'

/**
 * Verificar se usuário tem permissão para usar um modulo especifico no workspace
 */
const useCheckHasPermission = (module: CommunityModule): boolean => {
  const fbUser = useUser<firebase.default.User>()
  const fsUser = useFsUserDocData()
  const [hasPermission, setHasPermission] = useState(true)

  useEffect(() => {
    const call = async () => {
      try {
        const tokenResult = await fbUser.data?.getIdTokenResult(true)
        await fbUser.data?.getIdToken(true)
        const _permissions = tokenResult?.claims?.permissions as IWorkspacePermissions

        let _hasPermissions: boolean = false
        if (
          _permissions &&
          _permissions[module] &&
          _permissions[module][fsUser.workspace.id] &&
          _permissions[module][fsUser.workspace.id] == 1
        ) {
          _hasPermissions = true
        }
        setHasPermission(_hasPermissions)
      } catch (error) {
        setHasPermission(false)
      }
    }
    call()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return hasPermission
}

export default useCheckHasPermission
