import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import { MenuOpen } from '@material-ui/icons'
import Fab from '@material-ui/core/Fab'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  ListSubheader,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
  makeStyles,
} from '@material-ui/core'
import { PlayCircleFilled, ReportProblem, MoreVert } from '@material-ui/icons'
import { IAnimation } from 'collections'

interface IAnimationViewPureProps {
  /** Lista de animações para exibição */
  data?: Partial<IAnimation> | null
  /** Adiciona repouso a animação */
  rest?: boolean
  /** Repete animação */
  repeat?: boolean
  /** Animação está sendo executada */
  isPlaying: boolean
  /** Alteração de quando a animação está sendo executada */
  setIsPlaying: (value: boolean) => void
  /** Inicia automáticamente a reprodução da animação */
  autoPlay?: boolean
  /** Exibe controle de velocidade */
  speedControls?: boolean
  /** Texto Rápido (INTL) */
  textFast?: string
  /** Texto Médio (INTL) */
  textMedium?: string
  /** Texto Lento (INTL) */
  textLow?: string
  /** Texto abrir tabela */
  textOpenTable?: string
  /** Texto rotacionar avatar */
  textRotate?: string
  /** Texto velocidade */
  textSpeed?: string
  /** Texto outros */
  textOthers?: string
  /** Array de animações */
  htaAnimations?: string[]
  /** Altura do componente */
  height?: number
  /** Avatar escolhido */
  avatar?: string
  /** Oculta os controles */
  hiddenControls?: boolean
  /** Altera a velocidade da animação */
  setSpeed: (value: number) => void
  /** Velocidade da animação */
  speed: number
  /** Velocidade padrão */
  defaultSpeed?: number
  /** Id do elemento da animação  */
  idElement?: string
  /** callback ao finalizar animação */
  onFinalizeAnimation?: () => void
  handleClickOpenSigns?: () => void
  openTable?: boolean
  signs?: { id: string; glosa: string; relatedWords?: string[] }[]
  textNoSigns?: string
  textAnimation?: string
  textReport?: string
  textPlayAnimation?: string
  handleClickPlaySign?: (id: string) => Promise<void>
  handleClickReportProblem?: (id: string) => Promise<void>
  downTable?: boolean
  isLoading?: boolean
  workspaceId: string
}

const HTCoreElement = styled.div`
  position: relative;
  align-self: flex-end;
`

const ReplayButton = styled(Fab)`
  && {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    margin-left: 5px;
  }
`

const OpenTableButton = styled(Fab)`
  && {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    margin-left: 5px;
    right: 5px;
  }
`

const Row = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
`

const useStyles = makeStyles(() => ({
  popover: {
    '& button': {
      display: 'block',
      width: '100%',
      textAlign: 'left',
      textTransform: 'initial',
      fontWeight: 'normal',
      borderBottom: '1px solid #999',
    },
  },
}))

/**
 * Componente puro de exibição de animações
 */
const AnimationViewPure: React.SFC<IAnimationViewPureProps> = ({
  data,
  rest,
  repeat,
  isPlaying,
  setIsPlaying,
  autoPlay,
  speedControls,
  textLow,
  textMedium,
  textFast,
  htaAnimations,
  defaultSpeed,
  height,
  avatar,
  hiddenControls,
  speed,
  setSpeed,
  idElement,
  onFinalizeAnimation,
  handleClickOpenSigns,
  openTable,
  signs,
  textNoSigns,
  textAnimation,
  textReport,
  textPlayAnimation,
  handleClickPlaySign,
  handleClickReportProblem,
  downTable,
  isLoading,
  textRotate,
  textOpenTable,
  textSpeed,
  textOthers,
  workspaceId,
}) => {
  // Id do elemento do avatar
  const coreId = idElement || 'HTCoreElement'

  const [htaAnimation, setHtaAnimation] = useState('')
  const [rotation, setRotation] = useState(0)
  const [initializedAvatar, setInitializedAvatar] = useState(false)
  // Core do Avatar
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [animationCore, setAnimationCore] = useState<any>()

  const repeatedAnimationTimes = useRef<number>(0)

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const menuOptions = [
    {
      onClick: () => {
        handleChangeSpeed(1.5)
      },
      title: textFast,
      type: 'speed',
      value: 1.5,
    },
    {
      onClick: () => {
        handleChangeSpeed(1)
      },
      title: textMedium,
      type: 'speed',
      value: 1,
    },
    {
      onClick: () => {
        handleChangeSpeed(0.5)
      },
      title: textLow,
      type: 'speed',
      value: 0.5,
    },
    {
      onClick: () => {
        handleChangeRotation()
      },
      title: textRotate,

      type: 'others',
    },
  ]

  const handleCloseMore = () => {
    setAnchorEl(null)
  }

  const handleClickMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  let _style = {}
  if (downTable) {
    _style = {
      width: '100%',
      flexDirection: 'column',
      maxWidth: '250px',
      marginTop: '5px',
    }
  } else {
    _style = {
      height: '100%',
    }
  }
  const signsStyle = {
    display: 'flex',
    ..._style,
  }

  if (downTable) {
    _style = { marginTop: '5px', minWidth: '350px' }
  } else {
    _style = { marginLeft: '5px' }
  }
  const tableSignsStyle = {
    maxHeight: '442px',
    ..._style,
  }

  /**
   * Inicia o avatar para fazer as animações
   */
  const initAvatar = async () => {
    setTimeout(async () => {
      // @ts-ignore
      const _animationCore = new HTCore({
        token: process.env.HT_TOKEN || '',
        parentElement: document.getElementById(coreId),
        avatar: (avatar || 'hugo').toUpperCase(),
        language: workspaceId === 'HT-ASL' ? 'en-ase' : 'ptBR-bzs',
      })

      await _animationCore.load()
      _animationCore.setBackgroundColor('#eee')
      if (speedControls || defaultSpeed) _animationCore.setSpeed(speedControls ? speed : defaultSpeed)
      setInitializedAvatar(true)
      setAnimationCore(_animationCore)
    })
  }

  useEffect(() => {
    if (animationCore?.canvas) {
      animationCore.canvas.style.borderRadius = '10px'
    }
  }, [animationCore?.canvas])

  const handleChangeRotation = () => {
    // 0 -> Frente
    // 27 -> Direita
    // 41 -> Costas
    // 68 -> Esquerda
    setRotation((prev: number) => {
      switch (prev) {
        case 0:
          return 27
        case 27:
          return 41
        case 41:
          return 68
        default:
          return 0
      }
    })
  }

  useEffect(() => {
    initAvatar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initializedAvatar) animationCore.setRotation(rotation || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation])

  useEffect(() => {
    const call = async () => {
      setIsPlaying(false)

      if (data || (htaAnimations && htaAnimations.length)) {
        const _htaAnimation = data?.htaAnimation || ''
        setHtaAnimation(_htaAnimation)
        if (autoPlay && initializedAvatar && repeat) setIsPlaying(true)
      }
    }
    if (initializedAvatar) call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, htaAnimations, initializedAvatar])

  /** Faz o avatar animar um hta */
  const playAnimation = () => {
    if (animationCore && initializedAvatar) {
      let animations: string[] = []
      if (htaAnimations && htaAnimations.length) animations = [...htaAnimations]
      else animations = [htaAnimation]
      /** Adiciona repouso */
      if (rest) animations.push('@repouso')

      animationCore.signAnimationCodes(animations, true, false, {
        onSignalized: () => {
          if (repeat && repeatedAnimationTimes.current < 1) {
            repeatedAnimationTimes.current++
            playAnimation()
          } else {
            setIsPlaying(false)
            repeatedAnimationTimes.current = 0
            if (onFinalizeAnimation) onFinalizeAnimation()
          }
        },
      })
    }
  }

  useEffect(() => {
    if (!repeat) {
      repeatedAnimationTimes.current = 0
    }
  }, [repeat])

  const handleClickPlay = () => {
    setIsPlaying(!isPlaying)
  }

  useEffect(() => {
    if (isPlaying) {
      playAnimation()
    } else {
      if (animationCore) {
        animationCore.stop()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying])

  const handleChangeSpeed = (value: number) => {
    setSpeed(value)
  }

  useEffect(() => {
    if (animationCore) {
      animationCore.setSpeed(speed)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speed])

  return (
    <Grid
      item
      style={{
        display: 'flex',
        flexDirection: !downTable ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid>
          <HTCoreElement id={coreId} style={{ height: height ? height : 'auto' }}>
            {(htaAnimation || htaAnimations) && !hiddenControls && (
              <>
                <ReplayButton size="small" color="primary" aria-label="Replay animation" onClick={handleClickPlay}>
                  {isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
                </ReplayButton>
              </>
            )}

            <Box style={{ position: 'absolute', top: '3%', right: '3%' }}>
              <IconButton size="small" color="primary" onClick={handleClickMore}>
                <MoreVert />
              </IconButton>
            </Box>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseMore}
              onClick={(e) => e.stopPropagation()}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              className={classes.popover}
            >
              <ListSubheader>{textSpeed}</ListSubheader>
              {menuOptions
                ?.filter((el) => {
                  return el.type == 'speed'
                })
                .map((el, index) => (
                  <Box
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={speed == el.value}
                      onClick={() => {
                        el.onClick()
                      }}
                      disabled={isLoading}
                    />
                    <ListItemText primary={el.title} />
                  </Box>
                ))}
              <ListSubheader>{textOthers}</ListSubheader>
              {menuOptions
                ?.filter((el) => {
                  return el.type == 'others'
                })
                .map((el, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      el.onClick()
                      if (el.title != textRotate) handleCloseMore()
                    }}
                    disabled={isLoading}
                  >
                    {el.title}
                  </Button>
                ))}
            </Popover>
            <Tooltip title={textOpenTable || ''}>
              <OpenTableButton
                color="primary"
                onClick={() => {
                  if (handleClickOpenSigns) handleClickOpenSigns()
                }}
                size="small"
                aria-label="Open Table"
              >
                <MenuOpen />
              </OpenTableButton>
            </Tooltip>
          </HTCoreElement>
        </Grid>
      </Box>
      <Box style={signsStyle}>
        {signs && openTable && openTable == true && (
          <Zoom
            in={openTable}
            style={{
              transitionDelay: openTable ? '10ms' : '0ms',
            }}
          >
            <>
              {!isLoading && signs.length == 0 && <Typography style={{ marginLeft: '5px' }}>{textNoSigns}</Typography>}
              {signs.length > 0 && (
                <TableContainer component={Paper} style={tableSignsStyle}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" variant="head" size="small">
                          Glosa
                        </TableCell>
                        <TableCell align="center" variant="head" size="small">
                          {textAnimation}
                        </TableCell>
                        <TableCell align="center" variant="head" size="small">
                          {textReport}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {signs.map((el) => (
                        <Row id={el.id} key={el.id}>
                          <TableCell>
                            <Tooltip title={el.relatedWords?.toString() || ''}>
                              <Typography>{el.glosa}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center" style={{ cursor: 'pointer' }}>
                            <Tooltip title={textPlayAnimation || 'Play'}>
                              <PlayCircleFilled
                                color="primary"
                                onClick={() => {
                                  if (handleClickPlaySign) handleClickPlaySign(el.id)
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center" style={{ cursor: 'pointer' }}>
                            <ReportProblem
                              color="secondary"
                              onClick={() => {
                                if (handleClickReportProblem) handleClickReportProblem(el.id)
                              }}
                            />
                          </TableCell>
                        </Row>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          </Zoom>
        )}
      </Box>
    </Grid>
  )
}

export default AnimationViewPure
