import React, { ChangeEvent, useState, useEffect } from 'react'
import useIntl from '../../../hooks/useIntl'
import CardAnimationPure from './Pure'
import { IUserInfo } from 'collections'
import { getUsersByWorkspaceFunction } from '../../../services/firebase'

interface ICardAnimationProps {
  idElement: string
  currentAnimations: string[]
  score: string
  setScore: React.Dispatch<React.SetStateAction<string | undefined>>
  addWordText: string
  tags: string[]
  options: string[]
  handleChangeOptions: (value: string[]) => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
  handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClickOpenSigns?: () => void
  openTable?: boolean
  signs?: { id: string; glosa: string; relatedWords: string[] }[]
  handleClickPlaySign?: (id: string) => Promise<void>
  isPlaying: boolean
  setIsPlaying: (value: boolean) => void
  autoPlay: boolean
  signId: string
  setSignId: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
}

export type TTexts = {
  textFast: string
  textMedium: string
  textLow: string
  textQualityConst0: string
  textQualityConst1: string
  textQualityConst2: string
  textQualityConst3: string
  textDefinition0Message: string
  textDefinition1Message: string
  textDefinition2Message: string
  textDefinition3Message: string
  textDefinition0Reason: string
  textDefinition1Reason: string
  textDefinition2Reason: string
  textDefinition3Reason: string
  textNoSigns: string
  textAnimation: string
  textReport: string
  textPlayAnimation: string
  textRotateAvatar: string
  textOpenTable: string
  textSpeed: string
  textOthers: string
}

const CardAnimation: React.FC<ICardAnimationProps> = ({
  idElement,
  currentAnimations,
  score,
  setScore,
  addWordText,
  tags,
  options,
  handleChangeOptions,
  handleKeyPress,
  handleChangeTextField,
  handleClickOpenSigns,
  openTable,
  signs,
  handleClickPlaySign,
  isPlaying,
  setIsPlaying,
  autoPlay,
  signId,
  setSignId,
  isLoading,
}) => {
  const intl = useIntl()
  const defaultSpeed = 1.0
  const [speed, setSpeed] = useState(defaultSpeed)
  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})
  const [otherProblem, setOtherProblem] = useState('')
  const [reportProblem, setReportProblem] = useState(false)

  const texts: TTexts = {
    textFast: intl.get('messages.fast'),
    textMedium: intl.get('messages.medium'),
    textLow: intl.get('messages.low'),
    textQualityConst0: intl.get('pages.abTestNmtResults.qualityConst.0'),
    textQualityConst1: intl.get('pages.abTestNmtResults.qualityConst.1'),
    textQualityConst2: intl.get('pages.abTestNmtResults.qualityConst.2'),
    textQualityConst3: intl.get('pages.abTestNmtResults.qualityConst.3'),
    textDefinition0Message: intl.get('pages.abTestNmtResults.definition.0.message'),
    textDefinition1Message: intl.get('pages.abTestNmtResults.definition.1.message'),
    textDefinition2Message: intl.get('pages.abTestNmtResults.definition.2.message'),
    textDefinition3Message: intl.get('pages.abTestNmtResults.definition.3.message'),
    textDefinition0Reason: intl.get('pages.abTestNmtResults.definition.0.reason'),
    textDefinition1Reason: intl.get('pages.abTestNmtResults.definition.1.reason'),
    textDefinition2Reason: intl.get('pages.abTestNmtResults.definition.2.reason'),
    textDefinition3Reason: intl.get('pages.abTestNmtResults.definition.3.reason'),
    textNoSigns: intl.get('pages.validateTranslation.noSigns'),
    textAnimation: intl.get('pages.validateTranslation.animation'),
    textReport: intl.get('pages.validateTranslation.report'),
    textPlayAnimation: intl.get('messages.playAnimation'),
    textRotateAvatar: intl.get('messages.rotateAvatar'),
    textOpenTable: intl.get('messages.openTable'),
    textSpeed: intl.get('messages.speed'),
    textOthers: intl.get('messages.others'),
  }

  const handleClickReportProblem = async (id: string) => {
    setSignId(id)
    setReportProblem(!reportProblem)
  }

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <CardAnimationPure
      idElement={idElement}
      currentAnimations={currentAnimations}
      score={score}
      setScore={setScore}
      addWordText={addWordText}
      tags={tags}
      options={options}
      handleChangeOptions={handleChangeOptions}
      handleKeyPress={handleKeyPress}
      handleChangeTextField={handleChangeTextField}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      speed={speed}
      setSpeed={setSpeed}
      texts={texts}
      handleClickOpenSigns={handleClickOpenSigns}
      openTable={openTable}
      signs={signs}
      handleClickPlaySign={handleClickPlaySign}
      handleClickReportProblem={handleClickReportProblem}
      autoPlay={autoPlay}
      reportProblem={reportProblem}
      setReportProblem={setReportProblem}
      workspaceUsers={workspaceUsers}
      signId={signId}
      otherProblem={otherProblem}
      setOtherProblem={setOtherProblem}
      isLoading={isLoading}
    />
  )
}

export default CardAnimation
