import {
  updateDoc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  DocumentSnapshot,
  collection,
  doc,
  writeBatch,
  where,
  documentId,
  query as firestoreQuery,
  getDocs,
} from '@firebase/firestore'
import { ref, deleteObject } from '@firebase/storage'
import {
  CollectionReference,
  CollectionReferenceAdmin,
  CollectionReferenceClient,
  CollectionReferenceGeneric,
  DocumentReference,
  DocumentReferenceAdmin,
  DocumentReferenceGeneric,
  FieldPathAdmin,
  FirestoreAdmin,
  FirestoreGeneric,
  IBatchOperation,
  StorageAdmin,
  StorageGeneric,
} from '.'

interface IDatilologyReplace {
  [workspace: string]: {
    [letter: string]: string[]
  }
}

export const datilology_to_replace: IDatilologyReplace = {
  'HT-ASL': {
    ' ': ['@espaco'],
  },
  'HT-BSL': {
    ' ': ['@espaco'],
  },
  'HT-BZS': {
    ' ': ['@espaco'],

    '@': ['@arroba'],

    ç: ['@ced'],

    '!': ['@exclamacao'],

    '?': ['@interrogacao'],

    '.': ['@ponto'],

    '´': ['@agu'],

    á: ['@a', '@agu'],

    é: ['@e', '@agu'],

    í: ['@i', '@agu'],

    ó: ['@o', '@agu'],

    ú: ['@u', '@agu'],

    '~': ['@til'],

    ã: ['@a', '@til'],

    õ: ['@o', '@til'],

    '^': ['@cir'],

    â: ['@a', '@cir'],

    ê: ['@e', '@cir'],

    î: ['@i', '@cir'],

    ô: ['@o', '@cir'],

    û: ['@u', '@cir'],
  },
}

export const isDocumentReferenceAdmin = (ref: DocumentReferenceGeneric): ref is DocumentReferenceAdmin => {
  if ((ref as DocumentReferenceAdmin).collection !== undefined) {
    return true
  } else {
    return false
  }
}

export const isFirestoreAdmin = (db: FirestoreGeneric): db is FirestoreAdmin => {
  if ((db as FirestoreAdmin).collection !== undefined) {
    return true
  } else {
    return false
  }
}

export const isStorageAdmin = (storage: StorageGeneric): storage is StorageAdmin => {
  if ((storage as StorageAdmin).file !== undefined) {
    return true
  } else {
    return false
  }
}

export const isCollectionReferenceAdmin = (
  ref: CollectionReference | CollectionReferenceAdmin,
): ref is CollectionReferenceAdmin => {
  if ((ref as CollectionReferenceAdmin).doc !== undefined) {
    return true
  } else {
    return false
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateFirestoreDocument = (ref: DocumentReferenceGeneric, data: any) => {
  if (isDocumentReferenceAdmin(ref)) {
    return ref.update(data)
  } else {
    return updateDoc(ref as DocumentReference, data)
  }
}

export const getFirestoreDocument = (ref: DocumentReferenceGeneric) => {
  if (isDocumentReferenceAdmin(ref)) {
    return ref.get()
  } else {
    return getDoc(ref as DocumentReference)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setFirestoreDocument = (ref: DocumentReferenceGeneric, data: any) => {
  if (isDocumentReferenceAdmin(ref)) {
    return ref.set(data)
  } else {
    return setDoc(ref as DocumentReference, data)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addFirestoreDocument = (ref: CollectionReferenceGeneric, data: any): Promise<DocumentReference> => {
  if (isCollectionReferenceAdmin(ref)) {
    return new Promise(() => ref.add(data))
  } else {
    return addDoc(ref, data)
  }
}

export const deleteFirestoreDocument = (ref: DocumentReferenceGeneric) => {
  if (isDocumentReferenceAdmin(ref)) {
    return ref.delete()
  } else {
    return deleteDoc(ref as DocumentReference)
  }
}

export const getFirestoreDocumentById = (
  ref: DocumentReferenceGeneric,
  collectionPath: string,
  documentId: string,
): Promise<DocumentSnapshot> => {
  if (isDocumentReferenceAdmin(ref)) {
    return new Promise(() => ref.collection(collectionPath).doc(documentId).get())
  } else {
    return getDoc(doc(ref as DocumentReference, `${collectionPath}/${documentId}`))
  }
}

export const getCollectionReference = (
  workspaceRef: DocumentReferenceGeneric,
  collectionName: string,
): CollectionReferenceGeneric => {
  if (isDocumentReferenceAdmin(workspaceRef)) {
    return workspaceRef.collection(collectionName)
  } else {
    return collection(workspaceRef as DocumentReference, collectionName)
  }
}

export const getDocumentReference = (
  workspaceRef: DocumentReferenceGeneric,
  collectionName: string,
  id: string,
): DocumentReferenceGeneric => {
  if (isDocumentReferenceAdmin(workspaceRef)) {
    return workspaceRef.collection(collectionName).doc(id)
  } else {
    return doc(collection(workspaceRef as DocumentReference, collectionName), id)
  }
}

export const generateReference = (workspaceRef: DocumentReferenceGeneric, collectionName: string) => {
  if (isDocumentReferenceAdmin(workspaceRef)) {
    return workspaceRef.collection(collectionName).doc()
  } else {
    const animationsCollection = getCollectionReference(workspaceRef, collectionName)

    return doc(animationsCollection as CollectionReferenceClient)
  }
}

export const runBatchOperationsFirestore = async (db: FirestoreGeneric, operations: IBatchOperation[]) => {
  if (isFirestoreAdmin(db)) {
    let batch = db.batch()
    try {
      let index = 0
      for (const el of operations) {
        // A cada 500 registros commita e inicia um novo batch, pois o limite é 500 registros
        if (index % 499 == 0) {
          await batch.commit()
          batch = db.batch()
        }

        if (el.op === 'update') {
          batch.update(el.ref as DocumentReferenceAdmin, el.data)
        } else if (el.op === 'set') {
          batch.set(el.ref as DocumentReferenceAdmin, el.data)
        } else if (el.op === 'delete') {
          batch.delete(el.ref as DocumentReferenceAdmin)
        } else {
          throw 'invalid operation'
        }

        index++
      }
      await batch.commit()
    } catch (e) {
      throw (e as TypeError | RangeError | EvalError).message
    }
  } else {
    let batch = writeBatch(db)
    try {
      let index = 0
      for (const el of operations) {
        // A cada 500 registros commita e inicia um novo batch, pois o limite é 500 registros
        if (index % 499 == 0) {
          await batch.commit()
          batch = writeBatch(db)
        }
        if (el.op === 'update') {
          batch.update(el.ref as DocumentReference, el.data)
        } else if (el.op === 'set') {
          batch.set(el.ref as DocumentReference, el.data)
        } else if (el.op === 'delete') {
          batch.delete(el.ref as DocumentReference)
        } else {
          throw 'invalid operation'
        }
        index++
      }
      await batch.commit()
    } catch (e) {
      throw (e as TypeError | RangeError | EvalError).message
    }
  }
}

export const deleteStorageFile = (storage: StorageGeneric, path: string) => {
  if (isStorageAdmin(storage)) {
    return storage.file(path).delete()
  } else {
    const pathRef = ref(storage, path)
    return deleteObject(pathRef)
  }
}

export const getInBatch = async <T>(
  workspaceRef: DocumentReferenceGeneric,
  collectionName: string,
  batchIds: string[],
): Promise<Record<string, T>> => {
  const result: Record<string, T> = {}

  const batch: string[][] = []
  const batchNumber = 9
  for (let i = 0; i < batchIds.length; i = i + batchNumber) {
    batch.push(batchIds.slice(i, i + batchNumber))
  }

  let qry
  let docs = []

  for (const b of batch) {
    if (isDocumentReferenceAdmin(workspaceRef)) {
      qry = workspaceRef.collection(collectionName).where(FieldPathAdmin.documentId(), 'in', b)
      docs = (await qry.get()).docs
    } else {
      qry = firestoreQuery(collection(workspaceRef as DocumentReference, collectionName), where(documentId(), 'in', b))
      docs = (await getDocs(qry)).docs
    }

    docs.forEach((doc) => {
      result[doc.id] = doc.data() as T
    })
  }
  return result
}

export const parseFingerspell = (workspaceId: string, sentence: string): string[] => {
  const fingerspell: string[] = []
  sentence
    .normalize('NFKC')
    .split('')
    .map((letter) => {
      const _letter =
        (datilology_to_replace[workspaceId] && datilology_to_replace[workspaceId][letter.toLowerCase()]) || null
      if (_letter) {
        _letter.forEach((el) => {
          fingerspell.push(el)
        })
      } else if (letter.match(/^[A-Za-z0-9]*$/)) {
        fingerspell.push(`@${letter.toLowerCase()}`)
      }
    })
  return fingerspell
}
