import { useGlobal } from 'reactn'
import { useCallback } from 'react'

import logger from '../services/logger'
import { IGlobalAttr } from 'collections'

const { log } = logger('useRecordingDuration')

interface IVideoDurationFns {
  /**
   * Adiciona `stepValue` segundos na duração do video
   */
  increase: () => void
  /**
   * Remove `stepValue` segundos da duração do video
   */
  decrease: () => void
  /**
   * Seta um valor em segundos diretamente
   * @param value Duração em segundos que será aplicada a duração do vídeo
   */
  set: (value: number) => void
}

type VideoDurationHookTuple = [number, IVideoDurationFns]

/**
 * Gerencia um contador que pode aumentar ou reduzir a duração de um video dentro de um valor minimo e maximo
 */
const useRecordingDuration = (): VideoDurationHookTuple => {
  const [videoDuration, setVideoDuration] = useGlobal<IGlobalAttr, 'videoDuration'>('videoDuration')

  // Limites
  const minValue = 3000
  const maxValue = 25000

  // Valor a ser adicionado/subtraído
  const stepValue = 500

  const currentDuration = videoDuration || minValue
  /**
   * Seta a duração do vídeo manualmente diretamente
   * @param value Sentença que será aplicada
   */
  const set = useCallback(
    (value: number) => {
      // Trava o novo valor entre o valor minimo e maximo
      const newVideoDuration = Math.min(Math.max(minValue, value), maxValue)
      setVideoDuration(newVideoDuration)
      log(`alterado para ${newVideoDuration}`)
    },
    [setVideoDuration],
  )

  /**
   * Acrescenta `stepValue` segundos a videoDuration
   */
  const increase = useCallback(() => {
    set(currentDuration + stepValue)
  }, [currentDuration, set])

  /**
   * Remove `stepValue` segundos de videoDuration
   */
  const decrease = useCallback(() => {
    set(currentDuration - stepValue)
  }, [currentDuration, set])

  const fns: IVideoDurationFns = {
    increase,
    decrease,
    set,
  }

  return [currentDuration, fns]
}

export default useRecordingDuration
