import React, { useState, useEffect, useCallback } from 'react'
import { useGlobal } from 'reactn'

import { getBoundsByFrame } from '../../../services/utils'
import logger from '../../../services/logger'
import TimelineItemCreator, { ITransform } from './Pure'
import useTimelineRegions from '../../../hooks/useTimelineRegions'
import { IGlobalAttr, ITimelineCursorFns } from 'collections'

const { log } = logger('TimelineItemCreator')

interface ITimelineItemCreatorProps {
  onStart?: () => void
  onMouseMove?: (event: React.MouseEvent) => void
  onEnd?: (startFrame: number, endFrame: number) => void
  position?: number
  timelineCursorFns?: ITimelineCursorFns
}

/**
 * Construtor de itens da linha do tempo, basicamente detecta o dragging
 * de uma div e cria novo bloco, o dragEnd retorna os dados do novo bloco como callback
 */
const TimelineItemCreatorUser: React.FC<ITimelineItemCreatorProps> = ({
  onMouseMove,
  onStart,
  onEnd,
  position,
  timelineCursorFns,
}) => {
  const [transform, setTransform] = useState<ITransform>({
    startX: 0,
    cursorX: 0,
    minBoundX: 0,
    maxBoundX: 0,
  })
  const [isCreating, setIsCreating] = useState(false)
  const [timelineTags] = useTimelineRegions()
  const [timelinePixelsSizeState] = useGlobal<IGlobalAttr, 'timelinePixelsSize'>('timelinePixelsSize')
  const [timelineLeftPixelsOffsetState] = useGlobal<IGlobalAttr, 'timelineLeftPixelsOffset'>('timelineLeftPixelsOffset')
  const [fsVideoDocData] = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')

  const durationFrames = fsVideoDocData?.duration || 0
  const timelinePixelsSize = timelinePixelsSizeState || 0
  const timelineLeftPixelsOffset = timelineLeftPixelsOffsetState || 0

  /**
   * Contém a posição x dentro dos limites especificados
   * e remove a margem lateral do elemento principal
   */
  const normalize = (clientX: number) => {
    const innerClientX = clientX - timelineLeftPixelsOffset
    // Normaliza o elemento para conter no container
    const normalized = Math.max(Math.min(innerClientX, timelinePixelsSize), 0)
    return normalized
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    if (isCreating) return
    const normalized = normalize(event.clientX)
    const { minBoundX, maxBoundX } = getBoundsByFrame(
      Math.round((normalized / timelinePixelsSize) * durationFrames),
      timelineTags,
      durationFrames,
      timelinePixelsSize,
    )

    setTransform({
      startX: normalized,
      cursorX: normalized,
      minBoundX,
      maxBoundX,
    })

    log(`bounds: min ${minBoundX} max ${maxBoundX}`)

    setIsCreating(true)
    if (onStart) onStart()
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    let normalized = normalize(event.clientX)
    // Normaliza o elemento para caber nos bounds especificado
    normalized = Math.min(normalized, transform.maxBoundX)

    setTransform({
      ...transform,
      cursorX: normalized,
    })

    if (onMouseMove) onMouseMove(event)
  }

  const handleMouseUp = useCallback(
    () => {
      log('MouseUp!', isCreating)
      if (!isCreating) return

      setIsCreating(false)
      if (onEnd) {
        // Calcula o frame onde a tag inicia
        const startFrame = (transform.startX / timelinePixelsSize) * durationFrames

        // Calcula o frame onde a tag termina
        const endFrame = (transform.cursorX / timelinePixelsSize) * durationFrames

        onEnd(Math.round(startFrame), Math.round(endFrame))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCreating, transform, timelinePixelsSize],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //Evento de keydown da timeline
  const handleKeydown = (ev: KeyboardEvent) => {
    // Resgata o índice do item de acordo com a posição do cursor
    let indexItem = -1
    if (timelinePixelsSize > 0) {
      indexItem = timelineTags.findIndex(
        (el) =>
          el.endFrame >= ((position || 0) * durationFrames) / timelinePixelsSize &&
          el.startFrame <= ((position || 0) * durationFrames) / timelinePixelsSize,
      )
    }
    /**
     *  Caso o usuário tecle espaço só permitirá o evento caso não esteja em um item da timeline
     */

    /**
     * Key 32 = Space
     * Key 37 = Arrow Left
     * Key 39 = Arrow Right
     */
    if (ev.keyCode === 32 && indexItem === -1) {
      if (!isCreating) {
        const { minBoundX, maxBoundX } = getBoundsByFrame(
          Math.round((position || 0 / timelinePixelsSize) * durationFrames),
          timelineTags,
          durationFrames,
          timelinePixelsSize,
        )
        setTransform({
          startX: position || 0,
          cursorX: position || 0,
          minBoundX: minBoundX,
          maxBoundX: maxBoundX,
        })

        setIsCreating(true)
        if (onStart) onStart()
      } else {
        setIsCreating(false)
        if (onEnd) {
          // Calcula o frame onde a tag inicia
          const startFrame = (transform.startX * durationFrames) / timelinePixelsSize

          // Calcula o frame onde a tag termina
          const endFrame = ((position || 0) * durationFrames) / timelinePixelsSize

          onEnd(Math.round(startFrame), Math.round(endFrame))
        }
      }
    } else if (ev.keyCode === 39 && (indexItem === -1 || !isCreating)) {
      const incrementFrame = ev.shiftKey ? 10 : 1
      const prev = position || 0
      timelineCursorFns?.setPosition(prev < durationFrames ? prev + incrementFrame : prev)
      setTransform({
        ...transform,
        cursorX: position || 0,
      })
    } else if (ev.keyCode === 37) {
      const prev = position || 0
      const decrementFrame = ev.shiftKey ? 10 : 1
      timelineCursorFns?.setPosition(prev > 0 ? prev - decrementFrame : prev)
      setTransform({
        ...transform,
        cursorX: position || 0,
      })
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp, true)
    window.addEventListener('keydown', handleKeydown, true)
    return () => {
      window.removeEventListener('mouseup', handleMouseUp, true)
      window.removeEventListener('keydown', handleKeydown, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreating, transform, handleKeydown])

  return (
    <TimelineItemCreator
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      isCreating={isCreating}
      transform={transform}
    />
  )
}

export default TimelineItemCreatorUser
