import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useIntl from '../../../hooks/useIntl'
import {
  AnimationProblemsCode,
  DocumentData,
  DocumentReferenceClient,
  DocumentSnapshot,
  IAnimation,
  IAnimationProblem,
  ISign,
  IUserInfo,
  WorkspaceId,
  getCollectionReference,
  getFirestoreDocument,
} from 'collections'
import { useFirestore } from 'reactfire'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import { addContribAnimationReviewFunction } from '../../../services/firebase'
import useJobMeter from '../../../services/hooks/useJobMeter'
import {
  Query,
  Timestamp,
  documentId,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
  where,
} from 'firebase/firestore'
import useSnackbar from '../../../services/hooks/useSnackbar'

interface IReportModal {
  signId: string
  workspaceUsers: Record<string, IUserInfo>
  otherProblem: string
  setOtherProblem: React.Dispatch<React.SetStateAction<string>>
  openModalReport: boolean
  setOpenModalReport: React.Dispatch<React.SetStateAction<boolean>>
  setReportProblem: React.Dispatch<React.SetStateAction<boolean>>
}

const ReportModal: React.FC<IReportModal> = ({
  signId,
  workspaceUsers,
  otherProblem,
  setOtherProblem,
  openModalReport,
  setOpenModalReport,
  setReportProblem,
}) => {
  const [open, setOpen] = useState(openModalReport)
  const [signDoc, setSignDoc] = useState<DocumentSnapshot<DocumentData>>()
  const intl = useIntl()
  const firestore = useFirestore()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const { endJobMeter } = useJobMeter('validateTranslation')
  const showSnackbar = useSnackbar()
  const [animationProblems, setAnimationProblems] = useState<IAnimationProblem[]>([
    {
      id: AnimationProblemsCode.wrongFacialExpression,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongFacialExpression}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongHandShape,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongHandShape}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongLocation,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongLocation}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.floatyMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.floatyMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.tooSlow,
      description: intl.get(`animationProblems.${AnimationProblemsCode.tooSlow}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.standardPose,
      description: intl.get(`animationProblems.${AnimationProblemsCode.standardPose}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.unnecessaryFrames,
      description: intl.get(`animationProblems.${AnimationProblemsCode.unnecessaryFrames}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.tooFast,
      description: intl.get(`animationProblems.${AnimationProblemsCode.tooFast}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.uncannyMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.uncannyMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.noFacialExpression,
      description: intl.get(`animationProblems.${AnimationProblemsCode.noFacialExpression}`),
      checked: false,
    },
  ])
  const [selectedAnimation, setSelectedAnimation] = useState<IAnimation>()

  const handleOnChangeProblems = (problem: IAnimationProblem, checked: boolean) => {
    const _animationProblems = [...animationProblems]
    const index = _animationProblems.findIndex((animationProblem) => animationProblem.id == problem.id)
    if (index > -1) {
      _animationProblems[index].checked = checked
    }
    setAnimationProblems(_animationProblems)
  }

  const handleOnClickReport = async () => {
    if (!signDoc) return

    try {
      const errors: string[] = []
      animationProblems
        .filter((animationProblem) => animationProblem.checked == true)
        .forEach((animationProblem) => {
          errors.push(animationProblem.id)
        })
      if (otherProblem.trim() != '') {
        errors.push(otherProblem)
      }
      const currentAnimationReviewedAt = serverTimestamp() as Timestamp
      let currentAnimationHasErrors: boolean | null = null
      if (errors.length == 0) {
        currentAnimationHasErrors = false
        errors.push('NO_ERRORS')
      } else {
        currentAnimationHasErrors = true
      }

      const signData = signDoc.data() as ISign
      const { currentAnimation } = signData || {}

      const _currentAnimation = currentAnimation
      if (_currentAnimation) _currentAnimation.errors = errors

      runTransaction(firestore, async (transaction) => {
        transaction.update(signDoc.ref, {
          currentAnimationHasErrors: currentAnimationHasErrors,
          currentAnimation: _currentAnimation,
          currentAnimationReviewedAt,
        })

        if (currentAnimation?.animation) {
          transaction.update(currentAnimation.animation as DocumentReferenceClient, {
            errors: errors,
            reviewedBy: fsUserRef,
            reviewedAt: currentAnimationReviewedAt,
          })
        }
      })
      endJobMeter()

      await addContribAnimationReviewFunction({
        signId: signDoc.id,
        userId: fsUserRef.id,
        workspaceId: fsUser.workspace.id as WorkspaceId,
      })

      setReportProblem(false)
      const _animationProblems: IAnimationProblem[] = []
      animationProblems.forEach((animation) => {
        _animationProblems.push({ ...animation, checked: false })
      })
      setAnimationProblems(_animationProblems)

      showSnackbar(intl.get('messages.reportedSuccessfully'), {
        variant: 'success',
      })
    } catch (err) {
      showSnackbar((err as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
      setReportProblem(false)
      const _animationProblems: IAnimationProblem[] = []
      animationProblems.forEach((animation) => {
        _animationProblems.push({ ...animation, checked: false })
      })
      setAnimationProblems(_animationProblems)
    }
  }

  useEffect(() => {
    const call = async () => {
      let _animationData: IAnimation | undefined = undefined
      try {
        const signQry = query(
          getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
          where(documentId(), '==', signId),
        )
        const signSnapshot = (await getDocs(signQry)).docs
        const signData = signSnapshot.map((el) => {
          setSignDoc(el)
          return el.data() as ISign
        })

        if (signData && signData[0] && signData[0].currentAnimation && signData[0].currentAnimation?.animation) {
          const animationSnapshot = await getFirestoreDocument(signData[0].currentAnimation.animation)
          _animationData = animationSnapshot.data() as IAnimation
          setSelectedAnimation(_animationData)
        }
      } catch (err) {
        const e = err as Error
        showSnackbar(e.message, { variant: 'error' })
      }

      if (openModalReport == false && _animationData) {
        const _animationProblems: IAnimationProblem[] = []
        animationProblems.forEach((problem) => {
          let indexError: number | undefined = _animationData?.errors?.indexOf(problem.id)
          if (indexError == undefined) indexError = -1

          _animationProblems.push({
            ...problem,
            checked: indexError > -1 ? true : false,
          })
        })
        setAnimationProblems(_animationProblems)

        Object.entries(_animationData?.errors || {}).forEach((el) => {
          let _hasOtherProblem: boolean = true
          animationProblems.forEach((problem) => {
            if (problem.id == el[1]) {
              _hasOtherProblem = false
            }
          })
          if (_hasOtherProblem && el[1] != 'NO_ERRORS') setOtherProblem(el[1])
        })
      }
    }
    call()

    return () => {
      setOtherProblem('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signId])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpenModalReport(false)
        setOpen(false)
      }}
    >
      <DialogTitle>{intl.get('pages.validateTranslation.report')}</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid>
          <Typography style={{ marginLeft: -2 }} variant="h6">
            {intl.get('messages.animator')}:{' '}
            {(selectedAnimation &&
              workspaceUsers[selectedAnimation?.createdBy.id] &&
              workspaceUsers[selectedAnimation?.createdBy.id].displayName) ||
              ''}
          </Typography>
          {animationProblems.map((problem) => (
            <Grid item key={problem.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={problem.checked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      handleOnChangeProblems(problem, checked)
                    }
                    style={{ padding: '5px' }}
                  />
                }
                label={<Typography variant="body2">{problem.description}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <TextField
            value={otherProblem}
            style={{ marginTop: '10px', marginLeft: '-5px' }}
            size="small"
            label={intl.get('pages.reviewAnimation.otherProblem')}
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOtherProblem(event.target.value)}
          />
        </Grid>
        <Button variant="contained" color="primary" onClick={handleOnClickReport} style={{ marginTop: '10px' }}>
          {intl.get('messages.report')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ReportModal
