import React, { useEffect, useState } from 'react'
import AnimationViewPure from './Pure'
import { useFirestoreData } from '../../hooks/useFirestoreData'
import { IAnimation } from 'collections'
import { DocumentData } from '@firebase/firestore'
import { useFsUserDocData } from '../../hooks/useFsUser'

interface IAnimationData extends IAnimation {
  id: string
}

interface IAnimationViewProps {
  /** Dados do sinal atual */
  signData?: DocumentData
  /** HTA da animação */
  htaAnimation?: string
  /** Adiciona estado de repouso a animação */
  rest?: boolean
  /** Repete animação */
  repeat?: boolean
  /** Inicia automáticamente a reprodução da animação */
  autoPlay?: boolean
  /** Controles de velocidade */
  speedControls?: boolean
  /** Array de animações */
  htaAnimations?: string[]
  /** Velocidade padrão */
  defaultSpeed?: number
  /** Altura do componente */
  height?: number
  /** Avatar utilizado */
  avatar?: string
  /** Oculta controles */
  hiddenControls?: boolean
  /** Animação está sendo executada */
  isPlaying: boolean
  /** Alteração de quando a animação está sendo executada */
  setIsPlaying: (value: boolean) => void
  /** Altera a velocidade da animação */
  setSpeed: (value: number) => void
  /** Velocidade da animação */
  speed: number
  /** Texto label velocidade lenta */
  textLow: string
  /** Texto label velocidade média */
  textMedium: string
  /** Texto label velocidade rápida */
  textFast: string
  /** Texto rotacionar avatar */
  textRotate?: string
  /** Texto abrir tabela */
  textOpenTable?: string
  /** Texto velocidade */
  textSpeed?: string
  /** Texto outros */
  textOthers?: string
  /** Id do componente de animação */
  idElement?: string
  /** callback ao finalizar animação */
  onFinalizeAnimation?: () => void
  handleClickOpenSigns?: () => void
  openTable?: boolean
  signs?: { id: string; glosa: string; relatedWords?: string[] }[]
  textNoSigns?: string
  textAnimation?: string
  textReport?: string
  textPlayAnimation?: string
  handleClickPlaySign?: (id: string) => Promise<void>
  handleClickReportProblem?: (id: string) => Promise<void>
  downTable?: boolean
  isLoading?: boolean
}

/**
 * Componente de exibição de animações
 */
const AnimationView: React.SFC<IAnimationViewProps> = ({
  signData,
  htaAnimation,
  htaAnimations,
  rest,
  repeat,
  autoPlay,
  speedControls,
  defaultSpeed,
  height,
  avatar,
  hiddenControls,
  isPlaying,
  setIsPlaying,
  speed,
  setSpeed,
  textLow,
  textMedium,
  textFast,
  idElement,
  onFinalizeAnimation,
  handleClickOpenSigns,
  openTable,
  signs,
  textNoSigns,
  textAnimation,
  textReport,
  handleClickPlaySign,
  handleClickReportProblem,
  downTable,
  isLoading,
  textRotate,
  textOpenTable,
  textSpeed,
  textOthers,
}) => {
  const { currentAnimation } = signData || {}
  let animData: IAnimationData
  const fsUser = useFsUserDocData()

  if (signData) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    animData = useFirestoreData(currentAnimation?.animation.path).data as IAnimationData
  }

  const [animationData, setAnimationData] = useState<Partial<IAnimation> | null>(null)

  useEffect(() => {
    //@ts-ignore
    if (signData && animData) setAnimationData(animData)
    //@ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signData])

  useEffect(() => {
    if (htaAnimation) setAnimationData({ htaAnimation })
  }, [htaAnimation])

  return (
    <AnimationViewPure
      workspaceId={fsUser.workspace.id}
      rest={rest}
      data={animationData}
      htaAnimations={htaAnimations}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
      repeat={repeat}
      autoPlay={autoPlay}
      textFast={textFast}
      textMedium={textMedium}
      textLow={textLow}
      speedControls={speedControls}
      defaultSpeed={defaultSpeed}
      height={height}
      avatar={avatar}
      hiddenControls={hiddenControls}
      speed={speed}
      setSpeed={setSpeed}
      idElement={idElement}
      onFinalizeAnimation={onFinalizeAnimation}
      handleClickOpenSigns={handleClickOpenSigns}
      openTable={openTable}
      signs={signs}
      textNoSigns={textNoSigns}
      textAnimation={textAnimation}
      textReport={textReport}
      handleClickPlaySign={handleClickPlaySign}
      handleClickReportProblem={handleClickReportProblem}
      downTable={downTable}
      isLoading={isLoading}
      textRotate={textRotate}
      textOpenTable={textOpenTable}
      textSpeed={textSpeed}
      textOthers={textOthers}
    />
  )
}

export default AnimationView
