import { FC } from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import WarningIcon from '@material-ui/icons/Warning'
import Tooltip from '@material-ui/core/Tooltip'
import SignViewContainer from '../SignViewContainer'
import { IMenuOptions, ISegmentView } from 'collections'
import HoverablePlayer from '../../pages/HTube/HoverablePlayer'

interface ISignViewProps {
  /** Determina a glosa que representa este card */
  glosa: string
  /** Callback para sinal removido */
  onRemove?: () => void
  /** Altera a visualização do sinal */
  variant?: 'badge' | 'compact' | 'default'
  /** Texto do tooltip da glosa e popularidade */
  textTooltipGlosaNumberOfSegments: string
  /** Dados do segmento */
  segmentView?: ISegmentView
  /** Quantidade de segmentos do cluster */
  numberOfSegments: number
  /** Menu component */
  menu?: IMenuOptions[]
  /** Texto Sem vídeo */
  textWithoutVideo: string
}

/**
 * Esse componente recebe um atributo da tag que pode ser uma Glosa ou datiologia.
 * */
export const SignView: FC<ISignViewProps> = ({
  glosa,
  onRemove,
  variant = 'default',
  textTooltipGlosaNumberOfSegments,
  segmentView,
  numberOfSegments,
  menu,
  textWithoutVideo,
}) => {
  const videoInfo = (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
      {!segmentView && <WarningIcon color="error" />}
      <Typography color={!segmentView ? 'error' : 'textPrimary'}>
        {!segmentView ? textWithoutVideo : 'Cropping'}
      </Typography>
    </Box>
  )

  const banner = (
    <>
      {segmentView && <HoverablePlayer segment={segmentView} fullHeight={true} isPlaying={true} menu={menu} />}
      {!segmentView && videoInfo}
    </>
  )

  const footer = (
    <>
      <Tooltip title={textTooltipGlosaNumberOfSegments}>
        <Box py={1} display="flex" justifyContent="space-between">
          <Typography noWrap>{glosa}</Typography>
          <Typography noWrap>{numberOfSegments}</Typography>
        </Box>
      </Tooltip>
    </>
  )

  const content = (
    <SignViewContainer
      onRemove={onRemove}
      color="indigo"
      bannerEl={variant != 'badge' && banner}
      footerEl={variant != 'compact' && footer}
    />
  )

  if (variant === 'badge') {
    return (
      <Box height="fit-content" width="fit-content">
        {content}
      </Box>
    )
  }

  return content
}

export default SignView
