import React, { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'wouter'

import CardMessage from '../../components/CardMessage'
import Layout from '../../components/Layout'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { routes } from '../../community'
import Preloader from '../../components/Preloader'
import { IPaginationConfig, ISign, IUserInfo } from 'collections'
import useIntl from '../../hooks/useIntl'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import { Query, getCollectionReference, Timestamp, DocumentReference } from 'collections'

import { DocumentData, endBefore, limit, limitToLast, orderBy, query, startAfter, where } from '@firebase/firestore'
import { useFirestoreCollection } from 'reactfire'
import { getUsersByWorkspaceFunction } from '../../services/firebase'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import styled from 'styled-components'

const SearchField = styled(TextField)`
  && {
    margin: 10px 0;
  }
`

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface IReviewAnimationProps {
  path: string
}

interface ISignWithId extends ISign {
  id: string
  ref: DocumentReference
  animator: string
  animatorCurrentAnimation: string
  animationCreatedAt: string | Timestamp
}

let textDebouncer: NodeJS.Timeout

/**
 * Modulo de revisão de animação
 */
const ReviewAnimation: React.FC<IReviewAnimationProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const hasPermissionOnModule = useCheckHasPermission('reviewAnimation')
  const fsUser = useFsUserDocData()
  const [, setLocation] = useLocation()

  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>({
    currentPage: 0,
    firstOfList: null,
    lastOfList: null,
    rowsPerPage: 30,
    load: true,
  })

  const initialSignquery = query(
    getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
    where('currentAnimationHasErrors', '==', null),
    where('_state', '==', 'ANIMATED'),
    orderBy('popularity', 'desc'),
    limit(1),
  )

  const [signQueryState, setSignQueryState] = useState<Query<DocumentData>>(initialSignquery)

  const signsCollection = useFirestoreCollection(signQueryState)

  const [signs, setSigns] = useState<ISignWithId[]>([])
  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [searchInputText, setSearchInputText] = useState('')

  const intl = useIntl()

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const _signs: ISignWithId[] = []

    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.lastOfList = signsCollection.data.docs[signsCollection.data.docs.length - 1]
    _paginationConfig.firstOfList = signsCollection.data.docs[0]
    _paginationConfig.load = false

    for (const signSnapshot of signsCollection.data.docs) {
      const signData = signSnapshot.data() as ISignWithId
      signData.id = signSnapshot.id
      signData.ref = signSnapshot.ref
      const currentUser = signData?.isAnimating?.user && workspaceUsers[signData.isAnimating.user.id]

      const currentUserCurrentAnimation =
        signData?.currentAnimation?.createdBy && workspaceUsers[signData.currentAnimation.createdBy.id]
      _signs.push({
        ...signData,
        animator: currentUser?.displayName || ``,
        animatorCurrentAnimation: currentUserCurrentAnimation?.displayName || '',
        animationCreatedAt: signData.currentAnimation?.createdAt || '',
      })
    }

    setPaginationConfig(_paginationConfig)
    setSigns(_signs)
    setHasMoreResults(paginationConfig.rowsPerPage === _signs.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signsCollection.data])

  useEffect(() => {
    if (paginationConfig.load) {
      fetchSigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  /**
   * Resgata possíveis sinais a serem revisados
   */
  const fetchSigns = useCallback(() => {
    setIsLoading(true)
    try {
      let signsQuery = query(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where('currentAnimationHasErrors', '==', null),
        where('_state', '==', 'ANIMATED'),
      )

      if (searchInputText.trim()) {
        signsQuery = query(signsQuery, where('searchTerms', 'array-contains', searchInputText.toLowerCase()))
      }

      signsQuery = query(signsQuery, orderBy('popularity', 'desc'))

      if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
        if (paginationConfig.lastOfList) {
          signsQuery = query(signsQuery, startAfter(paginationConfig.lastOfList))
        } else {
          signsQuery = query(
            signsQuery,
            endBefore(paginationConfig.firstOfList),
            limitToLast(paginationConfig.rowsPerPage),
          )
        }
      }

      if (!paginationConfig.firstOfList) signsQuery = query(signsQuery, limit(paginationConfig.rowsPerPage))
      setSignQueryState(signsQuery)
    } finally {
      setIsLoading(false)
    }
  }, [
    fsUser.workspace,
    paginationConfig.firstOfList,
    paginationConfig.lastOfList,
    paginationConfig.rowsPerPage,
    searchInputText,
  ])

  const handleSearchSign = useCallback(() => {
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.firstOfList = null
    _paginationConfig.lastOfList = null
    _paginationConfig.currentPage = 0
    _paginationConfig.load = true
    setPaginationConfig(_paginationConfig)
  }, [paginationConfig])

  const onSignSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = (event.target && event.target.value) || ''
      setSearchInputText(text)
      clearTimeout(textDebouncer)
      textDebouncer = setTimeout(() => {
        handleSearchSign()
      }, 1000)
    },
    [handleSearchSign],
  )

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.rowsPerPage = Number(event.target.value)
      _paginationConfig.currentPage = 0
      _paginationConfig.lastOfList = null
      _paginationConfig.firstOfList = null
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const handleClickSign = (signId: string) => {
    setLocation(routes.reviewAnimation.replace(':signId', signId))
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.reviewAnimation')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.reviewAnimation')} requiredModule={'reviewAnimation'}>
      {/** Exibe o preload ja que está carregando */}
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingVideo')}></Preloader>}

      {/** Exibe a mensagem de que não existe itens na fila  */}
      {!isLoading && (
        <Grid item container alignItems="center" style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item md={10}>
            <Grid item container alignItems="center" style={{ display: 'flex', gap: '10px' }}>
              <SearchField
                label={intl.get('messages.search')}
                variant="filled"
                value={searchInputText}
                onChange={onSignSearch}
              />
            </Grid>

            <TableContainer component={Paper}>
              <Table stickyHeader style={{ minWidth: 700 }} aria-label="signs table">
                <TableHead>
                  <TableRow>
                    <TableCell>{intl.get('pages.hTube.sign.relatedWords')}</TableCell>
                    <TableCell>{intl.get('messages.animator')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {signs.map((sign) => (
                    <StyledTableRow
                      key={sign.id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleClickSign(sign.id)
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {sign.relatedWords.join(', ')}
                      </TableCell>
                      <TableCell>{sign.animator}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ pointerEvents: 'auto' }}
              component="div"
              count={-1}
              rowsPerPage={paginationConfig.rowsPerPage}
              page={paginationConfig.currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 30]}
              labelRowsPerPage={intl.get('messages.labelRows')}
              labelDisplayedRows={({ from, to }) =>
                intl.get('pages.animation.labelDisplayedRows', {
                  from,
                  to,
                })
              }
              nextIconButtonProps={{ disabled: !hasMoreResults }}
            />
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}

export default ReviewAnimation
