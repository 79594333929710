import React, { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import Layout from '../../components/Layout'
import { routes } from '../../community'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { SegmentId, ISegment, IMatchSegmentsQueue, getDocumentReference, getFirestoreDocument } from 'collections'
import CardMessage from '../../components/CardMessage'
import Preloader from '../../components/Preloader'
import useIntl from '../../hooks/useIntl'
import { useDatabase } from 'reactfire'
import LockModuleDialog from '../../components/LockModuleDialog'
import { useFsSettingsDocData } from '../../hooks/useFsSettingsDocData'
import { get, limitToLast, orderByChild, query, ref } from '@firebase/database'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'

interface IFindIdenticalSegmentsProps {
  path?: string
}

const FindIdenticalSegments: React.FC<IFindIdenticalSegmentsProps> = () => {
  const intl = useIntl()
  const hasPermissionOnModule = useCheckHasPermission('findIdenticalSegments')
  const fsUser = useFsUserDocData()
  const [segmentId, setSegmentId] = useState<SegmentId>()
  const [, setLocation] = useLocation()
  const [isLoading, setIsloading] = useState(true)
  const db = useDatabase()
  const [open, setOpen] = useState(false)
  const settings = useFsSettingsDocData()

  /**
   * Resgata o id do segmento na fila
   */
  const getSegmentToMatch = async () => {
    return new Promise<string>((resolve) => {
      const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/${
        settings.queueMatchSegments || 'newMatchSegmentsPriority'
      }`
      const reference = query(ref(db, referenceString), orderByChild('priority'), limitToLast(1000))

      get(reference).then(async (value) => {
        const queueMatch = Object.fromEntries(
          // TODO -> Implementar interfaces do database
          Object.entries(value.val()).filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (el: any) => el[1][Object.keys(el[1])[0]].numberOfEvaluations < 2,
          ),
        )

        const keys = Object.keys(queueMatch)
        let randomKey = ''
        let searchSegment = true
        let randomQueueObject: IMatchSegmentsQueue = {}
        while (searchSegment && keys.length) {
          const index = Math.floor(Math.random() * keys.length)

          randomKey = keys[index]
          if (randomKey) randomQueueObject = queueMatch[randomKey] as IMatchSegmentsQueue
          const segmentId = Object.keys(randomQueueObject)[0]
          const segmentRef = getDocumentReference(fsUser.workspace, 'segments', segmentId)
          const segmentSnapshot = await getFirestoreDocument(segmentRef)
          const segmentData = segmentSnapshot.data() as ISegment
          searchSegment = segmentData?.reported || false
          if (searchSegment) keys.splice(index, 1)
        }
        if (!keys.length) {
          setOpen(true)
          return
        }
        resolve(randomKey)
      })
    })
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  /**
   * Define um novo segmento que será comparado
   */
  const fetchSegment = useCallback(async () => {
    const segmentQueueId: string = await getSegmentToMatch()
    setIsloading(true)

    if (segmentQueueId) {
      setSegmentId(segmentQueueId)
      setLocation(routes.findIdenticalSegments.replace(':segmentQueueId', segmentQueueId))
    }

    setIsloading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.workspace])

  useEffect(() => {
    if (fsUser.modules.includes('findIdenticalSegments') && hasPermissionOnModule) {
      fetchSegment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.findIdenticalSegments')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.findIdenticalSegments')} requiredModule={'findIdenticalSegments'}>
      {/** Exibe o preload ja que está carregando */}
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingVideo')}></Preloader>}

      {/** Exibe a mensagem de que não existe itens na fila  */}
      {!isLoading && !segmentId && (
        <CardMessage
          title={intl.get('pages.findIdenticalSegments.noSegmentFound.title')}
          subtitle={intl.get('pages.findIdenticalSegments.noSegmentFound.description')}
        />
      )}
      <LockModuleDialog open={open} toggleOpen={toggleOpen} />
    </Layout>
  )
}

export default FindIdenticalSegments
