import { useEffect, useState, useCallback } from 'react'

import logger from '../services/logger'
import { ValidateActions, createTranslationValidation, ITranslationValidationRegion } from 'collections'
import useSentence from './useSentence'
import useNmtSuggestion from './useNmtSuggestion'
import useSnackbar from '../services/hooks/useSnackbar'
import { useFsUserDocData, useFsUserDocRef } from './useFsUser'
import useJobMeter from '../services/hooks/useJobMeter'
import useTranslationValidation from './useTranslationValidation'
import useFirebaseAnalytics from '../services/hooks/useFirebaseAnalytics'
import useContributionsLogger from '../services/hooks/useContributionsLogger'
import { updateQueuePriorityFunction } from '../services/firebase'
import useIntl from './useIntl'

const { log, error } = logger('useValidateTranslationModule')

interface IValidateTranslationModuleFns {
  save: (action: ValidateActions) => Promise<void>
}

type ValidateTranslationModuleHookTuple = [IValidateTranslationModuleFns]

/**
 * Gerencia o modulo de validação de traduções
 */
const useValidateTranslationModule = (): ValidateTranslationModuleHookTuple => {
  const [translationValidation, translationValidationFns] = useTranslationValidation()
  const [sentenceState, sentenceFns] = useSentence('validateTranslation')
  const [suggestionState, suggestionFns] = useNmtSuggestion()
  const showSnackbar = useSnackbar()
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const { translationValidatedEvent, validationCorrectIntervalEvent } = useFirebaseAnalytics()
  const contributionsLoggerFns = useContributionsLogger()[1]
  const { startJobMeter, endJobMeter, resetJobMeter } = useJobMeter('validateTranslation')
  const [lastCorrectDate, setLastCorrectDate] = useState<Date>()

  // Sempre que recebermos uma sugestao nova de tradução, atualizamos o view de sinais
  useEffect(() => {
    translationValidationFns.setRegions(suggestionState.nmtSuggestion || [])
    startJobMeter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionState.nmtSuggestion])

  useEffect(() => {
    suggestionFns.setSentence(sentenceState.sentence)
    if (sentenceState.sentence) {
      resetJobMeter()
      log('Sentença definida')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentenceState.sentence])

  const save = useCallback(
    async (action: ValidateActions) => {
      if (!sentenceState.sentence) {
        showSnackbar(intl.get('hooks.useValidateTranslationModule.noSentenceDefined'), { variant: 'error' })
        error('Não há sentença!')
        return
      }

      contributionsLoggerFns.update(sentenceState.sentence, 0, 'validateTranslation')

      const currentDate = new Date()
      if (action == 'correct') {
        setLastCorrectDate(currentDate)
        if (lastCorrectDate) {
          const timeDiff = currentDate.getTime() - lastCorrectDate.getTime()
          // Converte o tempo para segundos
          const interval = Math.round(timeDiff / 1000)
          log(`Tempo entre validações corretas: ${interval}`)
          validationCorrectIntervalEvent(interval)
        }
      }

      endJobMeter()
      translationValidatedEvent(sentenceState.sentence, action)

      if (translationValidation.length === 0) {
        showSnackbar(intl.get('hooks.useValidateTranslationModule.emptyTranslation'), { variant: 'error' })
        return
      }

      const translation = translationValidation.map((item) => {
        const region: ITranslationValidationRegion = {
          type: item.text ? 'fingerspell' : 'sign',
        }

        if (item.signRef) region.sign = item.signRef
        if (item.text) region.text = item.text

        return region
      })

      sentenceFns.refresh()

      try {
        await createTranslationValidation(fsUser, fsUserRef, {
          isCorrect: action === 'correct',
          isMissingSign: action === 'missingSigns',
          isOutOfContext: action === 'outOfContext',
          sentence: sentenceState.sentence,
          translation,
        })
        log('Validação salva')
        showSnackbar(intl.get('hooks.useValidateTranslationModule.savedValidation'), { variant: 'success' })
        contributionsLoggerFns.update(sentenceState.sentence, 1, 'validateTranslation')
        // Altera a prioridade na fila
        await updateQueuePriorityFunction({
          oralLanguageId: fsUser.oralLanguageId,
          signLanguageId: fsUser.signLanguageId,
          sentence: sentenceState.sentence || '',
          isCorrect: action === 'correct',
          workspaceId: fsUser.workspace.id,
          sentenceOrigin: sentenceState.origin?.toUpperCase(),
          corpusGroup: sentenceState.corpusGroup,
          sentenceCategory: sentenceState.sentenceCategory || ['undefined'],
          clientId: sentenceState.clientId,
        })
      } catch (err) {
        showSnackbar(intl.get('hooks.useValidateTranslationModule.failedSavedValidation'), { variant: 'error' })
        error(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser, fsUserRef, lastCorrectDate, sentenceState.sentence, translationValidation],
  )

  const fns: IValidateTranslationModuleFns = {
    save,
  }

  return [fns]
}

export default useValidateTranslationModule
