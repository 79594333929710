import { useCallback } from 'react'
import { useGlobal } from 'reactn'

import { useFsUserDocData } from './useFsUser'
import logger from '../services/logger'
import { IGlobalAttr, CommunityModule, ISentenceTranslationPriority, TCorpusGroup, getData } from 'collections'
import { useDatabase } from 'reactfire'
import { limitToLast, orderByChild, query, ref } from 'firebase/database'
import useSnackbar from '../services/hooks/useSnackbar'
import useIntl from './useIntl'

const { log } = logger('useSentence')

interface ISentenceStates {
  isLoading?: boolean
  sentence?: string
  origin?: string
  corpusGroup?: TCorpusGroup
  sentenceCategory?: string[] | null
  clientId?: string
}

interface ISentenceFns {
  /**
   * Chama o firebase function que pega uma nova sentença e define
   * no state atual deste hook
   */
  refresh: () => Promise<void>
  /**
   * Seta uma sentença diretamente
   * @param value Sentença que será aplicada
   */
  set: (value: string) => void
}

type SentenceHookTuple = [ISentenceStates, ISentenceFns]

/**
 * Este hook gerencia o fluxo de obtenção e atualização de setenças na aplicação
 */
const useSentence = (communityModule: CommunityModule): SentenceHookTuple => {
  const [sentence, setSentence] = useGlobal<IGlobalAttr, 'sentence'>('sentence')
  const [isLoading, setIsLoading] = useGlobal<IGlobalAttr, 'isLoadingSentence'>('isLoadingSentence')
  const [sentenceOrigin, setSentenceOrigin] = useGlobal<IGlobalAttr, 'sentenceOrigin'>('sentenceOrigin')
  const [corpusGroup, setCorpusGroup] = useGlobal<IGlobalAttr, 'corpusGroup'>('corpusGroup')
  const [sentenceCategory, setSentenceCategory] = useGlobal<IGlobalAttr, 'sentenceCategory'>('sentenceCategory')
  const [sentenceToRecord] = useGlobal<IGlobalAttr, 'sentenceToRecord'>('sentenceToRecord')
  const [clientId, setClientId] = useGlobal<IGlobalAttr, 'clientId'>('clientId')

  const fsUser = useFsUserDocData()

  const db = useDatabase()

  const showSnackbar = useSnackbar()
  const intl = useIntl()

  const getSentence = async () => {
    if (fsUser)
      return new Promise((resolve) => {
        if (sentenceToRecord) {
          const refString = `${fsUser?.workspace.id || ''}/${fsUser?.oralLanguageId || ''}/${
            fsUser?.signLanguageId || ''
          }/recordVideoPriority/${sentenceToRecord}`
          const reference = ref(db, refString)
          getData(reference).then((value) => {
            const sentenceData = value.val()
            resolve({
              data: {
                sentence: (sentenceData.sentence || '').normalize('NFKC'),
                origin: (sentenceData.sentenceOrigin || 'APP').toUpperCase(),
                corpusGroup: sentenceData.corpusGroup,
                sentenceCategory: sentenceData.sentenceCategory,
                clientId: sentenceData.clientId,
              },
            })
          })
        } else {
          const refDb = communityModule === 'signSentence' ? 'recordVideoPriority' : 'translationPriority'
          let sentences: Record<string, ISentenceTranslationPriority> = {}
          const refString = `${fsUser?.workspace.id || ''}/${fsUser?.oralLanguageId || ''}/${
            fsUser?.signLanguageId || ''
          }/${refDb}`
          const reference = ref(db, refString)

          const databaseQuery = query(reference, orderByChild('priority'), limitToLast(10000))

          getData(databaseQuery).then((value) => {
            sentences = value.val()
            if (!sentences) {
              showSnackbar(intl.get('hooks.useSentence.queueNotFound'), {
                variant: 'error',
              })
              return
            }
            const searchedKeys: string[] = []
            const keys = Object.keys(sentences)
            let randomKey: string = ''

            while (
              (!sentences[randomKey] ||
                !sentences[randomKey].sentence ||
                ((sentences[randomKey] ? sentences[randomKey].isExternal : false) !== fsUser.isExternal &&
                  communityModule === 'signSentence')) &&
              searchedKeys.length <= keys.length
            ) {
              while (searchedKeys.includes(randomKey)) {
                const index = Math.floor(Math.random() * keys.length)
                randomKey = keys[index]
              }
              if (!searchedKeys.includes(randomKey)) {
                searchedKeys.push(randomKey)
              }
            }

            if (
              !sentences[randomKey] ||
              !sentences[randomKey].sentence ||
              ((sentences[randomKey] ? sentences[randomKey].isExternal : false) !== fsUser.isExternal &&
                communityModule === 'signSentence')
            ) {
              showSnackbar(intl.get('hooks.useSentence.queueNotFound'), {
                variant: 'error',
              })
              return
            }
            resolve({
              data: {
                sentence: (sentences[randomKey].sentence || '').normalize('NFKC'),
                origin: (sentences[randomKey].sentenceOrigin || 'APP').toUpperCase(),
                corpusGroup: sentences[randomKey].corpusGroup,
                sentenceCategory: sentences[randomKey].sentenceCategory,
                clientId: sentences[randomKey].clientId,
              },
            })
          })
        }
      })
  }

  /**
   * Chama o firebase function que pega uma nova sentença e define
   * no state atual deste hook
   */
  const refresh = useCallback(async () => {
    setSentence('')
    setIsLoading(true)
    setSentenceOrigin('')
    setClientId('HT')
    setCorpusGroup('TRAIN')
    setSentenceCategory(['undefined'])
    log('Obtendo nova sentença...')

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any = {
      data: {
        sentence: '',
        origin: '',
        corpusGroup: 'TRAIN',
        sentenceCategory: ['undefined'],
      },
    }

    response = await getSentence()

    setSentence(response?.data.sentence || '')
    setIsLoading(false)
    setSentenceOrigin((response?.data.origin || '').toUpperCase())
    setClientId(response?.data.clientId || 'HT')
    setCorpusGroup(response?.data.corpusGroup)
    setSentenceCategory(response?.data.sentenceCategory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser?.oralLanguageId])

  /**
   * Seta uma sentença diretamente
   * @param value Sentença que será aplicada
   */
  const set = (value: string) => {
    setSentence(value)
  }

  const states: ISentenceStates = {
    sentence,
    isLoading,
    origin: sentenceOrigin?.toUpperCase(),
    clientId: clientId,
    corpusGroup,
    sentenceCategory,
  }

  const fns: ISentenceFns = {
    refresh,
    set,
  }

  return [states, fns]
}

export default useSentence
