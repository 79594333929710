import React, { useState, useEffect } from 'react'

import ContributionsHeader from './Pure'
import useContributionsPagination from '../../../../services/hooks/useContributionsPagination'
import { UserId, IUserInfo, IOptionsSelect, DocumentData } from 'collections'
import { useFirestore } from 'reactfire'
import { doc, DocumentReference } from '@firebase/firestore'
import useIntl from '../../../../hooks/useIntl'

interface IContributionsHeaderUse {
  /** Categorias das sentenças*/
  categories?: string[]
  /** Origens das sentenças*/
  origins?: string[]
  /** Estados do vídeo*/
  videoStates?: IOptionsSelect[]
  /** Função para aplicar filtros */
  applyFiltersProps?: (
    startDate: Date,
    endDate: Date,
    userReference?: DocumentReference,
    searchText?: string,
    signId?: string,
    sentenceOrigin?: string,
    sentenceCategory?: string,
    videoState?: string,
    page?: number,
  ) => void
}

interface IFilterDate {
  startDate: Date
  endDate: Date
}
/**
 * Cabeçalho das contribuições, aqui estão todos os seletores de filtros e o nome do workspace
 * Lembre-se que os filtros podem ser condicionados com o papel do usuário
 */
const ContributionsHeaderUse: React.FC<IContributionsHeaderUse> = ({
  categories,
  origins,
  videoStates,
  applyFiltersProps,
}) => {
  const [allUsersInfo, setAllUsersInfo] = useState<Record<UserId, IUserInfo>>({})
  const [{ usersInfo, filters }] = useContributionsPagination()
  const [selectedUserId, setSelectedUserId] = useState<string>(filters.userReference?.id || 'all')
  const [selectedUserRef, setSelectedUserRef] = useState<DocumentReference<DocumentData> | undefined>()
  const [sentenceOrigin, setSentenceOrigin] = useState<string>(filters.sentenceOrigin || 'all')
  const [sentenceCategory, setSentenceCategory] = useState<string>(filters.sentenceCategory || 'all')
  const [videoState, setVideoState] = useState<string>(filters.videoState || 'SEGMENTED')
  const [date, setDate] = useState<IFilterDate>({
    startDate: filters.startDate,
    endDate: filters.endDate,
  })
  const [searchText, setSearchText] = useState(filters.searchText)
  const [signId, setSignId] = useState(filters.signId)
  const firestore = useFirestore()
  const intl = useIntl()

  // Sempre que a lista de users atualizar, adicionamos all
  useEffect(() => {
    setAllUsersInfo(usersInfo)
  }, [usersInfo])

  /**
   * Data final foi alterada
   */
  const handleEndDateChange = (newDate: Date) => {
    setDate({ startDate: filters.startDate, endDate: newDate })
  }

  /**
   * Data inicial foi alterada
   */
  const handleStartDateChange = (newDate: Date) => {
    setDate({ startDate: newDate, endDate: filters.endDate })
  }

  /**
   * Usuário foi alterado
   */
  const handleUserChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const userId = event.target.value as UserId

    const userRef = userId !== 'all' ? doc(firestore, 'users', userId) : undefined

    setSelectedUserId(userId)
    setSelectedUserRef(userRef)
  }

  const handleOriginChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const origin = event.target.value as string

    setSentenceOrigin(origin)
  }

  const handleCategoryChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const category = event.target.value as string

    setSentenceCategory(category)
  }
  const handleStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const state = event.target.value as string

    setVideoState(state)
  }

  const handleApplyFilters = () => {
    applyFiltersProps!(
      date!.startDate !== filters.startDate ? date!.startDate : filters.startDate,
      date!.endDate !== filters.endDate ? date!.endDate : filters.endDate,
      selectedUserRef !== filters.userReference ? selectedUserRef : filters.userReference,
      searchText !== filters.searchText ? searchText : filters.searchText,
      signId !== filters.signId ? signId : filters.signId,
      sentenceOrigin !== filters.sentenceOrigin ? sentenceOrigin : filters.sentenceOrigin,
      sentenceCategory !== filters.sentenceCategory ? sentenceCategory : filters.sentenceCategory,
      videoState !== filters.videoState ? videoState : filters.videoState,
      0,
    )
  }

  const handleClearFilters = () => {
    setSearchText('')
    setSignId('')
    setSelectedUserId('all')
    setSentenceOrigin('all')
    setSentenceCategory('all')
    setVideoState('NONE')
    setDate({
      startDate: new Date('Jan 01 2018'),
      endDate: new Date(),
    })
    applyFiltersProps!(new Date('Jan 01 2018'), new Date(), undefined, '', '', 'all', 'all', 'NONE', 0)
  }

  return (
    <ContributionsHeader
      intl={intl}
      startDate={date.startDate}
      endDate={date.endDate}
      users={allUsersInfo}
      selectedUserId={selectedUserId}
      searchText={searchText ? searchText : ''}
      signId={signId ? signId : ''}
      sentenceOrigin={sentenceOrigin}
      sentenceCategory={sentenceCategory}
      videoState={videoState}
      categories={categories}
      origins={origins}
      videoStates={videoStates}
      onStartDateChange={handleStartDateChange}
      onEndDateChange={handleEndDateChange}
      onUserChange={handleUserChange}
      onOriginChange={handleOriginChange}
      onCategoryChange={handleCategoryChange}
      onStateChange={handleStateChange}
      onSearchTextChange={(event) => {
        setSearchText(event.target.value)
      }}
      onSignIdChange={(event) => {
        setSignId(event.target.value)
      }}
      applyFilter={handleApplyFilters}
      clearFilter={handleClearFilters}
    />
  )
}

export default ContributionsHeaderUse
