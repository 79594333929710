import { Grid } from '@material-ui/core'
import { IGlobalAttr, ISignOnDemand, serverTimestamp, StatusSignOnDemand, Timestamp } from 'collections'
import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn'
import { ISegmentViewProcessVideo, ISignWithRef } from '..'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import FramePlayer from '../../../components/FramePlayer'
import useIntl from '../../../hooks/useIntl'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { separeVideoSentence } from '../../../services/utils'
import VerticalTimelineItem from '../VerticalTimelineItem'
import { SearchSignOnDemand } from './SearchSignOnDemand'

interface IVerticalTimelineItemHoverableProps {
  segmentView: ISegmentViewProcessVideo
  index: number
  setSegments: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo[]>>
  segments: ISegmentViewProcessVideo[]
  setsegmentToSuggestion?: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo | null>>
  setEditing: React.Dispatch<React.SetStateAction<boolean>>

  setSignOnDemandLinked: React.Dispatch<React.SetStateAction<Record<string, ISignOnDemand>>>
  signOnDemandLinked: Record<string, ISignOnDemand>
  setSignsData: React.Dispatch<React.SetStateAction<Record<string, ISignWithRef | null>>>
  signsData: Record<string, ISignWithRef | null>
}

const initialSignOnDemand: ISignOnDemand = {
  glosa: '',
  portuguese: [],
  hta: null,
  status: StatusSignOnDemand.request,
  videoRef: null,
  segmentRef: null,
  error: null,
  isSegmenting: null,
  isRecording: null,
  searchReference: '',
  description: '',
  pieceOfSign: false,
  sentenceOrigin: '',
  corpusGroup: 'TRAIN',
  sentenceCategory: ['undefined'],
  clientId: '',
  regionOfUse: ['Norte', 'Nordeste', 'Centro-Oeste', 'Sudeste', 'Sul'],
}

interface ISearchSignOnDemandResult {
  text: string | null
  signOnDemand?: ISignOnDemand | null
}

const VerticalTimelineItemHoverable: React.FC<IVerticalTimelineItemHoverableProps> = ({
  segmentView,
  index,
  setSegments,
  segments,
  setsegmentToSuggestion,
  setEditing,
  setSignOnDemandLinked,
  signOnDemandLinked,
  setSignsData,
  signsData,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [selectedSignOnDemand, setSelectedSignOnDemand] = useState<ISearchSignOnDemandResult>({
    text: null,
    signOnDemand: null,
  })
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const showSnackbar = useSnackbar()
  const intl = useIntl()

  const [opennedRequestSignOnDemandDialog, setOpennedRequestSignOnDemandDialog] = useState(false)

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const handleOnMouseEnter = () => {
    setIsPlaying(true)
  }

  const handleOnMouseLeave = () => {
    timelineCursorFns.setPosition(segmentView.startFrame)
    setIsPlaying(false)
  }

  const toggleRequestSignOnDemandDialog = () => {
    setOpennedRequestSignOnDemandDialog(!opennedRequestSignOnDemandDialog)
  }

  const handleRequestSignOnDemand = async () => {
    try {
      if (!selectedSignOnDemand.signOnDemand && !selectedSignOnDemand.text) return

      const _signOnDemandLinked = { ...signOnDemandLinked }
      const segmentId = segments[index].id || ''
      if (selectedSignOnDemand.signOnDemand) {
        // Tem id vincula
        _signOnDemandLinked[segmentId] = selectedSignOnDemand.signOnDemand
      } else if (selectedSignOnDemand.text && selectedSignOnDemand.text.trim()) {
        // Cria nova solicitação de sinal sob demanda
        const signOnDemand = JSON.parse(JSON.stringify(initialSignOnDemand)) as ISignOnDemand

        signOnDemand.glosa = selectedSignOnDemand.text.trim()
        signOnDemand.portuguese?.push(selectedSignOnDemand.text.trim())
        signOnDemand.searchTerms = []
        const dateUpdate = serverTimestamp() as Timestamp
        signOnDemand.lastUpdate = dateUpdate
        signOnDemand.createdAt = dateUpdate
        signOnDemand.segmentToView = segmentView.ref || null
        signOnDemand.sentenceOrigin = segmentView.videoSentenceOrigin || 'DEMAND'
        signOnDemand.sentenceCategory = segmentView.videoSentenceCategories || ['undefined']

        _signOnDemandLinked[segmentId] = signOnDemand
      } else {
        if (!selectedSignOnDemand.text) {
          showSnackbar(
            intl.get('messages.requiredField', {
              field: intl.get('pages.signOnDemand.glosa'),
            }),
            { variant: 'error' },
          )
          return
        }
      }
      setSignOnDemandLinked(_signOnDemandLinked)

      // if (signOnDemandId) {
      //   // Cria vínculo
      //   handleLinkSignOnDemandSegment(signOnDemandId)
      // }
    } finally {
      setOpennedRequestSignOnDemandDialog(false)
    }
  }

  useEffect(() => {
    if (opennedRequestSignOnDemandDialog)
      setSelectedSignOnDemand({
        text: null,
        signOnDemand: null,
      })
  }, [opennedRequestSignOnDemandDialog])

  return (
    <>
      <Grid
        item
        container
        style={{ display: 'flex', justifyContent: 'space-between' }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <Grid item container md={6}>
          <div
            style={{
              height: '100%',
              width: '95%',
            }}
          >
            <FramePlayer
              reference={segmentView.id}
              src={segmentView.videoUrl || ''}
              onReady={() => ({})}
              onError={() => ({})}
              startFrame={segmentView.startFrame}
              framesDuration={segmentView.endFrame}
              timeFrame={appConfig.frameRate}
              frameRatio={30}
              onChangedTime={handleOnChangedTime}
              timelineCursor={timelineCursor}
              autoPlay={isPlaying}
              hoverable={true}
              fullHeight={false}
              framesVideo={segmentView.framesVideo}
              fullVideoDuration={segmentView.videoDuration}
              menuOptions={[
                {
                  onClick: () => {
                    toggleRequestSignOnDemandDialog()
                  },
                  title: intl.get('messages.signOnDemandRequest'),
                },
              ]}
            />
          </div>
        </Grid>
        <Grid item container md={6}>
          <VerticalTimelineItem
            setSignsData={setSignsData}
            signsData={signsData}
            isPlaying={isPlaying}
            signRef={segmentView.movement}
            index={index}
            segments={segments}
            setSegments={setSegments}
            setsegmentToSuggestion={setsegmentToSuggestion}
            setEditing={setEditing}
            menuItems={separeVideoSentence(segmentView.videoSentence || '')}
            signOnDemand={signOnDemandLinked[segments[index].id || ''] || null}
            signOnDemandLinked={signOnDemandLinked}
            setSignOnDemandLinked={setSignOnDemandLinked}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={opennedRequestSignOnDemandDialog}
        toggleOpen={toggleRequestSignOnDemandDialog}
        handleYes={handleRequestSignOnDemand}
        title={intl.get('messages.signOnDemandRequest')}
        subtitle={
          selectedSignOnDemand.signOnDemand?.id
            ? intl.get('messages.confirmLinkSignOnDemand')
            : intl.get('messages.confirmRequestNewSignOnDemand')
        }
        maxWidth={'xl'}
        disableKeyUp={true}
      >
        {opennedRequestSignOnDemandDialog && (
          <SearchSignOnDemand
            selectedSignOnDemand={selectedSignOnDemand}
            setSelectedSignOnDemand={setSelectedSignOnDemand}
          />
        )}
      </ConfirmationDialog>
    </>
  )
}

export default VerticalTimelineItemHoverable
