import './index.css'

import { setGlobal } from 'reactn'
import ReactDOM from 'react-dom'

import AppWrapper from './components/AppWrapper'
import Home from './pages/Home'
import SignSentenceLobby from './pages/SignSentence/Lobby'
import SignSentenceMain from './pages/SignSentence/Main'
import VideoManagerVideo from './pages/VideoManager/Video'
import VideoManager from './pages/VideoManager/Main'
import SegmentVideo from './pages/SegmentVideo/Video'
import SegmentVideoHome from './pages/SegmentVideo'
import SegmentVideoContributions from './pages/SegmentVideo/Contributions'
import FindIdenticalSegmentsHome from './pages/FindIdenticalSegments'
import FindIdenticalSegments from './pages/FindIdenticalSegments/Segment'
import AnimationHome from './pages/Animation'
import Animation from './pages/Animation/Animate'
import ReviewAnimationHome from './pages/ReviewAnimation'
import ReviewAnimation from './pages/ReviewAnimation/Animation'
import ValidateTranslations from './pages/ValidateTranslations'
import NotFound from './pages/NotFound'
import Admin from './pages/Admin'
import Configuration from './pages/Configuration'
import { IAppConfig, IGlobalAttr } from 'collections'
import HTubeMain from './pages/HTube/Main'
import HTube from './pages/HTube/Sign'
import SignOnDemand from './pages/SignOnDemand'
import SignOnDemandRecordVideo from './pages/SignOnDemand/RecordVideo'
import SignOnDemandSegmentVideo from './pages/SignOnDemand/SegmentVideo'
import DetailSignOnDemand from './pages/SignOnDemand/DetailSignOnDemand'
import reportWebVitals from './reportWebVitals'
import UpdatePermissions from './pages/UpdatePermissions'
import MotionLearningRecord from './pages/Motion/Learning/NewRecordScreen'
import MotionLGPD from './pages/Motion/LGPDMotion'
import ProcessVideo from './pages/ProcessVideo'
import PhraseManager from './pages/PhraseManager'
import AbTestNmt from './pages/AbTestNmt'
import AbTestNmtResults from './pages/AbTestNmtResults'
import ManuallyTranslation from './pages/ManuallyTranslation'
import ManuallyTranslationMain from './pages/ManuallyTranslation/Translate'
import Registration from './pages/Registration'

export const routes = {
  home: '/',
  registration: '/registration',
  notFound: '/:rest*',
  signSentenceLobby: '/signSentence/lobby',
  videoManager: '/videoManager',
  videoManagerVideo: '/videoManager/video/:videoId',
  signSentenceMain: '/signSentence',
  tagASignHome: '/tagASign',
  tagASign: '/tagASign/:videoId',
  animationHome: '/animation',
  animation: '/animation/:signId',
  validateTranslations: '/validateTranslations',
  segmentASignHome: '/segmentASign',
  segmentASign: '/segmentASign/video/:videoId/:user',
  segmentASignContributions: '/segmentASign/contributions',
  findIdenticalSegmentsHome: '/findIdenticalSegments',
  findIdenticalSegments: '/findIdenticalSegments/:segmentQueueId',
  reviewAnimationHome: '/reviewAnimation',
  reviewAnimation: '/reviewAnimation/:signId',
  admin: '/admin',
  configuration: '/configuration',
  hTubeHome: '/hTube',
  hTube: '/hTube/:clusterId',
  signOnDemand: '/signOnDemand',
  signOnDemandRecordVideo: '/signOnDemand/video/:signOnDemandId',
  signOnDemandSegmentVideo: '/signOnDemand/segment/:signOnDemandId',
  signOnDemandDetail: '/signOnDemand/detail/:signOnDemandId',
  updatePermissions: '/updatePermissions',
  processVideo: '/processVideo',
  motionLearningRecord: '/motion',
  motionLGPD: '/motion/terms',
  phraseManager: '/phraseManager',
  abTestNmt: '/abTestNmt/:version',
  abTestNmtResults: '/abTestNmtResults',
  manuallyTranslation: '/manuallyTranslation',
  manuallyTranslationMain: '/manuallyTranslation/:id',
}

export const defaultConfig: IAppConfig = {
  frameRate: 30,
  minTagFramesDuration: 2,
}

setGlobal<IGlobalAttr>({
  appConfig: defaultConfig,
})

ReactDOM.render(
  <>
    <AppWrapper>
      <MotionLearningRecord path={routes.motionLearningRecord} />
      <MotionLGPD path={routes.motionLGPD} />
      <Home path={routes.home} />
      <SignSentenceMain path={routes.signSentenceMain} />
      <VideoManager path={routes.videoManager} />
      <VideoManagerVideo path={routes.videoManagerVideo} />
      <SignSentenceLobby path={routes.signSentenceLobby} />
      <SegmentVideoHome path={routes.segmentASignHome} />
      <SegmentVideo path={routes.segmentASign} />
      <SegmentVideoContributions path={routes.segmentASignContributions} />
      <FindIdenticalSegmentsHome path={routes.findIdenticalSegmentsHome} />
      <FindIdenticalSegments path={routes.findIdenticalSegments} />
      <AnimationHome path={routes.animationHome} />
      <Animation path={routes.animation} />
      <ReviewAnimationHome path={routes.reviewAnimationHome} />
      <ReviewAnimation path={routes.reviewAnimation} />
      <ValidateTranslations path={routes.validateTranslations} />
      <Admin path={routes.admin} />
      <Configuration path={routes.configuration} />
      <HTubeMain path={routes.hTubeHome} />
      <HTube path={routes.hTube} />
      <SignOnDemand path={routes.signOnDemand} />
      <SignOnDemandRecordVideo path={routes.signOnDemandRecordVideo} />
      <SignOnDemandSegmentVideo path={routes.signOnDemandSegmentVideo} />
      <DetailSignOnDemand path={routes.signOnDemandDetail} />
      <ProcessVideo path={routes.processVideo} />
      <PhraseManager path={routes.phraseManager} />
      <UpdatePermissions path={routes.updatePermissions} />
      <AbTestNmt path={routes.abTestNmt} />
      <AbTestNmtResults path={routes.abTestNmtResults} />
      <ManuallyTranslation path={routes.manuallyTranslation} />
      <ManuallyTranslationMain path={routes.manuallyTranslationMain} />
      <Registration path={routes.registration} />
      <NotFound path={routes.notFound} />
    </AppWrapper>
  </>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
