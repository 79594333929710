import { useGlobal } from 'reactn'

import logger from '../services/logger'
import { IGlobalAttr, ITimelineRegion } from 'collections'

const { log } = logger('useTimelineRegions')

interface ITimelineTagsFns {
  /**
   * Remove todas as tags
   */
  clear: () => void
  /**
   * Adiciona uma nova tag à lista
   * @param tag tag que será adicionada a lista
   */
  add: (tag: ITimelineRegion) => void
  /**
   * Remove uma tag especifica da lista
   * @param index Indice da tag que iremos remover
   */
  remove: (index: string) => void
  /**
   * Atualiza os dados de uma tag
   * @param index indice da tag na lista
   * @param tag novos dados que serão aplicados a esta tag
   */
  update: (index: string, tag: ITimelineRegion) => void
  /**
   * Substitui a lista de tags por uma nova lista
   * @param tags lista de tags que substituirá a lista
   */
  set: (tags: ITimelineRegion[]) => void
}

type TimelineTagsHookTuple = [ITimelineRegion[], ITimelineTagsFns]

/**
 * Armazena e normaliza as tags da linha do tempo
 */
const useTimelineRegions = (): TimelineTagsHookTuple => {
  const [timelineTags, setTimelineTags] = useGlobal<IGlobalAttr, 'timelineTags'>('timelineTags')

  /**
   * Atualiza os dados de uma tag
   * @param id identificador da tag na lista
   * @param tag novos dados que serão aplicados a esta tag
   */
  const update = (id: string, tag: ITimelineRegion) => {
    const tags = [...(timelineTags || [])]
    const index = tags.findIndex((el) => el.id == id)
    if (index > -1) tags[index] = tag
    setTimelineTags(tags)

    log(`tag modificada entre ${tag.startFrame} e ${tag.endFrame}`)
  }

  /**
   * Remove uma tag especifica da lista
   * @param id identificador da tag que iremos remover
   */
  const remove = (id: string) => {
    const tags = [...(timelineTags || [])].filter((a) => a.id != id)
    setTimelineTags(tags)
    log(`${id} removida`)
  }

  /**
   * Adiciona uma nova tag à lista
   * @param tag tag que será adicionada a lista
   */
  const add = (tag: ITimelineRegion) => {
    const tags = [...(timelineTags || []), tag]

    setTimelineTags(tags)

    log(`Nova tag criada entre ${tag.startFrame} e ${tag.endFrame}`)
  }

  /**
   * Remove todas as tags
   */
  const clear = () => {
    setTimelineTags([])

    log('resetado')
  }

  const set = (tags: ITimelineRegion[]) => {
    setTimelineTags(tags)

    log(`Tags criadas`)
  }

  const fns: ITimelineTagsFns = {
    add,
    remove,
    update,
    clear,
    set,
  }

  return [timelineTags || [], fns]
}

export default useTimelineRegions
