import React, { useState, useEffect, useCallback } from 'react'

import UsersTablePure from './Pure'
import { IUserSecret, IUser, WorkspaceId, IWorkspace, IUserInfo, IUsersTable } from 'collections'
import { useFirestore } from 'reactfire'
import { getUserByIdFunction } from '../../../services/firebase'
import { DocumentSnapshot } from 'collections'
import Preloader from '../../../components/Preloader'
import UserModal from './UserModal'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { collection, doc, getDocs } from '@firebase/firestore'

interface IDataUserResponse {
  data: {
    user: IUserInfo
  }
}

const UsersTable: React.FC<IUsersTable> = ({ oralLanguages, signLanguages }) => {
  const firestore = useFirestore()
  const workspaceCollection = collection(firestore, 'workspaces')
  const [userSecrets, setUserSecrets] = useState<IUserSecret[]>([])
  const [allUsers, setAllUsers] = useState<IUserSecret[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [workspaceSelected, setWorkspaceSelected] = useState<string>('ALL')
  const [roleSelected, setRoleSelected] = useState<string>('ALL')
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>()
  const [userModalOpened, setUserModalOpened] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<IUserSecret>()
  const [isLoading, setIsLoading] = useState(true)
  const showSnackbar = useSnackbar()

  const getUserInfo = async (id: string) => {
    let userResponse: IDataUserResponse = {
      data: {
        user: {},
      },
    }
    try {
      userResponse = await getUserByIdFunction({ userId: id })
    } catch (e) {
      console.log((e as TypeError | RangeError | EvalError).message)
      showSnackbar((e as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
    return userResponse
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSetFilterList = () => {
    /** Filtra usuários */
    setIsLoading(true)
    /** Primeiro realiza o filtro de acordo com o que foi digitado no campo de pesquisa */
    let _userSecrets = allUsers.filter(
      (user: IUserSecret) =>
        !searchText ||
        user.displayName?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchText.toLowerCase()),
    )
    /** Caso o workspace seja diferente de 'ALL' filtra também o workspace selecionado */
    if (workspaceSelected != 'ALL') {
      _userSecrets = _userSecrets.filter((user: IUserSecret) => user.workspaceId == workspaceSelected)
    }

    /** Caso a role seja diferente de 'ALL' filtra também a role selecionada */
    if (roleSelected != 'ALL') {
      _userSecrets = _userSecrets.filter((user: IUserSecret) => user.role == roleSelected)
    }
    setUserSecrets(_userSecrets)
    setIsLoading(false)
  }

  const getWorkspaces = async () => {
    const _workspaces: IWorkspace[] = []
    const workspaceRef = await getDocs(workspaceCollection)
    workspaceRef.docs.map((workspace: DocumentSnapshot) => {
      const _workspace = {
        ...(workspace.data() as IWorkspace),
        id: workspace.id,
      }
      _workspaces.push(_workspace)
    })
    setWorkspaces(_workspaces)
  }

  useEffect(() => {
    onSetFilterList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceSelected, searchText, roleSelected])

  const onSearchUser = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const text = (event.target && event.target.value) || ''
    setSearchText(text)
  }, [])

  const onChangeWorkspace = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const workspace = event.target.value as WorkspaceId
    setWorkspaceSelected(workspace)
  }

  const onChangeRole = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const role = event.target.value as string
    setRoleSelected(role)
  }

  const getUsers = async () => {
    setIsLoading(true)
    /** Inicia o array dos usuários */
    const _userSecrets: IUserSecret[] = []
    /** Resgata os dados da coleção users do firestore */
    const userSecretsCollection = await getDocs(collection(firestore, 'users'))
    await Promise.all(
      userSecretsCollection.docs.map(async (userSnapshot) => {
        /** Percorre usuários para resgatar os dados "DisplayName e email" do auth do firebase */
        const { displayName, email } = (await getUserInfo(userSnapshot.id)).data.user
        const userData = userSnapshot.data() as IUser
        const userSecret: IUserSecret = {
          ...userData,
          uid: userSnapshot.id,
          displayName: displayName || 'Anonymous',
          email,
          workspaceId: userSnapshot.data().workspace.id,
          role: userData.role,
        }
        _userSecrets.push(userSecret)
      }),
    )
    /** adiciona ao estado, ordenando pelo displayName */
    setUserSecrets(
      _userSecrets.sort((a, b) => {
        return (a.displayName || '').toLowerCase() > (b.displayName || '').toLowerCase()
          ? 1
          : (b.displayName?.toLowerCase() || '') > (a.displayName || '').toLowerCase()
          ? -1
          : 0
      }),
    )
    /** Gravo a consulta para que possa ser manipulada em array sem
     * precisar consultar no banco de dados novamente */
    setAllUsers(_userSecrets)
    setIsLoading(false)
  }

  useEffect(() => {
    onSetFilterList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers])

  useEffect(() => {
    getUsers()
    getWorkspaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditUser = (user: IUserSecret) => {
    setSelectedUser(user)
    setUserModalOpened(!userModalOpened)
  }

  const handleCreateUser = () => {
    const _user: IUserSecret = {
      workspaceId: WorkspaceId.asl,
      workspace: doc(firestore, 'workspaces', 'HT-ASL'),
      modules: [],
      oralLanguageId: 'eng',
      signLanguageId: 'ase',
    }
    setSelectedUser(_user)
    setUserModalOpened(!userModalOpened)
  }

  return (
    <>
      {isLoading && <Preloader asBlock text="Loading..."></Preloader>}
      {!isLoading && (
        <>
          <UsersTablePure
            onSearchUser={onSearchUser}
            searchText={searchText}
            userSecrets={userSecrets}
            onChangeWorkspace={onChangeWorkspace}
            workspaceSelected={workspaceSelected}
            roleSelected={roleSelected}
            onChangeRole={onChangeRole}
            workspaces={workspaces || []}
            handleEditUser={handleEditUser}
            handleCreateUser={handleCreateUser}
          />
          <UserModal
            isOpen={userModalOpened}
            getUsers={getUsers}
            setIsOpen={setUserModalOpened}
            userSelected={selectedUser}
            workspaces={workspaces || []}
            oralLanguages={oralLanguages}
            signLanguages={signLanguages}
          />
        </>
      )}
    </>
  )
}

export default UsersTable
