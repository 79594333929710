import React, { useState } from 'react'

import ApplicationDialogPure from '../Pure'
import useDialog from '../../../services/hooks/useDialog'
import { feedbackFunction } from '../../../services/firebase'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import useIntl from '../../../hooks/useIntl'

const FeedbackTagVideoDialog = (): JSX.Element => {
  const [open, toggleOpen] = useDialog('feedbackTagVideo')
  const [feedback, setFeedback] = useState('')
  const [sending, setSending] = useState(false)
  const showSnackbar = useSnackbar()
  const fsUser = useFsUserDocData()
  const intl = useIntl()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value)
  }
  const sendFeedback = async () => {
    try {
      const workspace = fsUser?.workspace.id || '<not found>'

      const text = `
      ${intl.get('components.feedbackTagDialog.mainText.line1')}

      Workspace: ${intl.get('components.feedbackTagDialog.mainText.line2', {
        workspace: workspace,
      })}
      Motivo: ${intl.get('components.feedbackTagDialog.mainText.line3', {
        feedback: feedback,
      })}`

      setSending(true)
      await feedbackFunction({ text })
      setSending(false)
      showSnackbar(intl.get('messages.thankYou'), { variant: 'success' })
      toggleOpen()
    } catch (e) {
      showSnackbar(intl.get('messages.feedbackNotSent'), { variant: 'error' })
    }
  }
  return (
    <ApplicationDialogPure
      title={intl.get('components.feedbackTagDialog.newVideo')}
      labelledby="Get a new video dialog"
      open={open}
      options={{
        subtitle: intl.get('components.feedbackTagDialog.cantTag'),
        feedback: feedback,
        isPending: sending,
        labelTextFiel: intl.get('components.feedbackTagDialog.whatsWrong'),
      }}
      toggleOpen={toggleOpen}
      send={sendFeedback}
      handleChange={handleChange}
    />
  )
}

export default FeedbackTagVideoDialog
