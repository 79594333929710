import { ISign, ISignFlags, IUpdateSignState } from './types/sign'
import {
  addDoc,
  setDoc,
  getDoc,
  DocumentReference,
  CollectionReference,
  getDocs,
  query,
  where,
  limit,
  orderBy,
  collection,
  query as firestoreQuery,
  documentId,
} from 'firebase/firestore'

import { IUser } from './types/user'
import { DocumentData, DocumentReferenceGeneric, Query, serverTimestamp, Timestamp } from './types/firebase'
import { addFirestoreDocument, getCollectionReference, updateFirestoreDocument } from './Base'

const createUpdateFunction = async (ref: DocumentReferenceGeneric, signData: Partial<ISign>) => {
  return await updateFirestoreDocument(ref, signData)
}

export const updateSign = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, signData: Partial<ISign>) => {
  return await addDoc(ref, signData)
}

export const addSign = createAddFunction

const createSetFunction = async (ref: DocumentReference, signData: Partial<ISign>) => {
  return await setDoc(ref, signData)
}

export const setSign = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getSign = createGetFunction

const initialSignFlags: ISignFlags = {
  isAnimated: false,
  isCropped: false,
  isFingerspell: false,
  isExtractedKeypoints: false,
  isStudioFileRecorded: false,
  isStudioFileRecording: {
    lastUpdate: null,
    user: null,
    value: false,
  },
  isAnimating: {
    lastUpdate: null,
    user: null,
    value: false,
  },
  isCropping: {
    lastUpdate: null,
    taskName: null,
    value: false,
  },
  isExtractingKeypoints: {
    lastUpdate: null,
    taskName: null,
    value: false,
  },
}

/** Retorna dados iniciais do novo sinal */
export const getBaseSign = (
  fsUser: IUser,
  fsUserRef: DocumentReferenceGeneric,
  signData: Pick<
    ISign,
    | 'glosa'
    | 'origin'
    | 'promoted'
    | 'numberOfSegments'
    | 'primarySegment'
    | 'relatedWords'
    | 'definition'
    | 'searchReference'
    | 'pieceOfSign'
    | 'regionOfUse'
  >,
) => {
  const sign: ISign = {
    createdAt: serverTimestamp() as Timestamp,
    oralLanguageId: fsUser.oralLanguageId,
    signLanguageId: fsUser.signLanguageId,
    createdBy: fsUserRef,
    currentAnimation: null,
    popularity: 0,
    corpusPopularity: 0,
    translationPopularity: 0,
    searchTerms: [],
    firstLetter: [],
    stars: 0,
    currentAnimationHasErrors: null,
    currentAnimationReviewedAt: null,
    words: [],
    userPrimarySegment: false,
    video: null,
    ...initialSignFlags,
    ...signData,
    warning: false,
    frequency: 0,
    _state: signData.promoted ? 'PROMOTED' : 'CLUSTERED',
    unitedWithSigns: [],
  }

  return sign
}

/**
 * Salva um novo sinal no firestore
 * @param workspaceRef - Referencia do workspace onde salvaremos o sinal
 * @param fsUser - Dados do usuario no firestore
 * @param fsUserRef - Referencia do usuario que está criando este sinal
 * @param signId - Identificador do sinal
 * @param signData - Dados do sinal sem a data de criação
 */
export const createSign = async (
  workspaceRef: DocumentReferenceGeneric,
  fsUser: IUser,
  fsUserRef: DocumentReferenceGeneric,
  signData: Pick<
    ISign,
    | 'glosa'
    | 'origin'
    | 'promoted'
    | 'numberOfSegments'
    | 'primarySegment'
    | 'relatedWords'
    | 'definition'
    | 'searchReference'
    | 'pieceOfSign'
    | 'regionOfUse'
  >,
) => {
  const sign = getBaseSign(fsUser, fsUserRef, signData)

  return await addFirestoreDocument(getCollectionReference(workspaceRef, 'signs'), sign)
}

export const getSignReviewAnimationRef = async (
  workspaceRef: DocumentReferenceGeneric,
): Promise<DocumentReference<DocumentData> | null> => {
  const signsRef = getCollectionReference(workspaceRef, 'signs')
  // A busca por ids que sejam maior que randomId pode ser nula,
  // então fazemos duas buscas e logo em seguida fazemos concatenamos tudo
  const result = await getDocs(
    query(
      signsRef as Query<DocumentData>,
      where('currentAnimationHasErrors', '==', null),
      where('_state', '==', 'ANIMATED'),
      orderBy('corpusPopularity', 'desc'),
      limit(100),
    ),
  )

  const index = Math.floor(Math.random() * result.docs.length)

  return result.docs.length > 0 ? result.docs[index].ref : null
}

export const updateSignState = async (data: IUpdateSignState) => {
  if (data.signData && data.signData.isAnimated) {
    // STATE = 'ANIMATED'
    await updateSign(data.signRef, { _state: 'ANIMATED' })
  } else if (data.signData?.promoted) {
    // STATE = 'PROMOTED'
    await updateSign(data.signRef, { _state: 'PROMOTED' })
  }
}

export const getSignsBatch = async (
  workspaceRef: DocumentReference,
  signsIds: string[],
): Promise<Record<string, ISign>> => {
  const result: Record<string, ISign> = {}

  const batch: string[][] = []
  const batchNumber = 9
  for (let i = 0; i < signsIds.length; i = i + batchNumber) {
    batch.push(signsIds.slice(i, i + batchNumber))
  }

  for (const signs of batch) {
    const signsQuery = firestoreQuery(collection(workspaceRef, 'signs'), where(documentId(), 'in', signs))
    const signsSnapshot = await getDocs(signsQuery)

    signsSnapshot.docs.forEach((signSnapshot) => {
      result[signSnapshot.id] = signSnapshot.data() as ISign
    })
  }
  return result
}
