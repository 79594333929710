import { Button, createStyles, Grid, makeStyles, Typography, Theme } from '@material-ui/core'
import React, { useEffect } from 'react'
import LayoutMotion from '../../../components/LayoutMotion'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { motionColors } from '../../../styles'
import { format } from 'date-fns'
import { useLocation } from 'wouter'
import { routes } from '../../../community'

interface ILGPDProps {
  path?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lgpdTitle: {
      color: '#333',
      fontWeight: 'bold',
    },
    gridText: {
      gap: '10px',
      display: 'flex',
      flexDirection: 'column',
    },
    gridBody: {
      gap: '20px',
      display: 'flex',
      flexDirection: 'column',
      color: '#666',
      textAlign: 'justify',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
        margin: '50px auto 0px',
        '& button': {
          width: '250px',
          margin: '50px auto',
        },
      },
      [theme.breakpoints.up('xl')]: {
        width: '90%',
        margin: '25px auto 0px',
        '& button': {
          width: '250px',
          margin: '50px auto',
        },
      },
    },
  }),
)
// 03/07/2022 -> 7 de março de 2022
const documentUpdatedAt = new Date('03/07/2022')

const LGPDMotion: React.FC<ILGPDProps> = () => {
  const classes = useStyles()
  const { setLocalStorageValue, getLocalStorageValue } = useLocalStorage()
  const setLocation = useLocation()[1]

  const handleAcceptLGPD = () => {
    const lgpdValue = { accepted: true, dateAccepted: new Date() }
    setLocalStorageValue('lgpd', JSON.stringify(lgpdValue))
    setLocation(routes.motionLearningRecord)
  }
  useEffect(() => {
    const lgpd = getLocalStorageValue('lgpd')
    if (lgpd) {
      const jsonLGPD = JSON.parse(lgpd)
      if (new Date(jsonLGPD['dateAccepted']) > documentUpdatedAt) {
        setLocation(routes.motionLearningRecord)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutMotion>
      <Grid className={classes.gridBody}>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="h5">
            Termos de uso
          </Typography>
          <Typography variant="body2">Última atualização: {format(documentUpdatedAt, 'dd/MM/yyyy')}</Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography variant="body2">
            Ao acessar e usar o aplicativo Hand Talk, você automaticamente está aceitando os termos e condições a
            seguir, além de qualquer mudança ou atualização futura referente aos seus direitos de uso para utilização do
            serviço.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            1. Condições de Uso
          </Typography>
          <Typography variant="body2">
            Os termos aqui descritos são aplicados somente ao aplicativo Hand Talk. Alterações ou correções poderão ser
            realizadas nos termos de uso, e caso aconteça, você será notificado. Concordando com as mudanças, você
            poderá continuar usufruindo normalmente o serviço.
          </Typography>
          <Typography variant="body2">
            Se parte ou totalidade dos Termos de Uso for nula ou sofrer alteração, todas as demais disposições
            permanecerão em pleno vigor e efeito.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            2. O Aplicativo
          </Typography>
          <Typography variant="body2">
            2.1. O aplicativo é gratuito. Após efetuar o download, você poderá começar a usá-lo e a qualquer momento
            poderá desinstalá-lo.
          </Typography>
          <Typography variant="body2">
            2.2. Para con seguir usar o aplicativo é necessário um dispositivo, smartphone ou tablet, compatível com as
            requisições mínimas de processamento, tela e sistema operacional.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            3. Responsabilidades
          </Typography>
          <Typography variant="body2">
            3.1. A Hand Talk não se responsabiliza por algum dano que seja causado a você ou a terceiros pela utilização
            do aplicativo, pois a empresa disponibiliza apenas uma ferramenta de tradução, sendo que o conteúdo
            reproduzido por nossos tradutores virtuais é realizado de acordo com o que o usuário insere. Sendo assim, a
            marca e imagem da Hand Talk, bem como a dos patrocinadores, não possuirão nenhuma relação com as informações
            reproduzidas.
          </Typography>
          <Typography variant="body2">
            3.2. Todo o conteúdo inserido por você é de sua inteira responsabilidade.
          </Typography>
          <Typography variant="body2">
            3.3. As traduções geradas pelo aplicativo não poderão ser utilizadas por terceiros para fins comerciais ou
            eleitorais.
          </Typography>
          <Typography variant="body2">
            3.4. Também fica proibido o uso das traduções para interpretação e/ou transmissão de conteúdos de caráter
            ilegal (como racismo, difamação, obsceno, ameaça etc), que você não tem direito para disponibilizar ou que
            infrinja alguma imagem, marca ou direito autoral de qualquer pessoa física, jurídica ou entidade.
          </Typography>
          <Typography variant="body2">
            3.5. O uso das traduções automáticas não é autorizado para aplicação em conteúdos de vídeos ou transmissões
            online. Nesses tipos de materiais podem haver diálogos e ilustrações que exijam uma sincronização entre o
            que está sendo dito e a sinalização, coisa que a tradução automática não consegue realizar ainda. Para a
            finalidade acima citada, a Hand Talk recomenda que utilizem os serviços de um intérprete humano.
          </Typography>
          <Typography variant="body2">
            3.6. Ainda, a Hand Talk não será responsabilizada, sob quaisquer circunstâncias, por eventuais perdas e
            danos relacionados ao uso do aplicativo.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            4. Disponibilidade do serviço
          </Typography>
          <Typography variant="body2">
            4.1. O aplicativo Hand Talk oferece o serviço de tradução online e automática para língua de sinais
            gratuitamente e com a melhor qualidade possível, porém não se declara livre de erros em casos remotos.
          </Typography>
          <Typography variant="body2">
            4.2. O uso do serviço ocorrerá de maneira ininterrupta, contudo está passível a indisponibilidades
            provocadas por plataformas de terceiros necessárias para a prestação do serviço.
          </Typography>
          <Typography variant="body2">
            4.3. A Hand Talk reserva-se ao direito de, a qualquer momento, modificar ou suspender de vez ou
            temporariamente o aplicativo.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            5. Coleta e tratamento de informações
          </Typography>
          <Typography variant="body2">
            As informações coletadas através do aplicativo são utilizadas exclusivamente pela Hand Talk para auxiliar na
            otimização da qualidade do serviço prestado. Elas são analisadas para determinar o que é mais eficaz no
            aplicativo, para identificar meios de melhorá-lo e determinar como customizá-lo para atender a demanda dos
            usuários. Os dados, também poderão ser utilizados para outros fins de pesquisas e estudos para futuras
            aplicações da Hand Talk.
          </Typography>
          <Typography variant="body2">
            Para saber mais sobre o uso das informações, acesse nossa Política de Privacidade nossa.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            6. Propriedade intelectual e direitos da marca
          </Typography>
          <Typography variant="body2">
            6.1. Os elementos e ferramentas do aplicativo são de autoria da Hand Talk, e sua utilização não confere
            quaisquer direitos relativos à propriedade intelectual.
          </Typography>
          <Typography variant="body2">
            6.2. O uso do aplicativo para fins comerciais ou eleitorais não poderá ser feito sem prévia autorização.
            Você também não poderá vincular a imagem e marca da Hand Talk e seus patrocinadores a qualquer outra marca.
          </Typography>
          <Typography variant="body2">
            6.3. Os dados gerados em conexão com o uso do app serão de propriedade da Hand Talk.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            7. Hugo Ensina
          </Typography>
          <Typography variant="body2">
            O aplicativo utiliza os serviços do YouTube para reprodução dos vídeos da seção Hugo Ensina e está de acordo
            com os Termos de Serviço (ToS) da plataforma.
          </Typography>
        </Grid>
        <Grid className={classes.gridText}>
          <Typography className={classes.lgpdTitle} variant="body2">
            8. Disposições Finais
          </Typography>
          <Typography variant="body2">
            Estes termos são regidas pelas leis da República Federativa do Brasil, e qualquer ação ou processo judicial
            relativo a este aplicativo serão regidos por ela. Para qualquer controvérsia ou queixa oriunda da utilização
            do aplicativo Hand Talk ou aos termos de uso, fica eleito o Foro da Comarca de Maceió, Estado de Alagoas,
            sob a expressa renúncia de qualquer outro, por mais privilegiado que seja.
          </Typography>
        </Grid>
        <Button
          variant="contained"
          className={classes.lgpdTitle}
          style={{
            color: 'white',
            backgroundColor: motionColors.brandColors.secundary.main,
            borderRadius: '12px',
          }}
          onClick={() => {
            handleAcceptLGPD()
          }}
        >
          Concordo
        </Button>
      </Grid>
    </LayoutMotion>
  )
}

export default LGPDMotion
