import React from 'react'
import Chip from '@material-ui/core/Chip'
import { red } from '@material-ui/core/colors'
import { useSortable } from '@dnd-kit/sortable'
import { IManuallyTranslationTarget } from 'collections'
import { CSS } from '@dnd-kit/utilities'

interface INMTSuggestionItem {
  item: IManuallyTranslationTarget

  onGetSignByClick: (item: IManuallyTranslationTarget) => void
}

export const NMTSuggestionItem: React.FC<INMTSuggestionItem> = ({ item, onGetSignByClick }) => {
  const id = item.draggableId || ''
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
    transition: {
      duration: 50, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    transition,
  }

  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    // Somente double click
    if (ev.detail === 2) {
      // Leva item para pesquisa
      onGetSignByClick(item)
    }
  }

  return (
    <div key={item.draggableId} style={style} {...attributes} {...listeners} ref={setNodeRef}>
      <Chip
        label={item.word}
        onClick={handleClick}
        style={{
          backgroundColor: !item.animationExported ? red['500'] : item.type === 'fingerspell' ? '#d46d2a' : '#2296f3',
          color: 'white',
          minWidth: '120px',
        }}
      />
    </div>
  )
}
