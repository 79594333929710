import { Holistic, InputImage } from '@mediapipe/holistic'
import React, { useEffect, useRef, useState } from 'react'

/**
 * Um hook bem pequeno para uso do camera_utils do media pipe.
 * Ajuda a diminuir o código dentro do componente.
 * @see https://cdn.jsdelivr.net/npm/@mediapipe/camera_utils@0.3.1631210127/index.d.ts
 * @param {*} videoRef
 * @returns
 */

interface IUseCameraUtilsProps {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>
  holistic: Holistic
  width?: number
  height?: number
  cameraEnabled: boolean
}

const constraints = {
  audio: false,
  video: {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    deviceId: { ideal: '' },
  },
}

let cameraStream: MediaStream | null = null

const useCameraUtils = ({ videoRef, holistic, cameraEnabled }: IUseCameraUtilsProps) => {
  const animReqsIds = useRef<number[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const call = async () => {
      try {
        // TODO - Seleção de câmera
        // const cameras = await getCameras()

        console.log(`CameraEnabled mudou para ${cameraEnabled}`)

        if (cameraEnabled && holistic && videoRef.current) {
          setIsLoading(true)

          if (!navigator.mediaDevices && !(navigator.mediaDevices as any).getUserMedia) {
            navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia
            if (!navigator.userMedia) {
              alert('Please Update or Use Different Browser')
              return
            }
            cameraStream = await navigator.userMedia(constraints)
          } else {
            cameraStream = await navigator.mediaDevices.getUserMedia(constraints)
          }

          videoRef.current.srcObject = cameraStream

          videoRef.current.onloadedmetadata = () => {
            videoRef.current?.play()

            const reqId = requestAnimationFrame(drawVideoFrame)
            // Salvamos o id do request da animação para ao finalizar camera cancelá-los
            //cancelAnimationFrame(reqId);
            animReqsIds.current.push(reqId)
          }
        } else {
          // para a camera
          stopCamera()
        }
      } catch (err) {
        const error = err as Error
        console.log(error.message)
      }
    }

    call()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraEnabled])

  /**
   * Para a câmera e cancela as animações criadas dos frames
   * Criamos essa função devido ao camera_utils do mediapipe não encerrar animations criadas, assim ficando em loop
   * https://github.com/google/mediapipe/issues/3775
   */
  const stopCamera = () => {
    if (cameraStream) {
      // Parando camera
      const tracks = cameraStream.getTracks()
      tracks.forEach((track) => track.stop())
      animReqsIds.current.forEach((el) => {
        cancelAnimationFrame(el)
      })
    }
  }

  const drawVideoFrame = () => {
    if (videoRef.current && holistic) {
      holistic.send({ image: videoRef.current as InputImage }).then(() => {
        setIsLoading(false)
        const reqId = requestAnimationFrame(drawVideoFrame)
        animReqsIds.current.push(reqId)
      })
    }
  }

  return { cameraEnabled, isLoading }
}

export default useCameraUtils
