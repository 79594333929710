import React, { FC } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import FeedbackIcon from '@material-ui/icons/Feedback'
import SettingsIcon from '@material-ui/icons/Settings'
import Divider from '@material-ui/core/Divider'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import HelpIcon from '@material-ui/icons/Help'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import DescriptionIcon from '@material-ui/icons/Description'
import Box from '@material-ui/core/Box'

const version = process.env.REACT_APP_VERSION || '?'

interface IUserMenuProps {
  userAvatar: JSX.Element
  selectTheme: JSX.Element
  workspaceSelect: JSX.Element
  displayName: string
  email: string
  anchorEl?: HTMLElement
  toggleMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onAvatarClick?: () => void
  onFeedbackClick?: () => void
  onLogoutClick?: () => void
  onAdminClick?: () => void
  onConfigurationClick?: () => void
  canShowAdmin: boolean
  textConfiguration: string
  textSendFeedback: string
  textLogOut: string
  textTermsOfService: string
  textHelp: string
  onTermsOfServiceClick: () => void
  onHelpClick: () => void
}

export const UserMenuPure: FC<IUserMenuProps> = ({
  userAvatar,
  workspaceSelect,
  selectTheme,
  displayName,
  email,
  onAvatarClick,
  onFeedbackClick,
  onLogoutClick,
  anchorEl,
  toggleMenu,
  onAdminClick,
  onConfigurationClick,
  canShowAdmin,
  textConfiguration,
  textSendFeedback,
  textLogOut,
  textTermsOfService,
  textHelp,
  onTermsOfServiceClick,
  onHelpClick,
}) => {
  return (
    <Menu id="user-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={toggleMenu} style={{ zIndex: 2001 }}>
      <Box width={300}>
        {/* </Menu> */}
        <MenuItem onClick={onAvatarClick}>
          <ListItemAvatar>{userAvatar}</ListItemAvatar>
          <ListItemText primary={displayName} secondary={email} />
        </MenuItem>

        {selectTheme}
        {workspaceSelect}

        {/*  Botão de Feedback */}
        <MenuItem onClick={onFeedbackClick}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary={textSendFeedback} />
        </MenuItem>

        {/*  Botão de Configurações */}
        <MenuItem onClick={onConfigurationClick}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={textConfiguration} />
        </MenuItem>

        {/*  Botão de admin */}
        {canShowAdmin && (
          <MenuItem onClick={onAdminClick}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </MenuItem>
        )}

        {/* Botão de Termos de serviço */}
        <MenuItem
          onClick={() => {
            onTermsOfServiceClick()
          }}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={textTermsOfService} />
        </MenuItem>

        {/* Botão de Ajuda do Community */}
        <MenuItem onClick={onHelpClick}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={textHelp} />
        </MenuItem>

        {/* Botão de Logout */}
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={textLogOut} />
        </MenuItem>

        {/*  Versão */}
        <Divider />
        <ListItem>
          <ListItemText secondary={`v${version}`} />
        </ListItem>
      </Box>
    </Menu>
  )
}

export default UserMenuPure
