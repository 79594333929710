import { DatabaseReference, Query, DataSnapshot, onValue } from '@firebase/database'

interface Error {
  stack?: string | undefined
}

export const getData = (ref: DatabaseReference | Query): Promise<DataSnapshot> => {
  return new Promise((resolve, reject) => {
    function onError(error: Error) {
      reject(error)
    }

    function onData(snap: DataSnapshot) {
      resolve(snap)
    }

    onValue(ref, onData, onError, { onlyOnce: true })
  })
}
