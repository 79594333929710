import React from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ExampleImage from './ExampleImage'
import useIntl from '../../../../hooks/useIntl'
import badLighting1 from '../../../../images/badLighting1.jpg'
import badLighting2 from '../../../../images/badLighting2.jpg'
import badLighting3 from '../../../../images/badLighting3.jpg'
import goodLighting from '../../../../images/goodLighting.jpg'

const LightingStep: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <Typography>{intl && intl.get('pages.signSentence.lobby.lightingStep.description')}</Typography>
      <Grid container justifyContent="space-between">
        <ExampleImage
          imageUrl={badLighting1}
          imageAlt={intl.get('pages.signSentence.lobby.lightingStep.grid.badLighting1')}
        />
        <ExampleImage
          imageUrl={badLighting2}
          imageAlt={intl.get('pages.signSentence.lobby.lightingStep.grid.badLighting2')}
        />
        <ExampleImage
          imageUrl={badLighting3}
          imageAlt={intl.get('pages.signSentence.lobby.lightingStep.grid.badLighting3')}
        />
        <ExampleImage
          imageUrl={goodLighting}
          imageAlt={intl.get('pages.signSentence.lobby.lightingStep.grid.goodLighting')}
          isGood
        />
      </Grid>
    </>
  )
}

export default LightingStep
