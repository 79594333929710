import { ref } from '@firebase/database'
import { accentRemove, ISentenceData, IUser, preProcessSentenceToRecord } from 'collections'
import { Database, set } from 'firebase/database'

export const getBasePhraseToRecordVideoPriority = (sentenceData: Partial<ISentenceData>) => {
  const newSentence: ISentenceData = {
    priority: sentenceData.priority || 10.0, // adicionar rotina para calculo correto da prioridade
    sentence: sentenceData.sentence || '',
    sentenceOrigin: accentRemove(sentenceData.sentenceOrigin?.toUpperCase() || 'INTERNAL'), // origem padrão de inclusão
    sentenceCategory: sentenceData.sentenceCategory || ['undefined'], // incluir categorias da frase
    isValidated: sentenceData.isValidated || false, // flag de validação
    corpusGroup: sentenceData.corpusGroup || 'TRAIN', // grupo do corpus padrão 'TRAIN'
    clientId: sentenceData.clientId || 'HT', // Id do cliente
    isRecording: sentenceData.isRecording || false, // flag para indicar que a frase está sendo gravada
    needResearch: sentenceData.needResearch || false, // flag para indicar que a frase precisa de pesquisa
    isRepeated: sentenceData?.isRepeated || false,
    userRecording: sentenceData.userRecording || '', // usuário que está gravando a frase
    isExternal: sentenceData.isExternal || false, // flag que indica se é uma contribuição externa
  }
  return newSentence
}

export const setPhraseToRecordVideoPriority = (
  database: Database,
  fsUser: IUser,
  sentenceData: Partial<ISentenceData>,
) => {
  if (!sentenceData.sentence) return
  const md5Sentence = preProcessSentenceToRecord(sentenceData.sentence, fsUser.workspace.id)
  const sentenceRef = ref(
    database,
    `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${md5Sentence}`,
  )

  const newSentence: ISentenceData = getBasePhraseToRecordVideoPriority(sentenceData)

  set(sentenceRef, newSentence)
  return md5Sentence
}
