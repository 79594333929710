import React, { createContext, useContext, useCallback, useEffect } from 'react'
import { useUser, useAnalytics } from 'reactfire'

import logger from '../logger'
import { VideoId, UserId, WorkspaceId, CommunityModule, ValidateActions } from 'collections'
import { useFsUserDocData } from '../../hooks/useFsUser'

const { log } = logger('useFirebaseAnalytics')

interface IEvents {
  /**
   * Dispara o evento de criação de vídeo, o workspace será setado automaticamente
   * @params videoId - Id do video
   * @params duration - Duração do vídeo em frames
   * @params workedTime - Tempo em segundos que o interprete levou para gravar o video.
   * Do primeiro click no botão record até o clique no botão finish
   */
  videoCreatedEvent: (videoId: VideoId, duration: number) => void
  /**
   * Uma nova contribuição foi feita, iremos registrar os dados
   * @param communityModule Módulo do community onde houve uma contribuição
   * @param jobTime Quanto tempo em segundos levou para realizar esta contribuição
   */
  jobMeterEvent: (communityModule: CommunityModule, jobTime: number) => void
  /**
   * Dispara o evento para a ação: Tradução validada em `validateTranslation`.
   * @param sentence Sentença que foi traduzida
   * @param action Ação realizada pelo interprete
   */
  translationValidatedEvent: (sentence: string, action: ValidateActions) => void
  /**
   * Dispara o evento para a ação: Tradução validada em `validateTranslation` com o action correct
   * @param interval - Tempo em **segundos** entre duas contribuições com o action "correct"
   */
  validationCorrectIntervalEvent: (interval: number) => void
}

const initialEvents: IEvents = {
  videoCreatedEvent: () => null,
  jobMeterEvent: () => null,
  translationValidatedEvent: () => null,
  validationCorrectIntervalEvent: () => null,
}

const Context = createContext<IEvents>(initialEvents)

export const ProviderFirebaseAnalytics: React.FC = ({ children }) => {
  const fsUser = useFsUserDocData()
  const fbUser = useUser<firebase.default.User>().data

  const analytics = useAnalytics() as firebase.default.analytics.Analytics

  const setUserProperties = (userId: UserId, workspaceId: WorkspaceId) => {
    analytics.setUserId(userId)
    analytics.setUserProperties({
      workspace: workspaceId,
    })
    log('Propriedades de usuário definidas')
  }

  /**
   * Metodo principal, aqui iremos abstrair o logEvent do firebase analytics
   * e adicionar nossa camada de log
   */
  const logEvent = useCallback(
    async (eventName: string, properties: Record<string, string | number>) => {
      analytics.logEvent(eventName, properties)
      log(`Evento registrado ${eventName} ${JSON.stringify(properties)}`)
    },
    [analytics],
  )

  /**
   * Uma nova contribuição foi feita, iremos registrar os dados
   * @param communityModule Módulo do community onde houve uma contribuição
   * @param jobTime Quanto tempo em segundos levou para realizar esta contribuição
   */
  const jobMeterEvent = (communityModule: CommunityModule, jobTime: number) => {
    logEvent('JOB_METER', {
      workspaceId: fsUser?.workspace.id,
      jobTime,
      communityModule,
    })
  }

  /**
   * Dispara o evento para a ação: Tradução validada em `validateTranslation`.
   * @param sentence Sentença que foi traduzida
   * @param action Ação realizada pelo interprete
   */
  const translationValidatedEvent = (sentence: string, action: ValidateActions) =>
    logEvent('TRANSLATION_VALIDATED', {
      sentence,
      action,
      workspaceId: fsUser?.workspace.id,
    })

  /**
   * Dispara o evento para a ação: Vídeos do modulo de `signSentence` gravados e upload iniciado.
   * @param workTime - Tempo em **segundos** em que o interprete levou para gravar e iniciar o upload dos videos
   */
  const videoCreatedEvent = (videoId: VideoId, duration: number) =>
    logEvent('VIDEO_CREATED', {
      videoId,
      workspaceId: fsUser?.workspace.id,
      duration,
    })

  /**
   * Dispara o evento para a ação: Tradução validada em `validateTranslation` com o action correct
   * @param interval - Tempo em **segundos** entre duas contribuições com o action "correct"
   */
  const validationCorrectIntervalEvent = (interval: number) =>
    logEvent('VALIDATION_CORRECT_INTERVAL', {
      workspaceId: fsUser?.workspace.id,
      interval,
    })

  useEffect(() => {
    // Este hook só será montado quando o usuário estiver logado,
    // quando isso acontecer, definiremos os userProperties
    setUserProperties(fbUser?.uid || '', fsUser?.workspace.id as WorkspaceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const events: IEvents = {
    videoCreatedEvent,
    jobMeterEvent,
    translationValidatedEvent,
    validationCorrectIntervalEvent,
  }

  return <Context.Provider value={events}>{children}</Context.Provider>
}

/**
 * Gerenciador de eventos de analytics,
 * Iremos unificar todos os eventos neste script para evitar erros de ortografia,
 * duplicidade de eventos ou até mesmo insuficiencia de parametros
 */
export default (): IEvents => useContext(Context)
