import React, { createContext, useState, useContext, useEffect, useCallback } from 'react'
import logger from '../logger'
import { CommunityFeatures } from 'collections'

const { log } = logger('useFeatureToggle')

interface IFeatureToggleState {
  /** Mapa com o estado de cada feature */
  featuresMap: Record<CommunityFeatures, boolean>
}

/**
 * Estado inicial do mapa de features
 */
const initialFeaturesState: Record<CommunityFeatures, boolean> = {
  [CommunityFeatures.signSentenceContribViewer]: false,
  [CommunityFeatures.adminDashboard]: false,
  [CommunityFeatures.workspaceChange]: false,
}

const initialState: IFeatureToggleState = {
  featuresMap: initialFeaturesState,
}

interface IUpdateStateFunctions {
  setFeature: (feature: CommunityFeatures, value: boolean) => void
  reset: () => void
}

const initialFunctions: IUpdateStateFunctions = {
  setFeature: (): void => {
    /**/
  },
  reset: (): void => {
    /**/
  },
}

type Context = [IFeatureToggleState, IUpdateStateFunctions]

const Context = createContext<Context>([initialState, initialFunctions])

export const ProviderFeatureToggle: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState)
  /**
   * Assim que o componente for construido,
   * iremos carregar os padrões definidos anteriormente no localstorage
   */
  useEffect(() => {
    const data = localStorage.getItem('featuresMap') || '{}'
    const featuresMap = JSON.parse(data)
    setState((prev) => ({
      ...prev,
      featuresMap: {
        ...prev.featuresMap,
        ...featuresMap,
      },
    }))
  }, [])

  /**
   * Restaura os valores de features iniciais
   */
  const reset = () => {
    setState((prev) => ({
      ...prev,
      featuresMap: initialFeaturesState,
    }))
  }

  /**
   * Seta o valor de uma feature
   * @param feature - Nome da feature que será alterada
   * @param value - Valor da feature, quando verdadeiro ativa
   */
  const setFeature = useCallback(
    (feature: CommunityFeatures, value: boolean) => {
      log(`${feature} alterado para ${value}`)

      const featuresMap: Record<CommunityFeatures, boolean> = {
        ...state.featuresMap,
        [feature]: value,
      }

      // Salva a feature no localStorage
      const data = JSON.stringify(featuresMap)
      localStorage.setItem('featuresMap', data)

      // Atualiza a feature nos componentes dependentes
      setState((prev) => ({
        ...prev,
        featuresMap,
      }))
    },
    [state],
  )

  const updateStateFns: IUpdateStateFunctions = {
    setFeature,
    reset,
  }

  return <Context.Provider value={[state, updateStateFns]}>{children}</Context.Provider>
}

export default (): Context => useContext(Context)
