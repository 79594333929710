import React, { useEffect, useRef, useState } from 'react'

import Layout from '../../components/Layout'
import TextHeader from '../../components/TextHeader'
import ValidateTranslationsActions from '../../components/ValidateTranslationsActions'
import useValidateTranslationModule from '../../hooks/useValidateTranslationModule'
import useSentence from '../../hooks/useSentence'
import useTranslationValidation from '../../hooks/useTranslationValidation'
import useNmtSuggestion from '../../hooks/useNmtSuggestion'
import useShortcuts from '../../hooks/useShortcuts'
import {
  CommunityShortcuts,
  ISign,
  IAnimation,
  getCollectionReference,
  getFirestoreDocument,
  IUserInfo,
  getSignsBatch,
  DocumentReference,
  parseFingerspell,
} from 'collections'
import useIntl from '../../hooks/useIntl'
import useLockModules from '../../hooks/useLockModules'
import LockModuleDialog from '../../components/LockModuleDialog'
import AnimationView from '../../components/AnimationView'
import CardMessage from '../../components/CardMessage'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { Box, Backdrop, CircularProgress, Typography } from '@material-ui/core'
import { DocumentData, Query, documentId, getDocs, query, where } from 'firebase/firestore'
import useSnackbar from '../../services/hooks/useSnackbar'
import { getUsersByWorkspaceFunction } from '../../services/firebase'
import ReportModal from './ReportModal'
import { useRemoteConfig } from 'reactfire'
import { activate, getValue } from 'firebase/remote-config'

interface ITranslateProps {
  path: string
}

interface INmtConfig {
  [workspace: string]: {
    url: string
    token: string
  }
}

const ValidateTranslations: React.FC<ITranslateProps> = () => {
  const { lockedValidateTranslation, checkLockedModule, open, toggleOpen } = useLockModules()
  const [validateTranslationModuleFns] = useValidateTranslationModule()
  const [translationValidation] = useTranslationValidation()
  const [suggestionState] = useNmtSuggestion()
  const [sentenceState, sentenceFns] = useSentence('validateTranslation')
  const { shortcutEvent } = useShortcuts('validateTranslation')
  const intl = useIntl()
  const hasPermissionOnModule = useCheckHasPermission('validateTranslation')
  const [htaAnimations, setHtaAnimations] = useState<string[]>([])
  const [htaAnimationsDefault, setHtaAnimationsDefault] = useState<string[]>([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [autoPlay, setAutoPlay] = useState(true)
  const defaultSpeed = 1.0
  const [speed, setSpeed] = useState(defaultSpeed)
  const textFast = intl.get('messages.fast')
  const textMedium = intl.get('messages.medium')
  const textLow = intl.get('messages.low')
  const textNoSigns = intl.get('pages.validateTranslation.noSigns')
  const textAnimation = intl.get('pages.validateTranslation.animation')
  const textReport = intl.get('pages.validateTranslation.report')
  const textPlayAnimation = intl.get('messages.playAnimation')
  const fsUser = useFsUserDocData()
  const [openTable, setOpenTable] = useState<boolean>(false)
  const [signs, setSigns] = useState<{ id: string; glosa: string; relatedWords: string[] }[]>([])
  const showSnackbar = useSnackbar()
  const [isLoading, setIsloading] = useState(true)
  const [reportProblem, setReportProblem] = useState(false)
  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})
  const [signId, setSignId] = useState('')
  const [otherProblem, setOtherProblem] = useState('')

  const nmtConfig = useRef<INmtConfig>({
    'HT-ASL': {
      url: '',
      token: '',
    },
    'HT-BZS': {
      url: '',
      token: '',
    },
    'HT-BSL': {
      url: '',
      token: '',
    },
  })
  const fbRemoteConfig = useRemoteConfig()

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  const getAnimations = async () => {
    try {
      const url = nmtConfig.current[fsUser?.workspace.id]?.url
      const token = nmtConfig.current[fsUser?.workspace.id]?.token
      if (!url || !token) {
        return undefined
      }

      let body = new FormData()
      body.append('q', sentenceState.sentence || '')
      body.append('use_cache', 'false')
      body.append('adds_log', 'false')
      const response = await fetch(`${url}/translate`, {
        method: 'POST',
        headers: {
          authorization: token,
        },
        body,
      })

      const json = await response.json()
      const translation = json?.translate as string

      body = new FormData()
      body.append('q', translation)
      body.append('use_cache', 'false')
      body.append('adds_log', 'false')

      const response2 = await fetch(`${url}/animation`, {
        method: 'POST',
        headers: {
          authorization: token,
        },
        body,
      })

      const json2 = await response2.json()
      const _animations = (json2.animation as string).split('$')
      const _htaAnimations: string[] = []

      _animations.forEach((el) => {
        const fingerspells = parseFingerspell(fsUser.workspace.id, el || '')

        if (fingerspells.length > 1 && el[0] != '¶') {
          _htaAnimations.push(el)
        }
      })

      const _signsId: string[] = []
      translation.split(' ').forEach((el) => {
        if (el != '' && el[0] == '¶') _signsId.push(el.slice(1, el.length))
      })
      const _signsBatch = await getSignsBatch(fsUser.workspace as DocumentReference, _signsId)

      const _signs: { id: string; glosa: string; relatedWords: string[] }[] = []
      _signsId.forEach((el) => {
        if (_signsBatch[el] && _signsBatch[el].glosa)
          _signs.push({
            id: el,
            glosa: _signsBatch[el].glosa,
            relatedWords: _signsBatch[el].relatedWords,
          })
      })

      setSigns(_signs)
      setHtaAnimations(_htaAnimations)
      setHtaAnimationsDefault(_htaAnimations)
    } catch (err) {
      const e = err as Error
      showSnackbar(e.message, { variant: 'error' })
    } finally {
      setIsloading(false)
    }
  }

  useEffect(() => {
    setIsloading(true)
    setOpenTable(false)
    getAnimations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionState.nmtSuggestion, suggestionState.isLoading, fsUser.workspace.id])

  useEffect(() => {
    if (
      suggestionState.isLoading ||
      sentenceState.isLoading ||
      !translationValidation ||
      translationValidation.length === 0
    )
      return
    switch (shortcutEvent) {
      case CommunityShortcuts.correctValidationTranslate:
        validateTranslationModuleFns.save('correct')
        break
      case CommunityShortcuts.missingSignsValidationTranslate:
        validateTranslationModuleFns.save('missingSigns')
        break
      case CommunityShortcuts.outOfContextValidationTranslate:
        validateTranslationModuleFns.save('outOfContext')
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutEvent])

  useEffect(() => {
    if (lockedValidateTranslation) {
      checkLockedModule('validateTranslation')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedValidateTranslation])

  useEffect(() => {
    getUsers()
    sentenceFns.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isPlaying && htaAnimationsDefault.toString() != htaAnimations.toString()) {
      setHtaAnimations(htaAnimationsDefault)
      setAutoPlay(false)
    } else if (isPlaying) setAutoPlay(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying])

  useEffect(() => {
    const call = async () => {
      await activate(fbRemoteConfig)

      if (getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
        nmtConfig.current = JSON.parse(getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
    }
    call()
  }, [fbRemoteConfig])

  const handleClickPlaySign = async (id: string) => {
    setAutoPlay(true)
    const _htaAnimations: string[] = []
    try {
      const signQry = query(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where(documentId(), '==', id),
      )
      const signSnapshot = (await getDocs(signQry)).docs
      const signData = signSnapshot.map((el) => {
        return el.data() as ISign
      })

      if (signData[0] && signData[0].currentAnimation && signData[0].currentAnimation?.animation) {
        const animationSnapshot = await getFirestoreDocument(signData[0].currentAnimation.animation)
        const animationData = animationSnapshot.data() as IAnimation
        _htaAnimations.push(animationData.htaAnimation || '')
      }
    } catch (err) {
      const e = err as Error
      showSnackbar(e.message, { variant: 'error' })
    }
    setHtaAnimations(_htaAnimations)
  }

  const handleClickReportProblem = async (signId: string) => {
    setSignId(signId)
    setReportProblem(!reportProblem)
  }

  const handleClickOpenSigns = () => {
    setOpenTable(!openTable)
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.validateTranslation')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.validateTranslation')} requiredModule={'validateTranslation'}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {isLoading && (
          <Backdrop style={{ color: '#fff', zIndex: 1 }} open={true}>
            <Box
              style={{
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography>{intl.get('pages.validateTranslation.loadingAnimation')}</Typography>
              <CircularProgress color="inherit" />
            </Box>
          </Backdrop>
        )}

        <Box display="flex" alignContent="center" style={{ position: 'relative' }}>
          <TextHeader communityModule={'validateTranslation'} />
        </Box>
        <Box width="100%" height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Box display="flex">
            <AnimationView
              htaAnimations={htaAnimations}
              rest={true}
              repeat={true}
              autoPlay={autoPlay}
              speedControls={true}
              defaultSpeed={defaultSpeed}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speed={speed}
              setSpeed={setSpeed}
              textFast={textFast}
              textMedium={textMedium}
              textLow={textLow}
              handleClickOpenSigns={handleClickOpenSigns}
              openTable={openTable}
              signs={signs}
              textNoSigns={textNoSigns}
              textAnimation={textAnimation}
              textReport={textReport}
              textPlayAnimation={textPlayAnimation}
              handleClickPlaySign={handleClickPlaySign}
              handleClickReportProblem={handleClickReportProblem}
            />
          </Box>
          {reportProblem && (
            <ReportModal
              workspaceUsers={workspaceUsers}
              otherProblem={otherProblem}
              setOtherProblem={setOtherProblem}
              openModalReport={reportProblem}
              setOpenModalReport={setReportProblem}
              setReportProblem={setReportProblem}
              signId={signId}
            />
          )}
        </Box>

        <ValidateTranslationsActions
          lockButtons={
            suggestionState.isLoading ||
            sentenceState.isLoading ||
            !translationValidation ||
            translationValidation.length === 0
          }
          onValidate={validateTranslationModuleFns.save}
          openTable={openTable}
        />
      </Box>
      <LockModuleDialog open={open} toggleOpen={toggleOpen} />
    </Layout>
  )
}

export default ValidateTranslations
