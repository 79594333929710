import React, { Suspense, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { useGlobal } from 'reactn'
import Layout from '../../components/Layout'
import ModuleCardUser from './ModuleCard/index'
import { routes } from '../../community'
import CardMessage from '../../components/CardMessage'
import { CommunityModule, IGlobalAttr, IWorkspacePermissions } from 'collections'
import LockModuleDialog from '../../components/LockModuleDialog'
import Preloader from '../../components/Preloader'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { useRemoteConfig } from 'reactfire'
import { useState } from 'reactn'
import useIntl from '../../hooks/useIntl'
import useLockModules from '../../hooks/useLockModules'
import signSentenceImage from '../../images/interpret_sentence.svg'
import tagInterpretationImage from '../../images/tag_interpretation.svg'
import translateImage from '../../images/validation.png'
import signAnimationImage from '../../images/sign_animation.png'
import segmentModuleImage from '../../images/capa-modulo-segment.png'
import animationReviewImage from '../../images/animation_review.png'
import matchSegmentsImage from '../../images/capa-modulo-match.png'
import hTubeImage from '../../images/capa-modulo-htube.png'
import { fetchAndActivate } from '@firebase/remote-config'
import { getValue } from '@firebase/remote-config'
import signOnDemandModuleImage from '../../images/capa-modulo-signOnDemand.png'
import processVideoModuleImage from '../../images/capa-modulo-processVideo.png'
import phraseManagerModuleImage from '../../images/capa-modulo-phraseManager.png'
import abTestNmtModuleImage from '../../images/capa-modulo-teste-ab.png'
import updatePemrissionModuleImage from '../../images/capa-modulo-atualizar-permissoes.png'
import manuallyTranslationModuleImage from '../../images/manually-translation.png'
import { useAuth } from 'reactfire'

interface IHomeProps {
  path: string
}

const WrapperGrid = styled(Grid)`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 32px 16px;
`

const Home: React.FC<IHomeProps> = () => {
  const fsUser = useFsUserDocData()
  const fbAuth = useAuth()
  const fsRemoteConfig = useRemoteConfig()
  const [urlHelpModules, setUrlHelpModules] = useState<Record<string, CommunityModule>>()
  const [, setUrlHelpCommunity] = useGlobal<IGlobalAttr, 'urlHelpCommunity'>('urlHelpCommunity')

  const intl = useIntl()
  const textHome = intl.get('header.home')
  const { lockedRecordVideo, lockedValidateTranslation, checkLockedModule, open, toggleOpen } = useLockModules()

  const [permissions, setPermissions] = useState<IWorkspacePermissions>()

  useEffect(() => {
    const call = async () => {
      const userAuth = await fbAuth.currentUser?.getIdTokenResult()
      const _permissions = userAuth?.claims?.permissions as IWorkspacePermissions
      setPermissions(_permissions)

      await fetchAndActivate(fsRemoteConfig)
      const _urlHelpCommunity =
        getValue(fsRemoteConfig, 'HandTalk_url_help_community_' + fsUser?.signLanguageId).asString() || '{}'
      const _urlHelpModules = JSON.parse(_urlHelpCommunity)

      setUrlHelpModules(_urlHelpModules)
      setUrlHelpCommunity(_urlHelpModules['community'])
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsRemoteConfig, fsUser?.signLanguageId])

  const checkModulePermission = (module: string) => {
    if (
      permissions &&
      permissions[module] &&
      permissions[module][fsUser?.workspace.id] &&
      permissions[module][fsUser?.workspace.id] == 1
    )
      return true
    else false
  }

  const content = (
    <>
      {/** Usuário não tem acesso aos módulos */}
      {fsUser?.modules.length === 0 && (
        <CardMessage
          title="You do not have access to the modules."
          subtitle="Talk to an admin to change your module access."
        />
      )}

      {/** Condiciona a exibição do modulo de sinalização */}
      {checkModulePermission('signSentence') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.signSentence')}
            communityModule={'signSentence'}
            imageUrl={signSentenceImage}
            url={
              localStorage.getItem('showedLobbyRecordVideo') === 'true'
                ? routes.signSentenceMain
                : routes.signSentenceLobby
            }
            helpUrl={(urlHelpModules && urlHelpModules['signSentence']) || ''}
            lockedModule={lockedRecordVideo}
            checkLockedModule={checkLockedModule}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de segmentação  */}
      {checkModulePermission('segmentVideo') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.segmentVideo')}
            communityModule={'segmentVideo'}
            imageUrl={segmentModuleImage}
            url={routes.segmentASignHome}
            helpUrl={(urlHelpModules && urlHelpModules['segmentVideo']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de achar segmentos idênticos  */}
      {checkModulePermission('findIdenticalSegments') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.findIdenticalSegments')}
            communityModule={'findIdenticalSegments'}
            imageUrl={matchSegmentsImage}
            url={routes.findIdenticalSegmentsHome}
            helpUrl={(urlHelpModules && urlHelpModules['findIdenticalSegments']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de etiquetação */}
      {checkModulePermission('tagVideo') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.tagVideo')}
            communityModule={'tagVideo'}
            imageUrl={tagInterpretationImage}
            url={routes.tagASignHome}
            helpUrl={(urlHelpModules && urlHelpModules['tagVideo']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de tradução */}
      {checkModulePermission('validateTranslation') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.validateTranslation')}
            communityModule={'validateTranslation'}
            imageUrl={translateImage}
            url={routes.validateTranslations}
            helpUrl={(urlHelpModules && urlHelpModules['validateTranslation']) || ''}
            lockedModule={lockedValidateTranslation}
            checkLockedModule={checkLockedModule}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de animação */}
      {checkModulePermission('animation') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.animation')}
            communityModule={'animation'}
            imageUrl={signAnimationImage}
            url={routes.animationHome}
            helpUrl={(urlHelpModules && urlHelpModules['animation']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de revisão de animação */}
      {checkModulePermission('reviewAnimation') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.reviewAnimation')}
            communityModule={'reviewAnimation'}
            imageUrl={animationReviewImage}
            url={routes.reviewAnimationHome}
            helpUrl={(urlHelpModules && urlHelpModules['reviewAnimation']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de visualização de sinais */}
      {checkModulePermission('hTube') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.hTube')}
            communityModule={'hTube'}
            imageUrl={hTubeImage}
            url={routes.hTubeHome}
            helpUrl={(urlHelpModules && urlHelpModules['hTube']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de vídeo sob demanda */}
      {checkModulePermission('signOnDemand') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.signOnDemand')}
            communityModule={'signOnDemand'}
            imageUrl={signOnDemandModuleImage}
            url={routes.signOnDemand}
            helpUrl={(urlHelpModules && urlHelpModules['signOnDemand']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de gerenciamento de frases */}
      {checkModulePermission('phraseManager') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.phraseManager')}
            communityModule={'phraseManager'}
            imageUrl={phraseManagerModuleImage}
            url={routes.phraseManager}
            helpUrl={(urlHelpModules && urlHelpModules['phraseManager']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de processar vídeo */}
      {checkModulePermission('processVideo') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.processVideo')}
            communityModule={'processVideo'}
            imageUrl={processVideoModuleImage}
            url={routes.processVideo}
            helpUrl={(urlHelpModules && urlHelpModules['processVideo']) || ''}
          />
        </Grid>
      )}

      {checkModulePermission('abTestNmt') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.abTestNmt')}
            communityModule={'abTestNmt'}
            imageUrl={abTestNmtModuleImage}
            url={routes.abTestNmt}
            helpUrl={(urlHelpModules && urlHelpModules['abTestNmt']) || ''}
          />
        </Grid>
      )}

      {/** Condiciona a exibição do modulo de atualizar permissões */}
      {checkModulePermission('updatePermissions') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.updatePermissions')}
            communityModule={'updatePermissions'}
            imageUrl={updatePemrissionModuleImage}
            url={routes.updatePermissions}
          />
        </Grid>
      )}

      {checkModulePermission('manuallyTranslation') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.manuallyTranslation')}
            communityModule={'manuallyTranslation'}
            imageUrl={manuallyTranslationModuleImage}
            url={routes.manuallyTranslation}
          />
        </Grid>
      )}

      {checkModulePermission('videoManager') && (
        <Grid item xs={12} md={4}>
          <ModuleCardUser
            title={intl.get('modules.videoManager')}
            communityModule={'videoManager'}
            imageUrl={signSentenceImage}
            url={routes.videoManager}
          />
        </Grid>
      )}

      <LockModuleDialog open={open} toggleOpen={toggleOpen} />
    </>
  )

  return (
    <Layout title={textHome} imageBg>
      <WrapperGrid container>
        <Grid container alignItems="center" spacing={2}>
          {content}
        </Grid>
      </WrapperGrid>
    </Layout>
  )
}

const SuspensedHome: React.FC<IHomeProps> = (props) => (
  <Suspense fallback={<Preloader text="Loading Home" />}>
    <Home {...props} />
  </Suspense>
)

export default SuspensedHome
