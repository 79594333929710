import React from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { DateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import InputLabel from '@material-ui/core/InputLabel'
import { IOptionsSelect, IUserInfo, UserId } from 'collections'
import { Box, Button, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearFilter from '@material-ui/icons/Clear'

const AppBarStyled = styled(AppBar)`
  && {
    @media (max-width: 600px) {
      padding: 10px 0px;
    }
  }
`

interface IContributionsHeader {
  /** Textos para internacionalização */
  intl?: { get: (key: string) => string }
  /** Datetime de inicio do filtro */
  startDate: Date
  /** Datetime de inicio foi alterada */
  onStartDateChange: (newDate: Date) => void
  /** Datetime de fim do filtro */
  endDate: Date
  /** Datetime de fim foi alterada */
  onEndDateChange: (newDate: Date) => void
  /** Dicionario de usuários que aparecem na seleção de filtros */
  users: Record<UserId, IUserInfo>
  /** Id do usuário que foi selecionado no dropdown de seleção */
  selectedUserId: UserId
  /** Texto de pesquisa */
  searchText: string
  /** Id do sinal */
  signId?: string
  /** Origem da sentença do vídeo */
  sentenceOrigin?: string
  /** Categoria da sentença do vídeo */
  sentenceCategory?: string
  /** Estado do vídeo */
  videoState?: string
  /** Categorias das sentenças*/
  categories?: string[]
  /** Origens das sentenças*/
  origins?: string[]
  /** Estados do vídeo*/
  videoStates?: IOptionsSelect[]
  /** Lista de ids de usuários selecionados foi alterada */
  onUserChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Função para alterar o filtro da origem da sentença dos vídeos */
  onOriginChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Função para alterar o filtro da categoria da sentença dos vídeos */
  onCategoryChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Função para alterar o filtro do estado dos vídeos */
  onStateChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Função para alterar texto de pesquisa */
  onSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Função para alterar texto do id do sinal */
  onSignIdChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Função para aplicar filtros */
  applyFilter?: () => void
  /** Função para limpar filtros */
  clearFilter?: () => void
}

/**
 * Este é o cabeçalho da exibição das Contribuições.
 * Aqui exibimos os filtros necessarios para a listagem de vídeos
 */
const ContributionsHeader: React.FC<IContributionsHeader> = ({
  intl,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  users,
  selectedUserId,
  searchText,
  signId,
  sentenceOrigin,
  sentenceCategory,
  videoState,
  categories,
  origins,
  videoStates,
  onUserChange,
  onOriginChange,
  onCategoryChange,
  onStateChange,
  onSearchTextChange,
  onSignIdChange,
  applyFilter,
  clearFilter,
}) => {
  return (
    <AppBarStyled color="default" position="static">
      <Toolbar>
        <Grid container spacing={2} style={{ margin: '8px' }}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="search-text"
              label={intl?.get('pages.videoManager.others.searchText')}
              value={searchText}
              onChange={onSearchTextChange}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <InputLabel id="origins-label">{intl?.get('messages.origin')}</InputLabel>
            <Select fullWidth value={sentenceOrigin} onChange={onOriginChange}>
              <MenuItem key={'all'} value={'all'}>
                Todos
              </MenuItem>
              {origins?.map((origin) => (
                <MenuItem key={origin} value={origin}>
                  {origin}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <InputLabel id="categories-label">{intl?.get('messages.category')}</InputLabel>
            <Select fullWidth value={sentenceCategory} onChange={onCategoryChange}>
              <MenuItem key={'all'} value={'all'}>
                Todos
              </MenuItem>
              {categories?.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <InputLabel id="videoState-label">{intl?.get('messages.videoState')}</InputLabel>
            <Select fullWidth value={videoState} onChange={onStateChange}>
              {videoStates?.map((state) => (
                <MenuItem key={state.label} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              id="signId-input"
              label={intl?.get('messages.signId')}
              value={signId}
              onChange={onSignIdChange}
            />
          </Grid>

          {/** O seletor de usuários só está disponivel para administradores do workspace */}
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <InputLabel id="users-label">User(s)</InputLabel>
            <Select fullWidth value={selectedUserId} onChange={onUserChange}>
              <MenuItem key={'all'} value={'all'}>
                All users
              </MenuItem>
              {/** Adiciona o nome de todos os usuários  */}
              {Object.entries(users)
                .sort((el1, el2) => {
                  if (el1[1].displayName && el2[1].displayName && el1[1].displayName < el2[1].displayName) {
                    return -1
                  }
                  if (el1[1].displayName && el2[1].displayName && el1[1].displayName > el2[1].displayName) {
                    return 1
                  }
                  return 0
                })
                .map(([userId, { email, displayName }]) => (
                  <MenuItem key={userId} value={userId}>
                    {`${displayName || 'Anonymous'} ${email ? '- ' + email : ''}`}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <DateTimePicker
              fullWidth
              disabled={searchText ? true : false}
              value={searchText ? null : startDate}
              onChange={onStartDateChange as (date: MaterialUiPickersDate) => void}
              ampm={false}
              autoOk
              label={intl?.get('pages.videoManager.others.startDate')}
              format="dd/MM/yyyy HH:mm"
            />
          </Grid>

          <Grid item lg={3} md={3} sm={12} xs={12}>
            <DateTimePicker
              fullWidth
              disabled={searchText ? true : false}
              value={searchText ? null : endDate}
              onChange={onEndDateChange as (date: MaterialUiPickersDate) => void}
              ampm={false}
              autoOk
              label={intl?.get('pages.videoManager.others.endDate')}
              format="dd/MM/yyyy HH:mm"
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Box
              style={{
                display: 'flex',
                gap: '10px',
                flexDirection: 'row',
                marginLeft: 'auto',
              }}
            >
              <Button variant="contained" color="default" size="small" startIcon={<SearchIcon />} onClick={applyFilter}>
                {intl?.get('pages.videoManager.others.applyFilter')}
              </Button>
              <Button
                variant="contained"
                color="default"
                size="small"
                startIcon={<ClearFilter />}
                onClick={clearFilter}
              >
                {intl?.get('pages.videoManager.others.clearFilter')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBarStyled>
  )
}

export default ContributionsHeader
