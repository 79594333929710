import { FC } from 'react'

import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { Dialog, DialogContent, Typography } from '@material-ui/core'
import imageUrl from '../../images/maintenance-hugo.png'
import useIntl from '../../hooks/useIntl'

export const Maintenance: FC = () => {
  const intl = useIntl()
  return (
    <Grid item xs container direction="column" style={{ height: '100%' }}>
      <Helmet>
        <title>Community</title>
      </Helmet>
      <Dialog id={'labelledby'} open={true} maxWidth="sm" fullWidth>
        <>
          <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography paragraph variant="h4" style={{ alignSelf: 'center' }}>
              {intl.get('components.maintenance.mainMessage')}
            </Typography>
            <Typography paragraph variant="h6">
              {intl.get('components.maintenance.secondaryMessage')}
            </Typography>
            <img
              src={imageUrl}
              style={{
                maxHeight: '500px',
                maxWidth: '444px',
                alignSelf: 'center',
              }}
            />
          </DialogContent>
        </>
      </Dialog>
    </Grid>
  )
}

export default Maintenance
