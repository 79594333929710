import {
  AppBar,
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Toolbar,
  Typography,
  Theme,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import { motionColors, motionFonts } from '../../styles'
import DrawerMotion from '../DrawerMotion'
import { routes } from '../../community'
import { useLocation } from 'wouter'
import BottomBarMotion from '../BottomBarMotion'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { isMobile } from '../../services/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Motion */
    logoMotion: {
      fontFamily: motionFonts.logoFont,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '28.1739px',
      lineHeight: '130%',
      color: motionColors.brandColors.secundary.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: '60px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '80px',
      },
    },
    appBar: {
      backgroundColor: motionColors.backgroundColor,
      boxShadow: 'none',
      color: motionColors.fontColor,
      [theme.breakpoints.up('lg')]: {
        marginTop: '40px',
      },
      [theme.breakpoints.up('xl')]: {
        marginTop: '60px',
      },
    },
    gridContainer: {
      minHeight: '100vh',
      backgroundColor: motionColors.backgroundColor,
      color: motionColors.fontColor,
      fontFamily: motionFonts.textFont,
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gridColumnGap: '10px',
      width: '100%',
    },
    navLinkContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    },
    navLink: {
      color: motionColors.fontColor,
      fontWeight: 700,
      fontSize: '16px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif;',
      textTransform: 'none',
      marginLeft: '49px',
      '&:hover': {
        color: motionColors.brandColors.secundary.main,
        backgroundColor: motionColors.backgroundColor,
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
      },
      '&:first-of-type': {
        marginLeft: 0,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
      },
    },
  }),
)

const Item = withStyles({
  root: {
    '&$selected': {
      backgroundColor: motionColors.backgroundColor,
      color: motionColors.brandColors.secundary.main,
      textDecoration: 'underline',
      textUnderlineOffset: '5px',
    },
    '&$selected:hover': {
      backgroundColor: motionColors.backgroundColor,
    },
  },
  selected: {},
})(ListItem)

enum EMenuOption {
  home = 'home',
  learning = 'learning',
}

const LayoutMotion: React.FC = ({ children }) => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [location, setLocation] = useLocation()
  const { getLocalStorageValue } = useLocalStorage()

  const handleClickMenu = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleNavigation = (menuOption: EMenuOption) => {
    switch (menuOption) {
      case EMenuOption.learning:
        // return setLocation(routes.motionLearning)
        return
      case EMenuOption.home:
        return setLocation(routes.motionLearningRecord)
    }
  }

  useEffect(() => {
    const lgpdValueStr = getLocalStorageValue('lgpd')
    let acceptedLGPD = false

    if (lgpdValueStr) {
      const lgpdValue = JSON.parse(lgpdValueStr)
      acceptedLGPD = lgpdValue['accepted']
    }
    if (location !== routes.motionLGPD && !acceptedLGPD) {
      setLocation(routes.motionLGPD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid item className={classes.gridContainer} md={12}>
      <Grid
        item
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
        }}
      >
        <Grid>
          <Grid item style={{ width: '100%' }}>
            <AppBar className={classes.appBar} position="static">
              <Toolbar style={{ flexGrow: 1, justifyContent: 'space-between' }}>
                <Typography
                  className={classes.logoMotion}
                  variant="h6"
                  component="div"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setLocation(routes.motionLearningRecord)
                  }}
                >
                  Motion
                </Typography>

                {isMobile() ? (
                  <div>
                    <IconButton
                      size="medium"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={() => handleClickMenu()}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                ) : (
                  <List className={classes.navLinkContainer}>
                    <Item
                      selected={location === routes.motionLearningRecord}
                      button
                      className={classes.navLink}
                      disableRipple
                      onClick={() => {
                        handleNavigation(EMenuOption.home)
                      }}
                    >
                      Início
                    </Item>
                    <Item
                      selected={location.split('/').length > 2 && location.split('/')[2] === 'learning'}
                      button
                      className={classes.navLink}
                      disableRipple
                      onClick={() => {
                        handleNavigation(EMenuOption.learning)
                      }}
                    >
                      Aprendizagem
                    </Item>
                    <Item
                      button
                      className={classes.navLink}
                      disableRipple
                      onClick={() => {
                        handleNavigation(EMenuOption.home)
                      }}
                    >
                      Desafio
                    </Item>
                    <Item
                      button
                      className={classes.navLink}
                      disableRipple
                      onClick={() => {
                        handleNavigation(EMenuOption.home)
                      }}
                    >
                      Ajuda
                    </Item>
                  </List>
                )}
              </Toolbar>
            </AppBar>
          </Grid>

          <DrawerMotion setOpen={setOpenDrawer} open={openDrawer} />

          <Grid
            item
            style={{
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </Grid>
        </Grid>
        <Grid>
          <BottomBarMotion />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LayoutMotion
