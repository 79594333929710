import { Box, RadioGroup, FormControlLabel, Radio, InputLabel, TextField, Tooltip } from '@material-ui/core'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import AnimationView from '../../../components/AnimationView'
import { Autocomplete } from '@material-ui/lab'
import { TTexts } from '.'
import ReportModal from '../../ValidateTranslations/ReportModal'
import { IUserInfo } from 'collections'

interface ICardAnimationPureProps {
  idElement: string
  currentAnimations: string[]
  score: string
  setScore: React.Dispatch<React.SetStateAction<string | undefined>>
  addWordText: string
  tags: string[]
  options: string[]
  isPlaying: boolean
  setIsPlaying: (value: boolean) => void
  speed: number
  setSpeed: Dispatch<SetStateAction<number>>
  texts: TTexts
  handleChangeOptions: (value: string[]) => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
  handleChangeTextField: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClickOpenSigns?: () => void
  openTable?: boolean
  signs?: { id: string; glosa: string; relatedWords: string[] }[]
  handleClickPlaySign?: (id: string) => Promise<void>
  handleClickReportProblem?: (id: string) => Promise<void>
  autoPlay: boolean
  reportProblem: boolean
  setReportProblem: React.Dispatch<React.SetStateAction<boolean>>
  workspaceUsers: Record<string, IUserInfo>
  otherProblem: string
  setOtherProblem: React.Dispatch<React.SetStateAction<string>>
  signId: string
  isLoading: boolean
}

const CardAnimationPure: React.FC<ICardAnimationPureProps> = ({
  idElement,
  currentAnimations,
  score,
  setScore,
  addWordText,
  tags,
  options,
  handleChangeOptions,
  handleKeyPress,
  handleChangeTextField,
  isPlaying,
  setIsPlaying,
  speed,
  setSpeed,
  texts,
  handleClickOpenSigns,
  openTable,
  signs,
  handleClickPlaySign,
  handleClickReportProblem,
  autoPlay,
  reportProblem,
  setReportProblem,
  workspaceUsers,
  otherProblem,
  setOtherProblem,
  signId,
  isLoading,
}) => {
  const handleClickRadio = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLButtonElement

    if (target.value === score) {
      setScore('')
    } else {
      setScore(target.value)
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ display: 'flex' }}>
        <AnimationView
          htaAnimations={currentAnimations}
          rest={true}
          repeat={true}
          autoPlay={autoPlay}
          speedControls={true}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          speed={speed}
          setSpeed={setSpeed}
          textFast={texts.textFast}
          textMedium={texts.textMedium}
          textLow={texts.textLow}
          idElement={idElement}
          handleClickOpenSigns={handleClickOpenSigns}
          openTable={openTable}
          signs={signs}
          textNoSigns={texts.textNoSigns}
          textAnimation={texts.textAnimation}
          textReport={texts.textReport}
          textPlayAnimation={texts.textPlayAnimation}
          handleClickPlaySign={handleClickPlaySign}
          handleClickReportProblem={handleClickReportProblem}
          isLoading={isLoading}
          textRotate={texts.textRotateAvatar}
          textOpenTable={texts.textOpenTable}
          textSpeed={texts.textSpeed}
          textOthers={texts.textOthers}
        />
        {reportProblem && (
          <ReportModal
            workspaceUsers={workspaceUsers}
            otherProblem={otherProblem}
            setOtherProblem={setOtherProblem}
            openModalReport={reportProblem}
            setOpenModalReport={setReportProblem}
            setReportProblem={setReportProblem}
            signId={signId}
          />
        )}
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={score}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const _score = event.target.value
            setScore(_score)
          }}
        >
          <Tooltip title={`${texts.textDefinition0Message} (${texts.textDefinition0Reason})`}>
            <FormControlLabel
              value="0"
              control={<Radio color="primary" onClick={handleClickRadio} />}
              label={texts.textQualityConst0}
            />
          </Tooltip>

          <Tooltip title={`${texts.textDefinition1Message} (${texts.textDefinition1Reason})`}>
            <FormControlLabel
              value="1"
              control={<Radio color="primary" onClick={handleClickRadio} />}
              label={texts.textQualityConst1}
            />
          </Tooltip>

          <Tooltip title={`${texts.textDefinition2Message} (${texts.textDefinition2Reason})`}>
            <FormControlLabel
              value="2"
              control={<Radio color="primary" onClick={handleClickRadio} />}
              label={texts.textQualityConst2}
            />
          </Tooltip>

          <Tooltip title={`${texts.textDefinition3Message} (${texts.textDefinition3Reason})`}>
            <FormControlLabel
              value="3"
              control={<Radio color="primary" onClick={handleClickRadio} />}
              label={texts.textQualityConst3}
            />
          </Tooltip>
        </RadioGroup>
        <Box style={{ maxWidth: '250px', marginBottom: '10px' }}>
          <InputLabel id="x-label" style={{ minWidth: '5em', maxWidth: '10em', color: 'inherit' }}>
            Tags
          </InputLabel>
          <Autocomplete
            multiple
            limitTags={2}
            style={{ minWidth: '250px' }}
            options={tags}
            getOptionLabel={(option) => option}
            value={options}
            onChange={(_, value) => handleChangeOptions(value)}
            onKeyPress={handleKeyPress}
            renderInput={(params) => (
              <TextField {...params} value={addWordText} onChange={handleChangeTextField} variant={'outlined'} />
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CardAnimationPure
