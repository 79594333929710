import { FC } from 'react'
import styled from 'styled-components'

import GoodIcon from '@material-ui/icons/CheckCircle'
import BadIcon from '@material-ui/icons/Cancel'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

interface IExampleImageProps {
  imageUrl: string
  imageAlt: string
  isGood?: boolean
}

const CardMediaSized = styled(CardMedia)`
  width: 200px;
  height: 200px;
  padding: 8px;
`

const goodIcon = <GoodIcon color="primary" />
const badIcon = <BadIcon color="error" />

export const ExampleImage: FC<IExampleImageProps> = ({ imageUrl, imageAlt, isGood }) => (
  <Card>
    <CardMediaSized image={imageUrl} title={imageAlt}>
      {isGood ? goodIcon : badIcon}
    </CardMediaSized>
    <CardContent>
      <Typography variant="caption">{imageAlt}</Typography>
    </CardContent>
  </Card>
)

export default ExampleImage
