import React from 'react'
import { useLocation } from 'wouter'

import ModuleCard from './Pure'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { CommunityModule, LanguageKeypair, WorkspaceId } from 'collections'

interface IModuleCardUSeProps {
  title: string
  imageUrl: string
  url: string
  communityModule: CommunityModule
  helpUrl?: string
  lockedModule?: boolean
  checkLockedModule?: (communityModule: CommunityModule) => void
}

const ModuleCardUser: React.FC<IModuleCardUSeProps> = ({
  title,
  communityModule,
  imageUrl,
  url,
  helpUrl,
  lockedModule,
  checkLockedModule,
}) => {
  const fsUser = useFsUserDocData()
  const setLocation = useLocation()[1]

  const workspaceId = fsUser.workspace.id as WorkspaceId
  const langKeyPair = `${fsUser.oralLanguageId}-${fsUser.signLanguageId}` as LanguageKeypair
  const contributions = fsUser.contribs?.[workspaceId]?.[communityModule]?.[langKeyPair] || 0

  const handleOnclick = () => {
    if (lockedModule && checkLockedModule) {
      checkLockedModule(communityModule)
      return
    }
    setLocation(url)
  }

  return (
    <ModuleCard
      title={title}
      contributions={contributions}
      imageUrl={imageUrl}
      handleClick={handleOnclick}
      helpUrl={helpUrl}
      locked={lockedModule}
    />
  )
}

export default ModuleCardUser
