import { Box, Button, Divider } from '@material-ui/core'
import React, { useState } from 'react'

interface AddPhraseButtonProps {
  onClickAdd: () => void
}

const AddPhraseButton: React.FC<AddPhraseButtonProps> = ({ onClickAdd }) => {
  const [showButton, setShowButton] = useState(false)
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        position: 'relative',
      }}
      onMouseLeave={() => setShowButton(false)}
      onMouseEnter={() => setShowButton(true)}
    >
      <Divider style={{ width: '100%' }} />
      {showButton && (
        <>
          <Button
            style={{
              textTransform: 'none',
              padding: '0px',
              position: 'absolute',
            }}
            onClick={onClickAdd}
          >
            Incluir
          </Button>
        </>
      )}
    </Box>
  )
}

export default AddPhraseButton
