import { FC } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useIntl from '../../hooks/useIntl'

interface IParameters {
  subtitle: string
  buttonText?: string
  buttonCancelText?: string
}

export interface IApplicationDialogProps {
  title: string
  open: boolean
  /**
   * IParameters {
    labelTextFiel: string;
    subtitle: string; 
    feedback: string;
    isPending: boolean;
    buttonText?: string;
  }
   * */
  options?: IParameters
  /** Se options for undefined, então pode passar conteúdo do seu dialog. */
  component?: JSX.Element
  /** Se options for undefined, então pode passar o elemento de actions do seu dialog. */
  actionsElement?: JSX.Element
  /**
   * Identifica o elemento (ou elementos) que rotula o elemento atual.
   * */
  labelledby: string
  toggleOpen?: () => void
  send?: () => void
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}
export const ConfirmationDialogPure: FC<IApplicationDialogProps> = ({
  open,
  options,
  labelledby,
  toggleOpen,
  send,
  component,
  actionsElement,
  title,
  children,
  maxWidth,
}) => {
  const intl = useIntl()

  return (
    <Dialog
      id={labelledby}
      open={open}
      onClose={toggleOpen}
      aria-labelledby={labelledby}
      maxWidth={maxWidth || 'sm'}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      {options ? (
        <>
          <DialogContent style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
            {children}
            <DialogContentText>{options.subtitle}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={toggleOpen} color="primary">
              {options.buttonText ? options.buttonCancelText : intl.get('messages.cancel')}
            </Button>
            <Button variant="contained" onClick={send} color="primary">
              {options.buttonText ? options.buttonText : intl.get('messages.send')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent style={{ overflow: 'hidden' }}>{component}</DialogContent>
          {/** Mostraremos os actions parametrizados  */}
          {actionsElement && <DialogActions>{actionsElement}</DialogActions>}
        </>
      )}
    </Dialog>
  )
}

export default ConfirmationDialogPure
