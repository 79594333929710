/**
 * 🚧🚧🚧 ATENÇÃO 🚧🚧🚧
 * Este arquivo está originalmente em:
 * ./tools/shared/services/errors.ts
 * Se você encountrou em outro lugar, ele é um link-simbólico. Ao modificar
 * este arquivo, tome cuidado para que outros projetos não sejam quebrados!
 */

interface IToJson {
  status: number
  message: string
}

// Este arquivo exporta todos os erros que podemos jogar em nosso servidor.
// Erros de outros formatos devem ser considerados como 500 - Erro de servidor.

export class HtError extends Error {
  public status: number
  public safeMessage: string

  public constructor(safeMessage: string, status = 500) {
    super(safeMessage)
    this.status = status
    this.safeMessage = safeMessage
  }

  public toJson = (): IToJson => {
    return { status: this.status, message: this.safeMessage }
  }
}

// ------------------ Erros do community -------------------

export const errorVideoOrTagNotFound = new HtError('Vídeo or tags not found')

export const errorIsNotMasterOrAdmin = new HtError(
  'Ação não permitida! você precisa ser master ou administrador de um workspace para fazer isso',
)

export const errorInsuficientParams = new HtError('Parametros insuficientes', 406)

export const errorFramesNotFound = new HtError('Não foi possivel acessar os frames extraidos', 406)

export const errorFsDocNotFound = new HtError('Não foi possivel encontrar o documento no firestore', 406)

export const errorCropSignVideo = new HtError('Falha ao cortar vídeo com ffmpeg', 406)

export const errorCropVideo = new HtError('Falha ao cortar vídeo com ffmpeg', 406)

export const errorFfmpegSubprocess = new HtError('Falha no subprocesso do ffmpeg', 406)

export const errorFramesExtraction = new HtError('Falha ao extrair frames do vídeo', 406)

export const errorStorageFileNotFound = new HtError('Não foi possivel encontrar o arquivo no storage', 406)

export const errorStorageFileUpload = new HtError('Não foi possivel fazer upload do arquivo para o storage', 406)
