import React from 'react'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { IDrawerProps, DrawerName } from 'collections'
import PersonIcon from '@material-ui/icons/Person'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
  }),
)

const DrawerPure: React.FC<IDrawerProps> = ({ selectedDrawer, setSelectedDrawer }) => {
  const classes = useStyles()
  return (
    <Drawer
      variant="permanent"
      open={true}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem
          button
          selected={selectedDrawer === DrawerName.users}
          onClick={() => {
            if (setSelectedDrawer) setSelectedDrawer(DrawerName.users)
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem
          button
          selected={selectedDrawer === DrawerName.workspaces}
          onClick={() => {
            if (setSelectedDrawer) setSelectedDrawer(DrawerName.workspaces)
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Workspaces" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default DrawerPure
