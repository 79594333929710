import React from 'react'

import ContributionsLoggerPure from './Pure'
import useContributionsLogger from '../../services/hooks/useContributionsLogger'
import useIntl from '../../hooks/useIntl'

interface IContributionsLoggerProps {
  /** Adiciona um elemento customizado */
  includeCustomElem?: JSX.Element
}

/**
 * Fluxo de exibição de log de contribuições de todos os modulos durante uma sessão
 */
const ContributionsLogger: React.FC<IContributionsLoggerProps> = ({ includeCustomElem }) => {
  const [contributionsLog] = useContributionsLogger()
  const intl = useIntl()

  const contributionsLogOrdened = Object.values(contributionsLog).sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  )

  const inProgressContributions: number = contributionsLogOrdened.reduce(
    (prev, current) => prev + (current.progress != 1 ? 1 : 0),
    0,
  )

  const titleSessionContributions = intl.get('messages.sessionContributions.title')
  const messageSessionContributions = intl.get('messages.sessionContributions.message', {
    inProgressContributions: inProgressContributions,
    length: contributionsLogOrdened.length,
  })
  return (
    <ContributionsLoggerPure
      includeCustomElem={includeCustomElem}
      contributions={contributionsLogOrdened || []}
      textTitleSessionContributions={titleSessionContributions}
      textMessageSessionContributions={messageSessionContributions}
    />
  )
}

export default ContributionsLogger
