import { Fab, SvgIconTypeMap, Box, Popover } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import React from 'react'

interface IFabGroupButtonProps {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  Elements: JSX.Element[]
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  anchorEl: null | HTMLElement
  disabled: boolean
}

const FabGroupButton: React.FC<IFabGroupButtonProps> = ({ Icon, Elements, anchorEl, setAnchorEl, disabled }) => {
  const open = !!anchorEl

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handlePopClose = () => {
    setAnchorEl(null)
  }

  const Options = () => {
    return (
      <Popover
        onClose={handlePopClose}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 2, backgroundColor: 'transparent' }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '10px',
          }}
        >
          {Elements.map((el) => el)}
        </Box>
      </Popover>
    )
  }

  return (
    <>
      <Options />
      <Fab disabled={disabled} color="secondary" onClick={handleClickButton} size="small">
        <Icon />
      </Fab>
    </>
  )
}

export default FabGroupButton
