import React from 'react'
import useIntl from '../../hooks/useIntl'
import FullScreenSelectionDialogPure from './Pure'

interface IOptions {
  appBarElement: JSX.Element
  position: 'start' | 'end'
}

interface IFullScreenSelectionDialog {
  /**
   * Booleano para indicar se o dialog está aberto ou não
   */
  open: boolean
  /**
   * Título para o Dialog
   */
  title: string
  /**
   * Função para alternar abertura do dialog
   */
  toggleOpen: () => void
  /**
   * Função para executar a confirmação do dialog
   */
  handleConfirm: () => void
  /**
   * Texto para confirmação
   */
  confirmText?: string
  toolbarChildren?: IOptions
  children: JSX.Element
}

const FullScreenSelectionDialog: React.FC<IFullScreenSelectionDialog> = ({
  open,
  title,
  toggleOpen,
  handleConfirm,
  confirmText,
  children,
  toolbarChildren,
}) => {
  const intl = useIntl()

  const confirmString = confirmText ? confirmText : intl.get('messages.confirm')

  return (
    <FullScreenSelectionDialogPure
      open={open}
      title={title}
      toggleOpen={toggleOpen}
      handleConfirm={handleConfirm}
      confirmText={confirmString}
      appBarElement={toolbarChildren?.appBarElement}
      toolbarPosition={toolbarChildren?.position}
    >
      {children}
    </FullScreenSelectionDialogPure>
  )
}

export default FullScreenSelectionDialog
