import React, { createContext, useState, useContext, useCallback } from 'react'
import logger from '../logger'

const { log } = logger('useDrawer')

type DrawerContext = [boolean, (v?: boolean) => void]

// Precisamos de um estado inicial para o Context.
const Context = createContext<DrawerContext>([false, () => ({})])

export const ProviderDrawer: React.FC = ({ children }) => {
  const [isOpen, setDrawer] = useState<boolean>(false)

  const toggleDrawer = useCallback((v?: boolean) => {
    if (v === true || v === false) {
      log('Setando gaveta para', v)
      setDrawer(v)
    } else {
      log('Alternando gaveta')
      setDrawer((s) => !s)
    }
  }, [])

  return <Context.Provider value={[isOpen, toggleDrawer]}>{children}</Context.Provider>
}

export default (): DrawerContext => useContext(Context)
