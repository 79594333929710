import React, { useState, useRef, useCallback, useEffect, memo } from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'

import VideoCard from './Pure'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { copyToClipboard, getNewCategoriesText } from '../../../services/utils'
import {
  DocumentReference,
  DocumentReferenceGeneric,
  ISentenceData,
  VideoId,
  WorkspaceId,
  getDocumentReference,
  updateVideo,
} from 'collections'
import useIntl from '../../../hooks/useIntl'
import useContributionsPagination from '../../../services/hooks/useContributionsPagination'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { routes } from '../../../community'
import { getDownloadURL, ref } from 'firebase/storage'
import { useDatabase, useStorage } from 'reactfire'
import { backVideoStateFunction } from '../../../services/firebase'
import { setPhraseToRecordVideoPriority } from '../../../services/phrases'
import useTimelineCursor from '../../../hooks/useTimelineCursor'

interface IVideoCardUse {
  /** Estado atual do vídeo */
  _state: string
  /** Id do Cliente solicitante */
  clientId: string
  /** Grupo do corpus que o vídeo foi utilizado */
  corpusGroup: string
  /** Erros do vídeo */
  errors: {
    [userId: string]: string
  } | null
  /** Indicador se precisa criar sinais */
  needCreateSign: boolean
  /** Quantidade de segmentos do vídeo */
  numberOfSegments?: number
  /** Quantidade de vezes que o vídeo foi segmentado */
  numberOfSegmentations: number
  /** Quantidade de segmentos que faltam ser processados */
  segmentsToProcess: number | null
  /** Lista de sinais vinculados ao vídeo */
  signs?: string[]
  /** Nome do usuário que subiu este vídeo */
  displayName: string
  /** Sentença que será exibida como titulo do card */
  sentence: string
  /** Origem da sentença */
  sentenceOrigin?: string
  /** Categoria da sentença */
  sentenceCategory: string[] | null
  /** Path do vídeo que será adicionado ao player */
  videoSrc?: string
  /** Data de criação deste vídeo */
  createdAt: Date
  /** Usuário que criou o vídeo */
  createdBy: string
  /** Usuários que segmentaram o video */
  segmentedBy?: DocumentReferenceGeneric[] | null
  /** Indicador se o vídeo foi criado sob demanda (Sinal sob demanda) */
  createdOnDemand?: boolean
  /** Indicador se o vídeo está no corpus mais recente */
  usedOnTrain?: boolean
  /** Id do vídeo */
  videoId: VideoId
  /** Categorias das sentenças*/
  categories?: string[]
  /** Origens das sentenças*/
  origins?: string[]
  /** Condicional para saber se o usuário é um mantenedor */
  isMaintainer?: boolean
  /** Função para editar a segmentação do vídeo */
  handleOpenEditSegment?: (videoId: string, userId: string) => void
}

interface IVideoInitialState {
  sentenceOrigin?: string
  sentenceCategory: string[] | null
  clientId: string
  sentence: string
}

interface IKpData {
  face: [][]
  hand_l: [][]
  hand_r: [][]
  pose: [][]
}

/**
 * Card com informações sobre um determinado vídeo, este é o componente controlado
 */
const VideoCardUse: React.FC<IVideoCardUse> = ({
  _state,
  clientId,
  corpusGroup,
  errors,
  needCreateSign,
  numberOfSegmentations,
  segmentsToProcess,
  createdOnDemand,
  numberOfSegments,
  signs,
  sentence,
  sentenceOrigin,
  sentenceCategory,
  displayName,
  videoSrc,
  createdAt,
  createdBy,
  segmentedBy,
  usedOnTrain,
  videoId,
  categories,
  origins,
  isMaintainer,
  handleOpenEditSegment,
}) => {
  const intl = useIntl()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [videoSentence, setVideoSentence] = useState(sentence)
  const [videoClientId, setVideoClientId] = useState(clientId)
  const [videoSentenceCategories, setVideoSentenceCategories] = useState<string[] | null>(sentenceCategory)
  const [videoSentenceOrigin, setVideoSentenceOrigin] = useState<string | undefined>(sentenceOrigin)
  const [editInfo, setEditInfo] = useState(false)
  const [opennedConfirmSaveEditedInfoDialog, setOpennedConfirmSaveEditedInfoDialog] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [videoOptionsMenuEl, setVideoOptionsMenuEl] = useState<HTMLElement | null>(null)
  const [backVideoState, setBackVideoState] = useState<'RECORDED' | 'DELETED' | null>(null)
  const [opennedConfirmationDialog, setOpennedConfirmationDialog] = useState<
    'backVideoState' | 'editSegmentation' | null
  >(null)
  const [{ usersInfo }] = useContributionsPagination()
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const fsUser = useFsUserDocData()
  const storage = useStorage()
  const database = useDatabase()

  const showSnackbar = useSnackbar()

  const videoRef = useRef<HTMLVideoElement>(null)
  const videoElement = useRef<HTMLVideoElement>()
  const keypointsData = useRef<IKpData>()
  const framesData = useRef<string[]>()
  const displayingCanvas = useRef<boolean>()

  const segmentedByUsersDisplayName: string[] = []

  const videoInitialState: IVideoInitialState = {
    clientId: clientId,
    sentence: sentence,
    sentenceCategory: sentenceCategory,
    sentenceOrigin: sentenceOrigin,
  }

  segmentedBy?.map((user) => {
    segmentedByUsersDisplayName.push((usersInfo[user.id] && usersInfo[user.id].displayName) || 'Anonymous')
  })

  const toggleOpenConfirmSaveEditedInfoDialog = () => {
    setOpennedConfirmSaveEditedInfoDialog(!opennedConfirmSaveEditedInfoDialog)
  }

  const downloadVideoFile = async () => {
    setVideoOptionsMenuEl(null)
    const videoPath = `gs://ht-community-2/workspaces/${fsUser.workspace.id}/videos/${videoId}/${videoId}__ORIGINAL_VIDEO.mp4`
    if (storage && videoPath) {
      await getDownloadURL(ref(storage, videoPath)).then((url) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          const urlCreator = window.URL || window.webkitURL
          const videoUrl = urlCreator.createObjectURL(this.response)
          const tag = document.createElement('a')
          tag.href = videoUrl
          tag.target = '_blank'
          tag.download = `${videoId}__ORIGINAL_VIDEO.mp4`
          document.body.appendChild(tag)
          tag.click()
          document.body.removeChild(tag)
        }
        xhr.onerror = (err) => {
          showSnackbar(intl.get('pages.videoManager.others.downloadVideoFileFailed: ' + err), {
            variant: 'error',
          })
        }
        xhr.send()
      })
    }
  }

  /**
   * Botão de play/pause foi clicado
   */
  const handleToggleVideoState = useCallback(() => {
    if (!videoRef?.current) return

    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }

    setIsPlaying(!isPlaying)
  }, [isPlaying])

  /**
   * Vídeo foi assistido até o fim
   */
  const handleVideoEnded = useCallback(() => {
    setIsPlaying(false)
  }, [])

  /**
   * Vídeo está pronto e pode ser reproduzido
   */
  const handleVideoCanPlay = useCallback(() => {
    setIsLoading(false)
  }, [])

  /**
   * Vídeo está em processo de buffering, ou seja, carregando os proximos frames
   */
  const handleVideoWaiting = useCallback(() => {
    setIsLoading(true)
  }, [])

  /**
   * Vídeo saiu do processo de buffering ou entrou em estado de play por alguma outra razão
   */
  const handleVideoPlay = useCallback(() => {
    setIsLoading(false)
  }, [])

  /**
   * Botão copiar link foi clicado
   */
  const handleButtonCopyLinkClicked = useCallback(() => {
    try {
      copyToClipboard(`${window.location.origin}/videoManager/video/${videoId}`)
      showSnackbar(intl.get('pages.signSentence.videoCard.linkCopied'), {
        variant: 'info',
      })
    } catch (err) {
      showSnackbar(intl.get('pages.signSentence.videoCard.failedLinkCopied'), {
        variant: 'error',
      })
    }
  }, [showSnackbar, videoId, intl])

  /** Botão de edição foi clicado */
  const handleEditInfo = () => {
    setEditInfo(true)
  }

  /** Botão de cancelar edição foi clicado */
  const handleCancelEditInfo = () => {
    setEditInfo(false)
    setVideoSentence(videoInitialState.sentence)
    setVideoSentenceOrigin(videoInitialState.sentenceOrigin)

    let _videoSentenceCategories: string[] = []
    if (fsUser.oralLanguageId == 'por' && videoSentenceCategories && videoSentenceCategories?.length) {
      _videoSentenceCategories = getNewCategoriesText(videoSentenceCategories, false, true)
    } else _videoSentenceCategories = videoSentenceCategories || []
    setVideoSentenceCategories(_videoSentenceCategories)
  }

  /** Botão para recusar edição dos dados do vídeo foi clicado */
  const handleDisagreeUpdateInfo = () => {
    setVideoSentence(videoInitialState.sentence)
    setVideoSentenceOrigin(videoInitialState.sentenceOrigin)

    let _videoSentenceCategories: string[] = []
    if (fsUser.oralLanguageId == 'por' && videoSentenceCategories && videoSentenceCategories?.length) {
      _videoSentenceCategories = getNewCategoriesText(videoSentenceCategories, false, true)
    } else _videoSentenceCategories = videoSentenceCategories || []
    setVideoSentenceCategories(_videoSentenceCategories)
  }

  /** Botão de salvar edição foi clicado */
  const handleUpdateVideoInfo = () => {
    if (videoSentenceCategories?.length == 0) {
      showSnackbar(intl.get('pages.videoManager.others.saveCategoriesFailed'), {
        variant: 'error',
      })
      return
    }

    if (videoSentence == '') {
      showSnackbar(intl.get('pages.videoManager.others.saveVideoSentenceFailed'), {
        variant: 'error',
      })
      return
    }

    if (videoClientId == '') {
      showSnackbar(intl.get('pages.videoManager.others.saveClientIdFailed'), {
        variant: 'error',
      })
      return
    }

    toggleOpenConfirmSaveEditedInfoDialog()
  }

  /** Botão para voltar estado do vídeo foi clicado */
  const handleBackVideoState = (newState: 'RECORDED' | 'DELETED' | null) => {
    setVideoOptionsMenuEl(null)
    if (segmentsToProcess && segmentsToProcess !== numberOfSegments) {
      showSnackbar(intl.get('messages.existProcessedSegments'), {
        variant: 'error',
      })
      return
    }
    setBackVideoState(newState)
    setOpennedConfirmationDialog('backVideoState')
  }

  useEffect(() => {
    const htmlVideo = videoRef.current

    htmlVideo?.addEventListener('ended', handleVideoEnded)
    htmlVideo?.addEventListener('canplay', handleVideoCanPlay)
    htmlVideo?.addEventListener('play', handleVideoPlay)
    htmlVideo?.addEventListener('waiting', handleVideoWaiting)

    setIsLoading(true)

    return () => {
      htmlVideo?.removeEventListener('ended', handleVideoEnded)
      htmlVideo?.removeEventListener('canplay', handleVideoCanPlay)
      htmlVideo?.removeEventListener('play', handleVideoPlay)
      htmlVideo?.removeEventListener('waiting', handleVideoWaiting)
    }
  }, [handleVideoCanPlay, handleVideoEnded, handleVideoPlay, handleVideoWaiting])

  const saveBackVideoState = async () => {
    setOpennedConfirmationDialog(null)
    if (!backVideoState) {
      return
    }

    setIsLoading(true)

    // Dados da nova frase cadastrada
    const newSentence: ISentenceData = {
      priority: 10,
      sentence: sentence,
      sentenceOrigin: sentenceOrigin?.toUpperCase() || 'INTERNAL',
      sentenceCategory: sentenceCategory || ['undefined'],
      isValidated: false,
      corpusGroup: corpusGroup || 'TRAIN',
      clientId: clientId || 'HT',
      isRecording: false,
      needResearch: false,
      isRepeated: false,
      userRecording: '',
      isExternal: false,
    }

    backVideoStateFunction({
      videoId: videoId,
      newState: backVideoState,
      workspaceId: fsUser.workspace.id as WorkspaceId,
    })
      .then(
        () => {
          if (backVideoState === 'DELETED') {
            setPhraseToRecordVideoPriority(database, fsUser, newSentence)
          }

          showSnackbar(
            intl.get('messages.videoReturnedToQueue', {
              queue:
                backVideoState === 'DELETED'
                  ? intl.get('messages.recordingQueue')
                  : intl.get('messages.segmentationQueue'),
            }),
            { variant: 'success' },
          )
        },
        () => {
          showSnackbar(
            intl.get('messages.errorToReturnVideoState', {
              queue:
                backVideoState === 'DELETED'
                  ? intl.get('messages.recordingQueue')
                  : intl.get('messages.segmentationQueue'),
            }),
            { variant: 'error' },
          )
        },
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleActivateKeypoints = async () => {
    const keypointsPath = `/workspaces/${fsUser.workspace.id}/videos/${videoId}/${videoId}__MEDIAPIPE.json`
    const keypointsURL = await getDownloadURL(ref(storage, keypointsPath))
    const keypoints = await (await fetch(keypointsURL, { cache: 'force-cache' })).json()
    const framesPath = `/workspaces/${fsUser.workspace.id}/videos/${videoId}/${videoId}__FRAMES.json`
    const framesURL = await getDownloadURL(ref(storage, framesPath))
    const framesJson = await (await fetch(framesURL, { cache: 'force-cache' })).json()
    keypointsData.current = keypoints.data
    framesData.current = framesJson.frames
    if (keypointsData.current && framesData.current) {
      videoElement.current = videoRef.current!
      displayingCanvas.current = true
      setVideoOptionsMenuEl(null)
    }
  }

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  useEffect(() => {
    const call = async () => {
      setEditInfo(false)
      if (confirmed) {
        try {
          showSnackbar(intl.get('messages.savingData'), {
            variant: 'info',
          })

          const videoIdRef = getDocumentReference(fsUser.workspace, 'videos', videoId) as DocumentReference

          const sentence = videoSentence.normalize('NFKC')

          let _videoSentenceCategories: string[] = []
          if (fsUser.oralLanguageId == 'por' && videoSentenceCategories && videoSentenceCategories?.length) {
            _videoSentenceCategories = getNewCategoriesText(videoSentenceCategories, true)
          } else _videoSentenceCategories = videoSentenceCategories || []

          await updateVideo(videoIdRef, {
            sentence: sentence,
            clientId: videoClientId,
            sentenceCategory: _videoSentenceCategories,
            sentenceOrigin: videoSentenceOrigin,
          })

          showSnackbar(intl.get('messages.savedSuccessfully'), {
            variant: 'success',
          })
        } catch (err) {
          showSnackbar((err as TypeError | RangeError | EvalError).message, {
            variant: 'error',
          })
        }

        setConfirmed(false)
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed])

  useEffect(() => {
    if (fsUser.oralLanguageId == 'por' && videoSentenceCategories && videoSentenceCategories?.length) {
      const _videoSentenceCategories = getNewCategoriesText(videoSentenceCategories, false, true)
      setVideoSentenceCategories(_videoSentenceCategories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VideoCard
      id={videoId}
      _state={_state}
      clientId={videoClientId}
      corpusGroup={corpusGroup}
      errors={errors}
      needCreateSign={needCreateSign}
      numberOfSegmentations={numberOfSegmentations}
      segmentsToProcess={segmentsToProcess}
      createdOnDemand={createdOnDemand}
      numberOfSegments={numberOfSegments}
      signs={signs}
      videoRef={videoRef}
      videoElement={videoElement.current}
      createdAt={createdAt}
      createdBy={createdBy}
      segmentedBy={segmentedByUsersDisplayName}
      usedOnTrain={usedOnTrain}
      isLoading={isLoading}
      displayName={displayName}
      sentence={videoSentence}
      sentenceOrigin={videoSentenceOrigin}
      sentenceCategory={videoSentenceCategories}
      videoSrc={videoSrc}
      editInfo={editInfo}
      categories={categories}
      origins={origins}
      buttonIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      confirmSaveEditedInfoDialog={opennedConfirmSaveEditedInfoDialog}
      videoOptionsMenuEl={videoOptionsMenuEl}
      backVideoState={backVideoState}
      confirmationDialog={opennedConfirmationDialog}
      isMaintainer={isMaintainer}
      displayingCanvas={displayingCanvas.current!}
      keypointsData={keypointsData.current!}
      framesData={framesData.current!}
      isPlayingFramePlayer={isPlaying}
      timelineCursor={timelineCursor}
      onToggleVideoState={handleToggleVideoState}
      onClickCopyLinkButton={handleButtonCopyLinkClicked}
      handleChangeSentence={(e) => setVideoSentence(e.target.value)}
      handleChangeClientId={(e) => setVideoClientId(e.target.value)}
      handleEditInfo={handleEditInfo}
      handleClickCancel={handleCancelEditInfo}
      handleClickSave={handleUpdateVideoInfo}
      handleChangeCategories={(value: string[]) => {
        const undefinedId = value.findIndex((el) => {
          return el == intl.get('messages.undefined')
        })

        if (undefinedId != -1 && value.length > 1) return
        setVideoSentenceCategories(value)
      }}
      handleChangeOrigin={(
        event: React.ChangeEvent<{
          name?: string | undefined
          value: unknown
        }>,
      ) => {
        setVideoSentenceOrigin((event.target.value as string).toUpperCase())
      }}
      handleDisagreeConfirmation={() => {
        setOpennedConfirmSaveEditedInfoDialog(false)
        setConfirmed(false)
        handleDisagreeUpdateInfo()
      }}
      handleAgreeConfirmation={() => {
        setOpennedConfirmSaveEditedInfoDialog(false)
        setConfirmed(true)
      }}
      handleGoToProcessVideo={() => {
        setVideoOptionsMenuEl(null)
        window.open(`${routes.processVideo}#${videoId}`, '_blank', 'noreferrer')
      }}
      handleGoToHtubeSignInfo={(sign) => {
        window.open(routes.hTube.replace(':clusterId', sign), '_blank', 'noreferrer')
      }}
      handleDownloadVideoFile={downloadVideoFile}
      handleCloseVideoOptionsMenu={() => {
        setVideoOptionsMenuEl(null)
      }}
      handleOpenVideoOptionsMenu={(event: React.MouseEvent<HTMLButtonElement>) => {
        setVideoOptionsMenuEl(event.currentTarget)
      }}
      handleBackVideoState={(newState) => handleBackVideoState(newState)}
      handleEditSegmentation={() => {
        setOpennedConfirmationDialog('editSegmentation')
      }}
      handleOpenEditSegment={() => {
        if (segmentedBy) {
          handleOpenEditSegment && handleOpenEditSegment(videoId, segmentedBy[segmentedBy.length - 1].id)
        }
      }}
      saveBackVideoState={saveBackVideoState}
      toggleOpenConfirmationDialog={() => {
        setOpennedConfirmationDialog(null)
      }}
      handleActivateKeypoints={handleActivateKeypoints}
      handleDeactivateKeypoints={() => {
        displayingCanvas.current = false
        setVideoOptionsMenuEl(null)
      }}
      handleShowError={() => {
        showSnackbar(intl.get('pages.videoManager.others.framePlayerErro'), {
          variant: 'error',
        })
      }}
      handleOnChangedTime={handleOnChangedTime}
    />
  )
}

function propsAreEqual(prevProps: IVideoCardUse, nextProps: IVideoCardUse) {
  return prevProps.categories === nextProps.categories
}

export default memo(VideoCardUse, propsAreEqual)
