import { ISegment } from './types/segment'
import { addDoc, updateDoc, setDoc, getDoc, DocumentReference, CollectionReference } from 'firebase/firestore'
import { serverTimestamp, Timestamp } from '.'
import { IUser } from './types/user'
import { DocumentData, DocumentReferenceGeneric } from './types'
import { addFirestoreDocument, getCollectionReference } from './Base'

const createUpdateFunction = async (ref: DocumentReference, segmentData: Partial<ISegment>) => {
  return await updateDoc(ref, segmentData)
}

export const updateSegment = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, segmentData: Partial<ISegment>) => {
  return await addDoc(ref, segmentData)
}

export const addSegment = createAddFunction

const createSetFunction = async (ref: DocumentReference, segmentData: Partial<ISegment>) => {
  return await setDoc(ref, segmentData)
}

export const setSegment = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getSegment = createGetFunction

/** Retorna dados iniciais do novo segmento */
export const getBaseSegment = (
  fsUser: IUser,
  fsUserRef: DocumentReferenceGeneric,
  segmentData: Pick<ISegment, 'startFrame' | 'endFrame' | 'video' | 'type' | 'text'>,
) => {
  const segment: ISegment = {
    createdAt: serverTimestamp() as Timestamp,
    oralLanguageId: fsUser.oralLanguageId,
    signLanguageId: fsUser.signLanguageId,
    createdBy: fsUserRef,
    reported: false,
    hasMovement: false,
    movement: null,
    migratedTagSign: null,
    sign: null,
    usedOnSimilarityModel: false,
    wasMigratedFromTags: false,
    distanceToPrimary: 0,
    ...segmentData,
  }

  return segment
}

/**
 * Salva um novo segmento no firestore
 * @param workspaceRef - Referencia do workspace onde salvaremos o sinal
 * @param fsUser - Dados do usuario no firestore
 * @param fsUserRef - Referencia do usuario que está criando este sinal
 * @param segmentData - Dados do segmento sem a data de criação
 */
export const createSegment = async (
  workspaceRef: DocumentReferenceGeneric,
  fsUser: IUser,
  fsUserRef: DocumentReferenceGeneric,
  segmentData: Pick<ISegment, 'startFrame' | 'endFrame' | 'video' | 'type' | 'text'>,
): Promise<DocumentReference<DocumentData>> => {
  const segment = getBaseSegment(fsUser, fsUserRef, segmentData)

  return await addFirestoreDocument(getCollectionReference(workspaceRef, 'segments'), segment)
}
