import { IAbTestNmt } from './types/abTestNmt'
import { addDoc, updateDoc, setDoc, getDoc, DocumentReference, CollectionReference } from 'firebase/firestore'
import { DocumentReferenceGeneric, serverTimestamp, Timestamp } from './types/firebase'
import { getDocumentReference, setFirestoreDocument } from '.'

export const createAbTestNmt = (
  workspaceRef: DocumentReferenceGeneric,
  id: string,
  data: Pick<IAbTestNmt, 'version_a' | 'version_b' | 'scope' | 'totalSentences'>,
) => {
  const abTestNmt: IAbTestNmt = {
    createdAt: serverTimestamp() as Timestamp,
    version_a: data.version_a,
    version_b: data.version_b,
    scope: data.scope || '',
    totalSentences: data.totalSentences || 0,
  }

  return setFirestoreDocument(getDocumentReference(workspaceRef, 'abTestNmt', id), abTestNmt)
}

const createUpdateFunction = async (ref: DocumentReference, abTestNmtData: Partial<IAbTestNmt>) => {
  return await updateDoc(ref, abTestNmtData)
}

export const updateAbTestNmt = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, abTestNmtData: Partial<IAbTestNmt>) => {
  return await addDoc(ref, abTestNmtData)
}

export const addAbTestNmt = createAddFunction

const createSetFunction = async (ref: DocumentReference, abTestNmtData: Partial<IAbTestNmt>) => {
  return await setDoc(ref, abTestNmtData)
}

export const setAbTestNmt = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getAbTestNmt = createGetFunction
