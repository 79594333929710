import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { DocumentData, getCollectionReference, IPaginationConfig, IUser, IVideo, Query } from 'collections'
import { where, query, getDocs } from 'firebase/firestore'
import { OpenInNew } from '@material-ui/icons'
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useIntl from '../../../../hooks/useIntl'
import useSnackbar from '../../../../services/hooks/useSnackbar'
import { routes } from '../../../../community'
import { useCategories } from '../../../../hooks/useCategories'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const StyledLoaderWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const StyledLoader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export interface IApplicationPhrasesProps {
  open: boolean
  signId: string
  fsUser: IUser
  toggleOpen: () => void
}

export const ApplicationPhrasesDialog: FC<IApplicationPhrasesProps> = ({ open, signId, fsUser, toggleOpen }) => {
  const intl = useIntl()
  const showSnackbar = useSnackbar()

  const [sentences, setSentences] = useState<{ sentence: string; videoId: string; sentenceCategory: string }[]>([])
  const [sentencesToShow, setSentencesToShow] = useState<
    { sentence: string; videoId: string; sentenceCategory: string }[]
  >([])
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [categories, setCategories] = useState<string[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [paginationBack, setPaginationBack] = useState<boolean>(false)
  const { sentencesCategories } = useCategories()

  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>({
    currentPage: 0,
    firstOfList: null,
    lastOfList: null,
    rowsPerPage: 10,
    load: true,
  })

  const loadSentences = async () => {
    try {
      const videosQry = query(
        getCollectionReference(fsUser.workspace, 'videos') as Query<DocumentData>,
        where('signs', 'array-contains', signId),
      )

      const _sentences: {
        sentence: string
        videoId: string
        sentenceCategory: string
      }[] = []

      const videosSnapshot = await getDocs(query(videosQry))
      videosSnapshot.docs.forEach((videoSnapshot) => {
        const videoData = videoSnapshot.data() as IVideo
        const _sentenceCategory = videoData.sentenceCategory as string[]
        _sentences.push({
          sentence: videoData.sentence,
          videoId: videoSnapshot.id,
          sentenceCategory: _sentenceCategory.toString(),
        })
      })

      const _sentencesToShow = [..._sentences.slice(0, _sentences.length <= 10 ? _sentences.length : 10)]
      setSentences(_sentences)
      setSentencesToShow(_sentencesToShow)
      setLoadingData(false)
      setHasMoreResults(_sentences.length <= 9 ? false : true)
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const loadMore = async () => {
    try {
      const _lastSentence = sentences.indexOf(sentencesToShow[sentencesToShow.length - 1])
      let _sentencesToShow: {
        sentence: string
        videoId: string
        sentenceCategory: string
      }[]
      if (paginationBack == false) {
        _sentencesToShow = [...sentences.slice(_lastSentence + 1, _lastSentence + 11)]
        setSentencesToShow(_sentencesToShow)
      } else {
        const _firstSentence = sentences.indexOf(sentencesToShow[0])

        _sentencesToShow = [...sentences.slice(_firstSentence - 10, _firstSentence)]

        setSentencesToShow(_sentencesToShow)
      }

      setHasMoreResults(sentences.length - 1 == _lastSentence)
      setLoadingData(false)
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
        setPaginationBack(false)
      } else {
        setPaginationBack(true)
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
      setLoadingData(true)
    },
    [paginationConfig],
  )

  const handleChangeSentenceCategory = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const searchCategory = event.target.value as string
    setSelectedCategory(searchCategory)

    if (searchCategory != '') {
      const _filteredSentences: {
        sentence: string
        videoId: string
        sentenceCategory: string
      }[] = sentences.filter((el) => {
        return el.sentenceCategory.includes(searchCategory)
      })

      const _sentencesToShow = [..._filteredSentences]
      setSentences(sentences)
      setSentencesToShow(_sentencesToShow)
      setLoadingData(false)
      setHasMoreResults(_sentencesToShow.length <= 9 ? false : true)
    } else {
      loadSentences()
    }
  }

  useEffect(() => {
    setCategories(sentencesCategories)
    loadSentences()
    setSentencesToShow([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  useEffect(() => {
    if (sentences.length - 1 == sentences.indexOf(sentencesToShow[sentencesToShow.length - 1])) setHasMoreResults(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentencesToShow])

  return (
    <Dialog open={open} maxWidth="md" fullWidth style={{ marginTop: '50px' }}>
      <DialogTitle>{intl.get('pages.hTube.sign.applicationPhrase')}</DialogTitle>

      {loadingData && (
        <StyledLoaderWrapper>
          <StyledLoader>
            <CircularProgress />
          </StyledLoader>
        </StyledLoaderWrapper>
      )}

      {!loadingData && (
        <>
          <DialogContent>
            <Box style={{ margin: '10px' }}>
              <InputLabel id="x-label" style={{ minWidth: '5em', maxWidth: '10em', color: 'inherit' }}>
                {intl.get('pages.phraseManager.filters.categories')}
              </InputLabel>
              <Select
                id="grouped-select"
                style={{ color: 'inherit', width: '20%' }}
                onChange={handleChangeSentenceCategory}
                value={selectedCategory}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem key={''} value={''}>
                  {intl.get('pages.phraseManager.filters.removeFilter')}
                </MenuItem>
                {categories.map((category, key) => (
                  <MenuItem key={key} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {sentencesToShow.length == 0 && (
              <Box
                style={{
                  padding: '10px',
                  minHeight: '600px',
                  maxHeight: '600px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography>{intl.get('pages.abTestNmtResults.noData')}.</Typography>
              </Box>
            )}
            {sentencesToShow.length > 0 && (
              <>
                <TableContainer component={Paper} style={{ minHeight: '600px', maxHeight: '600px' }}>
                  <Table stickyHeader aria-label="phrases table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{intl.get('pages.segmentVideo.video.sentence')}</TableCell>
                        <TableCell align="center">{intl.get('pages.signOnDemand.sentenceCategory')}</TableCell>
                        <TableCell align="center">{intl.get('pages.hTube.sign.openVideo')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sentencesToShow.map((obj, index) => (
                        <StyledTableRow key={index}>
                          <TableCell component="th" scope="row">
                            {obj.sentence}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {obj.sentenceCategory}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Tooltip title={intl.get('pages.hTube.sign.openVideo')} style={{ cursor: 'pointer' }}>
                              <Link target="_blank" href={routes.videoManagerVideo.replace(':videoId', obj.videoId)}>
                                <OpenInNew />
                              </Link>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  style={{ pointerEvents: loadingData ? 'none' : 'visible' }}
                  component="div"
                  count={-1}
                  rowsPerPage={paginationConfig.rowsPerPage}
                  page={paginationConfig.currentPage}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={intl.get('messages.labelRows')}
                  labelDisplayedRows={({ from, to }) =>
                    intl.get('pages.animation.labelDisplayedRows', {
                      from,
                      to,
                    })
                  }
                  nextIconButtonProps={{ disabled: !hasMoreResults }}
                />
              </>
            )}
          </DialogContent>
        </>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              toggleOpen()
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
