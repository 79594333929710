import React, { useEffect, useState, useCallback } from 'react'

import { Box, Button, Card, CardContent, Grid, Popover, TextField, Tooltip, Typography, Chip } from '@material-ui/core'
import FullScreenSelectionDialog from '../../../../components/FullScreenSelectionDialog'
import {
  DocumentReferenceClient,
  getPathFramesJson,
  getSegment,
  ISegment,
  ISegmentView,
  ISignView,
  SignId,
  getFirestoreDocument,
  getCollectionReference,
  QueryDocumentSnapshot,
  IVideo,
} from 'collections'
import HoverablePlayer from '../../HoverablePlayer'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'
import useIntl from '../../../../hooks/useIntl'
import { useLocation, useRoute } from 'wouter'
import { routes } from '../../../../community'
import Preloader from '../../../../components/Preloader'
import { useFsUserDocData } from '../../../../hooks/useFsUser'
import { useStorage } from 'reactfire'
import { AddCircle } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/styles'
import useSnackbar from '../../../../services/hooks/useSnackbar'
import { getDownloadURL, ref } from '@firebase/storage'
import {
  getDocs,
  query as queryFirestore,
  orderBy,
  where,
  startAfter,
  limit,
  Query,
  DocumentData,
} from '@firebase/firestore'

interface IJoinClustersDialog {
  title: string
  open: boolean
  toggleOpen: () => void
  joinClusters: (signId: SignId, signIds: SignId[]) => Promise<string>
  words: string[]
  actualCluster: ISegmentView | null
  setIsLoadingOperation: (value: boolean) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    contentCard: {
      display: 'flex',
      padding: '16px',
      backgroundColor: '#5F5F5F',
    },
  }),
)

// Colocar em arquivo diferente?

const JoinClustersDialog: React.FC<IJoinClustersDialog> = ({
  title,
  open,
  toggleOpen,
  joinClusters,
  words,
  actualCluster,
  setIsLoadingOperation,
}) => {
  const [clustersToBeJoined, setClustersToBeJoined] = useState<SignId[]>([])
  const [similarClusters, setSimilarClusters] = useState<ISignView[]>([])
  const [opennedJoinDialog, setOpennedJoinDialog] = useState(false)
  const [, setIsLoadingClusters] = useState(true)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [lastSegment, setLastSegment] = useState<QueryDocumentSnapshot | null>(null)
  const [value] = useState('all')
  const [, routeParams] = useRoute(routes.hTube)
  const signId: SignId = routeParams?.clusterId || ''
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const storage = useStorage()
  const [, setLocation] = useLocation()
  const [searchText, setSearchText] = useState('')

  const openPopover = Boolean(anchorEl)
  const showSnackbar = useSnackbar()
  const classes = useStyles()

  const getVideoUrl = async (segment: ISegment, duplicateOf: string) => {
    const urlString = getPathFramesJson(fsUser.workspace.id, segment.video.id, duplicateOf)
    const url = await getDownloadURL(ref(storage, urlString))
    return url
  }

  const getClusters = useCallback(
    async (rewrite: boolean) => {
      setIsLoading(true)
      let _clusters: ISignView[] = [...similarClusters]
      if (rewrite) {
        _clusters = []
        setIsLoadingClusters(true)
      }
      const pageCount = 8
      let query = queryFirestore(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where('relatedWords', 'array-contains', value === 'all' ? searchText : value),
        orderBy('numberOfSegments', 'desc'),
      )

      if (lastSegment && !rewrite) {
        query = queryFirestore(query, startAfter(lastSegment))
      }

      const clustersSnapshot = await getDocs(queryFirestore(query, limit(pageCount)))

      for (const clusterSnapshot of clustersSnapshot.docs) {
        const _cluster = clusterSnapshot.data() as ISignView

        if (signId === clusterSnapshot.id) continue
        _cluster.id = clusterSnapshot.id
        _cluster.ref = clusterSnapshot.ref

        if (_cluster.primarySegment) {
          const segmentSnapshot = await getSegment(_cluster.primarySegment as DocumentReferenceClient)
          const _segmentData = segmentSnapshot.data() as ISegmentView
          const videoSnapshot = await getFirestoreDocument(_segmentData.video)
          const videoData = videoSnapshot.data() as IVideo
          _segmentData.videoUrl = await getVideoUrl(_segmentData, videoData.duplicateOf || '')
          _cluster.segment = _segmentData

          // Remover duplicados
          _clusters = _clusters.filter((c) => c.id !== _cluster.id)
          _clusters.push(_cluster)
        }
      }
      setSimilarClusters(_clusters)
      setHasMoreResults(clustersSnapshot.size == pageCount)
      setLastSegment(clustersSnapshot.docs[clustersSnapshot.docs.length - 1] || null)
      setIsLoading(false)
      setIsLoadingClusters(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser.workspace.id, lastSegment, words, similarClusters, value, searchText],
  )

  useEffect(() => {
    if (words.length && open) getClusters(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, words, open])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleActualCluster = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClick = (clusterId: SignId) => {
    if (clustersToBeJoined.length < 9 || clustersToBeJoined.includes(clusterId)) {
      const clusters = clustersToBeJoined.slice()
      const newList = clusters.includes(clusterId)
        ? clusters.filter((id) => id !== clusterId)
        : clusters.concat(clusterId)

      setClustersToBeJoined(newList)
    } else {
      showSnackbar(intl.get('pages.hTube.sign.clustersLimit'), {
        variant: 'info',
      })
    }
  }

  const toggleOpenJoinDialog = () => {
    setOpennedJoinDialog(!opennedJoinDialog)
  }

  const handleJoinClusters = async () => {
    toggleOpenJoinDialog()
    toggleOpen()
    showSnackbar(intl.get('messages.savingOperation'), {
      variant: 'info',
    })
    setIsLoadingOperation(true)
    const id = await joinClusters(signId, clustersToBeJoined)
    setIsLoadingOperation(false)
    setClustersToBeJoined([])
    setLocation(routes.hTube.replace(':clusterId', id || ''))
  }

  const handleTextSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = (event.target && event.target.value) || ''
      setSearchText(text)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText],
  )

  const onKeyUpSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      getClusters(true)
    }
  }

  const handleChipClick = (word: string) => {
    setSearchText(word)
  }

  const AppBarElement = () => {
    return (
      <Box display="flex" flexDirection="row">
        <Box ml={3}>
          <Button variant="outlined" onClick={handleActualCluster}>
            {intl.get('pages.hTube.sign.actualCluster')}
          </Button>
        </Box>
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Card style={{ width: '300px' }}>{actualCluster && <HoverablePlayer segment={actualCluster} />}</Card>
        </Popover>
        <Tooltip title={intl.get('pages.hTube.sign.clustersQuantity')}>
          <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }} ml={3}>
            <Box mr={1}>
              <AddCircle />
            </Box>
            <Typography style={{ paddingTop: '2px' }}>{clustersToBeJoined.length}</Typography>
          </Box>
        </Tooltip>
      </Box>
    )
  }

  return (
    <FullScreenSelectionDialog
      title={title}
      open={open}
      toggleOpen={toggleOpen}
      handleConfirm={toggleOpenJoinDialog}
      toolbarChildren={{
        position: 'start',
        appBarElement: AppBarElement(),
      }}
    >
      <Box>
        <Box
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            margin: '0px',
            display: 'flex',
          }}
        >
          <Box
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              margin: '0px',
              display: 'flex',
            }}
          >
            <TextField
              label={intl.get('messages.search')}
              variant="filled"
              value={searchText}
              disabled={isLoading}
              onKeyUp={onKeyUpSearch}
              onChange={handleTextSearchChange}
              style={{ margin: '5px 5px 0px 5px', width: '300px' }}
            />
            <Button
              color="primary"
              variant="contained"
              style={{ margin: '20px 0px 10px 15px', height: '60px' }}
              onClick={() => {
                getClusters(true)
              }}
            >
              {intl.get('messages.search')}
            </Button>
          </Box>
          <Box>
            {words.map((word: string) => (
              <Chip
                style={{ margin: '2px' }}
                key={word}
                color="primary"
                size="medium"
                label={word || ''}
                onClick={() => handleChipClick(word)}
              />
            ))}
          </Box>
        </Box>
        <Grid
          item
          container
          spacing={4}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            margin: '0px',
          }}
        >
          {similarClusters.map((cluster: ISignView) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={cluster.id} onClick={() => handleClick(cluster.id)}>
              <div
                style={{
                  border: clustersToBeJoined.includes(cluster.id) ? '3px solid #2196F3' : undefined,
                }}
              >
                <Card>
                  {cluster.segment && <HoverablePlayer key={cluster.id} segment={cluster.segment} />}
                  <CardContent className={classes.contentCard}>
                    <Typography variant="subtitle1">
                      {intl.get('messages.numberOfSegmentsWithNumber', {
                        number: cluster.numberOfSegments,
                      })}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
        {!hasMoreResults && !isLoading && (
          <Typography align="center">{intl.get('pages.signSentence.contributions.noMoreVideos')}</Typography>
        )}
        {isLoading && <Preloader asBlock text={intl.get('messages.loadingSegments')} />}
        {hasMoreResults && !isLoading && (
          <Box
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                getClusters(false)
              }}
            >
              {intl.get('messages.loadMore')}
            </Button>
          </Box>
        )}

        <ConfirmationDialog
          open={opennedJoinDialog}
          toggleOpen={toggleOpenJoinDialog}
          handleYes={handleJoinClusters}
          title={intl.get('pages.hTube.sign.joinClusters')}
          subtitle={intl.get('pages.hTube.sign.confirmJoin')}
        />
      </Box>
    </FullScreenSelectionDialog>
  )
}

export default JoinClustersDialog
