import React, { useEffect } from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'

import Preloader from '../../../components/Preloader'
import Layout from '../../../components/Layout'
import useContributionsPagination from '../../../services/hooks/useContributionsPagination'
import ContributionsHeader from './ContributionsHeader'
import { CommunityFeatures, VideoId, UserId } from 'collections'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { routes } from '../../../community'
import Link from '@material-ui/core/Link'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import useIntl from '../../../hooks/useIntl'

const Container = styled.div`
  min-width: 600px;
  width: 40%;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 600px) {
    min-width: unset;
    width: 100%;
    margin-top: unset;
  }
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.type === 'light' ? '#000' : '#FFF',
    },
  }),
)

const BlockGrid = styled(Grid)`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
`

interface IContributionsProps {
  path?: string
}
/**
 * Página de renderização de vídeos, aqui exibiremos o conteúdo de vídeos para os interpretes
 */
const Contributions: React.FC<IContributionsProps> = () => {
  const [{ videos, isApplyingFilters, hasMoreResults, usersInfo }, { loadMore, setCommunityModule }] =
    useContributionsPagination()

  const intl = useIntl()

  const classes = useStyles()

  useEffect(() => {
    setCommunityModule('segmentVideo')
    //loadMore(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getURLSegment = (videoId: VideoId, userId: UserId) => {
    return routes.segmentASign.replace(':videoId', videoId).replace(':user', userId)
  }

  const content = (
    <InfiniteScroll
      scrollableTarget="scrollable"
      dataLength={videos.length}
      endMessage={
        <BlockGrid item sm={12} lg={12}>
          <Typography>{intl.get('pages.segmentVideo.noVideoFound.title')}</Typography>
        </BlockGrid>
      }
      hasMore={hasMoreResults}
      next={loadMore}
      loader={
        <BlockGrid item sm={12} lg={12}>
          <Preloader asBlock text="Loading vídeos..." />
        </BlockGrid>
      }
    >
      <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
        <TableContainer component={Paper}>
          <Table aria-label="Segment video list">
            <TableHead>
              <TableRow>
                <TableCell>{intl.get('pages.segmentVideo.video.sentence')}</TableCell>
                <TableCell>{intl.get('messages.user')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videos.map(
                (video) =>
                  video.segmentedBy &&
                  video.segmentedBy.map((result, i) => (
                    <TableRow style={{ cursor: 'pointer' }} key={i}>
                      <TableCell>
                        <Link variant="body2" href={getURLSegment(video.videoId, result.id)} className={classes.link}>
                          {video.sentence}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link variant="body2" href={getURLSegment(video.videoId, result.id)} className={classes.link}>
                          {(usersInfo[result.id] && usersInfo[result.id].displayName) || 'Anonymous'}
                        </Link>
                      </TableCell>
                    </TableRow>
                  )),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </InfiniteScroll>
  )

  const loader = (
    <BlockGrid item sm={12} lg={12}>
      <Preloader asBlock text="Applying filters" />
    </BlockGrid>
  )

  return (
    <Layout
      requiredFeature={CommunityFeatures.signSentenceContribViewer}
      title={`${intl.get('modules.segmentVideo')} | Contributions`}
      imageBg
      id="scrollable"
    >
      <Container>
        <Card>
          <ContributionsHeader />
          <Box padding={2}>{!isApplyingFilters ? content : loader}</Box>
        </Card>
      </Container>
    </Layout>
  )
}

export default Contributions
