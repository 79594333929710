import { IGlobalAttr, IContributionLog, CommunityModule } from 'collections'
import React, { useEffect, useCallback, createContext, useContext, useState } from 'react'
import logger from '../logger'

const { log } = logger('useContributionsLogger.ts')

interface IContributionsLoggerFns {
  /**
   * Cria ou atualiza um log ja existente
   * @param identifier Identificador unico desta contribuição
   * @param progress Valor progresso atual desta contribuição
   * @param communityModule Modulo de origem da contribuição
   */
  update: (identifier: string, progress: number, communityModule: CommunityModule) => void
}

const initialOps = {
  update: () => {
    throw new Error('Estado não iniciado!')
  },
}

const initialState: IGlobalAttr['contributionsLog'] = {}

type ContributionsLoggerHookTuple = [Record<string, IContributionLog>, IContributionsLoggerFns]

const Context = createContext<ContributionsLoggerHookTuple>([initialState, initialOps])

/**
 * Gerencia todo fluxo de log de contribuições desta sessão
 */
export const ProviderContributionsLogger: React.FC = ({ children }) => {
  const [contributionsLog, setContributionsLog] = useState(initialState)

  useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      const inProgressContributions = Object.values(contributionsLog || {}).reduce(
        (prev, current) => prev + (current.progress != 1 ? 1 : 0),
        0,
      )

      if (inProgressContributions == 0) window.onbeforeunload = null
      else {
        // Impede a ação padrão do evento `beforeunload` que é descarregar a página
        event.preventDefault()

        // Define a propriedade `returnValue` do evento com uma mensagem personalizada
        // Esta mensagem será exibida no diálogo de confirmação do navegador
        event.returnValue = 'You have unsaved contributions! wait for all to be saved'
      }
    }
  }, [contributionsLog])

  /**
   * Cria ou atualiza um log ja existente
   * @param identifier Identificador unico desta contribuição
   * @param progress Valor progresso atual desta contribuição
   * @param communityModule Modulo de origem da contribuição
   * @param identifier Frase a ser exibida na lista de contribuições
   */
  const update = useCallback(
    (identifier: string, progress: number, communityModule: CommunityModule) => {
      const [sentence, createdAt] = identifier.split('##')
      const newContrib = {
        createdAt: createdAt ? new Date(+createdAt) : new Date(),
        identifier,
        sentence,
        progress,
        communityModule,
      }

      setContributionsLog((prev: Record<string, IContributionLog>) => {
        const contribs = {
          ...prev,
          [identifier]: newContrib,
        }

        log('Contribuições atuais: ', contribs)

        return contribs
      })
    },
    [setContributionsLog],
  )

  const fns: IContributionsLoggerFns = {
    update,
  }

  return <Context.Provider value={[contributionsLog, fns]}>{children}</Context.Provider>
}

export default (): ContributionsLoggerHookTuple => useContext(Context)
