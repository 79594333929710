import React from 'react'

import Box from '@material-ui/core/Box'
import StarRateIcon from '@material-ui/icons/StarRate'

interface IRatingProps {
  /** Numero de 0 a 5 representando o ranking atual */
  value: number
  /** Alinhamento do conteudo */
  justifyContent?: string
  /** Alinhamento dos itens */
  alignItems?: string
}

/**
 * Exibe uma classificação entre 0 e 5 estrelas
 */
const RatingPure: React.FC<IRatingProps> = ({ value, justifyContent = 'center', alignItems = 'center' }) => {
  return (
    <Box display="flex" justifyContent={justifyContent} alignItems={alignItems}>
      <StarRateIcon color={value > 0 ? 'inherit' : 'disabled'} />
      <StarRateIcon color={value > 1 ? 'inherit' : 'disabled'} />
      <StarRateIcon color={value > 2 ? 'inherit' : 'disabled'} />
      <StarRateIcon color={value > 3 ? 'inherit' : 'disabled'} />
      <StarRateIcon color={value > 4 ? 'inherit' : 'disabled'} />
    </Box>
  )
}

export default RatingPure
