import React, { useRef } from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import CameraStream from '../../CameraStream/index'
import useIntl from '../../../../hooks/useIntl'
const Wrapper = styled.div`
  height: 300px;
  margin: auto;
`

const FramingStep: React.FC = () => {
  const intl = useIntl()
  const videoRef = useRef<HTMLVideoElement>(null)
  return (
    <>
      <Typography>{intl && intl.get('pages.signSentence.lobby.framingStep.description')}</Typography>
      <Wrapper>
        <CameraStream
          showTemplate
          textUnableAccessCamera={intl.get('pages.signSentence.cameraStream.unableAccessCamera')}
          videoRef={videoRef}
        />
      </Wrapper>
    </>
  )
}

export default FramingStep
