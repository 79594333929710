import React, { useState } from 'react'
import {
  AuthProvider,
  useSigninCheck,
  useFirebaseApp,
  DatabaseProvider,
  FirestoreProvider,
  RemoteConfigProvider,
  AnalyticsProvider,
  StorageProvider,
} from 'reactfire'
import 'firebase/auth'
import { ProviderTheme } from './useTheme'
import { ProviderDrawer } from './useDrawer'
import { ProviderSnackbar } from './useSnackbar'
import { ProviderDialog } from './useDialog'
import { ProviderModuleSignSentence } from './useModuleSignSentence'
import { ProviderModuleVideoOnDemand } from './useModuleSignOnDemand'
import { ProviderModuleSignSentenceContributions } from './useContributionsPagination'
import { ProviderFeatureToggle } from './useFeatureToggle'
import { ProviderStage } from './useStage'
import { ProviderContributionsLogger } from './useContributionsLogger'
import Auth from '../../components/Auth'
import { getDatabase } from '@firebase/database'
import { getAuth } from '@firebase/auth'
import { getFirestore } from '@firebase/firestore'
import { getRemoteConfig } from '@firebase/remote-config'
import { getAnalytics } from '@firebase/analytics'
import { getStorage } from '@firebase/storage'
import { useLocation } from 'wouter'
import { ProviderLGPDCheck } from './useLGPDCheck'
import { getUser } from 'collections'
import { doc } from 'firebase/firestore'
import { User } from 'firebase/auth'
import { IntlProvider } from '../../hooks/useIntl'
/**
 * Embrulha um componente dentro do outro
 * @param Composed Componente de fora
 * @param Component Componente de dentro
 */
const wrapComponents = (Composed: React.FC, Component: React.FC) => {
  const NewComposed: React.FC = ({ children }) => (
    <Composed>
      <Component>{children}</Component>
    </Composed>
  )
  return NewComposed
}

const AllProviders: React.FC = ({ children }) => {
  const location = useLocation()[0]
  const [registeredUser, setRegisteredUser] = useState(false)
  // Nossa lista de providers por ordem de uso. Se um provider usar o hook de outro, ele tem que estar DEPOIS.

  const ComposedProviders = [
    ProviderTheme,
    ProviderSnackbar, // Esse context está causando o erro de props abaixo

    ProviderDrawer,
    ProviderContributionsLogger,
    //@ts-ignore
  ].reduce(wrapComponents)

  const RequiredAuthProviders = [
    IntlProvider,
    ProviderFeatureToggle,
    ProviderDialog,
    // ProviderFirebaseAnalytics,
    ProviderModuleSignSentence,
    ProviderModuleVideoOnDemand,
    ProviderStage,
    ProviderModuleSignSentenceContributions,
    ProviderLGPDCheck,
  ].reduce(wrapComponents)

  const checkRegisteredUser = async (user: User | null) => {
    if (!user) return
    const userRef = doc(firestore, 'users', user.uid)
    const userSnapshot = await getUser(userRef)
    if (userSnapshot.exists()) setRegisteredUser(true)
  }

  const AuthCheck = () => {
    const { status, data: signInCheckResult } = useSigninCheck()
    if (status === 'loading') {
      return <></>
    }

    if (signInCheckResult.user) {
      checkRegisteredUser(signInCheckResult.user)
    }

    //Rotas do motion para não exigir autenticação

    if (signInCheckResult.signedIn === true) {
      if (!registeredUser) return <></>
      else return <RequiredAuthProviders>{children}</RequiredAuthProviders>
    } else {
      // Não exigir autenticação para o registro
      if (location.includes('registration')) return <>{children}</>
      if (location.includes('motion')) return <>{children}</>
      return <Auth />
    }
  }

  const app = useFirebaseApp() // a parent component contains a `FirebaseAppProvider`

  // initialize Database and Auth with the normal Firebase SDK functions
  const database = getDatabase(app)
  const auth = getAuth(app)
  const firestore = getFirestore(app)
  const remoteConfig = getRemoteConfig(app)
  const analytics = getAnalytics(app)
  const storage = getStorage(app)

  return (
    <>
      {/**
       * Por enquanto estamos forçando que o usuário esteja logado
       * para utilizar todo sistem
       */}

      <AuthProvider sdk={auth}>
        <AnalyticsProvider sdk={analytics}>
          <DatabaseProvider sdk={database}>
            <FirestoreProvider sdk={firestore}>
              <RemoteConfigProvider sdk={remoteConfig}>
                <StorageProvider sdk={storage}>
                  <ComposedProviders>
                    <AuthCheck />
                  </ComposedProviders>
                </StorageProvider>
              </RemoteConfigProvider>
            </FirestoreProvider>
          </DatabaseProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </>
  )
}

export default AllProviders
