import { DocumentReferenceGeneric, IWorkspaceUserClaims, StatusSignOnDemand } from '.'
import { IContributionLog, IEntity } from './base'
import { DocumentReference, QueryDocumentSnapshot } from './firebase'
import { ISegmentView } from './segment'
import { IVideo } from './video'

export interface IMinPrioritySentenceValue {
  recordVideoPriority?: number
  translationPriority?: number
}

export interface IMinPrioritySentence {
  [workspaceId: string]: IMinPrioritySentenceValue
}

export interface IGlobalAttr {
  /** Sentença de um vídeo */
  sentence?: string
  /** Determina que uma sentença está carregando */
  isLoadingSentence?: boolean
  /** Origem da sentença */
  sentenceOrigin?: string
  /** Duração em segundos de um video */
  videoDuration?: number
  /** Contagem regressiva em segundos */
  countdown?: number
  /** Passo atual da contagem regressiva */
  countdownStep?: number
  /** Log de contribuições */
  contributionsLog?: Record<string, IContributionLog>
  /** Lista de tags da linha do tempo */
  timelineTags?: ITimelineRegion[]
  /** Referencia Video que está aberto atualmente */
  fsVideoRef?: DocumentReferenceGeneric
  /** Video que está aberto atualmente */
  fsVideoDocData?: IVideo
  /** Tradução sugerida por NMT */
  nmtSuggestion?: INmtRegion[]
  /** Validação de tradução que está em progresso */
  translationValidation?: INmtRegion[]
  /** Indica que estamos carregando uma sugestao da nmt */
  isLoadingNmtSuggestion?: boolean
  /** Dados da contribuição atual */
  contributionMetadata?: IContributionMetadata
  /** Tamanho da linha do tempo em pixels */
  timelinePixelsSize?: number
  /** Quantos pixels existem a esquerda da linha do tempo */
  timelineLeftPixelsOffset?: number
  /** Tag que está sob efeito do mouse */
  timelineTagHover?: ITimelineRegion
  /** Posição atual do cursor na linha do tempo */
  timelineCursorPosition?: number
  /** Qual ação de região está arrastada no momento */
  currentDraggingRegionAction?: IRegionAction
  /** Configuracões da aplicação */
  appConfig: IAppConfig
  /** Tempo de trabalho */
  jobMeter: IJobMeter
  /** Ultimo filtro da lista de sinais a serem animados */
  lastFilterSignList: string
  /** URL de ajuda do community */
  urlHelpCommunity?: string
  /** Filtro da lista de sinais sob demanda */
  filterSignOnDemand?: StatusSignOnDemand

  minPrioritySentences?: IMinPrioritySentence
  acceptedLgpd?: boolean | null
  /** Grupo do corpus */
  corpusGroup: TCorpusGroup
  /** Categoria da sentença */
  sentenceCategory: string[] | null
  /** id da sentença para gravação */
  sentenceToRecord?: string
  /** id do cliente */
  clientId?: string
  /** View do HTube */
  hTubeView: string
}

export interface INmtRegion {
  /** Identificar para facilitar a renderização de componentes */
  id: string
  signRef?: DocumentReference
  text?: string
}

export interface IContributionMetadata {
  /** Tempo em segundos liberar cancelar automaticamente a contribuição*/
  timeToRelease: number
  /** Contribuinte atual */
  contributor?: DocumentReference | null
  /** Data desde a ultima alteração de estado para 'em contribuição' */
  lastUpdate?: Date | null
  /** Indica que o usuário não pode contribuir agora, outra pessoa está contribuindo no momento */
  isLocked?: boolean
  /** Indica que o tempo chegou a zero */
  isTimerReachedZero?: boolean
}

export interface ITimelineRegion extends IRegionAction {
  id?: string
  startFrame: number
  endFrame: number
  text?: string | null
  ref?: DocumentReference
}

/**
 * Ações de transformação de regiões
 */
export interface IRegionAction {
  type: 'unknown' | 'create' | 'sign' | 'fingerspell'
  /** Referencia de um sinal, caso exista algum sinal vinculado */
  sign?: DocumentReference
  /** Representação escrita (glosa ou texto) desta região */
  representation: string
  /** Dados do segmento */
  segmentView?: ISegmentView
}

export interface IAppConfig {
  /** Quantidade de frames por segundo */
  frameRate: number
  /** Quantidade minima de frames que uma tag deve conter */
  minTagFramesDuration: number
}

/** Atributos da gravação do tempo de trabalho */
export interface IJobMeter {
  /** Data e hora de inicio da gravação */
  startDate: Date
  /** Determina se está contando o tempo de trabalho */
  isRunning: boolean
}

export enum DrawerName {
  users = 'users',
  workspaces = 'workspaces',
  shortcuts = 'shortcuts',
  otherSettings = 'otherSettings',
}

/** Propriedades do componente Drawer */
export interface IDrawerProps {
  /** Drawer selecionado */
  selectedDrawer?: DrawerName
  /** Evento para selecionar Drawer */
  setSelectedDrawer?: (drawerName: DrawerName) => void
}

/**
 * Nomes dos módulos do community
 */
export enum CommunityModuleName {
  signSentence = 'Record Video',
  tagVideo = 'Tagging',
  segmentVideo = 'Segment Video',
  findIdenticalSegments = 'Match Segments',
  reviewAnimation = 'Review Animation',
  animation = 'Animate',
  validateTranslation = 'Validate Translation',
  hTube = 'HTube',
  signOnDemand = 'Sign On Demand',
  processVideo = 'Process Video',
  phraseManager = 'Phrase Manager',
  updatePermissions = 'Update Permissions',
  abTestNmt = 'A/B Test',
  abTestNmtResults = 'A/B Test Results',

  manuallyTranslation = 'Manual Translation',
  videoManager = 'Video Manager',
}

export type LanguageKeypair = 'por-bzs' | 'eng-ase' | 'enb-bfi'

export enum CommunityFeatures {
  signSentenceContribViewer = 'signSentenceContribViewer',
  adminDashboard = 'adminDashboard',
  workspaceChange = 'workspaceChange',
}

export interface ITimelineCursorState {
  position: number
}

export interface ITimelineCursorFns {
  setPosition: (position: number) => void
}

export type TimelineCursorHookTuple = [ITimelineCursorState, ITimelineCursorFns]

export interface ITimeLineSegmentProps {
  sentence?: string
  timeFrame?: number
  timelineCursor: ITimelineCursorState
  timelineCursorFns: ITimelineCursorFns
  editing: boolean
  onlyOneSegment?: boolean
  duration?: number
  startFrame?: number
}

/**
 * Tagde um determinado video
 */
export interface ITag {
  /** Frame inicial desta marcação */
  startFrame: number
  /** frame final desta marcação */
  endFrame: number
  /** Determina se é um sinal ou datilologia */
  type: 'sign' | 'fingerspell'
  /** Se tiver texto é datilologia */
  text?: string
  /** se tiver sinal é sinal */
  sign?: DocumentReference
}

export interface IVideoTag extends IEntity {
  /**
   *  Vídeo onde as tags foram extraídas
   */
  video: DocumentReference
  /**
   * Array com informações sobre este vídeo no banco
   */
  tags: ITag[]
  /**
   * Estado atual deste tagset
   */
  status: null | 'verified' | 'problem'
}

export interface IUsersTableValue {
  id: string
  name: string
}

/** Propriedades do módulo admin */
export interface IUsersTable {
  oralLanguages: IUsersTableValue[]
  signLanguages: IUsersTableValue[]
}

/**
 * Claims que um usuário pode conter
 */
export interface IUserClaims {
  /** O usuário master tem acesso a todo sistema e pode trocar livremente de workspace*/
  master: boolean
  /**
   *  Permissões do usuário dentro de cada workspace
   *  @example
   * {
   *   'HT-ASL': {
   *      // Só tem acesso ao módulo de sinalização
   *      modules: ['signSentence']
   *      role: 'interpreter'
   *    },
   *   'development': {
   *      // não tem permissão para nada
   *      modules: []
   *      role: 'unknown'
   *    },
   * }
   */
  workspaces: Partial<Record<string, IWorkspaceUserClaims>>
}

/**
 * Paths do storage
 */

export const getVideoBasePath = (workspaceId: string, videoId: string): string =>
  `workspaces/${workspaceId}/${'videos'}/${videoId}`

export const getPathFramesJson = (workspaceId: string, videoId: string, duplicateOf: string): string =>
  `${getVideoBasePath(workspaceId, duplicateOf ? duplicateOf : videoId)}/${
    duplicateOf ? duplicateOf : videoId
  }__FRAMES.json`
export const getPathBlendFile = (workspaceId: string, animationId: string): string =>
  `workspaces/${workspaceId}/animations/${animationId}/${animationId}__ANIMATION.blend`
export const getPathVideoFile = (workspaceId: string, videoId: string): string =>
  `${getVideoBasePath(workspaceId, videoId)}/${videoId}__ORIGINAL_VIDEO.mp4`
export const getPathVideoCroppedFile = (workspaceId: string, videoId: string): string =>
  `${getVideoBasePath(workspaceId, videoId)}/${videoId}__CROPPED_VIDEO.mp4`
export const getPathVideoKeypointsFile = (workspaceId: string, videoId: string): string =>
  `${getVideoBasePath(workspaceId, videoId)}/${videoId}__MEDIAPIPE.json`

/**
 * Item de stage, aqui guardamos informações sobre o item que está na fila de contribuição
 */
export interface IStage {
  /** Lista de refencias  */
  references: DocumentReference[]
  isLoading?: boolean
  /** Determina que a ultima retornou 0 resultados */
  isEmpty: boolean
}

export interface IShortcuts {
  [keyShortcut: string]: string
}

export type TCorpusGroup = 'TRAIN' | 'VALIDATION'

export interface IOptionsSelect {
  value: string | number
  label: string
}

export interface IPaginationConfig {
  currentPage: number
  firstOfList: QueryDocumentSnapshot | null
  lastOfList: QueryDocumentSnapshot | null
  load: boolean
  rowsPerPage: number
}

export interface IMenuOptions {
  title: string
  onClick: () => void
}
