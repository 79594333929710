import { useGlobal } from 'reactn'
import { IGlobalAttr, ITimelineRegion } from 'collections'

interface ISentenceFns {
  /**
   * Seta uma nova tag que está sob foco do mouse
   * @param data dados da tag que está sob foco do mouse
   */
  set: (data?: ITimelineRegion) => void
}

type TimelineTagHoverHookTuple = [ITimelineRegion | undefined, ISentenceFns]

const useTimelineTagHover = (): TimelineTagHoverHookTuple => {
  const [timelineTagHover, setTimelineTagHover] = useGlobal<IGlobalAttr, 'timelineTagHover'>('timelineTagHover')

  const fns: ISentenceFns = {
    set: setTimelineTagHover,
  }

  return [timelineTagHover, fns]
}

export default useTimelineTagHover
