import React from 'react'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { ISignOnDemand } from 'collections'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

interface ISignsOnDemandListPureProps {
  /** Lista de sinais */
  data: ISignOnDemand[]
  /** Callback para reservar o sinal e gravar vídeo e segmentar */
  onSignOnDemandClick: (rowId: string) => void
  /** Texto da coluna Glosa */
  textGlosa: string
  /** Texto da coluna status */
  textStatus: string
  /** Texto da coluna animador */
  textRelatedWords: string
  /** Classes do useStyles */
  classes: ClassNameMap
  /** Função que retorna o status tratado com internacionalização */
  getStatus: (status: string, signOndemand: ISignOnDemand) => string
  /** Handle de edição */
  handleClickEditRow: (data: ISignOnDemand) => void
  /** Handle de exclusão */
  handleClickDeleteRow: (data: ISignOnDemand) => void
}

const SignRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }
`

const SignCell = styled(TableCell)`
  cursor: pointer;
`

/**
 *  Componente puro da listagem de sinais sob demanda
 */
const SignsOnDemandListPure: React.FC<ISignsOnDemandListPureProps> = ({
  data,
  onSignOnDemandClick,
  textGlosa,
  textStatus,
  textRelatedWords,
  classes,
  getStatus,
  handleClickEditRow,
  handleClickDeleteRow,
}) => {
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="Signs list">
        <TableHead>
          <TableRow>
            <TableCell>{textGlosa}</TableCell>
            <TableCell>{textRelatedWords}</TableCell>
            <TableCell>{textStatus}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <SignRow key={i} onClick={() => onSignOnDemandClick(row.id || '')}>
              <SignCell>{row.glosa || ''}</SignCell>
              <SignCell>{(row.portuguese || []).slice(0, 3).join(', ')}</SignCell>
              <SignCell>{getStatus(row.status || '', row) || ''}</SignCell>
              <SignCell>
                <IconButton
                  size="small"
                  disabled={row.status !== 'pending' && row.status !== 'request'}
                  onClick={(ev) => {
                    ev.stopPropagation()
                    handleClickEditRow(row)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </SignCell>
              <SignCell>
                <IconButton
                  size="small"
                  disabled={row.status !== 'pending' && row.status !== 'request'}
                  onClick={(ev) => {
                    ev.stopPropagation()
                    handleClickDeleteRow(row)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </SignCell>
            </SignRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SignsOnDemandListPure
