import React from 'react'
import styled from 'styled-components'
import { DropzoneState } from 'react-dropzone'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import PublishIcon from '@material-ui/icons/Publish'
import Zoom from '@material-ui/core/Zoom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'

const coloredDrive = require('../../../../images/colored_drive.png')

interface IUploadModalPureProps {
  /** Se o modal é visível */
  isOpen: boolean
  /** Callback para fechar o dialog */
  handleClose: () => void
  /** Propriedades do DnD */
  dropzoneState: DropzoneState
  /** Arquivo do blender */
  blendFile: File | null
  /** Texto clique ou arraste aqui */
  textClickOrDragHere: string
  /** Texto navegue */
  textNavigate: string
  /** Texto através de seus arquivos */
  textThroughYourFiles: string
  /** Texto instruções */
  textInstructions: string
  /** Texto arquivo blend */
  textBlendFile: string
  /** Texto titulo */
  textTitle: string
}

const DialogContainer = styled(Dialog)`
  && {
    border-radius: 10px;
  }
`

const DialogContent = styled(MuiDialogContent)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 58em;
    height: 27em;
  }
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    display: flex;
    align-items: center;
    padding: 28px 30px;
  }
`

const UploadIcon = styled(PublishIcon)`
  && {
    color: #c4c4c4;
    width: 50px;
    height: 50px;
    margin-bottom: 21px;
  }
`

const DndArea = styled(DialogContent)`
  cursor: pointer;
`

const UploadInput = styled.input`
  visibility: hidden;
`

const MainText = styled(Typography)`
  margin-top: 21px;
`

const VerifiedIcon = styled(CheckCircleIcon)`
  margin-right: 18px;
  color: ${(props: { isSet: boolean }) => (props.isSet ? `#4FEB4B` : '#C4C4C4')};
`

const DialogFooter = styled(MuiDialogActions)`
  && {
    padding: 36px 46px 27px;
    justify-content: flex-start;
    align-items: flex-start;
    height: 190px;
  }
`

const VerificationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const InstructionText = styled(Typography)`
  &&& {
    max-width: 271px;
    margin-top: 9px;
    margin-left: 44px;
    font-size: 14px;
  }
`

const FilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  align-items: center;
`

const ColoredSpan = styled.span`
  color: #2e81ff;
  font-weight: 600;
`

const FilingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-between;
`

const DriveIcon = styled(Avatar)`
  && {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
`

/**
 * Componente Puro de adição de arquivos de animação
 */
const UploadModalPure: React.SFC<IUploadModalPureProps> = ({
  isOpen,
  dropzoneState,
  blendFile,
  handleClose,
  textClickOrDragHere,
  textNavigate,
  textThroughYourFiles,
  textInstructions,
  textBlendFile,
  textTitle,
}) => {
  const { getRootProps, getInputProps } = dropzoneState

  return (
    <DialogContainer TransitionComponent={Zoom} onClose={handleClose} open={isOpen} maxWidth="lg">
      <DialogTitle disableTypography>
        <DriveIcon src={coloredDrive} alt="Símbolo do Google Drive" />
        <Typography>{textTitle}</Typography>
      </DialogTitle>

      <DndArea {...getRootProps()} dividers>
        <UploadIcon fontSize="large" />

        <MainText align="center" gutterBottom>
          {textClickOrDragHere} <ColoredSpan>{textNavigate}</ColoredSpan> <br /> {textThroughYourFiles}
        </MainText>
      </DndArea>

      <UploadInput id="dnd-area-file" type="file" multiple {...getInputProps()} accept=".blend, .txt" />

      <DialogFooter>
        <FilesContainer>
          <FilingContainer>
            <VerificationContainer>
              <VerifiedIcon isSet={!!blendFile} fontSize="large" />
              <Typography align="center">{textBlendFile}</Typography>
            </VerificationContainer>
          </FilingContainer>

          <Divider orientation="vertical" flexItem />
        </FilesContainer>

        <InstructionText>{textInstructions}</InstructionText>
      </DialogFooter>
    </DialogContainer>
  )
}

export default UploadModalPure
