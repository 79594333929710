import { createContext, useContext, useEffect, useRef } from 'react'
import intl from 'react-intl-universal'
import { useFsUserDocData } from './useFsUser'
import { OralLanguageId } from 'collections'

const locales = {
  por: require('../lang/ptBr.json'),
  eng: require('../lang/enUs.json'),
  enb: require('../lang/enUs.json'),
}

interface ISentenceStates {
  isLoading?: boolean
  sentence?: string
  origin?: string
}

interface IVariables {
  contributorName?: string
  sentence?: string
  sentenceState?: ISentenceStates
  variant?: string
  workspace?: string
  feedback?: string
  glosa?: string
  minTagFramesDuration?: number
  number?: number
  response?: string
  from?: number
  to?: number
  field?: string
  inProgressContributions?: number
  length?: number
  percentage?: number
  queue?: string
}

interface IIntlContextFNS {
  get: (key: string, variables?: IVariables) => string
}

// Create a new context
const IntlContext = createContext({} as IIntlContextFNS)

const useIntl = (): IIntlContextFNS => {
  return useContext(IntlContext)
}

// Intl Provider component
export const IntlProvider: React.FC = ({ children }) => {
  const fsUser = useFsUserDocData()
  const lastOralLanguageIntl = useRef<OralLanguageId | null>(null)
  const getInternationalization = (key: string, variables?: IVariables) => {
    if (variables) {
      return intl.get(key, variables).defaultMessage('Not implemented language')
    }
    return intl.get(key).defaultMessage('Not implemented language')
  }

  useEffect(() => {
    if (
      fsUser &&
      fsUser?.oralLanguageId &&
      (!lastOralLanguageIntl || fsUser?.oralLanguageId !== lastOralLanguageIntl.current)
    ) {
      const currentLocale = locales[fsUser?.oralLanguageId] ? fsUser?.oralLanguageId : 'eng'

      intl.init({
        currentLocale,
        locales,
      })
      lastOralLanguageIntl.current = fsUser?.oralLanguageId
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser?.oralLanguageId])

  // Create the context value
  const contextValue: IIntlContextFNS = {
    get: getInternationalization,
  }

  // Render the provider with the given children
  return <IntlContext.Provider value={contextValue}>{children}</IntlContext.Provider>
}

export default useIntl
