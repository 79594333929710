import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Box, CircularProgress, TextField } from '@material-ui/core'
import useIntl from '../../../hooks/useIntl'
import { useEffect } from 'react'
import { useState } from 'react'
import {
  IManuallyTranslation,
  Timestamp,
  getCollectionReference,
  IOptionsSelect,
  createManuallyTranslation,
  CollectionReferenceClient,
  IManuallyTranslationSentence,
  IManuallyTranslationTarget,
  getSignsBatch,
} from 'collections'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import Select from '../../../components/Select'
import { doc, DocumentReference } from '@firebase/firestore'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: '500px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

interface IModalIncludeManuallyTranslation {
  data: IManuallyTranslation | null
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const splitLine = (line: string, delimiter: string = ',') => {
  const items: string[] = []
  let item: string = ''
  let beginQuote: boolean = false
  line.split('').forEach((el, index) => {
    if (el == '"') {
      beginQuote = !beginQuote
      if (index == line.length - 1) items.push(item)
    } else if ((el == delimiter || index == line.length - 1) && !beginQuote) {
      if (index == line.length - 1) item += el
      items.push(item)
      item = ''
    } else {
      item += el
    }
  })
  return items
}

const ModalIncludeManuallyTranslation: React.FC<IModalIncludeManuallyTranslation> = ({ data, open, setOpen }) => {
  const intl = useIntl()

  const typesOptions: IOptionsSelect[] = [
    {
      label: intl.get('pages.manuallyTranslation.typeList.hugoTeaches'),
      value: 'hugo-ensina',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.dictionary'),
      value: 'dictionary',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.internalCampaign'),
      value: 'campanha-interna',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.customerDisclosure'),
      value: 'divulgacao-cliente',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.internalActivity'),
      value: 'atividade-interna',
    },
  ]
  const [isLoadingFile, setIsLoadingFile] = useState(false)

  const editing = data != null
  const [manuallyTranslation, setManuallyTranslation] = useState<Partial<IManuallyTranslation> | null>(
    editing
      ? { ...data }
      : {
          client: 'HT',
          type: 'atividade-interna',
          deadline: Timestamp.now(),
          createdAt: Timestamp.now(),
        },
  )
  const [file, setFile] = useState<File | null>(null)

  const showSnackbar = useSnackbar()
  const fsUser = useFsUserDocData()
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      setFile(null)
      setManuallyTranslation(
        data
          ? { ...data }
          : {
              client: 'HT',
              type: 'atividade-interna',
              deadline: Timestamp.now(),
              createdAt: Timestamp.now(),
            },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const checkRequiredFields = (attributes: (keyof IManuallyTranslation)[]) => {
    let result = true
    if (!manuallyTranslation) return false
    attributes.every((attr) => {
      if (!manuallyTranslation[attr]) {
        showSnackbar(
          intl.get('messages.requiredField', {
            field: intl.get(`pages.manuallyTranslation.${attr}`),
          }),
          { variant: 'error' },
        )
        result = false
      }
    })
    return result
  }

  const handleSaveManuallyTranslation = async () => {
    /** Verifica se todos os campos necessários estão preenchidos */
    const requiredFields: (keyof IManuallyTranslation)[] = ['projectName', 'client']
    if (!checkRequiredFields(requiredFields)) {
      return
    }
    if (!manuallyTranslation) return

    showSnackbar(intl.get('messages.savingData'), { variant: 'info' })

    if (!editing) {
      manuallyTranslation.status = 'requested'
      manuallyTranslation.requester = fsUser.displayName || ''
      manuallyTranslation.uid = ''
      manuallyTranslation.progress = 0
      manuallyTranslation.interpreter = []
      manuallyTranslation.context = null

      if (!manuallyTranslation.translationSentences) manuallyTranslation.translationSentences = []

      const manuallyTranslationsCollection = getCollectionReference(fsUser.workspace, 'manuallyTranslations')
      const newId = doc(manuallyTranslationsCollection as CollectionReferenceClient).id

      if (!manuallyTranslation.createdAt) return

      await createManuallyTranslation(fsUser.workspace, newId, {
        status: manuallyTranslation.status,
        translationSentences: manuallyTranslation.translationSentences || [],
        projectName: manuallyTranslation.projectName || '',
        client: manuallyTranslation.client || '',
        createdAt: manuallyTranslation.createdAt,
        uid: manuallyTranslation.uid,
        requester: manuallyTranslation.requester,
        type: manuallyTranslation.type || 'requested',
        deadline: manuallyTranslation.deadline || null,
        context: manuallyTranslation.context,
      })
    }

    handleClose()

    showSnackbar(intl.get('messages.savedSuccessfully'), {
      variant: 'success',
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const _file = e.target.files[0]
      setFile(_file)

      // Trata arquivo
      if (_file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
          setIsLoadingFile(true)
          try {
            if (!manuallyTranslation) return
            const text = e.target?.result as string
            if (text) {
              const dataText = text.split('\n')
              // Pega o cabeçalho
              const columnHeader = splitLine(dataText[0].toLowerCase().trim(), ',')

              const indexSource = columnHeader.indexOf('source')
              const indexTarget = columnHeader.indexOf('target')
              if (indexSource < 0) {
                console.log('erro na estrutura do arquivo, faltando coluna "source"')
                return
              }

              const arr: IManuallyTranslationSentence[] = []

              let signsIds: string[] = []
              dataText.map((line, index) => {
                if (index !== 0) {
                  const columns = splitLine(line, ',')
                  signsIds = signsIds.concat(
                    indexTarget > -1 && columns.length - 1 >= indexTarget && columns[indexTarget]
                      ? splitLine(columns[indexTarget], ' ')
                      : [],
                  )
                }
              })
              signsIds = signsIds.filter((el) => el.includes('¶'))

              signsIds = signsIds.map((el) => {
                return el.replace('¶', '').trim()
              })

              dataText.forEach((_line, index) => {
                const line = _line.trim()
                // pula a primeira linha (cabeçalho)
                if (index !== 0) {
                  if (line) {
                    const obj: IManuallyTranslationSentence = {
                      source: '',
                      verified: false,
                      target: [],
                    }
                    const columns = splitLine(line, ',')

                    obj.source = columns[indexSource] || ''
                    if (indexTarget > -1 && columns.length - 1 >= indexTarget && columns[indexTarget]) {
                      obj.target = splitLine(columns[indexTarget], ' ').map((el) => {
                        return {
                          word: (el.includes('¶') ? '' : el.replace('¶', '')).trim() || '',
                          signId: el.replace('¶', '').trim() || '',
                          type: el.includes('¶') ? 'sign' : 'fingerspell',
                        } as IManuallyTranslationTarget
                      })
                    }
                    arr.push(obj)
                  }
                }
              })
              const _manuallyTranslation = { ...manuallyTranslation }
              // resgatar words dos sinais
              if (signsIds.length > 0) {
                const signsBatch = await getSignsBatch(fsUser.workspace as DocumentReference, signsIds)

                arr.forEach((el) => {
                  el.target.forEach((target) => {
                    if (signsBatch[target.signId])
                      target.word = signsBatch[target.signId].glosa || signsBatch[target.signId].relatedWords[0]
                  })
                })
              }
              _manuallyTranslation.translationSentences = arr

              setManuallyTranslation(_manuallyTranslation)
            }
          } finally {
            setIsLoadingFile(false)
          }
        }
        reader.readAsText(_file)
      }
    }
  }

  const handleChangeManuallyTranslation = (
    attribute: keyof IManuallyTranslation,
    event:
      | React.ChangeEvent<HTMLElement>
      | React.ChangeEvent<{
          name?: string | undefined
          value: unknown
        }>,
    checked?: boolean,
  ) => {
    const _manuallyTranslation: Partial<IManuallyTranslation> = {
      ...manuallyTranslation,
    }

    if (checked != undefined) {
      //@ts-ignore
      _manuallyTranslation[attribute] = checked
    } else {
      //@ts-ignore
      _manuallyTranslation[attribute] = event.target.value
    }
    setManuallyTranslation(_manuallyTranslation)
  }

  const getDateString = (seconds: number | null) => {
    if (!seconds) return ''
    const date = new Date(seconds * 1000)
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
    const dateStr = date.toISOString()
    return dateStr.substr(0, dateStr.indexOf('.') - 3)
  }

  const handleChangeDeadline = (datetime: Timestamp) => {
    const _manuallyTranslation: Partial<IManuallyTranslation> = {
      ...manuallyTranslation,
    }
    if (datetime.seconds) {
      _manuallyTranslation.deadline = datetime
    } else {
      _manuallyTranslation.deadline = null
    }

    setManuallyTranslation(_manuallyTranslation)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Typography variant="h6">
            {`${intl.get(editing ? 'pages.signOnDemand.update' : 'pages.signOnDemand.add')} - ${intl.get(
              'modules.manuallyTranslation',
            )}`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <TextField
          variant={'outlined'}
          disabled={isLoadingFile}
          label={intl.get('pages.manuallyTranslation.projectName')}
          name={'projectName'}
          onChange={(ev) => {
            handleChangeManuallyTranslation('projectName', ev)
          }}
          value={manuallyTranslation?.projectName || ''}
        />
        <TextField
          variant={'outlined'}
          disabled={isLoadingFile}
          label={intl.get('pages.manuallyTranslation.client')}
          onChange={(ev) => {
            handleChangeManuallyTranslation('client', ev)
          }}
          value={manuallyTranslation?.client || ''}
        />
        <TextField
          type={'datetime-local'}
          variant={'outlined'}
          disabled={isLoadingFile}
          label={intl.get('pages.manuallyTranslation.deadline')}
          value={getDateString(manuallyTranslation?.deadline?.seconds || null)}
          onChange={(event) => {
            const deadline = new Date(event.target.value)

            handleChangeDeadline(Timestamp.fromDate(deadline))
          }}
        />
        <Box style={{ width: '100%' }}>
          <Select
            disabled={isLoadingFile}
            id="type-select"
            label={intl.get('messages.type')}
            value={manuallyTranslation?.type || 'campanha-interna'}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>,
            ) => {
              handleChangeManuallyTranslation('type', event)
              //setSearchType(event.target.value as string)
            }}
            optionsValues={typesOptions}
          />
        </Box>
        <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Button variant="contained" component="label">
            {`Upload ${(file && '(' + file.name + ')') || ''}`}
            <input hidden accept=".csv" onChange={handleFileChange} multiple={false} type="file" />
          </Button>
          {isLoadingFile && <CircularProgress />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleSaveManuallyTranslation()} color="primary" disabled={isLoadingFile}>
          {intl.get('messages.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalIncludeManuallyTranslation
