import { Timestamp, TimestampAdmin } from '.'
import { DocumentReference } from '.'

export enum EStatusTranslation {
  rejected = 'rejected',
  approved = 'approved',
  inProgress = 'in progress',
}
export interface IManuallyTranslationTarget {
  draggableId?: string
  signId: string
  type: 'sign' | 'fingerspell'
  htaAnimation?: string
  word: string

  animationExported: boolean
  waitingRevision: boolean
}

export interface IManuallyTranslationSentence {
  id?: string
  source: string
  target: IManuallyTranslationTarget[]
  verified: boolean
  legacyPDId?: string | null
  suggestions?: IManuallyTranslationTarget[]
}

export interface IManuallyTranslationErrors {
  message: string
  interpreter: DocumentReference
  createdAt: Timestamp
}

export type StatusManuallyTranslation = 'rejected' | 'approved' | 'inProgress' | 'requested' | 'finalized'

export interface IManuallyTranslation {
  id?: string
  createdAt: Timestamp | TimestampAdmin
  translationSentences: IManuallyTranslationSentence[]
  type: string
  deadline: Timestamp | TimestampAdmin | null
  client: string
  requester: string //nome do solicitante da demanda
  progress: number
  interpreter: DocumentReference[] | null
  projectName: string
  uid: string // id recebido do cms
  errors: IManuallyTranslationErrors[] | null
  status: StatusManuallyTranslation
  legacyPDIds: string[] | null
  searchTerms: string
  responsible: DocumentReference | null
  context: string | null
}
