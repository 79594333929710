import React, { createContext, useState, useContext, useCallback } from 'react'
import { useFirestore } from 'reactfire'
import logger from '../logger'
import { CommunityModule, IStage, DocumentReference, CollectionReferenceClient } from 'collections'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { doc, runTransaction } from '@firebase/firestore'
import { getCollectionReference } from 'collections'

const { log } = logger('useStage')

interface IStageState {
  /** Referencias armazenadas localmente */
  localReferences: Partial<Record<CommunityModule, DocumentReference[]>>
  /** Quantiade maxima de referencias que iremos estocar na memoria */
  maxLocalReferences: number
}

const initialState: IStageState = {
  localReferences: {},
  maxLocalReferences: 3,
}

interface IUpdateStateFunctions {
  shiftStage: (moduleName: CommunityModule) => Promise<DocumentReference | undefined>
}

const initialFunctions: IUpdateStateFunctions = {
  shiftStage: () => Promise.resolve(undefined),
}

type Context = [IStageState, IUpdateStateFunctions]

const Context = createContext<Context>([initialState, initialFunctions])

export const ProviderStage: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState)

  const fsUser = useFsUserDocData()

  const firestore = useFirestore()

  /**
   * Processa o stage obtendo e removendo a primeira entrada da fila utilizando transaction
   * @param moduleName - Módulo que gerencia a fila atual
   */
  const dequeueStageTransaction = useCallback(
    (moduleName: CommunityModule) => {
      const stageRef = doc(getCollectionReference(fsUser.workspace, 'stage') as CollectionReferenceClient, moduleName)
      return runTransaction(firestore, async (transaction) => {
        const snap = await transaction.get(stageRef)
        const data = snap.data() as IStage

        // A coleção ainda não existe, iremos criar agora
        if (!data) {
          log('Ainda não existe um stage para este workspace. Criando nova coleção')
          await transaction.set(stageRef, {
            references: [],
          })
          return
        }

        log('referencias obtidas')

        const { references } = data
        const values = references.splice(0, state.maxLocalReferences)

        transaction.update(stageRef, { references })

        return values
      })
    },
    [firestore, fsUser.workspace, state.maxLocalReferences],
  )

  /**
   * Pega o primeiro elemento da fila
   * @param moduleName Modulo que estamos trabalhando
   */
  const shiftStage = useCallback(
    async (moduleName: CommunityModule) => {
      const referencesClone = { ...state.localReferences }

      const hasKey = Object.keys(referencesClone).includes(moduleName)

      // Caso a chave não exista ou se a quantidade de refs for menor ou igual a 1
      // Iremos pegar novos dados e inserir localmente
      if (!hasKey || referencesClone[moduleName]!.length < 1) {
        log('Obtendo novos dados na fila')
        const references = await dequeueStageTransaction(moduleName)

        referencesClone[moduleName] = references
      }

      // Valor que iremos retornar
      const value = referencesClone[moduleName]?.shift()

      log(`Current ${value?.path}`)
      // Seta o novo estado
      setState((prev) => ({
        ...prev,
        localReferences: referencesClone,
      }))

      return value
    },
    [dequeueStageTransaction, state.localReferences],
  )

  const updateStateFns: IUpdateStateFunctions = {
    shiftStage,
  }

  return <Context.Provider value={[state, updateStateFns]}>{children}</Context.Provider>
}

export default (): Context => useContext(Context)
