import React, { useState, useEffect, useCallback } from 'react'

import ContributionMetadataViewPure from './Pure'
import useContributionMetadata from '../../hooks/useContributionMetadata'
import Timer from '../Timer'
import { useFsUserDocRef } from '../../hooks/useFsUser'
import { getUserByIdFunction } from '../../services/firebase'
import useIntl from '../../hooks/useIntl'

interface IContributionMetadataViewProps {
  avatarEl: JSX.Element
}

const ContributionMetadataView: React.FC<IContributionMetadataViewProps> = ({ avatarEl }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [time, setTime] = useState(0)
  const [text, setText] = useState('')
  const intl = useIntl()
  const [contributionMetadataState, contributionMetadataFns] = useContributionMetadata()
  const fsUserRef = useFsUserDocRef()

  const handleTimeZeroReached = useCallback(() => {
    contributionMetadataFns.setIsTimerReachedZero(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const call = async () => {
      if (!contributionMetadataState?.contributor || !contributionMetadataState.lastUpdate) return

      const { contributor, lastUpdate, timeToRelease } = contributionMetadataState
      setIsLoading(true)

      if (contributor.id == fsUserRef.id) {
        setText(intl.get('messages.youAreContributing'))
      } else {
        const data = await getUserByIdFunction({ userId: contributor.id })
        const contributorName = data.data.user.displayName || 'Someone'
        const capitalizedContributorName = contributorName.charAt(0).toUpperCase() + contributorName.slice(1)
        setText(
          intl.get('messages.someoneIsContributing', {
            contributorName: capitalizedContributorName,
          }),
        )
      }

      let diff = (new Date().getTime() - lastUpdate.getTime()) / 1000
      diff = Math.round(diff)

      let remainTime = timeToRelease - diff
      remainTime = Math.round(remainTime)
      remainTime = Math.max(remainTime, 0)

      if (remainTime === 0) {
        contributionMetadataFns.setIsTimerReachedZero(true)
      }

      setTime(remainTime)
      setIsLoading(false)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributionMetadataState, fsUserRef.id])

  return (
    <ContributionMetadataViewPure
      text={text}
      isLoading={isLoading}
      avatarEl={avatarEl}
      timerEl={
        <Timer timerDirection="decrease" onTimeZeroReached={handleTimeZeroReached} startTime={time} variant="compact" />
      }
    />
  )
}

export default ContributionMetadataView
