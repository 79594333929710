import { IWorkspace } from './types/workspace'
import { addDoc, updateDoc, setDoc, DocumentReference, CollectionReference } from 'firebase/firestore'
import { CommunityModule, Contrib, DocumentReferenceGeneric, isDocumentReferenceAdmin } from '.'
import { getFirestoreDocument } from './Base'

const createUpdateFunction = async (ref: DocumentReferenceGeneric, workspaceData: Partial<IWorkspace>) => {
  if (isDocumentReferenceAdmin(ref)) {
    return new Promise(() => ref.update(workspaceData))
  } else {
    return await updateDoc(ref as DocumentReference, workspaceData)
  }
}

export const updateWorkspace = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, workspaceData: Partial<IWorkspace>) => {
  return await addDoc(ref, workspaceData)
}

export const addWorkspace = createAddFunction

const createSetFunction = async (ref: DocumentReference, workspaceData: Partial<IWorkspace>) => {
  return await setDoc(ref, workspaceData)
}

export const setWorkspace = createSetFunction

const createGetFunction = async (ref: DocumentReferenceGeneric) => {
  return await getFirestoreDocument(ref)
}

export const getWorkspace = createGetFunction

/**
 * Contribuições por timestamp, estamos catalogando contribuições por dia,
 * então utilize o timestamp do dia.
 * Ex.:
 * ```
 * {
 *  1574362420977: {
 *    "por-bzs": 10,
 *    "eng-ase": 12,
 *  },
 *  1574362433874: {
 *    "por-bzs": 5,
 *    "eng-ase": 7,
 *  },
 * }
 * ```
 */
export type TimestampedContribs = Record<number, Contrib>

/**
 * Total de contribuições por módulo do community
 * Ex.:
 * ```
 * {
 *  // Nome do módulo
 *  "signSentence": {
 *    // Timestamp do dia
 *    1574362420977: {
 *      // Chave: Keypair de lingua --- valor: Quantidade de contribuições
 *      "por-bzs": 10,
 *      "eng-ase": 12,
 *    },
 *    1574362433874: {
 *      "por-bzs": 5,
 *      "eng-ase": 7,
 *    },
 *  },
 *  "tagVideo": {
 *    1574362420977: {
 *      "por-bzs": 3,
 *      "eng-ase": 2,
 *    },
 *    1574362433874: {
 *      "por-bzs": 1,
 *      "eng-ase": 0,
 *    },
 *  }
 * }
 * ```
 */
export type WorkspaceContrib = Partial<Record<CommunityModule, TimestampedContribs>>
