import React, { useRef } from 'react'
import { Box, Typography } from '@material-ui/core'
import { IKeypoints } from '../../types/keypoints'
import useMediaPipeCamera from '../../hooks/useMediapipeCamera'

interface IMediaPipeCameraProps {
  onFrame?: (mpResultsList: IKeypoints) => void
  cameraEnabled: boolean
  showKeypoints: boolean
}

const MediapipeCamera: React.FC<IMediaPipeCameraProps> = ({ onFrame, cameraEnabled, showKeypoints }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const fpsDivRef = useRef<HTMLDivElement>(null)
  const { isLoading } = useMediaPipeCamera({
    canvasRef,
    videoRef,
    onFrame,
    showKeypoints,
    cameraEnabled,
  })

  return (
    <div
      style={{
        flex: '1 1 0',
        position: 'relative',
        display: 'flex',
        height: '100%',
      }}
    >
      <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
        <video
          muted
          playsInline={true}
          controls={false}
          hidden={showKeypoints || !cameraEnabled}
          ref={videoRef}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '19px',
            borderTopRightRadius: '19px',
            transform: 'rotateY(180deg)',
          }}
        />
        {cameraEnabled && !isLoading && (
          <canvas
            ref={canvasRef}
            hidden={!showKeypoints}
            width="1280"
            height="720"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '19px',
              borderTopRightRadius: '19px',
            }}
          ></canvas>
        )}
        <div ref={fpsDivRef} />
        {!cameraEnabled && !isLoading && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography>Camera OFF</Typography>
          </Box>
        )}
        {isLoading && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography>Loading...</Typography>
          </Box>
        )}
      </div>
    </div>
  )
}

export default MediapipeCamera
