import React from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import VideocamIcon from '@material-ui/icons/Videocam'
import HistoryIcon from '@material-ui/icons/History'
import DoneIcon from '@material-ui/icons/Done'
import LabelIcon from '@material-ui/icons/Label'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { IContributionLog } from 'collections'
import { Hidden } from '@material-ui/core'

interface IContributionsLoggerPureProps {
  contributions: IContributionLog[]
  textTitleSessionContributions: string
  textMessageSessionContributions: string
  includeCustomElem?: JSX.Element
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: '#fff',
    },
    iconButton: {
      padding: '8px',
      [theme.breakpoints.up('md')]: {
        padding: '12px',
      },
    },
  }),
)

/**
 * Renderiza a view pura do menu de configurações
 */
const ContributionsLoggerPure: React.FC<IContributionsLoggerPureProps> = ({
  contributions,
  textTitleSessionContributions,
  textMessageSessionContributions,
  includeCustomElem,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useStyles()
  const inProgressContributions = contributions.reduce((prev, current) => prev + (current.progress != 1 ? 1 : 0), 0)

  const open = !!anchorEl

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handlePopClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <Badge badgeContent={inProgressContributions} color="secondary">
          <HistoryIcon className={classes.icon} />
        </Badge>
      </IconButton>
      <Popover onClose={handlePopClose} open={open} anchorEl={anchorEl} style={{ zIndex: 9999 }}>
        <Card>
          <CardHeader
            title={textTitleSessionContributions}
            subheader={textMessageSessionContributions}
            avatar={<HistoryIcon />}
            action={
              <IconButton size="small" onClick={handleClick}>
                <CloseIcon />
              </IconButton>
            }
          />

          <List style={{ width: 360, maxHeight: 300, overflow: 'auto' }}>
            {contributions.map((contrib, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>{contrib.communityModule === 'signSentence' ? <VideocamIcon /> : <LabelIcon />}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography noWrap>{contrib.sentence}</Typography>}
                  secondary={contrib.progress !== 1 ? 'saving...' : contrib.createdAt.toLocaleString()}
                />
                <ListItemSecondaryAction>
                  {contrib.progress === 1 ? (
                    <DoneIcon />
                  ) : (
                    <CircularProgress
                      size={20}
                      value={contrib.progress * 100}
                      variant={contrib.progress == 0 ? 'indeterminate' : 'determinate'}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {includeCustomElem && (
              <Hidden mdUp>
                <ListItem>
                  <ListItemText primary={<React.Fragment>{includeCustomElem}</React.Fragment>} />
                </ListItem>
              </Hidden>
            )}
          </List>
        </Card>
      </Popover>
    </>
  )
}

export default ContributionsLoggerPure
