import {
  IManuallyTranslation,
  IManuallyTranslationSentence,
  IManuallyTranslationTarget,
  ISign,
  StatusManuallyTranslation,
} from 'collections'
import axios from 'axios'
import { sendMail } from './utils'

const baseUrl = 'https://us-central1-ht-fb-e3130.cloudfunctions.net' // Produção
// const baseUrl = 'https://us-central1-handtalk-firebase-dev.cloudfunctions.net' //Dev

interface ICMSRequest {
  path: string
  body: IFinalizeProjectBody | IChangeStatusProjectBody
}

interface IFinalizeRequestData {
  data: IManuallyTranslation
  signsData: Record<string, ISign>
}

interface IChangeStatusRequestData {
  id: string
  uid: string
  userName: string
  message?: string

  status: StatusManuallyTranslation
  projectName: string
}

interface IProductTranslation {
  source: string
  target: string[]
  relatedWords?: string[]
  regionOfUse?: string[]
  searchReference?: string
}

interface IFinalizeProjectBody {
  uid: string
  translationSentence: IProductTranslation[]
}

interface IChangeStatusProjectBody {
  uid: string
  status: StatusManuallyTranslation
  info: {
    message?: string
    interpreter: string
  }
}

const callRequest = ({ path, body }: ICMSRequest) => {
  return axios.post(`${baseUrl}/${path}`, body, {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  })
}

export const finalizeCMSProject = (id: string, { data, signsData }: IFinalizeRequestData) => {
  if (!data.uid) return
  const translationSentence: IProductTranslation[] = []
  data.translationSentences.forEach((translation: IManuallyTranslationSentence) => {
    if (data.type == 'dictionary') {
      const signData = translation.target.length == 1 ? signsData[translation.target[0].signId] || null : null
      translationSentence.push({
        source: translation.source,
        target: translation.target.map((el: IManuallyTranslationTarget) =>
          el.type === 'fingerspell' ? `@${el.word}` : el.signId,
        ),
        relatedWords: translation.target.length !== 1 ? [] : signData ? signData.relatedWords : [],
        regionOfUse: translation.target.length !== 1 ? [] : signData ? signData.regionOfUse : [],
        searchReference: translation.target.length !== 1 ? '' : signData ? signData.searchReference : '',
      })
    } else {
      translationSentence.push({
        source: translation.source,
        target: translation.target.map((el: IManuallyTranslationTarget) =>
          el.type === 'fingerspell' ? `@${el.word}` : el.signId,
        ),
      })
    }
  })

  const body: IFinalizeProjectBody = {
    uid: data.uid,
    translationSentence: translationSentence,
  }

  try {
    const messageEmail: string = `Projeto finalizado com sucesso!\n
    URL: http://community.handtalk.me/manuallyTranslation/${id}`

    sendMail({
      to: 'linguistica@ia.handtalk.me',
      subject: `[Community - Tradução Manual] ${data.projectName}`,
      text: messageEmail,
      cc: 'joab@handtalk.me',
    })
  } catch (err) {
    console.log(err)
  }

  return callRequest({
    path: 'finalizeC3poRequest',
    body: body,
  })
}

export const changeCMSStatus = ({ id, uid, status, userName, message, projectName }: IChangeStatusRequestData) => {
  if (!uid) return
  const body: IChangeStatusProjectBody = {
    uid,
    status,
    info: {
      message,
      interpreter: userName,
    },
  }

  try {
    let messageEmail: string = ''
    switch (status) {
      case 'approved':
        messageEmail = 'Solicitação aprovada'
        break
      case 'inProgress':
        messageEmail = 'Solicitação em progresso'
        break
      case 'rejected':
        messageEmail = `Solicitação rejeitada\nMotivo: ${message}\nIntérprete: ${userName}`
        break
    }
    messageEmail += `\nURL: http://community.handtalk.me/manuallyTranslation/${id}`

    sendMail({
      to: 'linguistica@ia.handtalk.me',
      subject: `[Community - Tradução Manual] ${projectName}`,
      text: messageEmail,
      cc: 'joab@handtalk.me',
    })
  } catch (err) {
    console.log(err)
  }

  return callRequest({
    path: 'changeC3poReqStatus',
    body: body,
  })
}
