import React, { useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  IconButton,
  Popper,
  CircularProgress,
  Fade,
  makeStyles,
  createStyles,
  Box,
  TableFooter,
  Theme,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { ISegmentView, ISignView } from 'collections'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import CloseIcon from '@material-ui/icons/CloseOutlined'

import CardHTube from '../../pages/HTube/Main/CardHTube'
import useIntl from '../../hooks/useIntl'

interface IHTubeAccordeon {
  firstLetter: string
  expanded: boolean
  onChange: (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void
  onClose: () => void
  playVideo: (sign: string) => void
  page: number
  signs: ISignView[]
  segment: ISegmentView | undefined
  loading: boolean
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedSignId: React.Dispatch<React.SetStateAction<string>>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoPopper: {
      backgroundColor: '#424242',
      borderRadius: '4px',
      boxShadow: '0 0 1px #000000',
      '& > button': {
        display: 'flex',
        marginLeft: 'auto',
        '&:hover': {
          background: 'none',
        },
      },
      '& > div': {
        margin: '0px',
      },
    },
    videoLoading: {
      backgroundColor: '#424242',
      borderRadius: '4px',
      boxShadow: '0 0 1px #000000',
      width: '340px',
      height: '330px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    table: {
      cursor: 'pointer',
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tablePagination: { display: 'none' },
  }),
)

const HTubeAccordion: React.FC<IHTubeAccordeon> = ({
  firstLetter,
  expanded,
  onChange,
  onClose,
  playVideo,
  page,
  signs,
  segment,
  loading,
  onPageChange,
  setOpenInfo,
  setSelectedSignId,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  const [popoverEl, setPopoverEl] = React.useState<HTMLButtonElement | null>(null)
  const [selectedSign, setSelectedSign] = useState<ISignView | null>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleOpenPopover = (sign: ISignView) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    playVideo(sign.id)
    setSelectedSign(sign)
    setPopoverEl(event.currentTarget)
    setOpen(true)
  }

  const handleClickItem = (id: string) => {
    setSelectedSignId(id)
    setOpenInfo(true)
    window.history.replaceState(null, '', `#${id}`)
  }

  const handleClose = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation()
    setOpen(false)
    onClose()
  }

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{`${intl.get('messages.signsStartedWith')} "${firstLetter.toUpperCase()}"`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              margin: '50px',
              gap: '20px',
              height: '474px',
            }}
          >
            <CircularProgress />
            <Typography>{intl.get('messages.loadingData')}</Typography>
          </Box>
        ) : (
          <Table style={{ border: '1px solid rgba(81, 81, 81, 1)' }} size="medium">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '250px' }}>{intl.get('pages.signOnDemand.glosa')}</TableCell>
                <TableCell align="left">{intl.get('pages.signOnDemand.relatedWords')}</TableCell>
                <TableCell align="right">{intl.get('messages.showSign')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signs
                .filter((el) => el.firstLetter?.includes(firstLetter))
                .sort((el1, el2) =>
                  el2.glosa.toLowerCase()[0] == firstLetter.toLowerCase()
                    ? 10
                    : -1 - (el1.glosa.toLowerCase()[0] == firstLetter.toLowerCase() ? 10 : -1),
                )
                .map((sign) => (
                  <TableRow
                    className={classes.table}
                    key={sign.id}
                    onClick={() => {
                      handleClickItem(sign.id)
                    }}
                  >
                    <TableCell style={{ width: '250px' }}>{sign.glosa}</TableCell>
                    <TableCell align="left">
                      {sign.relatedWords
                        ? sign.relatedWords
                            .sort((el1, el2) =>
                              el2.toLowerCase()[0] == firstLetter.toLowerCase()
                                ? 10
                                : -1 - (el1.toLowerCase()[0] == firstLetter.toLowerCase() ? 10 : -1),
                            )
                            .join(', ')
                        : ''}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={handleOpenPopover(sign)}>
                        <PlayArrowIcon fontSize="medium" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  classes={{ caption: classes.tablePagination }}
                  count={-1}
                  rowsPerPage={5}
                  page={page}
                  onPageChange={onPageChange}
                  rowsPerPageOptions={[]}
                  labelRowsPerPage={''}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </AccordionDetails>
      <Popper open={open} anchorEl={popoverEl} placement="bottom" transition>
        {!segment ? (
          <Box className={classes.videoLoading}>
            <CircularProgress />
          </Box>
        ) : (
          <Fade in={open} timeout={350}>
            <Box className={classes.videoPopper}>
              <IconButton disableFocusRipple disableRipple onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              {selectedSign && (
                <CardHTube
                  key={selectedSign.id}
                  signId={selectedSign.id}
                  words={[]}
                  state={selectedSign._state}
                  numberOfSegments={selectedSign.numberOfSegments}
                  setOpenInfo={setOpenInfo}
                  setSelectedSignId={setSelectedSignId}
                  segment={segment}
                />
              )}
            </Box>
          </Fade>
        )}
      </Popper>
    </Accordion>
  )
}

export default HTubeAccordion
