import { useGlobal } from 'reactn'
import { useCallback, useEffect } from 'react'

import logger from '../services/logger'
import { IGlobalAttr } from 'collections'
import { asyncTimeout } from '../services/utils'

const { log } = logger('useCountdown')

interface ICountdownFns {
  /**
   * Inicia a Contagem regressiva
   */
  run: () => Promise<void>
  /**
   * Seta um valor em segundos diretamente
   * @param value Duração em segundos que será aplicada na contagem
   */
  set: (value: number) => void
}

interface ICountdownStates {
  countdown: number
  countdownStep: number
  minValue: number
  maxValue: number
}

type CountdownHookTuple = [ICountdownStates, ICountdownFns]

/**
 * Gerencia uma contagem regressiva, no minimo 0 e no maximo 10 segundos
 */
const useCountdown = (): CountdownHookTuple => {
  const [countdown, setCountdown] = useGlobal<IGlobalAttr, 'countdown'>('countdown')
  const [countdownStep, setCountdownStep] = useGlobal<IGlobalAttr, 'countdownStep'>('countdownStep')

  // Componente foi montado iremos pegar o valor armazenado no localstorage
  useEffect(() => {
    const storaged = parseInt(localStorage.getItem('countdown') || '3')
    setCountdown(storaged)
    setCountdownStep(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Limites
  const minValue = 0
  const maxValue = 10

  /**
   * Seta o countdown diretamente ao contador
   * @param value Sentença que será aplicada
   */
  const set = useCallback(
    (value: number) => {
      // Trava o novo valor entre o valor minimo e maximo
      const newCountdown = Math.min(Math.max(minValue, value), maxValue)
      setCountdown(newCountdown)
      localStorage.setItem('countdown', newCountdown.toString())
      log(`alterado para ${newCountdown}`)
    },
    [setCountdown],
  )

  /**
   * Inicia a contagem regressiva
   */
  const run = async () => {
    // Realiza a contagem de n segundos
    await Array(countdown)
      .fill(0)
      .reduce(async (promise, _, index) => {
        // aguarda promessa anterior
        await promise
        // seta o overlay
        setCountdownStep((countdown || 0) - index)
        // espera 1 segundo
        await asyncTimeout(1000)
      }, Promise.resolve())
    setCountdownStep(0)
  }

  const state: ICountdownStates = {
    countdown: countdown || 0,
    countdownStep: countdownStep || 0,
    minValue,
    maxValue,
  }

  const fns: ICountdownFns = {
    run,
    set,
  }

  return [state, fns]
}

export default useCountdown
