// Enums
// **********************************************************************

import { DocumentReferenceGeneric } from '.'
import { LanguagePair, OralLanguageId, SignLanguageId } from './base'

// Valid Firestore operations
export enum FirestoreOperation {
  set = 'set',
  update = 'update',
  delete = 'delete',
}

// Community modules created, it doesn't matter if they are depracated or not.
export type CommunityModule =
  | 'signSentence'
  | 'tagVideo'
  | 'validateTranslation'
  | 'animation'
  | 'segmentVideo'
  | 'reviewAnimation'
  | 'findIdenticalSegments'
  | 'hTube'
  | 'signOnDemand'
  | 'processVideo'
  | 'phraseManager'
  | 'updatePermissions'
  | 'abTestNmt'
  | 'abTestNmtResults'
  | 'manuallyTranslation'
  | 'videoManager'

export interface IModule {
  module: CommunityModule
  name: string
}

// Existing Workspace IDs
export enum WorkspaceId {
  asl = 'HT-ASL',
  annoy = 'HT-Annoy',
  bzs = 'HT-BZS',
  bfi = 'HT-BSL',
  bzsJessica = 'HT-BZS-JESSICA', // *
  bzsJoab = 'HT-BZS-JOAB', // *
  bzsMaiara = 'HT-BZS-MAIARA', // *
  bzsTarcisio = 'HT-BZS-TARCISIO', // *
  default = 'default',
  development = 'development',
}

// User roles availables
export enum UserRole {
  user = 'user',
  admin = 'admin',
  master = 'master',
  unknown = 'unknown',
  interpreter = 'interpreter',
  workspaceAdmin = 'workspaceAdmin',
}

// Module shortcuts available to users
export enum CommunityShortcuts {
  correctValidationTranslate = 'correctValidationTranslate',
  missingSignsValidationTranslate = 'missingSignsValidationTranslate',
  outOfContextValidationTranslate = 'outOfContextValidationTranslate',
  deleteSignSentence = 'deleteSignSentence',
  recordFinishSignSentence = 'recordFinishSignSentence',
  responseYesMatchSegments = 'responseYesMatchSegments',
  responseNoMatchSegments = 'responseNoMatchSegments',
  responseSkipMatchSegments = 'responseSkipMatchSegments',
  playPauseVideoMatchSegments = 'playPauseVideoMatchSegments',
  saveSegments = 'saveSegments',
  skipSegments = 'skipSegments',
}

// Types
// **********************************************************************

export type UserId = string
export type Role = 'reader' | 'writer'
export type UserOperation = FirestoreOperation

/**
 * Contribuições de acordo com a chave do par de língua
 * Contributions according to the language pair key
 * {
 *  "eng-ase": 25,
 *  "por-bzs": 67,
 * }
 */
export type Contrib = Partial<Record<LanguagePair, number>>

/**
 * Contributions by modules
 * {
 *  "findIdenticalSegments": {
 *    "eng-ase": 22,
 *    "por-bzs": 35,
 *  },
 *  "segmentVideo": {
 *    "por-bzs": 35,
 *  },
 * }
 */
export type ModuleContrib = Partial<Record<CommunityModule, Contrib>>

/**
 * Every contribution which a user has done, divided by worskpaces, modules and language pair key
 * {
 *  "HT-Annoy": {
 *    "findIdenticalSegments": {
 *      "eng-ase": 22,
 *    },
 *    "segmentVideo": {
 *      "eng-ase": 35,
 *    },
 *  },
 *  "HT-BZS": {
 *    "signSentence": {
 *      "por-bzs": 535,
 *    },
 *  },
 * }
 */
export type TotalUserContrib = Partial<Record<WorkspaceId, ModuleContrib>>

// Interfaces
// **********************************************************************

export interface IUser {
  /**
   * Lista de módulos permitidos para este usuário
   */
  modules: CommunityModule[]
  /**
   * Workspace que este usuário pode acessar
   */
  workspace: DocumentReferenceGeneric
  signLanguageId: SignLanguageId
  oralLanguageId: OralLanguageId
  isExternal: boolean
  /**
   * Total de contribuições por workspace do community
   * @example
   * {
   *  // Id do workspace
   *  "default": {
   *    // Nome do modulo
   *    "signSentence": {
   *      // Chave: Keypair de lingua --- valor: Quantidade de contribuições
   *      "por-bzs": 10,
   *      "eng-ase": 12,
   *    },
   *    "tagVideo": {
   *      "por-bzs": 0,
   *      "eng-ase": 0,
   *    },
   *  },
   *  "HT-ASL": {
   *    "signSentence": {
   *      "por-bzs": 3,
   *      "eng-ase": 2,
   *    },
   *    "tagVideo": {
   *      "por-bzs": 1,
   *      "eng-ase": 0,
   *    },
   *  }
   * }
   */
  contribs?: UserContribs
  /** Teclas de atalho do usuário */
  shortcuts?: Partial<Record<CommunityShortcuts, number>>
  /** Grupo de função do usuário */
  role?: UserRole
  /** Tempo padrão do usuário no módulo de gravação de vídeo */
  timeVideoRecord?: number

  displayName?: string
}

/**
 * Contribuições por módulo id
 * Ex.:
 * ```
 * {
 *  "signSentence": {
 *    "por-bzs": 120,
 *    "eng-ase": 0,
 *  },
 *  "tagVideo": {
 *    "por-bzs": 0,
 *    "eng-ase": 100,
 *  },
 * }
 * ```
 */
export type ContribsByModule = Partial<Record<CommunityModule, Contrib>>

/**
 * Total de contribuições por workspace do community
 * Ex.:
 * ```
 * {
 *  // Id do workspace
 *  "default": {
 *    // Nome do modulo
 *    "signSentence": {
 *      // Chave: Keypair de lingua --- valor: Quantidade de contribuições
 *      "por-bzs": 10,
 *      "eng-ase": 12,
 *    },
 *    "tagVideo": {
 *      "por-bzs": 0,
 *      "eng-ase": 0,
 *    },
 *  },
 *  "HT-ASL": {
 *    "signSentence": {
 *      "por-bzs": 3,
 *      "eng-ase": 2,
 *    },
 *    "tagVideo": {
 *      "por-bzs": 1,
 *      "eng-ase": 0,
 *    },
 *  }
 * }
 * ```
 */
export type UserContribs = Partial<Record<WorkspaceId, ContribsByModule>>

export interface IUserInfo {
  displayName?: string
  email?: string
  photoURL?: string
}

/**
 * Informações secretas do usuário, só pode ser acessado via funcions
 */
export interface IUserSecret {
  /** Id do usuário */
  uid?: UserId
  /** Nome do usuário no sistema */
  displayName?: string
  /** Email do usuário no sistema */
  email?: string
  /** Workspace atual do usuário */
  workspaceId?: WorkspaceId
  /** Papel do usuário no workspace atual */
  role?: UserRole | null
  /** Modulos que o usuário pode acessar */
  modules?: CommunityModule[]
  /** Lingua oral do usuário */
  oralLanguageId?: string
  /** Língua de sinais do usuário */
  signLanguageId?: string
  /** Referência do workspace */
  workspace?: DocumentReferenceGeneric
}

/**
 * Dados de contribuições de um determinado user
 */
export interface IBigqueryContribution {
  user: {
    uid: UserId
    email: string
    displayName: string
  }
  workspace: {
    id: WorkspaceId
    oralLanguageId: OralLanguageId
    signLanguageId: SignLanguageId
  }
  moduleName: CommunityModule
  date: Date
}
