import { useEffect, useState, useCallback } from 'react'
import { CommunityModule, CommunityShortcuts, IShortcuts } from 'collections'
import { useFsUserDocRef, useFsUserDocData } from '../hooks/useFsUser'
import { useFirestore } from 'reactfire'
import useSnackbar from '../services/hooks/useSnackbar'
import useIntl from './useIntl'
import { runTransaction } from '@firebase/firestore'
/**
 * O objetivo desse hook é executar o evento de acordo com a tecla de atalho
 * @param communityModule - Módulo do shortcut
 */

interface IUseShortcuts {
  shortcutEvent: string | undefined
  saveShortcuts: (_shortcuts: IShortcuts) => Promise<void>
}

const useShortcuts = (communityModule?: CommunityModule): IUseShortcuts => {
  const [shortcutEvent, setShortcutEvent] = useState<string>()
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const showSnackbar = useSnackbar()
  const firestore = useFirestore()
  const intl = useIntl()

  const getEventActionShortcut = useCallback((shortcut: number) => {
    if ('validateTranslation' == communityModule) {
      switch (shortcut) {
        case fsUser?.shortcuts?.correctValidationTranslate:
          return CommunityShortcuts?.correctValidationTranslate
        case fsUser?.shortcuts?.outOfContextValidationTranslate:
          return CommunityShortcuts?.outOfContextValidationTranslate
        case fsUser?.shortcuts?.missingSignsValidationTranslate:
          return CommunityShortcuts?.missingSignsValidationTranslate
      }
    } else if ('signSentence' == communityModule) {
      switch (shortcut) {
        case fsUser?.shortcuts?.deleteSignSentence:
          return CommunityShortcuts?.deleteSignSentence
        case fsUser?.shortcuts?.recordFinishSignSentence:
          return CommunityShortcuts?.recordFinishSignSentence
      }
    } else if ('findIdenticalSegments' == communityModule) {
      switch (shortcut) {
        case fsUser?.shortcuts?.responseYesMatchSegments:
          return CommunityShortcuts?.responseYesMatchSegments
        case fsUser?.shortcuts?.responseNoMatchSegments:
          return CommunityShortcuts?.responseNoMatchSegments
        case fsUser?.shortcuts?.responseSkipMatchSegments:
          return CommunityShortcuts?.responseSkipMatchSegments
        case fsUser?.shortcuts?.playPauseVideoMatchSegments:
          return CommunityShortcuts?.playPauseVideoMatchSegments
      }
    } else if ('segmentVideo' == communityModule) {
      switch (shortcut) {
        case fsUser?.shortcuts?.saveSegments:
          return CommunityShortcuts?.saveSegments
        case fsUser?.shortcuts?.skipSegments:
          return CommunityShortcuts?.skipSegments
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    const shortcut = event.keyCode
    const _shortcutEvent = getEventActionShortcut(shortcut)
    setShortcutEvent(_shortcutEvent)
    setShortcutEvent('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveShortcuts = async (_shortcuts: IShortcuts) => {
    await runTransaction(firestore, async (transaction) => {
      transaction.update(fsUserRef, {
        shortcuts: _shortcuts,
      })
    })
    showSnackbar(intl.get('messages.saveSuccessfully'), { variant: 'success' })
  }

  return { shortcutEvent, saveShortcuts }
}

export default useShortcuts
