import React, { Suspense } from 'react'
import { FirebaseAppProvider } from 'reactfire'
import { Router, Switch } from 'wouter'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { fbApp } from '../services/firebase'
import AllProviders from '../services/hooks'
import Preloader from './Preloader'
import { ProviderCamera } from '../services/hooks/useCamera'

interface IAppPreloaderProps {
  children: JSX.Element[]
}

/**
 * Este componente simples serve para embrulhar o app, aqui nos temos:
 * AllProviders - Render de todos os providers;
 * MuiPickersUtilsProvider - Extensão do frebase ui para seletores de data
 * Router - Roteador de nossa aplicação
 */
const AppWrapper: React.FC<IAppPreloaderProps> = ({ children }) => {
  return (
    <>
      <ProviderCamera>
        <FirebaseAppProvider firebaseApp={fbApp} suspense={true}>
          {/**
           * Provider de pickers de data e tempo para facilitar os seletores de filtros
           * em algumas paginas de administração.
           * Documentação oficial: https://material-ui-pickers.dev/
           */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Suspense fallback={<Preloader text="Loading providers" />}>
              <AllProviders>
                <Router>
                  <Switch>{children}</Switch>
                </Router>
              </AllProviders>
            </Suspense>
          </MuiPickersUtilsProvider>
        </FirebaseAppProvider>
      </ProviderCamera>
    </>
  )
}

export default AppWrapper
