import { AppBar, Box, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core'
import { FC } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { HTubeInfo } from '..'
import useIntl from '../../../../hooks/useIntl'

interface IModalSignInfo {
  signId: string
  openInfo: boolean
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedSignId: React.Dispatch<React.SetStateAction<string>>
}

const ModalSignInfo: FC<IModalSignInfo> = ({ signId, openInfo, setOpenInfo, setSelectedSignId }) => {
  const intl = useIntl()

  const handleCloseInfo = () => {
    setOpenInfo(false)
    setSelectedSignId('')
    window.history.replaceState(null, '', window.location.pathname)
  }

  return (
    <Dialog fullScreen open={openInfo} id="scrollableDiv">
      <AppBar color="default" style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCloseInfo} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography style={{ flex: 1 }} variant="h6" component="div">
            {intl.get('messages.info')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <HTubeInfo id={signId} />
      </Box>
    </Dialog>
  )
}

export default ModalSignInfo
