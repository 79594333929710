import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useFirestore, useFirestoreDoc, useStorage } from 'reactfire'
import { useRoute, useLocation } from 'wouter'

import { routes } from '../../../community'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import { getPathFramesJson, IGlobalAttr, ISegment, ISign, IVideo } from 'collections'
import { getUsersByWorkspaceFunction } from '../../../services/firebase'

import CardMessage from '../../../components/CardMessage'
import AnimationView from './AnimationView'
import ContributionMetadataView from '../../../components/ContributionMetadataView'
import UserMenu from '../../../components/UserMenu'
import Preloader from '../../../components/Preloader'
import Typography from '@material-ui/core/Typography'
import Layout from '../../../components/Layout'
import IconButton from '@material-ui/core/IconButton'
import PublishIcon from '@material-ui/icons/Publish'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import useContributionMetadata from '../../../hooks/useContributionMetadata'
import { differenceInSeconds } from 'date-fns'

import AnimationList from './AnimationList'
import UploadModal from './UploadModal'
import useJobMeter from '../../../services/hooks/useJobMeter'
import useIntl from '../../../hooks/useIntl'
import FramePlayer from '../../../components/FramePlayer'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import { useGlobal } from 'reactn'
import { DocumentReference, runTransaction, serverTimestamp } from '@firebase/firestore'
import { getDownloadURL, ref } from '@firebase/storage'
import { getDocumentReference, getFirestoreDocument } from 'collections'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'
import { Button } from '@material-ui/core'
import Timeline from '../../../components/Timeline'

interface IAnimateProps {
  path: string
}

const AnimationHomeContainer = styled(Grid)`
  width: 90%;
  margin: 25px 0;
`

const SignViewContainer = styled.div`
  width: 500px;
  padding-top: 30px;
`

const ActionButton = styled(IconButton)`
  && {
    background-color: #30b9f4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    color: white;

    ${({ theme }) =>
      theme.palette.type === 'dark' &&
      css`
        &:disabled {
          background-color: grey;
        }
      `}
  }
`

const BoxContainer = styled(Grid)`
  && {
    @media screen and (max-width: 857px) {
      display: flex;
      justify-content: center;
    }
    margin: 30px 0;
  }
`

const ButtonContainer = styled.div`
  && {
    position: fixed;
    right: 25px;
    bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 119px;
  }
`

const AnimateDivider = styled(Divider)`
  && {
    @media screen and (max-width: 857px) {
      visibility: hidden;
    }
    height: 400px;
  }
`

const AnimationStateText = styled(Typography)`
  && {
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 0.1px white;
    color: ${(props: { bgColor: string }) => props.bgColor};
  }
`

const BackButton = styled(IconButton)`
  && {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-right: 22px;

    &:first-child {
      color: #c4c4c4;
    }
  }
`
// Tempo para liberar a animação para ser usada por outro animador - 24 horas em segundos
const timeToReleaseAnimation = 86400

/**
 * Componente da animação de sinais
 */
const Animate: React.FC<IAnimateProps> = () => {
  const [workspaceUsers, setWorkspaceUsers] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [animationSelected, setAnimationSelected] = useState(0)
  const { startJobMeter, resetJobMeter } = useJobMeter('animation')
  const hasPermissionOnModule = useCheckHasPermission('animation')
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const [, setLocation] = useLocation()
  const [, routeParams] = useRoute(routes.animation)
  const signId = (routeParams && routeParams.signId) || ''
  const firestore = useFirestore()
  const signDoc = useFirestoreDoc(getDocumentReference(fsUser.workspace, 'signs', signId) as DocumentReference).data
  const intl = useIntl()
  const animationStates = {
    queue: {
      label: intl.get('pages.animation.notAnimated'),
      bg: '#00000080',
    },
    animating: {
      label: intl.get('pages.animation.animating'),
      bg: '#C9BC45',
    },
    done: {
      label: intl.get('pages.animation.done'),
      bg: '#36C73C',
    },
    unreviewed: {
      label: intl.get('pages.animation.unreviewed'),
      bg: '#FF8C00',
    },
    problem: {
      label: intl.get('pages.animation.hasErrors'),
      bg: '#FF0000',
    },
    fingerspell: {
      label: intl.get('messages.fingerspell'),
      bg: 'orange',
    },
  }

  const [contributionMetadataState, contributionMetadataFns] = useContributionMetadata()

  const signData = signDoc.data() as ISign
  const [segmentData, setSegmentData] = useState<ISegment | null>(null)
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const storage = useStorage()
  const {
    isFingerspell,
    isAnimated,
    isAnimating,
    isExtractingKeypoints,
    isStudioFileRecording,
    currentAnimation,
    currentAnimationHasErrors,
  } = signData

  const isAnimator = isAnimating.value && isAnimating.user && isAnimating.user.id === fsUserRef.id

  const canAnimate = !isAnimating.value || isAnimator

  const animationState =
    animationStates[
      isAnimating.value
        ? 'animating'
        : isFingerspell
        ? 'fingerspell'
        : currentAnimationHasErrors
        ? 'problem'
        : currentAnimation && isAnimated && currentAnimationHasErrors === false
        ? 'done'
        : currentAnimation && isAnimated && !currentAnimationHasErrors
        ? 'unreviewed'
        : 'queue'
    ]

  const lastAnimatingTime = isAnimating.lastUpdate?.toDate() || new Date()
  const isUnlockedByTime = differenceInSeconds(new Date(), lastAnimatingTime) > timeToReleaseAnimation

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  const downloadURI = (uri: string) => {
    const link = document.createElement('a')
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDownloadAnimationTemplate = async () => {
    const animationTemplateUrl = await getDownloadURL(ref(storage, 'animationTemplate/_animation_template.blend'))
    downloadURI(animationTemplateUrl)
  }

  const clearSign = () =>
    runTransaction(firestore, async (transaction) => {
      transaction.update(signDoc.ref, {
        isAnimating: {
          value: false,
          user: null,
          lastUpdate: null,
        },
      })
    })

  useEffect(() => {
    getUsers()

    return () => {
      resetJobMeter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const call = async () => {
      if (signData?.primarySegment) {
        const segmentSnapshot = await getFirestoreDocument(signData.primarySegment)
        const _segmentData = segmentSnapshot.data() as ISegment
        _segmentData.id = segmentSnapshot.id

        const videoSnapshot = await getFirestoreDocument(_segmentData.video)
        const videoData = videoSnapshot.data() as IVideo

        const videoUrlString = getPathFramesJson(
          fsUser.workspace.id,
          _segmentData.video.id,
          videoData.duplicateOf || '',
        )
        const videoUrl = await getDownloadURL(ref(storage, videoUrlString))
        _segmentData.url = videoUrl
        setSegmentData(_segmentData)
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.signLanguageId, fsUser.workspace.id, signDoc.id])

  useEffect(() => {
    if (!isAnimating.value || isUnlockedByTime) {
      contributionMetadataFns.setMetadata()
      if (isUnlockedByTime) clearSign()
    } else {
      contributionMetadataFns.setMetadata({
        timeToRelease: timeToReleaseAnimation,
        contributor: isAnimating.user,
        lastUpdate: isAnimating.lastUpdate?.toDate(),
        isLocked: fsUserRef.id !== isAnimating.user?.id,
        isTimerReachedZero: false,
      })

      startJobMeter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signDoc.id])

  useEffect(() => {
    if (contributionMetadataState && contributionMetadataState.isTimerReachedZero && isAnimating.value) {
      clearSign()
      setLocation('/animation')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributionMetadataState])

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const onStartAnimating = async () =>
    runTransaction(firestore, async (transaction) => {
      transaction.update(signDoc.ref, {
        isAnimating: {
          value: true,
          user: fsUserRef,
          lastUpdate: serverTimestamp(),
        },
      })
    })

  const onUploadClick = async () => {
    try {
      setIsLoading(true)
      if (!isAnimating.value) await onStartAnimating()

      setIsUploadModalOpen(true)
    } catch (err) {
      setLocation(`/animation`)
    } finally {
      setIsLoading(false)
    }
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.animation')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  // Se o sinal estiver sendo editado, não poderá ser visualizado
  if (signData && (isExtractingKeypoints.value || isStudioFileRecording.value)) {
    return (
      <Layout
        title={intl.get('modules.animation')}
        includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
      >
        <CardMessage
          title={`${
            (isExtractingKeypoints.value && intl.get('message.signIsExtractingKeypoints')) ||
            (isStudioFileRecording.value && intl.get('message.signIsRecording'))
          }.`}
          subtitle={intl.get('message.tryAgainLater')}
        />
      </Layout>
    )
  }

  return (
    <Layout
      title={intl.get('modules.animation')}
      requiredModule={'animation'}
      includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
    >
      {/** Exibe o preload ja que está carregando */}
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingAndSaving')}></Preloader>}

      {!isLoading && signData && (
        <AnimationHomeContainer>
          <Grid container>
            <Grid>
              <BackButton aria-label="Return to the list of signs" onClick={() => setLocation('/animation')}>
                <ArrowBackIcon fontSize="default" />
              </BackButton>
            </Grid>

            <Grid>
              <Typography variant="h4">{signData.glosa || intl.get('pages.animation.noGlosaFound')}</Typography>

              <AnimationStateText variant="body2" bgColor={animationState.bg}>
                {animationState.label}
              </AnimationStateText>
            </Grid>
          </Grid>

          <BoxContainer justifyContent="space-between" container>
            {/* Vídeo do sinal atual */}
            {segmentData && (
              <SignViewContainer>
                <FramePlayer
                  onError={() => ({})}
                  reference={segmentData.id}
                  onChangedTime={handleOnChangedTime}
                  frameRatio={30}
                  framesDuration={segmentData.endFrame}
                  timeFrame={appConfig.frameRate}
                  src={segmentData.url || ''}
                  startFrame={segmentData.startFrame}
                  timelineCursor={timelineCursor}
                  autoPlay={true}
                  autoPlayEnabled={true}
                  mirrorEnabled={true}
                />
                <Timeline
                  timeFrame={appConfig.frameRate}
                  timelineCursor={timelineCursor}
                  timelineCursorFns={timelineCursorFns}
                  editing={false}
                  duration={segmentData.endFrame - segmentData.startFrame}
                  startFrame={segmentData.startFrame}
                />
              </SignViewContainer>
            )}

            <AnimateDivider orientation="vertical" />

            <Grid>
              <Button
                color={'primary'}
                onClick={handleDownloadAnimationTemplate}
                style={{ marginBottom: '10px' }}
                variant="contained"
              >
                {intl.get('pages.animation.downloadAnimationTemplate')}
              </Button>
              {/* Exibição de uma animação  */}
              <AnimationView
                signDoc={signDoc.ref}
                workspaceUsers={workspaceUsers}
                signData={signData}
                setAnimationSelected={setAnimationSelected}
                animationSelected={animationSelected}
              />
            </Grid>
          </BoxContainer>

          {/* Lista de animações */}
          <AnimationList
            signDoc={signDoc.ref}
            signData={signData}
            workspaceUsers={workspaceUsers}
            animationSelected={animationSelected}
            setAnimationSelected={setAnimationSelected}
          />

          {/* Botão de upload */}

          <ButtonContainer>
            <Tooltip
              title={
                isAnimating.value
                  ? intl.get('pages.animation.uploadAnimation')
                  : intl.get('pages.animation.startToAnimate')
              }
              placement="left"
            >
              <ActionButton aria-label="Upload animation" onClick={onUploadClick} disabled={!canAnimate}>
                {isAnimating.value ? <PublishIcon fontSize="large" /> : <AddIcon fontSize="large" />}
              </ActionButton>
            </Tooltip>
          </ButtonContainer>

          {/* Modal para subir sinais para o firebase */}
          <UploadModal
            isOpen={isUploadModalOpen}
            signDoc={signDoc.ref}
            glosa={signData.glosa}
            setIsOpen={setIsUploadModalOpen}
            setIsLoading={setIsLoading}
          />
        </AnimationHomeContainer>
      )}
    </Layout>
  )
}

export default Animate
