import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ConfirmDialog, { ConfirmDialogContentType } from '../ConfirmDialog'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import Fab from '@material-ui/core/Fab'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Tooltip from '@material-ui/core/Tooltip'

interface IAnimationViewPureProps {
  /** Lista de animações para exibição */
  data: firebase.default.firestore.DocumentData[]
  /** Lista de usuários do workspace */
  workspaceUsers: firebase.default.firestore.DocumentData
  /** Animação selecinado */
  animationSelected: number
  /** Indíce da animação principal */
  currentAnimationIndex: number
  /** Determina se o botão `Set as fingerspelling` está ativado */
  actionsActive: boolean
  /** Callback para salvar como fingerspell */
  setSignAsFingerspelling: () => void
  /** Callback para mudar a animação selecionada */
  setAnimationSelected: (index: number) => void
  /** Callback para salvar a nova animação principal */
  setMainAnimation: (index?: number) => void
  /** Callback para desativar uma animação */
  disableAnimation: () => void
  /** Callback para ativar o sinal */
  enableSign: () => void
  /** Retorna a descrição do erro */
  getErrorDescription: (err: string) => string
  /** Texto habilitar sinal */
  textEnableSign: string
  /** Texto definir como datilologia */
  textSetAsFingerspell: string
  /** texto revisado por */
  textReviewedBy: string
  /** texto problemas */
  textProblems: string
  /** texto animador */
  textAnimator: string
  /** texto data */
  textDate: string
}

const InfoContainer = styled(Grid)`
  margin-left: 18px;
  max-width: 242px;
`

const HTCoreElement = styled.div`
  width: 250px;
  position: relative;
  align-self: flex-end;
`

const ValidationButton = styled(Button)`
  && {
    margin-bottom: 15px;
  }
`

const InfoText = styled(Typography)`
  && {
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const InfoTextContainer = styled(Grid)`
  && {
    margin-bottom: 18px;
  }
`

const ReplayButton = styled(Fab)`
  && {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    margin-left: 5px;
  }
`

const DisableButton = styled(IconButton)`
  && {
    position: absolute;
    right: 0;
  }
`

// Id do elemento do avatar
const coreId = 'HTCoreElement'

/**
 * Componente puro de exibição de animações
 */
const AnimationViewPure: React.SFC<IAnimationViewPureProps> = ({
  data,
  animationSelected,
  workspaceUsers,
  actionsActive,
  disableAnimation,
  setMainAnimation,
  setSignAsFingerspelling,
  enableSign,
  getErrorDescription,
  textEnableSign,
  textSetAsFingerspell,
  textAnimator,
  textProblems,
  textReviewedBy,
  textDate,
}) => {
  // Core do Avatar
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [animationCore, setAnimationCore] = useState<any>()
  const [isConfirmDialogOpen, setIsConfirmModalOpen] = useState(false)
  const [modalContentType, setModalContentType] = useState<ConfirmDialogContentType>('currentAnimation')
  const { createdBy, createdAt, isDisabled } = (data.length && data[animationSelected]) || {}

  const [htaAnimation, setHtaAnimation] = useState()

  const animatorName = (createdBy && workspaceUsers[createdBy.id] && workspaceUsers[createdBy.id].displayName) || ''

  useEffect(() => {
    setHtaAnimation(((data.length && data[animationSelected]) || {}).htaAnimation || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const setIsFingerspell = () => {
    setModalContentType('isFingerspell')
    setIsConfirmModalOpen(true)
  }

  const setIsSign = () => {
    setModalContentType('isSign')
    setIsConfirmModalOpen(true)
  }

  const setAnimationAsDisabled = () => {
    setModalContentType('isDisabled')
    setIsConfirmModalOpen(true)
  }

  const onConfirmDialog = () => {
    setIsConfirmModalOpen(false)
    switch (modalContentType) {
      case 'isDisabled':
        disableAnimation()
        break

      case 'currentAnimation':
        setMainAnimation()
        break

      case 'isFingerspell':
        setSignAsFingerspelling()
        break

      case 'isSign':
        enableSign()
        break
    }
  }

  /** Inicia o avatar para fazer as animações */
  const initAvatar = async () => {
    setTimeout(async () => {
      // @ts-ignore
      const _animationCore = new HTCore({
        token: process.env.HT_TOKEN || '',
        parentElement: document.getElementById(coreId),
      })

      await _animationCore.load()
      _animationCore.setBackgroundColor('#eee')

      setAnimationCore(_animationCore)
    })
  }

  /** Faz o avatar animar um hta */
  const playAnimation = () => {
    const animations = [htaAnimation, '@repouso']
    if (animationCore) animationCore?.signAnimationCodes(animations, true)
  }

  useEffect(() => {
    if (document.getElementById(coreId)) initAvatar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        gap: '10px',
      }}
    >
      <HTCoreElement id={coreId}>
        {htaAnimation && (
          <>
            <ReplayButton size="small" color="primary" aria-label="Replay animation" onClick={playAnimation}>
              <PlayCircleOutlineIcon />
            </ReplayButton>

            <DisableButton
              color="secondary"
              aria-label="Disable animation"
              onClick={setAnimationAsDisabled}
              disabled={!actionsActive || isDisabled?.value}
              style={{ top: '0px' }}
            >
              {isDisabled?.value ? <HighlightOffIcon color="secondary" /> : <DeleteForeverIcon />}
            </DisableButton>
          </>
        )}
      </HTCoreElement>

      <InfoContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <InfoTextContainer>
          <ValidationButton variant="contained" color="primary" onClick={!actionsActive ? setIsSign : setIsFingerspell}>
            {!actionsActive ? textEnableSign : textSetAsFingerspell}
          </ValidationButton>

          {!!data.length && data[animationSelected] && (
            <>
              <InfoText>
                {textAnimator}: {animatorName}
              </InfoText>

              <InfoText>
                {textDate}: {format(createdAt?.toDate() || new Date(), 'dd/MM/yyyy')}
              </InfoText>
              {data[animationSelected].reviewedBy && (
                <InfoText>
                  {textReviewedBy}:
                  {' ' +
                    (workspaceUsers[data[animationSelected].reviewedBy.id] &&
                      workspaceUsers[data[animationSelected].reviewedBy.id].displayName) || ''}
                </InfoText>
              )}
              {data[animationSelected].errors &&
                data[animationSelected].errors.length > 0 &&
                !data[animationSelected].errors.includes('NO_ERRORS') && (
                  <InfoText style={{ color: '#FF0000' }}>{textProblems}:</InfoText>
                )}
              {data[animationSelected].errors &&
                !data[animationSelected].errors.includes('NO_ERRORS') &&
                data[animationSelected].errors.map((_err: string, key: number) => (
                  <Tooltip key={key} title={getErrorDescription(_err)}>
                    <InfoText>• {getErrorDescription(_err)}</InfoText>
                  </Tooltip>
                ))}
            </>
          )}
        </InfoTextContainer>
      </InfoContainer>

      {/* Modal de confirmação de ações */}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={onConfirmDialog}
        onClose={() => setIsConfirmModalOpen(false)}
        contentType={modalContentType}
      />
    </Grid>
  )
}

export default AnimationViewPure
