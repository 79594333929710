import { useFirestore, useFirestoreDoc, useFirestoreDocData } from 'reactfire'
import { doc } from '@firebase/firestore'
import { DocumentData, DocumentReference } from 'firebase/firestore'

interface IUseFirestoreDataReturn {
  status: 'loading' | 'error' | 'success'
  data: DocumentData
}

export const useFirestoreData = (path: string): IUseFirestoreDataReturn => {
  const firestore = useFirestore()
  const ref = doc(firestore, path)
  const { status, data: docData } = useFirestoreDocData(ref, { idField: 'id' })
  // remove o id, pois dá problema no update caso tenha essa chave. Ela foi incluída na atualização do reactfire
  if (docData) {
    delete docData['id']
  }

  return { status, data: docData }
}

export const useFirestoreRef = (path: string): DocumentReference<DocumentData> => {
  const firestore = useFirestore()
  const ref = doc(firestore, path)
  return useFirestoreDoc(ref).data.ref
}
