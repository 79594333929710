/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../../../components/Layout'
import RecordDialog from '../../../components/RecordDialog'
import Accordion from '../../../components/HTubeAccordion'
import useIntl from '../../../hooks/useIntl'
import {
  getPathFramesJson,
  ISegmentView,
  ISignView,
  IPaginationConfig,
  getCollectionReference,
  getFirestoreDocument,
  QueryDocumentSnapshot,
  getDocumentReference,
  preProcess,
  SignState,
  IGlobalAttr,
  IVideo,
  getSignsBatch,
} from 'collections'
import Typography from '@material-ui/core/Typography'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import InfiniteScroll from 'react-infinite-scroll-component'
import Preloader from '../../../components/Preloader'
import { useStorage } from 'reactfire'
import CardHTube from './CardHTube'
import Skeleton from '@material-ui/lab/Skeleton'
import { getDownloadURL, ref } from '@firebase/storage'
import {
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
  where,
  endBefore,
  limitToLast,
  DocumentReference,
  collection,
  documentId,
  query as firestoreQuery,
} from '@firebase/firestore'
import useGetNmtSign from '../../../hooks/useGetNmtSign'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'
import CardMessage from '../../../components/CardMessage'
import { useGlobal } from 'reactn'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import GridViewIcon from '@material-ui/icons/Apps'
import ListViewIcon from '@material-ui/icons/List'
import ModalSignInfo from '../Sign/ModalSignInfo'
import { useLocation } from 'wouter'
import { routes } from '../../../community'

interface IHTubeProps {
  path: string
}

const BlockGrid = styled(Grid)`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
`

const initialPaginationConfig: IPaginationConfig = {
  currentPage: 0,
  firstOfList: null,
  lastOfList: null,
  load: false,
  rowsPerPage: 5,
}

const HTubeMain: React.FC<IHTubeProps> = () => {
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const [, setLocation] = useLocation()
  const hasPermissionOnModule = useCheckHasPermission('hTube')
  const [signs, setSigns] = useState<ISignView[]>([])
  const [lastSign, setLastSign] = useState<QueryDocumentSnapshot | null>(null)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const storage = useStorage()
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [signState, setSignState] = useState<SignState | 'ALL'>('ALL')
  const nmtFns = useGetNmtSign()[0]
  const [nmtOnSearch, setNmtOnSearch] = useState(false)
  const [openRecordDialog, setOpenRecordDialog] = React.useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [selectedSignId, setSelectedSignId] = useState('')
  const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false)
  const [hTubeView, setHTubeView] = useGlobal<IGlobalAttr, 'hTubeView'>('hTubeView')
  const viewState = hTubeView || 'Grid'

  const [expandedAccordion, setexpandedAccordion] = React.useState<string | false>(false)

  const [accordionSigns, setAccordionSigns] = useState<ISignView[]>([])
  const [segment, setSegment] = useState<ISegmentView>()

  const signalFirstLetter = React.useRef<string>('')

  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>(initialPaginationConfig)

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

  const segmentsBatchRef = useRef<string[]>([])

  const videosBatchRef = useRef<string[]>([])

  useEffect(() => {
    if (paginationConfig.load) {
      fetchSigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  const fetchSigns = useCallback(async () => {
    try {
      const _signs: ISignView[] = []
      setIsLoadingData(true)
      let searchQuery = query(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where('firstLetter', 'array-contains', signalFirstLetter.current),
        orderBy('glosa'),
        limit(5),
      )
      if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
        if (paginationConfig.lastOfList) {
          searchQuery = query(searchQuery, startAfter(paginationConfig.lastOfList), limit(5))
        } else {
          searchQuery = query(searchQuery, endBefore(paginationConfig.firstOfList), limitToLast(5))
        }
      }

      const signsSnapshot = await getDocs(searchQuery)

      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.lastOfList = signsSnapshot.docs[signsSnapshot.docs.length - 1]
      _paginationConfig.firstOfList = signsSnapshot.docs[0]
      _paginationConfig.load = false
      signsSnapshot.docs.map((querySnapshot) => {
        const _sign = querySnapshot.data() as ISignView

        _sign.id = querySnapshot.id

        _signs.push(_sign)
      })
      setPaginationConfig(_paginationConfig)
      setAccordionSigns(_signs)
      setIsLoadingData(false)
    } catch (e: any) {
      console.log(e.message)
    }
  }, [fsUser.workspace, paginationConfig])

  const handleChangeExpandedAccordion =
    (panel: string, el: string) => async (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setexpandedAccordion(isExpanded ? panel : false)
      const _paginationConfig = { ...paginationConfig }
      signalFirstLetter.current = el
      _paginationConfig.currentPage = 0
      _paginationConfig.lastOfList = null
      _paginationConfig.firstOfList = null
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    }

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const handlePlayVideo = async (sign: string) => {
    const _sign = accordionSigns.find((signs) => signs.id == sign)
    if (_sign && _sign.primarySegment) {
      const segmentSnapshot = await getFirestoreDocument(_sign.primarySegment)
      const _segmentData = (await segmentSnapshot.data()) as ISegmentView
      const videoSnapshot = await getFirestoreDocument(_segmentData.video)
      const videoData = videoSnapshot.data() as IVideo
      _segmentData.videoUrl = await getVideoUrl(_segmentData, videoData.duplicateOf || '')
      _sign.segment = _segmentData
      setSegment(_sign.segment)
    }
  }

  const handleCloseVideo = () => {
    setSegment(undefined)
  }

  const getVideoUrl = async (segment: ISegmentView, duplicateOf: string) => {
    const urlString = getPathFramesJson(fsUser.workspace.id, segment.video.id, duplicateOf)
    return await getDownloadURL(ref(storage, urlString))
  }

  const handleOpenRecordDialog = () => {
    setOpenRecordDialog(true)
    setIsLoading(true)
  }

  const handleCloseRecordDialog = () => {
    setOpenRecordDialog(false)
    setIsLoading(false)
  }

  const loadMore = useCallback(
    (override: boolean) => {
      const call = async () => {
        let _signs: ISignView[] = []
        if (override) setIsLoading(true)
        if (!override) _signs = [...signs]

        const pagination = 8
        let lastSignOnList: QueryDocumentSnapshot<DocumentData> | null = null
        let quantityOfSigns: number = 0
        let noSignFound: number = 0
        if (nmtOnSearch) {
          const nmtSignsResponse = await nmtFns.getSigns(searchText)
          const signsRef: ISignView[] = []
          if (nmtSignsResponse) {
            nmtSignsResponse.forEach((signRef) => {
              return signsRef.push(signRef.signRef as unknown as ISignView)
            })
          }
          if (signsRef) {
            let fillSign: boolean = true
            for (const sign of signsRef) {
              fillSign = true
              if (!sign) {
                noSignFound++
                continue
              }
              const signRef = getDocumentReference(fsUser.workspace, 'signs', sign.id)

              if (signRef !== undefined) {
                const signSnapshot = await getFirestoreDocument(signRef)
                const _sign = signSnapshot.data() as ISignView

                _signs.forEach((sign) => {
                  if (sign.id === signSnapshot.id) {
                    fillSign = false
                  }
                })

                if (fillSign) {
                  _sign.id = signSnapshot.id
                  _sign.ref = signSnapshot.ref

                  /** Resgata dados do segmento */
                  if (_sign.primarySegment) {
                    const segmentSnapshot = await getFirestoreDocument(_sign.primarySegment)
                    const _segmentData = (await segmentSnapshot.data()) as ISegmentView
                    if (_segmentData) {
                      const videoSnapshot = await getFirestoreDocument(_segmentData.video)
                      const videoData = videoSnapshot.data() as IVideo
                      _segmentData.videoUrl = await getVideoUrl(_segmentData, videoData.duplicateOf || '')
                    }
                    _sign.segment = _segmentData
                  }

                  _signs.push(_sign)
                  quantityOfSigns++
                }

                lastSignOnList = signSnapshot as QueryDocumentSnapshot<DocumentData>
                if (quantityOfSigns == pagination) break
              }
            }

            setHasMoreResults(signsRef.length - noSignFound > _signs.length)
            setLastSign(lastSignOnList || null)
            setSigns(_signs)
            setIsLoading(false)
            setHasMoreResults(signsRef.length - noSignFound > _signs.length)
            setLastSign(lastSignOnList || null)

            setSigns(_signs)
            setIsLoading(false)
          }
        } else {
          let _query = query(getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>)
          if (signState !== 'ALL') {
            _query = query(_query, where('_state', '==', signState))
          }

          if (searchText) {
            _query = query(
              _query,
              where(
                'searchTerms',
                'array-contains',
                preProcess(searchText, fsUser?.workspace.id || '', true, true, false, false),
              ),
            )
          }
          _query = query(_query, orderBy('numberOfSegments', 'desc'))
          if (lastSign && !override) {
            _query = query(_query, startAfter(lastSign))
          }

          const signsSnapshot = await getDocs(query(_query, limit(pagination)))
          for (const signSnapshot of signsSnapshot.docs) {
            const _sign = signSnapshot.data() as ISignView
            _sign.id = signSnapshot.id
            _sign.ref = signSnapshot.ref

            /** Resgata dados do segmento */
            if (_sign.primarySegment) {
              const segmentSnapshot = await getFirestoreDocument(_sign.primarySegment)
              const _segmentData = (await segmentSnapshot.data()) as ISegmentView
              if (_segmentData) {
                const videoSnapshot = await getFirestoreDocument(_segmentData.video)
                const videoData = videoSnapshot.data() as IVideo
                _segmentData.videoUrl = await getVideoUrl(_segmentData, videoData.duplicateOf || '')
              }
              _sign.segment = _segmentData
              _signs.push(_sign)
            }
          }

          setHasMoreResults(signsSnapshot.size == pagination)
          setLastSign(signsSnapshot.docs[signsSnapshot.docs.length - 1] || null)
          setSigns(_signs)
          setIsLoading(false)
          setHasMoreResults(signsSnapshot.size == pagination)
          setLastSign(signsSnapshot.docs[signsSnapshot.docs.length - 1] || null)

          // TODO - ORDENAR SEGMENTOS PELAS PALAVRAS RELACIONADAS.
          setSigns(_signs)
          setIsLoading(false)
        }
      }
      call()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser.workspace, lastSign, signs, signState, searchText, nmtOnSearch],
  )

  const handleTextSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = (event.target && event.target.value) || ''
      setSearchText(text)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText],
  )

  const onKeyUpSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      loadMore(true)
    }
  }

  const SkeletonPage = () => {
    const rangeArray = Array.from(Array(8), (_, i) => i + 1)

    return (
      <Box width="100%">
        <Grid container direction="row" justifyContent="center">
          {rangeArray.map((i) => (
            <Box key={i} style={{ margin: '5px' }}>
              <Skeleton variant="rect" width={340} height={283} style={{ borderRadius: '5px' }} />
            </Box>
          ))}
        </Grid>
      </Box>
    )
  }

  const handleChanceNmtSearch = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
      checked: boolean
    }>,
  ) => {
    setNmtOnSearch(event.target.checked)
  }

  useEffect(() => {
    setSignState('ALL')
  }, [])

  useEffect(() => {
    if (window.location.hash) {
      setLocation(routes.hTube.replace(':clusterId', window.location.hash.replace('#', '')))
    }
  }, [setLocation])

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.hTube')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  const getSegmentsBatch = async (workspaceRef: DocumentReference, segmentsId: string[]) => {
    const result: Record<string, ISegmentView> = {}

    const batch: string[][] = []
    const batchNumber = 9
    for (let i = 0; i < segmentsId.length; i = i + batchNumber) {
      batch.push(segmentsId.slice(i, i + batchNumber))
    }

    for (const segments of batch) {
      const segmentsQuery = firestoreQuery(collection(workspaceRef, 'segments'), where(documentId(), 'in', segments))
      const segmentsSnapshot = await getDocs(segmentsQuery)

      segmentsSnapshot.docs.forEach((segmentSnapshot) => {
        result[segmentSnapshot.id] = segmentSnapshot.data() as ISegmentView
      })
    }
    return result
  }

  const getVideosBatch = async (workspaceRef: DocumentReference, videosId: string[]) => {
    const result: Record<string, IVideo> = {}

    const batch: string[][] = []
    const batchNumber = 9
    for (let i = 0; i < videosId.length; i = i + batchNumber) {
      batch.push(videosId.slice(i, i + batchNumber))
    }

    for (const videos of batch) {
      const videosQuery = firestoreQuery(collection(workspaceRef, 'videos'), where(documentId(), 'in', videos))
      const videosSnapshot = await getDocs(videosQuery)

      videosSnapshot.docs.forEach((videoSnapshot) => {
        result[videoSnapshot.id] = videoSnapshot.data() as IVideo
      })
    }
    return result
  }

  const handleSearchSigns = (signs: string[]) => {
    const call = async () => {
      const _signs: ISignView[] = []
      segmentsBatchRef.current = []
      videosBatchRef.current = []

      const signsBatch = await getSignsBatch(fsUser.workspace as DocumentReference, signs)
      for (const signEntries of Object.entries(signsBatch)) {
        const sign = signEntries[1]
        const signId = signEntries[0]
        _signs.push({ ...(sign as ISignView), id: signId })
        if (sign.primarySegment?.id) {
          segmentsBatchRef.current.push(sign.primarySegment.id)
        }
      }
      const segmentsBatch = await getSegmentsBatch(fsUser.workspace as DocumentReference, segmentsBatchRef.current)
      for (const segment of Object.values(segmentsBatch)) {
        videosBatchRef.current.push(segment.video.id)
      }
      const videosBatch = await getVideosBatch(fsUser.workspace as DocumentReference, videosBatchRef.current)
      for (const segment of Object.values(segmentsBatch)) {
        const videoUrl = await getVideoUrl(segment, videosBatch[segment.video.id].duplicateOf || '')
        segment.videoUrl = videoUrl
      }
      for (const segmentsKey of Object.keys(segmentsBatch)) {
        for (const sign of _signs) {
          if (sign.primarySegment?.id == segmentsKey) {
            sign.segment = segmentsBatch[segmentsKey]
          }
        }
      }
      setSigns(_signs)
      setIsLoading(false)
    }
    call()
  }

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
    setHTubeView(newView || 'Grid')
  }

  const toggleGridView = () => {
    return (
      <ToggleButtonGroup value={viewState} exclusive onChange={handleChangeView} aria-label="text alignment">
        <ToggleButton value="Grid" aria-label="grid view">
          <GridViewIcon />
        </ToggleButton>
        <ToggleButton value="List" aria-label="list view">
          <ListViewIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }

  return (
    <Layout title={intl.get('modules.hTube')} requiredModule={'hTube'} id="scrollable">
      <Grid container md={10} item>
        {!hTubeView || hTubeView == 'Grid' ? (
          <>
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FormGroup style={{ margin: '20px 5px 0px 5px' }}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={handleChanceNmtSearch} value={nmtOnSearch} />}
                  label={intl.get('pages.hTube.main.useNMTInSearch')}
                />
              </FormGroup>
              <TextField
                label={intl.get('messages.search')}
                variant="filled"
                value={searchText}
                disabled={isLoading}
                onKeyUp={onKeyUpSearch}
                onChange={handleTextSearchChange}
                style={{ margin: '10px 5px 0px 5px', width: '300px' }}
              />

              <Box style={{ width: '300px', margin: '10px 0 10px 5px' }}>
                <InputLabel id="x-label">{intl.get('messages.state')}</InputLabel>
                <Select
                  className="notranslate"
                  fullWidth
                  defaultValue="ALL"
                  id="grouped-select"
                  value={signState}
                  style={{ margin: '10px 0 10px 5px' }}
                  MenuProps={{
                    style: { zIndex: 2002 },
                  }}
                  disabled={isLoading}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string | undefined
                      value: unknown
                    }>,
                  ) => {
                    setSignState((event.target.value as SignState) || 'ALL')
                  }}
                >
                  <MenuItem className={`notranslate`} value={'ALL'}>
                    {intl.get('messages.all')}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={'CLUSTERED'}>
                    {intl.get('messages.clustered')}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={'PROMOTED'}>
                    {intl.get('messages.promoted')}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={'ANIMATED'}>
                    {intl.get('messages.animated')}
                  </MenuItem>
                </Select>
              </Box>
              <IconButton
                disableRipple
                color="primary"
                style={{
                  margin: '5px 0px 10px 15px',
                  height: '60px',
                }}
                onClick={handleOpenRecordDialog}
                disabled={isLoading}
              >
                <VideoCallIcon fontSize="large" />
              </IconButton>

              <Button
                color="primary"
                variant="contained"
                style={{ margin: '5px 0px 10px 15px', height: '60px' }}
                onClick={() => {
                  loadMore(true)
                }}
                disabled={isLoading}
              >
                {intl.get('messages.search')}
              </Button>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '5px 0px 10px 15px',
                  height: '60px',
                }}
              >
                {toggleGridView()}
              </Box>
            </Grid>
            {isLoading && <SkeletonPage />}
            {!isLoading && signs.length > 0 && (
              <Box style={{ width: '100%' }}>
                <InfiniteScroll
                  scrollableTarget="Layout"
                  dataLength={signs.length}
                  endMessage={
                    <BlockGrid item sm={12} lg={12}>
                      <Typography>{intl.get('messages.noMoreSegments')}</Typography>
                    </BlockGrid>
                  }
                  hasMore={hasMoreResults}
                  next={() => loadMore(false)}
                  loader={
                    <BlockGrid item sm={12} lg={12}>
                      <Preloader asBlock text={intl.get('messages.loadingSegments')} />
                    </BlockGrid>
                  }
                >
                  <Grid direction="row" container justifyContent="center">
                    {selectedSignId == '' &&
                      signs.map((sign) => (
                        <CardHTube
                          key={sign.id}
                          signId={sign.id}
                          segment={sign.segment}
                          words={sign.words}
                          state={sign._state}
                          numberOfSegments={sign.numberOfSegments}
                          setOpenInfo={setOpenInfo}
                          setSelectedSignId={setSelectedSignId}
                        />
                      ))}
                  </Grid>
                </InfiniteScroll>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '60px',
                margin: '10px 50px 10px auto',
              }}
            >
              {toggleGridView()}
            </Box>
            <Box style={{ width: '100%', margin: '0px 50px 50px 50px' }}>
              {alphabet.map((el, index) => (
                <Accordion
                  setOpenInfo={setOpenInfo}
                  setSelectedSignId={setSelectedSignId}
                  key={el}
                  firstLetter={el}
                  expanded={expandedAccordion === `panel${index}`}
                  onChange={handleChangeExpandedAccordion(`panel${index}`, el)}
                  onClose={() => handleCloseVideo()}
                  playVideo={(el) => handlePlayVideo(el)}
                  page={paginationConfig.currentPage}
                  signs={accordionSigns}
                  segment={segment}
                  loading={isLoadingData}
                  onPageChange={handleChangePage}
                />
              ))}
            </Box>
          </>
        )}
      </Grid>
      <ModalSignInfo
        signId={selectedSignId}
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        setSelectedSignId={setSelectedSignId}
      />
      <RecordDialog
        open={openRecordDialog}
        onClose={handleCloseRecordDialog}
        isLoading={() => setIsLoading(true)}
        searchSigns={(signs) => {
          handleSearchSigns(signs)
        }}
      />
    </Layout>
  )
}

export default HTubeMain
