import React from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { IUserSecret, CommunityModuleName, IWorkspace } from 'collections'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import useIntl from '../../../hooks/useIntl'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      background: theme.palette.type === 'light' ? '#CCC' : '#525252',
    },
  }),
)

const SearchField = styled(TextField)`
  && {
    margin: 10px 0;
  }
`

const SelectContainer = styled(Box)`
  && {
    margin-top: 18px;
  }
`

interface IUsersTablePureProps {
  /** Lista secreta de usuários */
  userSecrets: IUserSecret[]
  /** Set do texto de pesquisa */
  onSearchUser?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Texto de pesquisa */
  searchText?: string
  /** evento do workspace selecionado alterado */
  onChangeWorkspace?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Workspace selecionado */
  workspaceSelected?: string
  /** evento da role selecionada alterada */
  onChangeRole?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Role selecionada */
  roleSelected?: string
  /** Lista de workspaces do community */
  workspaces?: IWorkspace[]
  /** Evento para abrir o modal de edição do usuário */
  handleEditUser?: (user: IUserSecret) => void
  /** Evento para abrir o modal de cadastro do usuário */
  handleCreateUser?: () => void
}

const UsersTablePure: React.FC<IUsersTablePureProps> = ({
  userSecrets,
  onSearchUser,
  searchText,
  onChangeWorkspace,
  workspaceSelected,
  onChangeRole,
  roleSelected,
  workspaces,
  handleEditUser,
  handleCreateUser,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  const roles = ['admin', 'user']

  /**
   * Cebeçalho da tabela, com as informações iniciais o titulo das colunas
   */
  const head = (
    <TableHead>
      <TableRow>
        <TableCell>{intl.get('messages.user')}</TableCell>
        <TableCell>Workspace</TableCell>
        <TableCell>{intl.get('pages.admin.usersTable.modules')}</TableCell>
        <TableCell>{intl.get('pages.admin.role')}</TableCell>
        <TableCell>{intl.get('pages.admin.usersTable.actions')}</TableCell>
      </TableRow>
    </TableHead>
  )

  /**
   * Corpo da nossa tabela, com os dados dos usuários
   */
  const body = (
    <TableBody>
      {userSecrets.map((user, index) => (
        <TableRow key={user.uid} className={index % 2 ? classes.tableRow : ''}>
          <TableCell>
            <CardHeader
              avatar={<Avatar>HT</Avatar>}
              title={user.displayName || 'Anonymous'}
              subheader={user.email || 'no email'}
              style={{ padding: 0 }}
            />
          </TableCell>
          <TableCell>{user.workspaceId}</TableCell>
          <TableCell>
            {user?.modules?.map((moduleName) => (
              <Box m={1} key={moduleName}>
                <Chip color="primary" size="small" label={CommunityModuleName[moduleName] || ''} />
              </Box>
            ))}
          </TableCell>
          <TableCell>{user.role}</TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                if (handleEditUser) handleEditUser(user)
              }}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )

  return (
    <Card>
      <Button
        style={{ margin: '5px', fontWeight: 'bold' }}
        variant="contained"
        color="primary"
        onClick={handleCreateUser}
      >
        {intl.get('pages.admin.usersTable.register')}
      </Button>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SearchField
            style={{ width: '100%' }}
            label="Search"
            variant="filled"
            value={searchText}
            onChange={onSearchUser}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SelectContainer>
            <InputLabel id="workspace-label">Workspace</InputLabel>
            <Select fullWidth value={workspaceSelected} onChange={onChangeWorkspace}>
              <MenuItem key={'ALL'} value={'ALL'}>
                {intl.get('pages.admin.usersTable.all')}
              </MenuItem>
              {workspaces &&
                workspaces.map((workspace: IWorkspace) => (
                  <MenuItem key={workspace.id} value={workspace.id}>
                    {workspace.id}
                  </MenuItem>
                ))}
            </Select>
          </SelectContainer>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SelectContainer>
            <InputLabel id="role-label">{intl.get('pages.admin.role')}</InputLabel>
            <Select fullWidth value={roleSelected} onChange={onChangeRole}>
              <MenuItem key={'ALL'} value={'ALL'}>
                {intl.get('pages.admin.usersTable.all')}
              </MenuItem>
              {roles &&
                roles.map((role: string) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
            </Select>
          </SelectContainer>
        </Grid>
      </Grid>
      <Table className={classes.table} aria-label="simple table">
        {head}
        {body}
      </Table>
    </Card>
  )
}

export default UsersTablePure
