import { useGlobal } from 'reactn'

import { IGlobalAttr, IRegionAction } from 'collections'

interface ICurrentRegionActionFns {
  /**
   * Atualiza os dados da ação de regiao atual
   */
  setRegion: (metadata?: IRegionAction) => void
}

type CurrentRegionActionHookTuple = [IRegionAction | undefined, ICurrentRegionActionFns]

/**
 * Observa e notifica outros hooks/componentes sobre a ação de região que está ativada
 */
const useCurrentRegionAction = (): CurrentRegionActionHookTuple => {
  const [currentDraggingRegionAction, setCurrentDraggingRegionAction] = useGlobal<
    IGlobalAttr,
    'currentDraggingRegionAction'
  >('currentDraggingRegionAction')

  const fns: ICurrentRegionActionFns = {
    setRegion: setCurrentDraggingRegionAction,
  }

  return [currentDraggingRegionAction, fns]
}

export default useCurrentRegionAction
