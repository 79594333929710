import { IEntity } from './base'
import { DocumentReference, ErrorSignOnDemand, Timestamp } from '.'
/**
 * **Raw data**
 * Generated by the animator at the end of the animation
 */
export interface IAnimation extends IEntity, IAnimationFlags {
  /**
   * Hta file for animation
   */
  htaAnimation: string | null
  /**
   * HtaAnimation string size
   */
  htaSize: number
  /**
   * Reference to the blender file on storage
   */
  blendFile: string
  /**
   * User ratings for animation
   */
  userRatings: Record<string, number> | null
  /**
   * Comment related to animation made
   */
  comment: string | null
  /**
   * List of animation errors
   */
  errors: ErrorSignOnDemand | null
  /**
   * Reference of the user who reviewed the signal
   * */
  reviewedBy: DocumentReference | null
  /** Date of animation review */
  reviewedAt: Timestamp | null
  /**
   * Referencia do documento
   */
  ref?: DocumentReference
  /**
   * sinal no qual a animação pertence
   */
  sign: DocumentReference | null
}

export interface IAnimationFlags {
  /** Animation is disabled */
  isDisabled: {
    /* Indicates whether it has been disabled */
    value: boolean
    /** Last change date */
    lastUpdate: Timestamp | null
    /** User who disabled animation */
    user: DocumentReference | null
  }
  /** The animation is validated */
  isValidated: {
    /* Indicates whether it has been validated */
    value: boolean
    /** Last change date */
    lastUpdate: Timestamp | null
    /** User who validated the animation */
    user: DocumentReference | null
  }
}

export enum AnimationProblemsCode {
  wrongFacialExpression = 'wrongFacialExpression',
  wrongHandShape = 'wrongHandShape',
  wrongLocation = 'wrongLocation',
  wrongMovement = 'wrongMovement',
  floatyMovement = 'floatyMovement',
  tooSlow = 'tooSlow',
  standardPose = 'standardPose',
  unnecessaryFrames = 'unnecessaryFrames',
  tooFast = 'tooFast',
  uncannyMovement = 'uncannyMovement',
  noFacialExpression = 'noFacialExpression',
}

export interface IAnimationProblem {
  id: AnimationProblemsCode
  description: string
  checked: boolean
}

export const AnimationProblems = {
  wrongFacialExpression: {
    id: AnimationProblemsCode.wrongFacialExpression,
    description: 'Wrong Facial Expression',
    checked: false,
  },
  wrongHandShape: {
    id: AnimationProblemsCode.wrongHandShape,
    description: 'Wrong Hand (Configuration or Orientation)',
    checked: false,
  },
  wrongLocation: {
    id: AnimationProblemsCode.wrongLocation,
    description: 'Wrong Point of Articulation',
    checked: false,
  },
  wrongMovement: {
    id: AnimationProblemsCode.wrongMovement,
    description: 'Wrong Movement Type',
    checked: false,
  },
  floatyMovement: {
    id: AnimationProblemsCode.floatyMovement,
    description: "'Unstable' or 'Floating' Movement",
    checked: false,
  },
  tooSlow: {
    id: AnimationProblemsCode.tooSlow,
    description: 'Very Slow Speed',
    checked: false,
  },
  standardPose: {
    id: AnimationProblemsCode.standardPose,
    description: 'Hand Down Start / End',
    checked: false,
  },
  unnecessaryFrames: {
    id: AnimationProblemsCode.unnecessaryFrames,
    description: 'Unnecessary Frame (Start or End)',
    checked: false,
  },
  tooFast: {
    id: AnimationProblemsCode.tooFast,
    description: 'Very Fast Speed',
    checked: false,
  },
  uncannyMovement: {
    id: AnimationProblemsCode.uncannyMovement,
    description: 'Linear / Hard / Robotic Movement',
    checked: false,
  },
  noFacialExpression: {
    id: AnimationProblemsCode.noFacialExpression,
    description: 'Absent Expression',
    checked: false,
  },
}
