import React, { FC } from 'react'
import styled from 'styled-components'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const itemHeight = '80px'
const TimelineItemCreatorStyle = styled.div`
  width: 100%;
  cursor: cell;
  height: ${itemHeight};
`
const TempBlock = styled.div`
  cursor: cell;
  position: absolute;
  bottom: 10px;
  height: ${itemHeight};
  opacity: 1;
  color: #fff;
  border: dashed #fff 2px;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tempBlock: {
      borderColor: theme.palette.type === 'light' ? theme.palette.grey[900] : '#fff',
    },
  }),
)

export interface ITransform {
  startX: number
  cursorX: number
  minBoundX: number
  maxBoundX: number
}

interface ITimelineItemCreatorProps {
  isCreating: boolean
  transform: ITransform
  onStart?: () => void
  handleMouseDown?: (event: React.MouseEvent) => void
  handleMouseMove?: (event: React.MouseEvent) => void
  handleMouseUp?: (event: React.MouseEvent) => void
}

/**
 * Construtor de itens da linha do tempo, basicamente detecta o dragging
 * de uma div e cria novo bloco, o dragEnd retorna os dados do novo bloco como callback
 */
export const TimelineItemCreator: FC<ITimelineItemCreatorProps> = ({
  handleMouseDown,
  handleMouseMove,
  isCreating,
  transform,
}) => {
  const classes = useStyles()
  return (
    <>
      <TimelineItemCreatorStyle onMouseDown={handleMouseDown} onMouseMove={handleMouseMove}>
        {/**
         * Demonstração visual do que está sendo criado,
         * basicamente exibe um bloco temporario na linha do tempo
         */}
        {isCreating && (
          <TempBlock
            className={classes.tempBlock}
            style={{
              left: `${transform.startX}px`,
              width: `${transform.cursorX - transform.startX}px`,
            }}
          />
        )}
      </TimelineItemCreatorStyle>
    </>
  )
}

export default TimelineItemCreator
