import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import ArrowUpIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownIcon from '@material-ui/icons/ArrowDownward'
import {
  getCollectionReference,
  IManuallyTranslation,
  IManuallyTranslationErrors,
  IOptionsSelect,
  IPaginationConfig,
  preProcess,
  updateManuallyTranslation,
} from 'collections'
import useIntl from '../../hooks/useIntl'
import styled from 'styled-components'
import { format } from 'date-fns'
import SearchIcon from '@material-ui/icons/Search'
import Select from '../../components/Select'
import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import {
  query,
  orderBy,
  startAfter,
  endBefore,
  limitToLast,
  limit,
  DocumentData,
  Query,
  where,
} from '@firebase/firestore'
import { useFirestoreCollection } from 'reactfire'
import { DocumentReference, Timestamp } from 'firebase/firestore'
import { useLocation } from 'wouter'
import { routes } from '../../community'
import useSnackbar from '../../services/hooks/useSnackbar'
import { changeCMSStatus } from '../../services/cmsRequests'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import Tooltip from '@material-ui/core/Tooltip'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { cmsStatusName, cmsTypesName } from '../../services/utils'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import CardMessage from '../../components/CardMessage'
import { Add as AddIcon } from '@material-ui/icons'
import ModalIncludeManuallyTranslation from './ModalIncludeManuallyTranslation'
import MenuMultiselect from '../../components/MenuMultiselect'
import { getUsersInBatchByIdFunction } from '../../services/firebase'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  cursor: 'pointer',
}))

interface IManuallyTranslationProps {
  path?: string
}

const initialPaginationConfig: IPaginationConfig = {
  currentPage: 0,
  firstOfList: null,
  lastOfList: null,
  load: true,
  rowsPerPage: 10,
}

interface IOrderList {
  field: string
  direction: 'asc' | 'desc'
}

interface IManuallyTranslationWithId extends IManuallyTranslation {
  id?: string
  ref?: DocumentReference
}

const ManuallyTranslation: React.FC<IManuallyTranslationProps> = () => {
  const hasPermissionOnModule = useCheckHasPermission('manuallyTranslation')
  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>(initialPaginationConfig)
  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchType, setSearchType] = useState(['ALL'])
  const [searchStatus, setSearchStatus] = useState<string>('ALL')

  const [projects, setProjects] = useState<IManuallyTranslationWithId[]>([])
  const [opennedRejectProject, setOpennedRejectProject] = useState(false)
  const [opennedModal, setOpennedModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IManuallyTranslationWithId | null>(null)
  const [reasonText, setReasonText] = useState('')
  const setLocation = useLocation()[1]
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const [orderQuery, setOrderQuery] = useState<IOrderList>({
    field: 'searchTerms',
    direction: 'desc',
  })
  const [displayNames, setDisplayNames] = useState<Array<string | undefined>>([])
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const showSnackbar = useSnackbar()

  const initialProjectQuery = query(
    getCollectionReference(fsUser.workspace, 'manuallyTranslations') as Query<DocumentData>,
    orderBy(orderQuery.field, orderQuery.direction),
    limit(1),
  )
  const [projectsQueryState, setProjectsQueryState] = useState<Query<DocumentData>>(initialProjectQuery)
  const projectsCollection = useFirestoreCollection(projectsQueryState)

  // Filtro de pesquisa
  const statusToSearch: IOptionsSelect[] = [
    { label: intl.get('messages.all'), value: 'ALL' },
    {
      label: intl.get('pages.manuallyTranslation.statusList.requested'),
      value: 'requested',
    },
    {
      label: intl.get('pages.manuallyTranslation.statusList.approved'),
      value: 'approved',
    },
    {
      label: intl.get('pages.manuallyTranslation.statusList.inProgress'),
      value: 'inProgress',
    },
    {
      label: intl.get('pages.manuallyTranslation.statusList.finalized'),
      value: 'finalized',
    },
    {
      label: intl.get('pages.manuallyTranslation.statusList.rejected'),
      value: 'rejected',
    },
  ]

  // Filtro de pesquisa
  const typesToSearch: IOptionsSelect[] = [
    { label: intl.get('messages.all'), value: 'ALL' },
    {
      label: intl.get('pages.manuallyTranslation.typeList.hugoTeaches'),
      value: 'hugo-ensina',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.dictionary'),
      value: 'dictionary',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.internalCampaign'),
      value: 'campanha-interna',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.customerDisclosure'),
      value: 'divulgacao-cliente',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.internalActivity'),
      value: 'atividade-interna',
    },
    {
      label: intl.get('pages.manuallyTranslation.typeList.corpusRevision'),
      value: 'revisao-corpus',
    },
  ]

  const toggleOpennedRejectProjectDialog = () => {
    setOpennedRejectProject(!opennedRejectProject)
  }

  useEffect(() => {
    const _projects: IManuallyTranslationWithId[] = []

    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.lastOfList = projectsCollection.data.docs[projectsCollection.data.docs.length - 1]
    _paginationConfig.firstOfList = projectsCollection.data.docs[0]
    _paginationConfig.load = false

    for (const projectSnapshot of projectsCollection.data.docs) {
      const projectData = projectSnapshot.data() as IManuallyTranslationWithId
      projectData.id = projectSnapshot.id
      projectData.ref = projectSnapshot.ref
      _projects.push(projectData)
    }
    setPaginationConfig(_paginationConfig)
    setProjects(_projects)
    setHasMoreResults(paginationConfig.rowsPerPage === _projects.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsCollection.data])

  const handleClickItem = (id: string) => {
    setLocation(routes.manuallyTranslationMain.replace(':id', id))
  }

  const fetchProjects = useCallback(async () => {
    try {
      if (searchType.length == 0) return

      let projectsQuery = query(getCollectionReference(fsUser.workspace, 'manuallyTranslations') as Query<DocumentData>)

      if (!searchType.includes('ALL')) {
        projectsQuery = query(
          projectsQuery,
          where(
            'type',
            'in',
            searchType.filter((el) => el !== 'ALL'),
          ),
        )
      }
      if (searchStatus !== 'ALL') {
        projectsQuery = query(projectsQuery, where('status', '==', searchStatus))
      }
      if (searchText.trim()) {
        projectsQuery = query(
          projectsQuery,
          where('searchTerms', '>=', preProcess(searchText, fsUser.workspace.id, true, false, false, false)),
          where('searchTerms', '<=', preProcess(searchText, fsUser.workspace.id, true, false, false, false) + '\uf8ff'),
          orderBy('searchTerms', orderQuery.field == 'searchTerms' ? orderQuery.direction : 'asc'),
        )
      }
      if (orderQuery.field !== 'searchTerms' || !searchText.trim())
        projectsQuery = query(projectsQuery, orderBy(orderQuery.field, orderQuery.direction))

      if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
        if (paginationConfig.lastOfList) {
          projectsQuery = query(projectsQuery, startAfter(paginationConfig.lastOfList))
        } else {
          projectsQuery = query(
            projectsQuery,
            endBefore(paginationConfig.firstOfList),
            limitToLast(paginationConfig.rowsPerPage),
          )
        }
      }

      if (!paginationConfig.firstOfList) projectsQuery = query(projectsQuery, limit(paginationConfig.rowsPerPage))

      setProjectsQueryState(projectsQuery)
      // const videosSnapshot = await getDocs(videosQuery)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err) {
      const error = err as Error
      showSnackbar(error.message, { variant: 'error' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig, fsUser.workspace, searchType, searchStatus, searchText, projectsQueryState])

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.rowsPerPage = Number(event.target.value)
      _paginationConfig.currentPage = 0
      _paginationConfig.lastOfList = null
      _paginationConfig.firstOfList = null
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = (event.target && event.target.value) || ''
    setSearchText(text)
  }

  const handleSearchProject = useCallback(() => {
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.firstOfList = null
    _paginationConfig.lastOfList = null
    _paginationConfig.currentPage = 0
    _paginationConfig.load = true
    setPaginationConfig(_paginationConfig)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderQuery])

  const handleKeyUpSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSearchProject()
    }
  }

  useEffect(() => {
    if (paginationConfig.load) {
      fetchProjects()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  const handleChangeOrderBy = (field: string) => {
    let newDirection: 'asc' | 'desc' = 'desc'
    if (field === orderQuery.field && orderQuery.direction == 'desc') {
      newDirection = 'asc'
    }
    setOrderQuery({
      direction: newDirection,
      field,
    })
  }

  useEffect(() => {
    const fetchDisplayNames = async () => {
      const usersIds: Array<string | null> = []
      projects.map((row) => (row.responsible ? usersIds.push(row.responsible.id) : usersIds.push(null)))
      const data = await getUsersInBatchByIdFunction({ userIds: usersIds })
      if (data) {
        const displayNames = data.data.users.map((user) => user?.displayName)
        setDisplayNames(displayNames)
      }
      setIsLoadingData(false)
    }

    fetchDisplayNames()
  }, [projects])

  useEffect(() => {
    if (paginationConfig.load == true) {
      setIsLoadingData(paginationConfig.load)
    }
  }, [paginationConfig.load])

  const handleApproveRequest = async (
    uid: string,
    ref: DocumentReference | null,
    interpreter: DocumentReference[],
    projectName: string,
    id: string,
  ) => {
    try {
      if (ref) {
        const _interpreter = [...interpreter]
        _interpreter.push(fsUserRef)
        await updateManuallyTranslation(ref, {
          status: 'approved',
          interpreter: _interpreter,
          responsible: fsUserRef,
        })
      }

      await changeCMSStatus({
        uid,
        status: 'approved',
        userName: fsUser.displayName || '',
        projectName: projectName,
        id,
      })

      showSnackbar(intl.get('pages.manuallyTranslation.successfullyApproved'), {
        variant: 'success',
      })
    } catch (err) {
      showSnackbar(intl.get('messages.failed'), {
        variant: 'error',
      })
    }
  }

  const handleRejectRequest = (
    uid: string,
    ref: DocumentReference | null,
    interpreter: DocumentReference[],
    errors: IManuallyTranslationErrors[],
    projectName: string,
    id: string,
  ) => {
    if (!reasonText) {
      showSnackbar(intl.get('pages.manuallyTranslation.reasonNotStated'), {
        variant: 'error',
      })
      return
    }

    if (ref) {
      const _interpreter = [...interpreter]
      _interpreter.push(fsUserRef)

      const _errors = [...errors]
      _errors.push({
        message: reasonText,
        interpreter: fsUserRef,
        createdAt: Timestamp.fromDate(new Date()),
      })

      updateManuallyTranslation(ref, {
        errors: _errors,
        status: 'rejected',
        interpreter: _interpreter,
        responsible: fsUserRef,
      })
    }

    changeCMSStatus({
      id,
      uid,
      status: 'rejected',
      userName: fsUser.displayName || '',
      message: reasonText,
      projectName: projectName,
    })
    setReasonText('')
  }

  const handleOpenConfirmRejectRequest = (item: IManuallyTranslation) => {
    setSelectedItem(item)
    setOpennedRejectProject(true)
  }

  // Usuário não tem papel de escritor
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.manuallyTranslation')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.manuallyTranslation')}>
      <Grid item container justifyContent={'center'}>
        <Grid
          item
          md={10}
          style={{
            marginTop: '10px',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Fab
                style={{ paddingLeft: '8px' }}
                onClick={() => {
                  setLocation(routes.home)
                }}
              >
                <ArrowBackIcon />
              </Fab>
              <Box>
                <Typography variant={'h5'}> {intl.get('pages.manuallyTranslation.projectList')}</Typography>
              </Box>
            </Box>
            <Tooltip title={intl.get('messages.save')}>
              <Fab
                size="small"
                color="primary"
                onClick={() => {
                  setOpennedModal(true)
                }}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </Box>
          <Paper variant="outlined" style={{ width: '100%' }}>
            <Box
              display="flex"
              alignItems="center"
              borderBottom="1px solid #cecece"
              borderRadius={0}
              style={{ gap: '10px' }}
            >
              <InputBase
                placeholder={intl.get('messages.search')}
                style={{ padding: 10, width: '100%' }}
                value={searchText}
                onChange={handleSearchTextChange}
                onKeyUp={handleKeyUpSearch}
              />

              <Box width={120}>
                <MenuMultiselect
                  label={intl.get('messages.type')}
                  setValue={setSearchType}
                  value={searchType}
                  options={typesToSearch}
                />
              </Box>
              <Box width={120}>
                <Select
                  id="status-select"
                  label={intl.get('messages.status')}
                  value={searchStatus}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string | undefined
                      value: unknown
                    }>,
                  ) => {
                    setSearchStatus(event.target.value as string)
                  }}
                  optionsValues={statusToSearch}
                />
              </Box>
              <IconButton
                aria-label="search"
                style={{ padding: 10 }}
                onClick={() => {
                  handleSearchProject()
                }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Paper>
          <TableContainer style={{ height: '60vh' }} component={Paper}>
            {isLoadingData && (
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress />
                <Typography>Carregando dados, aguarde...</Typography>
              </Box>
            )}
            {!isLoadingData && (
              <Table stickyHeader aria-label="Project List">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleChangeOrderBy('searchTerms')
                        }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          {intl.get('pages.manuallyTranslation.projectName')}
                        </Typography>

                        {orderQuery.field == 'searchTerms' && orderQuery.direction == 'desc' && (
                          <ArrowUpIcon fontSize="small" />
                        )}
                        {orderQuery.field == 'searchTerms' && orderQuery.direction == 'asc' && (
                          <ArrowDownIcon fontSize="small" />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{intl.get('messages.type')}</TableCell>
                    <TableCell>{intl.get('messages.status')}</TableCell>
                    <TableCell>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleChangeOrderBy('createdAt')
                        }}
                      >
                        {intl.get('pages.manuallyTranslation.creationDate')}
                        {orderQuery.field == 'createdAt' && orderQuery.direction == 'desc' && (
                          <ArrowUpIcon fontSize="small" />
                        )}
                        {orderQuery.field == 'createdAt' && orderQuery.direction == 'asc' && (
                          <ArrowDownIcon fontSize="small" />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleChangeOrderBy('deadline')
                        }}
                      >
                        {intl.get('pages.manuallyTranslation.deadline')}
                        {orderQuery.field == 'deadline' && orderQuery.direction == 'desc' && (
                          <ArrowUpIcon fontSize="small" />
                        )}
                        {orderQuery.field == 'deadline' && orderQuery.direction == 'asc' && (
                          <ArrowDownIcon fontSize="small" />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{intl.get('pages.manuallyTranslation.requester')}</TableCell>
                    <TableCell>{intl.get('pages.manuallyTranslation.client')}</TableCell>
                    <TableCell>{intl.get('pages.manuallyTranslation.responsible')}</TableCell>
                    <TableCell>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleChangeOrderBy('progress')
                        }}
                      >
                        {intl.get('pages.manuallyTranslation.progress')}
                        {orderQuery.field == 'progress' && orderQuery.direction == 'desc' && (
                          <ArrowUpIcon fontSize="small" />
                        )}
                        {orderQuery.field == 'progress' && orderQuery.direction == 'asc' && (
                          <ArrowDownIcon fontSize="small" />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {projects.map((row: IManuallyTranslationWithId, index: number) => (
                    <StyledTableRow
                      key={row.id}
                      onClick={() => {
                        if (row?.id) handleClickItem(row.id)
                      }}
                    >
                      <TableCell component="th">{row.projectName || ''}</TableCell>
                      <TableCell>
                        {intl.get(`pages.manuallyTranslation.typeList.${cmsTypesName[row.type]}`) || ''}
                      </TableCell>
                      <TableCell>
                        {cmsStatusName[row.status]
                          ? intl.get(`pages.manuallyTranslation.statusList.${cmsStatusName[row.status]}`)
                          : row.status}
                      </TableCell>
                      <TableCell component="th">
                        {row.createdAt ? format(row.createdAt.toDate(), 'dd/MM/yyyy') : ''}
                      </TableCell>
                      <TableCell component="th">
                        {row.deadline ? format(row.deadline?.toDate(), 'dd/MM/yyyy') : ''}
                      </TableCell>
                      <TableCell component="th">{row.requester || ''}</TableCell>
                      <TableCell component="th">{row.client || ''}</TableCell>
                      <TableCell component="th">{displayNames[index]}</TableCell>
                      <TableCell component="th">{row.progress.toFixed(2) || '0'}%</TableCell>
                      <TableCell component="th">
                        <Tooltip title={intl.get('pages.manuallyTranslation.approveRequest')}>
                          <Fab
                            size={'small'}
                            onClick={(ev) => {
                              ev.stopPropagation()
                              if (row.status === 'approved') return
                              handleApproveRequest(
                                row.uid,
                                row.ref || null,
                                row.interpreter || [],
                                row.projectName || '',
                                row.id || '',
                              )
                            }}
                          >
                            <ThumbUpAltIcon />
                          </Fab>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th">
                        <Tooltip title={intl.get('pages.manuallyTranslation.rejectRequest')}>
                          <Fab
                            size={'small'}
                            onClick={(ev) => {
                              ev.stopPropagation()
                              if (row.status === 'rejected') return
                              handleOpenConfirmRejectRequest(row)
                            }}
                          >
                            <ThumbDownAltIcon />
                          </Fab>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <TablePagination
            style={{ pointerEvents: 'auto' }}
            component="div"
            count={-1}
            rowsPerPage={paginationConfig.rowsPerPage}
            page={paginationConfig.currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30]}
            labelRowsPerPage={intl.get('messages.labelRows')}
            labelDisplayedRows={() => ''}
            nextIconButtonProps={{ disabled: !hasMoreResults }}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={opennedRejectProject}
        toggleOpen={() => {
          setSelectedItem(null)
          setReasonText('')
          toggleOpennedRejectProjectDialog()
        }}
        handleYes={() => {
          toggleOpennedRejectProjectDialog()
          handleRejectRequest(
            selectedItem?.uid || '',
            selectedItem?.ref || null,
            selectedItem?.interpreter || [],
            selectedItem?.errors || [],
            selectedItem?.projectName || '',
            selectedItem?.id || '',
          )
          setSelectedItem(null)
        }}
        title={intl.get('pages.manuallyTranslation.confirmTheRejectionOfTheProject')}
        subtitle={''}
      >
        <TextField
          variant={'outlined'}
          value={reasonText}
          onChange={(ev) => {
            setReasonText(ev.target.value)
          }}
          label={intl.get('messages.reason')}
          fullWidth={true}
        />
      </ConfirmationDialog>
      <ModalIncludeManuallyTranslation open={opennedModal} setOpen={setOpennedModal} data={null} />
    </Layout>
  )
}

export default ManuallyTranslation
