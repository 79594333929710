import React from 'react'
import { IManuallyTranslationSentence } from 'collections'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import { SearchSignItem } from '../SearchSignItem'
import { IFingerspell, ISignWithHta } from '../index'

interface ISearchSignProps {
  items: (ISignWithHta | IFingerspell)[]
  onPlayAnimation: (item?: IManuallyTranslationSentence | null, itemSearch?: ISignWithHta | IFingerspell) => void
  isPlaying: string | null
  onStopAnimation: () => void
}

const SearchSign = ({ items, onPlayAnimation, isPlaying, onStopAnimation }: ISearchSignProps) => {
  const { setNodeRef: setNodeRefSearch } = useDroppable({
    id: `searchdata--1`,
  })

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <SortableContext
        id={`searchdata--1`}
        items={items.map((el) => el.draggableId || '') || []}
        strategy={rectSortingStrategy}
      >
        <div ref={setNodeRefSearch}>
          {/*<Table aria-label="customized table">*/}
          {/*  <TableBody>*/}
          {items.map((item) => (
            <SearchSignItem
              item={item}
              onStopAnimation={onStopAnimation}
              onPlayAnimation={onPlayAnimation}
              isPlaying={isPlaying}
              key={item.draggableId}
            />
          ))}
        </div>
      </SortableContext>
    </div>
  )
}

export default SearchSign
