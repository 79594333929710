import React from 'react'
import { Box, Divider, IconButton, Tooltip, Typography } from '@material-ui/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import { IManuallyTranslationTarget } from 'collections'
import { NMTSuggestionItem } from '../NMTSuggestionItem'
import PublishIcon from '@material-ui/icons/Publish'
import useIntl from '../../../../hooks/useIntl'

interface INMTSuggestion {
  items: IManuallyTranslationTarget[]
  accordionId: string

  onGetSignByClick: (item: IManuallyTranslationTarget) => void
  onReplaceAllTarget: () => void
}

export const NMTSuggestion: React.FC<INMTSuggestion> = ({
  items,
  accordionId,
  onGetSignByClick,
  onReplaceAllTarget,
}) => {
  const { setNodeRef: setNodeRefNMTSearch } = useDroppable({
    id: `searchdata--2--${accordionId}`,
  })

  const intl = useIntl()

  return (
    <>
      {items.length > 0 && (
        <Box
          style={{
            marginTop: '10px',
            width: '100%',
            padding: '15px',
          }}
        >
          <Divider />
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Typography variant={'subtitle2'}>NMT</Typography>
            <Tooltip title={intl.get('pages.manuallyTranslation.replaceTranslation')}>
              <IconButton
                size={'small'}
                onClick={() => {
                  onReplaceAllTarget()
                }}
              >
                <PublishIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <SortableContext
            id={`searchdata--2--${accordionId}`}
            items={items.map((el) => el.draggableId || '') || []}
            strategy={rectSortingStrategy}
          >
            <div
              ref={setNodeRefNMTSearch}
              style={{
                flexDirection: 'row',
                display: 'flex',
                width: '100%',
                alignItems: 'flex-start',
                gap: '10px',
                overflow: 'auto',
                padding: '10px',
                flexWrap: 'wrap',
              }}
            >
              {items.map((item) => (
                <NMTSuggestionItem onGetSignByClick={onGetSignByClick} key={item.draggableId} item={item} />
              ))}
            </div>
          </SortableContext>
        </Box>
      )}
    </>
  )
}
