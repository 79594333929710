import useIntl from './useIntl'

export const useCategories = () => {
  const intl = useIntl()
  const sentencesCategories: string[] = intl
    .get('sentenceCategories')
    .split(' | ')
    .filter((el) => {
      return el != '' && el != ','
    })
    .sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0
    })

  return {
    sentencesCategories,
  }
}
