import React, { useState, useCallback, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import SignList from './SignList'
import SignSearchPure from './Pure'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { Query, collection, query, where, orderBy, limit, documentId } from '@firebase/firestore'
import { useFirestore, useStorage } from 'reactfire'
import useGetNmtSign from '../../hooks/useGetNmtSign'
import { CircularProgress } from '@material-ui/core'
import { preProcess } from 'collections'
import useIntl from '../../hooks/useIntl'
import { getDownloadURL, ref } from '@firebase/storage'
import { ISegmentViewProcessVideo } from '../../pages/ProcessVideo'
import { useSignRecognition } from '../../hooks/useSignRecognition'
import { IReturnSignRecognition } from '../../types/keypoints'

interface ISignSearchProps {
  /** Callback para texto de busca alterado */
  onSearchTermChange?: (text: string) => void

  segmentIdToSuggestion?: ISegmentViewProcessVideo | null
  setsegmentToSuggestion?: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo | null>>
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
}
/**
 * Componente de pesquisa de sinais
 */
const SignSearch: React.FC<ISignSearchProps> = ({
  onSearchTermChange,
  segmentIdToSuggestion,
  setsegmentToSuggestion,
  search,
  setSearch,
}) => {
  const [queryState, setQueryState] = useState<Query>()
  const [currentText, setCurrentText] = useState('')
  const fsUser = useFsUserDocData()
  const firestore = useFirestore()
  const [nmtSearch, setNmtSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const nmtSign = useGetNmtSign()[0]
  const intl = useIntl()
  const textSignSearch = intl.get('messages.signSearch')
  const [similarities, setSimilarities] = useState<string[]>([])
  const [countResults, setCountResults] = useState(0)

  const onResultsSignRecognition = (resPredict?: IReturnSignRecognition[]) => {
    const resultPredict = resPredict && resPredict.length > 0 ? resPredict[0].classes.map((el) => el.id) : []

    const _similarities = resultPredict.filter(
      (result) => result.toLowerCase() !== 'fingerspell' && result.toLowerCase() !== 'unknown',
    )

    setSimilarities(_similarities)
    handleClickSearch(_similarities.slice(0, 2))
  }

  const { predictSignRecognition } = useSignRecognition({
    onResults: (predictResult) => {
      onResultsSignRecognition(predictResult)
    },
  })

  const storage = useStorage()
  const textLoadMore = intl.get('messages.loadMore')

  const getSimilaritiesByModel = async (segmentIdToSuggestion: ISegmentViewProcessVideo | null) => {
    if (!segmentIdToSuggestion) return []
    // baixa keypoints
    const videoId = segmentIdToSuggestion.video.id //__MEDIAPIPE
    const keypointsPath = `/workspaces/${fsUser.workspace.id}/videos/${videoId}/${videoId}__MEDIAPIPE.json`
    getDownloadURL(ref(storage, keypointsPath)).then((keypointsURL) => {
      fetch(keypointsURL).then((res) => {
        res.json().then((keypoints) => {
          const keypointsData = keypoints.data

          const startFrame = segmentIdToSuggestion.startFrame
          const endFrame = segmentIdToSuggestion.endFrame
          const duration = segmentIdToSuggestion.videoDuration

          const startKeypoints = Math.round((startFrame / duration) * keypointsData.pose.length)

          const endKeypoints = Math.round((endFrame / duration) * keypointsData.pose.length)

          const keypointsToPredict: number[][][] = []

          for (let i = startKeypoints; i <= endKeypoints; i++) {
            keypointsToPredict.push(
              keypointsData.pose[i].concat(keypointsData.hand_l[i]).concat(keypointsData.hand_r[i]),
            )
          }

          predictSignRecognition(keypointsToPredict, [[0, keypointsToPredict.length]])
        })
      })
    })
  }

  useEffect(() => {
    if (segmentIdToSuggestion) {
      getSimilaritiesByModel(segmentIdToSuggestion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentIdToSuggestion])

  const handleClickLoadMore = () => {
    if (segmentIdToSuggestion && countResults == 2) {
      handleClickSearch(similarities, true)
    }
  }

  const handleClickSearch = useCallback(
    async (nmtSuggestionsBySegment?: string[], clearSuggestion?: boolean) => {
      setIsLoading(true)
      const signsRef = collection(firestore, 'workspaces', fsUser.workspace.id, 'signs')

      if (nmtSuggestionsBySegment && nmtSuggestionsBySegment.length) {
        const newQuery = query(signsRef, where(documentId(), 'in', nmtSuggestionsBySegment), limit(10))
        setQueryState(newQuery)
        if (setsegmentToSuggestion && clearSuggestion) setsegmentToSuggestion(null)
      } else if (currentText) {
        setSimilarities([])
        if (nmtSearch) {
          const nmtResultSigns = await nmtSign.getSignsXpresstranslation(currentText)
          if (nmtResultSigns && nmtResultSigns?.length) {
            const newQuery = query(signsRef, where(documentId(), 'in', nmtResultSigns), limit(10))
            setQueryState(newQuery)
          }
        } else {
          const _searchTerms = preProcess(currentText, fsUser.workspace.id, true, true, false, false)
          const newQuery = query(
            signsRef,
            where('signLanguageId', '==', fsUser.signLanguageId),
            where('oralLanguageId', '==', fsUser.oralLanguageId),
            where('searchTerms', 'array-contains', _searchTerms),
            where('_state', 'in', ['ANIMATED', 'PROMOTED']),
            orderBy('corpusPopularity', 'desc'),
            limit(10),
          )
          setQueryState(newQuery)
        }
      }
      if (onSearchTermChange) onSearchTermChange(currentText)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser.oralLanguageId, fsUser.signLanguageId, fsUser.workspace, onSearchTermChange, nmtSearch, currentText],
  )

  const handleSearchTextChanged = (term: string) => {
    setCurrentText(term)
  }

  const Loading = () => (
    <Box display="flex" alignItems="center" style={{ flex: 1 }} justifyContent="center">
      <CircularProgress variant="indeterminate" />
    </Box>
  )

  const searchResult = (
    <>
      {isLoading && <Loading />}
      {queryState && (
        <>
          <SignList
            query={queryState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            glosa={currentText}
            nmtSuggestionsBySegment={similarities}
            setCountResults={setCountResults}
          />
        </>
      )}
    </>
  )

  return (
    <SignSearchPure
      onSearchTermChange={handleSearchTextChanged}
      onClickSearch={handleClickSearch}
      nmtSearch={nmtSearch}
      setNmtSearch={setNmtSearch}
      textSignSearch={textSignSearch}
      search={search}
      setSearch={setSearch}
      loadMoreVisible={!isLoading && segmentIdToSuggestion != null && similarities.length != countResults}
      textLoadMore={textLoadMore}
      handleClickLoadMore={handleClickLoadMore}
    >
      <Box>
        {/** Exibe o resultado da pesquisa */}
        {searchResult}
      </Box>
    </SignSearchPure>
  )
}

export default SignSearch
