import React, { ChangeEvent } from 'react'
import { IListText, IPhraseListPagination, ISentence } from '..'
import PhraseListPure from './Pure'

interface IPhraseListProps {
  data: ISentence[]
  setSentences: React.Dispatch<React.SetStateAction<ISentence[]>>
  categories: string[]
  handleOnDeletePhraseClick: (key: string) => void
  handleOnClickRecordPhrase: (key: string, sentence: string) => void
  handleOnValidate: (event: React.ChangeEvent<HTMLInputElement>, sentenceData: ISentence, oldSentence: string) => void
  isLoading: boolean
  loadMoreSentences: (loadMore?: number) => void
  loggedUser: string
  paginationConfig: IPhraseListPagination
  hasMorePhrases: boolean
  handleChangePage: (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void
  listTexts: IListText
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PhraseList: React.FC<IPhraseListProps> = ({
  data,
  setSentences,
  categories,
  handleOnDeletePhraseClick,
  handleOnClickRecordPhrase,
  handleOnValidate,
  isLoading,
  loadMoreSentences,
  loggedUser,
  paginationConfig,
  hasMorePhrases,
  handleChangePage,
  listTexts,
  handleChangeRowsPerPage,
}) => {
  return (
    <PhraseListPure
      data={data || []}
      setSentences={setSentences}
      categories={categories}
      handleOnDeletePhraseClick={handleOnDeletePhraseClick}
      handleOnClickRecordPhrase={handleOnClickRecordPhrase}
      handleOnValidate={handleOnValidate}
      isLoading={isLoading}
      loadMoreSentences={loadMoreSentences}
      hasMorePhrases={hasMorePhrases}
      loggedUser={loggedUser}
      handleChangePage={handleChangePage}
      paginationConfig={paginationConfig}
      listTexts={listTexts}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

export default PhraseList
