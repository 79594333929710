import React, { useEffect, useRef } from 'react'
import useComponentSize from '@rehooks/component-size'
import useCamera from '../../../services/hooks/useCamera'
import CameraStream from './Pure'

const proportion = 16 / 9

interface ICameraStreamProps {
  className?: string
  camWidth?: string
  camHeight?: string
  showTemplate?: boolean
  overlay?: string
  children?: React.ReactNode

  template?: JSX.Element
  textUnableAccessCamera: string
  isRecording?: boolean
  videoRef?: React.RefObject<HTMLVideoElement>
}

const CameraStreamUse: React.FC<ICameraStreamProps> = ({
  className,
  camWidth,
  camHeight,
  showTemplate,
  overlay,
  template,
  textUnableAccessCamera,
  isRecording,
  videoRef,
}) => {
  const [cameraState, cameraFn] = useCamera()

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const wrapperSize = useComponentSize(wrapper)

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.height = videoRef?.current?.clientHeight || 0
      canvasRef.current.width = videoRef?.current?.clientWidth || 0
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef?.current, canvasRef?.current])

  // Atualiza a fonte do vídeo baseado na stream
  useEffect(() => {
    if (cameraState.stream && videoRef?.current) {
      if (videoRef) {
        videoRef.current.srcObject = cameraState.stream
        videoRef.current.play()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraState.stream])

  // Calcula a largura e altura do máximo possível mantendo a proporção
  let { width, height } = wrapperSize

  if (width / height > proportion) {
    width = height * proportion
  } else {
    height = width / proportion
  }

  const handleCameraActiveRequested = () => {
    cameraFn.getStream()
  }

  return (
    <CameraStream
      className={className}
      isRecording={isRecording || cameraState.isRecording}
      isCameraEnabled={cameraState.isEnabled || false}
      onRequestCamera={handleCameraActiveRequested}
      width={camWidth ? camWidth : width}
      height={camHeight ? camHeight : height}
      wrapperRef={wrapper}
      videoRef={videoRef}
      showTemplate={showTemplate}
      overlay={overlay}
      resolution={cameraState.resolution}
      canvasRef={canvasRef}
      template={template}
      textUnableAccessCamera={textUnableAccessCamera}
    />
  )
}

export default CameraStreamUse
