import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Select from '@material-ui/core/Select'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import Box from '@material-ui/core/Box'
import { useEffect } from 'react'
import { separeVideoSentence } from '../../../services/utils'

interface IItemStyleProps {
  variant: 'unknown' | 'create' | 'fingerspell' | 'link'
  isLocked: boolean
  isDragOver: boolean
  showDropZone: boolean
}
const ItemStyle = styled(Grid)`
  width: 100%
  height: 100%;
  background: #FF4F47;
  font-size: 1.5em;
  overflow: hidden;
  padding: 40px 8px 0px 8px;
  user-selection: none;
  box-sizing: border-box;
  color: white;
  border-top: solid 5px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  transition: background 100ms;

  ${({ variant }: IItemStyleProps) =>
    (variant === 'link' &&
      css`
        background: #2296f3;
      `) ||
    (variant === 'fingerspell' &&
      css`
        background: #d46d2a;
      `) ||
    (variant === 'create' &&
      css`
        background: #26a7a0;
      `)}

  ${({ showDropZone }: IItemStyleProps) =>
    showDropZone &&
    css`
      opacity: 1;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      border: dashed #fff 2px;
    `}

  ${({ isDragOver }: IItemStyleProps) =>
    isDragOver &&
    css`
      background: rgba(255, 255, 255, 0.6);
      border: dashed #fff 2px;
    `}

  ${({ isLocked }: IItemStyleProps) =>
    isLocked &&
    css`
      opacity: 0.6;
    `}
`

const ActionsStyle = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 8px;
  display: inline-flex;
  height: content-fit;
  width: content-fit;
  align-items: center;
  justify-content: center;
  padding: 5px;
`

const IconButtonStyled = styled(IconButton)`
  svg {
    color: white;
  }
`

const ResizerContainer = styled.div``

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      marginLeft: '10px',
    },
    icons: {
      fill: theme.palette.type === 'light' ? theme.palette.grey[900] : '#FFF',
    },
  }),
)

interface IResizerDivProps {
  direction: 'left' | 'right'
}
const ResizerDiv = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  width 7px;
  height: 100%;
  top: 0px;
  z-index: 5;
  ${({ direction }: IResizerDivProps) =>
    (direction === 'left' &&
      css`
        left: 0px;
      `) ||
    (direction === 'right' &&
      css`
        right: 0px;
      `)}
`

interface ITimelineItemProps {
  variant: 'unknown' | 'create' | 'fingerspell' | 'link'
  isLocked: boolean
  isDropActive: boolean
  isDragOver: boolean
  isMouseOver: boolean
  handleButtonRemoveClicked?: () => void
  handleOnMouseOver?: () => void
  handleOnMouseExit?: () => void
  sentence?: string
  selectedFingerspellSign?: string
  onSelectFingerspellSign?: (text: string) => void
  onPlayRegion?: () => void
  isPlaying?: boolean
  handleOnClick?: () => void
  selectIsOpened?: boolean
  textPlaySegment: string
  textDeleteSegment: string
  textSign: string
  textFingerspell: string
  textNotFingerspell: string
  editing: boolean
}

/**
 * Este componente deve ser renderizado dentro de Timeline,
 * o mesmo gerencia todo fluxo de edição e movimentação
 * de uma entrada especifica @param
 */
export const TimelineItem: FC<ITimelineItemProps> = ({
  variant,
  handleOnMouseOver,
  isDragOver,
  isLocked,
  isMouseOver,
  handleButtonRemoveClicked,
  isDropActive,
  handleOnMouseExit,
  sentence,
  selectedFingerspellSign,
  onSelectFingerspellSign,
  onPlayRegion,
  isPlaying,
  handleOnClick,
  selectIsOpened,
  textPlaySegment,
  textDeleteSegment,
  textSign,
  textFingerspell,
  textNotFingerspell,
  editing,
}) => {
  const classes = useStyles()
  const [menuItems, setMenuItems] = useState<string[]>([])

  useEffect(() => {
    const separedSentence = separeVideoSentence(sentence || '')
    setMenuItems(separedSentence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    if (handleOnClick) handleOnClick()
  }

  const handleSelectFingerspellSign = (text: string) => {
    if (onSelectFingerspellSign) onSelectFingerspellSign(text || '')
  }

  return (
    <>
      <ItemStyle
        variant={variant}
        onMouseEnter={handleOnMouseOver}
        onMouseLeave={handleOnMouseExit}
        isDragOver={isDragOver}
        isLocked={isLocked}
        showDropZone={isDropActive}
        onClick={onClick}
      >
        {isMouseOver && (
          <ResizerContainer>
            <ResizerDiv direction="left" />
            <ResizerDiv direction="right" />
          </ResizerContainer>
        )}

        <Select
          className="notranslate"
          defaultValue="sign"
          id="grouped-select"
          open={selectIsOpened}
          value={selectedFingerspellSign || 'sign'}
          onChange={(
            event: React.ChangeEvent<{
              name?: string | undefined
              value: unknown
            }>,
          ) => {
            handleSelectFingerspellSign(event.target.value as string)
          }}
        >
          <ListSubheader>{textNotFingerspell}</ListSubheader>
          <MenuItem className={`${classes.menuItem} notranslate`} value={'_sign'}>
            {textSign}
          </MenuItem>
          <ListSubheader>{textFingerspell}</ListSubheader>
          {menuItems.map((value, id) => (
            <MenuItem className={`${classes.menuItem} notranslate`} value={value} key={id}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {isMouseOver && (
          <Box
            style={{
              position: 'absolute',
              top: '-30px',
              width: 'auto',
              left: '0px',
            }}
          >
            <Box style={{ width: '70px' }}>
              <ActionsStyle style={{ right: 'auto', left: '0px' }}>
                <Tooltip title={textPlaySegment}>
                  <IconButtonStyled onClick={onPlayRegion} disabled={isLocked && !isPlaying} size="small">
                    {isPlaying ? (
                      <PauseCircleOutlineIcon style={{ color: '#000' }} className={classes.icons} />
                    ) : (
                      <PlayCircleOutlineIcon className={classes.icons} />
                    )}
                  </IconButtonStyled>
                </Tooltip>
              </ActionsStyle>

              {!isDropActive && !editing && (
                <ActionsStyle style={{ left: 'auto', right: '0px' }}>
                  <Tooltip title={textDeleteSegment}>
                    <IconButtonStyled onClick={handleButtonRemoveClicked} disabled={isLocked} size="small">
                      <DeleteIcon className={classes.icons} />
                    </IconButtonStyled>
                  </Tooltip>
                </ActionsStyle>
              )}
            </Box>
          </Box>
        )}
      </ItemStyle>
    </>
  )
}
export default TimelineItem
