import firebase from 'firebase/compat/app'
import { ISimilarityVote, TCorpusGroup, UserRecordAdmin } from '.'
import { CollectionReference, DocumentReference, DocumentReferenceGeneric, Timestamp } from './firebase'
import { SignId } from './sign'
import { CommunityModule, IUser, IUserInfo, UserId, WorkspaceId } from './user'
import { IVideo, VideoId } from './video'
import { IWorkspaceUserClaims } from './workspace'

export type LanguagePair = 'eng-ase' | 'por-bzs' | 'enb-bfi'
export type DocOrColReference = DocumentReference | CollectionReference

export enum OralLanguageId {
  eng = 'eng',
  por = 'por',
  enb = 'enb',
}

export type PriorityQueue =
  | 'matchSegmentsPriority'
  | 'newMatchSegmentsPriority'
  | 'translationPriority'
  | 'recordVideoPriority'

export enum SignLanguageId {
  ase = 'ase',
  bzs = 'bzs',
  bfi = 'bfi',
}

// TODO - Remover este type assim que o sistema de contribuição estiver pronto, ele foi feito para uma funcao temporaria
export interface ITempContrib {
  name: string
  contrib: number
}

/**
 * Entity base
 */
export interface IEntity {
  /** Timestamp with the creation date of this item */
  createdAt: Timestamp
  /** Reference of the user who created this item */
  createdBy: DocumentReferenceGeneric
  /** Language spoken by this entity */
  oralLanguageId: OralLanguageId
  /** Sign language of this entity */
  signLanguageId: SignLanguageId
}

/**
 * OrderBy configuration to order query results.
 *
 */
export interface IOrderByFirestore {
  /** Field which the results will be sorted */
  fieldPath: string | firebase.firestore.FieldPath
  /** Sort direction */
  direction?: firebase.firestore.OrderByDirection
}

/**
 * Functions
 */

// Functions
export type FunctionName = 'sendFeedbackOnCall' | 'getNextSignSentence' | 'getNextVideosToTag' | 'drawUsersContrib'

export interface IFunctionDefinition {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  output: any
}

export interface IProcessVideoInput {
  segmentId: string
  votes: ISimilarityVote
  score?: number
}

export interface IFunctions extends Record<FunctionName, IFunctionDefinition> {
  feedback: {
    name: 'feedback'
    input: { text: string }
    output: void
  }
  getNextSignSentence: {
    name: 'getNextSignSentence'
    input: { oralLanguageId: OralLanguageId }
    output: { sentence: string }
  }
  getNextSentenceFromDatabase: {
    name: 'getNextSentenceFromDatabase'
    input: {
      workspaceId: string
      signLanguageId: SignLanguageId
      oralLanguageId: OralLanguageId
      communityModule: CommunityModule
    }
    output: {
      sentence: string
    }
  }
  getVideoOfMyWorkspaces: {
    name: 'getNextVideosToTag'
    input: object
    output: {
      videos: Record<VideoId, IVideo>
    }
  }
  skipVideoForTag: {
    name: 'skipVideoForTag'
    input: {
      videoId: VideoId
    }
    output: void
  }
  addContribSegment: {
    name: 'addContribSegment'
    input: {
      videoId: VideoId
      userId: UserId
      workspaceId: WorkspaceId
      segmentsToProcess?: number
      numberOfSegments?: number
    }
    output: void
  }
  setUserName: {
    name: 'setUserName'
    input: {
      userId: UserId
      displayName: string
    }
    output: void
  }
  createFirebaseUser: {
    name: 'createFirebaseUser'
    input: {
      uid: string
      email: string
      displayName: string
      oralLanguageId: string
      workspaceId: string
      role: string
      signLanguageId: string
      modules: CommunityModule[]
      isExternal: boolean
    }
    output: void
  }

  addContribFindIdenticalSegments: {
    name: 'addContribFindIdenticalSegments'
    input: {
      videoId: VideoId
      userId: UserId
      workspaceId: WorkspaceId
      segmentId: string
      votes: ISimilarityVote
    }
    output: void
  }

  addContribModule: {
    name: 'addContribModule'
    input: {
      communityModule: CommunityModule
      fbUser: UserRecordAdmin
      user: IUser
      oralLanguageId: OralLanguageId
      signLanguageId: SignLanguageId
      workspaceId: WorkspaceId
      amount: number
    }
    output: void
  }
  addContribAnimationReview: {
    name: 'addContribAnimationReview'
    input: {
      signId: SignId
      userId: UserId
      workspaceId: WorkspaceId
    }
    output: void
  }
  getNextVideosToTag: {
    name: 'getVideoOfMyWorkspaces'
    input: object
    output: {
      videos: Record<VideoId, IVideo>
    }
  }
  drawUsersContrib: {
    name: 'drawUsersContrib'
    input: {
      workspaceId: WorkspaceId
    }
    output: {
      contribs: Record<UserId, ITempContrib>
    }
  }
  checkUserRole: {
    name: 'checkUserRole'
    input: {
      workspaceId: WorkspaceId
    }
    output: boolean
  }
  getUserById: {
    name: 'getUserById'
    input: {
      userId: UserId
    }
    output: {
      user: IUserInfo
    }
  }
  getUsersInBatchByIds: {
    name: 'getUsersInBatchByIds'
    input: {
      userIds: Array<string | null>
    }
    output: {
      users: Array<IUserInfo | null>
    }
  }
  getUsersByWorkspace: {
    name: 'getUsersByWorkspace'
    input: object
    output: { users: Record<UserId, IUserInfo> }
  }
  getAllUsers: {
    name: 'getAllUsers'
    input: object
    output: { users: Record<UserId, IUserInfo> }
  }
  modifyUserPermissions: {
    name: 'modifyUserPermissions'
    input: {
      targetUser: DocumentReference
      targetWorkspace: DocumentReference
      workspacePermissions: IWorkspaceUserClaims
    }
    output: { result: boolean; message: string }
  }
  updatePriority: {
    name: 'updatePriority'
    input: {
      sentence: string
      oralLanguageId: OralLanguageId
      signLanguageId: SignLanguageId
      priority?: number
      isCorrect?: boolean
      recordedVideo?: boolean
      workspaceId: string
      sentenceOrigin?: string
      reported?: boolean
      corpusGroup?: TCorpusGroup
      sentenceCategory?: string[] | null
      isValidated?: boolean
      clientId?: string
    }
    output: void
  }
  updateMatchSegmentsPriority: {
    name: 'updateMatchSegmentsPriority'
    input: {
      oralLanguageId: OralLanguageId
      signLanguageId: SignLanguageId
      segmentQueueId: string
      workspaceId: string
      reported?: boolean
    }
    output: void
  }
  changePrimarySegment: {
    name: 'changePrimarySegment'
    input: {
      segmentId: string
      workspaceId: string
      signId: string
    }
    output: void
  }
  removeSegmentFromCluster: {
    name: 'removeSegmentFromCluster'
    input: {
      segmentId: string
      workspaceId: string
      userId: string
    }
    output: void
  }
  joinClusters: {
    name: 'joinClusters'
    input: {
      signId: string
      workspaceId: string
      signIds: string[]
    }
    output: string
  }
  promoteClusterToSign: {
    name: 'promoteClusterToSign'
    input: {
      signId: string
      workspaceId: string
    }
    output: void
  }
  listClusterEdges: {
    name: 'listClusterEdges'
    input: {
      segmentA: string
      segmentB: string
      workspaceId: string
      currentMovementId: string
    }
    output: string[]
  }
  separateCluster: {
    name: 'separateCluster'
    input: {
      workspaceId: string
      currentMovementId: string
      userId: string
    }
    output: string
  }
  getNextSignToJoinCluster: {
    name: 'getNextSignToJoinCluster'
    input: {
      workspaceId: string
      signLanguageId: SignLanguageId
      oralLanguageId: OralLanguageId
    }
    output: {
      [signId: string]: {
        priority: number
        similars: string[]
      }
    }
  }
  acceptLGPDTerms: {
    name: 'acceptLGPDTerms'
    input: string
    output: void
  }
  processVideoOnCall: {
    name: 'processVideoOnCall'
    input: {
      userId: UserId
      workspaceId: WorkspaceId
      processVideoData: IProcessVideoInput[]
    }
    output: void
  }
  getWorkspaceToUser: {
    name: 'getWorkspaceToUser'
    input: void
    output: { name: string; path: string }[]
  }
  createUserOnWorkspace: {
    name: 'createUserOnWorkspace'
    input: {
      workspaceId: string
      userId: string
      userName: string
      isExternal: boolean
    }
    output: void
  }
  backVideoStateOnCall: {
    name: 'backVideoStateOnCall'
    input: {
      videoId: VideoId
      newState: 'RECORDED' | 'DELETED'
      workspaceId: WorkspaceId
    }
    output: void
  }

  deleteSignOnDemandOnCall: {
    name: 'deleteSignOnDemandOnCall'
    input: {
      signOnDemandId: string
      workspaceId: WorkspaceId
    }
    output: void
  }

  sendMailOnCall: {
    name: 'sendMailOnCall'
    input: {
      text: string
      to: string
      subject: string
      html?: string | Buffer | undefined
      cc?: string
    }
    output: void
  }
  updateVideoBQ: {
    name: 'updateVideoBQ'
    input: {
      query: string
    }
    output: void
  }
  addContribManualTranslation: {
    name: 'addContribManualTranslation'
    input: {
      userId: UserId
      workspaceId: WorkspaceId
    }
    output: void
  }
}

/**
 * Dados de contribuição para logar
 */
export interface IContributionLog {
  /** Identificador */
  identifier: string
  /** Progresso */
  progress: number
  /** Sentença para exibição */
  sentence?: string
  /** Modulo da contribuição */
  communityModule: CommunityModule
  /** Data de contribuição */
  createdAt: Date
}

export interface IBatchOperation {
  ref: DocumentReferenceGeneric
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  op: 'update' | 'delete' | 'set'
}
