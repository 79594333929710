import React, { FC } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Fab from '@material-ui/core/Fab'

export interface IUserAvatarProps {
  displayName: string
  imageUrl?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

/**
- Use um avatar para atribuir ações ou conteúdo a usuários específicos.
**/
export const AvatarPure: FC<IUserAvatarProps> = ({ onClick, imageUrl, displayName, children }) => {
  const avatar = (
    <Avatar alt={displayName} src={imageUrl}>
      {children}
    </Avatar>
  )

  if (!onClick) {
    return avatar
  }

  return (
    <Fab size="small" aria-label="Menu do usuário" onClick={onClick}>
      {avatar}
    </Fab>
  )
}

export default AvatarPure
