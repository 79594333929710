import { useAuth, useUser } from 'reactfire'
import React, { useState, useCallback } from 'react'
import { useLocation } from 'wouter'

import UserMenuPure from './Pure'
import { UserAvatar } from '../Avatar'
import useDialog from '../../services/hooks/useDialog'
import ThemeSelect from '../inputs/SelectTheme'
import useKeyPress from '../../hooks/useKeyPress'
import useFeatureToggle from '../../services/hooks/useFeatureToggle'
import { routes } from '../../community'
import WorkspaceSelect from '../inputs/WorkspaceSelect'
import useIntl from '../../hooks/useIntl'
import useLGPDCheck from '../../services/hooks/useLGPDCheck'
import { clearFirestoreCache } from '../../services/utils'
import { useGlobal } from 'reactn'
import { IGlobalAttr } from 'collections'

const ControlledUserMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const altPressed = useKeyPress(18) // ALT Key
  // O type de useAuth está errado! esse cast resolve o problema
  const auth = useAuth() as unknown as firebase.default.auth.Auth
  const fbUser = useUser<firebase.default.User>().data
  const [featureToggleStates] = useFeatureToggle()
  const setLocation = useLocation()[1]

  const email = fbUser?.email || 'no-email'
  const displayName = fbUser?.displayName || 'Anonymous'

  const handleFeedbackClick = useDialog('feedback')[1]
  const handleFeatureToggleClick = useDialog('featureToggle')[1]
  const handleAvatarClick = useDialog('upload')[1]
  const intl = useIntl()
  const textConfiguration = intl.get('menu.configuration')
  const textSendFeedback = intl.get('menu.sendFeedback')
  const textLogOut = intl.get('menu.logOut')
  const textTermsOfService = intl.get('menu.termsOfService')
  const textHelp = intl.get('menu.help')
  const showTermsOfService = useLGPDCheck()[1]

  const [urlHelpCommunity] = useGlobal<IGlobalAttr, 'urlHelpCommunity'>('urlHelpCommunity')

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (altPressed) {
        handleFeatureToggleClick()
      } else if (anchorEl) {
        setAnchorEl(undefined)
      } else {
        setAnchorEl(event.currentTarget)
      }
      // Se o usuário estiver segurando alt e clicar no botão do menu...
      // ... iremos exibir o featureToggle
    },
    [altPressed, anchorEl, handleFeatureToggleClick],
  )

  const handleLogoutClick = () => {
    auth.signOut().then(() => clearFirestoreCache())
  }

  const handleAdminClick = () => {
    setLocation(routes.admin)
  }

  const handleConfigurationClick = () => {
    setLocation(routes.configuration)
  }

  const handleTermsOfServiceClick = () => {
    showTermsOfService()
  }

  const handleHelpClick = () => {
    open(urlHelpCommunity, '_blank')
  }

  return (
    <>
      <UserAvatar onClick={toggleMenu} />
      <UserMenuPure
        anchorEl={anchorEl}
        userAvatar={<UserAvatar />}
        selectTheme={<ThemeSelect />}
        workspaceSelect={<WorkspaceSelect />}
        email={email}
        canShowAdmin={featureToggleStates.featuresMap.adminDashboard}
        displayName={displayName}
        onAvatarClick={handleAvatarClick}
        onFeedbackClick={handleFeedbackClick}
        onLogoutClick={handleLogoutClick}
        onAdminClick={handleAdminClick}
        toggleMenu={toggleMenu}
        onConfigurationClick={handleConfigurationClick}
        textConfiguration={textConfiguration}
        textSendFeedback={textSendFeedback}
        textLogOut={textLogOut}
        textTermsOfService={textTermsOfService}
        textHelp={textHelp}
        onTermsOfServiceClick={handleTermsOfServiceClick}
        onHelpClick={handleHelpClick}
      />
    </>
  )
}

export default ControlledUserMenu
