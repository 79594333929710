import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'

import { Box, Grid, Hidden, Typography } from '@material-ui/core'

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

import AppBarPure from './Pure'
import UserMenu from '../UserMenu'
import ContributionsLogger from '../ContributionsLogger'
import { CommunityModule } from 'collections'
import CardContribution from '../CardContribution'
import usaImg from '../../images/usa.png'
import brazilImg from '../../images/brazil.png'
import annoyImg from '../../images/annoy.png'
import ukImg from '../../images/uk.png'

interface IControlledAppBar {
  /** Título da página */
  title: string
  /** Adiciona um elemento customizado */
  includeCustomElem?: JSX.Element
  /** Módulo do Community para as contribuições */
  communityModule?: CommunityModule
  /** Workspace atual do usuário */
  workspace: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      fontSize: '1em',
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },
  }),
)

const ControlledAppBar: React.FC<{ title: string }> = ({ title, children }) => {
  const [, setLocation] = useLocation()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setLocation('/')
  }

  return (
    <AppBarPure title={title} onImageClick={handleClick}>
      {children}
    </AppBarPure>
  )
}

export default ControlledAppBar

/**
 * App Bar com indicador de câmera e outros componentes
 */
export const StandardAppBar: React.FC<IControlledAppBar> = ({
  title,
  includeCustomElem,
  communityModule,
  workspace,
}) => {
  const [countryImg, setCountryImg] = useState<string>('')

  const classes = useStyles()

  useEffect(() => {
    setCountryImg(
      workspace == 'HT-Annoy' ? annoyImg : workspace == 'HT-ASL' ? usaImg : workspace == 'HT-BSL' ? ukImg : brazilImg,
    )
  }, [workspace])

  return (
    <ControlledAppBar title={title}>
      <Grid container alignItems="center" style={{ width: 'auto', marginRight: '1%' }}>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingRight: '2px',
          }}
        >
          <Typography className={classes.title}>{workspace}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '2px',
          }}
        >
          <img
            alt={workspace}
            src={countryImg}
            style={{
              maxHeight: '30px',
              width: 'auto',
              height: 'auto',
              display: 'flex',
            }}
          />
        </Grid>
      </Grid>

      {communityModule && communityModule !== 'hTube' && (
        <Box>
          <CardContribution communityModule={communityModule} />
        </Box>
      )}

      <Box style={{ marginRight: '0.5%' }}>
        <ContributionsLogger includeCustomElem={includeCustomElem} />
      </Box>

      {includeCustomElem ? (
        <>
          <Hidden mdDown implementation="css">
            {includeCustomElem}
          </Hidden>
          <Hidden mdUp implementation="css">
            <UserMenu />
          </Hidden>
        </>
      ) : (
        <UserMenu />
      )}
    </ControlledAppBar>
  )
}
