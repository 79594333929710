import { IManuallyTranslationTarget } from 'collections'
import Chip from '@material-ui/core/Chip'
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { red } from '@material-ui/core/colors'
import { routes } from '../../../../community'

interface IDraggableChipProps {
  item: IManuallyTranslationTarget
  verified: boolean
  onDeleteChip: () => void
  onGetSignByClick: (item: IManuallyTranslationTarget) => void
}

const DraggableChip: React.FC<IDraggableChipProps> = ({ item, verified, onDeleteChip, onGetSignByClick }) => {
  const id = item.draggableId || ''
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
    transition: {
      duration: 50, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    transition,
  }

  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    // Somente double click
    if (ev.detail === 2) {
      // Leva item para pesquisa
      onGetSignByClick(item)
    }
    if (ev.detail === 1) {
      // Leva item para pesquisa
      if (item.waitingRevision) {
        const url = routes.reviewAnimation.replace(':signId', item.signId)
        window.open(url, '_blank')?.focus()
      }
    }
  }

  return (
    <div key={item.draggableId} style={style} {...attributes} {...listeners} ref={verified ? null : setNodeRef}>
      <Chip
        label={item.word}
        onClick={handleClick}
        style={{
          backgroundColor: item.waitingRevision
            ? '#09A78F'
            : !item.animationExported
            ? red['500']
            : item.type === 'fingerspell'
            ? '#d46d2a'
            : '#2296f3',
          color: 'white',
          minWidth: '120px',
        }}
        onDelete={() => {
          onDeleteChip()
        }}
      />
    </div>
  )
}

export default DraggableChip
