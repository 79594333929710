import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useDatabase, useFirestore, useStorage } from 'reactfire'
import {
  CollectionReferenceClient,
  DocumentData,
  DocumentReferenceGeneric,
  Query,
  saveSegmentSignOnDemand,
  updateVideoFlags,
  IGlobalAttr,
  IVideoFile,
  IVideoMidia,
  VideoId,
  StatusSignOnDemand,
  ErrorSignOnDemand,
  ISignOnDemand,
  getPathVideoFile,
  QueryDocumentSnapshot,
  Timestamp,
  createVideo,
  getCollectionReference,
  getDocumentReference,
  updateFirestoreDocument,
  WorkspaceId,
  TCorpusGroup,
  ISign,
  IProcessVideoInput,
  getData,
} from 'collections'
import useCamera from './useCamera'
import useContributionsLogger from './useContributionsLogger'
import useSnackbar from './useSnackbar'
import logger from '../logger'
import { uploadToStorage } from '../utils'
import * as firebase from 'firebase/compat/app'
import { useGlobal } from 'reactn'
import useFirebaseAnalytics from './useFirebaseAnalytics'
import useRecordingDuration from '../../hooks/useRecordingDuration'
import useCountdown from '../../hooks/useCountdown'
import useIntl from '../../hooks/useIntl'
import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import { useLocation } from 'wouter'
import { routes } from '../../community'
import useTimelineRegions from '../../hooks/useTimelineRegions'
import { collection, doc, documentId, limit, where, getDocs, query, serverTimestamp } from '@firebase/firestore'
import { addContribSegmentFunction, processVideoOnCallFunction } from '../firebase'
import { ref, remove } from '@firebase/database'

interface ISignSentenceState {
  numberOfVideosToRecord: number
  recordedVideos: IVideoFile[]
}

interface IProcessVideo {
  signData: ISign

  signOnDemandId: string
}

const initialState: ISignSentenceState = {
  numberOfVideosToRecord: 1,
  recordedVideos: [],
}

interface IUpdateStateFunctions {
  recordVideo: () => Promise<void>
  removeVideo: (videoId: number) => void
  createContribution: (
    sentence: string,
    signOnDemandRef: DocumentReferenceGeneric,
    error_selected: ErrorSignOnDemand,
    sentenceOrigin: string,
    corpusGroup: TCorpusGroup,
    sentenceCategory: string[] | null,
    clientId: string,
  ) => Promise<void>
  clearRecordedVideos: () => void
  saveSegment: (signOnDemand: ISignOnDemand, signOnDemandRef: DocumentReferenceGeneric) => void
  refreshNextSignOnDemandSegment: () => void
  refreshNextSignOnDemandRecordVideo: () => void
}

const initialFunctions: IUpdateStateFunctions = {
  recordVideo: () => Promise.resolve(),
  removeVideo: () => null,
  createContribution: () => Promise.resolve(),
  clearRecordedVideos: () => null,
  saveSegment: () => null,
  refreshNextSignOnDemandSegment: () => null,
  refreshNextSignOnDemandRecordVideo: () => null,
}

const { log, error } = logger('useModuleVideoOnDemand.tsx')

type Context = [ISignSentenceState, IUpdateStateFunctions]

const GlobalContext = createContext<Context>([initialState, initialFunctions])

export const ProviderModuleVideoOnDemand: React.FC = ({ children }) => {
  const countdownFns = useCountdown()[1]
  const firestore = useFirestore()
  const cameraFn = useCamera()[1]
  const [videoDuration] = useRecordingDuration()
  // States
  const [state, setState] = useState(initialState)
  const showSnackbar = useSnackbar()
  const intl = useIntl()
  const contributionsLoggerFns = useContributionsLogger()[1]
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const storage = useStorage() as firebase.default.storage.Storage
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const { videoCreatedEvent } = useFirebaseAnalytics()
  const setLocation = useLocation()[1]
  const [timelineRegions] = useTimelineRegions()
  const [fsVideoDocData] = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')
  const [fsVideoRef] = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')
  const db = useDatabase()

  /**
   * Inicia um contador de n segundos
   * Grava um novo vídeo e adiciona na fila de vídeos gravados
   */
  const recordVideo = useCallback(async () => {
    // Realiza a contagem de n segundos
    await countdownFns.run()

    const midiaId = doc(collection(firestore, 'tempRandom')).id

    const videoFile = await cameraFn.recordVideo(videoDuration, midiaId)

    // Adiciona o novo vídeo na lista de gravados
    setState((prev) => ({
      ...prev,
      recordedVideos: [...prev.recordedVideos, videoFile],
    }))
  }, [cameraFn, countdownFns, firestore, videoDuration])

  const clearRecordedVideos = useCallback(() => {
    setState((prev) => ({
      ...prev,
      recordedVideos: [],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState])

  // Quando o tempo mudar limpamos a lista de videos gravados
  useEffect(() => {
    clearRecordedVideos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDuration])

  /**
   * Cria e prepara o documento do firestore.
   */
  const createFirestoreData = useCallback(
    async (
      videoId: VideoId,
      sentence: string,
      sentenceOrigin: string,
      corpusGroup: TCorpusGroup,
      sentenceCategory: string[] | null,
      clientId: string,
    ) => {
      if (!sentence) {
        throw intl.get('messages.sentenceUndefined')
      }
      const midia: IVideoMidia[] = state.recordedVideos.map((video, index) => ({
        // Todos os vídeos gravados no community são frontais
        direction: 'front',
        fileName: video.id,
        // O primeiro vídeo gravado no community é sempre o principal
        isMain: index === 0,
      }))
      log(`Criando vídeo...`)
      // Converte a duração do video em segundos para frames
      const duration = Math.round((videoDuration / 1000) * appConfig.frameRate)
      await createVideo(fsUser, fsUserRef, videoId, {
        duration,
        sentence: sentence,
        midia,
        sentenceOrigin: sentenceOrigin.toUpperCase(),
        createdOnDemand: true,
        corpusGroup,
        sentenceCategory,
        clientId,
        isExternal: fsUser.isExternal || false,
      })

      videoCreatedEvent(videoId, duration)

      log(`${videoId} Criado`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.recordedVideos, videoDuration, appConfig.frameRate, fsUser, fsUserRef, videoCreatedEvent],
  )

  /**
   * Remove um vídeo da lista de vídeos gravados
   * @param videoId Id do vídeo que será removido do array
   */
  const removeVideo = useCallback(
    async (videoId: number) => {
      // Filtra os vídeos removendo o video atual
      const recordedVideos = state.recordedVideos.filter((_, index) => index !== videoId)
      // Seta o novo array
      setState((prev) => ({
        ...prev,
        recordedVideos,
      }))
    },

    [state.recordedVideos],
  )

  const refreshNextSignOnDemandSegment = useCallback(async () => {
    const segmentsRef = getCollectionReference(fsUser.workspace, 'signsOnDemand')
    const randomId = doc(segmentsRef as CollectionReferenceClient)
    const result = await Promise.all([
      await getDocs(
        query(
          segmentsRef as Query<DocumentData>,
          where('status', '==', StatusSignOnDemand.recordedVideo),
          where(documentId(), '>', randomId),
          limit(5),
        ),
      ),

      await getDocs(
        query(
          segmentsRef as Query<DocumentData>,
          where('status', '==', StatusSignOnDemand.recordedVideo),
          where(documentId(), '<', randomId),
          limit(5),
        ),
      ),
    ])

    const docs = result.reduce(
      (prev: QueryDocumentSnapshot[], queryResult) => [
        ...prev,
        ...queryResult.docs.filter((el) => !el.data().isSegmenting?.value),
      ],
      [],
    )
    if (docs.length) {
      const index = Math.floor(Math.random() * docs.length)
      const signOnDemandId = docs[index].id
      return setLocation(routes.signOnDemandSegmentVideo.replace(':signOnDemandId', signOnDemandId))
    } else {
      return setLocation(routes.signOnDemand)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshNextSignOnDemandRecordVideo = useCallback(async () => {
    const segmentsRef = getCollectionReference(fsUser.workspace, 'signsOnDemand')
    const randomId = doc(segmentsRef as CollectionReferenceClient)
    const result = await Promise.all([
      await getDocs(
        query(
          segmentsRef as Query<DocumentData>,
          where('status', '==', StatusSignOnDemand.pending),
          where(documentId(), '>', randomId),
          limit(5),
        ),
      ),

      await getDocs(
        query(
          segmentsRef as Query<DocumentData>,
          where('status', '==', StatusSignOnDemand.pending),
          where(documentId(), '<', randomId),
          limit(5),
        ),
      ),
    ])

    const docs = result.reduce(
      (prev: QueryDocumentSnapshot[], queryResult) => [
        ...prev,
        ...queryResult.docs.filter((el) => !el.data().isRecording?.value),
      ],
      [],
    )
    if (docs.length) {
      const index = Math.floor(Math.random() * docs.length)
      const signOnDemandId = docs[index].id
      return setLocation(routes.signOnDemandRecordVideo.replace(':signOnDemandId', signOnDemandId))
    } else {
      return setLocation(routes.signOnDemand)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Cria uma nova contribuição e envia os vídeos para o storage
   */
  const createContribution = async (
    sentence: string,
    signOnDemandRef: DocumentReferenceGeneric,
    error_selected: ErrorSignOnDemand,
    sentenceOrigin: string,
    corpusGroup: TCorpusGroup,
    sentenceCategory: string[] | null,
    clientId: string,
  ) => {
    // 1) Validação de dados
    const hasAllBlobs = state.recordedVideos.every((video) => !!video.blob)
    if (!hasAllBlobs) {
      error('Um ou mais vídeos não possui(em) blob(s)')
      showSnackbar(intl.get('messages.videoNotFound'), { variant: 'error' })
      return
    }

    if (!sentence) {
      error('Não existe frase ainda')
      showSnackbar(intl.get('messages.sentenceNotFound'), { variant: 'error' })
      return
    }

    if (state.recordedVideos.length < state.numberOfVideosToRecord) {
      error('Um dos VideoFile não existe!')
      showSnackbar(intl.get('messages.missingVideo'), { variant: 'error' })
      return
    }

    // 2) Criamos um id para o video isso muito importante para rodar tudo em paralelo
    const videoId = doc(collection(firestore, 'tempRandom')).id

    const identifier = `${sentence}##${+new Date()}`
    contributionsLoggerFns.update(identifier, 0, 'signSentence')

    // 3) Criando a coleção no firestore
    try {
      createFirestoreData(videoId, sentence, sentenceOrigin, corpusGroup, sentenceCategory, clientId)
      // Atualiza status
      await refreshNextSignOnDemandRecordVideo()
      await updateFirestoreDocument(signOnDemandRef, {
        isRecording: {
          lastUpdate: serverTimestamp() as Timestamp,
          user: fsUserRef,
          value: false,
        },
        status: StatusSignOnDemand.recordedVideo,
        videoRef: getDocumentReference(fsUser.workspace, 'videos', videoId),
        lastUpdate: serverTimestamp() as Timestamp,
        error: error_selected === ErrorSignOnDemand.noError ? null : error_selected,
      })
      /** Limpa a lista de vídeos */
      clearRecordedVideos()
    } catch (e) {
      error(e)
      showSnackbar(intl.get('messages.firestoreVideoCreationFailed'), {
        variant: 'error',
      })
      return
    }

    // 4) Upload de vídeos
    try {
      showSnackbar(`"${sentence!.substring(0, 10)}..." ${intl.get('messages.uploadingVideo')}`, {
        variant: 'info',
      })
      await Promise.all(
        state.recordedVideos.map((video) => {
          const path = getPathVideoFile(fsUser.workspace.id, videoId)
          return uploadToStorage(
            storage,
            path,
            video.blob!,
            'video/webm;codecs=h264',
            identifier,
            (identifier, value) => contributionsLoggerFns.update(identifier, value, 'signOnDemand'),
          )
        }),
      )

      log(`Upload Completo: ${sentence}`)

      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)

      await updateVideoFlags(videoRef, {
        isMidiaUploaded: true,
        isMidiaUploading: {
          value: false,
          lastUpdate: serverTimestamp() as Timestamp,
        },
      })

      showSnackbar(`"${sentence!.substring(0, 10)}..." ${intl.get('messages.videoUploaded')}`, {
        variant: 'success',
      })
    } catch (e) {
      error(e)
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
      await updateVideoFlags(videoRef, {
        isMidiaUploading: {
          value: true,
          lastUpdate: serverTimestamp() as Timestamp,
        },
      })
      showSnackbar(`"${sentence!.substring(0, 10)}..." ${intl.get('messages.videoUploadFail')}`, {
        variant: 'error',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  /**
   * Salva o segmento no banco de dados
   */
  const saveSegment = useCallback(
    async (signOnDemand: ISignOnDemand, signOnDemandRef: DocumentReferenceGeneric) => {
      if (!fsVideoRef || !fsVideoDocData || timelineRegions.length === 0) {
        showSnackbar(intl.get('hooks.useModuleSegmentVideo.timelineIsEmpty'), {
          variant: 'error',
        })
        return
      }

      showSnackbar(intl.get('hooks.useModuleSegmentVideo.savingRegions'), {
        variant: 'info',
      })
      try {
        const result = await saveSegmentSignOnDemand(
          firestore,
          signOnDemand,
          signOnDemandRef,
          fsVideoRef,
          timelineRegions,
          fsUser,
          fsUserRef,
        )
        await addContribSegmentFunction({
          videoId: fsVideoRef.id,
          userId: fsUserRef.id,
          workspaceId: fsUser.workspace.id as WorkspaceId,
          numberOfSegments: 1,
          segmentsToProcess: 0,
        })
        if (result && result.signData) {
          await processLinkedSigns({
            signData: result.signData,
            signOnDemandId: signOnDemandRef.id,
          })
        }

        showSnackbar(intl.get('hooks.useModuleSegmentVideo.videosSaved'), {
          variant: 'success',
        })
        refreshNextSignOnDemandSegment()
      } catch (e) {
        error(e)
        console.log(e)
        showSnackbar(intl.get('hooks.useModuleSegmentVideo.failSaving'), {
          variant: 'error',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fsUser.workspace.id, fsUserRef.id, fsVideoDocData, fsVideoRef, showSnackbar, timelineRegions.length],
  )

  const updateStateFns: IUpdateStateFunctions = {
    createContribution,
    recordVideo,
    removeVideo,
    clearRecordedVideos,
    saveSegment,
    refreshNextSignOnDemandSegment,
    refreshNextSignOnDemandRecordVideo,
  }

  const fetchRequestedSigns = async (signOnDemandId: string) => {
    const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${
      fsUser.signLanguageId
    }/${'requestedSigns'}/${signOnDemandId}`
    try {
      const sfDocRef = ref(db, referenceString)
      const values = await getData(sfDocRef)
      return values.val() as string[]
    } catch (e) {
      console.log(e)
    }
  }

  const deleteRequestedSigns = async (signOnDemandId: string) => {
    const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${
      fsUser.signLanguageId
    }/${'requestedSigns'}/${signOnDemandId}`
    try {
      const sfDocRef = ref(db, referenceString)
      await remove(sfDocRef)
    } catch (e) {
      console.log(e)
    }
  }

  const processLinkedSigns = async (data: IProcessVideo) => {
    const { signData, signOnDemandId } = data

    // Resgata a lista dos segmentos vinculados ao sinal sob demanda
    const requestedSigns: string[] = (await fetchRequestedSigns(signOnDemandId)) || []
    const processVotesVideo: IProcessVideoInput[] = []
    requestedSigns.forEach((segmentId) => {
      const processVideoData: IProcessVideoInput = {
        segmentId: segmentId,
        score: 2,
        votes: { [signData.primarySegment?.id || '']: 2 },
      }
      processVotesVideo.push(processVideoData)
    })
    if (processVotesVideo.length) {
      await processVideoOnCallFunction({
        userId: fsUserRef.id,
        workspaceId: fsUser.workspace.id as WorkspaceId,
        processVideoData: processVotesVideo,
      })
    }
    await deleteRequestedSigns(signOnDemandId)
  }

  return <GlobalContext.Provider value={[state, updateStateFns]}>{children}</GlobalContext.Provider>
}

export default (): Context => useContext(GlobalContext)
