import { useState } from 'react'

import { ITimelineCursorState, ITimelineCursorFns, TimelineCursorHookTuple } from 'collections'

/**
 * Controla o estado global de traducao
 */
const useTimelineCursor = (): TimelineCursorHookTuple => {
  const [timelineCursorPosition, setTimelineCursorPosition] = useState(0)

  const state: ITimelineCursorState = {
    position: timelineCursorPosition || 0,
  }

  const fns: ITimelineCursorFns = {
    setPosition: setTimelineCursorPosition,
  }

  return [state, fns]
}

export default useTimelineCursor
