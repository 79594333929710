import { IUser, UserId } from 'collections'
import { useUser } from 'reactfire'
import { useFirestoreData } from './useFirestoreData'

export const useFsUserDocData = (userId?: UserId): IUser => {
  const fbUser = useUser()

  const data = useFirestoreData(`users/${userId || fbUser.data?.uid}`).data as IUser

  return data
}
