import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty'
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import { MoreVert } from '@material-ui/icons'

interface ICanvasWrapperProps {
  visible: boolean
}

const CanvasWrapper = styled.div<ICanvasWrapperProps>`
  position: ${({ visible }: ICanvasWrapperProps) => (visible ? 'absolute' : 'none')};
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`
interface IWrapperStyleProps {
  fullHeight?: boolean
}
/**
 * Caixa principal que deixa todos os componentes em 16x9
 */
const Wrapper = styled.div`
  position: relative;
  background-color: #000000;
  width: 100%;
  padding-top: 56.25%;
  ${({ fullHeight }: IWrapperStyleProps) =>
    css`
      height: ${fullHeight ? '100%' : '0'};
    `}
`
const StyledLoaderWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const StyledLoader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

interface IFramePlayerProps {
  /**
   * Elementos filhos (geralmente um canvas)
   */
  children: React.ReactNode
  /**
   * Determina se está sendo reproduzido
   */
  isPlaying: boolean
  /**
   * Determina se está sendo carregado
   */
  isReady: boolean
  /**
   * Callback para botão de play/pause clicado
   */
  onMidiaButtonClick: (ev: React.MouseEvent<HTMLElement>) => void
  /**
   * Callback alteração do checkbox mirror
   */
  setMirror?: (checked: boolean) => void
  /**
   * Mirror
   */
  mirror?: boolean
  /**
   * Descrição do checkbox Mirror
   */
  mirrorText?: string
  /**
   * Habilita, desabilita mirror
   */
  mirrorEnabled?: boolean
  /**
   * Evento report
   */
  onReport?: () => void
  /**
   * Evento alteração de velocidade
   */
  onChangeSpeedPlayer?: (frameRate: number) => void
  /**
   * Velocidade do player
   */
  playerTimeFrame?: number
  /** Habilita o hover do player */
  hoverable?: boolean
  /** Altura 100% */
  fullHeight?: boolean

  /** Menu component */
  menuProps?: {
    menuComponent: JSX.Element
    handleClickMore: (e: React.MouseEvent<HTMLButtonElement>) => void
  } | null
  /** Condicional para verificar se o frame player deve exibir os controles */
  showPlayerControls?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      marginLeft: '10px',
    },
    icons: {
      fill: theme.palette.type === 'light' ? theme.palette.grey[900] : '#FFF',
    },
  }),
)

/**
 * Componente puro do FramePlayer, um canvas deve ser passado como filho
 * para o pleno funcionamento.
 */
const FramePlayerPure: FC<IFramePlayerProps> = ({
  children,
  isPlaying,
  isReady,
  onMidiaButtonClick,
  setMirror,
  mirror,
  mirrorText,
  mirrorEnabled,
  onReport,
  onChangeSpeedPlayer,
  playerTimeFrame,
  hoverable,
  fullHeight,
  menuProps,
  showPlayerControls = true,
}) => {
  const classes = useStyles()

  return (
    <Wrapper fullHeight={fullHeight}>
      <CanvasWrapper visible={isReady}>{children}</CanvasWrapper>
      {!isReady && (
        <StyledLoaderWrapper>
          <StyledLoader>
            <CircularProgress />
          </StyledLoader>
        </StyledLoaderWrapper>
      )}

      <Box style={{ position: 'absolute', bottom: '3%', left: '3%' }}>
        {mirrorEnabled && (
          <Box style={{ marginBottom: '5px' }}>
            <Tooltip title={mirrorText || ''}>
              <Fab
                size="small"
                color="primary"
                aria-label="player"
                onClick={() => {
                  if (setMirror) setMirror(!mirror)
                }}
              >
                <ThreeSixtyIcon style={{ color: mirror ? '#999' : '#FFF' }} />
              </Fab>
            </Tooltip>
          </Box>
        )}
        {!hoverable && showPlayerControls && (
          <Fab size="small" color="primary" aria-label="player" onClick={onMidiaButtonClick}>
            {isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
          </Fab>
        )}
      </Box>

      {onReport && (
        <Box style={{ position: 'absolute', top: '3%', left: '3%' }}>
          <Tooltip title="Report">
            <Fab size="small" color="secondary" aria-label="player" onClick={onReport}>
              <ReportOutlinedIcon />
            </Fab>
          </Tooltip>
        </Box>
      )}
      {!hoverable && showPlayerControls && (
        <Box style={{ position: 'absolute', bottom: '3%', right: '3%' }}>
          <Select
            className="notranslate"
            defaultValue="sign"
            id="grouped-select"
            value={playerTimeFrame}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined
                value: unknown
              }>,
            ) => {
              if (onChangeSpeedPlayer) onChangeSpeedPlayer(event.target.value as number)
            }}
          >
            <MenuItem className={`${classes.menuItem} notranslate`} value={100}>
              -2x
            </MenuItem>
            <MenuItem className={`${classes.menuItem} notranslate`} value={60}>
              -1x
            </MenuItem>
            <MenuItem className={`${classes.menuItem} notranslate`} value={30}>
              0x
            </MenuItem>
            <MenuItem className={`${classes.menuItem} notranslate`} value={10}>
              +1x
            </MenuItem>
            <MenuItem className={`${classes.menuItem} notranslate`} value={5}>
              +2x
            </MenuItem>
          </Select>
        </Box>
      )}
      {menuProps && (
        <Box style={{ position: 'absolute', top: '3%', right: '3%' }}>
          <IconButton size="small" color="primary" onClick={menuProps.handleClickMore}>
            <MoreVert />
          </IconButton>

          {menuProps.menuComponent}
        </Box>
      )}
    </Wrapper>
  )
}

export default FramePlayerPure
