import React, { useEffect, useRef, useState } from 'react'

import { Dialog, Fab, Grid, IconButton, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import CloseIcon from '@material-ui/icons/Close'
import RecIcon from '@material-ui/icons/VideoCall'
import styled from 'styled-components'
import TextHeader from '../TextHeader'
import useIntl from '../../hooks/useIntl'
import { useSegmentation } from '../../hooks/useSegmentation'
import { IKeypoints, IReturnSignRecognition } from '../../types/keypoints'
import MediapipeCamera from '../MediapipeCamera'
import { useSignRecognition } from '../../hooks/useSignRecognition'

interface IRecordDialog {
  open: boolean
  isLoading: () => void
  onClose: () => void
  searchSigns: (signs: string[]) => void
}

const FabWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Absolute = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: black;
  z-index: 0;
`

const AbsoluteRight = styled.div`
  position: absolute;
  height: 100%;
  right: 25px;
  top: 10px;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  display: flex;
  flex-direction: column;
`

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const recIcon = <RecIcon style={{ marginRight: 10 }} />

const RecordDialog: React.FC<IRecordDialog> = ({ open, onClose, isLoading, searchSigns }) => {
  const intl = useIntl()
  const [onRecording, setOnRecording] = useState<boolean>(false)
  const buttonText = intl.get('pages.signSentence.main.recordButton')
  const [cameraEnabled, setCameraEnabled] = useState(false)

  const kpts = useRef<IKeypoints[]>([])
  const signs = useRef<string[]>([])

  const onResultsSignRecognition = (predictResult?: IReturnSignRecognition[], onFinalize?: () => void) => {
    const signsIds: string[] = []
    predictResult?.forEach((el) => {
      el.classes.forEach((classItem) => {
        if (!signsIds.includes(classItem.id) && classItem.id !== 'fingerspell') {
          signsIds.push(classItem.id)
        }
      })
    })
    searchSigns(signsIds)
    if (onFinalize) {
      onFinalize()
    }
  }

  const { predictSignRecognition } = useSignRecognition({
    onResults: onResultsSignRecognition,
  })

  const onFinalizePredict = () => {
    kpts.current = []
  }

  const { predictSegmentation } = useSegmentation({
    onResults: (kpts, segments) => {
      predictSignRecognition(kpts, segments, onFinalizePredict)
    },
  })

  const recordDuration = 6000

  useEffect(() => {
    if (open == true) {
      // Quando o componente for exibido, iremos ligar a camera
      setCameraEnabled(true)
    } else {
      setCameraEnabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleStartRecord = async () => {
    kpts.current = []
    setOnRecording(true)
  }

  useEffect(() => {
    if (onRecording) {
      setTimeout(() => {
        onClose()
        isLoading()
        setOnRecording(false)
        const call = async () => {
          await handlePredict()
        }
        setTimeout(() => {
          call()
        }, 500)
      }, recordDuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRecording])

  const handlePredict = async () => {
    if (kpts.current.length > 0) {
      predictSegmentation(kpts.current)
    }
  }

  const onResultsMediapipe = (result: IKeypoints) => {
    const { leftHand, pose, rightHand, face } = result
    if (onRecording) {
      if (!leftHand && !pose && !rightHand) return

      kpts.current.push({
        leftHand,
        pose,
        rightHand,
        face,
      })
    }

    if (!onRecording) {
      signs.current = []
    }
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Grid
        container
        item
        xs
        direction="column"
        style={{ zIndex: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <TextHeader communityModule={'hTube'} text={intl.get('pages.hTube.sign.others.recordTitle')} />
        <FabWrapper>
          <Fab variant="extended" size="medium" color={'primary'} onClick={handleStartRecord} disabled={onRecording}>
            {recIcon}
            {buttonText}
          </Fab>
        </FabWrapper>
      </Grid>
      <Absolute>
        <MediapipeCamera cameraEnabled={cameraEnabled} showKeypoints={true} onFrame={onResultsMediapipe} />
      </Absolute>
      <AbsoluteRight>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </AbsoluteRight>
    </Dialog>
  )
}

export default RecordDialog
