import React, { useRef, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Fab,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@material-ui/core'
import Layout from '../../../components/Layout'
import RecIcon from '@material-ui/icons/VideoCall'
import FinishIcon from '@material-ui/icons/Check'
import useIntl from '../../../hooks/useIntl'
import {
  ISignOnDemand,
  StatusSignOnDemand,
  ErrorSignOnDemand,
  serverTimestamp,
  ISegmentView,
  getFirestoreDocument,
  getPathFramesJson,
  DocumentSnapshot,
  IVideo,
} from 'collections'
import CameraStream from '../../SignSentence/CameraStream'
import TextHeader from '../../../components/TextHeader'
import CardMessage from '../../../components/CardMessage'
import VideoPreview from '../../SignSentence/Main/VideoPreview'
import SettingsIcon from '@material-ui/icons/Settings'
import styled, { keyframes } from 'styled-components'
import SettingsMenu from '../../SignSentence/SettingsMenu'
import LockModuleDialog from '../../../components/LockModuleDialog'
import useCountdown from '../../../hooks/useCountdown'
import useJobMeter from '../../../services/hooks/useJobMeter'
import useLockModules from '../../../hooks/useLockModules'
import useSnackbar from '../../../services/hooks/useSnackbar'
import useCamera from '../../../services/hooks/useCamera'
import useRecordingDuration from '../../../hooks/useRecordingDuration'
import useModuleSignOnDemand from '../../../services/hooks/useModuleSignOnDemand'
import { useLocation, useRoute } from 'wouter'
import { routes } from '../../../community'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Preloader from '../../../components/Preloader'
import { useFirestoreData } from '../../../hooks/useFirestoreData'
import { Timestamp } from '@firebase/firestore'
import { getDocumentReference, updateFirestoreDocument } from 'collections'
import HoverablePlayer from '../../HTube/HoverablePlayer'
import { getDownloadURL, ref as refStorage } from 'firebase/storage'
import { useStorage } from 'reactfire'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'

const FabWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const animation = keyframes`
  from {  width: 0%; }
  to { width: 100%; }
`

interface ITimedProgressProps {
  speed: number
}

const TimedProgress = styled.div<ITimedProgressProps>`
  height: 5px;
  background-color: #eee;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0%;
  z-index: 2;
  &&:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: red;
    animation: ${animation} ${(p) => p.speed}ms linear infinite;
  }
`

const Absolute = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  background: black;
  z-index: 0;
`
const AbsoluteRight = styled.div`
  position: absolute;
  height: 100%;
  right: 10px;
  top: 20%;
  /* justify-content: flex-start; */
  z-index: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    align-items: center;
    justify-content: center;
    top: 0;
  }
`

const AbsoluteLeft = styled.div`
  position: absolute;
  height: 100%;
  left: 10px;
  top: 20%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  @media (min-width: 960px) {
    justify-content: flex-end;
    top: 0;
  }
`

const BackButton = styled(IconButton)`
  && {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-left: 10px;
    margin-top: 5px;
    z-index: 2;
    position: absolute;
    left: 0px;
    &:first-child {
      color: #c4c4c4;
    }
  }
`

const TextHeaderBox = styled(Box)`
  @media (max-width: 600px) {
    margin: 0 auto;
    max-width: 70%;
  }
`

const finishIcon = <FinishIcon style={{ marginRight: 10 }} />
const recIcon = <RecIcon style={{ marginRight: 10 }} />

interface ISignOnDemandRecordVideoProps {
  path: string
}

const SignOnDemandRecordVideo: React.FC<ISignOnDemandRecordVideoProps> = () => {
  const intl = useIntl()
  const hasPermissionOnModule = useCheckHasPermission('signOnDemand')
  const [
    { recordedVideos, numberOfVideosToRecord },
    { createContribution, recordVideo, removeVideo, clearRecordedVideos, refreshNextSignOnDemandRecordVideo },
  ] = useModuleSignOnDemand()
  const [mainButtonDisabled, setMainDisabled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [countdownState] = useCountdown()
  const camOverlay = countdownState.countdownStep !== 0 ? countdownState.countdownStep.toString() : ''
  const [camState, camFn] = useCamera()
  const canUpload = recordedVideos.length === numberOfVideosToRecord
  const buttonText = canUpload
    ? intl.get('pages.signSentence.main.finishButton')
    : intl.get('pages.signSentence.main.recordButton')

  const { startJobMeter, endJobMeter, resetJobMeter } = useJobMeter('signOnDemand')
  const [, routeParams] = useRoute(routes.signOnDemandRecordVideo)
  const signOnDemandId = routeParams?.signOnDemandId
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()

  const signOnDemandRef = getDocumentReference(fsUser?.workspace, 'signsOnDemand', signOnDemandId || '')
  const signOnDemand = useFirestoreData(signOnDemandRef.path).data as ISignOnDemand

  const { lockModule, open, toggleOpen } = useLockModules()
  const showSnackbar = useSnackbar()
  const buttonSettingsRef = useRef<HTMLButtonElement>(null)
  const [selectedError, setSelectedError] = useState<ErrorSignOnDemand>(ErrorSignOnDemand.noError)
  const [videoDuration] = useRecordingDuration()
  const setLocation = useLocation()[1]
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [cameraDevices, setCameraDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo | null>(null)
  const [segmentToView, setSegmentToView] = useState<ISegmentView | null>(null)

  const [infoPopoverEl, setInfoPopoverEl] = React.useState<HTMLButtonElement | null>(null)
  const [openedInfo, setOpenedInfo] = React.useState(false)
  const [refPopoverEl, setRefPopoverEl] = React.useState<HTMLButtonElement | null>(null)
  const [openedRef, setOpenedRef] = React.useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const storage = useStorage()

  const handleChangeCamera = (value: string) => {
    const _selectedDevice = cameraDevices.find((el) => el.deviceId == value) || null
    setSelectedCamera(_selectedDevice)
    const resultString = localStorage.getItem('selectedCamera')

    const device = resultString ? JSON.parse(resultString || '{}') : null
    localStorage.setItem('selectedCamera', JSON.stringify(_selectedDevice))
    if (device.deviceId != value) {
      camFn.closeStream()
      camFn.getStream()
    }
  }

  const getVideoUrl = async (videoId: string, duplicateOf: string) => {
    if (!videoId) return ''
    const urlString = getPathFramesJson(fsUser.workspace.id, videoId, duplicateOf)
    const url = await getDownloadURL(refStorage(storage, urlString))
    return url
  }

  const getSegmentData = async (segmentId?: string) => {
    if (!segmentId) {
      setSegmentToView(null)
      return
    }
    const segmentRef = getDocumentReference(fsUser.workspace, 'segments', segmentId)
    const segmentSnapshot = (await getFirestoreDocument(segmentRef)) as DocumentSnapshot
    if (!segmentSnapshot) {
      setSegmentToView(null)
      return
    }
    const segmentData = segmentSnapshot.data() as ISegmentView
    segmentData.id = segmentSnapshot.id
    const videoSnapshot = await getFirestoreDocument(segmentData.video)
    const videoData = videoSnapshot.data() as IVideo
    segmentData.videoUrl = await getVideoUrl(segmentData.video.id, videoData.duplicateOf || '')
    setSegmentToView(segmentData)
  }

  const handleMainButton = useCallback(async () => {
    setMainDisabled(true)
    // Verifica se todos os vídos ja foram gravados
    if (recordedVideos.length === numberOfVideosToRecord) {
      setIsLoading(true)
      endJobMeter()
      const sentenceContribution = (signOnDemand?.portuguese || [''])[0] || signOnDemand?.glosa || ''
      await createContribution(
        sentenceContribution || '',
        signOnDemandRef,
        selectedError,
        (signOnDemand?.sentenceOrigin || 'DEMAND').toUpperCase(),
        signOnDemand?.corpusGroup || 'TRAIN',
        signOnDemand?.sentenceCategory || ['undefined'],
        signOnDemand?.clientId || 'HT',
      )
      lockModule()

      // Faltam vídeos para gravar
    } else {
      try {
        startJobMeter()

        const numberOfMissingVideos = numberOfVideosToRecord - recordedVideos.length
        // Grava os vídeos restantes
        for (let i = 0; i < numberOfMissingVideos; i++) {
          await recordVideo()
        }
      } catch (err) {
        console.log(err)
        showSnackbar('Falha em alguma gravação!', { variant: 'error' })
      }
    }
    setMainDisabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recordedVideos.length,
    numberOfVideosToRecord,
    endJobMeter,
    signOnDemand?.portuguese,
    signOnDemand?.glosa,
    signOnDemand?.sentenceOrigin,
    signOnDemand?.corpusGroup,
    signOnDemand?.sentenceCategory,
    signOnDemand?.clientId,
    createContribution,
    signOnDemandRef,
    selectedError,
    lockModule,
    startJobMeter,
    recordVideo,
    showSnackbar,
  ])

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  const openInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInfoPopoverEl(event.currentTarget)
    setOpenedInfo((prev) => !prev)
  }

  const openRef = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRefPopoverEl(event.currentTarget)
    setOpenedRef((prev) => !prev)
  }

  useEffect(() => {
    const call = async () => {
      if (!updatingStatus) {
        if (
          signOnDemand?.status !== StatusSignOnDemand.pending &&
          signOnDemand?.status !== StatusSignOnDemand.request &&
          signOnDemand?.videoRef
        ) {
          setLocation(routes.signOnDemandSegmentVideo.replace(':signOnDemandId', signOnDemandId || ''))
          return
        } else {
          if (!signOnDemand?.isRecording?.value) {
            setUpdatingStatus(true)
            await updateFirestoreDocument(signOnDemandRef, {
              isRecording: {
                lastUpdate: serverTimestamp() as Timestamp,
                user: fsUserRef,
                value: true,
              },
            })
            setUpdatingStatus(false)
          }
        }
        // Limpa a lista de vídeos gravados
        clearRecordedVideos()
        //

        const devices = await camFn.getCameras()
        const resultString = localStorage.getItem('selectedCamera')
        const device = resultString ? JSON.parse(resultString || '{}') : null
        setSelectedCamera(device)
        setCameraDevices(devices)
        if (signOnDemand?.segmentToView) getSegmentData(signOnDemand?.segmentToView.id)

        // Quando o componente for montado, iremos ligar a camera
        camFn.getStream()
        setIsLoading(false)
      }
    }
    call()
    return () => {
      if (signOnDemand?.isRecording?.value && signOnDemand?.isRecording?.user?.id === fsUserRef.id) {
        updateFirestoreDocument(signOnDemandRef, {
          isRecording: {
            lastUpdate: serverTimestamp() as Timestamp,
            user: fsUserRef,
            value: false,
          },
        })
      }
      // Quando for desmontado, desligamos a camera
      camFn.closeStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOnDemandRef.id])

  useEffect(() => {
    // Sempre que a sentença mudar, resetamos o job meter
    resetJobMeter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOnDemand])

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.signOnDemand')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  if (signOnDemand?.isRecording?.value && signOnDemand?.isRecording?.user?.id !== fsUserRef.id) {
    const date = new Date()
    const dif = Math.floor((+date - +signOnDemand?.isRecording?.lastUpdate) / (1000 * 60 * 60))
    //Destrava o vídeo caso tenha mais de 1 hora gravando
    if (dif >= 1) {
      updateFirestoreDocument(signOnDemandRef, {
        isRecording: {
          lastUpdate: serverTimestamp() as Timestamp,
          user: signOnDemand?.isRecording?.user,
          value: false,
        },
      })
    }
    return (
      <Layout
        title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.signSentence')}`}
        requiredModule={'signOnDemand'}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" item xs>
          <Typography variant="subtitle1" gutterBottom style={{ margin: 0 }}>
            {intl.get('pages.signOnDemand.someoneIsRecordingVideo')}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              refreshNextSignOnDemandRecordVideo()
            }}
          >
            {intl.get('messages.searchAgain')}
          </Button>
        </Grid>
      </Layout>
    )
  }

  return (
    <Layout
      title={`${intl.get('modules.signOnDemand')} - ${intl.get('modules.signSentence')}`}
      requiredModule={'signOnDemand'}
    >
      <BackButton aria-label="Return to the list of signs" onClick={() => setLocation('/signOnDemand')}>
        <ArrowBackIcon fontSize="default" />
      </BackButton>
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingVideo')}></Preloader>}
      {!isLoading && (
        <>
          {' '}
          <Grid container item xs direction="column" style={{ zIndex: 1 }}>
            <TextHeaderBox>
              <TextHeader
                showDurationChanger
                communityModule={'signOnDemand'}
                text={(signOnDemand?.portuguese || []).slice(0, 3).join(', ') || signOnDemand?.glosa || ''}
              />
            </TextHeaderBox>
            <Grid item xs container direction="column" style={{ paddingLeft: '10px' }} alignItems="flex-start">
              <Grid>
                {/** Varre todos os vídeos gravados e renderiza na tela */}
                {recordedVideos.map((video, index) => (
                  <VideoPreview key={index} video={video} clear={() => removeVideo(index)} />
                ))}
              </Grid>
            </Grid>
            <FabWrapper>
              <Fab
                variant="extended"
                size="medium"
                color={canUpload ? 'primary' : 'secondary'}
                onClick={handleMainButton}
                disabled={!signOnDemand || mainButtonDisabled}
              >
                {canUpload ? finishIcon : recIcon}
                {buttonText}
              </Fab>
              <Box ml={1}>
                <Fab ref={buttonSettingsRef} variant="extended" size="medium" color="default" onClick={toggleMenu}>
                  <SettingsIcon />
                </Fab>
              </Box>
            </FabWrapper>
          </Grid>
          <Absolute>
            {camState.isRecording && <TimedProgress speed={videoDuration} />}
            <CameraStream
              overlay={camOverlay}
              showTemplate={countdownState.countdownStep !== 0}
              textUnableAccessCamera={intl.get('pages.signSentence.cameraStream.unableAccessCamera')}
              videoRef={videoRef}
            />
          </Absolute>
          <AbsoluteLeft>
            {(signOnDemand?.description || signOnDemand?.searchReference) && (
              <Hidden mdUp implementation="css">
                <Button style={{ textTransform: 'capitalize' }} onClick={openInfo} color="default" variant="contained">
                  Info
                </Button>
                <Popover
                  open={openedInfo}
                  anchorEl={infoPopoverEl}
                  onClose={() => {
                    setInfoPopoverEl(null)
                    setOpenedInfo(false)
                  }}
                  style={{ padding: '2%' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box style={{ margin: '2%' }}>
                    {signOnDemand?.description && (
                      <Box>
                        <Typography>{`${intl.get('messages.description')}: `}</Typography>
                        <Typography>{signOnDemand?.description || ''}</Typography>
                      </Box>
                    )}
                    {signOnDemand?.searchReference && (
                      <Box style={{ marginTop: '10px' }}>
                        <Typography>{`${intl.get('pages.signOnDemand.reference')}: `}</Typography>
                        <Link rel="noreferrer" target="_blank" href={signOnDemand?.searchReference || ''}>
                          {signOnDemand?.searchReference || ''}
                        </Link>
                      </Box>
                    )}
                  </Box>
                </Popover>
              </Hidden>
            )}
            <Hidden mdDown implementation="css">
              {signOnDemand?.description && (
                <Box>
                  <Typography>{`${intl.get('messages.description')}: `}</Typography>
                  <Typography>{signOnDemand?.description || ''}</Typography>
                </Box>
              )}
              {signOnDemand?.searchReference && (
                <Box style={{ marginTop: '10px' }}>
                  <Typography>{`${intl.get('pages.signOnDemand.reference')}: `}</Typography>
                  <Link rel="noreferrer" target="_blank" href={signOnDemand?.searchReference || ''}>
                    {signOnDemand?.searchReference || ''}
                  </Link>
                </Box>
              )}
            </Hidden>
          </AbsoluteLeft>
          <AbsoluteRight>
            {/*<AnimationView
              htaAnimation={signOnDemand?.hta || ''}
              rest={true}
              repeat={true}
              autoPlay={true}
              speedControls={true}
            />*/}
            <Hidden mdUp implementation="css">
              <Button style={{ textTransform: 'capitalize' }} onClick={openRef} color="default" variant="contained">
                {segmentToView ? `Ref` : `Error`}
              </Button>
              <Popover
                open={openedRef}
                anchorEl={refPopoverEl}
                onClose={() => {
                  setRefPopoverEl(null)
                  setOpenedRef(false)
                }}
                style={{ padding: '2%' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Card style={{ width: '300px' }}>
                  {segmentToView && <HoverablePlayer showFullScreenSegment={true} segment={segmentToView} />}
                </Card>
                <Box style={{ width: '250px', margin: '10px 0 10px 5px' }}>
                  <InputLabel>{intl.get(`pages.signOnDemand.error`)}</InputLabel>
                  <Select
                    className="notranslate"
                    fullWidth
                    defaultValue="sign"
                    id="grouped-select"
                    value={selectedError}
                    style={{ margin: '10px 0 10px 5px' }}
                    MenuProps={{
                      style: { zIndex: 2002 },
                    }}
                    onChange={(
                      event: React.ChangeEvent<{
                        name?: string | undefined
                        value: unknown
                      }>,
                    ) => {
                      //@ts-ignore
                      setSelectedError(event.target.value)
                    }}
                  >
                    <MenuItem className={`notranslate`} value={ErrorSignOnDemand.noError}>
                      {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.noError}`)}
                    </MenuItem>
                    <MenuItem className={`notranslate`} value={ErrorSignOnDemand.compositeSign}>
                      {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.compositeSign}`)}
                    </MenuItem>
                    <MenuItem className={`notranslate`} value={ErrorSignOnDemand.outdatedSign}>
                      {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.outdatedSign}`)}
                    </MenuItem>
                    <MenuItem className={`notranslate`} value={ErrorSignOnDemand.animationError}>
                      {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.animationError}`)}
                    </MenuItem>
                    <MenuItem className={`notranslate`} value={ErrorSignOnDemand.unknownSign}>
                      {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.unknownSign}`)}
                    </MenuItem>
                    <MenuItem className={`notranslate`} value={ErrorSignOnDemand.translationError}>
                      {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.translationError}`)}
                    </MenuItem>
                  </Select>
                </Box>
              </Popover>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Card style={{ width: '300px' }}>
                {segmentToView && <HoverablePlayer showFullScreenSegment={true} segment={segmentToView} />}
              </Card>
              <Box style={{ width: '250px', margin: '10px 0 10px 5px' }}>
                <InputLabel>{intl.get(`pages.signOnDemand.error`)}</InputLabel>
                <Select
                  className="notranslate"
                  fullWidth
                  defaultValue="sign"
                  id="grouped-select"
                  value={selectedError}
                  style={{ margin: '10px 0 10px 5px' }}
                  MenuProps={{
                    style: { zIndex: 2002 },
                  }}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string | undefined
                      value: unknown
                    }>,
                  ) => {
                    //@ts-ignore
                    setSelectedError(event.target.value)
                  }}
                >
                  <MenuItem className={`notranslate`} value={ErrorSignOnDemand.noError}>
                    {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.noError}`)}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={ErrorSignOnDemand.compositeSign}>
                    {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.compositeSign}`)}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={ErrorSignOnDemand.outdatedSign}>
                    {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.outdatedSign}`)}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={ErrorSignOnDemand.animationError}>
                    {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.animationError}`)}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={ErrorSignOnDemand.unknownSign}>
                    {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.unknownSign}`)}
                  </MenuItem>
                  <MenuItem className={`notranslate`} value={ErrorSignOnDemand.translationError}>
                    {intl.get(`pages.signOnDemand.errors.${ErrorSignOnDemand.translationError}`)}
                  </MenuItem>
                </Select>
              </Box>
            </Hidden>
          </AbsoluteRight>
          <SettingsMenu
            anchorEl={buttonSettingsRef?.current || undefined}
            onClose={toggleMenu}
            open={isMenuOpen}
            cameraDevices={cameraDevices}
            handleChangeCamera={handleChangeCamera}
            selectedCamera={selectedCamera}
          />
          <LockModuleDialog open={open} toggleOpen={toggleOpen} />
        </>
      )}
    </Layout>
  )
}

export default SignOnDemandRecordVideo
