import React from 'react'

import SettingsMenuPure from './Pure'
import useCountdown from '../../../hooks/useCountdown'
import useIntl from '../../../hooks/useIntl'
interface ISettingsMenuProps {
  anchorEl?: HTMLElement
  open: boolean
  onClose?: () => void
  cameraDevices: MediaDeviceInfo[]
  handleChangeCamera: (value: string) => void
  selectedCamera: MediaDeviceInfo | null
  pinContour?: boolean
  handleChangePinContour?: (value: boolean) => void
}

const SettingsMenu: React.FC<ISettingsMenuProps> = ({
  open,
  onClose,
  anchorEl,
  cameraDevices,
  handleChangeCamera,
  selectedCamera,
  pinContour,
  handleChangePinContour,
}) => {
  const [countdownState, countDownFns] = useCountdown()
  const intl = useIntl()
  const textCountdown = intl.get('pages.signSentence.settingsMenu.countdownText')
  const textFixContour = intl.get('pages.signSentence.settingsMenu.fixContour')

  return (
    <SettingsMenuPure
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      countdown={countdownState.countdown}
      onCountDownChange={countDownFns.set}
      textCountdown={textCountdown}
      cameraDevices={cameraDevices}
      selectedCamera={selectedCamera}
      handleChangeCamera={handleChangeCamera}
      pinContour={pinContour}
      textFixContour={textFixContour}
      handleChangePinContour={handleChangePinContour}
    />
  )
}

export default SettingsMenu
