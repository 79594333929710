import React, { useState } from 'react'
import { useGlobal } from 'reactn'
import ApplicationDialogPure from '../Pure'
import useDialog from '../../../services/hooks/useDialog'
import useSnackbar from '../../../services/hooks/useSnackbar'
import useModuleSegmentVideo from '../../../hooks/useModuleSegmentVideo'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import { updateVideoFlags, getDocumentReference, IGlobalAttr, getFirestoreDocument, IVideo } from 'collections'
import useIntl from '../../../hooks/useIntl'

const ReportSegmentDialog = (): JSX.Element => {
  const [open, toggleOpen] = useDialog('reportSegmentDialog')
  const [report, setReport] = useState('')
  const [sending, setSending] = useState(false)
  const showSnackbar = useSnackbar()
  const [fsVideoRef] = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')
  const { skipVideo } = useModuleSegmentVideo()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const intl = useIntl()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReport(event.target.value)
  }

  const sendReport = async () => {
    try {
      if (report.trim() == '') {
        showSnackbar(intl.get('messages.writeReport'), { variant: 'error' })
        return
      }
      setSending(true)
      const userId = fsUserRef.id
      const videoId = fsVideoRef?.id
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId || '')
      await updateVideoFlags(videoRef, {
        errors: {
          [userId]: report,
        },
      })

      const videoData = (await getFirestoreDocument(videoRef)).data() as IVideo
      if (videoData.isSegmenting.value == true) skipVideo()

      setSending(false)
      showSnackbar(intl.get('messages.thankYou'), { variant: 'success' })
      toggleOpen()
    } catch (e) {
      setSending(false)
      showSnackbar(intl.get('messages.reportNotSent'), { variant: 'error' })
    }
  }

  return (
    <ApplicationDialogPure
      title={intl.get('components.reportSegmentDialog.giveReport')}
      labelledby="report-dialog"
      open={open}
      options={{
        subtitle: intl.get('components.reportSegmentDialog.whatsWrong'),
        feedback: report,
        isPending: sending,
        labelTextFiel: intl.get('components.reportSegmentDialog.yourReport'),
      }}
      toggleOpen={toggleOpen}
      send={sendReport}
      handleChange={handleChange}
    />
  )
}

export default ReportSegmentDialog
