import { doc } from '@firebase/firestore'
import { activate, getValue } from '@firebase/remote-config'
import { useEffect, useRef } from 'react'
import { useRemoteConfig } from 'reactfire'
import { useGlobal } from 'reactn'

import logger from '../services/logger'
import { CollectionReferenceClient, DocumentReferenceClient, IGlobalAttr, INmtRegion } from 'collections'
import { useFsUserDocData } from './useFsUser'
import { getCollectionReference, getDocumentReference } from 'collections'
import { addSpaceBetweenFingerspell } from '../services/utils'

const { log } = logger('useNmtSuggestion')

interface INmtSuggestionState {
  nmtSuggestion?: INmtRegion[]
  isLoading?: boolean
}

interface ISentenceStateResult {
  nmt_version: string
  translation: INmtRegion[]
}

interface INmtSuggestionFns {
  /**
   * Seta uma nova sentença para traduzir
   * @param sentence Sentença que iremos traduzir
   */
  setSentence: (sentence?: string) => Promise<ISentenceStateResult>
}

type NmtSuggestionHookTuple = [INmtSuggestionState, INmtSuggestionFns]

interface INmtConfig {
  [workspace: string]: {
    url: string
    token: string
  }
}

/**
 * Consulta a NMT para sugerir traduções de sinais ou datilologia
 */
const useNmtSuggestion = (): NmtSuggestionHookTuple => {
  const fsUser = useFsUserDocData()
  const nmtConfig = useRef<INmtConfig>({
    'HT-ASL': {
      url: '',
      token: '',
    },
    'HT-BZS': {
      url: '',
      token: '',
    },
    'HT-BSL': {
      url: '',
      token: '',
    },
  })
  const [nmtSuggestion, setNmtSuggestion] = useGlobal<IGlobalAttr, 'nmtSuggestion'>('nmtSuggestion')
  const [isLoading, setIsLoading] = useGlobal<IGlobalAttr, 'isLoadingNmtSuggestion'>('isLoadingNmtSuggestion')
  const fbRemoteConfig = useRemoteConfig()

  const parseSuggestion = async (pResult: string[]) => {
    const _result = pResult.filter((el) => el !== '')
    const result = addSpaceBetweenFingerspell(_result)

    const parsed = await Promise.all(
      result.map(async (value) => {
        const region: INmtRegion = {
          id: doc(getCollectionReference(fsUser.workspace, 'random') as CollectionReferenceClient).id,
        }
        if (value.includes('¶')) {
          const signId = value.substring(1, value.length)
          region.signRef = getDocumentReference(fsUser.workspace, 'signs', signId) as DocumentReferenceClient
        } else {
          region.text = value
        }
        return region
      }),
    )
    setNmtSuggestion(parsed)
    return parsed
  }

  useEffect(() => {
    const call = async () => {
      await activate(fbRemoteConfig)

      if (getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
        nmtConfig.current = JSON.parse(getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
    }
    call()
  }, [fbRemoteConfig])

  /**
   * Seta uma nova sentença e atualiza as sugestões
   */
  const setSentence = async (sentence?: string) => {
    try {
      const url = nmtConfig.current[fsUser?.workspace.id]?.url
      const token = nmtConfig.current[fsUser?.workspace.id]?.token
      if (!url || !token) {
        return {
          nmt_version: '',
          translation: [],
        }
      }
      setIsLoading(true)
      if (sentence) {
        log('Obtendo nova tradução...')
        const body = new FormData()
        body.append('q', sentence)
        body.append('use_cache', 'false')
        body.append('adds_log', 'false')

        const response = await fetch(`${url}/translate`, {
          method: 'POST',
          headers: {
            authorization: token,
          },
          body,
        })

        const json = await response.json()
        const translation = json?.translate as string

        if (translation) {
          log(`"${sentence}" traduzido para "${translation}"`)
          const parsed = await parseSuggestion(translation.split(' '))

          const result: ISentenceStateResult = {
            nmt_version: json?.nmt_version,
            translation: parsed,
          }
          return result
        } else {
          log(`Falha ao obter traducao de "${sentence}"`)
        }
      } else {
        setNmtSuggestion([])
      }
      return {
        nmt_version: '',
        translation: [],
      }
    } finally {
      setIsLoading(false)
    }
  }

  const state: INmtSuggestionState = {
    nmtSuggestion,
    isLoading,
  }

  const fns: INmtSuggestionFns = {
    setSentence,
  }

  return [state, fns]
}

export default useNmtSuggestion
