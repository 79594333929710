import { IUser } from './types/user'
import { addDoc, updateDoc, setDoc, getDoc, DocumentReference, CollectionReference } from 'firebase/firestore'

const createUpdateFunction = async (
  ref: DocumentReference,
  userData: Pick<IUser, 'workspace' | 'oralLanguageId' | 'signLanguageId'>,
) => {
  return await updateDoc(ref, userData)
}

export const updateUser = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, userData: Partial<IUser>) => {
  return await addDoc(ref, userData)
}

export const addUser = createAddFunction

const createSetFunction = async (ref: DocumentReference, userData: Partial<IUser>) => {
  return await setDoc(ref, userData)
}

export const setUser = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getUser = createGetFunction
