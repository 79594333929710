import Link from '@material-ui/core/Link'
import ApplicationDialogPure from '../Pure'
import useDialog from '../../../services/hooks/useDialog'
import useIntl from '../../../hooks/useIntl'

const UploadDialog = (): JSX.Element => {
  const [open, toggleOpen] = useDialog('upload')
  const intl = useIntl()
  return (
    <ApplicationDialogPure
      labelledby="feedback-dialog"
      title={intl.get('components.updateDialog.updateGravatar')}
      open={open}
      toggleOpen={toggleOpen}
      component={
        <Link
          target="_blank"
          rel="noreferrer"
          href={'https://pt.gravatar.com/?logout=1'}
          style={{
            border: '2px solid #aaa',
            borderRadius: '4px',
            padding: '2px 5px',
            margin: '2px',
            background: '#ddd',
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {intl.get('components.updateDialog.updateNow')}
        </Link>
      }
    />
  )
}

export default UploadDialog
