import { useState, useEffect, useCallback } from 'react'

const useKeyPress = (targetKey: number, callback?: () => void): boolean => {
  // Crie um estado para salvar se uma tecla foi apertada ou não
  const [keyPressed, setKeyPressed] = useState(false)

  // Se a tecla foi apertada, salve o state e chame o callback
  const downHandler = useCallback(
    (x: KeyboardEvent) => {
      const { keyCode } = x
      if (keyCode === targetKey) {
        setKeyPressed(true)
        if (callback) {
          callback()
        }
      }
    },
    [callback, targetKey],
  )

  // Se foi desapertada, sete o state de volta para falso
  const upHandler = useCallback(
    ({ keyCode }: KeyboardEvent) => {
      if (keyCode === targetKey) {
        setKeyPressed(false)
      }
    },
    [targetKey],
  )

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [downHandler, upHandler]) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}

export default useKeyPress
