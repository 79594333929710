import { ISettings } from 'collections'
import { useFirestoreData } from './useFirestoreData'

/**
 * Pega os dados de um documento do firestore configurações
 */
export const useFsSettingsDocData = (): ISettings => {
  const data = useFirestoreData('settings/app').data as ISettings

  return data
}
