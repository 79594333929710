import { activate, getValue } from 'firebase/remote-config'
import { useEffect, useRef, useState } from 'react'
import { useRemoteConfig } from 'reactfire'
import { useFsUserDocData } from './useFsUser'
import logger from '../services/logger'
import {
  CollectionReferenceClient,
  DocumentReferenceClient,
  getCollectionReference,
  getDocumentReference,
  IGlobalAttr,
  INmtRegion,
} from 'collections'
import { doc } from 'firebase/firestore'
import { useGlobal } from 'reactn'
const { log } = logger('useGetNmtSign')

interface IGetNmtSignFns {
  getSigns: (text: string) => Promise<INmtRegion[] | undefined>
  getSignsXpresstranslation: (text: string) => Promise<string[] | undefined>
}

interface IGetNmtSignState {
  signs: INmtRegion[] | undefined
  isLoading: boolean | undefined
}

interface INmtConfig {
  [workspace: string]: {
    url: string
    urlDevelopment?: string
    token: string
  }
}

type GetNmtSignHookTuple = [IGetNmtSignFns, IGetNmtSignState]

const useGetNmtSign = (): GetNmtSignHookTuple => {
  const fsUser = useFsUserDocData()
  const nmtConfig = useRef<INmtConfig>({
    'HT-ASL': {
      url: '',
      token: '',
    },
    'HT-BZS': {
      url: '',
      token: '',
    },
    'HT-BSL': {
      url: '',
      token: '',
    },
  })

  const [isLoading, setIsLoading] = useGlobal<IGlobalAttr, 'isLoadingNmtSuggestion'>('isLoadingNmtSuggestion')
  const fbRemoteConfig = useRemoteConfig()
  const [signs, setSigns] = useState<INmtRegion[]>()

  useEffect(() => {
    const call = async () => {
      await activate(fbRemoteConfig)

      if (getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
        nmtConfig.current = JSON.parse(getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
    }
    call()
  }, [fbRemoteConfig])

  const parseSuggestion = async (result: string[]): Promise<INmtRegion[]> => {
    const parsed = await Promise.all(
      result.map(async (value) => {
        const region: INmtRegion = {
          id: doc(getCollectionReference(fsUser.workspace, 'random') as CollectionReferenceClient).id,
        }
        if (value.includes('¶')) {
          const signId = value.substring(1, value.length)
          region.signRef = getDocumentReference(fsUser.workspace, 'signs', signId) as DocumentReferenceClient
        } else {
          region.text = value
        }

        return region
      }),
    )
    setSigns(parsed)
    return parsed
  }

  const getSignsXpresstranslation = async (text: string) => {
    const url = nmtConfig.current[fsUser?.workspace.id]?.url

    if (!url) return []
    const token = nmtConfig.current[fsUser?.workspace.id]?.token
    if (!url || !token) {
      return undefined
    }

    setIsLoading(true)
    log('Obtendo nova tradução...')
    const body = new FormData()
    body.append('q', text)
    body.append('use_cache', 'false')
    body.append('adds_log', 'false')
    body.append('debug', 'true')

    const response = await fetch(`${url}/xpresstranslation`, {
      method: 'POST',
      headers: {
        authorization: token,
      },
      body,
    })
    const json = await response.json()
    const setOfMovements = new Set<string>(json.movement_ids || [])
    return Array.from(setOfMovements)
  }

  const getSigns = async (text: string): Promise<INmtRegion[] | undefined> => {
    const url = nmtConfig.current[fsUser?.workspace.id]?.url
    const token = nmtConfig.current[fsUser?.workspace.id]?.token
    if (!url || !token) {
      return undefined
    }

    setIsLoading(true)
    log('Obtendo nova tradução...')
    const body = new FormData()
    body.append('q', text)
    body.append('use_cache', 'false')
    body.append('adds_log', 'false')

    const response = await fetch(`${url}/translate`, {
      method: 'POST',
      headers: {
        authorization: token,
      },
      body,
    })

    const json = await response.json()
    const translation = json?.translate as string

    if (translation) {
      log(`"${text}" traduzido para "${translation}"`)
      await parseSuggestion(translation.split(' '))
    } else {
      log(`Falha ao obter traducao de "${text}"`)
    }
    setIsLoading(false)
    const parsed = await parseSuggestion(translation.split(' '))
    return parsed
  }

  const fns = {
    getSigns,
    getSignsXpresstranslation,
  }

  const state = {
    signs,
    isLoading,
  }

  return [fns, state]
}

export default useGetNmtSign
