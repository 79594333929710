import React from 'react'
import { Box, Link } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import StopIcon from '@material-ui/icons/Stop'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import { IManuallyTranslationSentence } from 'collections'
import { IFingerspell, ISignWithHta } from '../index'
import { routes } from '../../../../community'
import Tooltip from '@material-ui/core/Tooltip'
import useIntl from '../../../../hooks/useIntl'
import { red } from '@material-ui/core/colors'

interface ISearchSignItem {
  item: ISignWithHta | IFingerspell
  isPlaying: string | null
  onStopAnimation: () => void
  onPlayAnimation: (item?: IManuallyTranslationSentence | null, itemSearch?: ISignWithHta | IFingerspell) => void
}

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: 14,
  },
}))(Box)

const StyledRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    padding: '10px',
  },
}))(Card)

export const SearchSignItem: React.FC<ISearchSignItem> = ({ item, isPlaying, onPlayAnimation, onStopAnimation }) => {
  const id = item.draggableId || ''
  const intl = useIntl()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,

    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: 9999,
    opacity: isDragging ? 0.5 : 1,
  }

  return (
    <StyledRow ref={setNodeRef} style={style} {...attributes} {...listeners} key={item.draggableId}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          opacity: isPlaying !== item.draggableId && isPlaying !== null ? 0.15 : 1,
          pointerEvents: isPlaying !== item.draggableId && isPlaying !== null ? 'none' : 'initial',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <StyledTableCell>
            <Fab
              size={'small'}
              onClick={(ev) => {
                ev.stopPropagation()
                if (item.draggableId === isPlaying) {
                  onStopAnimation()
                } else {
                  onPlayAnimation(null, item)
                }
              }}
              color={'primary'}
              style={{
                backgroundColor: item.waitingRevision
                  ? '#09A78F'
                  : !item.animationExported
                  ? red['500']
                  : item.type === 'fingerspell'
                  ? '#d46d2a'
                  : 'primary',
              }}
            >
              {isPlaying === item.draggableId ? <StopIcon /> : <PlayArrowIcon />}
            </Fab>
          </StyledTableCell>
          <StyledTableCell>{item.word || ''}</StyledTableCell>
        </Box>
        <StyledTableCell>
          {item.type === 'sign' && (
            <Tooltip title={intl.get('components.signView.showInHTube')}>
              <Link href={routes.hTube.replace(':clusterId', item.signId)} target={'_blank'}>
                <Fab
                  size={'small'}
                  onClick={(ev) => {
                    ev.stopPropagation()
                  }}
                  color={'primary'}
                >
                  <OndemandVideoIcon />
                </Fab>
              </Link>
            </Tooltip>
          )}
        </StyledTableCell>
      </Box>
    </StyledRow>
  )
}
