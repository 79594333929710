import React, { useEffect } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import useDebounce from '../../services/hooks/useDebounce'
import { Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

const ScrollableBox = styled(Box)`
  flex: 1;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #696969;
    border-radius: 5px;
  }
`

interface ISignSearchPureProps {
  /** Callback para campo de texto alterado */
  onSearchTermChange?: (term: string) => void
  children?: JSX.Element
  setNmtSearch?: React.Dispatch<React.SetStateAction<boolean>>
  nmtSearch?: boolean
  onClickSearch: () => Promise<void>
  textSignSearch: string
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  loadMoreVisible?: boolean
  handleClickLoadMore?: () => void
  textLoadMore?: string
}

/**
 * Renderiza a caixa de busca de sinais
 */
const SignSearchPure: React.FC<ISignSearchPureProps> = ({
  onSearchTermChange,
  children,
  setNmtSearch,
  nmtSearch,
  onClickSearch,
  textSignSearch,
  search,
  setSearch,
  loadMoreVisible,
  handleClickLoadMore,
  textLoadMore,
}) => {
  const deboucedTerm = useDebounce(search, 200)

  const handleSearchTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value as string
    const normalized = text.toLocaleLowerCase()
    setSearch(normalized)
  }

  useEffect(() => {
    if (onSearchTermChange) onSearchTermChange(deboucedTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboucedTerm])

  const handleChangeUseNmtCheck = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (setNmtSearch) setNmtSearch(checked)
  }

  const onKeyUpSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onClickSearch()
    }
  }

  const head = (
    <Box width="100%">
      <Paper variant="outlined">
        <Box display="flex" alignItems="center" borderBottom="1px solid #cecece" borderRadius={0}>
          <InputBase
            placeholder={textSignSearch}
            style={{ padding: 10, width: '100%' }}
            value={search}
            onChange={handleSearchTextChanged}
            onKeyUp={onKeyUpSearch}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={nmtSearch}
                onChange={handleChangeUseNmtCheck}
                style={{ padding: '5px', color: blue[500] }}
              />
            }
            label={<Typography variant="body2">NMT</Typography>}
          />
          <IconButton
            aria-label="search"
            style={{ padding: 10 }}
            onClick={() => {
              onClickSearch()
            }}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  )

  return (
    <Paper
      variant="outlined"
      style={{
        border: 'none',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {head}
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <ScrollableBox overflow="auto" p={2}>
          {children}
        </ScrollableBox>

        {loadMoreVisible && (
          <div
            style={{
              margin: '10px',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Button variant="contained" color="primary" onClick={handleClickLoadMore}>
              {textLoadMore || ''}
            </Button>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default SignSearchPure
