import { useCallback } from 'react'

import useFirebaseAnalytics from './useFirebaseAnalytics'
import { CommunityModule, IGlobalAttr } from 'collections'
import logger from '../logger'
import { useGlobal } from 'reactn'

const { log } = logger('useJobMeter')

interface IJobMeter {
  startJobMeter: () => void
  endJobMeter: () => void
  resetJobMeter: () => void
}
/**
 * O objetivo deste hook é medir quanto tempo leva para realizar cada contribuição,
 * com isso podemos ter uma média de desempenho de cada módulo.
 * Assim que um job é encerrado, o material é reportado automaticamente para o firebase analytics
 * @param communityModule - Módulo trabalhado
 */
const useJobMeter = (communityModule: CommunityModule): IJobMeter => {
  const [jobMeter, setJobMeter] = useGlobal<IGlobalAttr, 'jobMeter'>('jobMeter')

  const { jobMeterEvent } = useFirebaseAnalytics()

  /**
   * Inicia o contador definindo a data atual no state
   */
  const startJobMeter = useCallback(() => {
    if (!jobMeter || !jobMeter.isRunning) {
      setJobMeter({
        startDate: new Date(),
        isRunning: true,
      })
      log('Iniciado!')
    } else {
      log('Já está em andamento! Continuando do anterior')
    }
  }, [jobMeter, setJobMeter])

  /** Limpa o jobMeter e deixa pronto para a proxima utilização */
  const resetJobMeter = useCallback(() => {
    setJobMeter({ ...jobMeter, isRunning: false })
    log('Resetado')
  }, [jobMeter, setJobMeter])

  /**
   * Finaliza o contador, calcula o tempo de trabalho e reporta o progresso para o firebase analytics
   */
  const endJobMeter = useCallback(() => {
    if (jobMeter && jobMeter.isRunning) {
      const endDate = new Date()
      const jobTime = endDate.getTime() - jobMeter.startDate.getTime()
      // Converte o tempo para segundos
      const normalizedJobTime = Math.round(jobTime / 1000)
      log(`Reportando contribuição em ${communityModule} com duração de ${normalizedJobTime} segundos `)
      jobMeterEvent(communityModule, normalizedJobTime)
      resetJobMeter()
    } else {
      log('Não é possivel registrar o evento pois o job não foi iniciado')
    }
  }, [jobMeter, communityModule, jobMeterEvent, resetJobMeter])

  return { startJobMeter, endJobMeter, resetJobMeter }
}

export default useJobMeter
