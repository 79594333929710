import { WorkspaceId } from 'collections'
import { IReturnSignRecognition } from '../types/keypoints'
import { useFsUserDocData } from './useFsUser'
import useSignRecognitionModel from './useSignRecognitionModel'

interface IUseSignRecognition {
  onResults?: (predictResult?: IReturnSignRecognition[], onFinalize?: () => void) => void
  topK?: number
}

export const useSignRecognition = ({ onResults, topK }: IUseSignRecognition) => {
  const fsUser = useFsUserDocData()
  const predict = useSignRecognitionModel(fsUser.workspace.id as WorkspaceId)[1]

  const predictSignRecognition = (keypoints: number[][][], segments: number[][], onFinalize?: () => void) => {
    predict({
      kpts: keypoints,
      topk: topK || 5,
      segments: segments,
    })
      .then((predictResult) => {
        if (onResults) {
          onResults(predictResult, onFinalize)
        }
        return predictResult
      })
      .catch((err) => {
        console.log(err)
        if (onFinalize) {
          onFinalize()
        }
      })
  }

  return { predictSignRecognition }
}
