import { DocumentReferenceGeneric, IManuallyTranslation } from './types'
import { getDocumentReference, setFirestoreDocument, updateFirestoreDocument } from './Base'
import { preProcess } from './Phrases'

export const createManuallyTranslation = (
  workspaceRef: DocumentReferenceGeneric,
  id: string,
  data: Pick<
    IManuallyTranslation,
    | 'client'
    | 'translationSentences'
    | 'uid'
    | 'requester'
    | 'type'
    | 'projectName'
    | 'deadline'
    | 'createdAt'
    | 'status'
    | 'context'
  >,
) => {
  const manuallyTranslation: IManuallyTranslation = {
    createdAt: data.createdAt,
    errors: null,
    interpreter: [],
    progress: 0,
    client: data.client,
    translationSentences: data.translationSentences,
    uid: data.uid,
    requester: data.requester,
    type: data.type,
    projectName: data.projectName,
    deadline: data.deadline,
    status: data.status,
    legacyPDIds: null,
    searchTerms: preProcess(data.projectName, workspaceRef.id, true, false, false, false),
    responsible: null,
    context: data.context,
  }

  return setFirestoreDocument(getDocumentReference(workspaceRef, 'manuallyTranslations', id), manuallyTranslation)
}

export const updateManuallyTranslation = (ref: DocumentReferenceGeneric, data: Partial<IManuallyTranslation>) => {
  return updateFirestoreDocument(ref, data)
}
