import React from 'react'
import { useFirestoreCollection, useFirestore, useStorage } from 'reactfire'

import AnimationListPure from './Pure'

import { DocumentReference, IAnimation } from 'collections'
import { useFsUserDocRef, useFsUserDocData } from '../../../../hooks/useFsUser'
import useIntl from '../../../../hooks/useIntl'
import { Query, query, runTransaction, serverTimestamp } from '@firebase/firestore'
import { DocumentData, orderBy, where } from '@firebase/firestore'
import { getCollectionReference } from 'collections'

interface IAnimationData extends IAnimation {
  id: string
}

interface IAnimationListProps {
  /** Refêrencia ao sinal atual */
  signDoc: DocumentReference
  /** Usuários do workspace */
  workspaceUsers: DocumentData
  /** Dados do sinal atual */
  signData: DocumentData
  /** Número da animação selecionada */
  animationSelected: number
  /** Callback para ativar a animação */
  setAnimationSelected: (index: number) => void
}

/**
 * Componente de exibição de animações
 */
const AnimationList: React.SFC<IAnimationListProps> = ({
  signDoc,
  workspaceUsers,
  signData,
  animationSelected,
  setAnimationSelected,
}) => {
  const { currentAnimation, isAnimating } = signData
  const firestore = useFirestore()
  const storage = useStorage()
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const animationsCollection = useFirestoreCollection(
    query(
      getCollectionReference(fsUser.workspace, 'animations') as Query<DocumentData>,
      where('sign', '==', signDoc),
      orderBy('isDisabled.value', 'asc'),
    ),
  ).data
  const animationsCollectionData: IAnimationData[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animationsCollection.forEach((animation: any) =>
    animationsCollectionData.push({ ...animation.data(), id: animation.id }),
  )
  const intl = useIntl()
  const textMain = intl.get('messages.main').toUpperCase()
  const textDate = intl.get('messages.date').toUpperCase()
  const textAnimator = intl.get('messages.animator').toUpperCase()
  const textCopyHtaTooltip = intl.get('pages.animation.copyHta')
  const textDownloadBlendTooltip = intl.get('pages.animation.downloadBlendFile')
  const currentAnimationIndex =
    currentAnimation &&
    animationsCollectionData.findIndex(({ id }: IAnimationData) => id === currentAnimation.animation.id)

  /** Salva uma animação como animação principal */
  const setMainAnimation = async () => {
    if (animationsCollectionData[animationSelected].htaAnimation) {
      let currentAnimationHasErrors: boolean | null = null
      const _errors = animationsCollectionData[animationSelected].errors
      if (_errors && _errors.length > 0) {
        currentAnimationHasErrors = !_errors.includes('NO_ERRORS')
      }

      await runTransaction(firestore, async (transaction) => {
        transaction.update(signDoc, {
          // @ts-ignore

          isAnimated: true,
          isAnimating: {
            lastUpdate: signData.isAnimating.lastUpdate,
            user: signData.isAnimating.user,
            value: false,
          },
          currentAnimation: {
            animation: animationsCollection.docs[animationSelected].ref,
            createdBy: animationsCollectionData[animationSelected].createdBy,
            createdAt: animationsCollectionData[animationSelected].createdAt,
            errors: animationsCollectionData[animationSelected].errors || null,
          },
          currentAnimationHasErrors: currentAnimationHasErrors,
          currentAnimationReviewedAt: animationsCollectionData[animationSelected].reviewedAt || null,
        })
      })
    }
  }

  /** Ativa o status de erro de datilologia */
  const setSignAsFingerspelling = async () => {
    await runTransaction(firestore, async (transaction) => {
      transaction.update(signDoc, {
        isFingerspell: true,
        isAnimating: {
          value: false,
          user: null,
          lastUpdate: null,
        },
      })
    })
  }

  const disableAnimation = async () => {
    // @ts-ignore
    const animationDoc = animationsCollection.docs[animationSelected].ref
    await runTransaction(firestore, async (transaction) => {
      transaction.update(animationDoc, {
        isDisabled: {
          user: fsUserRef,
          lastUpdate: serverTimestamp(),
          value: true,
        },
      })

      if (currentAnimation && currentAnimation.animation.id === animationDoc.id) {
        transaction.update(signDoc, {
          currentAnimation: null,
          isAnimated: false,
          currentAnimationHasErrors: null,
          currentAnimationReviewedAt: null,
        })
      }
    })
  }

  return (
    <AnimationListPure
      data={animationsCollectionData}
      currentAnimationIndex={currentAnimationIndex}
      storage={storage}
      actionsActive={!isAnimating.value || isAnimating.user.id === fsUserRef.id}
      animationSelected={animationSelected}
      workspaceUsers={workspaceUsers}
      setAnimationSelected={setAnimationSelected}
      setMainAnimation={setMainAnimation}
      setSignAsFingerspelling={setSignAsFingerspelling}
      disableAnimation={disableAnimation}
      textMain={textMain}
      textDate={textDate}
      textAnimator={textAnimator}
      textCopyHtaTooltip={textCopyHtaTooltip}
      textDownloadBlendTooltip={textDownloadBlendTooltip}
    />
  )
}

export default AnimationList
