import { IKeypoints, IKeypointsPredict } from '../types/keypoints'

import useAutoSegmentationModel from './useSegmentationModel'
import { landMarksToArray, treat_keypoints_func } from '../services/pre_process'
import { useFsUserDocData } from './useFsUser'

// import { Container } from './styles';

interface IUseSegmentationProps {
  onResults?: (keypoints: number[][][], segments: number[][], onFinalize?: () => void) => void
}

export const useSegmentation = ({ onResults }: IUseSegmentationProps) => {
  const fsUser = useFsUserDocData()
  const [, predict] = useAutoSegmentationModel(fsUser.workspace.id)

  /**
   *
   * @param data dados dos keypoints capturados do mediapipe
   */
  const predictSegmentation = async (keypoints: IKeypoints[], onFinalize?: () => void) => {
    // if (currentFrame === null) return;

    const kp_to_predict: IKeypointsPredict = {
      pose: [],
      hand_l: [],
      hand_r: [],
    }

    // // Tratamos os keypoints e colocamos no formato aceito pelo modelo
    for (let frame = 0; frame < (keypoints || []).length; frame++) {
      const [pose, hand_l, hand_r] = await landMarksToArray(
        keypoints[frame].pose,
        keypoints[frame].leftHand,
        keypoints[frame].rightHand,
      )

      kp_to_predict.pose = kp_to_predict.pose.concat([pose])
      kp_to_predict.hand_l = kp_to_predict.hand_l.concat([hand_l])
      kp_to_predict.hand_r = kp_to_predict.hand_r.concat([hand_r])
    }

    const { pose, hand_l, hand_r } = kp_to_predict
    const { data: kpts } = treat_keypoints_func(pose, hand_l, hand_r)
    predict(kpts, -1)
      .then((segments) => {
        if (segments && segments.length) {
          if (onResults) {
            onResults(kpts, segments, onFinalize)
          } else {
            if (onFinalize) {
              onFinalize()
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
        if (onFinalize) {
          onFinalize()
        }
      })
  }
  return {
    predictSegmentation,
  }
}
