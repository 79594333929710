import React, { useState, useEffect } from 'react'

import WorkspacesTablePure from './Pure'
import { IWorkspace, IUsersTable, DocumentSnapshot } from 'collections'
import { useFirestore, useFirestoreCollection } from 'reactfire'
import Preloader from '../../../components/Preloader'
import WorkspaceModal from './WorkspaceModal'
import { collection } from '@firebase/firestore'

const WorkspacesTable: React.FC<IUsersTable> = ({ oralLanguages, signLanguages }) => {
  const firestore = useFirestore()
  const workspaceCollection = useFirestoreCollection(collection(firestore, 'workspaces')).data
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>()
  const [workspaceModalOpened, setWorkspaceModalOpened] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)

  /** Hook que escuta a coleção de workspaces caso haja modificação seja atualizada em tempo real */
  useEffect(() => {
    const _workspaces: IWorkspace[] = []
    workspaceCollection.docs.map((workspace: DocumentSnapshot) => {
      const _workspace = {
        ...(workspace.data() as IWorkspace),
        id: workspace.id,
      }
      _workspaces.push(_workspace)
    })
    setWorkspaces(_workspaces)
    setIsLoading(false)
  }, [workspaceCollection])

  const handleCreateWorkspace = () => {
    setWorkspaceModalOpened(!workspaceModalOpened)
  }

  return (
    <>
      {(isLoading && <Preloader asBlock text="Loading..."></Preloader>) || (
        <>
          <WorkspacesTablePure workspaces={workspaces || []} handleCreateWorkspace={handleCreateWorkspace} />
          <WorkspaceModal
            isOpen={workspaceModalOpened}
            setIsOpen={setWorkspaceModalOpened}
            workspaces={workspaces || []}
            oralLanguages={oralLanguages}
            signLanguages={signLanguages}
          />
        </>
      )}
    </>
  )
}

export default WorkspacesTable
