import { activate, getValue } from 'firebase/remote-config'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Typography,
  Grid,
} from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../components/Layout'
import useIntl from '../../hooks/useIntl'
import CardAnimation from './CardAnimation'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useAuth, useDatabase, useFirestore, useRemoteConfig } from 'reactfire'
import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import { query, ref, set } from '@firebase/database'
import {
  IAbTestNmt,
  preProcess,
  IAnimation,
  getDocumentReference,
  getFirestoreDocument,
  DocumentReference,
  IWorkspacePermissions,
  createAbTestNmt,
  ISign,
  IRatings,
  getCollectionReference,
} from 'collections'
import { collection, where, query as firestoreQuery, getDocs, Timestamp } from '@firebase/firestore'
import useSnackbar from '../../services/hooks/useSnackbar'
import CongrulationsDialog from './CongrulationsDialog'
import CardMessage from '../../components/CardMessage'
import { useLocation, useRoute } from 'wouter'
import { routes } from '../../community'
import md5 from 'md5'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import deepEqual from 'deep-equal'
import { DocumentData, Query, documentId, runTransaction } from 'firebase/firestore'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { get } from 'firebase/database'

interface IAbTestProps {
  path: string
}

interface IAbTestNmtRT {
  url_base: string
  version_a: string
  version_b: string
  sentences: string[][]
  tags: string[]
  scope: string
  translations: {
    [version: string]: {
      [sentenceId: string]: {
        signs: {
          id: string
          glosa: string
          relatedWords: string[]
        }[]
        htaAnimations: string[]
        translate: string
      }
    }
  }
}

interface INmtConfig {
  [workspace: string]: {
    url: string
    urlDevelopment?: string
    token: string
  }
}

export interface IEvaluated {
  createdAt: Timestamp
  sentence: string
  score_a: number | null
  score_b: number | null
  tags_a: string[]
  tags_b: string[]
  translate_a: string[]
  translate_b: string[]
}

interface ILastAbtestPhraseData {
  scoreLeft: string | undefined
  scoreRight: string | undefined
  optionsLeft: string[]
  optionsRight: string[]
  translationLeft: string[]
  translationRight: string[]
}

/**
 * Modulo de validação da qualidade da NMT
 */
const AbTestNmt: React.FC<IAbTestProps> = () => {
  const showSnackbar = useSnackbar()
  const hasPermissionOnModule = useCheckHasPermission('abTestNmt')
  const [location, setLocation] = useLocation()
  const [, routeParams] = useRoute(routes.abTestNmt)
  const intl = useIntl()
  const firestore = useFirestore()
  const db = useDatabase()
  const fsUser = useFsUserDocData()
  const fbAuth = useAuth()
  const fsUserRef = useFsUserDocRef()
  const fbRemoteConfig = useRemoteConfig()
  const nmtConfig = useRef<INmtConfig>({
    'HT-ASL': {
      url: '',
      token: '',
    },
    'HT-BZS': {
      url: '',
      token: '',
    },
    'HT-BSL': {
      url: '',
      token: '',
    },
  })
  const [abTestNmtRT, setAbTestNmtRT] = useState<IAbTestNmtRT | undefined>(undefined)
  const [currentPos, setCurrentPos] = useState(0)
  const [currentSentence, setCurrentSentence] = useState('')
  const [currentAnimationsLeft, setCurrentAnimationsLeft] = useState<string[]>([])
  const [currentAnimationsRight, setCurrentAnimationsRight] = useState<string[]>([])
  const [currentBatch, setCurrentBatch] = useState<number | undefined>(undefined)
  const [validated, setValidated] = useState<boolean>(false)
  const [scoreLeft, setScoreLeft] = useState<string | undefined>(undefined)
  const [scoreRight, setScoreRight] = useState<string | undefined>(undefined)
  const [addWordTextLeft, setAddWordTextLeft] = useState('')
  const [addWordTextRight, setAddWordTextRight] = useState('')
  const [optionsLeft, setOptionsLeft] = useState<string[]>([])
  const [optionsRight, setOptionsRight] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [translationLeft, setTranslationLeft] = useState<string[]>([])
  const [translationRight, setTranslationRight] = useState<string[]>([])
  const [openCongrulationDialog, setOpenCongrulationsDialog] = useState<boolean>(false)
  const [abTestNmtId, setAbTestNmtId] = useState<string>('')
  const [testClosed, setTestClosed] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<IWorkspacePermissions>()
  const [openTableLeft, setOpenTableLeft] = useState<boolean>(false)
  const [openTableRight, setOpenTableRight] = useState<boolean>(false)
  const [signsLeft, setSignsLeft] = useState<{ id: string; glosa: string; relatedWords: string[] }[]>([])
  const [signsRight, setSignsRight] = useState<{ id: string; glosa: string; relatedWords: string[] }[]>([])
  const [signIdLeft, setSignIdLeft] = useState<string>('')
  const [signIdRight, setSignIdRight] = useState<string>('')
  const [isPlayingLeft, setIsPlayingLeft] = useState(false)
  const [isPlayingRight, setIsPlayingRight] = useState(false)
  const [autoPlayLeft, setAutoPlayLeft] = useState(false)
  const [autoPlayRight, setAutoPlayRight] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [oneSideMode, setOneSideMode] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)
  const [skippedPhrases, setSkippedPhrases] = useState<number>(0)
  const [skippedPhrasesLength, setSkippedPhrasesLength] = useState<number>(0)
  const [seeNotRatedPhrases, setSeeNotRatedPhrases] = useState<boolean>(false)
  const [lastAbTestSentence, setLastAbTestSentence] = useState<string>('')
  const [lastAbTestPos, setLastAbTestPos] = useState<number>(0)
  const [openFinishedTestDialog, setOpenFinishedTestDialog] = useState<boolean>(false)
  const [testFinished, setTestFinished] = useState<boolean>(false)
  const [lastAbTestPhraseData, setLastAbTestPhraseData] = useState<ILastAbtestPhraseData>({
    scoreLeft: undefined,
    scoreRight: undefined,
    optionsLeft: [],
    optionsRight: [],
    translationLeft: [],
    translationRight: [],
  })

  const handleClickBack = async () => {
    setCurrentPos(currentPos - 1)
    const skippedPhrasesString = localStorage.getItem('abTestSkippedPhrasesId')
    const skippedPhrasesArray: string[] = skippedPhrasesString && JSON.parse(skippedPhrasesString)
    if (abTestNmtRT) {
      let _currentSentence = ''
      if (!seeNotRatedPhrases) {
        _currentSentence = abTestNmtRT.sentences[currentBatch || 0][currentPos - 1]
        setCurrentSentence(_currentSentence)
      } else {
        _currentSentence = abTestNmtRT.sentences[currentBatch || 0].filter(
          (el) => preProcess(el, fsUser?.workspace.id || '', false, false, true) == skippedPhrasesArray[currentPos - 1],
        )[0]
        setCurrentSentence(_currentSentence)
      }
      const md5Sentence = preProcess(_currentSentence, fsUser?.workspace.id || '', false, false, true)

      const abTestNmtRef = getDocumentReference(fsUser.workspace, 'abTestNmt', abTestNmtId)

      const ratingsRef = getDocumentReference(abTestNmtRef, 'ratings', md5Sentence)
      const ratingsData: IRatings = (await getFirestoreDocument(ratingsRef)).data() as IRatings

      if (ratingsData && ratingsData.usersRatings[fsUserRef.id]) {
        const _abTestNmtSentence = ratingsData.usersRatings[fsUserRef.id]
        setScoreLeft(_abTestNmtSentence.score_a?.toString() || undefined)
        setScoreRight(_abTestNmtSentence.score_b?.toString() || undefined)
        setOptionsLeft(_abTestNmtSentence.tags_a)
        setOptionsRight(_abTestNmtSentence.tags_b)
        setTranslationLeft(ratingsData.translate_a)
        setTranslationRight(ratingsData.translate_b)
      }
    }
  }

  const handleClickNext = async () => {
    setOpenTableLeft(false)
    setOpenTableRight(false)
    const skippedPhrasesString = localStorage.getItem('abTestSkippedPhrasesId')
    const skippedPhrasesArray: string[] = skippedPhrasesString && JSON.parse(skippedPhrasesString)
    if (validated == false) {
      setOpenConfirmationDialog(true)
    } else {
      try {
        let _currentPos = 0
        let _currentSentence = ''
        let _skippedPhrases
        if (!seeNotRatedPhrases) {
          const sentencesLength = (abTestNmtRT?.sentences[currentBatch || 0].length || 1) - 1

          const md5Sentence = preProcess(currentSentence, fsUser?.workspace.id || '', false, false, true)

          if (skippedPhrasesArray && skippedPhrasesArray.includes(md5Sentence)) {
            localStorage.setItem(
              'abTestSkippedPhrasesId',
              JSON.stringify(skippedPhrasesArray.filter((el) => el != md5Sentence)),
            )
            setSkippedPhrases((prev) => prev - 1)
          }

          if (currentPos < sentencesLength) {
            _currentPos = currentPos + 1
            setCurrentPos(_currentPos)
            setLastAbTestPos(_currentPos)
            setLastAbTestSentence(currentSentence)
          } else {
            if (skippedPhrases > 0) {
              localStorage.setItem(
                'abTestSkippedPhrasesId',
                JSON.stringify(skippedPhrasesArray.filter((el) => el != '')),
              )
              setOpenFinishedTestDialog(true)
            } else {
              localStorage.removeItem('abTestSkippedPhrasesId')
              setOpenCongrulationsDialog(!openCongrulationDialog)
            }
          }

          if (abTestNmtRT) {
            _currentSentence = abTestNmtRT.sentences[currentBatch || 0][_currentPos]
            setCurrentSentence(_currentSentence)
          }
        } else {
          const sentencesLength = (skippedPhrasesArray.length || 1) - 1
          const sentence = preProcess(currentSentence, fsUser?.workspace.id || '', false, false, true)

          if (!skippedPhrasesArray.includes(sentence)) {
            return
          } else {
            const newSkippedPhrasesArray = skippedPhrasesArray.filter((el) => el != sentence)
            newSkippedPhrasesArray.unshift('')
            localStorage.setItem('abTestSkippedPhrasesId', JSON.stringify(newSkippedPhrasesArray))
            _skippedPhrases = skippedPhrases - 1
            setSkippedPhrases(_skippedPhrases)
          }

          if (currentPos < sentencesLength) {
            _currentPos = currentPos + 1
            setCurrentPos(_currentPos)
            if (abTestNmtRT) {
              _currentSentence = abTestNmtRT.sentences[currentBatch || 0].filter(
                (el) =>
                  preProcess(el, fsUser?.workspace.id || '', false, false, true) == skippedPhrasesArray[_currentPos],
              )[0]
            }
            setCurrentSentence(_currentSentence)
          } else {
            if (abTestNmtRT && testFinished) {
              if (_skippedPhrases > 0) {
                localStorage.setItem(
                  'abTestSkippedPhrasesId',
                  JSON.stringify(skippedPhrasesArray.filter((el) => el != '')),
                )
                setOpenFinishedTestDialog(true)
              } else {
                localStorage.removeItem('abTestSkippedPhrasesId')
                setOpenCongrulationsDialog(!openCongrulationDialog)
              }
            } else {
              seeAbTestNmtPhrases()
            }
          }
        }

        const nextMd5Sentence = preProcess(_currentSentence, fsUser?.workspace.id || '', false, false, true)

        const md5Sentence = preProcess(currentSentence, fsUser?.workspace.id || '', false, false, true)

        const abTestNmtRef = getDocumentReference(fsUser.workspace, 'abTestNmt', abTestNmtId)

        let ratingsRef = getDocumentReference(abTestNmtRef, 'ratings', md5Sentence)
        let ratingsData: IRatings = {
          createdAt: Timestamp.fromDate(new Date()),
          sentence: '',
          translate_a: [],
          translate_b: [],
          usersRatings: {},
          batch: 0,
        }

        const sentencesListLeft = abTestNmtRT?.translations[abTestNmtRT.version_a][md5Sentence]

        const _translate_a = sentencesListLeft ? sentencesListLeft?.translate.split(' ') : translationLeft

        let _translate_b: string[] = []

        if (!oneSideMode) {
          const sentencesListRight = abTestNmtRT?.translations[abTestNmtRT.version_b][md5Sentence]

          _translate_b = sentencesListRight ? sentencesListRight?.translate.split(' ') : translationRight
        }

        await runTransaction(firestore, async (transaction) => {
          const ratingsDoc = await transaction.get(ratingsRef as DocumentReference)

          ratingsData = ratingsDoc.data() as IRatings
          const _ratings: IRatings = {
            createdAt: Timestamp.fromDate(new Date()),
            sentence: currentSentence,
            translate_a: _translate_a,
            translate_b: _translate_b,
            batch: currentBatch || 0,
            usersRatings: {
              ...(ratingsData && ratingsData.usersRatings ? ratingsData.usersRatings : {}),
              [fsUserRef.id]: {
                createdAt:
                  ratingsData && ratingsData.usersRatings[fsUserRef.id]
                    ? ratingsData.usersRatings[fsUserRef.id].createdAt
                    : Timestamp.fromDate(new Date()),
                score_a: scoreLeft ? parseInt(scoreLeft) : validated ? 0 : null,
                score_b: oneSideMode || !validated ? null : scoreRight ? parseInt(scoreRight) : 0,
                tags_a: optionsLeft,
                tags_b: optionsRight,
                skipped: !validated,
              },
            },
          }
          if (!deepEqual(ratingsData, _ratings)) {
            if (!ratingsDoc.exists()) {
              transaction.set(ratingsRef as DocumentReference, {
                ..._ratings,
              })
            } else {
              transaction.update(ratingsRef as DocumentReference, {
                usersRatings: {
                  ...ratingsData.usersRatings,
                  [fsUserRef.id]: _ratings.usersRatings[fsUserRef.id],
                },
              })
            }
            showSnackbar(intl.get('messages.savedSuccessfully'), {
              variant: 'success',
            })
          }
        })

        ratingsRef = getDocumentReference(abTestNmtRef, 'ratings', nextMd5Sentence)
        ratingsData = (await getFirestoreDocument(ratingsRef)).data() as IRatings

        if (_currentSentence != '' && ratingsData && ratingsData.usersRatings[fsUserRef.id]) {
          setScoreLeft(ratingsData.usersRatings[fsUserRef.id].score_a?.toString() || undefined)
          setScoreRight(ratingsData.usersRatings[fsUserRef.id].score_b?.toString() || undefined)
          setOptionsLeft(ratingsData.usersRatings[fsUserRef.id].tags_a)
          setOptionsRight(ratingsData.usersRatings[fsUserRef.id].tags_b)
          setTranslationLeft(ratingsData.translate_a)
          setTranslationRight(ratingsData.translate_b)
        } else {
          cleanState()
        }
      } catch (error) {
        showSnackbar((error as TypeError | RangeError | EvalError).message, {
          variant: 'error',
        })
        cleanState()
      }
    }
  }

  const cleanState = () => {
    setScoreLeft(undefined)
    setScoreRight(undefined)
    setAddWordTextLeft('')
    setAddWordTextRight('')
    setOptionsLeft([])
    setOptionsRight([])
    setTranslationLeft([])
    setTranslationRight([])
  }

  const toggleOpenCongrulationDialog = () => {
    setOpenCongrulationsDialog(!openCongrulationDialog)
  }

  const handleKeyPressLeft = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && addWordTextLeft != '') {
      event.preventDefault()

      const _options = [...new Set([...optionsLeft, addWordTextLeft])]
      setOptionsLeft(_options)

      updateTags(addWordTextLeft)
      setAddWordTextLeft('')
    }
  }

  const handleKeyPressRight = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && addWordTextRight != '') {
      event.preventDefault()

      const _options = [...new Set([...optionsRight, addWordTextRight])]
      setOptionsRight(_options)

      updateTags(addWordTextRight)
      setAddWordTextRight('')
    }
  }

  const handleChangeTextFieldLeft = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddWordTextLeft(event.target.value)
  }

  const handleChangeTextFieldRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddWordTextRight(event.target.value)
  }

  const handleChangeOptionsLeft = (value: string[]) => {
    setOptionsLeft(value)
  }

  const handleChangeOptionsRight = (value: string[]) => {
    setOptionsRight(value)
  }

  const handleClickPlaySignLeft = async (id: string) => {
    setAutoPlayLeft(true)

    const _htaAnimations: string[] = []
    try {
      const signQry = firestoreQuery(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where(documentId(), '==', id),
      )
      const signSnapshot = (await getDocs(signQry)).docs
      const signData = signSnapshot.map((el) => {
        return el.data() as ISign
      })

      if (signData[0] && signData[0].currentAnimation && signData[0].currentAnimation?.animation) {
        const animationSnapshot = await getFirestoreDocument(signData[0].currentAnimation.animation)
        const animationData = animationSnapshot.data() as IAnimation
        _htaAnimations.push(animationData.htaAnimation || '')
      }
    } catch (err) {
      const e = err as Error
      showSnackbar(e.message, { variant: 'error' })
    }
    setCurrentAnimationsLeft(_htaAnimations)
  }

  const handleClickOpenSignsLeft = () => {
    setOpenTableLeft(!openTableLeft)
  }

  const handleClickPlaySignRight = async (id: string) => {
    setAutoPlayRight(true)

    const _htaAnimations: string[] = []
    try {
      const signQry = firestoreQuery(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where(documentId(), '==', id),
      )
      const signSnapshot = (await getDocs(signQry)).docs
      const signData = signSnapshot.map((el) => {
        return el.data() as ISign
      })

      if (signData[0] && signData[0].currentAnimation && signData[0].currentAnimation?.animation) {
        const animationSnapshot = await getFirestoreDocument(signData[0].currentAnimation.animation)
        const animationData = animationSnapshot.data() as IAnimation
        _htaAnimations.push(animationData.htaAnimation || '')
      }
    } catch (err) {
      const e = err as Error
      showSnackbar(e.message, { variant: 'error' })
    }
    setCurrentAnimationsRight(_htaAnimations)
  }

  const handleClickOpenSignsRight = () => {
    setOpenTableRight(!openTableRight)
  }

  const handleSkipPhrase = async () => {
    setOpenConfirmationDialog(false)
    const skippedPhrasesString = localStorage.getItem('abTestSkippedPhrasesId')
    const skippedPhrasesArray: string[] = (skippedPhrasesString && JSON.parse(skippedPhrasesString)) || []
    try {
      let _currentPos = 0
      let _currentSentence = ''
      if (!seeNotRatedPhrases) {
        const sentencesLength = (abTestNmtRT?.sentences[currentBatch || 0]?.length || 1) - 1
        if (currentPos < sentencesLength) {
          _currentPos = currentPos + 1
          setCurrentPos(_currentPos)
          setLastAbTestPos(_currentPos)
          setLastAbTestSentence(currentSentence)
        } else {
          setOpenFinishedTestDialog(true)
        }

        if (abTestNmtRT) {
          _currentSentence = abTestNmtRT.sentences[currentBatch || 0][_currentPos]
          setCurrentSentence(_currentSentence)
        }
      } else {
        const sentencesLength = (skippedPhrasesArray.length || 1) - 1

        if (currentPos < sentencesLength) {
          _currentPos = currentPos + 1
          setCurrentPos(_currentPos)
          if (abTestNmtRT) {
            _currentSentence = abTestNmtRT.sentences[currentBatch || 0].filter(
              (el) =>
                preProcess(el, fsUser?.workspace.id || '', false, false, true) == skippedPhrasesArray[_currentPos],
            )[0]
          }
          setCurrentSentence(_currentSentence)
        } else {
          if (abTestNmtRT && testFinished) {
            localStorage.setItem('abTestSkippedPhrasesId', JSON.stringify(skippedPhrasesArray.filter((el) => el != '')))
            setOpenFinishedTestDialog(true)
          } else {
            seeAbTestNmtPhrases()
          }
        }
      }

      const nextMd5Sentence = preProcess(_currentSentence, fsUser?.workspace.id || '', false, false, true)

      const md5Sentence = preProcess(currentSentence, fsUser?.workspace.id || '', false, false, true)

      const abTestNmtRef = getDocumentReference(fsUser.workspace, 'abTestNmt', abTestNmtId)

      let ratingsRef = getDocumentReference(abTestNmtRef, 'ratings', md5Sentence)
      let ratingsData: IRatings = {
        createdAt: Timestamp.fromDate(new Date()),
        sentence: '',
        translate_a: [],
        translate_b: [],
        usersRatings: {},
        batch: currentBatch || 0,
      }

      const sentencesListLeft = abTestNmtRT?.translations[abTestNmtRT.version_a][md5Sentence]

      const _translate_a = sentencesListLeft ? sentencesListLeft?.translate.split(' ') : translationLeft

      await runTransaction(firestore, async (transaction) => {
        const ratingsDoc = await transaction.get(ratingsRef as DocumentReference)

        ratingsData = ratingsDoc.data() as IRatings
        const _ratings: IRatings = {
          createdAt: Timestamp.fromDate(new Date()),
          sentence: currentSentence,
          translate_a: _translate_a,
          translate_b: translationRight,
          batch: currentBatch || 0,
          usersRatings: {
            ...(ratingsData && ratingsData.usersRatings ? ratingsData.usersRatings : {}),
            [fsUserRef.id]: {
              createdAt:
                ratingsData && ratingsData.usersRatings[fsUserRef.id]
                  ? ratingsData.usersRatings[fsUserRef.id].createdAt
                  : Timestamp.fromDate(new Date()),
              score_a: scoreLeft ? parseInt(scoreLeft) : validated ? 0 : null,
              score_b: oneSideMode ? null : scoreRight ? parseInt(scoreRight) : validated ? 0 : null,
              tags_a: optionsLeft,
              tags_b: optionsRight,
              skipped: !validated,
            },
          },
        }
        if (!deepEqual(ratingsData, _ratings)) {
          if (!ratingsDoc.exists()) {
            transaction.set(ratingsRef as DocumentReference, {
              ..._ratings,
            })
          } else {
            transaction.update(ratingsRef as DocumentReference, {
              usersRatings: {
                ...ratingsData.usersRatings,
                [fsUserRef.id]: _ratings.usersRatings[fsUserRef.id],
              },
            })
          }
          showSnackbar(intl.get('messages.savedSuccessfully'), {
            variant: 'success',
          })
        }
      })

      if (skippedPhrasesArray.includes(md5Sentence)) {
        return
      } else {
        skippedPhrasesArray.push(md5Sentence)
        localStorage.setItem('abTestSkippedPhrasesId', JSON.stringify(skippedPhrasesArray))
        setSkippedPhrases((prev) => prev + 1)
      }

      ratingsRef = getDocumentReference(abTestNmtRef, 'ratings', nextMd5Sentence)
      ratingsData = (await getFirestoreDocument(ratingsRef)).data() as IRatings

      if (_currentSentence != '' && ratingsData && ratingsData.usersRatings[fsUserRef.id]) {
        setScoreLeft(ratingsData.usersRatings[fsUserRef.id].score_a?.toString() || undefined)
        setScoreRight(ratingsData.usersRatings[fsUserRef.id].score_b?.toString() || undefined)
        setOptionsLeft(ratingsData.usersRatings[fsUserRef.id].tags_a)
        setOptionsRight(ratingsData.usersRatings[fsUserRef.id].tags_b)
        setTranslationLeft(ratingsData.translate_a)
        setTranslationRight(ratingsData.translate_b)
      } else {
        cleanState()
      }
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
      cleanState()
    }
  }

  const handleChangeSeeNotRatedCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      seeAbTestNmtPhrases()
    }
    setLastAbTestPhraseData({
      scoreLeft: scoreLeft || undefined,
      scoreRight: scoreRight || undefined,
      optionsLeft: optionsLeft || [],
      optionsRight: optionsRight || [],
      translationLeft: translationLeft || [],
      translationRight: translationRight || [],
    })
    setSeeNotRatedPhrases(event.target.checked)
  }

  const updateTags = async (word: string) => {
    try {
      const tagsRef = ref(db, `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/abTestNmt/tags`)
      const _tags = [...new Set([...tags, word])]
      await set(tagsRef, _tags)
      setTags(_tags)
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const checkHasAbTest = async () => {
    try {
      if (!abTestNmtRT || currentBatch == undefined) return
      let notRatedLength = 0

      let versionString = ''
      if (!abTestNmtRT?.version_b) {
        versionString = abTestNmtRT?.version_a
        setOneSideMode(true)
      } else {
        versionString = abTestNmtRT?.version_a + abTestNmtRT?.version_b
        setOneSideMode(false)
      }
      const abTestNmtId = md5(versionString)

      const abTestNmtRef = getDocumentReference(fsUser.workspace, 'abTestNmt', abTestNmtId)

      const abTestNmtSnapshot = await getFirestoreDocument(abTestNmtRef)
      const abTestNmtData = abTestNmtSnapshot.data() as IAbTestNmt

      setAbTestNmtId(abTestNmtSnapshot.id)

      if (!abTestNmtData) {
        const _abTestNmt: IAbTestNmt = {
          createdAt: Timestamp.fromDate(new Date()),
          version_a: abTestNmtRT?.version_a || '',
          version_b: abTestNmtRT?.version_b || '',
          scope: abTestNmtRT.scope || '',
          totalSentences: abTestNmtRT.sentences[currentBatch].length || 0,
        }

        await createAbTestNmt(fsUser.workspace, abTestNmtId, _abTestNmt)
      }

      const ratingsQry = firestoreQuery(
        collection(firestore, 'workspaces', fsUser.workspace.id, 'abTestNmt', abTestNmtId, 'ratings'),
      )

      const ratingsSnapshot = await getDocs(ratingsQry)
      const ratingsDocs = ratingsSnapshot.docs
      const evaluateds: IEvaluated[] = []
      abTestNmtRT.sentences[currentBatch].forEach((sentence) => {
        const _md5Sentence = preProcess(sentence, fsUser?.workspace.id || '', false, false, true)
        ratingsDocs.forEach((rating) => {
          const _rating = rating.data() as IRatings
          if (rating.id == _md5Sentence)
            if (_rating.usersRatings[fsUserRef.id]) {
              evaluateds.push({
                ..._rating,
                ..._rating.usersRatings[fsUserRef.id],
              })
              if (_rating.usersRatings[fsUserRef.id].skipped == true) {
                notRatedLength = notRatedLength + 1
              }
            }
        })
      })

      if (evaluateds.length > 0) {
        const recentEvaluated: IEvaluated = evaluateds[evaluateds.length - 1]
        let sentenceIndex: number = 0

        if (recentEvaluated) sentenceIndex = abTestNmtRT.sentences[currentBatch].indexOf(recentEvaluated.sentence) + 1

        if (sentenceIndex >= abTestNmtRT?.sentences[currentBatch].length) {
          setCurrentPos(sentenceIndex - 1)
          setCurrentSentence(abTestNmtRT?.sentences[currentBatch][sentenceIndex - 1])
          const ratingsRef = getDocumentReference(
            abTestNmtRef,
            'ratings',
            preProcess(
              abTestNmtRT?.sentences[currentBatch][sentenceIndex - 1],
              fsUser?.workspace.id || '',
              false,
              false,
              true,
            ),
          )
          const ratingsData = (await getFirestoreDocument(ratingsRef)).data() as IRatings
          setScoreLeft(ratingsData.usersRatings[fsUserRef.id].score_a?.toString() || undefined)
          setScoreRight(ratingsData.usersRatings[fsUserRef.id].score_b?.toString() || undefined)
          setOptionsLeft(ratingsData.usersRatings[fsUserRef.id].tags_a)
          setOptionsRight(ratingsData.usersRatings[fsUserRef.id].tags_b)
          setTranslationLeft(ratingsData.translate_a)
          setTranslationRight(ratingsData.translate_b)
          if (notRatedLength > 0) {
            showSnackbar(intl.get('pages.abTestNmt.validateRemainingPhrases'), {
              variant: 'info',
            })
            setTestFinished(true)
          } else {
            setOpenCongrulationsDialog(!openCongrulationDialog)
          }
        } else {
          setCurrentPos(recentEvaluated ? sentenceIndex : 0)
          setCurrentSentence(abTestNmtRT?.sentences[currentBatch][recentEvaluated ? sentenceIndex : 0])
        }
        setSkippedPhrases(notRatedLength)
      } else {
        const _currentSentence = abTestNmtRT.sentences[currentBatch][0]
        setCurrentSentence(_currentSentence)

        const _md5Sentence = preProcess(_currentSentence, fsUser?.workspace.id || '', false, false, true)

        let _translationsA: string[] = []
        let _translationsB: string[] = []

        if (
          abTestNmtRT.translations[abTestNmtRT?.version_a] &&
          abTestNmtRT.translations[abTestNmtRT?.version_a][_md5Sentence]
        )
          _translationsA = abTestNmtRT.translations[abTestNmtRT?.version_a][_md5Sentence].htaAnimations

        if (!oneSideMode) {
          if (
            abTestNmtRT.translations[abTestNmtRT?.version_b] &&
            abTestNmtRT.translations[abTestNmtRT?.version_b][_md5Sentence]
          )
            _translationsB = abTestNmtRT.translations[abTestNmtRT?.version_b][_md5Sentence].htaAnimations
        }
        setTranslationLeft(_translationsA)
        setTranslationRight(_translationsB)
      }
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const getSigns = async () => {
    try {
      const md5Sentence = preProcess(currentSentence, fsUser.workspace.id, false, false, true)

      const sentencesListLeft = abTestNmtRT?.translations[abTestNmtRT.version_a][md5Sentence]

      const _signsLeft = sentencesListLeft?.signs || []
      const _translationLeft = sentencesListLeft?.translate?.split(' ') || []
      const _animationsLeft = sentencesListLeft?.htaAnimations || []

      setSignsLeft(_signsLeft)

      setTranslationLeft(_translationLeft)

      setCurrentAnimationsLeft(_animationsLeft)

      if (!oneSideMode) {
        const sentencesListRight = abTestNmtRT?.translations[abTestNmtRT.version_b][md5Sentence]
        const _signsRight = sentencesListRight?.signs || []
        const _translationRight = sentencesListRight?.translate.split(' ') || []
        const _animationsRight = sentencesListRight?.htaAnimations || []
        setSignsRight(_signsRight)
        setTranslationRight(_translationRight)
        setCurrentAnimationsRight(_animationsRight)
      }
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const seeAbTestNmtPhrases = async () => {
    setCurrentPos(lastAbTestPos)
    setCurrentSentence(lastAbTestSentence)
    setSeeNotRatedPhrases(false)
    setScoreLeft(lastAbTestPhraseData.scoreLeft)
    setScoreRight(lastAbTestPhraseData.scoreRight)
    setOptionsLeft(lastAbTestPhraseData.optionsLeft)
    setOptionsRight(lastAbTestPhraseData.optionsRight)
    setTranslationLeft(lastAbTestPhraseData.translationLeft)
    setTranslationRight(lastAbTestPhraseData.translationRight)
    const skippedPhrasesString = localStorage.getItem('abTestSkippedPhrasesId')
    const skippedPhrasesArray: string[] = (skippedPhrasesString && JSON.parse(skippedPhrasesString)) || []
    if (skippedPhrasesArray.filter((el) => el != '').length == 0) {
      localStorage.removeItem('abTestSkippedPhrasesId')
    } else {
      localStorage.setItem('abTestSkippedPhrasesId', JSON.stringify(skippedPhrasesArray.filter((el) => el != '')))
    }
  }

  useEffect(() => {
    const call = async () => {
      const userAuth = await fbAuth.currentUser?.getIdTokenResult()
      setPermissions(userAuth?.claims?.permissions as IWorkspacePermissions)

      if (currentBatch == undefined) {
        const sentencesUrl = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/abTestNmt`
        const reference = query(ref(db, sentencesUrl))
        const _abTestNmtRT: IAbTestNmtRT = (await get(reference)).val()
        if (!_abTestNmtRT) {
          setTestClosed(true)
          return
        }
        setAbTestNmtRT(_abTestNmtRT)
      }
    }
    call()

    // getSentences()
    // setOpenTableLeft(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!currentBatch && location.includes('batch')) {
      const _currentBatch = Number(location.slice(location.indexOf('batch') + 5))

      setCurrentBatch(_currentBatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (abTestNmtRT && currentBatch != undefined && currentBatch >= 0) {
      if (!routeParams || routeParams.version == ':version') {
        let versionString = ''
        if (abTestNmtRT?.version_b)
          versionString = abTestNmtRT?.version_a + '_' + abTestNmtRT?.version_b + '_batch' + currentBatch?.toString()
        else versionString = abTestNmtRT?.version_a + '_batch' + currentBatch?.toString()
        setLocation(routes.abTestNmt.replace(':version', versionString))
      }
      checkHasAbTest()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestNmtRT, currentBatch])

  useEffect(() => {
    setIsLoading(true)
    if (currentSentence && currentSentence !== '') {
      getSigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSentence])

  useEffect(() => {
    const _validated = (scoreLeft != undefined && scoreRight != undefined) || (oneSideMode && scoreLeft != undefined)
    setValidated(_validated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreLeft, scoreRight])

  useEffect(() => {
    const call = async () => {
      await activate(fbRemoteConfig)

      if (getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
        nmtConfig.current = JSON.parse(getValue(fbRemoteConfig, 'HandTalk_NMT_Suggestion').asString())
    }
    call()
  }, [fbRemoteConfig])

  useEffect(() => {
    if (seeNotRatedPhrases) {
      setLastAbTestPos(currentPos)
      setLastAbTestSentence(currentSentence)
      setSkippedPhrasesLength(skippedPhrases)
      setCurrentPos(0)
      cleanState()
      const skippedPhrasesString = localStorage.getItem('abTestSkippedPhrasesId')
      const skippedPhrasesArray = skippedPhrasesString && JSON.parse(skippedPhrasesString)

      const getNotRatedPhrases = async () => {
        const abTestNmtRef = getDocumentReference(fsUser.workspace, 'abTestNmt', abTestNmtId)

        const ratingsRef = getDocumentReference(
          abTestNmtRef,
          'ratings',
          skippedPhrasesArray[currentPos == 0 ? currentPos : 0],
        )
        const ratingsData = (await getFirestoreDocument(ratingsRef)).data() as IRatings

        setCurrentSentence(ratingsData.sentence)
      }

      getNotRatedPhrases()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeNotRatedPhrases])

  const BackNextButtons = () => {
    return (
      <>
        <Fab
          aria-label="Back"
          style={{
            position: 'absolute',
            alignSelf: 'flex-start',
            bottom: '50%',
          }}
          color="primary"
          onClick={handleClickBack}
          disabled={currentPos == 0}
        >
          <ArrowBackIcon />
        </Fab>
        <Fab
          aria-label="Next"
          style={{
            position: 'absolute',
            alignSelf: 'flex-end',
            bottom: '50%',
          }}
          color="primary"
          onClick={handleClickNext}
          disabled={isLoading}
        >
          <ArrowForwardIcon />
        </Fab>
      </>
    )
  }

  if (testClosed) {
    return (
      <Layout title={intl.get('modules.abTestNmt')}>
        {permissions &&
          permissions['abTestNmtResults'] &&
          permissions['abTestNmtResults'][fsUser?.workspace.id] &&
          permissions['abTestNmtResults'][fsUser?.workspace.id] == 1 && (
            <Box
              style={{
                width: '90%',
                marginTop: '10px',
                marginRight: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                aria-label="Results"
                onClick={() => {
                  setLocation(routes.abTestNmtResults)
                }}
              >
                {intl.get('pages.abTestNmt.seeResults')}
              </Button>
            </Box>
          )}
        <Box style={{ position: 'absolute', width: '15%' }}>
          <CardMessage title={intl.get('pages.abTestNmt.hasNoTest')} />
        </Box>
      </Layout>
    )
  }

  const handleChooseBatch = (index: number) => {
    setCurrentBatch(index)
    let url = abTestNmtRT?.version_a
    if (abTestNmtRT && abTestNmtRT?.version_b) url = url + '_' + abTestNmtRT?.version_b + '_batch' + index.toString()
    else url = url + '_batch' + index.toString()

    setLocation(routes.abTestNmt.replace(':version', url))
  }

  const chooseBatch = () => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">{intl.get('pages.abTestNmt.currentTest')}</Typography>
            <Typography>
              {intl.get('pages.abTestNmt.version')} A: {abTestNmtRT?.version_a}
            </Typography>
            <Typography>
              {intl.get('pages.abTestNmt.version')} B: {abTestNmtRT?.version_b}
            </Typography>
            <Typography>
              {intl.get('pages.abTestNmt.scope')}: {abTestNmtRT?.scope}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {abTestNmtRT?.sentences.map((el, index) => (
              <Button
                key={index}
                variant="contained"
                color="primary"
                style={{ margin: '5px 0px', textTransform: 'none' }}
                onClick={() => handleChooseBatch(index)}
              >
                Batch: {index}
              </Button>
            ))}
          </Box>
        </Grid>
      </Box>
    )
  }
  // Usuário não tem papel de escritor
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.abTestNmt')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.abTestNmt')}>
      {currentBatch == undefined && chooseBatch()}
      {routeParams && routeParams.version != ':version' && currentBatch != undefined && (
        <>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                width: '90%',
                marginTop: '10px',
                marginRight: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '20px',
              }}
            >
              <Typography>{`${intl.get('pages.abTestNmt.notRatedPhrases')}: ${
                !isLoading ? skippedPhrases : ''
              }`}</Typography>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={skippedPhrases == 0}
                  checked={seeNotRatedPhrases}
                  onChange={handleChangeSeeNotRatedCheck}
                  color="primary"
                />
                <Typography>{`${intl.get('pages.abTestNmt.seeNotRatedPhrases')}`}</Typography>
              </Box>
              {permissions &&
                permissions['abTestNmtResults'] &&
                permissions['abTestNmtResults'][fsUser?.workspace.id] &&
                permissions['abTestNmtResults'][fsUser?.workspace.id] == 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="Results"
                    onClick={() => {
                      setLocation(routes.abTestNmtResults)
                    }}
                  >
                    {intl.get('pages.abTestNmt.seeResults')}
                  </Button>
                )}
            </Box>

            <Box
              style={{
                width: '80%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="h4"
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {currentSentence}
              </Typography>
              <Box
                style={{
                  marginTop: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <CardAnimation
                  idElement="HTCoreElementLeft"
                  currentAnimations={currentAnimationsLeft}
                  score={scoreLeft || ''}
                  setScore={setScoreLeft}
                  addWordText={addWordTextLeft}
                  tags={tags || []}
                  options={optionsLeft}
                  handleChangeOptions={handleChangeOptionsLeft}
                  handleKeyPress={handleKeyPressLeft}
                  handleChangeTextField={handleChangeTextFieldLeft}
                  handleClickOpenSigns={handleClickOpenSignsLeft}
                  openTable={openTableLeft}
                  signs={signsLeft}
                  handleClickPlaySign={handleClickPlaySignLeft}
                  isPlaying={isPlayingLeft}
                  setIsPlaying={setIsPlayingLeft}
                  autoPlay={autoPlayLeft}
                  signId={signIdLeft}
                  setSignId={setSignIdLeft}
                  isLoading={isLoading}
                />
                <Box
                  style={{
                    width: '10%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  <Typography>
                    {seeNotRatedPhrases
                      ? `${currentPos + 1}/${skippedPhrasesLength}`
                      : `${currentPos + 1}/${
                          abTestNmtRT?.sentences[currentBatch || 0].length
                            ? abTestNmtRT?.sentences[currentBatch || 0].length
                            : 1
                        }`}
                  </Typography>
                </Box>
                {!oneSideMode && (
                  <CardAnimation
                    idElement="HTCoreElementRight"
                    currentAnimations={currentAnimationsRight}
                    score={scoreRight || ''}
                    setScore={setScoreRight}
                    addWordText={addWordTextRight}
                    tags={tags || []}
                    options={optionsRight}
                    handleChangeOptions={handleChangeOptionsRight}
                    handleKeyPress={handleKeyPressRight}
                    handleChangeTextField={handleChangeTextFieldRight}
                    isPlaying={isPlayingRight}
                    setIsPlaying={setIsPlayingRight}
                    autoPlay={autoPlayRight}
                    handleClickOpenSigns={handleClickOpenSignsRight}
                    openTable={openTableRight}
                    signs={signsRight}
                    handleClickPlaySign={handleClickPlaySignRight}
                    signId={signIdRight}
                    setSignId={setSignIdRight}
                    isLoading={isLoading}
                  />
                )}
              </Box>
              <BackNextButtons />
            </Box>
          </Box>
          <CongrulationsDialog open={openCongrulationDialog} toggleOpenCloseDialog={toggleOpenCongrulationDialog} />
          <ConfirmationDialog
            open={openConfirmationDialog}
            toggleOpen={() => setOpenConfirmationDialog(false)}
            handleYes={() => {
              handleSkipPhrase()
            }}
            title={intl.get('pages.abTestNmt.phraseNotValidated')}
            subtitle={intl.get('pages.abTestNmt.skipPhraseConfirmation')}
          />
          <Dialog
            open={openFinishedTestDialog}
            onClose={() => {
              setLocation('/')
            }}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>{intl.get('pages.abTestNmt.endOfTest')}</DialogTitle>
            <DialogContent>
              <Typography>{intl.get('pages.abTestNmt.remainingPhrases')}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenFinishedTestDialog(false)
                  setLocation('/')
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Layout>
  )
}

export default AbTestNmt
