import { FC } from 'react'
import useIntl from '../../hooks/useIntl'
import BottomBarPure from './Pure'

interface IBottomBarProps {
  prevEnabled?: boolean
  nextEnabled?: boolean
  prevCallback?: () => void
  nextCallback?: () => void
}

const BottomBar: FC<IBottomBarProps> = ({ prevEnabled, nextEnabled, prevCallback, nextCallback }) => {
  const intl = useIntl()
  const textBack = intl.get('messages.back')
  const textNext = intl.get('messages.next')
  return (
    <BottomBarPure
      prevEnabled={prevEnabled}
      nextEnabled={nextEnabled}
      prevCallback={prevCallback}
      nextCallback={nextCallback}
      textBack={textBack}
      textNext={textNext}
    />
  )
}
export default BottomBar
