import React from 'react'
import SignsListPure from './Pure'
import { useLocation } from 'wouter'
import { ISign, DocumentReference, Timestamp } from 'collections'
import useIntl from '../../../hooks/useIntl'

interface ISignWithId extends ISign {
  id: string
  ref: DocumentReference
  animator: string
  animatorCurrentAnimation: string
  animationCreatedAt: string | Timestamp
}

interface ISignsListProps {
  /** Lista dos sinais */
  signsData: ISignWithId[] | null
}

/**
 * Componente da listagem de sinais
 */
const SignsList: React.FC<ISignsListProps> = ({ signsData }) => {
  const [, setLocation] = useLocation()

  const intl = useIntl()
  const textSign = intl.get('messages.sign').toUpperCase()
  const textStars = intl.get('messages.stars').toUpperCase()
  const textStatus = intl.get('messages.status').toUpperCase()
  const textAnimator = intl.get('messages.animator').toUpperCase()
  const textDate = intl.get('messages.date').toUpperCase()
  const textPopularity = intl.get('messages.popularity').toUpperCase()
  const textPending = intl.get('pages.animation.pending')
  const textHasErrors = intl.get('pages.animation.hasErrors')
  const textDone = intl.get('pages.animation.done')
  const textAnimating = intl.get('pages.animation.animating')
  const textUnreviewed = intl.get('pages.animation.unreviewed')
  const textFingerspell = intl.get('messages.fingerspell')
  const textNumberOfSegments = intl.get('messages.numberOfSegments')
  const textReportDate = intl.get('messages.reportDate')

  const onSelectSign = async (rowIndex: number) => {
    if (signsData) {
      const signData = signsData[rowIndex]
      setLocation(`/animation/${signData.id || ''}`)
    }
  }

  return (
    <SignsListPure
      textSign={textSign}
      textStars={textStars}
      textStatus={textStatus}
      textAnimator={textAnimator}
      textDate={textDate}
      textPopularity={textPopularity}
      textPending={textPending}
      textHasErrors={textHasErrors}
      textDone={textDone}
      textAnimating={textAnimating}
      textUnreviewed={textUnreviewed}
      textFingerspell={textFingerspell}
      data={signsData || []}
      onSignClick={onSelectSign}
      textNumberOfSegments={textNumberOfSegments}
      textReportDate={textReportDate}
    />
  )
}

export default SignsList
