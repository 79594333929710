import DrawerPure from './Pure'

import React from 'react'
import { IDrawerProps } from 'collections'

const Drawer: React.FC<IDrawerProps> = ({ selectedDrawer, setSelectedDrawer }) => {
  return <DrawerPure selectedDrawer={selectedDrawer} setSelectedDrawer={setSelectedDrawer} />
}

export default Drawer
