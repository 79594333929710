import intl from 'react-intl-universal'
import { OralLanguageId } from 'collections'

const locales = {
  por: require('../lang/ptBr.json'),
  eng: require('../lang/enUs.json'),
  enb: require('../lang/enUs.json'),
}

interface ISentenceStates {
  isLoading?: boolean
  sentence?: string
  origin?: string
}

interface IVariables {
  contributorName?: string
  sentence?: string
  sentenceState?: ISentenceStates
  variant?: string
  workspace?: string
  feedback?: string
  glosa?: string
  minTagFramesDuration?: number
  number?: number
  response?: string
  from?: number
  to?: number
  field?: string
  inProgressContributions?: number
  length?: number
  percentage?: number
  queue?: string
}

interface IIntlNoAuth {
  get: (key: string, variables?: IVariables) => string
}

const useIntlNoAuth = (defaultLang: OralLanguageId): IIntlNoAuth => {
  const getInternationalization = (key: string, variables?: IVariables) => {
    if (variables) return intl.get(key, variables).defaultMessage('Not implemented language')
    return intl.get(key).defaultMessage('Not implemented language')
  }
  const internationalization: IIntlNoAuth = {
    get: getInternationalization,
  }

  const currentLocale = locales[defaultLang] ? defaultLang : OralLanguageId.eng

  intl.init({
    currentLocale,
    locales,
  })

  return internationalization
}

export default useIntlNoAuth
