import { DocumentReference, DocumentReferenceGeneric, TCorpusGroup, Timestamp } from '.'

export interface ISignOnDemand {
  id?: string
  glosa?: string | null
  portuguese?: string[] | null
  lastUpdate?: Timestamp | null
  createdAt?: Timestamp
  hta?: string | null
  status?: StatusSignOnDemand
  videoRef?: DocumentReferenceGeneric | null
  segmentRef?: DocumentReference | null
  error?: ErrorSignOnDemand | null
  isSegmenting?: {
    lastUpdate: Timestamp
    user: DocumentReference
    value: boolean
  } | null
  isRecording?: {
    lastUpdate: Timestamp
    user: DocumentReference
    value: boolean
  } | null
  /** Reference of the sign */
  searchReference?: string
  /** Description of the sign */
  description?: string
  /** Part of a sign */
  pieceOfSign?: boolean
  /** Origin of sentence */
  sentenceOrigin?: string
  /** Corpus Group */
  corpusGroup?: TCorpusGroup
  /** Indicates the category of the sentence */
  sentenceCategory?: string[] | null
  /** Indicates the client of the sentence */
  clientId?: string
  /** Indicates the region of use */
  regionOfUse?: string[]
  /** Referencia do segmento que servirá como base para gravação */
  segmentToView?: DocumentReferenceGeneric | null

  searchTerms?: string[]
}

export enum StatusSignOnDemand {
  recordedVideo = 'recordedVideo',
  segmentedVideo = 'segmentedVideo',
  pending = 'pending',
  request = 'request',
}

export enum ErrorSignOnDemand {
  noError = 'noError',
  compositeSign = 'compositeSign',
  outdatedSign = 'outdatedSign',
  animationError = 'animationError',
  unknownSign = 'unknownSign',
  translationError = 'translationError',
}
