import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TranslateIcon from '@material-ui/icons/Translate'
import ErrorIcon from '@material-ui/icons/Error'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { ValidateActions } from 'collections'

interface IValidateTranslationActionsProps {
  /** Callback para valição realizada */
  onValidate?: (action: ValidateActions) => void
  /** Bloqueia os botões */
  lockButtons?: boolean
  /** Texto do botão incorreto */
  incorrectText: string
  /** Texto do botão correto */
  correctText: string
  /** Texto do botão fora de contexto */
  outOfContextText: string
  /** Texto do botão faltando sinal */
  missingSignText: string
}

/**
 * Componente com os botões de validação de uma determinada tradução
 */
const ValidateTranslationActionsPure: React.FC<IValidateTranslationActionsProps> = ({
  onValidate,
  lockButtons,
  incorrectText,
  correctText,
  outOfContextText,
  missingSignText,
}) => {
  const [elemAnchor, setElemAnchor] = useState<HTMLElement | null>(null)

  const handleOnValidate = (action: ValidateActions) => {
    if (onValidate) onValidate(action)
    setElemAnchor(null)
  }

  const handleIncorrectButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setElemAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setElemAnchor(null)
  }

  useEffect(() => {
    if (lockButtons) {
      setElemAnchor(null)
    }
  }, [lockButtons])

  return (
    <>
      <Box mr={2}>
        <Box width="100%" display="flex" alignContent="center" justifyContent="center" mb={2}>
          <Box mr={2}>
            <Button
              disabled={lockButtons}
              onClick={handleIncorrectButtonClick}
              variant="contained"
              color="secondary"
              endIcon={<ExpandMoreIcon />}
            >
              {incorrectText}
            </Button>
          </Box>
          <Button
            disabled={lockButtons}
            color="primary"
            variant="contained"
            endIcon={<DoneIcon />}
            onClick={() => handleOnValidate('correct')}
          >
            {correctText}
          </Button>
        </Box>
      </Box>

      <Menu open={!!elemAnchor} onClose={handleMenuClose} anchorEl={elemAnchor}>
        <List>
          <ListItem button color="secondary" onClick={() => handleOnValidate('outOfContext')}>
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText>{outOfContextText}</ListItemText>
          </ListItem>
          <ListItem button onClick={() => handleOnValidate('missingSigns')}>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            <ListItemText>{missingSignText}</ListItemText>
          </ListItem>
        </List>
      </Menu>
    </>
  )
}
export default ValidateTranslationActionsPure
