import { IGetNewStateVideo, IUpdateStateVideo, IVideo, IVideoFlags, VideoId } from './types/video'
import {
  CollectionReference,
  deleteFirestoreDocument,
  deleteStorageFile,
  DocumentReference,
  DocumentReferenceGeneric,
  FirestoreGeneric,
  getPathFramesJson,
  getPathVideoCroppedFile,
  getPathVideoFile,
  getPathVideoKeypointsFile,
  IBatchOperation,
  IEntity,
  ISegment,
  IUser,
  runBatchOperationsFirestore,
  StorageGeneric,
  UserId,
} from '.'
import {
  addDoc,
  updateDoc,
  getDoc,
  Timestamp,
  serverTimestamp,
  QueryDocumentSnapshot,
  query,
  DocumentData,
  Query,
  getDocs,
  orderBy,
  limit,
  where,
} from '@firebase/firestore'
import { errorFsDocNotFound } from './services/errors'
import {
  getCollectionReference,
  getDocumentReference,
  getFirestoreDocument,
  getFirestoreDocumentById,
  setFirestoreDocument,
  updateFirestoreDocument,
} from './Base'
import { preProcess, preProcessSentenceToRecord } from './Phrases'

const createUpdateFunction = async (ref: DocumentReference, videoData: Partial<IVideo>) => {
  return await updateDoc(ref, videoData)
}

export const updateVideo = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, videoData: Partial<IVideo>) => {
  return await addDoc(ref, videoData)
}

export const addVideo = createAddFunction

const createSetFunction = async (ref: DocumentReferenceGeneric, videoData: Partial<IVideo>) => {
  return await updateFirestoreDocument(ref, videoData)
}

export const setVideo = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getVideo = createGetFunction

const initialVideoFlags: IVideoFlags = {
  isExtractedFrames: false,
  isMidiaUploaded: false,
  isExtractingFrames: {
    value: false,
    lastUpdate: serverTimestamp() as Timestamp,
    taskName: null,
  },
  isMidiaUploading: {
    value: true,
    lastUpdate: serverTimestamp() as Timestamp,
  },
  isTagging: {
    value: false,
    lastUpdate: null,
    user: null,
  },
  isSegmenting: {
    value: false,
    lastUpdate: null,
    user: null,
  },
  errors: null,
  _state: 'RECORDED',
  madeByProcessModule: false,
  sentenceCategory: ['undefined'],
  searchTerms: '',
  sentenceHash: '',
  translationReferences: [],
  revision: null,
}

/**
 * Cria um novo vídeo no firestore
 * @param fsUser Usuário logado do firestore que está criando o video
 * @param fsUserRef Referencia do usuário que está criando o video
 * @param id Id do vídeo que iremos salvar
 * @param data Dados do vídeo
 */
export const createVideo = async (
  fsUser: IUser,
  fsUserRef: DocumentReference,
  id: VideoId,
  data: Pick<
    IVideo,
    | 'sentence'
    | 'duration'
    | 'midia'
    | 'sentenceOrigin'
    | 'createdOnDemand'
    | 'sentenceCategory'
    | 'corpusGroup'
    | 'clientId'
    | 'isExternal'
  >,
) => {
  const video: IVideo = {
    ...initialVideoFlags,
    createdAt: serverTimestamp() as Timestamp,
    createdBy: fsUserRef,
    oralLanguageId: fsUser.oralLanguageId,
    signLanguageId: fsUser.signLanguageId,
    sentence: data.sentence.normalize('NFKC'),
    duration: data.duration,
    midia: data.midia,
    sentenceHash: preProcessSentenceToRecord(data.sentence, fsUser.workspace.id),
    numberOfTags: 0,
    taggedBy: [],
    numberOfSkipsByTags: 0,
    tagsSkippedBy: [],
    segmentedBy: [],
    numberOfSegments: 0,
    errors: null,
    keypointsExtracted: false,
    sentenceOrigin: data.sentenceOrigin || 'APP',
    paralelSentenceReported: false,
    sentenceCategory: data.sentenceCategory || ['undefined'],
    usedOnTrain: false,
    signs: [],
    createdOnDemand: data.createdOnDemand || false,
    isExtractKeypointsFromJson: false,
    segmentsToProcess: null,
    corpusGroup: data.corpusGroup || 'TRAIN',
    numberOfSegmentations: 0,
    _state: 'RECORDED',
    madeByProcessModule: false,
    clientId: data.clientId,
    isProcessingVideo: null,
    needCreateSign: false,
    duplicateOf: null,
    isExternal: data.isExternal || false,
  }

  return setFirestoreDocument(getDocumentReference(fsUser.workspace, 'videos', id), video)
}

/**
 * Atualiza a lista de flags de um vídeo
 * @param workspaceRef Referencia do workspace onde o video se encontra
 * @param videoId Id do vídeo que iremos atualizar
 * @param flags Flags que iremos atualizar
 */
export const updateVideoFlags = (videoRef: DocumentReferenceGeneric, flags: Partial<IVideoFlags>) => {
  return updateFirestoreDocument(videoRef, flags)
}

/**
 * Atualiza a lista de flags de um vídeo
 * @param workspaceRef Referencia do workspace onde o video se encontra
 * @param videoId Id do vídeo que iremos atualizar
 * @param flags Flags que iremos atualizar
 */
export const updateVideoFlagsById = (
  workspaceRef: DocumentReferenceGeneric,
  videoId: string,
  flags: Partial<IVideoFlags>,
) => {
  updateFirestoreDocument(getDocumentReference(workspaceRef, 'videos', videoId), flags)
}

/**
 * Pega um video do firestore
 * @param workspaceRef Referencia do workspace onde o video se encontra
 * @param videoId Id do video que iremos pegar
 */
export const getVideoById = async (workspaceRef: DocumentReferenceGeneric, videoId: VideoId): Promise<IVideo> => {
  const snapshot = await getFirestoreDocumentById(workspaceRef, 'videos', videoId)
  const data = snapshot.data()

  if (!data) throw errorFsDocNotFound

  return data as IVideo
}

/**
 * Atualiza um video existente no firestore
 * @param workspaceRef - Workspace onde o video se encontra
 * @param videoId Id do vídeo que iremos atualizar
 * @param update Dados de atualização do vídeo
 */
export const updateVideoDocument = async (
  videoRef: DocumentReferenceGeneric,
  update: Partial<Omit<IVideo, keyof IEntity>>,
) => {
  return updateFirestoreDocument(videoRef, update)
}

/**
 * Pula um vídeo adicionando +1 na quantidade de skips e adiciona o usuário na lista de videos pulados
 * @param videoId Id do vídeo que será pulado
 * @param userId Referencia do usuário que pulou o video
 * @param workspace Workspace onde o vídeo se encontra
 */
export const skipVideo = async (
  videoId: string,
  userRef: DocumentReferenceGeneric,
  workspaceRef: DocumentReferenceGeneric,
) => {
  // Prepara a referencia do video
  const videoRef = getDocumentReference(workspaceRef, 'videos', videoId)

  // Pega os valores salvos
  const videosSnapshot = await getFirestoreDocument(videoRef)
  let { tagsSkippedBy, numberOfSkipsByTags } = videosSnapshot.data() as IVideo

  // Atualiza a lista de vídeos pulados
  tagsSkippedBy = [...tagsSkippedBy, userRef]
  numberOfSkipsByTags = tagsSkippedBy.length

  // Salva apenas os dados modificados
  await updateFirestoreDocument(videoRef, {
    tagsSkippedBy,
    numberOfSkipsByTags,
  })
}

/**
 * Retorna uma lista de (numberOfVideos) vídeos não etiquetados pelo usuário. A
 * lista é aleatória com prioridade nos vídeos que possuem um menor número de
 * etiquetas realizadas.
 * @param user IUser do usuário.
 * @param userId Id do Usuário. Necessário para que sejam filtrados os vídeos já etiquetados por ele.
 * @param numberOfVideos Quantos vídeos a retornar. Padrão 10.
 */
export const getUntaggedVideos = async (user: IUser, userId: UserId, numberOfVideos = 10) => {
  const { workspace, oralLanguageId, signLanguageId } = user
  // Trava de segurança pra limitar o número de execuções

  // Essa função pega uma lista de {pageSize} dos itens ordenados pelo número de etiquetas
  const getNextPageSnaps = async (
    numberOfTags: number,
    previousResults: QueryDocumentSnapshot[],
  ): Promise<QueryDocumentSnapshot[]> => {
    // THADEU: não está funcionando, pois não existe nenhum video com numberOfTags
    // Outra opção é ao invés de usar endAt, usar o startAfter(snap) com o snapshot do último documento dos resultados.

    console.log(`Pegando vídeos com ${numberOfTags} tag(s).`)

    const newQuery = query(
      getCollectionReference(workspace, 'videos') as Query<DocumentData>,
      where('status', '==', 'untagged'),
      where('oralLanguageId', '==', oralLanguageId),
      where('signLanguageId', '==', signLanguageId),
      where('midiaReady', '==', true),
      where('framesReady', '==', true),
      where('numberOfTags', '==', numberOfTags),
    )

    const results = await getDocs(newQuery)

    const filteredResults = results.docs
      // Remove vídeos já etiquetado ou pulado por esta pessoa
      .filter((snapshot) => {
        const video = snapshot.data() as IVideo
        const taggedBy = video.taggedBy || []
        const tagsSkippedBy = video.tagsSkippedBy || []
        const ids = [...taggedBy, ...tagsSkippedBy].map((ref) => ref.id)
        return !ids.includes(userId)
      })

    if (numberOfTags !== 0) {
      console.log(
        `Vídeos dos ${results.docs.length} o usuário já havia tageado ${results.docs.length - filteredResults.length}`,
      )
    }

    const validResults = [...previousResults, ...filteredResults]

    // Já temos um número de vídeos suficiente
    if (validResults.length >= numberOfVideos) {
      console.log(
        `Encontramos um número de vídeos válidos suficiente com ${numberOfTags} ou menos tags.`,
        `Achamos ${validResults.length} de ${numberOfVideos}.`,
      )
      return validResults
    }

    // Se o número de tags for maior 1ue 5 e não tiver resultado, considere que acabaram-se os vídeos
    if (results.docs.length === 0 && numberOfTags > 5) {
      console.log(
        `Não encontramos um número de vídeos válidos suficiente com ${numberOfTags} ou menos tags.`,
        `Achamos ${validResults.length} de ${numberOfVideos}.`,
      )
      return validResults
    }

    // Se nenhuma das condições foi atingida, procure na próxima página com um número de tags a mais
    const newResults = await getNextPageSnaps(numberOfTags + 1, validResults)
    return newResults
  }

  // Fazemos a primeira busca por vídeos com 0 tags.
  const snaps = await getNextPageSnaps(0, [])

  const videos: Record<string, IVideo> = {}
  // Pega (numberOfVideos) elementos aleatórios dentre os válidos
  snaps
    // Embaralha o array
    .sort(() => 0.5 - Math.random())
    // Pega os primeiros (numberOfVideos) elementos
    .slice(0, numberOfVideos)
    // Salva no dicionário.
    .forEach((snap) => {
      videos[snap.id] = snap.data() as IVideo
    })

  console.log(`Retornando ${Object.keys(videos).length} vídeos.`)
  return videos
}

/**
 * Retorna a referência para um video
 * @param workspaceRef Referência para um workspace
 * @param videoId String do id do vídeo
 */
export const getVideoRef = (workspaceRef: DocumentReferenceGeneric, videoId: string) =>
  getDocumentReference(workspaceRef, 'videos', videoId)

/**
 * Pega um video aleatorio entre os 100 mais antigos
 * @param workspaceRef Referencia do workspace onde iremos pegar o video
 */
export const getRandomVideoRef = async (workspaceRef: DocumentReferenceGeneric) => {
  const videosRef = getCollectionReference(workspaceRef, 'videos') as Query
  const newQuery = query(
    videosRef,
    where('_state', '==', 'EXTRACTED_FRAMES'),
    where('isSegmenting.value', '==', false),
    where('sentenceOrigin', '!=', 'DEMAND'),
    where('numberOfSegmentations', '==', 0),
    where('createdOnDemand', '==', false),
    orderBy('sentenceOrigin'),
    orderBy('createdAt', 'desc'),
    limit(100),
  )

  // A busca ordenado pela data de criação dos vídeos, já que a criação de vídeos segue uma prioridade.
  const result = await getDocs(newQuery)

  const index = Math.floor(Math.random() * result.docs.length)

  return result.docs.length > 0 ? result.docs[index].ref : null
}

export const getNewVideoState = (data: IGetNewStateVideo) => {
  if (data.videoData && data.videoData.paralelSentenceReported) {
    // STATE = QUARANTINE
    return 'QUARANTINE'
  } else if (data.videoData && data.videoData.errors) {
    // STATE = WITH_ERROR
    return 'WITH_ERROR'
  } else if (
    data.videoData &&
    data.videoData.segmentsToProcess === 0 &&
    data.videoData.numberOfSegmentations > 0 &&
    data.videoData.madeByProcessModule
  ) {
    // STATE = VALIDATED
    return 'VALIDATED'
  } else if (data.videoData && data.videoData.segmentsToProcess === 0 && data.videoData.numberOfSegmentations > 0) {
    // STATE = PROCESSED
    return 'PROCESSED'
  } else if (data.videoData && data.videoData.numberOfSegmentations > 0) {
    // STATE = SEGMENTED
    return 'SEGMENTED'
  } else if (
    data.createdFramesJson ||
    data.videoData?.isExtractedFrames ||
    data.videoData?._state == 'EXTRACTED_FRAMES'
  ) {
    // STATE = EXTRACTED_FRAMES
    return 'EXTRACTED_FRAMES'
  } else if (data.createdKeypoints || data.videoData?.keypointsExtracted) {
    // STATE = EXTRACTED_KEYPOINTS
    return 'EXTRACTED_KEYPOINTS'
  } else {
    // STATE = RECORDED
    return 'RECORDED'
  }
}

export const updateStateVideo = async (data: IUpdateStateVideo) => {
  const newState = getNewVideoState(data)
  await updateVideoDocument(data.videoRef, { _state: newState })
}

export const generateSearchTermsVideo = (_sentence: string, workspaceId: string, md5: boolean = false) => {
  const sentence = preProcess(_sentence, workspaceId, true, true, md5, true)
  return sentence
}

export const updateSearchTermsVideo = (videoRef: DocumentReferenceGeneric, sentence: string, workspaceId: string) => {
  const searchTerms = generateSearchTermsVideo(sentence, workspaceId)
  return updateVideoDocument(videoRef, { searchTerms })
}

interface IPath {
  [pathName: string]: string
}

export const deleteVideo = async (
  storage: StorageGeneric,
  workspaceId: string,
  videoRef: DocumentReferenceGeneric,
  batch?: IBatchOperation[],
) => {
  const videoId = videoRef.id
  // Apagar vídeos do Storage

  const paths: IPath = {
    framesJsonPath: getPathFramesJson(workspaceId, videoId, ''),
    videoPath: getPathVideoFile(workspaceId, videoId),
    keypointsPath: getPathVideoKeypointsFile(workspaceId, videoId),
    videoCroppedPath: getPathVideoCroppedFile(workspaceId, videoId),
  }

  for (const key of Object.keys(paths)) {
    try {
      await deleteStorageFile(storage, paths[key])
    } catch (e) {
      const err = e as Error
      console.log('Falha ao excluir arquivo do storage', paths[key])
      console.log(err.message)
    }
  }

  // Apagar documento de vídeo
  if (batch) {
    batch.push({ ref: videoRef, op: 'delete' })
  } else {
    await deleteFirestoreDocument(videoRef)
  }
}

export const backVideoState = async (
  firestore: FirestoreGeneric,
  storageGeneric: StorageGeneric,
  workspaceId: string,
  videoRef: DocumentReferenceGeneric,
  videoData: IVideo,
  newState: 'RECORDED' | 'DELETED',
  segments?: ISegment[],
) => {
  try {
    console.log('VideoId:', videoRef.id)
    const batch: IBatchOperation[] = []
    if (
      ['EXTRACTED_FRAMES', 'EXTRACTED_KEYPOINTS', 'RECORDED', 'SEGMENTED', 'QUARANTINE', 'WITH_ERROR'].includes(
        videoData._state,
      )
    ) {
      if (segments && segments.length && (newState == 'DELETED' || newState == 'RECORDED')) {
        const _segments = [...segments]
        // Deleta os segmentos
        _segments.forEach((segment) => {
          if (segment.ref) {
            batch.push({
              op: 'delete',
              ref: segment.ref,
            })
          }
        })
        // Atualiza documento de vídeo caso não vá excluí-lo
        if (newState !== 'DELETED') {
          batch.push({
            op: 'update',
            ref: videoRef,
            data: {
              isProcessingVideo: null,
              isSegmenting: {
                value: false,
                lastUpdate: null,
                user: null,
              },
              needCreateSign: false,
              numberOfSegmentations: 0,
              numberOfSegments: 0,
              segmentedBy: [],
              segmentsToProcess: null,
              madeByProcessModule: false,
            },
          })
        }
      }
      if (newState == 'DELETED') await deleteVideo(storageGeneric, workspaceId, videoRef, batch)
    }
    if (batch.length > 0) await runBatchOperationsFirestore(firestore, batch)
  } catch (err) {
    const error = err as Error
    console.error(error.message)
  }
}
