import { FC, useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core'
import useIntl from '../../../../hooks/useIntl'
import AnimationView from '../../../../components/AnimationView'
import {
  IAnimation,
  ISignView,
  IUserInfo,
  DocumentReference,
  getCollectionReference,
  AnimationProblemsCode,
  IAnimationProblem,
  getDocumentReference,
  ISign,
  DocumentReferenceClient,
  WorkspaceId,
  serverTimestamp,
  Timestamp,
} from 'collections'
import { useFsUserDocData, useFsUserDocRef } from '../../../../hooks/useFsUser'
import { FileCopyRounded } from '@material-ui/icons'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { format } from 'date-fns'
import GetAppIcon from '@material-ui/icons/GetApp'
import styled from 'styled-components'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { addContribAnimationReviewFunction, getUsersByWorkspaceFunction } from '../../../../services/firebase'
import { useFirestore, useFirestoreDoc, useStorage } from 'reactfire'
import { DocumentData, getDocs, Query, query } from '@firebase/firestore'
import { runTransaction, where } from '@firebase/firestore'
import { getDownloadURL, ref } from '@firebase/storage'
import useSnackbar from '../../../../services/hooks/useSnackbar'
import useJobMeter from '../../../../services/hooks/useJobMeter'
import { routes } from '../../../../community'

export interface IApplicationDialogProps {
  opened: boolean
  primarySegment: ISignView | null
  toggleOpen: () => void
  signId: string | ''
}

const MainIcon = styled(CheckCircleIcon)`
  margin-right: 10px;
`

const AnimationRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }

  && {
    cursor: pointer;
    background-color: ${(props: { isselected: string }) => props.isselected === 'true' && '#2196f3'};
  }
`

export const AnimationViewDialog: FC<IApplicationDialogProps> = ({ opened, primarySegment, toggleOpen, signId }) => {
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const showSnackbar = useSnackbar()
  const { endJobMeter } = useJobMeter('hTube')
  const [animationProblems, setAnimationProblems] = useState<IAnimationProblem[]>([
    {
      id: AnimationProblemsCode.wrongFacialExpression,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongFacialExpression}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongHandShape,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongHandShape}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongLocation,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongLocation}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.wrongMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.wrongMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.floatyMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.floatyMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.tooSlow,
      description: intl.get(`animationProblems.${AnimationProblemsCode.tooSlow}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.standardPose,
      description: intl.get(`animationProblems.${AnimationProblemsCode.standardPose}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.unnecessaryFrames,
      description: intl.get(`animationProblems.${AnimationProblemsCode.unnecessaryFrames}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.tooFast,
      description: intl.get(`animationProblems.${AnimationProblemsCode.tooFast}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.uncannyMovement,
      description: intl.get(`animationProblems.${AnimationProblemsCode.uncannyMovement}`),
      checked: false,
    },
    {
      id: AnimationProblemsCode.noFacialExpression,
      description: intl.get(`animationProblems.${AnimationProblemsCode.noFacialExpression}`),
      checked: false,
    },
  ])

  const signDoc = useFirestoreDoc(
    getDocumentReference(fsUser.workspace, 'signs', signId) as DocumentReferenceClient,
  ).data

  const signData = signDoc.data() as ISign
  const { currentAnimation } = signData || {}

  const [selectedAnimation, setSelectedAnimation] = useState<IAnimation>()
  const [animations, setAnimations] = useState<IAnimation[]>([])

  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})
  const [animationSelected, setAnimationSelected] = useState(-1)
  const [indexCurrentAnimation, setIndexCurrentAnimation] = useState(-1)
  const firestore = useFirestore()
  const storage = useStorage()
  const [reportProblem, setReportProblem] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [speed, setSpeed] = useState(1.0)
  const textFast = intl.get('messages.fast')
  const textMedium = intl.get('messages.medium')
  const textLow = intl.get('messages.low')
  const textRotateAvatar = intl.get('messages.rotateAvatar')
  const [otherProblem, setOtherProblem] = useState('')

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    setWorkspaceUsers(data.users)
  }

  useEffect(() => {
    console.log(animations)
    if (animations.length) setSelectedAnimation(animations[animationSelected])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationSelected])

  useEffect(() => {
    getUsers()
    if (primarySegment) {
      const call = async () => {
        const animationsSnapshot = await getDocs(
          query(
            getCollectionReference(fsUser.workspace, 'animations') as Query<DocumentData>,
            where('sign', '==', primarySegment.ref),
          ),
        )

        const _animations: IAnimation[] = []
        for (const animationSnapshot of animationsSnapshot.docs) {
          const animation = animationSnapshot.data() as IAnimation
          animation.ref = animationSnapshot.ref
          _animations.push(animation)
        }
        setAnimations(_animations)
        setAnimationSelected(
          _animations.findIndex((el) => el?.ref?.id == primarySegment.currentAnimation?.animation?.id),
        )
        setIndexCurrentAnimation(
          _animations.findIndex((el) => el?.ref?.id == primarySegment.currentAnimation?.animation?.id),
        )
        setSelectedAnimation(_animations.find((el) => el?.ref?.id == primarySegment.currentAnimation?.animation?.id))
      }
      call()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primarySegment])

  /** Salva uma animação como animação principal */
  const setMainAnimation = async (index: number) => {
    if (animations[index].htaAnimation && primarySegment) {
      setIndexCurrentAnimation(index)

      let currentAnimationHasErrors: boolean | null = null
      const _errors = animations[index].errors
      if (_errors && _errors.length > 0) {
        currentAnimationHasErrors = !_errors.includes('NO_ERRORS')
      }

      await runTransaction(firestore, async (transaction) => {
        transaction.update(primarySegment.ref as DocumentReference, {
          // @ts-ignore
          isAnimated: true,
          isAnimating: {
            lastUpdate: primarySegment.isAnimating.lastUpdate,
            user: primarySegment.isAnimating.user,
            value: false,
          },
          currentAnimation: {
            animation: animations[index].ref,
            createdBy: animations[index].createdBy,
            createdAt: animations[index].createdAt,
            errors: animations[index].errors || null,
          },
          currentAnimationHasErrors: currentAnimationHasErrors,
        })
      })
    }
  }

  const copyHtaText = (htaAnimation: string) => {
    navigator.clipboard.writeText(htaAnimation)
  }

  const downloadBlendFile = async (blendFile?: string | null) => {
    if (blendFile) {
      const downloadUrl = await getDownloadURL(ref(storage, blendFile))
      open(downloadUrl)
    }
  }

  const handleOnChangeProblems = (problem: IAnimationProblem, checked: boolean) => {
    const _animationProblems = [...animationProblems]
    const index = _animationProblems.findIndex((animationProblem) => animationProblem.id == problem.id)
    if (index > -1) {
      _animationProblems[index].checked = checked
    }
    setAnimationProblems(_animationProblems)
  }

  const handOnChangeOtherProblem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherProblem(event.target.value)
  }

  const handleOnClickReport = async () => {
    if (!signDoc) return

    try {
      const errors: string[] = []
      animationProblems
        .filter((animationProblem) => animationProblem.checked == true)
        .forEach((animationProblem) => {
          errors.push(animationProblem.id)
        })
      if (otherProblem.trim() != '') {
        errors.push(otherProblem)
      }

      let currentAnimationHasErrors: boolean | null = null
      if (errors.length == 0) {
        currentAnimationHasErrors = false
        errors.push('NO_ERRORS')
      } else {
        currentAnimationHasErrors = true
      }

      const _currentAnimation = currentAnimation
      if (_currentAnimation) _currentAnimation.errors = errors
      const currentAnimationReviewedAt = serverTimestamp() as Timestamp
      runTransaction(firestore, async (transaction) => {
        transaction.update(signDoc.ref, {
          currentAnimationHasErrors: currentAnimationHasErrors,
          currentAnimation: _currentAnimation,
          currentAnimationReviewedAt,
        })

        if (currentAnimation?.animation) {
          transaction.update(currentAnimation.animation as DocumentReferenceClient, {
            errors: errors,
            reviewedBy: fsUserRef,
            reviewedAt: currentAnimationReviewedAt,
          })
        }
      })
      endJobMeter()

      await addContribAnimationReviewFunction({
        signId: signId,
        userId: fsUserRef.id,
        workspaceId: fsUser.workspace.id as WorkspaceId,
      })

      setReportProblem(false)
      const _animationProblems: IAnimationProblem[] = []
      animationProblems.forEach((animation) => {
        _animationProblems.push({ ...animation, checked: false })
      })
      setAnimationProblems(_animationProblems)

      showSnackbar(intl.get('messages.reportedSuccessfully'), {
        variant: 'success',
      })
    } catch (err) {
      showSnackbar((err as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
      setReportProblem(false)
      const _animationProblems: IAnimationProblem[] = []
      animationProblems.forEach((animation) => {
        _animationProblems.push({ ...animation, checked: false })
      })
      setAnimationProblems(_animationProblems)
    }
  }

  return (
    <Dialog open={opened} maxWidth="md" fullWidth style={{ marginTop: '50px' }}>
      <>
        <DialogContent>
          <Tooltip
            title="Report Problem"
            style={{
              position: 'absolute',
              right: '10px',
              cursor: 'pointer',
              marginRight: '10px',
            }}
            onClick={() => {
              if (reportProblem == false) {
                const _animationProblems: IAnimationProblem[] = []
                animationProblems.forEach((problem) => {
                  let indexError: number | undefined = selectedAnimation?.errors?.indexOf(problem.id)
                  if (indexError == undefined) indexError = -1

                  _animationProblems.push({
                    ...problem,
                    checked: indexError > -1 ? true : false,
                  })
                })
                setAnimationProblems(_animationProblems)

                Object.entries(selectedAnimation?.errors || {}).forEach((el) => {
                  let _hasOtherProblem: boolean = true
                  animationProblems.forEach((problem) => {
                    if (problem.id == el[1]) {
                      _hasOtherProblem = false
                    }
                  })
                  if (_hasOtherProblem && el[1] != 'NO_ERRORS') setOtherProblem(el[1])
                })
              }

              setReportProblem(!reportProblem)
            }}
          >
            <Fab disabled={indexCurrentAnimation != animationSelected} color="default" size="small">
              <ReportProblemIcon />
            </Fab>
          </Tooltip>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <AnimationView
              htaAnimation={selectedAnimation?.htaAnimation || ''}
              rest={true}
              repeat={false}
              autoPlay={false}
              speedControls={true}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              speed={speed}
              setSpeed={setSpeed}
              textFast={textFast}
              textMedium={textMedium}
              textLow={textLow}
              textRotate={textRotateAvatar}
            />

            {reportProblem && (
              <Zoom in={reportProblem} style={{ transitionDelay: reportProblem ? '10ms' : '0ms' }}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid>
                    <Typography style={{ marginLeft: -2 }} variant="h6">
                      {intl.get('messages.animator')}:{' '}
                      {(animations[animationSelected] &&
                        workspaceUsers[animations[animationSelected]?.createdBy.id] &&
                        workspaceUsers[animations[animationSelected]?.createdBy.id].displayName) ||
                        ''}
                    </Typography>
                    {animationProblems.map((problem) => (
                      <Grid item key={problem.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={problem.checked}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                handleOnChangeProblems(problem, checked)
                              }
                              style={{ padding: '5px' }}
                            />
                          }
                          label={<Typography variant="body2">{problem.description}</Typography>}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    <TextField
                      value={otherProblem}
                      style={{ marginTop: '10px', marginLeft: '-5px' }}
                      size="small"
                      label={intl.get('pages.reviewAnimation.otherProblem')}
                      variant="outlined"
                      onChange={handOnChangeOtherProblem}
                    />
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOnClickReport}
                    style={{ marginTop: '10px' }}
                  >
                    {intl.get('messages.report')}
                  </Button>
                </Box>
              </Zoom>
            )}
          </Grid>
          <Table aria-label="Animations list">
            <TableHead>
              <TableRow>
                <TableCell>{intl.get('messages.animator').toUpperCase()}</TableCell>
                <TableCell>{intl.get('messages.date').toUpperCase()}</TableCell>
                <TableCell>HTA</TableCell>
                <TableCell align="center">{intl.get('messages.main').toUpperCase()}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {animations.map((row, i) => (
                <AnimationRow
                  isselected={(i === animationSelected).toString()}
                  key={i}
                  onClick={() => setAnimationSelected(i)}
                >
                  <TableCell>
                    {(row.createdBy &&
                      workspaceUsers[row.createdBy.id] &&
                      workspaceUsers[row.createdBy.id].displayName) ||
                      ''}
                  </TableCell>

                  <TableCell>{format(row.createdAt?.toDate() || new Date(), 'dd/MM/yyyy')}</TableCell>

                  <TableCell>{row.htaSize}</TableCell>

                  <TableCell align="center">
                    {indexCurrentAnimation === i ? (
                      <Grid justifyContent="center" container>
                        <MainIcon />
                        <Typography>{intl.get('messages.main').toUpperCase()}</Typography>
                      </Grid>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={row.isDisabled.value}
                        onClick={() => {
                          setMainAnimation(i)
                        }}
                      >
                        {intl.get('pages.animation.setMain')}
                      </Button>
                    )}
                  </TableCell>

                  <TableCell>
                    <Tooltip title={intl.get('pages.animation.copyHta')}>
                      <IconButton
                        aria-label="Copy HTA"
                        component="span"
                        onClick={() => {
                          copyHtaText(row.htaAnimation || '')
                        }}
                      >
                        <FileCopyRounded />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={intl.get('pages.animation.downloadBlendFile')}>
                      <IconButton
                        aria-label="Download .blend"
                        component="span"
                        onClick={() => {
                          downloadBlendFile(row.blendFile)
                        }}
                        disabled={!row.blendFile}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    {!row.errors && indexCurrentAnimation === i && (
                      <Link
                        target="_blank"
                        color={'textPrimary'}
                        onClick={() => {
                          const url = routes.reviewAnimation.replace(':signId', row.sign?.id || '')
                          window.open(url, '_blank')?.focus()
                        }}
                      >
                        <Typography>{intl.get('pages.animation.unreviewed')}</Typography>
                      </Link>
                    )}
                    {(row.errors || indexCurrentAnimation !== i) && (
                      <Typography>
                        {row.errors && row.errors[0] != 'NO_ERRORS'
                          ? intl.get('pages.animation.unreviewed')
                          : intl.get('pages.signOnDemand.errors.noError')}
                      </Typography>
                    )}
                  </TableCell>
                </AnimationRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                toggleOpen()
              }}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Box>
      </>
    </Dialog>
  )
}

export default AnimationViewDialog
