import React, { FC } from 'react'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
//import useIntl from '../../../hooks/useIntl'
import outline from './liviaOutline.svg'

const Wrapper = styled(Grid)`
  width: 100%;
  height: 100%;
`

const Relative = styled.div`
  position: relative;
`

const Absolute = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleX(-1);
  background-color: black;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
`

const Overlay = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 8px;
  color: white;
`

const Clickable = styled.div`
  cursor: pointer;
`

interface ICameraStreamProps {
  /**
   * Se for true, então tem outline
   * */
  showTemplate?: boolean
  overlay?: string
  isRecording: boolean
  wrapperRef: React.RefObject<HTMLDivElement>
  className?: string
  width: string | number | undefined
  height: string | number | undefined
  videoRef?: React.RefObject<HTMLVideoElement>
  /** Chamado quando o usuário clica no overlay de permissão */
  onRequestCamera: () => void
  /** Indica que o usuário tem permissão e a camera está ativada */
  isCameraEnabled: boolean
  resolution?: number | null
  canvasRef?: React.RefObject<HTMLCanvasElement>
  template?: JSX.Element
  textUnableAccessCamera: string
}

export const CameraStream: FC<ICameraStreamProps> = ({
  isCameraEnabled,
  onRequestCamera,
  showTemplate,
  template,
  overlay,
  children,
  isRecording,
  wrapperRef,
  className,
  width,
  height,
  videoRef,
  resolution,
  canvasRef,
  textUnableAccessCamera,
}) => {
  //const intl = useIntl()
  return (
    <Wrapper ref={wrapperRef} container alignItems="center" justifyContent="center" className={className}>
      <Relative style={{ width, height }}>
        <Absolute>
          <Video ref={videoRef} muted autoPlay />
        </Absolute>

        <Absolute>{canvasRef && <canvas ref={canvasRef} hidden />}</Absolute>

        {showTemplate && template && <Absolute>{template}</Absolute>}

        {showTemplate && !template && (
          <Absolute>
            <Image src={outline} alt="Person template" />
          </Absolute>
        )}

        {overlay && (
          <Absolute>
            <Overlay container alignItems="center" justifyContent="center">
              <Typography variant="h2">{overlay}</Typography>
            </Overlay>
          </Absolute>
        )}

        {isRecording && (
          <Absolute>
            <Overlay container>🔴REC - {resolution && resolution}p</Overlay>
          </Absolute>
        )}

        {!isCameraEnabled && (
          <Clickable onClick={onRequestCamera}>
            <Absolute>
              <Overlay container alignItems="center" justifyContent="center">
                <Typography align="center" variant="h5">
                  {textUnableAccessCamera}
                </Typography>
              </Overlay>
            </Absolute>
          </Clickable>
        )}

        {children && <Absolute>{children}</Absolute>}
      </Relative>
    </Wrapper>
  )
}

export default CameraStream
