import React from 'react'
import { Theme, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { useState } from 'react'
import useIntl from '../../../hooks/useIntl'

interface IModalDeletePhrase {
  openModalDelete: boolean
  handleClickAction: (action: string) => void
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: '500px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const ModalDeletePhrase: React.FC<IModalDeletePhrase> = ({ openModalDelete, handleClickAction }) => {
  const [open, setOpen] = useState(openModalDelete)
  const intl = useIntl()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column' }}>
          {intl.get('pages.phraseManager.warnings.deletePhrase')}
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={() => handleClickAction('yes')} color="primary">
            {intl.get('messages.yes')}
          </Button>
          <Button autoFocus variant="contained" onClick={() => handleClickAction('no')} color="primary">
            {intl.get('messages.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ModalDeletePhrase
