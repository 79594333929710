import React from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ExampleImage from './ExampleImage'
import useIntl from '../../../../hooks/useIntl'

import badContrast1 from '../../../../images/badContrast1.jpg'
import badContrast2 from '../../../../images/badContrast2.jpg'
import badContrast3 from '../../../../images/badContrast3.jpg'
import goodLighting from '../../../../images/goodLighting.jpg'

const ContrastStep: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <Typography>{intl && intl.get('pages.signSentence.lobby.contrastStep.description')}</Typography>
      <Grid container justifyContent="space-between">
        <ExampleImage
          imageUrl={badContrast1}
          imageAlt={intl.get('pages.signSentence.lobby.contrastStep.grid.badContrast1')}
        />
        <ExampleImage
          imageUrl={badContrast3}
          imageAlt={intl.get('pages.signSentence.lobby.contrastStep.grid.badContrast2')}
        />
        <ExampleImage
          imageUrl={badContrast2}
          imageAlt={intl.get('pages.signSentence.lobby.contrastStep.grid.badContrast3')}
        />
        <ExampleImage
          imageUrl={goodLighting}
          imageAlt={intl.get('pages.signSentence.lobby.contrastStep.grid.goodContrast')}
          isGood
        />
      </Grid>
    </>
  )
}

export default ContrastStep
