import { useGlobal } from 'reactn'

import { IGlobalAttr, IContributionMetadata } from 'collections'

interface IContributionMetadataFns {
  /**
   * Atualiza os dados da contribuição atual
   */
  setMetadata: (metadata?: IContributionMetadata) => void
  /**
   * Define que o temporizador chegou a zero ou nao
   */
  setIsTimerReachedZero: (value: boolean) => void
}

type ContributionMetadataHookTuple = [IContributionMetadata | undefined, IContributionMetadataFns]

/**
 * Toda contribuição possui metadados,
 * esse hook serve para compartilhar esses dados entre todos os componentes,
 * deste modo você pode condicionar views quando o usuario do metadado for diferente do logado
 */
const useContributionMetadata = (): ContributionMetadataHookTuple => {
  const [contributionMetadata, setContributionMetadata] = useGlobal<IGlobalAttr, 'contributionMetadata'>(
    'contributionMetadata',
  )

  const setIsTimerReachedZero = (value: boolean) => {
    if (contributionMetadata) {
      setContributionMetadata({
        ...contributionMetadata,
        isTimerReachedZero: value,
      })
    }
  }

  const fns: IContributionMetadataFns = {
    setMetadata: setContributionMetadata,
    setIsTimerReachedZero,
  }

  return [contributionMetadata, fns]
}

export default useContributionMetadata
