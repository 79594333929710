import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import logger from '../../services/logger'
import FramePlayerPure from './Pure'
import { CommunityModule, ITimelineCursorState, IMenuOptions } from 'collections'
import useIntl from '../../hooks/useIntl'
import { Box, Button, makeStyles, Popover } from '@material-ui/core'
import ShowVideoDialog from '../ShowVideoDialog'

const { log, error } = logger('FramePlayer')

const StyledCanvas = styled.canvas`
  height: 100%;
`

const useStyles = makeStyles(() => ({
  popover: {
    '& button': {
      display: 'block',
      width: '100%',
      textAlign: 'left',
      textTransform: 'initial',
      fontWeight: 'normal',
      borderBottom: '1px solid #999',
    },
  },
}))

interface IKpData {
  face: [][]
  hand_l: [][]
  hand_r: [][]
  pose: [][]
}

interface IFramePlayerProps {
  /**
   * Velocidade de reprodução
   */
  frameRatio: number
  /**
   * Duração do frame
   */
  framesDuration: number
  /**
   * Caminho para o json com os frames
   */
  src: string
  /**
   * Tempo em frames
   */
  timeFrame: number
  /**
   * Player pronto para ser utilizado
   */
  onReady?: (framesDuration: number) => void
  /**
   * Algo deu errado no carregamento do json
   */
  onError: () => void

  /**
   * Callback para cursor da linha do tempo movida
   * @param value - Posição do cursor (em frames)
   */
  onChangedTime?: (value: number) => void
  /**
   * Frame que inicia o segmento
   */
  startFrame?: number
  /**
   * Cursor da timeline
   */
  timelineCursor?: ITimelineCursorState
  /**
   * Auto play do vídeo
   */
  autoPlay?: boolean
  /**
   * Habilita, desabilita recurso de mirror (espelho)
   */
  mirrorEnabled?: boolean
  /**
   * Habilita, desabilita auto play do vídeo
   */
  autoPlayEnabled?: boolean
  /**
   * Evento report
   */
  onReport?: () => void
  /**
   * Id de referencia
   */
  reference?: string | number
  /**
   * Módulo do community
   */
  communityModule?: CommunityModule
  /**
   * Habilita a função hover
   */
  hoverable?: boolean
  /** Altura 100% */
  fullHeight?: boolean
  /** Frames */
  framesVideo?: string[]
  /** Keypoints */
  keypointsData?: IKpData
  /** duration do vídeo completo */
  fullVideoDuration?: number
  /** Sentença do vídeo */
  videoSentence?: string
  /** Evento de parada do Hover */
  handleOnMouseLeave?: () => void
  /** Menu component */
  menuOptions?: IMenuOptions[]
  /** Exibe segmento em fullscreen */
  showFullScreenSegment?: boolean
  /** Condicional para verificar se o frame player está sendo executado */
  isPlayingFromProps?: boolean
  /** Condicional para verificar se o frame player deve exibir os controles */
  showPlayerControls?: boolean
  /** Elemento de vídeo */
  videoElement?: HTMLVideoElement
  /**Exibe desenho dos keypoints*/
  drawKeypoints?: boolean
}

/**
 * Este componente recebe a url do frames JSON por parametro e condiciona a exibição de um player
 * de frames com base em suas flags.
 */
const FramePlayer: React.FC<IFramePlayerProps> = ({
  src,
  onReady,
  onError,
  onChangedTime,
  framesDuration,
  timeFrame,
  startFrame,
  timelineCursor,
  autoPlay,
  mirrorEnabled,
  autoPlayEnabled,
  onReport,
  reference,
  communityModule,
  hoverable,
  fullHeight,
  framesVideo,
  keypointsData,
  fullVideoDuration,
  videoSentence,
  handleOnMouseLeave,
  menuOptions,
  showFullScreenSegment,
  isPlayingFromProps,
  showPlayerControls,
  videoElement,
  drawKeypoints,
}) => {
  const [context, setContext] = useState<CanvasRenderingContext2D>()
  const [frames, setFrames] = useState<HTMLImageElement[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isReady, setIsReady] = useState(false)
  const [count, setCount] = useState(startFrame || 0)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const currentFrame = timelineCursor?.position || 0
  const [mirror, setMirror] = useState(false)
  const intl = useIntl()
  const [playerTimeFrame, setPlayerTimeFrame] = useState(timeFrame || 30)

  const mirrorText = intl.get('pages.findIdenticalSegments.segment.mirror')
  const showVideoText = intl.get('messages.showVideo')
  const showFullScreenSegmentText = intl.get('messages.showFullScreenSegment')
  const [openShowVideo, setOpenShowVideo] = useState(false)
  const [openShowFullScreenSegment, setOpenShowFullScreenSegment] = useState(false)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleCloseMore = () => {
    setAnchorEl(null)
  }

  const handleClickMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const toggleOpenShowVideo = () => {
    setIsPlaying(false)
    if (handleOnMouseLeave) handleOnMouseLeave()
    setOpenShowVideo(!openShowVideo)
  }

  const toggleOpenShowFullScreenSegment = () => {
    setIsPlaying(false)
    if (handleOnMouseLeave) handleOnMouseLeave()
    setOpenShowFullScreenSegment(!openShowFullScreenSegment)
  }

  useEffect(() => {
    setIsPlaying(autoPlay || false)
  }, [autoPlay])

  useEffect(() => {
    setCount(startFrame || 0)
  }, [startFrame])

  const onMidiaButtonClick = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation()
    setIsPlaying(!isPlaying)
  }

  // chamando o setInterval quando for play e desabilitando quando for pause
  useEffect(() => {
    if (
      (isPlaying || isPlayingFromProps) &&
      onChangedTime &&
      (startFrame || communityModule == 'segmentVideo' || communityModule == 'processVideo')
    ) {
      const id = setTimeout(() => {
        setCount((c) => c + 1)
        onChangedTime(count)
        if (count >= framesDuration) {
          setCount(startFrame || 0)
          onChangedTime(startFrame || 0)
          setIsPlaying(autoPlay || autoPlayEnabled || false)
          clearTimeout(id)
        }

        clearTimeout(id)
      }, playerTimeFrame)
      return () => clearTimeout(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, isPlaying, playerTimeFrame, startFrame, isPlayingFromProps])

  useEffect(() => {
    setIsReady(false)

    // Sö iremos carregar os frames se existir um src
    if (src || framesVideo) {
      const call = async () => {
        try {
          let json: { frames: string[] }
          if (!framesVideo) {
            log(`Baixando frames de ${src}...`)

            const response = await fetch(src, { cache: 'force-cache' })
            json = await response.json()
          } else {
            json = { frames: framesVideo }
          }

          log(`${json.frames.length} frames carregados`)

          const framesToset = (json.frames || []).map((frame: string) => {
            const img = new Image()
            img.src = frame
            return img
          })

          setFrames(framesToset)
          if (onReady) onReady(json.frames.length)
          setIsReady(true)
        } catch (_error) {
          setFrames([])
          if (onReady) onReady(0)
          setIsReady(false)
          error(_error)
          onError()
        }
      }

      call()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, reference, framesVideo])

  const transformCoordinateSystem = (pointX: number, pointY: number) => {
    const maxU = 1
    const maxX = 270
    const maxY = 270

    // levando quina inferior esquerda para origem
    pointX *= -1
    pointX += maxU / 2
    pointY += maxU * 1.4

    return [(pointX * maxX) / maxU, (pointY * maxY) / maxU]
  }

  const draw = (pointX: number, pointY: number, ctx?: CanvasRenderingContext2D) => {
    if (pointX !== 0 && pointY !== 0 && ctx) {
      ctx!.rect(pointX, pointY, 3, 3)
      ctx!.fillStyle = '#00FF00'
    }
  }

  useEffect(() => {
    if (!context && !frames && !currentFrame) return
    let requestAnimationFrameId: number

    const render = () => {
      const frame = frames[currentFrame]

      if (frame) {
        context!.canvas.height = 270
        context!.canvas.width = 270
        if (drawKeypoints) {
          context!.beginPath()
          if (keypointsData) {
            Object.keys(keypointsData.face[currentFrame]).map((_keypoint) => {
              if (keypointsData.face[currentFrame][Number(_keypoint)][0]) {
                const x = keypointsData.face[currentFrame][Number(_keypoint)][0][0]
                const y = keypointsData.face[currentFrame][Number(_keypoint)][0][1]
                const [xNew, yNew] = transformCoordinateSystem(x, y)
                draw(xNew, yNew, context!)
              }
            })
            Object.keys(keypointsData.hand_l[currentFrame]).map((_keypoint) => {
              if (keypointsData.hand_l[currentFrame][Number(_keypoint)]) {
                const x = keypointsData.hand_l[currentFrame][Number(_keypoint)][0]
                const y = keypointsData.hand_l[currentFrame][Number(_keypoint)][1]
                const [xNew, yNew] = transformCoordinateSystem(x, y)
                draw(xNew, yNew, context!)
              }
            })
            Object.keys(keypointsData.pose[currentFrame]).map((_keypoint) => {
              if (keypointsData.pose[currentFrame][Number(_keypoint)]) {
                const x = keypointsData.pose[currentFrame][Number(_keypoint)][0]
                const y = keypointsData.pose[currentFrame][Number(_keypoint)][1]
                const [xNew, yNew] = transformCoordinateSystem(x, y)
                draw(xNew, yNew, context!)
              }
            })
            Object.keys(keypointsData.hand_r[currentFrame]).map((_keypoint) => {
              if (keypointsData.hand_r[currentFrame][Number(_keypoint)]) {
                const x = keypointsData.hand_r[currentFrame][Number(_keypoint)][0]
                const y = keypointsData.hand_r[currentFrame][Number(_keypoint)][1]
                const [xNew, yNew] = transformCoordinateSystem(x, y)
                draw(xNew, yNew, context!)
              }
            })
          }
          context!.stroke()
        }
        /**
         * frame.height != frame.width -> Quando o video não estiver cropado,
         * realizar crop na visualização
         */
        context!.drawImage(
          frame,
          frame.height != frame.width ? (frame.width - frame.height) / 2 : 0,
          0,
          frame.height,
          frame.height,
          0,
          0,
          context!.canvas.width * (mirror ? -1 : 1),
          context!.canvas.height,
        )
        context!.fill()
      }

      requestAnimationFrameId = requestAnimationFrame(render)
    }

    if (context && frames) {
      render()
    }

    return () => {
      cancelAnimationFrame(requestAnimationFrameId)
    }
  }, [context, currentFrame, frames, mirror, videoElement, drawKeypoints, keypointsData])

  useEffect(() => {
    if (canvasRef.current) {
      log('Canvas encontrado')
      const ctx = canvasRef.current.getContext('2d')

      if (ctx) setContext(ctx)
    }
  }, [canvasRef])

  // componente de opções
  const menuFramePlayer =
    fullVideoDuration || showFullScreenSegment || menuOptions ? (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        {menuOptions?.map((el, index) => (
          <Button
            key={index}
            onClick={() => {
              el.onClick()
              handleCloseMore()
            }}
          >
            {el.title}
          </Button>
        ))}
        {fullVideoDuration && (
          <Button
            onClick={() => {
              toggleOpenShowVideo()
              handleCloseMore()
            }}
          >
            {showVideoText}
          </Button>
        )}
        {showFullScreenSegment && (
          <Button
            onClick={() => {
              toggleOpenShowFullScreenSegment()
              handleCloseMore()
            }}
          >
            {showFullScreenSegmentText}
          </Button>
        )}
      </Popover>
    ) : null

  return (
    <>
      <FramePlayerPure
        isPlaying={isPlaying}
        isReady={isReady}
        onMidiaButtonClick={onMidiaButtonClick}
        mirror={mirror}
        setMirror={setMirror}
        mirrorEnabled={mirrorEnabled}
        mirrorText={mirrorText}
        onReport={onReport}
        onChangeSpeedPlayer={setPlayerTimeFrame}
        playerTimeFrame={playerTimeFrame || 30}
        hoverable={hoverable}
        fullHeight={fullHeight}
        showPlayerControls={showPlayerControls}
        menuProps={
          menuFramePlayer
            ? {
                menuComponent: menuFramePlayer,
                handleClickMore: handleClickMore,
              }
            : null
        }
      >
        <Box style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <StyledCanvas ref={canvasRef} />
        </Box>
      </FramePlayerPure>
      {fullVideoDuration && (
        <ShowVideoDialog
          labelledby="show-video-dialog"
          open={openShowVideo}
          toggleOpen={toggleOpenShowVideo}
          videoDuration={fullVideoDuration}
          videoUrl={src}
          framesVideo={framesVideo}
          videoSentence={videoSentence}
        />
      )}
      {showFullScreenSegment && (
        <ShowVideoDialog
          labelledby="show-full-screen-segment-dialog"
          open={openShowFullScreenSegment}
          toggleOpen={toggleOpenShowFullScreenSegment}
          videoDuration={framesDuration}
          videoUrl={src}
          framesVideo={framesVideo}
          videoSentence={videoSentence}
          startFrame={startFrame}
        />
      )}
    </>
  )
}

export default FramePlayer
