import { FC } from 'react'
import styled from 'styled-components'

import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'

import bgDarkTheme from '../../images/bg_community_dark.svg'
import bgLightTheme from '../../images/bg_community_light.svg'

const NoBgGrid = styled(Grid)`
  position: relative;
  overflow-x: auto;
`

interface ILayoutProps {
  title?: string
  imageBg?: boolean
  appBar: JSX.Element
  id?: string
  themeColor: 'dark' | 'light'
}

export const LayoutPure: FC<ILayoutProps> = ({ children, title, imageBg, appBar, id, themeColor }) => {
  const BgGrid = styled(Grid)`
    background-image: url(${themeColor === 'dark' ? bgDarkTheme : bgLightTheme});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: auto;
    height: auto;
    position: relative;
  `

  const fullTitle = `HT Community${title ? ` | ${title}` : ''}`
  const GridComponent = imageBg ? BgGrid : NoBgGrid
  const useStyles = makeStyles({
    root: {
      overflowY: 'auto',
      flex: 1,
    },
  })
  const classes = useStyles()

  return (
    <Grid item xs container direction="column" style={{ height: '100%' }}>
      <Helmet>
        <title>{fullTitle}</title>
      </Helmet>
      {appBar}
      <GridComponent
        item
        container
        wrap="nowrap"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        id={id}
        className={classes.root}
      >
        {children}
      </GridComponent>
    </Grid>
  )
}

export default LayoutPure
