import React, { useState } from 'react'

import ApplicationDialogPure from '../Pure'
import useDialog from '../../../services/hooks/useDialog'
import { feedbackFunction } from '../../../services/firebase'
import useSnackbar from '../../../services/hooks/useSnackbar'
import useIntl from '../../../hooks/useIntl'

const FeedbackDialog = (): JSX.Element => {
  const [open, toggleOpen] = useDialog('feedback')
  const [feedback, setFeedback] = useState('')
  const [sending, setSending] = useState(false)
  const showSnackbar = useSnackbar()
  const intl = useIntl()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value)
  }

  const sendFeedback = async () => {
    try {
      setSending(true)
      await feedbackFunction({ text: feedback })
      setSending(false)
      showSnackbar(intl.get('messages.thankYou'), { variant: 'success' })
      toggleOpen()
    } catch (e) {
      showSnackbar(intl.get('messages.feedbackNotSent'), {
        variant: 'error',
      })
    }
  }

  return (
    <ApplicationDialogPure
      title={intl.get('components.feedbackDialog.giveFeedback')}
      labelledby="feedback-dialog"
      open={open}
      options={{
        subtitle: intl.get('components.feedbackDialog.improving'),
        feedback: feedback,
        isPending: sending,
        labelTextFiel: intl.get('components.feedbackDialog.yourFeedback'),
      }}
      toggleOpen={toggleOpen}
      send={sendFeedback}
      handleChange={handleChange}
    />
  )
}

export default FeedbackDialog
