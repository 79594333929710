import { generateReference, runBatchOperationsFirestore } from '..'
import { getBaseSegment } from '../Segment'
import { getBaseSign } from '../Sign'
import {
  DocumentReferenceGeneric,
  ErrorSignOnDemand,
  FirestoreGeneric,
  IBatchOperation,
  ISign,
  ISignOnDemand,
  ITimelineRegion,
  IUser,
  serverTimestamp,
  StatusSignOnDemand,
  Timestamp,
} from '../types'

export const saveSegmentSignOnDemand = async (
  db: FirestoreGeneric,
  signOnDemand: ISignOnDemand,
  signOnDemandRef: DocumentReferenceGeneric,
  fsVideoRef: DocumentReferenceGeneric,
  timelineRegions: ITimelineRegion[],
  fsUser: IUser,
  fsUserRef: DocumentReferenceGeneric,
) => {
  const batchOperations: IBatchOperation[] = []
  let signResult: ISign | null = null
  for (const region of timelineRegions) {
    // Cria o segmento necessario
    const segmentRef = generateReference(fsUser.workspace, 'segments')
    const segmentData = getBaseSegment(fsUser, fsUserRef, {
      startFrame: region.startFrame,
      endFrame: region.endFrame,
      video: fsVideoRef,
      type: region.type,
      text: region.text || '',
    })
    batchOperations.push({
      ref: signOnDemandRef,
      data: {
        isSegmenting: {
          lastUpdate: serverTimestamp() as Timestamp,
          user: fsUserRef,
          value: false,
        },
        status: StatusSignOnDemand.segmentedVideo,
        segmentRef: segmentRef,
        lastUpdate: serverTimestamp() as Timestamp,
      },
      op: 'update',
    })

    const signRef = generateReference(fsUser.workspace, 'signs')

    /** Cria o sinal */
    let signData = getBaseSign(fsUser, fsUserRef, {
      glosa: signOnDemand.glosa || '',
      origin: {
        startFrame: region.startFrame,
        endFrame: region.endFrame,
        video: fsVideoRef,
      },
      numberOfSegments: 1,
      primarySegment: segmentRef,
      promoted: true,
      relatedWords: signOnDemand.portuguese || [],
      searchReference: signOnDemand.searchReference || '',
      definition: signOnDemand.description || '',
      regionOfUse: signOnDemand.regionOfUse || [],
      pieceOfSign: signOnDemand.pieceOfSign || false,
    })

    segmentData.movement = signRef
    segmentData.hasMovement = true

    /** Caso exista HTA */
    if (signOnDemand.hta) {
      /** Cria a animação */

      const animationError = signOnDemand.error === ErrorSignOnDemand.animationError

      const animationRef = generateReference(fsUser.workspace, 'animations')

      const _createdAt = serverTimestamp() as Timestamp
      const noErrors: ErrorSignOnDemand[] = [ErrorSignOnDemand.noError]
      const newFlagsSign: Partial<ISign> = {
        isAnimated: true,
        isAnimating: {
          value: false,
          user: null,
          lastUpdate: null,
        },
        currentAnimation: {
          animation: animationRef,
          createdAt: _createdAt,
          createdBy: fsUserRef,
          errors: animationError ? [signOnDemand.error as ErrorSignOnDemand] : noErrors,
        },
        currentAnimationHasErrors: animationError,
        currentAnimationReviewedAt: null,
      }
      signData = { ...signData, ...newFlagsSign }

      const animationData = {
        isDisabled: {
          value: false,
          user: null,
          lastUpdate: null,
        },
        createdBy: fsUserRef,
        createdAt: _createdAt as Timestamp,
        oralLanguageId: fsUser.oralLanguageId,
        signLanguageId: fsUser.signLanguageId,
        htaAnimation: signOnDemand.hta,
        htaSize: signOnDemand.hta.length,
        blendFile: '',
        comment: null,
        isValidated: {
          value: false,
          user: null,
          lastUpdate: null,
        },
        errors: animationError ? [signOnDemand.error] : ['NO_ERRORS'],
        sign: signRef,
        reviewedBy: null,
        userRatings: null,
        reviewedAt: null,
      }

      batchOperations.push({
        ref: animationRef,
        data: animationData,
        op: 'set',
      })
    }
    signResult = { ...signData }
    batchOperations.push({
      ref: segmentRef,
      data: segmentData,
      op: 'set',
    })

    batchOperations.push({
      ref: signRef,
      data: signData,
      op: 'set',
    })
  }
  if (batchOperations.length) {
    await runBatchOperationsFirestore(db, batchOperations)
  }
  return {
    signData: signResult,
  }
}
