import React, { useEffect, useState, useCallback } from 'react'

import TimerPure from './Pure'

interface ITimerProps {
  startTime: number
  timerDirection: 'increse' | 'decrease'
  variant: 'compact' | 'outlined'
  withIcon?: boolean
  onTimeZeroReached?: () => void
}

const timerStep = 1000
const Timer: React.FC<ITimerProps> = ({ startTime, timerDirection, variant, withIcon = true, onTimeZeroReached }) => {
  const [time, setTime] = useState(0)

  const handleProcessTimer = useCallback(
    (direction: 'increse' | 'decrease') => {
      const newTime = direction === 'increse' ? time + 1 : time - 1

      if (newTime == 0 && onTimeZeroReached) onTimeZeroReached()
      if (newTime >= 0) setTime(newTime)
    },
    [onTimeZeroReached, time],
  )

  useEffect(() => {
    setTime(startTime)
  }, [startTime])

  useEffect(() => {
    const interval = setInterval(() => handleProcessTimer(timerDirection), timerStep)
    return () => {
      clearInterval(interval)
    }
  }, [handleProcessTimer, timerDirection])

  return <TimerPure withIcon={withIcon} variant={variant} time={time}></TimerPure>
}

export default Timer
