import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { IOptionsSelect } from 'collections'
import React, { useRef } from 'react'

interface IMenuMultiselectProps {
  value: string[]
  setValue: React.Dispatch<React.SetStateAction<string[]>>
  label: string
  options: IOptionsSelect[]
}

const MenuMultiselect: React.FC<IMenuMultiselectProps> = ({ value, setValue, label, options }) => {
  const oldValues = useRef(value)

  const handleChange = (val: string[]) => {
    let values: string[] = []

    // Se o valor anterior não tem all e o novo tem Marca todos
    if (
      (val.includes('ALL') && !oldValues.current.includes('ALL')) ||
      (options.length - 1 == val.length && !oldValues.current.includes('ALL'))
    ) {
      values = [...options.map((el) => el.value.toString())]
    } else if (!val.includes('ALL') && oldValues.current.includes('ALL')) {
      // Se o valor atual não tem all e o anterior tem desmarca todos
      values = []
    } else if (val.includes('ALL') && options.length - 2 !== val.length) {
      values = val.filter((el) => el !== 'ALL')
    } else {
      values = val
    }

    oldValues.current = values

    setValue(values)
  }

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ width: '100%' }}
        label={label}
        multiple
        value={value}
        onChange={(ev) => {
          handleChange(ev.target.value as string[])
        }}
      >
        {options.map((el) => (
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MenuMultiselect
