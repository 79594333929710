import React from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import Box from '@material-ui/core/Box'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useIntl from '../../../../hooks/useIntl'
import { IUsersTableValue } from 'collections'

interface IWorkspaceModalProps {
  /** Se o modal é visível */
  isOpen: boolean
  /** Callback para fechar o dialog */
  handleClose: () => void
  /** Evento save */
  handleSave: () => void
  /** Evento de alteração do nome */
  onChangeName: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Nome do workspace */
  workspaceName: string
  /** Língua de sinal */
  signLanguageId: string
  /** Língua oral do workspace */
  oralLanguageId: string
  /** Evento de alteração da língua oral do workspace */
  onChangeOralLanguage: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Evento de alteração da língua de sinais do workspace */
  onChangeSignLanguage: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Evento de alteração do id do workspace */
  onChangeWorkspaceId: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Id do Workspace */
  workspaceId: string
  /** Lista de Oral Languages */
  oralLanguages: IUsersTableValue[]
  /** Lista de Sign Languages */
  signLanguages: IUsersTableValue[]
}

const DialogContainer = styled(Dialog)`
  && {
    border-radius: 10px;
  }
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    display: flex;
    align-items: center;
    padding: 15px 15px;
  }
`

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

/**
 * Componente Puro de cadastro de Workspace
 */
const WorkspaceModalPure: React.SFC<IWorkspaceModalProps> = ({
  isOpen,
  handleClose,
  handleSave,
  onChangeName,
  workspaceName,
  signLanguageId,
  oralLanguageId,
  onChangeSignLanguage,
  onChangeOralLanguage,
  onChangeWorkspaceId,
  workspaceId,
  oralLanguages,
  signLanguages,
}) => {
  const intl = useIntl()
  return (
    <DialogContainer TransitionComponent={Zoom} onClose={handleClose} open={isOpen}>
      <Box>
        <DialogTitle disableTypography>
          <Button onClick={handleClose}>
            <ArrowBackIos />
          </Button>
          <Typography>{'Create workspace'}</Typography>
        </DialogTitle>

        <Container style={{ marginLeft: '20px', marginRight: '20px' }}>
          <SideContainer>
            <InputLabel style={{ fontSize: '12px' }} id="workspace-id-label">
              Workspace Id
            </InputLabel>
            <TextField value={workspaceId} onChange={onChangeWorkspaceId} />
            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="name-label">
              {intl.get('pages.admin.name')}
            </InputLabel>
            <TextField value={workspaceName} onChange={onChangeName} />
            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="oral-language-label">
              {intl.get('pages.admin.oralLanugage')}
            </InputLabel>
            <Select fullWidth value={oralLanguageId} onChange={onChangeOralLanguage}>
              {oralLanguages &&
                oralLanguages.map((oralLanguage) => (
                  <MenuItem key={oralLanguage.id} value={oralLanguage.id}>
                    {oralLanguage.name}
                  </MenuItem>
                ))}
            </Select>
            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="sign-language-label">
              {intl.get('pages.admin.signLanguage')}
            </InputLabel>
            <Select fullWidth value={signLanguageId} onChange={onChangeSignLanguage}>
              {signLanguages &&
                signLanguages.map((signLanguage) => (
                  <MenuItem key={signLanguage.id} value={signLanguage.id}>
                    {signLanguage.name}
                  </MenuItem>
                ))}
            </Select>
          </SideContainer>
        </Container>
        <Box
          p={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Box p={1}>
            <Button onClick={handleClose} variant="outlined">
              {intl.get('messages.cancel')}
            </Button>
          </Box>
          <Box p={1}>
            <Button variant="contained" onClick={handleSave} color="primary">
              {intl.get('messages.save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogContainer>
  )
}

export default WorkspaceModalPure
