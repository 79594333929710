import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import useInteractable from '../services/hooks/useInteractable'
import { DocumentReference, ISegmentView } from 'collections'
import useCurrentRegionAction from '../hooks/useCurrentRegionAction'

interface IInteractableStyledProps {
  isBlock: boolean
  isDragging: boolean
}

const InteractableStyled = styled.div`
  position: ${({ isBlock }: IInteractableStyledProps) => (isBlock ? 'relative' : 'fixed')};
  ${({ isDragging }: IInteractableStyledProps) =>
    isDragging &&
    css`
      pointer-events: none;
    `}
`

interface IDraggableRegionActionProps {
  onStartDrag?: () => void
  onEndDrag?: () => void
  children?: JSX.Element
  type: 'create' | 'fingerspell' | 'sign'
  /** Representação textual, pode ser a glosa ou um texto para datilologia */
  representation: string
  sign?: DocumentReference
  segmentView?: ISegmentView
}

const DraggableAction: React.FC<IDraggableRegionActionProps> = ({
  onStartDrag,
  onEndDrag,
  children,
  type,
  sign,
  representation,
  segmentView,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [currentX, setCurrentX] = useState(0)
  const [currentY, setCurrentY] = useState(0)
  const { transform, elemRef, statusDragging, clientX, clientY } = useInteractable({
    baseX: currentX,
    baseY: currentY,
    draggable: true,
  })

  const [refElement, setRefElement] = useState(elemRef)
  const useCurrentRegionActionFns = useCurrentRegionAction()[1]

  const handleDragStart = () => {
    useCurrentRegionActionFns.setRegion({
      type,
      representation,
      sign,
      segmentView,
    })
  }

  const handleDragEnd = () => {
    useCurrentRegionActionFns.setRegion()
  }

  useEffect(() => {
    setRefElement(elemRef)
  }, [elemRef])

  useEffect(() => {
    if (statusDragging == 'begin') {
      setCurrentX(clientX)
      setCurrentY(clientY)
      setIsDragging(true)
      handleDragStart()
      if (onStartDrag) onStartDrag()
    } else if (statusDragging == 'end') {
      setCurrentX(0)
      setCurrentY(0)
      setIsDragging(false)
      handleDragEnd()
      if (onEndDrag) onEndDrag()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDragging])

  return (
    <InteractableStyled
      data-item-type="sign"
      ref={refElement}
      isDragging={isDragging}
      isBlock={!isDragging}
      style={{
        touchAction: 'none',
        transform: `translate(${transform.offsetX}px, ${transform.offsetY}px)`,
        top: `${transform.y}px`,
        left: `${transform.x}px`,
      }}
    >
      {children}
    </InteractableStyled>
  )
}

export default DraggableAction
