import { Fab, Grid } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Layout from '../../components/Layout'
import useIntl from '../../hooks/useIntl'
import PhraseList from './PhraseList'
import ModalIncludePhrase from './ModalIncludePhrase'
import { ref, get, remove, update, set } from '@firebase/database'
import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import { useDatabase, useDatabaseObjectData, useUser } from 'reactfire'
import ModalDeletePhrase from './ModalDeletePhrase'
import {
  IGlobalAttr,
  preProcess,
  TCorpusGroup,
  ISentenceData,
  preProcessSentenceToRecord,
  IWorkspacePermissions,
} from 'collections'
import { setGlobal, useGlobal } from 'reactn'
import useSnackbar from '../../services/hooks/useSnackbar'
import SignSentenceMain from '../SignSentence/Main'
import CardMessage from '../../components/CardMessage'
import { getNewCategoriesText } from '../../services/utils'
import FilterComponent from './FilterComponent'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useCategories } from '../../hooks/useCategories'

interface IPhraseManagerProps {
  path: string
}

interface IRecordVideoPriorityData {
  priority: number
  sentence: string
  sentenceOrigin: string
  clientId: string
  sentenceCategory?: string[]
  corpusGroup: TCorpusGroup
  isValidated: boolean
  isRecording: boolean
  needResearch: boolean
  isRepeated: boolean
  userRecording: string
  isExternal: boolean
}

export interface ISentence {
  id: string
  sentence: string
  sentenceCategory: string[]
  sentenceOrigin: string
  clientId: string
  priority: number
  isValidated: boolean
  corpusGroup: string
  isRecording: boolean
  needResearch: boolean
  isRepeated: boolean
  userRecording: string
  isExternal: boolean
}

export interface IOriginData {
  id: number
  origin: string
}

export interface IFilterObject {
  searchText: string
  searchSentenceOrigin: string
  searchCategory: string
  searchCorpusGroup: string
  searchIsValidated: string
  searchNeedResearch: string
  searchClientId: string
  searchIsExternal: string
}

export interface ITitles {
  searchText: string
  origins: string
  removeFilter: string
  categories: string
  corpus: string
  needResearch: string
  train: string
  validation: string
  validated: string
  yes: string
  no: string
  clearFilter: string
  search: string
  clientId: string
  phrase: string
  external: string
}

export interface IListText {
  delete: string
  repeatedSentence: string
  recordingVideo: string
  recordVideo: string
  validate: string
  sentence: string
  category: string
  needResearch: string
  actions: string
  undefined: string
  labelDisplayedRows: string
  labelRows: string
}

export interface IPhraseListPagination {
  currentPage: number
  firstOfList: number
  lastOfList: number
  load: boolean
  rowsPerPage: number
}

const initialTranslationsPaginationConfig = {
  currentPage: 0,
  firstOfList: 0,
  lastOfList: 10,
  load: false,
  rowsPerPage: 10,
}

const PhraseManager: React.FC<IPhraseManagerProps> = () => {
  const intl = useIntl()
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const db = useDatabase()
  const showSnackbar = useSnackbar()
  const fbUser = useUser<firebase.default.User>()

  const sentencesReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority`
  const sentencesRef = ref(db, sentencesReferenceString)
  const { status, data: sentencesData } = useDatabaseObjectData(sentencesRef)

  const originsReferenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/sentenceOrigins`
  const originsRef = ref(db, originsReferenceString)
  const { data: originsData } = useDatabaseObjectData(originsRef)
  const { sentencesCategories: categories } = useCategories()

  const origins: string[] = Object.entries(originsData as IOriginData)
    .map((value) => (value[0] != 'NO_ID_FIELD' ? value[1] : null))
    .filter((el) => {
      if (el) return el
    })

  const filterTexts: ITitles = {
    searchText: intl.get('pages.phraseManager.filters.searchText'),
    origins: intl.get('pages.phraseManager.filters.origins'),
    removeFilter: intl.get('pages.phraseManager.filters.removeFilter'),
    categories: intl.get('pages.phraseManager.filters.categories'),
    corpus: intl.get('pages.phraseManager.filters.corpus'),
    needResearch: intl.get('pages.phraseManager.filters.needResearch'),
    train: intl.get('pages.signOnDemand.corpusGroup.train'),
    validation: intl.get('pages.signOnDemand.corpusGroup.validation'),
    validated: intl.get('pages.phraseManager.filters.validated'),
    yes: intl.get('messages.yes'),
    no: intl.get('messages.no'),
    clearFilter: intl.get('pages.phraseManager.filters.clearFilter'),
    search: intl.get('pages.phraseManager.filters.search'),
    clientId: intl.get('pages.phraseManager.filters.clientId'),
    phrase: intl.get('pages.phraseManager.phrase'),
    external: intl.get('pages.phraseManager.filters.external'),
  }

  const listTexts: IListText = {
    delete: intl.get('pages.phraseManager.gridInfo.delete'),
    repeatedSentence: intl.get('messages.repeatedSentence'),
    recordingVideo: intl.get('pages.phraseManager.gridInfo.recordingVideo'),
    recordVideo: intl.get('pages.phraseManager.gridInfo.recordVideo'),
    validate: intl.get('pages.phraseManager.gridInfo.validate'),
    sentence: intl.get('pages.phraseManager.gridInfo.sentence'),
    category: intl.get('messages.category'),
    needResearch: intl.get('pages.phraseManager.filters.needResearch'),
    actions: intl.get('pages.phraseManager.gridInfo.actions'),
    undefined: intl.get('messages.undefined'),
    labelDisplayedRows: intl.get('pages.animation.labelDisplayedRows'),
    labelRows: intl.get('messages.labelRows'),
  }

  const [sentences, setSentences] = useState<ISentence[]>([])
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [sentenceId, setSentenceId] = useState('')
  const [filterObject, setFilterObject] = useState<IFilterObject>({
    searchText: '',
    searchSentenceOrigin: '',
    searchCategory: '',
    searchCorpusGroup: '',
    searchIsValidated: '',
    searchNeedResearch: '',
    searchClientId: '',
    searchIsExternal: 'false',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [isRecorded, setIsRecorded] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const [paginationConfig, setPaginationConfig] = useState<IPhraseListPagination>(initialTranslationsPaginationConfig)
  const [sentenceToRecord] = useGlobal<IGlobalAttr, 'sentenceToRecord'>('sentenceToRecord')
  const [hasMorePhrases, setHasMorePhrases] = useState(true)
  const [dataToShow, setDataToShow] = useState<ISentence[]>([])

  const handleChangeSearchText = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const sentenceText = event.target.value as string
    setFilterObject({
      ...filterObject,
      searchText: sentenceText,
    })
  }

  const handleChangeSearchOrigin = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const sentenceOrigin = event.target.value as string
    setFilterObject({
      ...filterObject,
      searchSentenceOrigin: sentenceOrigin.toUpperCase(),
    })
  }

  const handleChangeSearchCategory = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const searchCategory = event.target.value as string
    setFilterObject({ ...filterObject, searchCategory: searchCategory })
  }

  const handleChangeSearchCorpus = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const searchCorpusGroup = event.target.value as string
    setFilterObject({ ...filterObject, searchCorpusGroup: searchCorpusGroup })
  }

  const handleChangeSearchIsValidated = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const searchIsValidated = event.target.value as string
    setFilterObject({ ...filterObject, searchIsValidated: searchIsValidated })
  }

  const handleChangeSearchNeedResearch = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const _searchNeedResearch = event.target.value as string
    setFilterObject({
      ...filterObject,
      searchNeedResearch: _searchNeedResearch,
    })
  }

  const handleChangeClientId = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const clientIdText = event.target.value as string
    setFilterObject({
      ...filterObject,
      searchClientId: clientIdText,
    })
  }

  const handleChangeSearchIsExternal = (
    event: ChangeEvent<{
      value: unknown
    }>,
  ) => {
    const _searchIsExternal = event.target.value as string
    setFilterObject({
      ...filterObject,
      searchIsExternal: _searchIsExternal,
    })
  }

  const handleOnDeletePhraseClick = (key: string) => {
    setOpenModalDelete(!openModalDelete)
    setSentenceId(key)
  }

  const handleOnClickRecordPhrase = async (key: string, sentence: string) => {
    const md5Sentence = preProcessSentenceToRecord(sentence.normalize('NFKC'), fsUser.workspace.id)
    if (key != md5Sentence) {
      const sentenceData = await updateSentenceData(key, md5Sentence, true)
      if (sentenceData && sentenceData.isValidated) {
        setGlobal<IGlobalAttr>({
          sentenceToRecord: md5Sentence,
        })
        setIsRecording(true)
      } else {
        showSnackbar(intl.get('pages.phraseManager.warnings.unvalidatedSentence'), {
          variant: 'warning',
        })
      }
    } else {
      const sentenceData = sentences.find((el) => el.id == key)
      if (sentenceData && sentenceData.isValidated) {
        const sentenceToUpdateRef = ref(
          db,
          `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${key}`,
        )

        await update(sentenceToUpdateRef, {
          isRecording: true,
          userRecording: fsUserRef.id,
        })

        const _sentences = [...sentences]
        const updateSentenceId = _sentences.findIndex((el: ISentence) => el.id == key)
        _sentences[updateSentenceId] = {
          ...sentenceData,
          isRecording: true,
          userRecording: fsUserRef.id,
        }
        _sentences.sort((a, b) => {
          const aPriority = a.priority || 10
          const bPriority = b.priority || 10
          return bPriority - aPriority
        })
        setSentences(_sentences)
        setGlobal<IGlobalAttr>({
          sentenceToRecord: key,
        })
        setIsRecording(true)
      } else {
        showSnackbar(intl.get('pages.phraseManager.warnings.unvalidatedSentence'), {
          variant: 'warning',
        })
      }
    }
  }

  const handleCloseModal = () => {
    // setSentenceId('')
  }

  const handleClickAction = async (action: string) => {
    if (action == 'yes' && sentenceId != '') {
      await remove(
        ref(
          db,
          `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${sentenceId}`,
        ),
      )
      setOpenModalDelete(false)
      const _sentences = [...sentences]
      const deleteSentence = _sentences.findIndex((el: ISentence) => el.id == sentenceId)
      _sentences.splice(deleteSentence, 1)
      _sentences.sort((a, b) => {
        const aPriority = a.priority || 10
        const bPriority = b.priority || 10
        return bPriority - aPriority
      })
      setSentences(_sentences)
    } else {
      setOpenModalDelete(false)
    }
    setSentenceId('')
  }

  const handleOnValidate = async (
    event: React.ChangeEvent<HTMLInputElement>,
    sentenceData: ISentence,
    oldSentence: string,
  ) => {
    const eventValue = event.target.checked
    let changed = false
    if (sentenceData.sentence != oldSentence) changed = true
    else changed = false

    const md5Sentence = preProcessSentenceToRecord(sentenceData.sentence.normalize('NFKC'), fsUser.workspace.id)

    const sentenceRef = ref(
      db,
      `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${md5Sentence}`,
    )
    const sentence = await get(sentenceRef)
    if (sentence.exists() && changed) {
      // se existe e mudou quer dizer que não é o que já está gravado
      // ou seja, já existe uma outra frase
      showSnackbar(intl.get('pages.phraseManager.warnings.existsPhrase'), {
        variant: 'warning',
      })
      sentenceData.isValidated = false
      return
    }

    if (eventValue && (!sentenceData.sentenceCategory || sentenceData.sentenceCategory.length == 0)) {
      showSnackbar(intl.get('pages.phraseManager.warnings.infoCategory'), {
        variant: 'warning',
      })
      sentenceData.isValidated = false
      return
    }

    if (md5Sentence != sentenceData.id) {
      updateSentenceData(sentenceData.id, md5Sentence, false)
    } else {
      setIsUpdating(true)
      const sentenceRef = ref(
        db,
        `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${md5Sentence}`,
      )

      let _videoSentenceCategories = sentenceData.sentenceCategory || ['undefined']
      if (fsUser.oralLanguageId == 'por' && sentenceData.sentenceCategory && sentenceData.sentenceCategory?.length) {
        _videoSentenceCategories = getNewCategoriesText(sentenceData.sentenceCategory, true)
      } else _videoSentenceCategories = sentenceData.sentenceCategory || ['undefined']

      await update(sentenceRef, {
        sentence: sentenceData.sentence,
        sentenceCategory: _videoSentenceCategories,
        isValidated: eventValue,
        needResearch: sentenceData.needResearch || false,
      })
      setIsUpdating(false)
      const _sentences = [...sentences]
      const idToUpdate = _sentences.findIndex((sentenceSnapshot) => sentenceSnapshot.id == sentenceData.id)

      _videoSentenceCategories = _sentences[idToUpdate].sentenceCategory || ['undefined']
      if (
        fsUser.oralLanguageId == 'por' &&
        _sentences[idToUpdate].sentenceCategory &&
        _sentences[idToUpdate].sentenceCategory?.length
      ) {
        _videoSentenceCategories = getNewCategoriesText(_sentences[idToUpdate].sentenceCategory, false, true)
      } else _videoSentenceCategories = _sentences[idToUpdate].sentenceCategory || ['undefined']

      _sentences[idToUpdate].id = md5Sentence
      _sentences[idToUpdate].sentence = sentenceData.sentence
      _sentences[idToUpdate].sentenceCategory = _videoSentenceCategories
      _sentences[idToUpdate].isValidated = eventValue
      _sentences[idToUpdate].needResearch = sentenceData.needResearch
      _sentences[idToUpdate].isRecording = sentenceData.isRecording
      _sentences.sort((a, b) => {
        const aPriority = a.priority || 10
        const bPriority = b.priority || 10
        return bPriority - aPriority
      })
      setSentences(_sentences)
    }
  }

  const handleOnClearFilter = () => {
    setFilterObject({
      searchText: '',
      searchSentenceOrigin: '',
      searchCategory: '',
      searchCorpusGroup: '',
      searchIsValidated: '',
      searchNeedResearch: '',
      searchClientId: '',
      searchIsExternal: 'false',
    })
  }

  const getSentences = async () => {
    if (status == 'error') return
    let filteredSentences = Object.entries((sentencesData || []) as ISentenceData[])

    if (filterObject?.searchText.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        const searchText = preProcess(filterObject.searchText, fsUser?.workspace.id || '', true, true, false, false)
        const sentence = preProcess(data.sentence || '', fsUser?.workspace.id || '', true, true, false, false)
        return sentence.includes(searchText)
      })
    }
    if (filterObject?.searchSentenceOrigin.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        return (data.sentenceOrigin || '').toUpperCase() == filterObject.searchSentenceOrigin
      })
    }
    if (filterObject?.searchCategory.trim()) {
      const _category = getNewCategoriesText([filterObject.searchCategory], true)
      const _searchCategory = _category[0]
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        return data.sentenceCategory?.includes(_searchCategory)
      })
    }
    if (filterObject?.searchCorpusGroup.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        return data.corpusGroup == filterObject.searchCorpusGroup
      })
    }
    if (filterObject?.searchIsValidated.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        const isValidated = filterObject.searchIsValidated == 'true' ? true : false
        return data.isValidated == isValidated
      })
    }
    if (filterObject?.searchNeedResearch.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        const searchNeedResearch = filterObject.searchNeedResearch == 'true' ? true : false
        return data.needResearch == searchNeedResearch
      })
    }
    if (filterObject?.searchClientId.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        return data.clientId == filterObject?.searchClientId
      })
    }

    if (fsUser.isExternal == true) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        return data.isExternal == true
      })
    } else if (filterObject?.searchIsExternal.trim()) {
      filteredSentences = filteredSentences.filter((el) => {
        const data = el[1] as IRecordVideoPriorityData
        return data.isExternal == (filterObject.searchIsExternal == 'true' ? true : false)
      })
    }

    filteredSentences.sort((a, b) => {
      const aPriority = a[1].priority || 10
      const bPriority = b[1].priority || 10
      return bPriority - aPriority
    })

    const _sentences: ISentence[] = []
    for (const value of filteredSentences) {
      if (value[0] == 'NO_ID_FIELD') continue
      const objValue = value[1] as IRecordVideoPriorityData

      let _videoSentenceCategories = objValue.sentenceCategory || ['undefined']
      if (fsUser.oralLanguageId == 'por' && objValue.sentenceCategory && objValue.sentenceCategory?.length) {
        _videoSentenceCategories = getNewCategoriesText(objValue.sentenceCategory, false, true)
      } else _videoSentenceCategories = objValue.sentenceCategory || ['undefined']

      const sentence: ISentence = {
        id: value[0],
        sentence: objValue.sentence,
        sentenceCategory: _videoSentenceCategories,
        isValidated: objValue.isValidated,
        sentenceOrigin: objValue.sentenceOrigin ? objValue.sentenceOrigin.toUpperCase() : '',
        corpusGroup: objValue.corpusGroup,
        priority: objValue.priority,
        clientId: objValue.clientId,
        isRecording: objValue.isRecording,
        needResearch: objValue.needResearch,
        isRepeated: objValue.isRepeated || false,
        userRecording: objValue.userRecording || '',
        isExternal: objValue.isExternal || false,
      }
      _sentences.push(sentence)
    }
    filteredSentences = []

    setSentences(_sentences)

    const _dataToShow = _sentences.slice(0, paginationConfig.rowsPerPage)
    setDataToShow(_dataToShow)
    setHasMorePhrases(paginationConfig.lastOfList < _sentences.length)
  }

  const onChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    const _paginationConfig = { ...paginationConfig }
    if (page > _paginationConfig.currentPage) {
      _paginationConfig.firstOfList += _paginationConfig.rowsPerPage
      _paginationConfig.lastOfList += _paginationConfig.rowsPerPage
    } else {
      _paginationConfig.firstOfList -= _paginationConfig.rowsPerPage
      _paginationConfig.lastOfList -= _paginationConfig.rowsPerPage
    }
    _paginationConfig.currentPage = page
    _paginationConfig.load = true
    const _dataToShow = sentences.slice(_paginationConfig.firstOfList, _paginationConfig.lastOfList)
    setDataToShow(_dataToShow)
    setPaginationConfig(_paginationConfig)
  }

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.rowsPerPage = Number(event.target.value)
    _paginationConfig.currentPage = 0
    _paginationConfig.firstOfList = 0
    _paginationConfig.lastOfList = _paginationConfig.rowsPerPage
    _paginationConfig.load = true
    const _dataToShow = sentences.slice(_paginationConfig.firstOfList, _paginationConfig.lastOfList)
    setDataToShow(_dataToShow)
    setPaginationConfig(_paginationConfig)
  }

  const updateSentenceData = async (keyBefore: string, keyAfter: string, isRecording: boolean) => {
    const newSentenceRef = ref(
      db,
      `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${keyAfter}`,
    )

    const sentenceData: ISentence | undefined = sentences.find((el) => el.id == keyBefore)
    if (!sentenceData) return

    const sentenceCategory = sentenceData.sentenceCategory || ['undefined']
    const newSentence: ISentenceData = {
      priority: sentenceData.priority, // adicionar rotina para calculo correto da prioridade
      sentence: sentenceData.sentence,
      sentenceOrigin: sentenceData.sentenceOrigin.toUpperCase() || 'INTERNAL', // origem padrão de inclusão
      sentenceCategory: getNewCategoriesText(sentenceCategory, true), // incluir categorias da frase
      isValidated: sentenceData.isValidated, // flag de validação
      corpusGroup: sentenceData.corpusGroup || 'TRAIN', // grupo do corpus padrão 'TRAIN'
      clientId: sentenceData.clientId || 'HT', // Id do cliente
      isRecording, // flag para indicar que a frase está sendo gravada
      needResearch: sentenceData.needResearch || false, // flag para indicar que a frase precisa de pesquisa
      isRepeated: sentenceData.isRepeated || false, // flag que indica se é uma repetição
      userRecording: isRecording ? fsUserRef.id : '', // usuário que está gravando a frase
      isExternal: sentenceData.isExternal || false, //flag que indica se é uma contribuição externa
    }
    await set(newSentenceRef, newSentence)

    const _sentences = [...sentences]

    const updateSentenceId = _sentences.findIndex((el: ISentence) => el.id == keyBefore)

    let _videoSentenceCategories = _sentences[updateSentenceId].sentenceCategory || ['undefined']
    if (
      fsUser.oralLanguageId == 'por' &&
      _sentences[updateSentenceId].sentenceCategory &&
      _sentences[updateSentenceId].sentenceCategory?.length
    ) {
      _videoSentenceCategories = getNewCategoriesText(_sentences[updateSentenceId].sentenceCategory, false, true)
    } else _videoSentenceCategories = _sentences[updateSentenceId].sentenceCategory || ['undefined']

    _sentences[updateSentenceId] = {
      id: keyAfter,
      priority: sentenceData.priority, // adicionar rotina para calculo correto da prioridade
      sentence: sentenceData.sentence,
      sentenceOrigin: sentenceData.sentenceOrigin.toUpperCase() || 'INTERNAL', // origem padrão de inclusão
      sentenceCategory: _videoSentenceCategories, // incluir categorias da frase
      isValidated: sentenceData.isValidated, // flag de validação
      corpusGroup: sentenceData.corpusGroup || 'TRAIN', // grupo do corpus padrão 'TRAIN'
      clientId: sentenceData.clientId || 'HT', // Id do cliente
      isRecording, // flag para indicar que a frase está sendo gravada
      needResearch: sentenceData.needResearch, // flag para indicar que a frase precisa de pesquisa
      isRepeated: sentenceData.isRepeated || false, // flag que indica se é uma repetição
      userRecording: isRecording ? fsUserRef.id : '', // usuário que está gravando a frase
      isExternal: sentenceData.isExternal || false, // flag que indica se é uma contribuição externa
    }
    _sentences.sort((a, b) => {
      const aPriority = a.priority || 10
      const bPriority = b.priority || 10
      return bPriority - aPriority
    })
    setSentences(_sentences)
    setIsUpdating(true)
    await remove(
      ref(
        db,
        `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${keyBefore}`,
      ),
    )
    setIsUpdating(false)
    return sentenceData
  }

  const updateIsRecording = async () => {
    const sentenceToUpdateRef = ref(
      db,
      `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${sentenceToRecord}`,
    )

    await update(sentenceToUpdateRef, {
      isRecording: false,
    })
  }

  const handleSearch = () => {
    setIsLoading(true)
    setSentences([])
    setPaginationConfig(initialTranslationsPaginationConfig)
    getSentences()
    setIsLoading(false)
  }

  useEffect(() => {
    if (!isUpdating) getSentences()
    setIsUpdating(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentencesData])

  useEffect(() => {
    if (!isRecording && !isRecorded) {
      if (sentenceToRecord != '') {
        const sentenceFinded = sentences.find((el) => el.id == sentenceToRecord)
        if (sentenceFinded) updateIsRecording()
      }
    } else if (isRecorded) {
      setIsRecorded(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording])

  const filterComponent = useMemo(() => {
    return (
      <FilterComponent
        fsUser={fsUser}
        filterTexts={filterTexts}
        origins={origins}
        categories={categories}
        filterObject={filterObject}
        handleSearch={handleSearch}
        handleOnClearFilter={handleOnClearFilter}
        handleChangeSearchText={handleChangeSearchText}
        handleChangeSearchOrigin={handleChangeSearchOrigin}
        handleChangeSearchCategory={handleChangeSearchCategory}
        handleChangeSearchCorpus={handleChangeSearchCorpus}
        handleChangeSearchIsValidated={handleChangeSearchIsValidated}
        handleChangeSearchNeedResearch={handleChangeSearchNeedResearch}
        handleChangeClientId={handleChangeClientId}
        handleChangeSearchIsExternal={handleChangeSearchIsExternal}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject, origins, categories])

  const listComponent = useMemo(() => {
    return (
      <PhraseList
        data={dataToShow || []}
        setSentences={setSentences}
        categories={categories}
        handleOnDeletePhraseClick={handleOnDeletePhraseClick}
        handleOnClickRecordPhrase={handleOnClickRecordPhrase}
        handleOnValidate={handleOnValidate}
        isLoading={isLoading}
        loadMoreSentences={getSentences}
        loggedUser={fsUserRef.id}
        paginationConfig={paginationConfig}
        hasMorePhrases={hasMorePhrases}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
        listTexts={listTexts}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentences, dataToShow, fsUser.workspace.id])

  const hasPermissionOnModule = useMemo(async () => {
    let _hasPermissions: boolean = false
    try {
      const tokenResult = await fbUser.data?.getIdTokenResult(true)
      await fbUser.data?.getIdToken(true)
      const _permissions = tokenResult?.claims?.permissions as IWorkspacePermissions

      if (
        _permissions &&
        _permissions['phraseManager'] &&
        _permissions['phraseManager'][fsUser.workspace.id] &&
        _permissions['phraseManager'][fsUser.workspace.id] == 1
      ) {
        _hasPermissions = true
      }
    } catch (error) {
      _hasPermissions = false
    }
    return _hasPermissions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.workspace.id])

  // Usuário não tem papel de escritor
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.phraseManager')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <>
      {isRecording ? (
        <SignSentenceMain
          setIsRecording={setIsRecording}
          setIsRecorded={setIsRecorded}
          updateIsRecording={updateIsRecording}
        />
      ) : (
        <Layout title={intl.get('modules.phraseManager')} requiredModule={'phraseManager'}>
          <Grid item justifyContent="center" alignItems="center" container>
            <Grid
              item
              md={10}
              style={{
                margin: '10px',
                alignItems: 'center',
              }}
            >
              {filterComponent}
              {listComponent}
            </Grid>
          </Grid>

          <Fab
            aria-label="scroll to top"
            style={{
              position: 'fixed',
              bottom: '10px',
              left: '30px',
            }}
            color="default"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>

          <ModalIncludePhrase
            sentences={sentences}
            setSentences={setSentences}
            handleCloseModal={handleCloseModal}
            database={db}
            fsUser={fsUser}
            origins={origins}
            categories={categories}
            titles={filterTexts}
          />

          {openModalDelete && (
            <ModalDeletePhrase openModalDelete={openModalDelete} handleClickAction={handleClickAction} />
          )}
        </Layout>
      )}
    </>
  )
}

export default PhraseManager
