import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import { ISign } from 'collections'
import { routes } from '../../community'
import useIntl from '../../hooks/useIntl'

interface ISignWarningDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  signs: ISign[]
  setSigns: React.Dispatch<React.SetStateAction<ISign[]>>
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow)

export const SignWarningDialog: React.FC<ISignWarningDialogProps> = ({ open, setOpen, signs, setSigns }) => {
  const intl = useIntl()
  const handleClose = () => {
    setOpen(false)
    setSigns([])
  }

  const handleGoToHTube = (id: string) => {
    if (id) {
      const url = routes.hTube.replace(':clusterId', id)
      window.open(url, '_blank')?.focus()
    }
  }

  const handleGoToReviewAnimation = (id: string) => {
    if (id) {
      const url = routes.reviewAnimation.replace(':signId', id)
      window.open(url, '_blank')?.focus()
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{intl.get('messages.signs')}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Glosa</TableCell>
                  <TableCell align="right">{intl.get('components.signView.showInHTube')}</TableCell>
                  <TableCell align="right">{intl.get('modules.reviewAnimation')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {signs.map((row) => (
                  <StyledTableRow key={'aa'}>
                    <TableCell component="th" scope="row">
                      {row.relatedWords.length ? row.relatedWords[0] : row.glosa}
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        disabled={row.searchReference ? true : false}
                        variant="contained"
                        onClick={() => {
                          handleGoToHTube(row.id || '')
                        }}
                      >
                        {intl.get('messages.go')}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        disabled={
                          row.isFingerspell || (row.currentAnimation && row.currentAnimationHasErrors !== null)
                            ? true
                            : false
                        }
                        onClick={() => {
                          handleGoToReviewAnimation(row.id || '')
                        }}
                      >
                        {intl.get('messages.go')}
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {intl.get('messages.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
