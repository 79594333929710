import { FormControl, InputLabel, MenuItem, Select as SelectMUI } from '@material-ui/core'
import React from 'react'
import { IOptionsSelect } from 'collections'

interface ISelectProps {
  id: string
  label: string
  value: string
  disabled?: boolean
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  optionsValues: IOptionsSelect[]
}

const Select: React.FC<ISelectProps> = ({ id, label, value, disabled, onChange, optionsValues }) => {
  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <SelectMUI
        labelId={`${id}-label`}
        className="notranslate"
        fullWidth
        id={id}
        value={value}
        MenuProps={{
          style: { zIndex: 2002 },
        }}
        disabled={disabled}
        onChange={onChange}
      >
        {optionsValues.map((el, index) => (
          <MenuItem key={index} className={`notranslate`} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </SelectMUI>
    </FormControl>
  )
}

export default Select
