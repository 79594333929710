import React from 'react'
import { IManuallyTranslation, IManuallyTranslationSentence, IManuallyTranslationTarget } from 'collections'
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { AccordionDetails, Box } from '@material-ui/core'
import DraggableAccordion from '../DraggableAccordion'
import { useDroppable } from '@dnd-kit/core'
import { DroppableChipList } from '../DroppableChipList'
import { IFingerspell, ISignWithHta } from '../index'
import { NMTSuggestion } from '../NMTSuggestion'
import { v4 as uuidv4 } from 'uuid'
import AddPhraseButton from './AddPhraseButton'

interface IDroppableAccordionList {
  data: IManuallyTranslation | null
  setData: React.Dispatch<React.SetStateAction<IManuallyTranslation | null>>
  onAddSentence: (index: number) => void
  onDeleteSentence: (index: number) => void
  onVerifyTranslation: (id: string) => void
  onPlayAnimation: (item?: IManuallyTranslationSentence | null, itemSearch?: ISignWithHta | IFingerspell) => void
  isPlaying: string | null
  onFinalizeAnimation: () => void
  onGetTranslationFromNMT: (index: number) => void
  onDeleteChip: (item: IManuallyTranslationSentence, indexChip: number) => void
  expandedAccordions: string[]
  setExpandedAccordions: React.Dispatch<React.SetStateAction<string[]>>
  onGetSignByClick: (item: IManuallyTranslationTarget) => void
  dataInPagination: IManuallyTranslationSentence[]
  firstOfList: number
}

export const DroppableAccordionList: React.FC<IDroppableAccordionList> = ({
  data,
  isPlaying,
  setData,
  onAddSentence,
  onDeleteSentence,
  onVerifyTranslation,
  onPlayAnimation,
  onFinalizeAnimation,
  onGetTranslationFromNMT,
  onDeleteChip,
  expandedAccordions,
  setExpandedAccordions,
  onGetSignByClick,
  dataInPagination,
  firstOfList,
}) => {
  const { setNodeRef: setNodeRefAccordions } = useDroppable({
    id: 'accordions--1',
  })

  const handleReplaceAllTarget = (index: number) => {
    if (!data) return
    const _data = { ...data }
    const target: IManuallyTranslationTarget[] = []

    const suggestions = _data.translationSentences[index].suggestions || []

    suggestions.forEach((el) => {
      target.push({ ...el, draggableId: uuidv4() })
    })

    _data.translationSentences[index].target = target
    setData(_data)
  }

  const getSentenceIndex = (item: IManuallyTranslationSentence): number => {
    const trueIndex = Object.entries(data?.translationSentences || {}).findIndex((el) => {
      return el[1].id == item.id
    })
    return trueIndex
  }

  return (
    <SortableContext
      id={'accordions--1'}
      items={dataInPagination.map((el) => el.id || '') || []}
      strategy={horizontalListSortingStrategy}
    >
      <div
        ref={setNodeRefAccordions}
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <AddPhraseButton onClickAdd={() => onAddSentence(0)} />
        {dataInPagination.map((item: IManuallyTranslationSentence, index: number) => (
          <Box
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <DraggableAccordion
              index={getSentenceIndex(item)}
              onDeleteSentence={onDeleteSentence}
              onVerifyTranslation={onVerifyTranslation}
              item={item}
              data={data}
              setData={setData}
              onGetTranslationFromNMT={onGetTranslationFromNMT}
              onPlayAnimation={() => {
                onPlayAnimation(item)
              }}
              disabled={isPlaying !== null && isPlaying !== item.id}
              isPlaying={isPlaying}
              onFinalizeAnimation={onFinalizeAnimation}
              expandedAccordions={expandedAccordions}
              setExpandedAccordions={setExpandedAccordions}
            >
              <>
                <AccordionDetails style={{ minHeight: '72px', zIndex: 9999 }}>
                  <DroppableChipList onDeleteChip={onDeleteChip} item={item} onGetSignByClick={onGetSignByClick} />
                </AccordionDetails>
                <NMTSuggestion
                  onGetSignByClick={onGetSignByClick}
                  accordionId={item.id || ''}
                  items={
                    data?.translationSentences[firstOfList + index] &&
                    data?.translationSentences[firstOfList + index].suggestions
                      ? data?.translationSentences[firstOfList + index].suggestions || []
                      : []
                  }
                  onReplaceAllTarget={() => {
                    handleReplaceAllTarget(firstOfList + index)
                  }}
                />
              </>
            </DraggableAccordion>

            <AddPhraseButton onClickAdd={() => onAddSentence(index + 1)} />
          </Box>
        ))}
      </div>
    </SortableContext>
  )
}
