import React from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import TimerIcon from '@material-ui/icons/Timer'

interface ITimerProps {
  /** Tempo atual em segundos */
  time: number
  variant: 'outlined' | 'compact'
  withIcon?: boolean
}

/**
 * Exibe um contador para o tempo passado por parametro
 */
const TimerPure: React.FC<ITimerProps> = ({ time, variant, withIcon = true }) => {
  const hour = Math.floor(time / 3600)
  const minutes = Math.floor((time - hour * 3600) / 60)
  const seconds = time - (hour * 3600 + minutes * 60)

  const hoursFormat = (value: number) => value.toString().padStart(2, '0')

  // O valor padrão é um valor muito longo para ser exibido
  let formatedTime = `24h+`

  // O tempo é maior que uma hora
  if (time >= 3600) {
    formatedTime = `${hoursFormat(hour)}:${hoursFormat(minutes)}:${hoursFormat(seconds)}`
    // O tempo é menor que 1 minuto
  } else if (time < 60) {
    formatedTime = `${hoursFormat(seconds)}`
  } else {
    formatedTime = `${hoursFormat(minutes)}:${hoursFormat(seconds)}`
  }

  const content = (
    <Box p={1} display="flex" justifyContent="flex-start" alignItems="center">
      {withIcon && <TimerIcon fontSize={variant === 'compact' ? 'small' : 'default'} />}
      <Box ml={1}>
        <Typography variant={variant === 'compact' ? 'body1' : 'h6'}>{formatedTime}</Typography>
      </Box>
    </Box>
  )

  if (variant === 'compact') return content

  return (
    <Box width="fit-content">
      <Card variant="outlined">{content}</Card>
    </Box>
  )
}

export default TimerPure
