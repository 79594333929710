import React from 'react'
import styled from 'styled-components'
import { IUserSecret, IWorkspace, CommunityModule, IUsersTableValue, IModule } from 'collections'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import Box from '@material-ui/core/Box'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useIntl from '../../../../hooks/useIntl'

interface IUserModalPureProps {
  /** Se o modal é visível */
  isOpen: boolean
  /** Callback para fechar o dialog */
  handleClose: () => void
  /** Usuário selecionado */
  user?: IUserSecret
  /** Workspaces */
  workspaces: IWorkspace[]
  /** Evento de alteração do workspace */
  onChangeWorkspace: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Evento save */
  handleSave: () => void
  /** Evento de alteração do nome */
  onChangeName: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** Lista de módulos do community */
  modules: IModule[]
  /** Evento de alteração do módulo */
  onChangeModule: (moduleName: CommunityModule) => void
  /** Evento de alteração de email */
  onChangeEmail: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
  /** lista de oral languages */
  oralLanguages: IUsersTableValue[]
  /** lista de sign languages */
  signLanguages: IUsersTableValue[]
}

const DialogContainer = styled(Dialog)`
  && {
    border-radius: 10px;
  }
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    display: flex;
    align-items: center;
    padding: 15px 15px;
  }
`

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

/**
 * Componente Puro de edição e cadastro de usuário
 */
const UserModalPure: React.SFC<IUserModalPureProps> = ({
  isOpen,
  handleClose,
  user,
  workspaces,
  onChangeWorkspace,
  handleSave,
  onChangeName,
  modules,
  onChangeModule,
  onChangeEmail,
  oralLanguages,
  signLanguages,
}) => {
  const intl = useIntl()
  const roles = [
    {
      id: 'admin',
    },
    {
      id: 'user',
    },
  ]

  return (
    <DialogContainer TransitionComponent={Zoom} onClose={handleClose} open={isOpen}>
      <Box>
        <DialogTitle disableTypography>
          <Button onClick={handleClose}>
            <ArrowBackIos />
          </Button>
          <Typography>{user && user.displayName}</Typography>
        </DialogTitle>

        <Container style={{ marginLeft: '20px', marginRight: '20px' }}>
          <SideContainer>
            <InputLabel style={{ fontSize: '12px' }} id="name-label">
              {intl.get('pages.admin.name')}
            </InputLabel>
            <TextField value={user && user.displayName} onChange={onChangeName} />
            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="oral-language-label">
              {intl.get('pages.admin.oralLanguage')}
            </InputLabel>
            <Select fullWidth value={user && user.oralLanguageId} disabled>
              {oralLanguages &&
                oralLanguages.map((oralLanguage) => (
                  <MenuItem key={oralLanguage.id} value={oralLanguage.id}>
                    {oralLanguage.name}
                  </MenuItem>
                ))}
            </Select>

            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="workspace-label">
              Workspace
            </InputLabel>
            <Select fullWidth value={user?.workspaceId} onChange={onChangeWorkspace}>
              {workspaces &&
                workspaces.map((workspace: IWorkspace) => (
                  <MenuItem key={workspace.id} value={workspace.id}>
                    {workspace.id}
                  </MenuItem>
                ))}
            </Select>
          </SideContainer>

          <Divider style={{ marginLeft: '20px', marginRight: '20px' }} orientation="vertical" flexItem />
          <SideContainer>
            <InputLabel style={{ fontSize: '12px' }} id="email-label">
              Email
            </InputLabel>
            <TextField value={user && user.email} disabled={Boolean(user && user.uid)} onChange={onChangeEmail} />
            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="sign-language-label">
              {intl.get('pages.admin.signLanguage')}
            </InputLabel>
            <Select fullWidth value={user && user.signLanguageId} disabled>
              {signLanguages &&
                signLanguages.map((signLanguage) => (
                  <MenuItem key={signLanguage.id} value={signLanguage.id}>
                    {signLanguage.name}
                  </MenuItem>
                ))}
            </Select>
            <InputLabel style={{ fontSize: '12px', marginTop: '10px' }} id="role-label">
              {intl.get('pages.admin.role')}
            </InputLabel>
            <Select fullWidth value={''} disabled>
              {roles &&
                roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.id}
                  </MenuItem>
                ))}
            </Select>
          </SideContainer>
          <Divider style={{ marginLeft: '20px', marginRight: '20px' }} orientation="vertical" flexItem />
          <SideContainer>
            <Box
              p={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'flex-end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {modules.map((module) => (
                <Box style={{ marginTop: '10px' }} key={module.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={user?.modules && user.modules.includes(module.module)}
                        onChange={() => onChangeModule(module.module)}
                        style={{ padding: '5px' }}
                      />
                    }
                    label={<Typography variant="body2">{module['name']}</Typography>}
                  />
                </Box>
              ))}
            </Box>
          </SideContainer>
        </Container>
        <Box
          p={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Box p={1}>
            <Button onClick={handleClose} variant="outlined">
              {intl.get('messages.cancel')}
            </Button>
          </Box>
          <Box p={1}>
            <Button variant="contained" onClick={handleSave} color="primary">
              {intl.get('messages.save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogContainer>
  )
}

export default UserModalPure
