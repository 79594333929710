import React, { useEffect, MouseEvent, ChangeEvent, useState, SetStateAction, Dispatch } from 'react'
import {
  Box,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableHead,
} from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons'
import { IUserInfo, IWorkspacePermissions } from 'collections'
import styled from 'styled-components'
import useIntl from '../../../hooks/useIntl'

interface IUsersTable {
  workspaceUsers:
    | Record<
        string,
        {
          displayName?: string
          email?: string
          photoURL?: string
          workspaceId?: string
          permissions?: IWorkspacePermissions
        }
      >
    | undefined
  workspaceUsersToShow:
    | Record<
        string,
        {
          displayName?: string
          email?: string
          photoURL?: string
          workspaceId?: string
          permissions?: IWorkspacePermissions
        }
      >
    | undefined
  userData:
    | {
        [UserId: string]: {
          photoURL: string
          displayName: string
          roles: Record<string, boolean>
        }
      }
    | undefined
  handleClickAddUserBtn: (userId: string) => void
  setSelectedModules: Dispatch<SetStateAction<string[]>>
  setSelectedUser: Dispatch<
    SetStateAction<
      | {
          id: string
          name: string
          email: string
          workspaceId: string
          userRole: string
          permissions: IWorkspacePermissions
          isExternal?: boolean
        }
      | undefined
    >
  >
  page: number
  setPage: Dispatch<SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
}

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

const tablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  )
}

const createData = (
  id: string,
  name: string,
  email: string,
  workspaceId: string,
  roles: Record<string, boolean>,
  permissions: IWorkspacePermissions,
): {
  id: string
  name: string
  email: string
  workspaceId: string
  userRole: string
  permissions: IWorkspacePermissions
} => {
  let userRole = ''
  Object.entries(roles).map((role) => {
    if (userRole == '') userRole = role[0]
    else userRole += ' - ' + role[0]
  })
  return { id, name, email, workspaceId, userRole, permissions }
}

const UserRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }
  width: 100%;
`

const UserCell = styled(TableCell)`
  cursor: pointer;
`

const UsersTable: React.FC<IUsersTable> = ({
  workspaceUsers,
  workspaceUsersToShow,
  userData,
  handleClickAddUserBtn,
  setSelectedModules,
  setSelectedUser,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const intl = useIntl()

  const [rows, setRows] = useState<
    {
      id: string
      name: string
      email: string
      workspaceId: string
      userRole: string
      permissions: IWorkspacePermissions
    }[]
  >([])

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClickAddModuleToUser = (user: {
    id: string
    name: string
    email: string
    workspaceId: string
    userRole: string
    permissions: IWorkspacePermissions
  }) => {
    const _modules = user.userRole.split(' - ')

    const _user = workspaceUsers
      ? {
          ...user,
          permissions: workspaceUsers[user.id].permissions || user.permissions,
        }
      : user

    setSelectedUser(_user)
    setSelectedModules(_modules)
    handleClickAddUserBtn('')
  }

  const calcSortOfUsers = (a: [string, IUserInfo], b: [string, IUserInfo]): number => {
    if (!a[1].displayName || !b[1].displayName) return 1
    else return a[1].displayName < b[1].displayName ? -1 : 1
  }

  useEffect(() => {
    if (workspaceUsersToShow) {
      const _rows: {
        id: string
        name: string
        email: string
        workspaceId: string
        userRole: string
        permissions: IWorkspacePermissions
      }[] = []

      if (userData) {
        Object.entries(workspaceUsersToShow)
          .sort((a, b) => calcSortOfUsers(a, b))
          .map((user) => {
            _rows.push(
              createData(
                user[0],
                user[1].displayName || user[1].email || '',
                user[1].email || '',
                user[1].workspaceId || '',
                userData[user[0]] ? userData[user[0]].roles : {},
                user[1].permissions || {},
              ),
            )
          })
      }
      setRows(_rows)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceUsersToShow])

  // Espaços vazios quando não possuir todos os dados na ultima página.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom pagination table" style={{ minHeight: 700 }}>
        <TableHead>
          <TableCell component="th" scope="row">
            {intl.get('pages.admin.name')}
          </TableCell>
          <TableCell style={{ width: '40%', paddingRight: '10px' }} align="right">
            Workspace
          </TableCell>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
            <UserRow
              key={row.id}
              onClick={() => {
                handleClickAddModuleToUser(row)
              }}
            >
              <UserCell component="th" scope="row" align="left">
                {row.name}
              </UserCell>
              <UserCell style={{ width: '40%', paddingRight: '10px' }} align="right">
                {row.workspaceId}
              </UserCell>
            </UserRow>
          ))}
          {emptyRows > 0 && (
            <UserRow style={{ height: 59 * emptyRows }}>
              <TableCell colSpan={6} />
            </UserRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={tablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsersTable
