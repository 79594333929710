import React, { ChangeEvent, useEffect, useState } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Box, FormControl, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import useIntl from '../../../hooks/useIntl'
import { Database } from '@firebase/database'
import { IUser, ISentenceData, TCorpusGroup, WorkspaceId } from 'collections'
import useSnackbar from '../../../services/hooks/useSnackbar'
import { setPhraseToRecordVideoPriority } from '../../../services/phrases'
import { IVideoWithId } from '..'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { backVideoStateFunction } from '../../../services/firebase'

interface IModalBackPhraseToRecording {
  open: boolean
  handleCloseModal: () => void
  database: Database
  fsUser: IUser
  origins: string[]
  selectedVideo: IVideoWithId | null
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: '500px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const defaultSentece = {
  id: '',
  sentence: '',
  sentenceCategory: ['undefined'],
  isValidated: false,
  corpusGroup: 'TRAIN',
  sentenceOrigin: 'INTERNAL',
  priority: 10,
  clientId: 'HT',
  isRecording: false,
  needResearch: false,
  isRepeated: false,
  userRecording: '',
  isExternal: false,
}

const ModalBackPhraseToRecording: React.FC<IModalBackPhraseToRecording> = ({
  open,
  handleCloseModal,
  database,
  fsUser,
  origins,
  selectedVideo,
}) => {
  const [sentenceToInclude, setSentenceToInclude] = useState<ISentenceData>(defaultSentece)

  const intl = useIntl()
  const showSnackbar = useSnackbar()
  const [opennedConfirmBackSentenceDialog, setOpennedConfirmBackSentenceDialog] = useState(false)

  const handleClose = () => {
    handleCloseModal()
  }

  useEffect(() => {
    if (!selectedVideo) return
    const _sentenceToInclude: ISentenceData = {
      priority: 10,
      sentence: selectedVideo.sentence,
      sentenceOrigin: selectedVideo.sentenceOrigin?.toUpperCase() || '',
      sentenceCategory: selectedVideo.sentenceCategory || ['undefined'],
      isValidated: false,
      corpusGroup: selectedVideo.corpusGroup,
      clientId: selectedVideo.clientId,
      isRecording: false,
      needResearch: false,
      isRepeated: false,
      userRecording: '',
      isExternal: selectedVideo.isExternal,
    }
    setSentenceToInclude(_sentenceToInclude)
  }, [selectedVideo])

  const handleSavePhrase = async () => {
    if (!selectedVideo) return
    try {
      backVideoStateFunction({
        videoId: selectedVideo.id,
        newState: 'DELETED',
        workspaceId: fsUser.workspace.id as WorkspaceId,
      }).then(
        () => {
          setPhraseToRecordVideoPriority(database, fsUser, sentenceToInclude)

          showSnackbar(
            intl.get('messages.videoReturnedToQueue', {
              queue: intl.get('messages.recordingQueue'),
            }),
            { variant: 'success' },
          )
        },
        () => {
          showSnackbar(
            intl.get('messages.errorToReturnVideoState', {
              queue: intl.get('messages.recordingQueue'),
            }),
            { variant: 'error' },
          )
        },
      )

      showSnackbar(intl.get('messages.savedSuccessfully'), {
        variant: 'success',
      })
    } catch (error) {
      showSnackbar((error as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  const toggleConfirmBackSentenceDialog = () => {
    setOpennedConfirmBackSentenceDialog(!opennedConfirmBackSentenceDialog)
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {intl.get('pages.processVideo.returnSentenceToRecord')}
        </DialogTitle>
        <DialogContent dividers style={{ display: 'flex', flexDirection: 'column' }}>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              value={sentenceToInclude.sentence}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSentenceToInclude({
                  ...sentenceToInclude,
                  sentence: event.target.value,
                })
              }
              label={intl.get('pages.phraseManager.phrase')}
              variant="filled"
              style={{ marginTop: '10px' }}
              maxRows={10}
              fullWidth
              multiline
            />
          </Box>

          <Box>
            <TextField
              value={sentenceToInclude.clientId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSentenceToInclude({
                  ...sentenceToInclude,
                  clientId: event.target.value,
                })
              }
              label={intl.get('messages.clientId')}
              variant="filled"
              style={{ marginTop: '10px' }}
              fullWidth
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <FormControl style={{ marginTop: '10px', width: '50%' }}>
                <Typography id="x-label">Corpus</Typography>
                <Select
                  id="grouped-select"
                  style={{ color: 'inherit' }}
                  onChange={(
                    event: ChangeEvent<{
                      value: unknown
                    }>,
                  ) => {
                    const _corpusGroup = event.target.value as TCorpusGroup
                    setSentenceToInclude({
                      ...sentenceToInclude,
                      corpusGroup: _corpusGroup,
                    })
                  }}
                  defaultValue="TRAIN"
                  value={sentenceToInclude.corpusGroup}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem key="0" value={'TRAIN'}>
                    {intl.get('pages.signOnDemand.corpusGroup.train')}
                  </MenuItem>
                  <MenuItem key="1" value={'VALIDATION'}>
                    {intl.get('pages.signOnDemand.corpusGroup.validation')}
                  </MenuItem>
                </Select>
              </FormControl>
              {!fsUser.isExternal && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '45%',
                  }}
                >
                  <FormControl style={{ marginTop: '10px' }}>
                    <Typography id="x-label">{intl.get('pages.phraseManager.filters.external')}</Typography>
                    <Select
                      fullWidth
                      id="grouped-select"
                      style={{ color: 'inherit' }}
                      onChange={(
                        event: ChangeEvent<{
                          value: unknown
                        }>,
                      ) => {
                        const _value = event.target.value as string
                        const _isExternal = _value == 'true' ? true : false
                        setSentenceToInclude({
                          ...sentenceToInclude,
                          isExternal: _isExternal,
                        })
                      }}
                      value={sentenceToInclude.isExternal}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem key={''} value={''}>
                        {intl.get('pages.phraseManager.filters.removeFilter')}
                      </MenuItem>
                      <MenuItem key="0" value={'true'}>
                        {intl.get('messages.yes')}
                      </MenuItem>
                      <MenuItem key="1" value={'false'}>
                        {intl.get('messages.no')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
            <FormControl
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <Typography id="x-label">{intl.get('pages.phraseManager.sentenceOrigin')}</Typography>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Select
                  id="grouped-select"
                  style={{ color: 'inherit' }}
                  defaultValue="INTERNAL"
                  fullWidth
                  value={sentenceToInclude.sentenceOrigin}
                  onChange={(
                    event: ChangeEvent<{
                      value: unknown
                    }>,
                  ) => {
                    const _sentenceOrigin = event.target.value as string
                    setSentenceToInclude({
                      ...sentenceToInclude,
                      sentenceOrigin: _sentenceOrigin,
                    })
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {origins &&
                    origins.map((origin) => (
                      <MenuItem key="0" value={origin}>
                        {origin}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Button
            autoFocus
            color="primary"
            onClick={() => {
              setOpennedConfirmBackSentenceDialog(true)
            }}
          >
            {intl.get('messages.save')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={opennedConfirmBackSentenceDialog}
        toggleOpen={toggleConfirmBackSentenceDialog}
        handleYes={() => {
          if (!sentenceToInclude) {
            showSnackbar(intl.get('pages.phraseManager.warnings.informSentence'), {
              variant: 'warning',
            })
            return
          }
          handleSavePhrase()
          handleClose()
          setOpennedConfirmBackSentenceDialog(false)
        }}
        title={intl.get('messages.backVideoStatus')}
        subtitle={intl.get('messages.wantReturnVideo', {
          queue: intl.get('messages.recordingQueue'),
        })}
      />
    </>
  )
}

export default ModalBackPhraseToRecording
