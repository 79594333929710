import { IAnimation } from './types/animation'
import { addDoc, updateDoc, setDoc, getDoc, DocumentReference, CollectionReference } from 'firebase/firestore'

const createUpdateFunction = async (ref: DocumentReference, animationData: Partial<IAnimation>) => {
  return await updateDoc(ref, animationData)
}

export const updateAnimation = createUpdateFunction

const createAddFunction = async (ref: CollectionReference, animationData: Partial<IAnimation>) => {
  return await addDoc(ref, animationData)
}

export const addAnimation = createAddFunction

const createSetFunction = async (ref: DocumentReference, animationData: Partial<IAnimation>) => {
  return await setDoc(ref, animationData)
}

export const setAnimation = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getAnimation = createGetFunction
