import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useGlobal } from 'reactn'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import TimelineItem from './TimelineItem/Index'
import TimelineCursor from './TimelineCursor/index'
import TimelineItemCreator from './TimelineItemCreator/index'
import logger from '../../services/logger'
import useResize from '../../services/hooks/useResize'
import { ITimelineRegion, IGlobalAttr, ITimeLineSegmentProps } from 'collections'
import useTimelineRegions from '../../hooks/useTimelineRegions'
import useSnackbar from '../../services/hooks/useSnackbar'
import useJobMeter from '../../services/hooks/useJobMeter'
import useIntl from '../../hooks/useIntl'

import blankPixelImage from '../../images/blankPixel.png'
const { log } = logger('Timeline')

const mainTimelineHeight = '100px'
const timelineHeight = '100px'

const TimelineMainStyle = styled(Grid)`
  position: relative;
  width: 100%;
  height: ${mainTimelineHeight};
  left: 0px;
  top: 10px;
`

const TimelineContainerStyle = styled(Paper)`
  position: absolute
  width: 100%;
  height: ${timelineHeight};
  left: 0px;
  bottom: 0px;
  padding-top: 0px;
  border: 1px solid #cecece;
`

const TimelineEmptyTooltip = styled(Grid)`
  position: absolute;
  pointer-events: none;
  left: 0px;
  bottom: 0px;
  font-size: 1.3em;
  width: 100%;
  height: ${timelineHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

/**
 * Linha do tempo do vídeo, aqui adicionamos uma serie de itens customizaveis pelo usários
 * O objetivo deste componente é tornar possivel que os usuários mantenham um fluxo de marcação de glosas
 * baseado em tempo de vídeo
 */
const Timeline: React.FC<ITimeLineSegmentProps> = ({
  sentence,
  timeFrame,
  timelineCursor,
  timelineCursorFns,
  editing,
  onlyOneSegment,
  duration,
  startFrame,
}) => {
  // Estados do editor de sinais
  const [isLockedItems, setIsLockedItems] = useState(false)
  // Referencias
  const areaRef = useRef<HTMLDivElement>(null)
  const dragableItemRef = useRef<HTMLImageElement>(null)
  // Hooks
  const showSnackbar = useSnackbar()
  const [timelineTags, timelineTagsFns] = useTimelineRegions()
  const resize = useResize()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [timelinePixelsSizeState, setTimelinePixelsSize] = useGlobal<IGlobalAttr, 'timelinePixelsSize'>(
    'timelinePixelsSize',
  )
  const [timelineLeftPixelsOffsetState, setTimelineLeftPixelsOffset] = useGlobal<
    IGlobalAttr,
    'timelineLeftPixelsOffset'
  >('timelineLeftPixelsOffset')
  const [fsVideoDocData] = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')

  const timelinePixelsSize = timelinePixelsSizeState || 0
  const timelineLeftPixelsOffset = timelineLeftPixelsOffsetState || 0
  const durationFrames = duration || fsVideoDocData?.duration || 0
  const cursorPosition = Math.round(
    ((timelineCursor.position - (startFrame ? startFrame - 1 : 0)) / durationFrames) * timelinePixelsSize,
  )
  const [isPlaying, setIsPlaying] = useState(false)
  const [count, setCount] = useState(0)
  const [currentRegion, setCurrentRegion] = useState<ITimelineRegion>()

  const { startJobMeter } = useJobMeter('segmentVideo')
  const intl = useIntl()
  //
  // Comportamento(s) do TimelineItemCreator
  // ----------------------------------------------------------------------------

  const handleOnStartItemCreation = () => {
    startJobMeter()
    setIsLockedItems(true)
  }

  const handleOnEndItemCreation = (startFrame: number, endFrame: number) => {
    if (timelineTags.length == 1 && onlyOneSegment) {
      setIsLockedItems(false)
      showSnackbar(
        intl.get('components.timeline.maxTimelineRegions', {
          variant: 'error',
        }),
      )
      return
    }
    const tagDuration = endFrame - startFrame
    if (tagDuration >= appConfig.minTagFramesDuration) {
      timelineTagsFns.add({
        id: (Math.round(startFrame) + Math.round(endFrame)).toString() || '',
        representation: 'Unknown',
        startFrame,
        endFrame,
        type: 'sign',
        text: '',
      })
    } else {
      showSnackbar(
        intl.get('components.timeline.minFramesDuration', {
          minTagFramesDuration: appConfig.minTagFramesDuration,
        }),
        { variant: 'error' },
      )
    }
    setIsLockedItems(false)
  }

  //
  // Comportamento(s) do TimelineItem
  // ----------------------------------------------------------------------------

  const handleOnStartDragItem = () => {
    setIsLockedItems(true)
    setIsPlaying(false)
  }

  const handleOnItemModified = (id: string, region: ITimelineRegion) => {
    timelineTagsFns.update(id, region)
    setIsLockedItems(false)
    setIsPlaying(false)
  }

  const handleOnItemRemoved = (id: string) => {
    timelineTagsFns.remove(id)
    setIsPlaying(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeydown = (ev: KeyboardEvent) => {
    //Captura tecla que o usuário pressionou
    const shortcut = ev.code.toUpperCase()
    //Resgata o índice da timeline na qual o cursor está
    const indexItem = timelineTags.findIndex(
      (el) =>
        el.endFrame >= ((cursorPosition || 0) * durationFrames) / timelinePixelsSize &&
        el.startFrame <= ((cursorPosition || 0) * durationFrames) / timelinePixelsSize,
    )
    if (indexItem && indexItem > -1 && shortcut === 'DELETE') {
      handleOnItemRemoved(timelineTags[indexItem].id || '')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown, true)
    return () => {
      window.removeEventListener('keydown', handleKeydown, true)
    }
  }, [handleKeydown])

  /**
   * Referencia da TimelineArea encontrada/modificada
   */
  useEffect(() => {
    const { current } = areaRef
    if (current) {
      const bounding = current.getBoundingClientRect()
      log(
        `
        Bounds da area definidos:
        Esquerda: ${bounding.left},
        Largura ${bounding.width}
        `,
      )
      setTimelinePixelsSize(bounding.width)
      setTimelineLeftPixelsOffset(bounding.left)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaRef, resize])

  const handleOnMouseMove = useCallback(
    (event: React.MouseEvent | React.DragEvent) => {
      if (!isPlaying) {
        const { clientX } = event
        const normalized = clientX - timelineLeftPixelsOffset
        const currentFrame = Math.round((normalized / timelinePixelsSize) * durationFrames)
        timelineCursorFns.setPosition(currentFrame + (startFrame || 0))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timelineLeftPixelsOffset, timelinePixelsSize, durationFrames],
  )

  const handleOnPlayRegion = (region: ITimelineRegion) => {
    setCount(region.startFrame)
    setCurrentRegion(region)
    setIsPlaying(!isPlaying)
  }

  useEffect(() => {
    setIsLockedItems(isPlaying)
    if (isPlaying && currentRegion) {
      const id = setTimeout(() => {
        setCount((c) => c + 1)
        if (count >= currentRegion.endFrame) {
          setCount(currentRegion.startFrame)
          clearTimeout(id)
        }

        clearTimeout(id)
      }, timeFrame)
      return () => clearTimeout(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, isPlaying])

  useEffect(() => {
    timelineCursorFns.setPosition(count)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return (
    <TimelineMainStyle style={{ height: startFrame ? '20px' : mainTimelineHeight }}>
      <TimelineContainerStyle
        onMouseMove={handleOnMouseMove}
        ref={areaRef}
        color="default"
        style={{ height: startFrame ? '20px' : mainTimelineHeight }}
      >
        {/** O criador de itens deve ser spawnado por traz dos itens ja existentes*/}
        {!startFrame && !editing && (
          <TimelineItemCreator
            onMouseMove={handleOnMouseMove}
            onStart={handleOnStartItemCreation}
            onEnd={handleOnEndItemCreation}
            position={cursorPosition}
            timelineCursorFns={timelineCursorFns}
          />
        )}

        {/** Sinais (itens da linha do tempo) */}
        {!startFrame &&
          timelineTags.map((tag) => (
            <TimelineItem
              sentence={sentence}
              tag={tag}
              key={tag?.id}
              id={tag?.id || null}
              isLocked={isLockedItems}
              onStartDrag={handleOnStartDragItem}
              onModified={handleOnItemModified}
              onRemoved={handleOnItemRemoved}
              onPlayRegion={() => handleOnPlayRegion(tag)}
              isPlaying={isPlaying}
              text={tag.text}
              cursorPosition={cursorPosition}
              editing={editing}
            />
          ))}

        {/** A linha do tempo está vazia, iremos exibir um texto de ajuda */}
        {!startFrame && timelineTags.length === 0 && !isLockedItems && (
          <TimelineEmptyTooltip>{intl.get('components.timeline.timelineText')}</TimelineEmptyTooltip>
        )}
      </TimelineContainerStyle>

      {/** 
        Cursor meramente ilustrativo, não serve para nada além de um feedback visual
      */}
      <TimelineCursor position={cursorPosition} startFrame={startFrame} />

      <img src={blankPixelImage} ref={dragableItemRef} />
    </TimelineMainStyle>
  )
}

export default Timeline
