// Cores utilizadas

//MOTION
export const motionColors = {
  neutralColors: {
    high: {
      main: '#F5F5F5',
      medium: '#C4C4C4',
      light: '#DEDEDE',
    },
  },
  highlightColors: {
    main: '#413987',
    light: '#5B69C2',
  },
  brandColors: {
    primary: {
      dark: '#333333',
      medium: '#666666',
      main: '#F5F5F5',
    },
    secundary: {
      main: '#F06F06',
      disabled: '#979797',
    },
  },
  fontColor: '#333',
  backgroundColor: '#FFF',
}

export const motionFonts = {
  logoFont: 'KG Always A Good Time',
  textFont: 'Roboto',
}
