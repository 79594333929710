import * as firestore from '@google-cloud/firestore'
import * as storage from '@google-cloud/storage'
import { auth } from 'firebase-functions'

export type DocumentReferenceAdmin = firestore.DocumentReference
export type TimestampAdmin = firestore.Timestamp
export type DocumentSnapshotAdmin = firestore.DocumentSnapshot
export type QueryDocumentSnapshotAdmin = firestore.QueryDocumentSnapshot
export type DocumentDataAdmin = firestore.DocumentData
export type FirestoreAdmin = firestore.Firestore
export type CollectionReferenceAdmin = firestore.CollectionReference
export type StorageAdmin = storage.Bucket

export const FieldValueAdmin = firestore.FieldValue
export const FieldPathAdmin = firestore.FieldPath

export type UserRecordAdmin = auth.UserRecord
