import { FC } from 'react'
import styled from 'styled-components'

import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import SchoolIcon from '@material-ui/icons/School'
import HelpIcon from '@material-ui/icons/Help'
import Link from '@material-ui/core/Link'
import Lock from '@material-ui/icons/Lock'
import Tooltip from '@material-ui/core/Tooltip'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import useIntl from '../../../hooks/useIntl'

interface IModuleCardProps {
  title: string
  contributions: number
  imageUrl: string
  handleClick: () => void
  helpUrl?: string
  locked?: boolean
}

const Image = styled(CardMedia)`
  height: 250px;
  background-size: cover !important;
  background-position: center !important;
`
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkIcon: {
      color: theme.palette.type === 'dark' ? '#FFF' : theme.palette.grey[900],
    },
  }),
)

export const ModuleCard: FC<IModuleCardProps> = ({ title, contributions, imageUrl, handleClick, helpUrl, locked }) => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Box height="100%">
      <Card>
        <CardActionArea component="a" onClick={handleClick}>
          <Image image={imageUrl} />
        </CardActionArea>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            <Typography variant="h6">{title}</Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              {locked && (
                <Tooltip title={intl.get('messages.locked')}>
                  <Lock style={{ marginRight: '5px' }} className={classes.linkIcon} />
                </Tooltip>
              )}

              <Box display="flex" justifyContent="center" alignItems="center">
                <Link target="_blank" href={helpUrl}>
                  <Box style={{ marginTop: '5px' }} mr={1}>
                    <Tooltip title={intl.get('messages.help')}>
                      <HelpIcon className={classes.linkIcon} />
                    </Tooltip>
                  </Box>
                </Link>
              </Box>
              <Tooltip title={intl.get('messages.contributions')}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <SchoolIcon />
                  <Box ml={1}>
                    <Typography variant="h6">{contributions || 0}</Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default ModuleCard
