import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'

import ApplicationDialogPure from '../Pure'
import useDialog from '../../../services/hooks/useDialog'
import useFeatureToggle from '../../../services/hooks/useFeatureToggle'
import { CommunityFeatures } from 'collections'
import useIntl from '../../../hooks/useIntl'

const FeatureToggleDialog: React.FC = () => {
  const intl = useIntl()
  const [open, toggleOpen] = useDialog('featureToggle')
  const [{ featuresMap }, { setFeature, reset }] = useFeatureToggle()

  return (
    <ApplicationDialogPure
      title={intl.get('messages.featureToggle')}
      labelledby="feedback-dialog"
      open={open}
      component={
        <List>
          {Object.entries(featuresMap).map(([featureName, featureValue]) => (
            <ListItem key={featureName}>
              <ListItemText primary={featureName}></ListItemText>
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  checked={featureValue}
                  onChange={() =>
                    setFeature(
                      // Cast de string para o Enum
                      featureName as CommunityFeatures,
                      // O novo estado é o inverso do estado atual
                      !featureValue,
                    )
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      }
      actionsElement={
        <>
          <Button onClick={reset} color="primary">
            Reset defaults
          </Button>
          <Button onClick={toggleOpen} color="primary">
            Close
          </Button>
        </>
      }
      toggleOpen={toggleOpen}
    />
  )
}

export default FeatureToggleDialog
