import React, { useEffect } from 'react'
import useIntl from '../../hooks/useIntl'
import { ConfirmationDialogPure } from './Pure'

interface IConfirmationDialogProps {
  open: boolean
  toggleOpen: () => void
  handleYes: () => void
  title: string
  subtitle: string
  disableKeyUp?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  open,
  toggleOpen,
  handleYes,
  title,
  subtitle,
  children,
  disableKeyUp,
  maxWidth,
}) => {
  const intl = useIntl()

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.keyCode === 13 && !disableKeyUp) {
      handleYes()
    }
  }

  useEffect(() => {
    if (open) {
      window.addEventListener('keyup', handleKeyUp)
    } else {
      window.removeEventListener('keyup', handleKeyUp)
    }
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <ConfirmationDialogPure
      title={title}
      labelledby="report-dialog"
      open={open}
      options={{
        subtitle: subtitle,
        buttonText: intl.get('messages.yes'),
        buttonCancelText: intl.get('messages.no'),
      }}
      toggleOpen={toggleOpen}
      send={handleYes}
      maxWidth={maxWidth}
    >
      {children}
    </ConfirmationDialogPure>
  )
}

export default ConfirmationDialog
