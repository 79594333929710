import React, { useEffect, useState, useCallback, useRef } from 'react'
import styled, { keyframes } from 'styled-components'

import Grid from '@material-ui/core/Grid'
import RecIcon from '@material-ui/icons/VideoCall'
import FinishIcon from '@material-ui/icons/Check'
import Fab from '@material-ui/core/Fab'
import Box from '@material-ui/core/Box'
import SettingsIcon from '@material-ui/icons/Settings'
import BackIcon from '@material-ui/icons/ArrowBack'

import useCamera from '../../../services/hooks/useCamera'
import Layout from '../../../components/Layout'
import useModuleSignSentence from '../../../services/hooks/useModuleSignSentence'
import CameraStream from '../CameraStream/index'
import useSnackbar from '../../../services/hooks/useSnackbar'
import TextHeader from '../../../components/TextHeader/index'
import VideoPreview from './VideoPreview/index'
import { CommunityShortcuts, preProcessSentenceToRecord } from 'collections'
import CardMessage from '../../../components/CardMessage'
import useJobMeter from '../../../services/hooks/useJobMeter'
import SettingsMenu from '../SettingsMenu'
import useSentence from '../../../hooks/useSentence'
import useRecordingDuration from '../../../hooks/useRecordingDuration'
import useCountdown from '../../../hooks/useCountdown'
import useIntl from '../../../hooks/useIntl'
import useShortcuts from '../../../hooks/useShortcuts'
import useLockModules from '../../../hooks/useLockModules'
import LockModuleDialog from '../../../components/LockModuleDialog'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'
import { ref, remove } from 'firebase/database'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import { useDatabase } from 'reactfire'
const FabWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const animation = keyframes`
  from {  width: 0%; }
  to { width: 100%; }
`

interface ITimedProgressProps {
  speed: number
}

const TimedProgress = styled.div<ITimedProgressProps>`
  height: 5px;
  background-color: #eee;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0%;
  z-index: 2;
  &&:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: red;
    animation: ${animation} ${(p) => p.speed}ms linear infinite;
  }
`

const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: black;
  z-index: 0;
`

const finishIcon = <FinishIcon style={{ marginRight: 10 }} />
const recIcon = <RecIcon style={{ marginRight: 10 }} />

interface ISignSentenceMainProps {
  path?: string
  setIsRecording?: React.Dispatch<React.SetStateAction<boolean>>
  setIsRecorded?: React.Dispatch<React.SetStateAction<boolean>>
  updateIsRecording?: () => Promise<void>
}

const SignSentenceMain: React.FC<ISignSentenceMainProps> = ({ setIsRecording, setIsRecorded, updateIsRecording }) => {
  const { lockModule, lockedRecordVideo, checkLockedModule, open, toggleOpen } = useLockModules()
  const [mainButtonDisabled, setMainDisabled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [camState, camFn] = useCamera()
  const [{ recordedVideos, numberOfVideosToRecord }, { createContribution, recordVideo, removeVideo }] =
    useModuleSignSentence()
  const showSnackbar = useSnackbar()
  const hasPermissionOnModule = useCheckHasPermission('signSentence')
  const [videoDuration] = useRecordingDuration()
  const [countdownState] = useCountdown()

  const { startJobMeter, endJobMeter, resetJobMeter } = useJobMeter('signSentence')
  const intl = useIntl()
  const { shortcutEvent } = useShortcuts('signSentence')
  const [sentenceState, sentenceFns] = useSentence('signSentence')
  const buttonSettingsRef = useRef<HTMLButtonElement>(null)
  const [cameraDevices, setCameraDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo | null>(null)
  const [pinContour, setPinContour] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const fsUser = useFsUserDocData()
  const db = useDatabase()

  const handleChangeCamera = (value: string) => {
    const _selectedDevice = cameraDevices.find((el) => el.deviceId == value) || null
    setSelectedCamera(_selectedDevice)
    const resultString = localStorage.getItem('selectedCamera')

    const device = resultString ? JSON.parse(resultString || '{}') : null

    localStorage.setItem('selectedCamera', JSON.stringify(_selectedDevice))
    if (device.deviceId != value) {
      camFn.closeStream()
      camFn.getStream()
    }
  }

  const handleChangePinContour = (value: boolean) => {
    setPinContour(value)
    localStorage.setItem('pinContour', JSON.stringify(value))
  }

  useEffect(() => {
    if (lockedRecordVideo) {
      checkLockedModule('signSentence')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedRecordVideo])

  useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (updateIsRecording) updateIsRecording()
      event.preventDefault()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateIsRecording])

  const getCameraDevices = async () => {
    const devices = await camFn.getCameras()
    const resultString = localStorage.getItem('selectedCamera')

    const device = resultString ? JSON.parse(resultString || '{}') : null
    setSelectedCamera(device)
    setCameraDevices(devices)
  }

  useEffect(() => {
    setPinContour(localStorage.getItem('pinContour') == 'true' ? true : false)
    try {
      sentenceFns.refresh()
      // Quando o componente for montado, iremos ligar a camera
      camFn.getStream()

      getCameraDevices()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      showSnackbar(e.message, { variant: 'error' })
    }
    return () => {
      // Quando for desmontado, desligamos a camera
      camFn.closeStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Se carregar esse componente do nada, pegue uma frase.
  useEffect(() => {
    // Sempre que a sentença mudar, resetamos o job meter
    resetJobMeter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentenceState.sentence])

  /**
   * Callback do botão principal, grava novos vídeos ou faz upload dos atuais
   */
  const handleMainButton = useCallback(async () => {
    setMainDisabled(true)
    // Verifica se todos os vídos ja foram gravados
    if (recordedVideos.length === numberOfVideosToRecord) {
      await createContribution(setIsRecording ? false : true)

      if (!setIsRecording && !setIsRecorded && sentenceState && sentenceState.sentence) {
        const sentenceId = preProcessSentenceToRecord(sentenceState.sentence.normalize('NFKC'), fsUser.workspace.id)
        await remove(
          ref(
            db,
            `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/recordVideoPriority/${sentenceId}`,
          ),
        )
      }

      endJobMeter()

      lockModule()
      if (setIsRecording) setIsRecording(false)
      if (setIsRecorded) setIsRecorded(true)
      // Faltam vídeos para gravar
    } else {
      try {
        startJobMeter()

        const numberOfMissingVideos = numberOfVideosToRecord - recordedVideos.length
        // Grava os vídeos restantes
        for (let i = 0; i < numberOfMissingVideos; i++) {
          await recordVideo()
        }
      } catch (err) {
        console.log(err)
        showSnackbar('Falha em alguma gravação!', { variant: 'error' })
      }
    }
    setMainDisabled(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recordedVideos.length,
    numberOfVideosToRecord,
    createContribution,
    endJobMeter,
    showSnackbar,
    startJobMeter,
    recordVideo,
    lockModule,
  ])

  useEffect(() => {
    if (sentenceState.isLoading || mainButtonDisabled) return
    switch (shortcutEvent) {
      case CommunityShortcuts.deleteSignSentence:
        if (recordedVideos.length > 0) removeVideo(0)
        break
      case CommunityShortcuts.recordFinishSignSentence:
        handleMainButton()
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutEvent])

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  // Usuário não tem papel de escritor
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.signSentence')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  const camOverlay = countdownState.countdownStep !== 0 ? countdownState.countdownStep.toString() : ''
  const canUpload = recordedVideos.length === numberOfVideosToRecord
  const buttonText = canUpload
    ? intl.get('pages.signSentence.main.finishButton')
    : intl.get('pages.signSentence.main.recordButton')

  return (
    <Layout title={intl.get('modules.signSentence')} requiredModule={'signSentence'}>
      <Grid container item direction="column" style={{ zIndex: 1, minHeight: '90vh' }}>
        {setIsRecording && (
          <BackIcon
            cursor={'pointer'}
            onClick={() => {
              setIsRecording(false)
            }}
            style={{
              position: 'absolute',
              marginTop: '10px',
              marginLeft: '10px',
            }}
          />
        )}
        <TextHeader showDurationChanger communityModule={'signSentence'} />
        <Grid item xs container direction="column" alignItems="flex-end">
          {/** Varre todos os vídeos gravados e renderiza na tela */}
          {recordedVideos.map((video, index) => (
            <VideoPreview key={index} video={video} clear={() => removeVideo(index)} />
          ))}
        </Grid>
        <FabWrapper>
          <Fab
            variant="extended"
            size="medium"
            color={canUpload ? 'primary' : 'secondary'}
            onClick={handleMainButton}
            disabled={sentenceState.isLoading || mainButtonDisabled}
          >
            {canUpload ? finishIcon : recIcon}
            {buttonText}
          </Fab>
          <Box ml={1}>
            <Fab ref={buttonSettingsRef} variant="extended" size="medium" color="default" onClick={toggleMenu}>
              <SettingsIcon />
            </Fab>
          </Box>
        </FabWrapper>
      </Grid>
      <Absolute>
        {camState.isRecording && <TimedProgress speed={videoDuration} />}
        <CameraStream
          overlay={camOverlay}
          showTemplate={pinContour || countdownState.countdownStep !== 0}
          textUnableAccessCamera={intl.get('pages.signSentence.cameraStream.unableAccessCamera')}
          videoRef={videoRef}
        />
      </Absolute>

      <SettingsMenu
        anchorEl={buttonSettingsRef?.current || undefined}
        onClose={toggleMenu}
        open={isMenuOpen}
        cameraDevices={cameraDevices}
        handleChangeCamera={handleChangeCamera}
        selectedCamera={selectedCamera}
        pinContour={pinContour}
        handleChangePinContour={handleChangePinContour}
      />
      <LockModuleDialog open={open} toggleOpen={toggleOpen} />
    </Layout>
  )
}

export default SignSentenceMain
