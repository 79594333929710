import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { getNewCategoriesText } from '../../services/utils'

interface IMultiSelectSentenceCategory {
  options: string[]
  value: string[]
  handleChangeOptions: (value: string[]) => void
}

const MultiSelectSentenceCategory: React.FC<IMultiSelectSentenceCategory> = ({
  options,
  value,
  handleChangeOptions,
}) => {
  const fsUser = useFsUserDocData()
  let _value: string[] = []
  if (fsUser.oralLanguageId == 'por' && value && value?.length) _value = getNewCategoriesText(value)
  else _value = value

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option}
      value={_value || []}
      onChange={(_, value) => handleChangeOptions(value)}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} variant={'outlined'} />}
    />
  )
}

export default MultiSelectSentenceCategory
