import { useEffect, useState, useRef, RefObject } from 'react'
import interact from 'interactjs'

interface IInteractableProps {
  /**
   * Quando verdadeiro, não permite que o elemento ultrapasse os bounds do elemento pai
   */
  contained?: boolean
  baseX?: number
  baseY?: number
  /**
   * O elemento pode ser arrastado
   */
  draggable?: 'x' | 'y' | boolean
  /**
   * O elemento pode ser escalonado
   */
  resizable?: 'x' | 'y' | boolean
  /**
   * Determina que um elemento pode ser dropado aqui
   */
  dropzone?: boolean
  dropzoneAccept?: string
  lockDragAxis?: boolean | 'x' | 'y' | 'xy' | 'start'
  width?: number
  className?: string
  minX?: number
  maxX?: number
  minSize?: number
  style?: React.CSSProperties
  boundsDraggable?: string | undefined
  positionX?: number
  onDragEnter?: () => void
  onDragLeave?: () => void
  onDropActivate?: () => void
  onDropDeactivate?: () => void
  onDrop?: () => void
  onDrag?: (x: number) => void
}

interface ITransform {
  offsetY: number
  offsetX: number
  width: number
  x: number
  y: number
}

interface IUseInteractable {
  transform: ITransform
  elemRef: RefObject<HTMLDivElement>
  clientX: number
  clientY: number
  statusDragging: 'none' | 'begin' | 'end'
  handleDragMove: (event: Interact.DragEvent) => void
  handleStatusDragging: (value: React.SetStateAction<'none' | 'begin' | 'end'>) => void
}

const useInteractable = (children: IInteractableProps): IUseInteractable => {
  const [transform, setTransform] = useState<ITransform>({
    width: 0,
    offsetX: 0,
    offsetY: 0,
    x: 0,
    y: 0,
  })

  const [state, setState] = useState<'none' | 'dragging' | 'rezising' | 'idle'>('none')
  const [statusDragging, setStatusDragging] = useState<'none' | 'begin' | 'end'>('none')
  const [clientX, setClientX] = useState(0)
  const [clientY, setClientY] = useState(0)
  const [interactor, setInteractor] = useState<Interact.Interactable>()

  const elemRef = useRef<HTMLDivElement>(null)
  //
  // Controle de movimento do item
  // ----------------------------------------------------------------------------

  const handleDragStart = (event: Interact.DragEvent) => {
    setClientX(event.clientX)
    setClientY(event.clientY)
    setStatusDragging('begin')
    setState('dragging')
  }

  const handleDragMove = (event: Interact.DragEvent) => {
    const { dx, dy } = event
    setTransform((prev) => ({
      ...prev,
      offsetX: prev.offsetX + dx,
      offsetY: prev.offsetY + dy,
    }))
  }

  //
  // Controle de escala do item
  // ----------------------------------------------------------------------------

  const handleResizeMove = (event: Interact.ResizeEvent) => {
    const { rect, deltaRect } = event
    setTransform((prev) => ({
      ...prev,
      width: rect.width,
      offsetX: deltaRect ? prev.offsetX + deltaRect.left : prev.offsetX,
    }))
  }

  //
  // Inicialização do componente
  // ----------------------------------------------------------------------------

  const setup = (element: HTMLElement) => {
    const interactable = interact(element)

    // O elemento é arrastavel
    if (children.draggable) {
      interactable.draggable({
        lockAxis: children.lockDragAxis,
        onmove: handleDragMove,
        onend: () => setState('idle'),
        onstart: handleDragStart,
        modifiers: [
          interact!.modifiers!.restrictRect({
            restriction: children.boundsDraggable,
          }),
        ],
      })
    }

    if (children.dropzone) {
      interactable.dropzone({
        accept: `[data-item-type="${children.dropzoneAccept}"]`,
        ondropactivate: children.onDropActivate,
        ondropdeactivate: children.onDropDeactivate,
        ondrop: children.onDrop,
        ondragenter: children.onDragEnter,
        ondragleave: children.onDragLeave,
      })
    }

    // O elemento é escalonavel
    if (children.resizable) {
      interactable
        .resizable({
          // resize from all edges and corners
          edges: { left: true, right: true, bottom: false, top: false },
          inertia: true,
        })
        .on('resizemove', handleResizeMove)
        .on('resizestart', () => setState('rezising'))
        .on('resizeend', () => setState('idle'))
    }

    setInteractor(interactable)
  }

  useEffect(() => {
    const { current } = elemRef
    if (current) setup(current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elemRef])

  // Componente desmontado
  useEffect(() => {
    return () => {
      if (interactor) interactor.unset()
    }
  }, [interactor])

  //
  // Limitação de resizing e dragging em um bounding especifico
  // ----------------------------------------------------------------------------

  useEffect(() => {
    if (state === 'idle') {
      setStatusDragging('end')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    setTransform((prev) => ({
      ...prev,
      width: children.width || 0,
      x: children.baseX || 0,
      y: children.baseY || 0,
      offsetX: 0,
      offsetY: 0,
    }))

    // O type Modifier não é exportado em Interact, portanto utilizamos any
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dragModifiers: any[] = []
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resizeModifiers: any[] = []

    const bounds: Interact.Rect = {
      bottom: 0,
      top: 0,
      left: children.minX || 0,
      right: children.maxX || 0,
    }

    // O elemento não deve sair dos boundings do pai
    if (children.contained) {
      dragModifiers.push(
        interact.modifiers!.restrictRect({
          restriction: bounds,
        }),
      )
      resizeModifiers.push(
        interact.modifiers!.restrictEdges({
          outer: bounds,
        }),
      )
    }

    // Aplicaas configurações para conter o elemento dentro de um rect especifico
    // (Drag)
    if (interactor && children.draggable && children.contained) {
      interactor.draggable({
        modifiers: [
          interact.modifiers!.restrictRect({
            restriction: bounds,
          }),
        ],
      })

      // Aplica as configurações para conter o elemento dentro do rect especifico
      // (Resize)
      if (interactor && children.resizable && children.contained && children.minSize) {
        interactor.resizable({
          modifiers: [
            ...resizeModifiers,

            // minimum size
            interact.modifiers!.restrictSize({
              min: { width: children.minSize, height: 0 },
              max: {
                width: (children.maxX || 0) - (children.minX || 0),
                height: 0,
              },
            }),
          ],
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    children.minX,
    children.maxX,
    children.width,
    interactor,
    children.contained,
    children.baseX,
    children.baseY,
    children.minSize,
  ])

  useEffect(() => {
    if (state === 'dragging' && children.onDrag) {
      children.onDrag(transform.offsetX)
    }

    if (state !== 'dragging' && children.positionX) {
      setTransform((prev) => ({
        ...prev,
        offsetX: children.positionX! | 0,
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transform.offsetX, children.positionX])

  const handleStatusDragging = (value: React.SetStateAction<'none' | 'begin' | 'end'>) => {
    setStatusDragging(value)
  }

  return {
    transform,
    elemRef,
    clientX,
    clientY,
    statusDragging,
    handleDragMove,
    handleStatusDragging,
  }
}
export default useInteractable
