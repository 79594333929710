import React from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { IUserInfo, UserId } from 'collections'

const AppBarStyled = styled(AppBar)`
  && {
    @media (max-width: 600px) {
      padding: 10px 0px;
    }
  }
`

interface IContributionsHeader {
  /** Dicionario de usuários que aparecem na seleção de filtros */
  users: Record<UserId, IUserInfo>
  /** Id do usuário que foi selecionado no dropdown de seleção */
  selectedUserId: UserId
  /** Lista de ids de usuários selecionados foi alterada */
  onUserChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
}

/**
 * Este é o cabeçalho da exibição das Contribuições.
 * Aqui exibimos os filtros necessarios para a listagem de vídeos
 */
const ContributionsHeader: React.FC<IContributionsHeader> = ({ users, selectedUserId, onUserChange }) => {
  return (
    <AppBarStyled color="default" position="static">
      <Toolbar>
        <Grid container spacing={2}>
          {/** O seletor de usuários só está disponivel para administradores do workspace */}
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <InputLabel id="users-label">User(s)</InputLabel>
            <Select fullWidth value={selectedUserId} onChange={onUserChange}>
              {/** Adiciona o nome de todos os usuários  */}
              {Object.entries(users).map(([userId, { email, displayName }]) => (
                <MenuItem key={userId} value={userId}>
                  {`${displayName || 'Anonymous'} ${email ? '- ' + email : ''}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBarStyled>
  )
}

export default ContributionsHeader
