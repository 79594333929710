import React, { useCallback, useState } from 'react'
import { useFirestore } from 'reactfire'
import { IUsersTableValue, IWorkspace, OralLanguageId, setWorkspace, SignLanguageId } from 'collections'
import useSnackbar from '../../../../services/hooks/useSnackbar'
import WorkspaceModalPure from './Pure'
import useIntl from '../../../../hooks/useIntl'
import { doc } from '@firebase/firestore'

interface IWorkspaceModalPureProps {
  /** Se o modal é visível */
  isOpen: boolean
  /** Callback para definir o estado do modal */
  setIsOpen: (state: boolean) => void
  /** Workspaces */
  workspaces: IWorkspace[]
  /** Lista de oral languages */
  oralLanguages: IUsersTableValue[]
  /** Lista de sign languages */
  signLanguages: IUsersTableValue[]
}

/**
 * Componente de edição e cadastro de usuário
 */
const WorkspaceModal: React.FC<IWorkspaceModalPureProps> = ({
  isOpen,
  setIsOpen,
  workspaces,
  oralLanguages,
  signLanguages,
}) => {
  const intl = useIntl()
  const showSnackbar = useSnackbar()
  const [workspaceName, setWorkspaceName] = useState('')
  const [signLanguageId, setSignLanguageId] = useState('ase')
  const [oralLanguageId, setOralLanguageId] = useState('eng')
  const [workspaceId, setWorkspaceId] = useState('')
  const firestore = useFirestore()
  const handleClose = () => {
    setIsOpen(false)
  }

  const onChangeName = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string
    setWorkspaceName(value)
  }, [])

  const onChangeWorkspaceId = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string
    setWorkspaceId(value)
  }, [])

  const onChangeOralLanguage = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const _oralLanguageId = event.target.value as string
    setOralLanguageId(_oralLanguageId)
  }, [])

  const onChangeSignLanguage = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const _signLanguageId = event.target.value as string
    setSignLanguageId(_signLanguageId)
  }, [])

  const handleSave = async () => {
    try {
      if (!workspaceId) {
        showSnackbar(intl.get('pages.admin.worspaceModal.missingId'), {
          variant: 'error',
        })
        return
      }
      if (!workspaceName) {
        showSnackbar(intl.get('pages.admin.worspaceModal.missingName'), {
          variant: 'error',
        })
        return
      }
      if (!oralLanguageId) {
        showSnackbar(intl.get('pages.admin.worspaceModal.missingOralLanguage'), { variant: 'error' })
        return
      }
      if (!signLanguageId) {
        showSnackbar(intl.get('pages.admin.worspaceModal.missingSignLanguage'), { variant: 'error' })
        return
      }

      if (workspaces.filter((workspace) => workspace.id?.toLowerCase() == workspaceId.toLowerCase()).length > 0) {
        showSnackbar(intl.get('messages.workspaceAlready'), {
          variant: 'error',
        })
        return
      }
      await setWorkspace(doc(firestore, 'workspaces', workspaceId), {
        name: workspaceName,
        oralLanguageId: oralLanguageId as OralLanguageId,
        signLanguageId: signLanguageId as SignLanguageId,
        roles: {},
      })
      showSnackbar(intl.get('messages.savedSuccessfully'), {
        variant: 'success',
      })
      handleClose()
    } catch (e) {
      console.log(e)
      showSnackbar((e as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  return (
    <WorkspaceModalPure
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={handleSave}
      onChangeName={onChangeName}
      workspaceName={workspaceName}
      signLanguageId={signLanguageId}
      oralLanguageId={oralLanguageId}
      onChangeSignLanguage={onChangeSignLanguage}
      onChangeOralLanguage={onChangeOralLanguage}
      onChangeWorkspaceId={onChangeWorkspaceId}
      workspaceId={workspaceId}
      oralLanguages={oralLanguages}
      signLanguages={signLanguages}
    />
  )
}

export default WorkspaceModal
