import React, { useState, useEffect } from 'react'

import ApplicationDialogPure from '../Pure'
import useDialog from '../../../services/hooks/useDialog'
import { feedbackFunction } from '../../../services/firebase'
import useSnackbar from '../../../services/hooks/useSnackbar'
import useSentence from '../../../hooks/useSentence'
import useIntl from '../../../hooks/useIntl'

interface IState {
  sentence: string
  isPending: boolean
}

const FeedbackSentenceDialog = (): JSX.Element => {
  const [sentenceState, sentenceFns] = useSentence('signSentence')
  const [state, setState] = useState<IState>({ sentence: '', isPending: false })
  const [open, toggleOpen] = useDialog('feedbackSentence')
  const showSnackbar = useSnackbar()
  const intl = useIntl()

  useEffect(() => {
    setState((s: IState) => ({ ...s, sentence: sentenceState.sentence || '' }))
  }, [sentenceState])

  // Seta a frase do estado local do componente
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sentence = event.target.value
    setState((s) => ({ ...s, sentence }))
  }

  // Enviar formulário
  const send = async () => {
    const { sentence } = state
    setState((s) => ({ ...s, isPending: true }))

    if (!sentence) {
      await sentenceFns.refresh()
      setState((s) => ({ ...s, isPending: false }))
      return
    }

    feedbackFunction({
      text: intl.get('components.feedbackSentenceDialog.changeSentence', {
        sentence: sentence,
        sentenceState: sentenceState,
      }),
    })
    sentenceFns.set(sentence)
    showSnackbar(intl.get('messages.thankYou'), { variant: 'success' })
    setState((s) => ({ ...s, isPending: false }))
    toggleOpen()
  }

  const buttonText = state.sentence ? 'Ok' : intl.get('components.feedbackSentenceDialog.newSentence')

  return (
    <ApplicationDialogPure
      title={intl.get('components.feedbackSentenceDialog.setSentence')}
      labelledby="Set sentence dialog"
      open={open}
      options={{
        subtitle: intl.get('components.feedbackSentenceDialog.setSentence'),
        feedback: state.sentence,
        isPending: state.isPending,
        buttonText,
        labelTextFiel: intl.get('components.feedbackSentenceDialog.typeSentence'),
      }}
      toggleOpen={toggleOpen}
      send={send}
      handleChange={handleInputChange}
    />
  )
}

export default FeedbackSentenceDialog
