import { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

interface ICardMessageProps {
  title: string
  subtitle?: string
}

export const CardMessagePure: FC<ICardMessageProps> = ({ title, subtitle }) => {
  return (
    <Card
      style={{
        margin: '10%',
        border: '1px solid #d5d4d4',
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="inherit" gutterBottom>
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CardMessagePure
