import React, { createContext, useState, useContext, useCallback } from 'react'

import logger from '../logger'
import { recordVideoFromStream } from '../utils'
import { IVideoFile } from 'collections'

const { log, error } = logger('useCamera')

interface ICameraState {
  isEnabled: boolean
  src?: string
  resolution?: number
  stream?: MediaStream
  isRecording: boolean
}

const initialState: ICameraState = {
  isEnabled: false,
  isRecording: false,
  resolution: 480,
}

interface ICameraOps {
  getStream: () => void
  closeStream: () => void
  recordVideo: (duration: number, midiaId: string) => Promise<IVideoFile>
  getCameras: () => Promise<MediaDeviceInfo[]>
}

const initialOps = {
  getStream: () => {
    throw new Error('Estado não iniciado')
  },
  closeStream: () => {
    throw new Error('Estado não iniciado')
  },
  recordVideo: () => {
    throw new Error('Estado não iniciado')
  },
  getCameras: () => {
    throw new Error('Estado não iniciado')
  },
}

type TCameraContext = [ICameraState, ICameraOps]

export const CameraContext = createContext<TCameraContext>([initialState, initialOps])

export const ProviderCamera: React.FC = ({ children }) => {
  // Infelizmente só podemos salvar strings no LocalStorage...
  const [cameraState, setCameraState] = useState(initialState)

  const getCameras = async () => {
    const devices = (await navigator.mediaDevices.enumerateDevices()).filter((el) => el.kind == 'videoinput')
    return devices
  }

  /**
   * Pega o stream da câmera e salva no state.
   */
  const getStream = useCallback(async () => {
    const resultString = localStorage.getItem('selectedCamera')

    const device = resultString ? JSON.parse(resultString || '{}') : null
    let deviceId = device?.deviceId || null

    const devices = await getCameras()

    if (devices && devices.length > 0 && !deviceId) {
      deviceId = devices[0].deviceId
    }

    log('Iniciando streams de câmera')
    // Funciona no chrome
    let stream: MediaStream | undefined = undefined
    let resolution = 480

    // Tentar pegar na resolução Full HD
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { ideal: deviceId },
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: false,
      })
      log('Pego 1080p')
      resolution = 1080
    } catch (err) {
      error('Erro ao pegar full 1080p')
    }
    // Tentar pegar na resolução HD
    if (!stream) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { ideal: deviceId },
            width: { ideal: 1280 },
            height: { ideal: 720 },
          },
          audio: false,
        })
        log('Pego 720p')
        resolution = 720
      } catch (err) {
        error('Erro ao pegar 720p')
      }
    }
    // Tentar pegar qualquer uma
    if (!stream) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { ideal: deviceId } },
          audio: false,
        })
        log('Pego stream resolução baixa.')
      } catch (err) {
        error('Erro ao pegar stream qualquer')
      }
    }
    if (stream) {
      setCameraState({
        isEnabled: true,
        stream,
        isRecording: false,
        resolution: resolution,
      })
    } else {
      setCameraState(initialState)
      throw new Error(`Can't retrieve camera stream.`)
    }
  }, [])

  const closeStream = useCallback(() => {
    setCameraState((state) => {
      if (state.stream) {
        log('Fechando streams da câmera')
        const tracks = state.stream.getTracks()
        tracks.forEach((track) => track.stop())
        return { ...state, isEnabled: false }
      }
      log('Câmera não inicializada.')
      return initialState
    })
  }, [])

  const recordVideo = async (duration: number, midiaId: string) => {
    if (!cameraState.stream) {
      throw new Error('Não é possível gravar: stream indisponível')
    }

    if (cameraState.isRecording) {
      throw new Error('Já estamos gravando um vídeo. Aguarde um pouco')
    }

    setCameraState((oldState) => ({ ...oldState, isRecording: true }))
    const video = await recordVideoFromStream(cameraState.stream, duration, midiaId)
    setCameraState((oldState) => ({ ...oldState, isRecording: false }))
    return video
  }

  return (
    <CameraContext.Provider value={[cameraState, { closeStream, getStream, recordVideo, getCameras }]}>
      {children}
    </CameraContext.Provider>
  )
}

export default (): TCameraContext => useContext(CameraContext)
