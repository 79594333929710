import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { isAfter, subDays } from 'date-fns'
import Rating from '../../../components/Rating'

interface ISignsListPureProps {
  /** Lista de sinais */
  data: firebase.default.firestore.DocumentData[]
  /** Callback para reservar o sinal e ir animar */
  onSignClick: (rowIndex: number) => void
  /** Texto da coluna sinal */
  textSign: string
  /** Texto da coluna estrelas */
  textStars: string
  /** Texto da coluna status */
  textStatus: string
  /** Texto da coluna animador */
  textAnimator: string
  /** Texto da coluna data */
  textDate: string
  /** Texto da coluna popularidade */
  textPopularity: string
  /** Texto do status pendente */
  textPending: string
  /** Texto do status com erros */
  textHasErrors: string
  /** Texto do status feito */
  textDone: string
  /** Texto do status animando */
  textAnimating: string
  /** Texto do status não revisado */
  textUnreviewed: string
  /** Texto do status não revisado */
  textFingerspell: string
  /** Texto do número de segmentos */
  textNumberOfSegments: string
  /** Texto da data do report */
  textReportDate: string
}

const SignRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }
`

const SignCell = styled(TableCell)`
  cursor: pointer;
`

/**
 *  Componente puro da listagem de sinais
 */
const SignsListPure: React.FC<ISignsListPureProps> = ({
  data,
  onSignClick,
  textSign,
  textStars,
  textStatus,
  textAnimator,
  textDate,
  textPopularity,
  textPending,
  textHasErrors,
  textDone,
  textAnimating,
  textUnreviewed,
  textNumberOfSegments,
  textReportDate,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Signs list">
        <TableHead>
          <TableRow>
            <TableCell>{textSign}</TableCell>
            <TableCell>{textStars}</TableCell>
            <TableCell>{textStatus}</TableCell>
            <TableCell>{textAnimator}</TableCell>
            <TableCell>{textReportDate}</TableCell>
            <TableCell>{textDate}</TableCell>
            <TableCell>{textPopularity}</TableCell>
            <TableCell>{textNumberOfSegments.toUpperCase()}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <SignRow key={i} onClick={() => onSignClick(i)}>
              <SignCell>{row.glosa}</SignCell>

              <SignCell>
                <Rating justifyContent="left" alignItems="left" value={row.stars} />
              </SignCell>

              <SignCell>
                {row.isFingerspell
                  ? 'fingerspell'
                  : row.isAnimating.value && isAfter(row.isAnimating.lastUpdate.toDate(), subDays(new Date(), 1))
                  ? textAnimating
                  : row.currentAnimationHasErrors
                  ? textHasErrors
                  : row.currentAnimation && row.isAnimated && row.currentAnimationHasErrors === false
                  ? textDone
                  : row.currentAnimation && row.isAnimated && !row.currentAnimationHasErrors
                  ? textUnreviewed
                  : textPending}
              </SignCell>

              <SignCell>
                {(row.isAnimating.value &&
                  isAfter(row.isAnimating.lastUpdate.toDate(), subDays(new Date(), 1)) &&
                  row.animator) ||
                  row.animatorCurrentAnimation}
              </SignCell>
              <SignCell>
                {row.currentAnimationReviewedAt && format(row.currentAnimationReviewedAt?.toDate(), 'dd/MM/yyyy')}
              </SignCell>

              <SignCell>{row.animationCreatedAt && format(row.animationCreatedAt?.toDate(), 'dd/MM/yyyy')}</SignCell>
              <SignCell>{row.corpusPopularity}</SignCell>
              <SignCell>{row.numberOfSegments}</SignCell>
            </SignRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SignsListPure
