import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useStorage, useFirestore } from 'reactfire'
import { useFsUserDocRef, useFsUserDocData } from '../../../../hooks/useFsUser'
import {
  getPathBlendFile,
  setAnimation,
  DocumentReference,
  serverTimestamp,
  CollectionReferenceClient,
} from 'collections'
import UploadModalPure from './Pure'
import { uploadToStorage } from '../../../../services/utils'
import useJobMeter from '../../../../services/hooks/useJobMeter'
import useIntl from '../../../../hooks/useIntl'
import { doc, runTransaction, Timestamp } from '@firebase/firestore'
import { ref } from '@firebase/storage'
import { getCollectionReference } from 'collections'

interface IUploadModalProps {
  /** Se o modal é visível */
  isOpen: boolean
  /** Refêrencia ao sinal atual */
  signDoc: DocumentReference
  /** Glosa do sinal */
  glosa: string
  /** Callback para definir o estado de loading */
  setIsLoading: (state: boolean) => void
  /** Callback para definir o estado do modal */
  setIsOpen: (state: boolean) => void
}

interface IAnimFiles {
  /** Arquivo do blender */
  blendFile: File | null
}

/**
 * Componente de adição de arquivos de animação
 */
const UploadModal: React.FC<IUploadModalProps> = ({ isOpen, signDoc, glosa, setIsLoading, setIsOpen }) => {
  const storage = useStorage()
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const firestore = useFirestore()
  const [{ blendFile }, setFiles] = useState<IAnimFiles>({
    blendFile: null,
  })
  const { endJobMeter } = useJobMeter('animation')
  const intl = useIntl()
  const textClickOrDragHere = intl.get('pages.animation.uploadModal.clickOrDragHere')
  const textNavigate = intl.get('pages.animation.uploadModal.navigate')
  const textThroughYourFiles = intl.get('pages.animation.uploadModal.throughYourFiles')
  const textInstructions = intl.get('pages.animation.uploadModal.instruction')
  const textBlendFile = intl.get('pages.animation.uploadModal.blendFile')
  const textTitle = intl.get('pages.animation.uploadModal.title')

  const handleClose = () => {
    setIsOpen(false)
  }
  useEffect(() => {
    if (blendFile) {
      endJobMeter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blendFile])

  const saveAnimationFiles = useCallback(async ({ blendFile }: IAnimFiles) => {
    if (blendFile) {
      try {
        setIsLoading(true)

        const animationsCollection = getCollectionReference(fsUser.workspace, 'animations')

        const newId = doc(animationsCollection as CollectionReferenceClient).id
        const path = getPathBlendFile(fsUser.workspace.id, newId)

        await uploadToStorage(storage, path, blendFile, 'application/x-blender', glosa)
        const _createdAt = serverTimestamp()
        await runTransaction(firestore, async (transaction) => {
          transaction.update(signDoc, {
            isAnimated: true,
            isAnimating: {
              value: false,
              user: null,
              lastUpdate: null,
            },
            currentAnimation: {
              animation: doc(animationsCollection as CollectionReferenceClient, newId),
              createdAt: _createdAt,
              createdBy: fsUserRef,
              errors: null,
            },
            currentAnimationHasErrors: null,
            currentAnimationReviewedAt: null,
          })
        })

        await setAnimation(doc(animationsCollection as CollectionReferenceClient, newId), {
          isDisabled: {
            value: false,
            user: null,
            lastUpdate: null,
          },
          createdBy: fsUserRef,
          createdAt: _createdAt as Timestamp,
          oralLanguageId: fsUser.oralLanguageId,
          signLanguageId: fsUser.signLanguageId,
          htaAnimation: '',
          htaSize: 0,
          blendFile: ref(storage, path).toString(),
          comment: null,
          isValidated: {
            value: false,
            user: null,
            lastUpdate: null,
          },
          errors: null,
          sign: signDoc,
          reviewedBy: null,
          userRatings: null,
          reviewedAt: null,
        })

        handleClose()
        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const blendNewFile = acceptedFiles.find(
        ({ type, name }) => type === 'application/x-blender' || name.endsWith('.blend'),
      )

      setFiles(({ blendFile }) => {
        const newFiles: IAnimFiles = {
          blendFile: blendNewFile || blendFile,
        }

        saveAnimationFiles(newFiles)

        return newFiles
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const dropzoneState = useDropzone({ onDrop })

  return (
    <UploadModalPure
      isOpen={isOpen}
      handleClose={handleClose}
      dropzoneState={dropzoneState}
      blendFile={blendFile}
      textClickOrDragHere={textClickOrDragHere}
      textNavigate={textNavigate}
      textThroughYourFiles={textThroughYourFiles}
      textBlendFile={textBlendFile}
      textInstructions={textInstructions}
      textTitle={textTitle}
    />
  )
}

export default UploadModal
