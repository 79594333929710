import React, { Suspense } from 'react'

import { ISign, DocumentReference, ISegmentView, IMenuOptions } from 'collections'
import SignViewPure from './Pure'
import Skeleton from '@material-ui/lab/Skeleton'
import useIntl from '../../hooks/useIntl'
import { useFirestoreData } from '../../hooks/useFirestoreData'
import { routes } from '../../community'

interface ISignViewProps {
  signRef: DocumentReference
  startWithValue?: ISign
  variant?: 'badge' | 'compact' | 'default'
  onRemove?: () => void
  showControls?: boolean
  segmentView?: ISegmentView
}

interface ISignViewVideoProps extends ISignViewProps {
  sign: ISign
  segmentView?: ISegmentView
}

const SignViewVideo: React.FC<ISignViewVideoProps> = ({ sign, variant, onRemove, segmentView }) => {
  const intl = useIntl()
  const textTooltipGlosaNumberOfSegments = intl.get('components.signView.tooltipGlosaNumberOfSegments', {
    percentage: sign.corpusPopularity * 100 || 0,
    glosa: sign.glosa,
  })
  const textWithoutVideo = intl.get('messages.withoutVideo')

  const handleClickShowInHTube = () => {
    const url = `${window.location.origin}${routes.hTube.replace(':clusterId', segmentView?.movement?.id || '')}`
    window.open(url, '_blank')?.focus()
  }

  const menuOptionsSignView: IMenuOptions[] = [
    {
      title: intl.get('components.signView.showInHTube'),
      onClick: handleClickShowInHTube,
    },
  ]

  return (
    <>
      <SignViewPure
        onRemove={onRemove}
        variant={variant}
        glosa={sign.glosa}
        textTooltipGlosaNumberOfSegments={textTooltipGlosaNumberOfSegments}
        textWithoutVideo={textWithoutVideo}
        segmentView={segmentView}
        numberOfSegments={sign.numberOfSegments}
        menu={menuOptionsSignView}
      />
    </>
  )
}

/**
 * Exibição de dados do sinal
 */
const SignViewData: React.FC<ISignViewProps> = ({ signRef, onRemove, variant, segmentView }) => {
  const sign = useFirestoreData(signRef.path).data as ISign
  const intl = useIntl()
  const textTooltipGlosaNumberOfSegments = intl.get('components.signView.tooltipGlosaNumberOfSegments', {
    percentage: sign.corpusPopularity * 100 || 0,
    glosa: sign.glosa,
  })
  const textWithoutVideo = intl.get('messages.withoutVideo')

  return (
    <>
      {segmentView ? (
        <SignViewVideo sign={sign} signRef={signRef} onRemove={onRemove} variant={variant} segmentView={segmentView} />
      ) : (
        <>
          <SignViewPure
            onRemove={onRemove}
            variant={variant}
            glosa={sign.glosa}
            textTooltipGlosaNumberOfSegments={textTooltipGlosaNumberOfSegments}
            textWithoutVideo={textWithoutVideo}
            numberOfSegments={sign.numberOfSegments}
          />
        </>
      )}
    </>
  )
}

/**
 * Exibe a os dados de um sinal ou o loading
 */
const SignView: React.FC<ISignViewProps> = (props) => {
  const suspensed = <Skeleton variant="rect" width="100%" height="100%" />
  return (
    <Suspense fallback={suspensed}>
      <SignViewData {...props} />
    </Suspense>
  )
}

export default SignView
