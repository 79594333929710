import React, { useCallback, useState, useEffect } from 'react'
import { useFirestore } from 'reactfire'
import {
  IUserSecret,
  IWorkspace,
  WorkspaceId,
  CommunityModule,
  OralLanguageId,
  IUsersTableValue,
  IModule,
} from 'collections'
import useSnackbar from '../../../../services/hooks/useSnackbar'
import UserModalPure from './Pure'
import { createFirebaseUserFunction } from '../../../../services/firebase'
import { useAuth } from 'reactfire'
import useIntl from '../../../../hooks/useIntl'
import { doc } from '@firebase/firestore'
import { sendPasswordResetEmail } from '@firebase/auth'

interface IUserModalPureProps {
  /** Se o modal é visível */
  isOpen: boolean
  /** Callback para definir o estado do modal */
  setIsOpen: (state: boolean) => void
  /** Usuário selecionado */
  userSelected?: IUserSecret
  /** Workspaces */
  workspaces: IWorkspace[]
  /** Atualiza lista de usuários */
  getUsers: () => void
  /** lista de oral languages */
  oralLanguages: IUsersTableValue[]
  /** lista de sign languages */
  signLanguages: IUsersTableValue[]
}

/**
 * Componente de edição e cadastro de usuário
 */
const UserModal: React.FC<IUserModalPureProps> = ({
  isOpen,
  setIsOpen,
  userSelected,
  workspaces,
  getUsers,
  oralLanguages,
  signLanguages,
}) => {
  const [user, setUser] = useState<IUserSecret>()
  const firestore = useFirestore()
  const showSnackbar = useSnackbar()
  const auth = useAuth()
  const intl = useIntl()
  const [modules] = useState([
    {
      name: intl.get('modules.signSentence'),
      module: 'signSentence',
    },
    {
      name: intl.get('modules.segmentVideo'),
      module: 'segmentVideo',
    },
    {
      name: intl.get('modules.findIdenticalSegments'),
      module: 'findIdenticalSegments',
    },
    {
      name: intl.get('modules.validateTranslation'),
      module: 'validateTranslation',
    },
    {
      name: intl.get('modules.animation'),
      module: 'animation',
    },
    {
      name: intl.get('modules.reviewAnimation'),
      module: 'reviewAnimation',
    },
    {
      name: intl.get('modules.hTube'),
      module: 'hTube',
    },
    {
      name: intl.get('modules.signOnDemand'),
      module: 'signOnDemand',
    },
    {
      name: intl.get('modules.processVideo'),
      module: 'processVideo',
    },
  ])

  const handleClose = () => {
    setIsOpen(false)
  }
  useEffect(() => {
    const _user: IUserSecret = { ...userSelected }
    setUser(_user)
  }, [userSelected])

  const onChangeWorkspace = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      let _user: IUserSecret | null = {}
      if (user) _user = { ...user }
      const workspaceId = event.target.value as WorkspaceId
      const workspaceSelected = workspaces.find((value: IWorkspace) => value.id === workspaceId)
      _user['workspaceId'] = workspaceId
      _user['workspace'] = doc(firestore, 'workspaces', workspaceId)
      _user['oralLanguageId'] = workspaceSelected?.oralLanguageId
      _user['signLanguageId'] = workspaceSelected?.signLanguageId
      setUser(_user)
    },
    [workspaces, user, firestore],
  )

  const onChangeName = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const _user: IUserSecret = { ...user }
      const userName = event.target.value as string
      _user['displayName'] = userName
      setUser(_user)
    },
    [user],
  )

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const _user: IUserSecret = { ...user }
      const email = event.target.value as string
      _user['email'] = email
      setUser(_user)
    },
    [user],
  )

  const onChangeModule = useCallback(
    (moduleName: CommunityModule) => {
      const _user: IUserSecret = { ...user }
      const index = _user?.modules?.indexOf(moduleName) || -1
      if (index > -1) {
        _user?.modules?.splice(index, 1)
      } else {
        _user?.modules?.push(moduleName)
      }
      setUser(_user)
    },
    [user],
  )

  const handleSave = async () => {
    try {
      if (!user) {
        showSnackbar(intl.get('messages.failed'), { variant: 'error' })
        return
      }
      if (!user.email) {
        showSnackbar(intl.get('messages.missingEmail'), { variant: 'error' })
        return
      }
      await createFirebaseUserFunction({
        uid: user.uid || '',
        email: user.email || '',
        displayName: user.displayName || '',
        workspaceId: user.workspaceId || '',
        role: user.role || '',
        signLanguageId: user.signLanguageId || '',
        oralLanguageId: user.oralLanguageId || '',
        modules: user.modules || [],
        isExternal: false,
      })
      auth.languageCode = user.oralLanguageId === OralLanguageId.por ? 'pt' : 'en'
      if (!user.uid)
        await sendPasswordResetEmail(auth, user.email, {
          // Depois que o usuário resetar a senha e clicar no botão continuar, será redirecionado para esta página
          url: 'https://community.handtalk.me',
          handleCodeInApp: false,
        })
      showSnackbar(intl.get('messages.savedSuccessfully'), {
        variant: 'success',
      })
      handleClose()
      getUsers()
    } catch (e) {
      console.log(e)
      showSnackbar((e as TypeError | RangeError | EvalError).message, {
        variant: 'error',
      })
    }
  }

  return (
    <UserModalPure
      workspaces={workspaces}
      isOpen={isOpen}
      user={user}
      handleClose={handleClose}
      onChangeWorkspace={onChangeWorkspace}
      handleSave={handleSave}
      onChangeName={onChangeName}
      onChangeModule={onChangeModule}
      modules={(modules as IModule[]) || []}
      onChangeEmail={onChangeEmail}
      oralLanguages={oralLanguages}
      signLanguages={signLanguages}
    />
  )
}

export default UserModal
