/**
 * 🚧🚧🚧 ATENÇÃO 🚧🚧🚧
 * Este arquivo está originalmente em:
 * ./tools/shared/services/logger.ts
 * Se você encountrou em outro lugar, ele é um link-simbólico. Ao modificar
 * este arquivo, tome cuidado para que outros projetos não sejam quebrados!
 */

import debug from 'debug'

interface ILogger {
  log: debug.Debugger
  warn: debug.Debugger
  error: debug.Debugger
}

/**
 * Exporta um objeto com log, warn e error. Use-os para logar.
 * @param domain Texto que identifica de onde vem esse logger, geralmente o nome do arquivo.
 */
const logger = (domain: string): ILogger => {
  const log = debug(`ht:${domain}`)
  log.log = console.log.bind(console)

  const warn = debug(`ht:${domain}`)
  warn.log = console.warn.bind(console)

  const error = debug(`ht:${domain}`)
  return { log, warn, error }
}

export default logger
