import React, { useEffect, useState, useCallback, createRef } from 'react'
import { useGlobal } from 'reactn'
import styled from 'styled-components'
import { useRoute, useLocation } from 'wouter'
import { useStorage, useDatabase } from 'reactfire'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useFsUserDocData, useFsUserDocRef } from '../../../hooks/useFsUser'
import useSnackbar from '../../../services/hooks/useSnackbar'
import Layout from '../../../components/Layout'
import logger from '../../../services/logger'
import FramePlayer from '../../../components/FramePlayer'
import {
  IGlobalAttr,
  SegmentId,
  ISegment,
  ISimilarity,
  UserId,
  CommunityShortcuts,
  getPathFramesJson,
  WorkspaceId,
  DocumentReference,
  IVideo,
} from 'collections'
import { routes } from '../../../community'
import Paper from '@material-ui/core/Paper'
import { green } from '@material-ui/core/colors'
import ContributionMetadataView from '../../../components/ContributionMetadataView'
import UserMenu from '../../../components/UserMenu'
import LinearProgress from '@material-ui/core/LinearProgress'
import useTimelineCursor from '../../../hooks/useTimelineCursor'
import useJobMeter from '../../../services/hooks/useJobMeter'
import {
  addContribFindIdenticalSegmentsFunction,
  updateMatchSegmentsPriorityFunction,
} from '../../../services/firebase'
import useIntl from '../../../hooks/useIntl'
import useShortcuts from '../../../hooks/useShortcuts'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import useContributionMetadata from '../../../hooks/useContributionMetadata'
import Preloader from '../../../components/Preloader'
import { Card } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useFsSettingsDocData } from '../../../hooks/useFsSettingsDocData'
import { getDownloadURL, ref } from '@firebase/storage'
import { get, ref as refDb } from '@firebase/database'
import { getDocumentReference, getFirestoreDocument, updateFirestoreDocument } from 'collections'

const { log } = logger('FindIdenticalSegments')

const VideoContainer = styled(Grid)`
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

interface IFindIdenticalSegmentsProps {
  path: string
}

interface ISegmentMatch extends ISegment {
  videoDuration: number
  videoSentence: string
}

/**
 * Modulo de encontrar sinais identicos
 */
const FindIdenticalSegments: React.FC<IFindIdenticalSegmentsProps> = () => {
  const fsUser = useFsUserDocData()
  const [, routeParams] = useRoute(routes.findIdenticalSegments)
  const segmentQueueId: string = routeParams?.segmentQueueId || ''
  const [segmentId, setSegmentId] = useState<string>('')
  const [segmentRef, setSegmentRef] = useState<DocumentReference | null>(null)
  const [segment, setSegment] = useState<ISegmentMatch>()
  const [url, setUrl] = useState('')

  const storage = useStorage()
  const [timelineCursor, timelineCursorFns] = useTimelineCursor()
  const [timelineCursorIdentical, timelineCursorFnsIdentical] = useTimelineCursor()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const [, setLocation] = useLocation()
  const { startJobMeter, resetJobMeter, endJobMeter } = useJobMeter('findIdenticalSegments')
  const [similarity, setSimilarity] = useState<ISimilarity>()
  const [votes, setVotes] = useState<ISimilarity>()
  const [identicalSegments, setIdenticalSegments] = useState<ISegmentMatch[]>()
  const fsUserRef = useFsUserDocRef()
  const [indexIdenticalSegment, setIndexIdenticalSegment] = useState(0)
  const intl = useIntl()
  const { shortcutEvent } = useShortcuts('findIdenticalSegments')
  const [autoPlayLeftVideo, setAutoPlayLeftVideo] = useState(false)
  const buttonYesRef = createRef<HTMLButtonElement>()
  const buttonNoRef = createRef<HTMLButtonElement>()
  const buttonSkipRef = createRef<HTMLButtonElement>()
  const buttonBackRef = createRef<HTMLButtonElement>()
  const [isLoadingSegment, setIsLoadingSegment] = useState(true)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [opennedDialog, setOpennedDialog] = useState(false)
  const [opennedConfirmDialog, setOpennedConfirmDialog] = useState(false)
  const [segmentReportId, setSegmentReportId] = useState('')
  const [countVotes, setCountVotes] = useState({ yes: 0, no: 0, skip: 0 })
  const db = useDatabase()
  const [, contributionMetadataFns] = useContributionMetadata()
  const settings = useFsSettingsDocData()

  const toggleOpenDialog = () => {
    setOpennedDialog(!opennedDialog)
  }

  const toggleOpenConfirmDialog = () => {
    setIsLoadingSegment(false)
    setOpennedConfirmDialog(!opennedConfirmDialog)
  }

  const openDialogReport = (id: string) => {
    setSegmentReportId(id)
    toggleOpenDialog()
  }
  const [isLoading, setIsLoading] = useState(false)

  const getUrlVideoBySegment = async (_segment: ISegment, duplicateOf: string) => {
    const videoUrlString = getPathFramesJson(fsUser.workspace.id, _segment?.video?.id || '', duplicateOf)
    const videoUrl = await getDownloadURL(ref(storage, videoUrlString))
    return videoUrl
  }
  const showSnackbar = useSnackbar()

  const getIdenticalSegments = async () => {
    const referenceString = `${fsUser.workspace.id}/${fsUser.oralLanguageId}/${fsUser.signLanguageId}/${
      settings.queueMatchSegments || 'newMatchSegmentsPriority'
    }/${segmentQueueId}`
    const reference = refDb(db, referenceString)
    const matchPriority = await get(reference)

    //Resgata segmento principal (da esquerda)
    const _segmentId = Object.keys(matchPriority.val())[0]
    setSegmentId(_segmentId)
    const _segmentRef = getDocumentReference(fsUser.workspace, 'segments', _segmentId) as DocumentReference
    setSegmentRef(_segmentRef)
    const _segment = (await getFirestoreDocument(_segmentRef)).data() as ISegmentMatch
    const videoSnapshot = await getFirestoreDocument(_segment.video)
    const videoData = videoSnapshot.data() as IVideo
    _segment.videoDuration = videoData.duration
    _segment.videoSentence = videoData.sentence
    setSegment(_segment)
    if (_segment) {
      const urlVideoSegment = await getUrlVideoBySegment(_segment, videoData.duplicateOf || '')
      setUrl(urlVideoSegment)
    }
    //
    if (matchPriority.val() && _segmentId) {
      const suggestionSegments = matchPriority.val()[_segmentId].similarSegments
      if (suggestionSegments) {
        const data = await Promise.all<ISegmentMatch>(
          suggestionSegments.map(async (value: string) => {
            const identicalSegmentSnapshot = await getFirestoreDocument(
              getDocumentReference(fsUser.workspace, 'segments', value),
            )
            const identicalSegmentData = (await identicalSegmentSnapshot.data()) as ISegmentMatch
            const videoSnapshot = await getFirestoreDocument(identicalSegmentData.video)
            const videoData = videoSnapshot.data() as IVideo
            const urlVideoIdenticalSegment = await getUrlVideoBySegment(
              identicalSegmentData,
              videoData.duplicateOf || '',
            )
            identicalSegmentData.videoDuration = videoData.duration
            identicalSegmentData.videoSentence = videoData.sentence
            identicalSegmentData.url = urlVideoIdenticalSegment
            identicalSegmentData.id = identicalSegmentSnapshot.id
            return identicalSegmentData
          }),
        )
        setIndexIdenticalSegment(0)
        setIdenticalSegments(data)
        setIsLoadingPage(false)
      }
    }
  }

  useEffect(() => {
    log('Carregando Segmentos')
    const call = async () => {
      await getIdenticalSegments()
    }
    call()
    contributionMetadataFns.setMetadata({
      timeToRelease: 3600,
      contributor: fsUserRef,
      lastUpdate: new Date(),
      isLocked: false,
      isTimerReachedZero: false,
    })
    resetJobMeter()
    startJobMeter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChangedTime = (value: number) => {
    timelineCursorFns.setPosition(value)
  }

  const handleOnChangedTimeIdentical = (value: number) => {
    timelineCursorFnsIdentical.setPosition(value)
  }

  const handleSkip = () => {
    setLocation(routes.findIdenticalSegmentsHome)
  }

  const handleSave = useCallback(
    async (_votes) => {
      if (isLoading) return
      setIsLoading(true)
      try {
        showSnackbar(intl.get('messages.savingResponses'), {
          variant: 'info',
        })

        //TODO -> Melhorar esta rotina
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newVotes: any = {}
        Object.keys(_votes[fsUserRef.id]).map((keyVote) => {
          /** Condiciona se o voto anterior do usuário for
           * diferente de nulo (tiver valor, ou seja, ele não pulou em outro momento que avaliou o par),
           * incrementa ou decrementa */
          if (_votes[fsUserRef.id][keyVote] != null) {
            const incrementValue: number = _votes[fsUserRef.id][keyVote] == 1 ? 1 : -1
            newVotes[keyVote] = incrementValue
          }
        })

        if (segment)
          addContribFindIdenticalSegmentsFunction({
            videoId: segment.video.id,
            userId: fsUserRef.id,
            workspaceId: fsUser.workspace.id as WorkspaceId,
            segmentId: segmentRef?.id || '',
            votes: newVotes,
          })
        endJobMeter()

        //Remove da fila
        await updateMatchSegmentsPriorityFunction({
          oralLanguageId: fsUser.oralLanguageId,
          signLanguageId: fsUser.signLanguageId,
          workspaceId: fsUser.workspace.id,
          segmentQueueId: segmentQueueId,
          reported: false,
        })

        showSnackbar(intl.get('messages.savedSuccessfully'), {
          variant: 'success',
        })
        handleSkip()
      } catch (err) {
        handleSkip()
        showSnackbar((err as TypeError | RangeError | EvalError).message, {
          variant: 'error',
        })
      }
      setIsLoading(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [votes],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleIdenticalResponse = async (response: 'yes' | 'no' | 'skip' | 'back') => {
    buttonSkipRef.current?.blur()
    buttonYesRef.current?.blur()
    buttonNoRef.current?.blur()
    buttonBackRef.current?.blur()

    if (response === 'back') {
      if (indexIdenticalSegment != 0) {
        setIndexIdenticalSegment((prev) => prev - 1)
      }
      return
    }

    if (isLoadingSegment) return
    setIsLoadingSegment(true)
    const userId = fsUserRef.id as UserId
    const userResponse = response === 'yes' ? 1 : response === 'no' ? 0 : null
    const identicalSegmentId: SegmentId = (identicalSegments && identicalSegments[indexIdenticalSegment]?.id) || ''
    const _similarity = { ...similarity }
    if (userResponse != null) {
      _similarity[userId] = Object.assign({}, _similarity[userId], {
        [identicalSegmentId]:
          ((_similarity[userId] && _similarity[userId][identicalSegmentId]) || 0) + (userResponse === 1 ? 1 : -1),
      })
    } else if (_similarity[userId] && _similarity[userId][identicalSegmentId] == null) {
      _similarity[userId] = Object.assign({}, _similarity[userId], {
        [identicalSegmentId]: null,
      })
    }

    const _votes = { ...votes }
    _votes[userId] = Object.assign({}, _votes[userId], {
      [identicalSegmentId]: userResponse,
    })

    setVotes(_votes)
    showSnackbar(
      intl.get('pages.findIdenticalSegments.votedSuccessfully', {
        response: intl.get(`messages.${response.toLowerCase()}`).toUpperCase(),
      }),
      {
        variant: 'default',
      },
    )
    setSimilarity(_similarity)
    if (identicalSegments && indexIdenticalSegment === identicalSegments.length - 1) {
      setOpennedConfirmDialog(true)
    } else {
      setIndexIdenticalSegment((prev) => prev + 1)
    }
  }

  const handleConfirmVote = async () => {
    toggleOpenConfirmDialog()
    await handleSave(votes)
  }

  useEffect(() => {
    switch (shortcutEvent) {
      case CommunityShortcuts.responseYesMatchSegments:
        handleIdenticalResponse('yes')
        break
      case CommunityShortcuts.responseNoMatchSegments:
        handleIdenticalResponse('no')
        break
      case CommunityShortcuts.responseSkipMatchSegments:
        handleIdenticalResponse('skip')
        break
      case CommunityShortcuts.playPauseVideoMatchSegments:
        setAutoPlayLeftVideo(!autoPlayLeftVideo)
        break
    }
  }, [autoPlayLeftVideo, handleIdenticalResponse, shortcutEvent])

  const handleReportSegment = async () => {
    toggleOpenDialog()
    setIsLoading(true)
    const id = segmentReportId
    const segmentReportRef = getDocumentReference(fsUser.workspace, 'segments', id)
    updateFirestoreDocument(segmentReportRef, { reported: true })
    const segmentReportSnapshot = await getFirestoreDocument(segmentReportRef)
    const segmentReportData = segmentReportSnapshot.data() as ISegment
    updateFirestoreDocument(segmentReportData.video, {
      errors: { [fsUserRef.id]: 'segmentError' },
    })
    if (id === segmentId) {
      updateMatchSegmentsPriorityFunction({
        oralLanguageId: fsUser.oralLanguageId,
        signLanguageId: fsUser.signLanguageId,
        workspaceId: fsUser.workspace.id,
        segmentQueueId: segmentQueueId,
        reported: true,
      })
      handleSkip()
    } else {
      handleIdenticalResponse('skip')
    }
    setIsLoading(false)
    showSnackbar(intl.get('messages.reportedSuccessfully'))
  }

  useEffect(() => {
    const _countVotes = { yes: 0, no: 0, skip: 0 }
    if (votes && votes[fsUserRef.id]) {
      Object.values(votes[fsUserRef.id]).map((value) => {
        _countVotes[value === 1 ? 'yes' : value === 0 ? 'no' : 'skip'] =
          _countVotes[value === 1 ? 'yes' : value === 0 ? 'no' : 'skip'] + 1
      })
    }
    setCountVotes(_countVotes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [votes])

  const SkeletonPage = () => {
    return <Skeleton variant="rect" width={'98%'} height={20} style={{ borderRadius: '4px', paddingRight: '12px' }} />
  }

  return (
    <Layout
      title={intl.get('modules.findIdenticalSegments')}
      requiredModule={'findIdenticalSegments'}
      includeCustomElem={<ContributionMetadataView avatarEl={<UserMenu />} />}
    >
      <Box width="100%" p={2}>
        {isLoading && <Preloader text={intl.get('messages.savingResponses')}></Preloader>}

        {!isLoading && (
          <Grid container justifyContent="center">
            {/** Area de visualização do vídeo */}
            <Grid item xs md={12} sm={12}>
              <Grid
                item
                sm={12}
                md={12}
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box width="90%">
                    {!isLoadingPage ? (
                      <>
                        <Paper
                          color="default"
                          style={{
                            padding: 2,
                            border: '1px solid #cecece',
                            boxShadow: 'none',
                            width: '98%',
                          }}
                        >
                          <LinearProgress
                            style={{ height: '20px', position: 'relative' }}
                            variant="determinate"
                            value={
                              ((indexIdenticalSegment + 1) / (identicalSegments ? identicalSegments.length : 0)) * 100
                            }
                          ></LinearProgress>
                          <Typography
                            style={{
                              position: 'absolute',
                              top: '62px',
                              width: '100%',
                              left: '0px',
                              right: '0px',
                            }}
                            variant="body2"
                            align="center"
                          >
                            {indexIdenticalSegment + 1 || 0}/{(identicalSegments && identicalSegments.length) || 0}
                          </Typography>
                        </Paper>
                      </>
                    ) : (
                      <SkeletonPage />
                    )}
                  </Box>
                  <Card style={{ padding: '10px' }}>
                    <Typography>{`${intl.get('messages.yes')}: ${countVotes['yes']}`}</Typography>
                    <Typography>{`${intl.get('messages.no')}: ${countVotes['no']}`}</Typography>
                    <Typography>{`${intl.get('messages.skip')}: ${countVotes['skip']}`}</Typography>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                style={{
                  marginBottom: 20,
                }}
              >
                <Typography variant="h6" align="center">
                  {intl.get('pages.findIdenticalSegments.segment.signsTheSame')}
                </Typography>
              </Grid>

              <Box
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                {/* Vídeo do sinal atual */}
                <VideoContainer
                  style={{
                    background: 'black',
                    padding: '5px',
                    width: '49%',
                  }}
                >
                  <FramePlayer
                    reference={segment?.id}
                    onError={() => ({})}
                    onReady={() => ({})}
                    onChangedTime={handleOnChangedTime}
                    frameRatio={30}
                    framesDuration={segment?.endFrame || 0}
                    timeFrame={appConfig.frameRate}
                    src={url}
                    startFrame={segment?.startFrame}
                    timelineCursor={timelineCursor}
                    autoPlay={autoPlayLeftVideo}
                    autoPlayEnabled={true}
                    mirrorEnabled={true}
                    onReport={() => openDialogReport(segmentId)}
                    fullVideoDuration={segment?.videoDuration}
                    videoSentence={segment?.videoSentence}
                  />
                </VideoContainer>
                <VideoContainer
                  item
                  style={{
                    background: 'black',
                    padding: '5px',
                    width: '49%',
                  }}
                >
                  <FramePlayer
                    reference={indexIdenticalSegment}
                    onError={() => ({})}
                    onReady={() => {
                      setIsLoadingSegment(false)
                    }}
                    onChangedTime={handleOnChangedTimeIdentical}
                    frameRatio={30}
                    framesDuration={
                      (identicalSegments?.length && identicalSegments[indexIdenticalSegment].endFrame) || 0
                    }
                    timeFrame={appConfig.frameRate}
                    src={(identicalSegments?.length && identicalSegments[indexIdenticalSegment].url) || ''}
                    startFrame={(identicalSegments?.length && identicalSegments[indexIdenticalSegment].startFrame) || 0}
                    timelineCursor={timelineCursorIdentical}
                    autoPlay={true}
                    mirrorEnabled={true}
                    onReport={() =>
                      openDialogReport(identicalSegments ? identicalSegments[indexIdenticalSegment]?.id || '' : '')
                    }
                    fullVideoDuration={
                      (identicalSegments?.length && identicalSegments[indexIdenticalSegment].videoDuration) || 0
                    }
                    videoSentence={
                      (identicalSegments?.length && identicalSegments[indexIdenticalSegment].videoSentence) || ''
                    }
                  />
                </VideoContainer>
              </Box>

              <Grid item>
                <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center" p={2}>
                  <Box display="flex">
                    <Box mr={2}>
                      <Button
                        variant="outlined"
                        onClick={() => handleIdenticalResponse('back')}
                        ref={buttonBackRef}
                        startIcon={<SkipPreviousIcon />}
                      >
                        {intl.get('messages.back')}
                      </Button>
                    </Box>
                    <Box mr={2}>
                      <Button
                        variant="contained"
                        onClick={() => handleIdenticalResponse('skip')}
                        ref={buttonSkipRef}
                        startIcon={<SkipNextIcon />}
                      >
                        {intl.get('messages.skip')}
                      </Button>
                    </Box>
                    <Box mr={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleIdenticalResponse('no')}
                        ref={buttonNoRef}
                        startIcon={<CancelIcon />}
                      >
                        {intl.get('messages.no')}
                      </Button>
                    </Box>
                    <Button
                      style={{ background: green[700], color: '#fff' }}
                      variant="contained"
                      onClick={() => handleIdenticalResponse('yes')}
                      ref={buttonYesRef}
                      startIcon={<CheckIcon />}
                    >
                      {intl.get('messages.yes')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <ConfirmationDialog
        open={opennedDialog}
        toggleOpen={toggleOpenDialog}
        handleYes={handleReportSegment}
        title={intl.get('messages.sendReport')}
        subtitle={intl.get('messages.confirmYourRport')}
      />
      <ConfirmationDialog
        open={opennedConfirmDialog}
        toggleOpen={toggleOpenConfirmDialog}
        handleYes={handleConfirmVote}
        title={intl.get('messages.confirmVote')}
        subtitle={intl.get('messages.confirmYourVote')}
      />
    </Layout>
  )
}

export default FindIdenticalSegments
