import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'wouter'

import CardMessage from '../../components/CardMessage'
import Layout from '../../components/Layout'
import { VideoId, getRandomVideoRef, DocumentReference } from 'collections'
import { useFsUserDocData } from '../../hooks/useFsUser'
import { routes } from '../../community'
import Preloader from '../../components/Preloader'
import useIntl from '../../hooks/useIntl'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'

interface ISegmentVideoProps {
  path: string
}

/**
 * Modulo de etiquetação de sinais em vídeos
 */
const SegmentVideo: React.FC<ISegmentVideoProps> = () => {
  const fsUser = useFsUserDocData()
  const [videoId, setVideoId] = useState<VideoId>()
  const [, setLocation] = useLocation()
  const [isLoading, setIsloading] = useState(true)
  const hasPermissionOnModule = useCheckHasPermission('segmentVideo')
  const intl = useIntl()
  /**
   * Define um novo vídeo que será segmentado
   */
  const fetchVideo = useCallback(async () => {
    if (!fsUser.modules.includes('segmentVideo')) return
    setIsloading(true)

    const videoRef = await getRandomVideoRef(fsUser.workspace as DocumentReference)

    if (videoRef?.id) {
      setVideoId(videoRef?.id)
      setLocation(routes.segmentASign.replace(':videoId', videoRef.id).replace(':user', 'segment'))
    }

    setIsloading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fsUser.modules, fsUser.workspace.id])

  // Seta os módulos do usuário
  useEffect(() => {
    if (fsUser.modules.includes('segmentVideo') && hasPermissionOnModule) {
      fetchVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermissionOnModule])

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.segmentVideo')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.segmentVideo')} requiredModule={'segmentVideo'}>
      {/** Exibe o preload ja que está carregando */}
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingVideo')}></Preloader>}

      {/** Exibe a mensagem de que não existe itens na fila  */}
      {!isLoading && !videoId && (
        <CardMessage
          title={intl.get('pages.segmentVideo.noVideoFound.title')}
          subtitle={intl.get('pages.segmentVideo.noVideoFound.description')}
        />
      )}
    </Layout>
  )
}

export default SegmentVideo
