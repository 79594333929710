import { useState, useEffect } from 'react'

const useResize = (): number => {
  const [resizeValue, setResizeValue] = useState(0)

  useEffect(() => {
    window.onresize = () => {
      setResizeValue(window.innerWidth)
    }
    return () => {
      window.onresize = null
    }
  }, [])

  return resizeValue
}

export default useResize
