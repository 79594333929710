import { ITranslationValidation } from './types/translationValidation'
import { addDoc, updateDoc, setDoc, getDoc, DocumentReference, CollectionReference } from 'firebase/firestore'
import { IUser } from './types/user'
import { DocumentData, serverTimestamp, Timestamp } from './types/firebase'
import { addFirestoreDocument, getCollectionReference } from './Base'

const createUpdateFunction = async (
  ref: DocumentReference,
  translationValidationData: Partial<ITranslationValidation>,
) => {
  return await updateDoc(ref, translationValidationData)
}

export const updateTranslationValidation = createUpdateFunction

const createAddFunction = async (
  ref: CollectionReference,
  translationValidationData: Partial<ITranslationValidation>,
) => {
  return await addDoc(ref, translationValidationData)
}

export const addTranslationValidation = createAddFunction

const createSetFunction = async (
  ref: DocumentReference,
  translationValidationData: Partial<ITranslationValidation>,
) => {
  return await setDoc(ref, translationValidationData)
}

export const setTranslationValidation = createSetFunction

const createGetFunction = async (ref: DocumentReference) => {
  return await getDoc(ref)
}

export const getTranslationValidation = createGetFunction

/**
 * Cria uma nova contribuição de avaliação de tradução
 * @param fsUser Usuário logado do firestore
 * @param fsUserRef Referencia do usuário
 * @param data Dados da avaliação
 */
export const createTranslationValidation = async (
  fsUser: IUser,
  fsUserRef: DocumentReference,
  data: Pick<ITranslationValidation, 'sentence' | 'translation' | 'isCorrect' | 'isOutOfContext' | 'isMissingSign'>,
): Promise<DocumentReference<DocumentData>> => {
  const { sentence, translation, isCorrect, isMissingSign, isOutOfContext } = data

  const validation: ITranslationValidation = {
    createdAt: serverTimestamp() as Timestamp,
    createdBy: fsUserRef,
    oralLanguageId: fsUser.oralLanguageId,
    signLanguageId: fsUser.signLanguageId,
    sentence,
    translation,
    isCorrect,
    isMissingSign,
    isOutOfContext,
    recordedBy: null,
  }
  return await addFirestoreDocument(getCollectionReference(fsUser.workspace, 'translationValidations'), validation)
}
