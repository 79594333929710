import React from 'react'

import CardContributionPure from './Pure'
import { CommunityModule, LanguageKeypair, WorkspaceId } from 'collections'
import { useFsUserDocData } from '../../hooks/useFsUser'
import useIntl from '../../hooks/useIntl'

interface ICardContribution {
  communityModule: CommunityModule
}
/**
 * Utilize para exibir a quantidade de traduções em um determinado módulo
 */
const CardContribution: React.FC<ICardContribution> = ({ communityModule }) => {
  const fsUser = useFsUserDocData()
  const oralLanguageId = fsUser?.oralLanguageId
  const signLanguageId = fsUser?.signLanguageId
  const workspace = fsUser?.workspace

  const langKeyPair = `${oralLanguageId}-${signLanguageId}` as LanguageKeypair
  const intl = useIntl()
  const contributionText = intl.get('messages.contributions')

  const contribs =
    // @ts-ignore
    fsUser?.contribs?.[workspace.id as WorkspaceId]?.[communityModule]?.[langKeyPair] || 0

  return <CardContributionPure contributionText={contributionText} amount={contribs} />
}

export default CardContribution
