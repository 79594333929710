import {
  makeStyles,
  createStyles,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'
import { OralLanguageId } from 'collections'
import React, { useEffect } from 'react'
import { useFsUserDocData } from '../../hooks/useFsUser'
import useIntl from '../../hooks/useIntl'
import { acceptLGPDTermsFunction } from '../../services/firebase'
import useSnackbar from '../../services/hooks/useSnackbar'

const useStyles = makeStyles(() =>
  createStyles({
    subtitleTerms: {
      marginTop: '10px',
      fontWeight: 'bold',
    },
    optionsTerms: {
      marginLeft: 10,
    },
    titleTerms: {
      marginBottom: 10,
    },
  }),
)

interface ILGPDTerms {
  checkAcceptedLGPD?: () => Promise<boolean>
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  acceptedLgpd: boolean | null
}

const LGPDTerms: React.FC<ILGPDTerms> = ({ checkAcceptedLGPD, open, setOpen, acceptedLgpd }) => {
  const classes = useStyles()
  const fsUser = useFsUserDocData()
  const intl = useIntl()
  const showSnackbar = useSnackbar()

  const handleAcceptTerms = async () => {
    handleClose()
    showSnackbar(intl.get('messages.saving'), { variant: 'info' })
    await acceptLGPDTermsFunction('')
    if (checkAcceptedLGPD) await checkAcceptedLGPD()
    showSnackbar(intl.get('messages.savedSuccessfully'), { variant: 'success' })
  }

  useEffect(() => {
    const call = async () => {
      if (checkAcceptedLGPD) {
        const lgpdAccepted = await checkAcceptedLGPD()
        setOpen(lgpdAccepted === false)
      }
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const TextLGPDPtBr = () => {
    return (
      <>
        <Typography variant="body1">
          A aceitação do presente Termo estabelece o consentimento para tratamento de alguns dados pessoais do Titular,
          pela empresa HAND TALK TECNOLOGIA S/A, aqui denominada como CONTROLADORA, inscrita no CNPJ sob n°
          16.918.665/0001-19, em razão do acesso ao sistema da Controladora e vinculação do Titular com as contribuições
          voluntárias. Diante disso, ao seguir com o aceite você dispõe dos seus dados pessoais e/ou dados pessoais
          sensíveis, de acordo com os artigos 7° e 11 da Lei n° 13.709/2018, conforme disposto neste termo:
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          I - Dados Pessoais
        </Typography>
        <Typography variant="body1">
          1.1. O Titular autoriza a Controladora a realizar o tratamento, ou seja, a utilizar os seguintes dados
          pessoais e/ou dados pessoais sensíveis, para os fins que serão relacionados na cláusula segunda:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Nome completo;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          b) E-mail;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          c) Imagem através de vídeo.
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          II - Finalidade do Tratamento dos Dados
        </Typography>
        <Typography variant="body1">
          2.1. O Titular autoriza que a Controladora utilize os dados pessoais e/ou dados pessoais sensíveis listados
          neste termo para as seguintes finalidades:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Imagem através de vídeo para extração de pontos de articulação (Keypoints);
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          b) Imagem através de vídeo para exibição do vídeo para usuários a realização de atividades no sistema;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          c) Imagem através de vídeo para criação de modelos de reconhecimentos de sinais e tradução;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          d) Nome para permitir acesso do Titular ao sistema da Controladora;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          e) Nome para vinculação do Titular com as contribuições na plataforma Community.
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          f) Nome e e-mail para que a Controladora identifique o Titular e possa entrar em contato quando necessário.
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          III - Compartilhamento de Dados
        </Typography>
        <Typography variant="body1">
          3.1. A Controladora fica autorizada a compartilhar os dados pessoais do Titular com outros agentes de
          tratamento de dados, caso seja necessário para as finalidades listadas neste instrumento, desde que, sejam
          respeitados os princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados,
          transparência, segurança, prevenção, não discriminação e responsabilização e prestação de contas.
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          IV - Responsabilidade pela Segurança de Dados
        </Typography>
        <Typography variant="body1">
          4.1. A Controladora se responsabiliza por manter medidas de segurança, técnicas e administrativas suficientes
          a proteger os dados pessoais do Titular e à Autoridade Nacional de Proteção de Dados (ANPD), comunicando ao
          Titular, caso ocorra algum incidente de segurança que possa acarretar risco ou dano relevante, conforme artigo
          48 da Lei n° 13.709/2020.
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          V – Concessão de Uso de Imagem e Tempo de Tratamento de Dados
        </Typography>
        <Typography variant="body1">
          5.1. À Controladora, é permitido manter e utilizar os dados pessoais do Titular durante todo o período de
          relacionamento entre o Titular e a empresa Hand Talk Tecnologia S/A e ainda após o término desse
          relacionamento, para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do
          artigo 16 da Lei n° 13.709/2018, como também, para tratamento dos vídeos enviados na plataforma Community.
        </Typography>
        <Typography variant="body1">
          5.2. O Titular dos Dados autoriza a Controladora por tempo indeterminado, desde já, a:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Utilizar e veicular os vídeos realizados com o registro da imagem do titular na plataforma “Community” para
          a Hand Talk, para fins de abastecimento de traduções, sem qualquer limitação de número de inserções e
          reproduções;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          b) O titular dos dados cede e transfere a Hand Talk, seus sucessores ou cessionários, todos os direitos
          autorais gerados no &quot;Community&quot; através da reprodução de seus vídeos enviados na plataforma.
        </Typography>
        <Typography variant="body1">
          5.3. O Titular dos Dados autoriza a Controladora por tempo indeterminado e em caráter irrevogável e
          irretratável, desde já a:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Utilizar e veicular todas as contribuições feitas pelo Titular nos sistemas e plataformas da empresa Hand
          Talk Tecnologia S/A.
        </Typography>
        <Typography variant="body1">
          5.4. O titular dos dados reconhece que a empresa Hand Talk Tecnologia S.A é a única proprietária das
          contribuições feitas em seus sistemas e plataformas, como também, de todos os dados gerados através das
          contribuições.
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          VI - Direito de Revogação de Consentimento
        </Typography>
        <Typography variant="body1">
          6.1. O Titular poderá revogar seu consentimento com relação ao tratamento de dados pessoais, a qualquer tempo,
          por e-mail ou por carta escrita, conforme o artigo 8°, § 5°, da Lei n° 13.709/2020.
        </Typography>
      </>
    )
  }

  const TextTitleLgpd = (language: OralLanguageId) => {
    if (language == OralLanguageId.eng) return 'Consent Form for Personal Data Processing - GDPR'
    return 'Termo de Consentimento para Tratamento de Dados Pessoais - LGPD'
  }

  const TextLGPDEnUs = () => {
    return (
      <>
        <Typography variant="body1">
          The acceptance of the presente Term establishes consent for the processing of some of the Holder&apos;s
          personal information, by the company HAND TALK TECNOLOGIA S/A, hereby called CONTROLLER, registered under CNPJ
          nº 16.918.665/0001-19, in reason of access to the Controller&apos;s system and the Holder&apos;s binding to
          voluntary contributions. In sight of that, by proceeding with the acceptance you give your personal
          information and/or sensitive data, according to articles 7º and 11º of the Law nº 13.709/2018, as shown in
          this term:
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          I - Personal Data
        </Typography>
        <Typography variant="body1">
          1.1. The Holder authorizes the Controller to follow through with the processing, that is, utilize the
          following personal data and/or sensitive information, for purposes that will be listed in the second clause:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Full name;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          b) E-mail;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          c) Image via video.
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          II - Purpose of Data Processing
        </Typography>
        <Typography variant="body1">
          2.1. The Holder authorizes the Controller to utilize the personal data and/or sensitive information listed in
          this term for the following purposes:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Image via video for extraction of articulation points (Keypoints);
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          b) Image via video for video exhibition for users to perform activities in the system;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          c) Image via video for creation of translation and signs recognition models;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          d) Name to allow Holder&apos;s access to the system of the Controller;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          e) Name for Holder&apos;s binding to the contributions in the platform Community;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          f) Name and e-mail for the Controller to identify and contact the Holder when necessary;
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          III - Data Sharing
        </Typography>
        <Typography variant="body1">
          3.1. The Controller shall be authorized to share the Holder’s personal data with other data processing agents,
          in case is necessary for the purposes listed in this document, provided that respected the good faith
          principles, purposes, adequacy, necessity, free access, data quality, transparency, security, prevention, non
          discrimination and accountability.
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          IV - Responsibility for Data Security
        </Typography>
        <Typography variant="body1">
          4.1. The Controller takes responsibility upon maintaining security measures, techniques and procedures
          sufficient to protect the Holder’s personal data and the National Authority in Data Protection (Autoridade
          Nacional de Proteção de Dados - ANPD), communicating the Holder in case any security incident occurs that can
          entail risk or relevant damage, according to article 48 of Law n° 13.709/2020.
        </Typography>

        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          V – Image Use Concession and Data Processing Time
        </Typography>
        <Typography variant="body1">
          5.1. To the Controller, is allowed to keep and utilize the personal information (them being: name and image)
          the Holder’s data throughout all the relationship period between the Holder and the company Hand Talk
          Tecnologia S/A and even after the termination of this relationship, to fulfill legal obligations or imposed by
          supervisory bodies, in terms of article 16 of Law n° 13.709/2018, as also, for processing of videos sent in
          the platform Community.
        </Typography>
        <Typography variant="body1">
          5.2. The data&apos;s Holder authorizes the Controller for an undetermined amount of time, as of this moment,
          to:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Utilize and convey the videos made with the Holder’s image registration in the platform Community for Hand
          Talk, for translation supply, without any limitation in number of insertions and reproductions;
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          b) The data’s Holder assigns and transfers to Hand Talk, its successors or assignees, all copyrights generated
          in Community through the reproduction of the videos sent in the platform.
        </Typography>
        <Typography variant="body1">
          5.3. According to what was stipulated in the Service Provision contract, signed by the parts, the Data’s
          Holder authorizes the Controller for undetermined time, starting now, irreversibly and irrevocably, to:
        </Typography>
        <Typography variant="body1" className={classes.optionsTerms}>
          a) Utilize and transmit all contibutions made by the Holder in the systems and platforms of the company Hand
          Talk Tecnologia S/A.
        </Typography>
        <Typography variant="body1">
          5.4. The data&apos;s holder recognizes that the company Hand Talk Tecnologia S.A is the sole proprietor of the
          contributions made on its systems and platforms, as well as all data generated through the contributions.
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitleTerms}>
          VI - Right to Revocation of Consent
        </Typography>
        <Typography variant="body1">
          6.1. The Holder may revoke his consent regarding the processing of personal information, at any given moment,
          via e-mail or written letter, according to article 8°, § 5°, of the Law nº 13.709/2020.
        </Typography>
      </>
    )
  }

  const handleClose = () => {
    setOpen(false)
  }

  const DialogTerms = () => {
    return (
      <Dialog
        open={open}
        //TransitionComponent={Transition}
        keepMounted
        aria-describedby="lgpd-dialog"
        fullWidth={true}
        maxWidth={'md'}
        style={{ zIndex: 9999 }}
      >
        <DialogTitle>{TextTitleLgpd(fsUser.oralLanguageId)}</DialogTitle>
        <DialogContent>{fsUser.oralLanguageId == 'por' ? <TextLGPDPtBr /> : <TextLGPDEnUs />}</DialogContent>
        <DialogActions>
          {!acceptedLgpd && (
            <Button
              variant="contained"
              onClick={() => {
                handleAcceptTerms()
              }}
            >
              {intl.get('messages.agreeTermsOfUse')}
            </Button>
          )}
          {acceptedLgpd && (
            <Button
              variant="contained"
              onClick={() => {
                handleClose()
              }}
            >
              {intl.get('messages.close')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  return <DialogTerms />
}

export default LGPDTerms
