import React from 'react'
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable'
import { IManuallyTranslationSentence, IManuallyTranslationTarget } from 'collections'
import DraggableChip from '../DraggableChip'

interface IDroppableChipList {
  item: IManuallyTranslationSentence
  onDeleteChip: (item: IManuallyTranslationSentence, indexChip: number) => void
  onGetSignByClick: (item: IManuallyTranslationTarget) => void
}

export const DroppableChipList: React.FC<IDroppableChipList> = ({ item, onDeleteChip, onGetSignByClick }) => {
  const { setNodeRef: setNodeRefChips } = useSortable({
    id: `chiplist--${item.id || ''}`,
    data: {
      type: 'chips',
    },
  })

  return (
    <SortableContext
      id={`chiplist--${item.id || ''}`}
      items={item.target?.map((el) => el.draggableId || '') || []}
      strategy={rectSortingStrategy}
    >
      <div
        ref={item.verified ? null : setNodeRefChips}
        style={{
          flexDirection: 'row',
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          gap: '10px',
          overflow: 'auto',
          padding: '10px',
          flexWrap: 'wrap',
        }}
      >
        {item.target?.map((value: IManuallyTranslationTarget, indexChip: number) => (
          <DraggableChip
            key={value.draggableId}
            item={value}
            verified={item.verified}
            onDeleteChip={() => {
              if (item.verified) return
              onDeleteChip(item, indexChip)
            }}
            onGetSignByClick={onGetSignByClick}
          />
        ))}
      </div>
    </SortableContext>
  )
}
