import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { CommunityShortcuts, IShortcuts } from 'collections'
import { Typography, Button, Divider } from '@material-ui/core'
import useShortcuts from '../../../hooks/useShortcuts'
import { green } from '@material-ui/core/colors'
import { useFsUserDocData } from '../../../hooks/useFsUser'
import useIntl from '../../../hooks/useIntl'

const Shortcuts = (): JSX.Element => {
  const { saveShortcuts } = useShortcuts()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [shortcutsConfig, setShortcutsConfig] = useState<any>({})
  const fsUser = useFsUserDocData()
  const intl = useIntl()

  useEffect(() => {
    setShortcutsConfig(fsUser.shortcuts)
  }, [fsUser.shortcuts])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, keyShortcut: string) => {
    if (event.keyCode === 27) {
      setShortcutsConfig({ ...shortcutsConfig, [keyShortcut]: '' })
    } else {
      const shortcut = event.keyCode.toString()
      setShortcutsConfig({
        ...shortcutsConfig,
        [keyShortcut]: shortcut,
      })
    }
  }

  const handleSave = () => {
    saveShortcuts(shortcutsConfig as IShortcuts)
  }

  const getKeyDescription = (keyCode: number) => {
    if (keyCode === 32) return 'SPACE'
    return String.fromCharCode(keyCode)
  }

  return (
    <Grid container justifyContent="center" style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
      <Grid item xs sm={8} md={8}>
        <Grid item>
          <Box p={2}>
            <Typography align="center" variant="h6">
              {intl.get('messages.shortcuts')}
            </Typography>
          </Box>
        </Grid>
        <Box
          style={{
            border: 'solid #AAAA 2px',
            borderRadius: '10px',
            padding: '15px',
          }}
        >
          <Grid item>
            <Box p={2}>
              <Typography variant="subtitle2">{intl.get('modules.validateTranslation')}</Typography>
              <Divider />
            </Box>
          </Grid>
          <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
            <Box p={1}>
              <TextField
                label={intl.get('messages.correct')}
                value={getKeyDescription(shortcutsConfig?.correctValidationTranslate)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.correctValidationTranslate)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.missingSign')}
                value={getKeyDescription(shortcutsConfig?.missingSignsValidationTranslate)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.missingSignsValidationTranslate)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.outOfContext')}
                value={getKeyDescription(shortcutsConfig?.outOfContextValidationTranslate)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.outOfContextValidationTranslate)
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            border: 'solid #AAAA 2px',
            borderRadius: '10px',
            padding: '15px',
            marginTop: '10px',
          }}
        >
          <Grid item>
            <Box p={2}>
              <Typography variant="subtitle2">{intl.get('modules.signSentence')}</Typography>
              <Divider />
            </Box>
          </Grid>
          <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
            <Box p={1}>
              <TextField
                label={intl.get('messages.recordFinish')}
                value={getKeyDescription(shortcutsConfig?.recordFinishSignSentence)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.recordFinishSignSentence)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.delete')}
                value={getKeyDescription(shortcutsConfig?.deleteSignSentence)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.deleteSignSentence)
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            border: 'solid #AAAA 2px',
            borderRadius: '10px',
            padding: '15px',
            marginTop: '10px',
          }}
        >
          <Grid item>
            <Box p={2}>
              <Typography variant="subtitle2">{intl.get('modules.findIdenticalSegments')}</Typography>
              <Divider />
            </Box>
          </Grid>
          <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
            <Box p={1}>
              <TextField
                label={intl.get('messages.playPauseVideo')}
                value={getKeyDescription(shortcutsConfig?.playPauseVideoMatchSegments)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.playPauseVideoMatchSegments)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.responseYes')}
                value={getKeyDescription(shortcutsConfig?.responseYesMatchSegments)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.responseYesMatchSegments)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.responseNo')}
                value={getKeyDescription(shortcutsConfig?.responseNoMatchSegments)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.responseNoMatchSegments)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.responseSkip')}
                value={getKeyDescription(shortcutsConfig?.responseSkipMatchSegments)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.responseSkipMatchSegments)
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            border: 'solid #AAAA 2px',
            borderRadius: '10px',
            padding: '15px',
            marginTop: '10px',
          }}
        >
          <Grid item>
            <Box p={2}>
              <Typography variant="subtitle2">{intl.get('modules.segmentVideo')}</Typography>
              <Divider />
            </Box>
          </Grid>
          <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
            <Box p={1}>
              <TextField
                label={intl.get('messages.save')}
                value={getKeyDescription(shortcutsConfig?.saveSegments)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.saveSegments)
                }
              />
            </Box>
            <Box p={1}>
              <TextField
                label={intl.get('messages.skip')}
                value={getKeyDescription(shortcutsConfig?.skipSegments)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown(event, CommunityShortcuts.skipSegments)
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
          p={2}
        >
          <Button variant="contained" onClick={handleSave} style={{ background: green[700], color: '#fff' }}>
            {intl.get('messages.save')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Shortcuts
