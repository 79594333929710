import React from 'react'

import { AppBar, Box, Button, Dialog, Toolbar, Typography } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface IFullScreenSelectionDialogPure {
  /**
   * Booleano para indicar se o dialog está aberto ou não
   */
  open: boolean
  /**
   * Título para o Dialog
   */
  title: string
  /**
   * Função para alternar abertura do dialog
   */
  toggleOpen: () => void
  /**
   * Função para executar a confirmação do dialog
   */
  handleConfirm: () => void
  /**
   * Texto para confirmação
   */
  confirmText: string
  appBarElement?: JSX.Element
  toolbarPosition?: string
  children: JSX.Element
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreen: {
      position: 'absolute',
      top: theme.spacing(8),
      left: 0,
      height: `calc(100% - ${theme.spacing(8)}px)`,
    },
    start: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    children: {
      position: 'relative',
      top: theme.spacing(10),
      left: 0,
      width: '100%',
    },
  }),
)

const FullScreenSelectionDialogPure: React.FC<IFullScreenSelectionDialogPure> = ({
  open,
  title,
  toggleOpen,
  handleConfirm,
  confirmText,
  children,
  appBarElement,
  toolbarPosition,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={toggleOpen}
      classes={{
        paperFullScreen: classes.fullScreen,
      }}
    >
      <Box
        style={{
          position: 'relative',
          width: '100%',
          zIndex: 1000,
        }}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={toggleOpen} aria-label="close-icon">
              <Close />
            </IconButton>
            {toolbarPosition === 'start' ? (
              <Box className={classes.start} display="flex" flexDirection="row">
                <Typography variant="h6">{title}</Typography>
                <div>{appBarElement}</div>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row">
                <Typography variant="h6" className={classes.start}>
                  {title}
                </Typography>
                {appBarElement}
              </Box>
            )}
            <Button autoFocus color="inherit" onClick={handleConfirm}>
              {confirmText}
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box style={{ overflow: 'auto' }}>{children}</Box>
    </Dialog>
  )
}

export default FullScreenSelectionDialogPure
