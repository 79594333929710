import { Drawer, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import SchoolIcon from '@material-ui/icons/School'
import HandsIcon from '../../images/hands.svg'
import AvatarIcon from '../../images/AvatarIcon.svg'
import HelpIcon from '../../images/HelpIcon.svg'
import { motionColors } from '../../styles'
import { useLocation } from 'wouter'
import { routes } from '../../community'

interface IDrawerMotionProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const DrawerMotion: React.FC<IDrawerMotionProps> = ({ open, setOpen }) => {
  const [location] = useLocation()

  const toggleDrawer = () => {
    setOpen(!open)
  }

  // const handleClickItem = (menuOption: EMenuOption) => {
  //   switch (menuOption) {
  //     case EMenuOption.learning:
  //       return setLocation(routes.motionLearning)
  //     case EMenuOption.home:
  //       return setLocation(routes.motionHome)
  //     case EMenuOption.selectAvatar:
  //       return setLocation(routes.motionSelectAvatar)
  //   }
  // }

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={() => toggleDrawer()}
      PaperProps={{
        style: {
          // backgroundColor: motionColors.backgroundColor,
          color: motionColors.fontColor,
        },
      }}
    >
      <ListItem
        button
        // onClick={() => {
        //   handleClickItem(EMenuOption.home)
        // }}
        selected={location === routes.motionLearningRecord}
      >
        <ListItemIcon>
          <HomeIcon style={{ color: motionColors.fontColor }} />
        </ListItemIcon>
        <ListItemText primary={'Início'} />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   handleClickItem(EMenuOption.learning)
        // }}
        selected={location.split('/').length > 2 && location.split('/')[2] === 'learning'}
      >
        <ListItemIcon>
          <SchoolIcon style={{ color: motionColors.fontColor }} />
        </ListItemIcon>
        <ListItemText primary={'Aprendizagem'} />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   handleClickItem(EMenuOption.home)
        // }}
        selected={location.split('/').length > 2 && location.split('/')[2] === 'challenge'}
      >
        <ListItemIcon>
          <img
            style={{
              height: '24px',
            }}
            src={HandsIcon}
          />
        </ListItemIcon>
        <ListItemText primary={'Desafio'} />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   handleClickItem(EMenuOption.selectAvatar)
        // }}
        // selected={location === routes.motionSelectAvatar}
      >
        <ListItemIcon>
          <img
            style={{
              height: '24px',
            }}
            src={AvatarIcon}
          />
        </ListItemIcon>
        <ListItemText primary={'Avatar'} />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   handleClickItem(EMenuOption.home)
        // }}
      >
        <ListItemIcon>
          <img
            style={{
              height: '22px',
            }}
            src={HelpIcon}
          />
        </ListItemIcon>
        <ListItemText primary={'Ajuda'} />
      </ListItem>
    </Drawer>
  )
}

export default DrawerMotion
