import { FC } from 'react'

import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'

interface IBottomBarProps {
  prevEnabled?: boolean
  nextEnabled?: boolean
  prevCallback?: () => void
  nextCallback?: () => void
  textBack: string
  textNext: string
}

/**
 - Use o botão voltar ou próximo para exibir conteúdos de etapas.
 **/
export const BottomBarPure: FC<IBottomBarProps> = ({
  prevEnabled,
  nextEnabled,
  prevCallback,
  nextCallback,
  textNext,
  textBack,
}) => {
  return (
    <AppBar position="static" color="default" style={{ position: 'fixed', bottom: '0' }}>
      <Toolbar>
        <Button variant="outlined" disabled={!prevEnabled} onClick={prevCallback}>
          {textBack}
        </Button>
        <Grid item xs />
        <Button color="primary" variant="contained" disabled={!nextEnabled} onClick={nextCallback}>
          {textNext}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default BottomBarPure
