import React from 'react'
import styled from 'styled-components'

const timelineHeight = '100px'

const TimelineCursorStyle = styled.div`
  position: absolute;
  left: 0%;
  bottom: 0px;
  background: #2c91f4;
  width: 3px;
  height: ${timelineHeight};
  z-index: 2;
  pointer-events: none;
}
`

interface ITimelineCursorProps {
  position: number
  startFrame?: number
}

/**
 * Cursor da linha do tempo, utilize para deslizar entre o tempo do vídeo
 */
export const TimelineCursor: React.FC<ITimelineCursorProps> = ({ position, startFrame }) => {
  return (
    <TimelineCursorStyle
      style={{
        transform: `translate(${position - (startFrame || 0)}px, 0px)`,
        height: startFrame ? '20px' : timelineHeight,
      }}
    />
  )
}
export default TimelineCursor
