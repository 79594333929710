import React, { useEffect, useState } from 'react'
import { useStorage } from 'reactfire'
import Grid from '@material-ui/core/Grid'
import { ISign, DocumentReference, Query, ISegmentView, getPathFramesJson, IVideo, getInBatch } from 'collections'
import SignView from '../SignView'
import { useFsUserDocData } from '../../hooks/useFsUser'
import DraggableRegionAction from '../DraggableRegionAction'
import { ref, getDownloadURL } from '@firebase/storage'
import { getDocs } from 'firebase/firestore'

interface ISignList {
  /** Busca que será feita para exibir os sinais */
  query: Query
  /** string */
  glosa: string
  /** */
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  nmtSuggestionsBySegment?: string[]
  setCountResults: React.Dispatch<React.SetStateAction<number>>
}

interface ISignAndRef {
  signData: ISign
  refSign: DocumentReference
  segmentView?: ISegmentView
}

/**
 * Lista de sinais com atualização automatica através de uma query
 */
const SignList: React.FC<ISignList> = ({
  query,
  glosa,
  setIsLoading,
  isLoading,
  nmtSuggestionsBySegment,
  setCountResults,
}) => {
  const [draggingItemId, setDraggindItemId] = useState('')
  const fsUser = useFsUserDocData()
  //const { data: signSnapshot } = useFirestoreCollection(query)
  const [signs, setSigns] = useState<ISignAndRef[]>([])
  const storage = useStorage()

  const handleStartDrag = (itemId: string) => {
    setDraggindItemId(itemId)
  }

  const handleEndDrag = () => {
    setDraggindItemId('')
  }

  const getVideoUrl = async (videoId: string, duplicateOf: string) => {
    if (!videoId) return ''
    const urlString = getPathFramesJson(fsUser.workspace.id, videoId, duplicateOf)

    return await getDownloadURL(ref(storage, urlString))
  }

  useEffect(() => {
    const call = async () => {
      const _signs: ISignAndRef[] = []
      const _unequalsSigns: ISignAndRef[] = []

      const signSnapshot = await getDocs(query)

      const segmentsIds: string[] = []
      signSnapshot.docs.forEach((doc) => {
        const data = doc.data() as ISign
        if (data.primarySegment) {
          segmentsIds.push(data.primarySegment.id)
        }
      })

      const segmentsData = await getInBatch<ISegmentView>(fsUser.workspace, 'segments', segmentsIds)

      const videosIds = Object.entries(segmentsData).map((el) => el[1].video.id)

      const videosData = await getInBatch<IVideo>(fsUser.workspace, 'videos', videosIds)

      signSnapshot.docs.forEach(async (document) => {
        const data = document.data() as ISign

        const signViewData: ISignAndRef = {
          signData: data,
          refSign: document.ref,
        }

        const segmentViewData = segmentsData[data.primarySegment?.id || '']

        /** Resgata dados do segmento */
        if (segmentViewData) {
          const videoData = videosData[segmentViewData.video?.id || '']
          if (videoData) {
            segmentViewData.videoDuration = videoData.duration
            segmentViewData.videoSentence = videoData.sentence
            segmentViewData.id = data.primarySegment?.id
            segmentViewData.videoDuplicateOf = videoData.duplicateOf || ''
          }
          signViewData.segmentView = segmentViewData
        }
        //
        if (data.glosa == glosa) _signs.push(signViewData)
        else _unequalsSigns.push(signViewData)
      })
      const _ordenedSign = _signs.concat(_unequalsSigns)

      // Resgata as urls dos frames do video e faz o download
      for (const _sign of _ordenedSign) {
        if (_sign.segmentView) {
          _sign.segmentView.videoUrl = await getVideoUrl(
            _sign.segmentView.video.id,
            _sign.segmentView.videoDuplicateOf || '',
          )
        }
      }

      // Remove duplicados
      const reduced: ISignAndRef[] = []
      _ordenedSign.forEach((item) => {
        const duplicated =
          reduced.findIndex((el) => {
            return item.refSign.id == el.refSign.id
          }) > -1

        if (!duplicated) {
          reduced.push(item)
        }
      })
      //
      if (nmtSuggestionsBySegment && nmtSuggestionsBySegment.length) {
        setSigns(
          reduced.sort(
            (a, b) => nmtSuggestionsBySegment.indexOf(a.refSign.id) - nmtSuggestionsBySegment.indexOf(b.refSign.id),
          ),
        )
      } else {
        setSigns(reduced)
      }

      setIsLoading(false)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    setCountResults(signs.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signs])

  return (
    <Grid container spacing={2} style={{ maxHeight: '75vh', justifyContent: 'center' }}>
      {!isLoading &&
        signs.map(({ refSign, signData, segmentView }) => (
          <Grid item style={{ width: '50%', minWidth: '200px' }} key={refSign.id}>
            <DraggableRegionAction
              type="sign"
              representation={signData.glosa}
              sign={refSign}
              onEndDrag={handleEndDrag}
              onStartDrag={() => handleStartDrag(refSign.id)}
              segmentView={segmentView}
            >
              <SignView
                variant={draggingItemId == refSign.id ? 'badge' : 'default'}
                signRef={refSign}
                startWithValue={signData}
                segmentView={segmentView}
              />
            </DraggableRegionAction>
          </Grid>
        ))}
    </Grid>
  )
}

export default SignList
