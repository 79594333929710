import React, { createContext, useState, useContext, useCallback, useEffect } from 'react'
import { useFirestore, useStorage } from 'reactfire'

import logger from '../logger'
import useCamera from './useCamera'
import {
  IVideoFile,
  IVideoMidia,
  VideoId,
  IGlobalAttr,
  getPathVideoFile,
  serverTimestamp,
  Timestamp,
  updateVideoFlags,
  createVideo,
} from 'collections'
import useFirebaseAnalytics from './useFirebaseAnalytics'
import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import useSentence from '../../hooks/useSentence'
import useRecordingDuration from '../../hooks/useRecordingDuration'
import useCountdown from '../../hooks/useCountdown'
import { uploadToStorage } from '../utils'
import useContributionsLogger from './useContributionsLogger'
import useSnackbar from './useSnackbar'
import { useGlobal } from 'reactn'
import { updateQueuePriorityFunction } from '../firebase'
import useIntl from '../../hooks/useIntl'
import { collection, doc } from '@firebase/firestore'
import { getDocumentReference } from 'collections'
const { log, error } = logger('useModuleSignSentence.tsx')

interface ISignSentenceState {
  numberOfVideosToRecord: number
  recordedVideos: IVideoFile[]
}

const initialState: ISignSentenceState = {
  numberOfVideosToRecord: 1,
  recordedVideos: [],
}

interface IUpdateStateFunctions {
  recordVideo: () => Promise<void>
  removeVideo: (videoId: number) => void
  createContribution: (refreshSentence: boolean) => Promise<void>
}

const initialFunctions: IUpdateStateFunctions = {
  recordVideo: () => Promise.resolve(),
  removeVideo: () => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createContribution: (refreshSentence: boolean) => Promise.resolve(),
}

type Context = [ISignSentenceState, IUpdateStateFunctions]

const GlobalContext = createContext<Context>([initialState, initialFunctions])

export const ProviderModuleSignSentence: React.FC = ({ children }) => {
  // States
  const [state, setState] = useState(initialState)
  // Hooks
  const cameraFn = useCamera()[1]
  const firestore = useFirestore()
  const storage = useStorage()
  const [videoDuration, videoDurationFns] = useRecordingDuration()
  const countdownFns = useCountdown()[1]
  const fsUserRef = useFsUserDocRef()
  const fsUser = useFsUserDocData()
  const [sentenceState, sentenceFns] = useSentence('signSentence')

  const contributionsLoggerFns = useContributionsLogger()[1]
  const showSnackbar = useSnackbar()
  const [appConfig] = useGlobal<IGlobalAttr, 'appConfig'>('appConfig')
  const { videoCreatedEvent } = useFirebaseAnalytics()
  const intl = useIntl()
  // Quando o tempo mudar limpamos a lista de videos gravados
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      recordedVideos: [],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDuration, sentenceState.sentence])

  // Quando a sentença mudar, alteramos também o tempo
  useEffect(() => {
    if (sentenceState.sentence) {
      // Damos 900 milisegundo por palavra
      let _videoDuration = (sentenceState.sentence.split(' ').length || 1) * 900
      if (fsUser?.timeVideoRecord && fsUser?.timeVideoRecord > 0) _videoDuration = fsUser?.timeVideoRecord * 1000
      // Seta o a duração do video diretamente
      videoDurationFns.set(_videoDuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentenceState.sentence])

  /**
   * Inicia um contador de n segundos
   * Grava um novo vídeo e adiciona na fila de vídeos gravados
   */
  const recordVideo = useCallback(async () => {
    // Realiza a contagem de n segundos
    await countdownFns.run()

    const midiaId = doc(collection(firestore, 'tempRandom')).id
    const videoFile = await cameraFn.recordVideo(videoDuration, midiaId)

    // Adiciona o novo vídeo na lista de gravados
    setState((prev) => ({
      ...prev,
      recordedVideos: [...prev.recordedVideos, videoFile],
    }))
  }, [cameraFn, countdownFns, firestore, videoDuration])

  /**
   * Remove um vídeo da lista de vídeos gravados
   * @param videoId Id do vídeo que será removido do array
   */
  const removeVideo = useCallback(
    async (videoId: number) => {
      // Filtra os vídeos removendo o video atual
      const recordedVideos = state.recordedVideos.filter((_, index) => index !== videoId)
      // Seta o novo array
      setState((prev) => ({
        ...prev,
        recordedVideos,
      }))
    },

    [state.recordedVideos],
  )

  /**
   * Cria e prepara o documento do firestore.
   */
  const createFirestoreData = useCallback(
    async (videoId: VideoId) => {
      if (!sentenceState.sentence) {
        throw intl.get('messages.sentenceUndefined')
      }
      const midia: IVideoMidia[] = state.recordedVideos.map((video, index) => ({
        // Todos os vídeos gravados no community são frontais
        direction: 'front',
        fileName: video.id,
        // O primeiro vídeo gravado no community é sempre o principal
        isMain: index === 0,
      }))

      log(`Criando vídeo...`)

      // Converte a duração do video em segundos para frames
      const duration = Math.round((videoDuration / 1000) * appConfig.frameRate)

      await createVideo(fsUser, fsUserRef, videoId, {
        duration,
        sentence: sentenceState.sentence,
        midia,
        sentenceOrigin: (sentenceState.origin || '').toUpperCase(),
        createdOnDemand: false,
        corpusGroup: sentenceState.corpusGroup || 'TRAIN',
        sentenceCategory: sentenceState.sentenceCategory || ['undefined'],
        clientId: sentenceState.clientId || 'HT',
        isExternal: fsUser.isExternal || false,
      })

      videoCreatedEvent(videoId, duration)

      log(`${videoId} Criado`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sentenceState.sentence,
      state.recordedVideos,
      videoDuration,
      appConfig.frameRate,
      fsUser,
      fsUserRef,
      videoCreatedEvent,
    ],
  )

  /**
   * Cria uma nova contribuição e envia os vídeos para o storage
   */
  const createContribution = async (refreshSentence: boolean) => {
    // 1) Validação de dados
    const hasAllBlobs = state.recordedVideos.every((video) => !!video.blob)
    if (!hasAllBlobs) {
      error('Um ou mais vídeos não possui(em) blob(s)')
      showSnackbar(intl.get('messages.videoNotFound'), { variant: 'error' })
      return
    }

    if (!sentenceState.sentence) {
      error('Não existe frase ainda')
      showSnackbar(intl.get('messages.sentenceNotFound'), { variant: 'error' })
      return
    }

    if (state.recordedVideos.length < state.numberOfVideosToRecord) {
      error('Um dos VideoFile não existe!')
      showSnackbar(intl.get('messages.missingVideo'), { variant: 'error' })
      return
    }

    // 2) Criamos um id para o video isso muito importante para rodar tudo em paralelo
    const videoId = doc(collection(firestore, 'tempRandom')).id

    const identifier = `${sentenceState.sentence}##${+new Date()}`
    contributionsLoggerFns.update(identifier, 0, 'signSentence')

    // 3) Criando a coleção no firestore
    try {
      await createFirestoreData(videoId)
    } catch (e) {
      error(e)
      showSnackbar(intl.get('messages.firestoreVideoCreationFailed'), {
        variant: 'error',
      })
      return
    }
    if (refreshSentence)
      //Atualizamos a frase
      sentenceFns.refresh()
    // 4) Upload de vídeos
    try {
      showSnackbar(`"${sentenceState.sentence!.substring(0, 10)}..." ${intl.get('messages.uploadingVideo')}`, {
        variant: 'info',
      }),
        Promise.all(
          state.recordedVideos.map((video) => {
            const path = getPathVideoFile(fsUser.workspace.id, videoId)
            return uploadToStorage(
              storage,
              path,
              video.blob!,
              'video/webm;codecs=h264',
              identifier,
              (identifier, value) => contributionsLoggerFns.update(identifier, value, 'signSentence'),
            )
          }),
        )

      log(`Upload Completo: ${sentenceState.sentence}`)
      // Altera a prioridade na fila
      await updateQueuePriorityFunction({
        oralLanguageId: fsUser.oralLanguageId,
        signLanguageId: fsUser.signLanguageId,
        sentence: sentenceState.sentence || '',
        recordedVideo: true,
        workspaceId: fsUser.workspace.id,
        sentenceOrigin: (sentenceState.origin || '').toUpperCase(),
        corpusGroup: sentenceState.corpusGroup || 'TRAIN',
        sentenceCategory: sentenceState.sentenceCategory || ['undefined'],
        clientId: sentenceState.clientId || 'HT',
      })
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
      updateVideoFlags(videoRef, {
        isMidiaUploaded: true,
        isMidiaUploading: {
          value: false,
          lastUpdate: serverTimestamp() as Timestamp,
        },
        sentenceCategory: sentenceState.sentenceCategory || ['undefined'],
      })

      showSnackbar(`"${sentenceState.sentence!.substring(0, 10)}..." ${intl.get('messages.videoUploaded')}`, {
        variant: 'success',
      })
    } catch (e) {
      //Atualizamos a frase
      sentenceFns.refresh()

      error(e)
      const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoId)
      updateVideoFlags(videoRef, {
        isMidiaUploading: {
          value: true,
          lastUpdate: serverTimestamp() as Timestamp,
        },
      })
      showSnackbar(`"${sentenceState.sentence!.substring(0, 10)}..." ${intl.get('messages.videoUploadFail')}`, {
        variant: 'error',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const updateStateFns: IUpdateStateFunctions = {
    createContribution,
    recordVideo,
    removeVideo,
  }

  return <GlobalContext.Provider value={[state, updateStateFns]}>{children}</GlobalContext.Provider>
}

export default (): Context => useContext(GlobalContext)
