import {
  changePrimarySegmentFunction,
  joinClustersFunction,
  promoteClusterToSignFunction,
  removeSegmentFromClusterFunction,
} from '../services/firebase'
import useSnackbar from '../services/hooks/useSnackbar'
import logger from '../services/logger'
import { SegmentId, SignId } from 'collections'
import { useFsUserDocData, useFsUserDocRef } from './useFsUser'
import useIntl from './useIntl'

interface IUseHTubeModuleFns {
  changePrimarySegment: (segmentId: SegmentId, signId: SignId) => void
  removeSegmentFromCluster: (segmentId: SegmentId) => void
  joinClusters: (signId: SignId, signIds: SignId[]) => Promise<string>
  promoteClusterToSign: (signId: SignId) => void
}

const { error } = logger('HTubeModule.tsx')

const useModuleHTube = (): IUseHTubeModuleFns => {
  const fsUser = useFsUserDocData()
  const fsUserRef = useFsUserDocRef()
  const showSnackbar = useSnackbar()
  const intl = useIntl()

  /**
   * Altera a referência do segmento primário de um sinal
   *
   * @param segmentId - ID do segmento que será o primário
   * @param signId - ID do sinal que terá seu segmento primário alterado
   */
  const changePrimarySegment = async (segmentId: SegmentId, signId: SignId) => {
    try {
      showSnackbar(intl.get('messages.changingPrimarySegment'), {
        variant: 'info',
      })
      await changePrimarySegmentFunction({
        segmentId: segmentId,
        signId: signId,
        workspaceId: fsUser.workspace.id,
      })
      showSnackbar(intl.get('messages.success'), { variant: 'success' })
    } catch (e) {
      error(e)
      showSnackbar(intl.get('messages.failed'), { variant: 'error' })
    }
  }

  /**
   * Remove um segmento ao zerar seu score com todos segmentos do cluster
   *
   * @param segmentId - ID do segmento que será removido do cluster
   */
  const removeSegmentFromCluster = async (segmentId: SegmentId) => {
    try {
      await removeSegmentFromClusterFunction({
        segmentId: segmentId,
        workspaceId: fsUser.workspace.id,
        userId: fsUserRef.id,
      })
      showSnackbar(intl.get('messages.success'), { variant: 'success' })
    } catch (e) {
      error(e)
      showSnackbar(intl.get('messages.failed'), { variant: 'error' })
    }
  }

  const joinClusters = async (signId: SignId, signIds: SignId[]) => {
    try {
      const response = await joinClustersFunction({
        signId: signId,
        signIds: signIds,
        workspaceId: fsUser.workspace.id,
      })

      showSnackbar(intl.get('messages.success'), { variant: 'success' })
      return response.data
    } catch (e) {
      error(e)
      showSnackbar(intl.get('messages.failed'), { variant: 'error' })
    }
    return ''
  }

  /**
   * Promove o cluster a sinal
   *
   * @param signId - ID do sinal que será promovido
   */
  const promoteClusterToSign = async (signId: SignId) => {
    try {
      await promoteClusterToSignFunction({
        signId: signId,
        workspaceId: fsUser.workspace.id,
      })
      showSnackbar(intl.get('messages.success'), { variant: 'success' })
    } catch (e) {
      error(e)
      showSnackbar(intl.get('messages.failed'), { variant: 'error' })
    }
  }

  return {
    changePrimarySegment,
    removeSegmentFromCluster,
    joinClusters,
    promoteClusterToSign,
  }
}

export default useModuleHTube
