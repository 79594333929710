import React, { useState, useCallback, useEffect } from 'react'

import CardMessage from '../../components/CardMessage'
import Layout from '../../components/Layout'
import {
  IGlobalAttr,
  Query,
  getCollectionReference,
  IPaginationConfig,
  ISign,
  DocumentReference,
  IUserInfo,
  Timestamp,
} from 'collections'
import { useFsUserDocData } from '../../hooks/useFsUser'
import Preloader from '../../components/Preloader'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import TablePagination from '@material-ui/core/TablePagination'
import SignsList from './SignsLst'
import styled from 'styled-components'
import { useGlobal } from 'reactn'
import useIntl from '../../hooks/useIntl'
import {
  doc,
  DocumentData,
  endBefore,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from '@firebase/firestore'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import { useFirestore, useFirestoreCollection } from 'reactfire'
import { getUsersByWorkspaceFunction } from '../../services/firebase'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

interface IAnimationProps {
  path: string
}

const AnimationHomeContainer = styled.div`
  width: 90%;
  margin: 30px;
`

const SearchField = styled(TextField)`
  && {
    margin: 10px 0;
  }
`

let textDebouncer: NodeJS.Timeout

interface ISignWithId extends ISign {
  id: string
  ref: DocumentReference
  animator: string
  animatorCurrentAnimation: string
  animationCreatedAt: string | Timestamp
}

/**
 * Modulo de animação de sinais
 */
const Animation: React.FC<IAnimationProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [lastFilterSignList, setLastFilterSignList] = useGlobal<IGlobalAttr, 'lastFilterSignList'>('lastFilterSignList')
  const [lastFilter, setLastFilter] = useState(lastFilterSignList || 'pending')
  const [searchInputText, setSearchInputText] = useState('')
  const [currentAnimator, setCurrentAnimator] = useState('ALL')
  const [sorting, setSorting] = useState('numberOfSegments')
  const firestore = useFirestore()
  const fsUser = useFsUserDocData()
  const intl = useIntl()

  const [paginationConfig, setPaginationConfig] = useState<IPaginationConfig>({
    currentPage: 0,
    firstOfList: null,
    lastOfList: null,
    rowsPerPage: 30,
    load: true,
  })
  const hasPermissionOnModule = useCheckHasPermission('animation')
  const initialSignquery = query(
    getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
    where('isFingerspell', '==', false),
    where('currentAnimationHasErrors', '==', lastFilter == 'withError' ? true : null),
    where('_state', '==', lastFilter == 'pending' ? 'PROMOTED' : 'ANIMATED'),
    orderBy(sorting, 'desc'),
    limit(1),
  )

  const [signQueryState, setSignQueryState] = useState<Query<DocumentData>>(initialSignquery)

  const signsCollection = useFirestoreCollection(signQueryState)

  const [signs, setSigns] = useState<ISignWithId[]>([])

  const [hasMoreResults, setHasMoreResults] = useState(false)
  const [workspaceUsers, setWorkspaceUsers] = useState<Record<string, IUserInfo>>({})

  const getUsers = async () => {
    const { data } = await getUsersByWorkspaceFunction({})
    console.log(data)
    setWorkspaceUsers(data.users)
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSearchSign()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFilter])

  const fetchSigns = useCallback(() => {
    try {
      setIsLoading(true)

      let signsQuery = query(
        getCollectionReference(fsUser.workspace, 'signs') as Query<DocumentData>,
        where('isFingerspell', '==', false),
        where(
          'currentAnimationHasErrors',
          '==',
          lastFilter == 'withError' ? true : lastFilter == 'done' ? false : null,
        ),
        where('_state', '==', lastFilter == 'pending' ? 'PROMOTED' : 'ANIMATED'),
      )

      if (
        (lastFilter == 'withError' || lastFilter == 'unreviewed' || lastFilter == 'done') &&
        currentAnimator !== 'ALL'
      ) {
        const userData = Object.entries(workspaceUsers).filter((user) => {
          return user.length > 1 && user[1].displayName == currentAnimator
        })

        const fsUserRef = doc(firestore, `users/${userData[0][0]}`)
        signsQuery = query(signsQuery, where('currentAnimation.createdBy', '==', fsUserRef))
      }

      if (searchInputText.trim()) {
        signsQuery = query(signsQuery, where('searchTerms', 'array-contains', searchInputText.toLowerCase()))
      }

      signsQuery = query(signsQuery, orderBy(sorting, 'desc'))

      if (paginationConfig.lastOfList || paginationConfig.firstOfList) {
        if (paginationConfig.lastOfList) {
          signsQuery = query(signsQuery, startAfter(paginationConfig.lastOfList))
        } else {
          signsQuery = query(
            signsQuery,
            endBefore(paginationConfig.firstOfList),
            limitToLast(paginationConfig.rowsPerPage),
          )
        }
      }

      if (!paginationConfig.firstOfList) signsQuery = query(signsQuery, limit(paginationConfig.rowsPerPage))
      setSignQueryState(signsQuery)
    } finally {
      setIsLoading(false)
    }
  }, [
    currentAnimator,
    firestore,
    fsUser.workspace,
    lastFilter,
    paginationConfig.firstOfList,
    paginationConfig.lastOfList,
    paginationConfig.rowsPerPage,
    searchInputText,
    sorting,
    workspaceUsers,
  ])

  useEffect(() => {
    if (paginationConfig.load) {
      fetchSigns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig])

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _paginationConfig = { ...paginationConfig }
      _paginationConfig.rowsPerPage = Number(event.target.value)
      _paginationConfig.currentPage = 0
      _paginationConfig.lastOfList = null
      _paginationConfig.firstOfList = null
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  useEffect(() => {
    const _signs: ISignWithId[] = []

    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.lastOfList = signsCollection.data.docs[signsCollection.data.docs.length - 1]
    _paginationConfig.firstOfList = signsCollection.data.docs[0]
    _paginationConfig.load = false

    for (const signSnapshot of signsCollection.data.docs) {
      const signData = signSnapshot.data() as ISignWithId
      signData.id = signSnapshot.id
      signData.ref = signSnapshot.ref
      const currentUser = signData?.isAnimating?.user && workspaceUsers[signData.isAnimating.user.id]

      const currentUserCurrentAnimation =
        signData?.currentAnimation?.createdBy && workspaceUsers[signData.currentAnimation.createdBy.id]
      _signs.push({
        ...signData,
        animator: currentUser?.displayName || ``,
        animatorCurrentAnimation: currentUserCurrentAnimation?.displayName || '',
        animationCreatedAt: signData.currentAnimation?.createdAt || '',
      })
    }

    setPaginationConfig(_paginationConfig)
    setSigns(_signs)
    setHasMoreResults(paginationConfig.rowsPerPage === _signs.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signsCollection.data])

  const handleSearchSign = useCallback(() => {
    const _paginationConfig = { ...paginationConfig }
    _paginationConfig.firstOfList = null
    _paginationConfig.lastOfList = null
    _paginationConfig.currentPage = 0
    _paginationConfig.load = true
    setPaginationConfig(_paginationConfig)
  }, [paginationConfig])

  const onSignSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = (event.target && event.target.value) || ''
      setSearchInputText(text)
      clearTimeout(textDebouncer)
      textDebouncer = setTimeout(() => {
        handleSearchSign()
      }, 1000)
    },
    [handleSearchSign],
  )

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
      const _paginationConfig = { ...paginationConfig }
      if (page > _paginationConfig.currentPage) {
        _paginationConfig.firstOfList = null
      } else {
        _paginationConfig.lastOfList = null
      }
      _paginationConfig.currentPage = page
      _paginationConfig.load = true
      setPaginationConfig(_paginationConfig)
    },
    [paginationConfig],
  )

  const handleChangeStatus = (state: string) => {
    setLastFilter(state)
    setLastFilterSignList(state)
  }

  // Usuário não tem papel de escritor
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.animation')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.animation')} requiredModule={'animation'}>
      {/** Exibe o preload ja que está carregando */}
      {isLoading && <Preloader asBlock text={intl.get('messages.fetchingSign')}></Preloader>}

      {!isLoading && (
        <AnimationHomeContainer>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{intl.get('messages.signs')}</Typography>

              <Typography variant="body2">{intl.get('messages.signsList')}</Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" style={{ display: 'flex', gap: '10px' }}>
            <SearchField
              label={intl.get('messages.search')}
              variant="filled"
              value={searchInputText}
              onChange={onSignSearch}
            />
            <FormControl disabled={lastFilter == 'pending'} variant="filled">
              <InputLabel>Animador</InputLabel>
              <Select
                value={currentAnimator}
                onChange={(
                  event: React.ChangeEvent<{
                    value: unknown
                  }>,
                ) => {
                  setCurrentAnimator(event.target.value as string)
                  handleSearchSign()
                }}
              >
                <MenuItem value="ALL">Todos</MenuItem>
                <MenuItem value="Icaro Carvalho">Icaro</MenuItem>
                <MenuItem value="Mayron Oliveira">Mayron</MenuItem>
                <MenuItem value="ViniciusPremero">Vinicius Premero</MenuItem>
              </Select>
            </FormControl>
            <FormControl disabled={lastFilter == 'pending'} variant="filled">
              <InputLabel>{intl.get('pages.hTube.sign.orderBy')}</InputLabel>
              <Select
                value={sorting}
                onChange={(
                  event: React.ChangeEvent<{
                    value: unknown
                  }>,
                ) => {
                  setSorting(event.target.value as string)
                  handleSearchSign()
                }}
              >
                <MenuItem value="numberOfSegments">{intl.get('messages.numberOfSegments')}</MenuItem>
                <MenuItem value="currentAnimation.createdAt">{intl.get('messages.date')}</MenuItem>
                <MenuItem value="corpusPopularity">{intl.get('messages.popularity')}</MenuItem>
                <MenuItem value="currentAnimationReviewedAt">
                  {intl.get('messages.currentAnimationReviewedAt')}
                </MenuItem>
              </Select>
            </FormControl>
            <RadioGroup row value={lastFilter} onChange={(ev) => handleChangeStatus(ev.target.value)}>
              <FormControlLabel
                value="pending"
                control={<Radio color="primary" />}
                label={intl.get('pages.animation.pending')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="withError"
                control={<Radio color="primary" />}
                label={intl.get('pages.animation.hasErrors')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="unreviewed"
                control={<Radio color="primary" />}
                label={intl.get('pages.animation.unreviewed')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="done"
                control={<Radio color="primary" />}
                label={intl.get('pages.animation.done')}
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>

          <SignsList signsData={signs} />
          <TablePagination
            style={{ pointerEvents: 'auto' }}
            component="div"
            count={-1}
            rowsPerPage={paginationConfig.rowsPerPage}
            page={paginationConfig.currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            rowsPerPageOptions={[30, 50, 100]}
            labelRowsPerPage={intl.get('messages.labelRows')}
            labelDisplayedRows={({ from, to }) =>
              intl.get('pages.animation.labelDisplayedRows', {
                from,
                to,
              })
            }
            nextIconButtonProps={{ disabled: !hasMoreResults }}
          />
        </AnimationHomeContainer>
      )}
    </Layout>
  )
}

export default Animation
