import { ref, getDownloadURL } from '@firebase/storage'
import { where, query, getDocs, orderBy } from '@firebase/firestore'

import {
  getCollectionReference,
  getPathFramesJson,
  Query,
  DocumentData,
  getDocumentReference,
  DocumentReference,
  ISignOnDemand,
  IGlobalAttr,
  IVideo,
} from 'collections'
import React, { useEffect } from 'react'
import { useStorage } from 'reactfire'
import { useFsUserDocData } from '../../../hooks/useLoggedUser'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import VerticalTimelineItemHoverable from '../VerticalTimelineItemHoverable'
import styled from 'styled-components'
import { ISegmentViewProcessVideo, ISignWithRef, IVideoWithId } from '..'
import useDialog from '../../../services/hooks/useDialog'
import { red } from '@material-ui/core/colors'
import useIntl from '../../../hooks/useIntl'
import { useGlobal } from 'reactn'

interface IVerticalTimelineProps {
  videoData: IVideoWithId | null
  setSegments: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo[]>>
  segments: ISegmentViewProcessVideo[]
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setsegmentToSuggestion: React.Dispatch<React.SetStateAction<ISegmentViewProcessVideo | null>>
  setEditing: React.Dispatch<React.SetStateAction<boolean>>

  setSignOnDemandLinked: React.Dispatch<React.SetStateAction<Record<string, ISignOnDemand>>>
  signOnDemandLinked: Record<string, ISignOnDemand>

  fetchSignsOnDemand: (segmentsIds: string[]) => void
  setSignsData: React.Dispatch<React.SetStateAction<Record<string, ISignWithRef | null>>>
  signsData: Record<string, ISignWithRef | null>
}

const ScrollableGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  max-height: 90vh;
  overflow: auto;
  gap: 10px;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #696969;
    border-radius: 5px;
  }
`

const VerticalTimeline: React.FC<IVerticalTimelineProps> = ({
  videoData,
  setSegments,
  segments,
  isLoading,
  setIsLoading,
  setsegmentToSuggestion,
  setEditing,
  setSignOnDemandLinked,
  signOnDemandLinked,
  fetchSignsOnDemand,
  signsData,
  setSignsData,
}) => {
  const storage = useStorage()
  const fsUser = useFsUserDocData()
  const handleReportClick = useDialog('reportSegmentDialog')[1]
  const setFsVideoRef = useGlobal<IGlobalAttr, 'fsVideoRef'>('fsVideoRef')[1]
  const setFsVideoDocData = useGlobal<IGlobalAttr, 'fsVideoDocData'>('fsVideoDocData')[1]
  const intl = useIntl()

  const getVideoUrl = async () => {
    if (!videoData) return ''
    const urlString = getPathFramesJson(fsUser.workspace.id, videoData.id, videoData.duplicateOf || '')
    const url = await getDownloadURL(ref(storage, urlString))
    return url
  }

  const fetchSegments = async () => {
    setIsLoading(true)
    try {
      const _segments: ISegmentViewProcessVideo[] = []
      if (videoData) {
        const videoUrl = await getVideoUrl()
        const response = await fetch(videoUrl, { cache: 'force-cache' })
        const json = await response.json()
        const { frames } = json
        const segmentsCollectionRef = getCollectionReference(fsUser.workspace, 'segments')
        const videoRef = getDocumentReference(fsUser.workspace, 'videos', videoData.id)
        const segmentsQuery = query(
          segmentsCollectionRef as Query<DocumentData>,
          where('video', '==', videoRef),
          orderBy('startFrame'),
        )
        const segmentsSnapshot = await getDocs(segmentsQuery)

        for (const i of segmentsSnapshot.docs) {
          const segmentData = i.data() as ISegmentViewProcessVideo
          segmentData.ref = i.ref
          segmentData.videoUrl = videoUrl
          segmentData.framesVideo = frames
          segmentData.id = i.id
          segmentData.videoDuration = videoData.duration
          segmentData.videoSentence = videoData.sentence
          segmentData.videoSentenceCategories = videoData.sentenceCategory
          segmentData.videoSentenceOrigin = videoData.sentenceOrigin
          segmentData.oldMovement = (segmentData.movement as DocumentReference) || null
          segmentData.oldType = segmentData.type
          segmentData.oldText = segmentData.text
          _segments.push(segmentData)
        }
      }
      setSegments(_segments)
      fetchSignsOnDemand(_segments.map((el) => el.id || ''))
    } finally {
      setIsLoading(false)
    }
  }

  const handleReportVideoButtonClick = () => {
    if (!videoData) return

    handleReportClick()
    setFsVideoDocData(videoData as IVideo)
    setFsVideoRef(videoData.ref)
  }

  useEffect(() => {
    fetchSegments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoData?.id])

  return (
    <>
      {!isLoading && segments.length > 0 && (
        <Box style={{ width: '100%' }}>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              style={{
                margin: '20px 0px',
              }}
            >
              {videoData?.sentenceCategory?.length == 1
                ? `Categoria da frase: ${videoData.sentenceCategory}.`
                : `Categorias da frase: ${videoData?.sentenceCategory?.join(', ')}.`}
            </Typography>

            <Box mr={2}>
              <Button
                variant="outlined"
                style={{ color: red['400'], borderColor: red['400'] }}
                onClick={handleReportVideoButtonClick}
              >
                {intl.get('messages.report')}
              </Button>
            </Box>
          </Box>
          <ScrollableGrid item container>
            {segments.map((el, index) => (
              <VerticalTimelineItemHoverable
                signsData={signsData}
                setSignsData={setSignsData}
                key={index}
                index={index}
                segmentView={el}
                segments={segments}
                setSegments={setSegments}
                setsegmentToSuggestion={setsegmentToSuggestion}
                setEditing={setEditing}
                setSignOnDemandLinked={setSignOnDemandLinked}
                signOnDemandLinked={signOnDemandLinked}
              />
            ))}
          </ScrollableGrid>
        </Box>
      )}
    </>
  )
}

export default VerticalTimeline
