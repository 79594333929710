import { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import LessIcon from '@material-ui/icons/RemoveCircleOutline'
import MoreIcon from '@material-ui/icons/AddCircleOutline'
import useIntl from '../../../hooks/useIntl'

interface ISecondsIncreaseDecreasePros {
  text?: string
  seconds: string
  /**
   * Ex.: Horas, Minutos e Segundos
   * */
  unidadeTempo: string
  durationDecease: () => void
  durationIncrease: () => void
}

export const SecondsIncreaseDecrease: FC<ISecondsIncreaseDecreasePros> = ({
  text,
  seconds,
  durationDecease,
  durationIncrease,
  unidadeTempo,
}) => {
  const intl = useIntl()
  return (
    <Typography variant="h6" gutterBottom align="center">
      {text}
      <span>
        <Tooltip title={intl.get('components.textHeader.secondsIncreaseDecrease.decrease')}>
          <IconButton size="small" onClick={durationDecease} color="inherit">
            <LessIcon />
          </IconButton>
        </Tooltip>
        {seconds}
        <Tooltip title={intl.get('components.textHeader.secondsIncreaseDecrease.decrease')}>
          <IconButton size="small" onClick={durationIncrease} color="inherit">
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </span>
      {unidadeTempo}
    </Typography>
  )
}

export default SecondsIncreaseDecrease
