import {
  Box,
  Chip,
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { collection, IAbTestNmt, IRatings, preProcess, IUserInfo } from 'collections'
import { getDocs, Timestamp } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useFirestore } from 'reactfire'
import Layout from '../../components/Layout'
import { useFsUserDocData } from '../../hooks/useFsUser'
import useIntl from '../../hooks/useIntl'
import { useLocation } from 'wouter'
import { routes } from '../../community'
import useCheckHasPermission from '../../services/hooks/useCheckHasPermission'
import CardMessage from '../../components/CardMessage'
import md5 from 'md5'
import { getAllUsersFunction } from '../../services/firebase'

interface IAbTestResultsProps {
  path: string
}

interface IRating {
  createdAt: Timestamp
  sentence: string
  score_a: number | null
  score_b: number | null
  tags_a: string[]
  tags_b: string[]
  userCount: number | 0
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.light : theme.palette.grey[500],
      },
    },
  }),
)

const AbTestNmtResults: React.FC<IAbTestResultsProps> = () => {
  const intl = useIntl()
  const firestore = useFirestore()
  const fsUser = useFsUserDocData()
  const classes = useStyles()
  const [, setLocation] = useLocation()
  const hasPermissionOnModule = useCheckHasPermission('abTestNmtResults')

  const [tabValue, setTabValue] = useState('1')
  const [versions, setVersions] = useState<IAbTestNmt[]>([])
  const [ratings, setRatings] = useState<{
    [abTestNmtID: string]: { [ratingsID: string]: IRatings }
  }>({})
  const [selectedVersion, setSelectedVersion] = useState<IAbTestNmt | undefined>()
  const [score, setScore] = useState<{
    score_a: number
    score_b: number | null
    difference: number
  }>({ score_a: 0, score_b: null, difference: 0 })
  const [userRatingsQuantity, setUserRatingsQuantity] = useState<{
    [userId: string]: number
  }>({})
  const [users, setUsers] = useState<Record<string, IUserInfo>>({})

  const handleChange = (newValue: string) => {
    setTabValue(newValue)
  }

  const handleClickVersion = (version: IAbTestNmt) => {
    setSelectedVersion(version)
  }

  const phrasesRows = () => {
    if (!selectedVersion) return <></>

    const _ratings: IRating[] = []
    const abTestNmtID = md5(selectedVersion?.version_a + selectedVersion?.version_b)
    Object.values(ratings[abTestNmtID] || {}).forEach((rating) => {
      Object.values(rating.usersRatings).forEach((element) => {
        const index = _ratings.findIndex((el) => el.sentence == rating.sentence)
        if (index == -1) {
          const _rating: IRating = {
            ...element,
            userCount: 1,
            sentence: rating.sentence,
          }
          _ratings.push(_rating)
        } else {
          const _rating = {
            ..._ratings[index],
            sentence: rating.sentence,
            userCount: _ratings[index].userCount + 1,
            score_a: Math.round(
              ((_ratings[index].score_a || 0) + (element.score_a || 0)) / (_ratings[index].userCount + 1),
            ),
            score_b: !_ratings[index].score_b
              ? Math.round(((_ratings[index].score_b || 0) + (element.score_b || 0)) / (_ratings[index].userCount + 1))
              : null,
          }
          _ratings[index] = _rating
        }
      })
    })

    _ratings.sort((a, b) => {
      return a.sentence.toLowerCase() > b.sentence.toLowerCase()
        ? 1
        : b.sentence.toLowerCase() > a.sentence.toLowerCase()
        ? -1
        : 0
    })

    return _ratings.map((rating) => (
      <TableRow key={rating.sentence}>
        <TableCell component="th" scope="row">
          {rating.sentence}
        </TableCell>
        <TableCell align="right">{rating.userCount}</TableCell>
        <TableCell align="right">{intl.get(`pages.abTestNmtResults.qualityConst.${rating.score_a || 0}`)}</TableCell>
        <TableCell align="right">
          {rating.score_b ? intl.get(`pages.abTestNmtResults.qualityConst.${rating.score_b || 0}`) : ''}
        </TableCell>
      </TableRow>
    ))
  }

  const tagsRows = () => {
    if (!selectedVersion) return <></>
    const _ratings: IRating[] = []
    const abTestNmtID = md5(selectedVersion?.version_a + selectedVersion?.version_b)

    Object.entries(ratings[abTestNmtID] || {}).map((rating) => {
      Object.entries(rating[1].usersRatings).map((user) => {
        const _rating = { ...user[1], userCount: 1 } as unknown as IRating
        _ratings.push(_rating)
      })
    })

    const tags: Record<
      string,
      {
        countA: number
        countB: number
      }
    > = {}

    _ratings.map((rating) => {
      rating.tags_a.map((tag: string) => {
        tags[tag] = {
          ...tags[tag],
          countA: tags[tag] ? (tags[tag].countA || 0) + 1 : 1,
        }
      })
      rating.tags_b.map((tag: string) => {
        tags[tag] = {
          ...tags[tag],
          countB: tags[tag] ? (tags[tag].countB || 0) + 1 : 1,
        }
      })
    })

    return Object.entries(tags).map((tag) => (
      <TableRow key={tag[0]}>
        <TableCell component="th" scope="row">
          {tag[0]}
        </TableCell>
        <TableCell align="right">{tag[1].countA || 0}</TableCell>
        <TableCell align="right">{tag[1].countB || 0}</TableCell>
      </TableRow>
    ))
  }

  const translationsHistory = () => {
    if (!selectedVersion) return <></>

    const _notTranslated: string[] = []
    const abTestNmtID = md5(selectedVersion?.version_a + selectedVersion?.version_b)

    Object.values(ratings[abTestNmtID] || {}).forEach((rating) => {
      if (rating.translate_a && (!rating.translate_a.length || rating.translate_a.length == 0))
        _notTranslated.push(rating.sentence)
    })

    return _notTranslated.map((sentence, index) => (
      <TableRow key={`${sentence}-${index}`}>
        <TableCell component="th" scope="row">
          {sentence}
        </TableCell>
      </TableRow>
    ))
  }

  const getVersions = async () => {
    const abTestsNmtRef = collection(firestore, 'workspaces', fsUser.workspace.id, 'abTestNmt')
    const abTestsNmtDocs = await getDocs(abTestsNmtRef)
    const _versions: IAbTestNmt[] = []
    let _ratingsDocs: {
      [abTestNmtID: string]: { [ratingsID: string]: IRatings }
    } = {}
    for (const doc of abTestsNmtDocs.docs) {
      const abTestNmt = doc.data() as IAbTestNmt

      _versions.push(abTestNmt)

      const ratingsRef = collection(firestore, 'workspaces', fsUser.workspace.id, 'abTestNmt', doc.id, 'ratings')
      const _ratingsDoc = await getDocs(ratingsRef)
      for (const ratingDoc of _ratingsDoc.docs) {
        const _rating = ratingDoc.data() as IRatings
        const md5Sentence = preProcess(_rating.sentence, fsUser?.workspace.id || '', false, false, true)
        _ratingsDocs = {
          ..._ratingsDocs,
          [doc.id]: { ..._ratingsDocs[doc.id], [md5Sentence]: _rating },
        }
      }
    }
    setRatings(_ratingsDocs)
    setVersions(_versions)
    setSelectedVersion(_versions[0])
  }

  const fetchUsers = async () => {
    const { data } = await getAllUsersFunction({})
    setUsers({ ...data.users })
  }

  useEffect(() => {
    getVersions()
    fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedVersion) {
      const _ratings: IRating[] = []
      const abTestNmtID = md5(selectedVersion?.version_a + selectedVersion?.version_b)
      const _userRatingsQuantity: { [userId: string]: number } = {}
      Object.entries(ratings[abTestNmtID] || {}).map((rating) => {
        Object.entries(rating[1].usersRatings).map((user) => {
          _userRatingsQuantity[user[0]] = (_userRatingsQuantity[user[0]] || 0) + 1
          const _rating = { ...user[1], userCount: 1 } as unknown as IRating
          _ratings.push(_rating)
        })
      })
      let scoreA = 0
      let scoreB = 0
      _ratings.forEach((rating) => {
        scoreA += rating.score_a || 0
        scoreB += rating.score_b || 0
      })
      scoreA = scoreA / _ratings.length
      scoreB = scoreB / _ratings.length
      setScore({
        score_a: scoreA,
        score_b: scoreB,
        difference: scoreB - scoreA,
      })
      setUserRatingsQuantity(_userRatingsQuantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersion])

  // Usuário não tem papel de escritor
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.abTestNmtResults')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  return (
    <Layout title={intl.get('modules.abTestNmtResults')}>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={3}>
          <Box
            style={{
              marginTop: '20px',
              marginLeft: '10px',
            }}
          >
            <Tooltip title={intl.get('messages.back')}>
              <Fab
                aria-label="Back"
                style={{
                  marginLeft: '10px',
                }}
                color="default"
                size="small"
                onClick={() => setLocation(routes.abTestNmt)}
              >
                <ArrowBackIcon />
              </Fab>
            </Tooltip>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              minWidth: '100px',
            }}
          >
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer
                style={{
                  maxHeight: 440,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TableHead
                  style={{
                    width: '100%',
                  }}
                >
                  <TableRow
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <TableCell
                      key={1}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Typography
                        gutterBottom
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {intl.get('pages.abTestNmtResults.scope')}
                      </Typography>
                    </TableCell>
                    <TableCell
                      key={1}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Typography
                        gutterBottom
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {intl.get('pages.abTestNmtResults.version')}
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        (A | B)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '5px',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  {versions.map((version, index) => (
                    <TableRow
                      key={index}
                      className={classes.tableRow}
                      onClick={() => {
                        handleClickVersion(version)
                      }}
                      style={{ width: '100%' }}
                      selected={
                        version.version_a + version.version_b ==
                        (selectedVersion ? selectedVersion.version_a + selectedVersion.version_b : '')
                      }
                    >
                      <TableCell
                        style={{
                          cursor: 'pointer',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Typography
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '50%',
                          }}
                        >
                          {version.scope}
                        </Typography>
                        <Typography
                          gutterBottom
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '50%',
                          }}
                        >
                          {version.version_a + ' | ' + version.version_b}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row',
              marginTop: '50px',
              marginBottom: '50px',
            }}
          >
            <Chip
              color="primary"
              label={
                isNaN(score.score_a)
                  ? intl.get('pages.abTestNmtResults.noData')
                  : `${intl.get('pages.abTestNmtResults.score')} A: ${score.score_a.toFixed(2)}`
              }
            />
            <Chip
              color="primary"
              label={
                score.score_b
                  ? isNaN(score.score_b)
                    ? intl.get('pages.abTestNmtResults.noData')
                    : `${intl.get('pages.abTestNmtResults.score')} B: ${score.score_b.toFixed(2)}`
                  : intl.get('pages.abTestNmtResults.noData')
              }
            />
            <Chip
              color="primary"
              label={
                isNaN(score.difference)
                  ? intl.get('pages.abTestNmtResults.noData')
                  : `${intl.get('pages.abTestNmtResults.difference')} : ${score.difference.toFixed(2)}`
              }
            />
            <Chip
              color="primary"
              label={`${intl.get('pages.abTestNmtResults.totalSentences')} : ${selectedVersion?.totalSentences || 0}`}
            />
          </Box>

          {selectedVersion && (
            <TabContext value={tabValue}>
              <Box
                style={{
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <TabList
                  onChange={(_, newValue) => handleChange(newValue)}
                  aria-label="lab API tabs example"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab label={intl.get('pages.abTestNmtResults.phrases')} value={'1'} />
                  <Tab label={intl.get('pages.abTestNmtResults.tags')} value={'2'} />
                  <Tab label={intl.get('pages.abTestNmtResults.userHistory')} value={'3'} />
                  <Tab label={intl.get('pages.abTestNmtResults.translationHistory')} value={'4'} />
                </TabList>
              </Box>

              <TabPanel value={'1'}>
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{intl.get('pages.abTestNmtResults.phrase')}</TableCell>
                        <TableCell align="right">{intl.get('pages.abTestNmtResults.userCounter')}</TableCell>
                        <TableCell align="right">
                          {intl.get('pages.abTestNmtResults.score')}: {selectedVersion.version_a}
                        </TableCell>
                        <TableCell align="right">
                          {intl.get('pages.abTestNmtResults.score')}: {selectedVersion.version_b}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{phrasesRows()}</TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={'2'}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>TAG</TableCell>
                        <TableCell align="right">
                          {intl.get('pages.abTestNmtResults.count')}: {selectedVersion.version_a}
                        </TableCell>
                        <TableCell align="right">
                          {intl.get('pages.abTestNmtResults.count')}: {selectedVersion.version_b}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{tagsRows()}</TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={'3'}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{intl.get('pages.abTestNmtResults.users')}</TableCell>
                        <TableCell align="left">{intl.get('pages.abTestNmtResults.ratedPhrases')}</TableCell>
                        <TableCell align="left">{intl.get('pages.abTestNmtResults.unratedPhrases')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(userRatingsQuantity).map((el, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {users[el[0]] && users[el[0]].displayName ? users[el[0]].displayName : ''}
                          </TableCell>
                          <TableCell>{el[1]}</TableCell>
                          <TableCell>{selectedVersion?.totalSentences || 0 - el[1]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={'4'}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{intl.get('pages.abTestNmtResults.untranslatedPhrases')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{translationsHistory()}</TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabContext>
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AbTestNmtResults
