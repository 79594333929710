import { useGlobal } from 'reactn'

import { IGlobalAttr, INmtRegion } from 'collections'

interface ITranslationValidationFns {
  setRegions: (regions: INmtRegion[]) => void
}

type TranslationValidationHookTuple = [INmtRegion[], ITranslationValidationFns]

/**
 * Controla o estado global de traducao
 */
const useTranslationValidation = (): TranslationValidationHookTuple => {
  const [translationValidation, setTranslationValidation] = useGlobal<IGlobalAttr, 'translationValidation'>(
    'translationValidation',
  )

  const fns: ITranslationValidationFns = {
    setRegions: setTranslationValidation,
  }

  return [translationValidation || [], fns]
}

export default useTranslationValidation
