import React, { useMemo } from 'react'
import { useUser } from 'reactfire'

import AvatarPure, { IUserAvatarProps } from './Pure'
import { getGravatarUrl } from '../../services/utils'

// Avatar controlado do usuário.
export const UserAvatar: React.FC<Pick<IUserAvatarProps, 'onClick'>> = ({ onClick }) => {
  const fbUser = useUser<firebase.default.User>()

  const avatarUrl = useMemo(() => getGravatarUrl(fbUser.data?.email || ''), [fbUser.data?.email])

  return <AvatarPure onClick={onClick} imageUrl={avatarUrl} displayName={fbUser.data?.displayName || 'Anonymous'} />
}
