import React from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import { Hidden, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const BoxStyled = styled(Box)`
  background-color: rgba(255, 255, 255, 0.2);
`

interface IContributionMetadataViewPureProps {
  text: string
  avatarEl?: JSX.Element
  timerEl?: JSX.Element
  isLoading?: boolean
}

/**
 * Visualizador de metadados de contribuição
 */
const ContributionMetadataViewPure: React.FC<IContributionMetadataViewPureProps> = ({
  text,
  avatarEl,
  timerEl,
  isLoading,
}) => {
  return (
    <Box position="relative">
      <BoxStyled
        pl={1}
        height={32}
        pr={avatarEl ? 3 : 1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={5}
      >
        {isLoading && <Skeleton width={300} variant="text" />}
        {!isLoading && (
          <>
            <Box>
              <Typography>{text}</Typography>
            </Box>
            {timerEl && <Box ml={1}>{timerEl}</Box>}
          </>
        )}
      </BoxStyled>

      {avatarEl && (
        <Hidden mdDown>
          <Box position="absolute" right={-15} top={-3}>
            {avatarEl}
          </Box>
        </Hidden>
      )}
    </Box>
  )
}

export default ContributionMetadataViewPure
