import React, { useEffect, useState } from 'react'
import { useAuth, useFirestore } from 'reactfire'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WorkIcon from '@material-ui/icons/Work'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { useFsUserDocData, useFsUserDocRef } from '../../hooks/useFsUser'
import useSnackbar from '../../services/hooks/useSnackbar'
import { WorkspaceId, IWorkspace, updateUser, getWorkspace } from 'collections'
import useFeatureToggle from '../../services/hooks/useFeatureToggle'
import { doc } from '@firebase/firestore'

const SelectStyled = styled(Select)`
  .MuiSelect-root {
    padding: 0;

    &:focus {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 0;
    }
  }
  .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    fieldset {
      border: none !important;
    }
  }
`

const id = 'workspace-selector'

const WorkspaceSelect: React.FC = () => {
  const firestore = useFirestore()
  const fsUser = useFsUserDocData()
  const fbAuth = useAuth()
  const fsUserRef = useFsUserDocRef()
  const workspaceId = fsUser.workspace.id
  const [featureToggleState] = useFeatureToggle()
  const showSnackbar = useSnackbar()
  const [workspaceIds, setWorkspaceIds] = useState<string[]>([])
  const handleOnValueChange = async (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    if (!featureToggleState.featuresMap.workspaceChange) {
      event.preventDefault()
      showSnackbar('Feature locked, talk to admin', { variant: 'warning' })
      return
    }

    try {
      const workspaceId = event.target.value as WorkspaceId
      const workspace = doc(firestore, 'workspaces', workspaceId)
      const workspaceSnapshot = await getWorkspace(workspace)
      const workspaceData = workspaceSnapshot.data() as IWorkspace

      const oralLanguageId = workspaceData.oralLanguageId
      const signLanguageId = workspaceData.signLanguageId

      await updateUser(fsUserRef, {
        workspace,
        oralLanguageId,
        signLanguageId,
      })

      showSnackbar(`Workspace changed to "${workspaceId}"`, {
        variant: 'success',
      })
    } catch (err) {
      console.log(err)
      showSnackbar(`Fail in workspace transition`, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    const call = async () => {
      const userAuth = await fbAuth.currentUser?.getIdTokenResult()
      const userClaims: any = userAuth?.claims
      let _workspaceIds: string[] = []
      Object.values(userClaims?.permissions || {}).forEach((element: any) => {
        Object.entries(element).forEach((el) => {
          if (el[1] == 1) _workspaceIds = [...new Set([..._workspaceIds, el[0]])]
        })
      })
      setWorkspaceIds(_workspaceIds)
    }
    call()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderValue = () => {
    return (
      <ListItem>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText>{workspaceId}</ListItemText>
      </ListItem>
    )
  }

  return (
    <SelectStyled
      value={workspaceId}
      renderValue={renderValue}
      inputProps={{ name: id, id }}
      variant="outlined"
      fullWidth
      onChange={handleOnValueChange}
      disabled={!featureToggleState.featuresMap.workspaceChange}
      MenuProps={{
        style: { zIndex: 2002 },
      }}
    >
      {workspaceIds.map((workspaceId) => (
        <MenuItem key={workspaceId} value={workspaceId}>
          {workspaceId}
        </MenuItem>
      ))}
    </SelectStyled>
  )
}

export default WorkspaceSelect
