import React, { useState } from 'react'

import { makeStyles, Box } from '@material-ui/core'
import { IMenuOptions, ISegmentView, SegmentId, SignId } from 'collections'
import HoverablePlayer from '../../HoverablePlayer'
import useIntl from '../../../../hooks/useIntl'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'
import { useRoute } from 'wouter'
import { routes } from '../../../../community'
import useSnackbar from '../../../../services/hooks/useSnackbar'

interface ICardSegment {
  segment: ISegmentView
  removeSegmentFromCluster?: (segmentId: SegmentId) => void
  changePrimarySegment?: (segmentId: SegmentId, signId: SignId) => void
  id: string
  segments: ISegmentView[]
  setSegments: React.Dispatch<React.SetStateAction<ISegmentView[]>>
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    bottom: '5%',
    right: '3%',
  },
  popover: {
    '& button': {
      display: 'block',
      width: '100%',
      textAlign: 'left',
      textTransform: 'initial',
      fontWeight: 'normal',
      borderBottom: '1px solid #999',
    },
  },
}))

const CardSegment: React.FC<ICardSegment> = ({
  segment,
  removeSegmentFromCluster,
  changePrimarySegment,
  id,
  segments,
  setSegments,
}) => {
  const [opennedRemoveDialog, setOpennedRemoveDialog] = useState(false)
  const [opennedChangeDialog, setOpennedChangeDialog] = useState(false)
  const [, routeParams] = useRoute(routes.hTube)
  const signId: SignId = routeParams?.clusterId || id
  const intl = useIntl()
  const showSnackbar = useSnackbar()

  const toggleOpenRemoveDialog = () => {
    setOpennedRemoveDialog(!opennedRemoveDialog)
  }

  const toggleOpenChangeDialog = () => {
    setOpennedChangeDialog(!opennedChangeDialog)
  }

  const handleRemoveSegment = () => {
    try {
      if (removeSegmentFromCluster) removeSegmentFromCluster(segment.id || '')
      const segmentIndex = segments.findIndex((el) => el.id == segment.id)
      const _segments = [...segments]
      _segments.splice(segmentIndex, 1)
      setSegments(_segments)
    } catch (err) {
      const error = err as Error
      showSnackbar(error.message, { variant: 'error' })
    }
    toggleOpenRemoveDialog()
  }

  const handleChangeSegment = () => {
    if (changePrimarySegment) changePrimarySegment(segment.id || '', signId)
    toggleOpenChangeDialog()
  }
  const classes = useStyles()

  const menuCardSegment: IMenuOptions[] = [
    {
      title: intl.get('pages.hTube.sign.removeSegmentFromCluster'),
      onClick: toggleOpenRemoveDialog,
    },
    {
      title: intl.get('pages.hTube.sign.changePrimarySegment'),
      onClick: toggleOpenChangeDialog,
    },
  ]

  return (
    <Box className={classes.root}>
      <HoverablePlayer segment={segment} menu={menuCardSegment} />
      {removeSegmentFromCluster && changePrimarySegment && (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <ConfirmationDialog
              open={opennedRemoveDialog}
              toggleOpen={toggleOpenRemoveDialog}
              handleYes={handleRemoveSegment}
              title={intl.get('pages.hTube.sign.removeSegmentFromCluster')}
              subtitle={intl.get('pages.hTube.sign.confirmRemotion')}
            />
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <ConfirmationDialog
              open={opennedChangeDialog}
              toggleOpen={toggleOpenChangeDialog}
              handleYes={handleChangeSegment}
              title={intl.get('pages.hTube.sign.changePrimarySegment')}
              subtitle={intl.get('pages.hTube.sign.confirmAlteration')}
            />
          </div>
        </>
      )}
    </Box>
  )
}

export default CardSegment
