import {
  Typography,
  Button,
  TextField,
  Box,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import googleImg from '../../images/googleIcon.svg'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'
import { OralLanguageId } from 'collections'
import { doc } from '@firebase/firestore'
import { useFirestore } from 'reactfire'
import logger from '../../services/logger'
import useSnackbar from '../../services/hooks/useSnackbar'
import styled from 'styled-components'
import { auth, createUserOnWorkspaceFunction, getWorkspaceToUserFunction } from '../../services/firebase'
import { useLocation } from 'wouter'
import { routes } from '../../community'
import useIntlNoAuth from '../../hooks/useIntlNoAuth'
import { useLocalStorage } from '../../hooks/useLocalStorage'
const { error } = logger('Auth')

const FullScreenGrid = styled(Grid)`
  width: 100%;
  height: 100%;
`

interface IRegistrationProps {
  path: string
}

const Registration: React.FC<IRegistrationProps> = () => {
  const firestore = useFirestore()
  const provider = new GoogleAuthProvider()

  const showSnackbar = useSnackbar()
  const [, setLocation] = useLocation()

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [hasUsernameError, setHasUsernameError] = useState(false)
  const [hasEmailError, setHasEmailError] = useState(false)
  const [hasPasswordError, setHasPasswordError] = useState(false)
  const [equalsPasswords, setEqualsPassword] = useState(false)
  const [workspacePath, setWorkspacePath] = useState<string>('')
  const [googleWorkspacePath, setGoogleWorkspacePath] = useState<string>('')
  const [workspaces, setWorkspaces] = useState<{ name: string; path: string }[]>()
  const { getLocalStorageValue } = useLocalStorage()
  const [lang, setLang] = useState<OralLanguageId>(OralLanguageId.eng)
  const intl = useIntlNoAuth(lang)
  const [openWorkspaceModal, setOpenWorkspaceModal] = useState(false)

  useEffect(() => {
    const call = async () => {
      const _workspaces = (await getWorkspaceToUserFunction()).data
      setWorkspaces(_workspaces)
    }
    call()

    const defaultLanguage: OralLanguageId =
      (getLocalStorageValue('defaultLanguage') as OralLanguageId) || OralLanguageId.eng

    setLang(defaultLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Campo de nome foi alterado
   */
  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
    setHasUsernameError(event.target.value.length === 0)
  }

  /**
   * Campo de email foi alterado
   */
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim())
    setHasEmailError(event.target.value.length === 0)
  }

  /**
   * Campo de password foi alterado
   */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setHasPasswordError(event.target.value.length === 0)
  }

  /**
   * Campo para confirmação de password foi alterado
   */
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value)
    setEqualsPassword(event.target.value === password)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      handleRegisterWithEmail()
    }
  }

  const handleChooseWorkspace = () => {
    setOpenWorkspaceModal(!openWorkspaceModal)
  }

  const handleChangeDialogWorkspace = (
    event: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    const _workspacePath = event.target.value as string
    setGoogleWorkspacePath(_workspacePath)
  }

  const handleSignWithGoogle = () => {
    if (!googleWorkspacePath) {
      showSnackbar(intl.get('login.messages.infomrWorkspace'), {
        variant: 'error',
      })
      return
    }

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result)

        //const token = credential?.accessToken
        // // The signed-in user info.
        const additionalUserInfo = getAdditionalUserInfo(result)
        if (additionalUserInfo?.isNewUser) {
          const user = result.user
          const workspaceRef = doc(firestore, googleWorkspacePath)
          await createUserOnWorkspaceFunction({
            workspaceId: workspaceRef.id,
            userId: user.uid,
            userName: user.displayName || '',
            isExternal: true,
          })
          // Atualiza claims
          await user.getIdTokenResult(true)
        }
        setLocation(routes.home)
      })
      .catch((error) => {
        // Handle Errors here.
        console.log('error: ', error)
        const errorCode = error.code
        const errorMessage = error.message
        showSnackbar(`${errorCode} ': ' ${errorMessage}`, {
          variant: 'error',
        })
        // // The email of the user's account used.
        // const email = error.customData.email
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }

  const handleRegisterWithEmail = async () => {
    setHasUsernameError(username.length === 0)
    setHasEmailError(email.length === 0)
    setHasPasswordError(password.length === 0)
    setEqualsPassword(confirmPassword === password)

    if (!workspacePath) {
      showSnackbar(intl.get('login.messages.infomrWorkspace'), {
        variant: 'error',
      })
      return
    } else if (!equalsPasswords) {
      showSnackbar(intl.get('login.messages.passwordNeedBeEqual'), {
        variant: 'error',
      })
      return
    }

    if (username.length > 0 && email.length > 0 && password.length > 0 && confirmPassword.length > 0) {
      try {
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user

            const workspaceRef = doc(firestore, workspacePath)

            updateProfile(user, {
              displayName: username || user.displayName || '',
            })

            await createUserOnWorkspaceFunction({
              workspaceId: workspaceRef.id,
              userId: user.uid,
              userName: username || user.displayName || '',
              isExternal: true,
            })
            setLocation(routes.home)
          })
          .catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            showSnackbar(`${errorCode} ': ' ${errorMessage}`, {
              variant: 'error',
            })
          })
      } catch (err) {
        setPassword('')
        setHasUsernameError(true)
        setHasEmailError(true)
        setHasPasswordError(true)
        error(err)
        showSnackbar((err as TypeError | RangeError | EvalError).message, {
          variant: 'error',
        })
      }
    } else {
      if (username.length === 0) {
        showSnackbar(intl.get('login.messages.specifyUsername'), {
          variant: 'error',
        })
      } else {
        showSnackbar(intl.get('login.messages.specifyEmailPassword'), {
          variant: 'error',
        })
      }
    }
  }

  const handleChangeWorkspace = (
    event: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    const _workspacePath = event.target.value as string
    setWorkspacePath(_workspacePath)
  }

  return (
    <>
      <FullScreenGrid container item xs justifyContent="center" alignItems="center" direction="column">
        {!workspaces ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Card>
              <CardHeader title={intl.get('login.registration')} />
              <CardContent>
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: 'row',
                  }}
                >
                  <Box>
                    <TextField
                      fullWidth
                      value={username}
                      type="text"
                      onChange={handleUserNameChange}
                      label={intl.get('login.username')}
                      required
                      error={hasUsernameError}
                      style={{ marginBottom: 10 }}
                    />

                    <TextField
                      fullWidth
                      value={email}
                      type="email"
                      onChange={handleEmailChange}
                      label={intl.get('login.email')}
                      required
                      error={hasEmailError}
                      style={{ marginBottom: 10 }}
                    />

                    <TextField
                      fullWidth
                      value={password}
                      type="password"
                      onChange={handlePasswordChange}
                      label={intl.get('login.password')}
                      required
                      error={hasPasswordError}
                      onKeyDown={handleKeyDown}
                    />

                    <TextField
                      fullWidth
                      value={confirmPassword}
                      type="password"
                      required
                      onChange={handleConfirmPasswordChange}
                      label={intl.get('login.confirmPassword')}
                      error={equalsPasswords}
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                  <Box style={{ marginLeft: '10px' }}>
                    <InputLabel id="demo-select-small">Workspace</InputLabel>
                    {workspaces && (
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={workspacePath}
                        label="Workspace"
                        required
                        fullWidth
                        onChange={handleChangeWorkspace}
                      >
                        <MenuItem key="0" value={''}>
                          {intl.get('login.messages.selectWorkspace')}
                        </MenuItem>
                        {workspaces.map((el) => (
                          <MenuItem key={el.path} value={el.path}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Box>
                </Box>
              </CardContent>
              <CardActions
                style={{
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                <Button size="small" variant="contained" color="primary" onClick={handleRegisterWithEmail}>
                  {intl.get('login.register')}
                </Button>
              </CardActions>
            </Card>
            <Button
              size="medium"
              variant="outlined"
              color="default"
              onClick={handleChooseWorkspace}
              style={{ marginTop: '10px' }}
            >
              <img alt={'Google image'} src={googleImg} style={{ marginRight: '5px' }} />
              <Typography>{intl.get('login.continueWithGoogle')}</Typography>
            </Button>
            <Dialog open={openWorkspaceModal}>
              <DialogTitle id="select-workspace">Workspace</DialogTitle>
              <DialogContent>
                {workspaces && (
                  <Select
                    labelId="select-small"
                    id="select-small"
                    value={googleWorkspacePath}
                    label="Workspace"
                    required
                    fullWidth
                    onChange={handleChangeDialogWorkspace}
                  >
                    <MenuItem key="0" value={''}>
                      {intl.get('login.messages.selectWorkspace')}
                    </MenuItem>
                    {workspaces.map((el) => (
                      <MenuItem key={el.path} value={el.path}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleChooseWorkspace} color="primary">
                  {intl.get('messages.cancel')}
                </Button>
                <Button variant="contained" onClick={handleSignWithGoogle} color="primary">
                  {intl.get('messages.confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </FullScreenGrid>
    </>
  )
}

export default Registration
