import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'wouter'

import Grid from '@material-ui/core/Grid'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Stepper from '@material-ui/core/Stepper'

import { routes } from '../../../community'
import BottomBar from '../../../components/BottomBar'
import Layout from '../../../components/Layout'
import useCamera from '../../../services/hooks/useCamera'
import CameraPermissionStep from './Steps/CameraPermissionStep'
import FramingStep from './Steps/FramingStep'
import ContrastStep from './Steps/ContrastStep'
import LightingStep from './Steps/LightingStep'
import Preloader from '../../../components/Preloader'
import CardMessage from '../../../components/CardMessage'
import useIntl from '../../../hooks/useIntl'
import useLockModules from '../../../hooks/useLockModules'
import LockModuleDialog from '../../../components/LockModuleDialog'
import useCheckHasPermission from '../../../services/hooks/useCheckHasPermission'

enum Steps {
  GetPermission = 0,
  Framing,
  Contrast,
  Lighting,
  Done,
}

interface ISignSentenceLobbyProps {
  path: string
}

const ScrollerGrid = styled(Grid)`
  overflow: auto;
  width: 100%;
  clear: both;
`

const Container = styled.div`
  max-width: 1000px;
  margin: auto;
`

const SignSentenceLobby: React.FC<ISignSentenceLobbyProps> = () => {
  const { lockedRecordVideo, checkLockedModule, open, toggleOpen } = useLockModules()
  const intl = useIntl()
  const hasPermissionOnModule = useCheckHasPermission('signSentence')
  const stepsData: { title: string; Component: React.FC }[] = [
    {
      title: intl.get('pages.signSentence.lobby.cameraPermissionStep.title'),
      Component: CameraPermissionStep,
    },
    {
      title: intl.get('pages.signSentence.lobby.framingStep.title'),
      Component: FramingStep,
    },
    {
      title: intl.get('pages.signSentence.lobby.contrastStep.title'),
      Component: ContrastStep,
    },
    {
      title: intl.get('pages.signSentence.lobby.lightingStep.title'),
      Component: LightingStep,
    },
  ]
  const [cameraState, camFn] = useCamera()
  const [currentStep, setStep] = useState<Steps>(Steps.GetPermission)
  const setLocation = useLocation()[1]

  useEffect(() => {
    if (lockedRecordVideo) {
      checkLockedModule('signSentence')
    }

    // Quando o componente for montado, iremos ligar a camera
    camFn.getStream()
    return () => {
      // Quando for desmontado, desligamos a camera
      camFn.closeStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedRecordVideo])

  const goToStep = (value: number) => () => {
    if (currentStep + value === Steps.Done) {
      localStorage.setItem('showedLobbyRecordVideo', 'true')
      setLocation(routes.signSentenceMain)
    }
    setStep((step) => step + value)
  }

  // Usuário não tem permissão no modulo
  if (!hasPermissionOnModule) {
    return (
      <Layout title={intl.get('modules.signSentence')}>
        <CardMessage
          title={intl.get('components.layout.featureLocked')}
          subtitle={intl.get('components.layout.talkToAdmin')}
        />
      </Layout>
    )
  }

  if (currentStep === Steps.Done) {
    return <Preloader text="Redirecting" />
  }

  return (
    <Layout title={`${intl.get('modules.signSentence')} - Lobby`} requiredModule={'signSentence'}>
      <ScrollerGrid item>
        <Container>
          <Stepper activeStep={currentStep} orientation="vertical">
            {stepsData.map(({ title, Component }, index) => (
              <Step key={title}>
                <StepLabel>{title}</StepLabel>
                {currentStep === index && (
                  <StepContent>
                    <Component />
                  </StepContent>
                )}
              </Step>
            ))}
          </Stepper>
        </Container>
      </ScrollerGrid>
      <BottomBar
        prevEnabled={currentStep !== Steps.GetPermission}
        nextEnabled={!cameraState.isEnabled ? false : true}
        nextCallback={goToStep(1)}
        prevCallback={goToStep(-1)}
      />
      <LockModuleDialog open={open} toggleOpen={toggleOpen} />
    </Layout>
  )
}

export default SignSentenceLobby
