import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  TablePagination,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import WarningIcon from '@material-ui/icons/Warning'
import CameraIcon from '@material-ui/icons/VideoCall'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

import styled from 'styled-components'
import { IListText, ISentence } from '..'
import React, { ChangeEvent } from 'react'
import MultiSelectSentenceCategory from '../../../components/MultiSelectSentenceCategory'
import { red } from '@material-ui/core/colors'
import { getNewCategoriesText } from '../../../services/utils'

interface IPhraseListProps {
  data: ISentence[]
  setSentences: React.Dispatch<React.SetStateAction<ISentence[]>>
  categories: string[]
  handleOnDeletePhraseClick: (key: string) => void
  handleOnClickRecordPhrase: (key: string, sentence: string) => void
  handleOnValidate: (event: React.ChangeEvent<HTMLInputElement>, sentenceData: ISentence, oldSentence: string) => void
  isLoading: boolean
  loadMoreSentences: (loadMore?: number) => void
  hasMorePhrases: boolean
  loggedUser: string
  handleChangePage: (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void
  paginationConfig: {
    currentPage: number
    firstOfList: number | null
    lastOfList: number | null
    load: boolean
    rowsPerPage: number
  }
  listTexts: IListText
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PhraseRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: #c4c4c41a;
  }
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
`

const PhraseCell = styled(TableCell)`
  cursor: pointer;
  padding: 0;
  padding-left: 10px;
`

const PhraseListPure: React.FC<IPhraseListProps> = ({
  data,
  setSentences,
  categories,
  handleOnDeletePhraseClick,
  handleOnClickRecordPhrase,
  handleOnValidate,
  isLoading,
  hasMorePhrases,
  loggedUser,
  handleChangePage,
  paginationConfig,
  listTexts,
  handleChangeRowsPerPage,
}) => {
  const actionButtons = (sentenceData: ISentence) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip title={listTexts.delete}>
          <IconButton
            disabled={sentenceData.isValidated}
            onClick={() => {
              handleOnDeletePhraseClick(sentenceData.id)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        {sentenceData.isRepeated && (
          <Tooltip title={listTexts.repeatedSentence}>
            <IconButton>
              <WarningIcon />
            </IconButton>
          </Tooltip>
        )}
        {!sentenceData.isRepeated && (
          <Tooltip title={sentenceData.isRecording ? listTexts.recordingVideo : listTexts.recordVideo}>
            <span>
              <IconButton
                disabled={
                  !sentenceData.isValidated || (sentenceData.isRecording && sentenceData.userRecording != loggedUser)
                }
                onClick={() => {
                  handleOnClickRecordPhrase(sentenceData.id, sentenceData.sentence)
                }}
              >
                <CameraIcon style={sentenceData.isRecording ? { color: red[500] } : {}} />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        {!isLoading && (
          <Table aria-label="Signs list">
            <TableHead>
              <TableRow>
                <TableCell align="center" variant="head" size="small" style={{ width: '50px' }}>
                  {listTexts.validate}
                </TableCell>
                <TableCell align="center" variant="head" size="small">
                  {listTexts.sentence}
                </TableCell>
                <TableCell align="center" variant="head" size="small">
                  {listTexts.category}
                </TableCell>
                <TableCell align="center" variant="head" size="small">
                  {listTexts.needResearch}
                </TableCell>
                <TableCell
                  align="center"
                  variant="head"
                  size="small"
                  style={{ padding: '0', paddingLeft: '10px', width: '50px' }}
                >
                  {listTexts.actions}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((obj: ISentence) => (
                <PhraseRow key={obj.id} style={{ padding: '0' }}>
                  <PhraseCell
                    style={{
                      padding: '0',
                    }}
                  >
                    <Tooltip
                      title={listTexts.validate}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Checkbox
                        color="default"
                        checked={obj.isValidated}
                        onChange={(event) => {
                          const el = document.getElementById(obj.id) as HTMLInputElement
                          const oldSentence = obj.sentence
                          if (el != null) obj.sentence = el.value
                          obj.isValidated = event.target.checked
                          handleOnValidate(event, obj, oldSentence)
                        }}
                      />
                    </Tooltip>
                  </PhraseCell>
                  <PhraseCell
                    style={{
                      padding: '0',
                      paddingLeft: '10px',
                      width: '65%',
                    }}
                  >
                    {!obj.isValidated ? (
                      <TextField
                        style={{
                          width: '100%',
                        }}
                        id={obj.id}
                        defaultValue={obj.sentence}
                        multiline
                      ></TextField>
                    ) : (
                      obj.sentence || ''
                    )}
                  </PhraseCell>
                  <PhraseCell>
                    {!obj.isValidated ? (
                      <MultiSelectSentenceCategory
                        options={categories}
                        value={obj.sentenceCategory}
                        handleChangeOptions={(value: string[]) => {
                          const _sentences = [...data]
                          const sentenceId = _sentences.findIndex((el) => el.id == obj.id)

                          const undefinedId = value.findIndex((el) => {
                            return el == listTexts.undefined
                          })

                          if (undefinedId != -1 && value.length > 1) return
                          _sentences[sentenceId].sentenceCategory = getNewCategoriesText(value, false, true)
                          setSentences(_sentences)
                        }}
                      />
                    ) : obj.sentenceCategory ? (
                      getNewCategoriesText(obj.sentenceCategory, false, true).join(', ')
                    ) : (
                      ''
                    )}
                  </PhraseCell>
                  <PhraseCell>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Checkbox
                        color="default"
                        disabled={obj.isValidated}
                        checked={obj.needResearch}
                        icon={<CheckCircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onChange={(event) => {
                          const _sentences = [...data]
                          const sentenceId = _sentences.findIndex((el) => el.id == obj.id)
                          _sentences[sentenceId].needResearch = event.target.checked
                          setSentences(_sentences)
                        }}
                      />
                    </Box>
                  </PhraseCell>
                  <PhraseCell style={{ padding: '0', paddingLeft: '10px' }}>{actionButtons(obj)}</PhraseCell>
                </PhraseRow>
              ))}
            </TableBody>
            <TablePagination
              style={{ pointerEvents: 'auto' }}
              count={-1}
              page={paginationConfig.currentPage}
              rowsPerPage={paginationConfig.rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={listTexts.labelRows}
              labelDisplayedRows={({ from, to }) =>
                listTexts.labelDisplayedRows.replace('{from}', from.toString()).replace(/{to}/g, to.toString())
              }
              nextIconButtonProps={{ disabled: !hasMorePhrases }}
            />
          </Table>
        )}
      </TableContainer>
    </>
  )
}

export default PhraseListPure
