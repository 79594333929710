import { Box, Typography } from '@material-ui/core'
import React from 'react'

import { Redirect, useLocation } from 'wouter'
import Layout from '../components/Layout'
import useIntl from '../hooks/useIntl'
import logger from '../services/logger'

interface INotFoundProps {
  path: string
}

const { log } = logger('NotFound')

const NotFound: React.FC<INotFoundProps> = () => {
  const [location] = useLocation()
  const intl = useIntl()

  log(`Página ${location} não encontrada.`)
  return (
    <Layout title={intl.get('pages.notFound.title')} imageBg>
      <Redirect to="/NotFound" />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <Typography
          variant="h1"
          component="div"
          style={{
            alignSelf: 'center',
            marginTop: '50%',
          }}
        >
          404
        </Typography>
        <Typography
          variant="h2"
          component="div"
          style={{
            alignSelf: 'center',
          }}
        >
          {intl.get('pages.notFound.pageNotFound')}
        </Typography>
      </Box>
    </Layout>
  )
}

export default NotFound
