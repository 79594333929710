import { Card, CardActionArea, makeStyles, CardContent, Grid, Typography, Tooltip, Link } from '@material-ui/core'
import React, { useState } from 'react'
import useIntl from '../../../../hooks/useIntl'
import { ISegmentView, SignState } from 'collections'
import HoverablePlayer from '../../HoverablePlayer'
import { Theme, createStyles } from '@material-ui/core/styles'

interface ICardHTube {
  signId: string
  segment?: ISegmentView
  words?: []
  state: SignState
  numberOfSegments: number
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>> | null
  setSelectedSignId: React.Dispatch<React.SetStateAction<string>> | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345,
    },
    grid: {
      margin: '5px',
      minWidth: '340px',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    headingAccordion: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
)

const CardHTube: React.FC<ICardHTube> = ({
  signId,
  segment,
  words,
  state,
  numberOfSegments,
  setOpenInfo,
  setSelectedSignId,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const [open, setOpen] = useState(true)

  return (
    <Grid item className={classes.grid} key={segment?.id}>
      <Tooltip
        placement="bottom"
        onScrollCapture={() => {
          setOpen(false)
        }}
        open={open}
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              offset: {
                offset: '0px, -200px',
              },
            },
          },
        }}
        title={words ? words.map((word) => Object.keys(word)[0]).join(' - ') : ''}
      >
        <Card className={classes.card}>
          <CardActionArea>
            <Link
              variant="body2"
              onClick={(ev) => {
                ev.stopPropagation()
                if (setOpenInfo) setOpenInfo(true)
                if (setSelectedSignId) setSelectedSignId(signId)
                window.history.replaceState(null, '', `#${signId}`)
              }}
            >
              {segment ? (
                <HoverablePlayer segment={segment} />
              ) : (
                <div
                  style={{
                    height: '195px',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {intl.get('messages.withoutVideo')}
                </div>
              )}
            </Link>
          </CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography variant="subtitle1">
              {intl.get('messages.state') + ':  ' + intl.get(`messages.${state.toLowerCase()}`)}
            </Typography>
            <Typography variant="subtitle2">
              {intl.get('messages.numberOfSegmentsWithNumber', {
                number: numberOfSegments,
              })}
            </Typography>
          </CardContent>
        </Card>
      </Tooltip>
    </Grid>
  )
}

export default CardHTube
