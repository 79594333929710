import { IGlobalAttr } from 'collections'
import React, { useEffect, createContext, useContext, useState } from 'react'
import logger from '../logger'
import { useUser } from 'reactfire'
import LGPDTerms from '../../components/LGPDTerms'
import Preloader from '../../components/Preloader'
import useIntl from '../../hooks/useIntl'

const { log } = logger('useLGPDCheck.ts')

const initialState: IGlobalAttr['acceptedLgpd'] = null

type LGPDCheckHookResult = [boolean | null, () => void]

// eslint-disable-next-line @typescript-eslint/no-empty-function
const Context = createContext<LGPDCheckHookResult>([initialState, () => {}])

/**
 * Gerencia o aceite do usuário da LGPD
 */
export const ProviderLGPDCheck: React.FC = ({ children }) => {
  const [acceptedLgpd, setAcceptedLgpd] = useState<boolean | null>(initialState)
  const fbUser = useUser<firebase.default.User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const intl = useIntl()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const toggleOpenDialog = () => {
    setOpenDialog(!openDialog)
  }

  const checkAcceptedLGPD = async () => {
    log('Checando dados do token do usuário. . .')
    setIsLoading(true)
    const tokenResult = await fbUser.data?.getIdTokenResult(true)
    await fbUser.data?.getIdToken(true)
    const _acceptedLgpd = !!tokenResult?.claims.acceptedLGPD
    setAcceptedLgpd(_acceptedLgpd)
    setIsLoading(false)
    return _acceptedLgpd
  }

  useEffect(() => {
    checkAcceptedLGPD()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbUser.data?.uid])

  return (
    <Context.Provider value={[acceptedLgpd, toggleOpenDialog]}>
      {acceptedLgpd && children}
      {
        <LGPDTerms
          checkAcceptedLGPD={checkAcceptedLGPD}
          acceptedLgpd={acceptedLgpd}
          open={openDialog}
          setOpen={setOpenDialog}
        />
      }
      {isLoading && <Preloader asBlock text={intl.get('messages.loadingProviders')}></Preloader>}
    </Context.Provider>
  )
}

export default (): LGPDCheckHookResult => useContext(Context)
